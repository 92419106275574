import React from 'react';
import c from 'classnames';
import CopyText from '@components/typography/CopyText';
import SearchSuggestion from '@components/common/SearchBar/components/SearchSuggestion';
import { SuggestionsByCategory } from '@components/common/SearchBar/types';
import useTextSnippets from '@services/useTextSnippets';
import { SearchHit } from '@infrastructure/api/BaseNClient/useGlobalSearch';

import styles from '@components/common/SearchBar/style.module.scss';

type Props = {
  suggestions?: { data: SearchHit[] };
  onSuggestionClick: (suggestions: SearchHit) => void;
  inline?: boolean;
  searchTerm: string;
};

const ActiveSuggestions: React.FC<Props> = ({ inline = false, suggestions, onSuggestionClick, searchTerm }) => {
  const headerI18n = useTextSnippets('header');

  const suggestionsByCategories = suggestions
    ? suggestions.data.reduce(
        (suggestionsByCategory, suggestion: SearchHit) => {
          const type = suggestion.type as SearchHit['type'];

          suggestionsByCategory[type].push(suggestion);

          return suggestionsByCategory;
        },
        {
          entity: [],
          dashboard: [],
          issue: [],
          alert: [],
        } as SuggestionsByCategory
      )
    : null;

  return (
    <div className={c(styles.suggestions, inline && styles.inline)}>
      {suggestions && suggestions.data && (
        <div className={styles.suggestionsContainer}>
          {suggestionsByCategories
            ? Object.keys(suggestionsByCategories).map(category => {
                const suggestionsByCategory = suggestionsByCategories[category as keyof typeof suggestionsByCategories];

                if (suggestionsByCategory && suggestionsByCategory.length === 0) {
                  return null;
                }

                return (
                  <div className={styles.searchCategoryWrapper} key={category}>
                    <CopyText variant="copy-2" additionalClass="text-gray-4 cursor-normal">
                      {headerI18n[category as keyof typeof headerI18n]}
                    </CopyText>
                    {suggestionsByCategory.map((suggestion: SearchHit) => (
                      <SearchSuggestion
                        key={suggestion.id}
                        onClick={onSuggestionClick}
                        suggestion={suggestion}
                        searchTerm={searchTerm}
                      />
                    ))}
                  </div>
                );
              })
            : null}
        </div>
      )}
    </div>
  );
};

export default ActiveSuggestions;
