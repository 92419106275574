import React, { useCallback } from 'react';
import c from 'classnames';
import useTextSnippets from '@services/useTextSnippets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { PanelId, selectPanels, toggleNode, togglePanel } from '@redux/sidebar';
import { toggleSidebar } from '@redux/layout';
import { TreeNodeInfo } from '@components/common/Tree';
import { SidebarPanel } from './SidebarPanel';
import { SidebarLink, SidebarMenu } from './SidebarMenu';
import ROUTES from '@infrastructure/routes';
import { useHotkeys } from 'react-hotkeys-hook';
import { KEYCODES } from '@constants/keyboardShortcutsConfig';
import { useSidebarFavorites } from './useSidebarFavorites';
import noop from '@utils/noop';
import { useNavigate } from 'react-router-dom';
import { IS_PROD } from '@config';

import styles from './Sidebar.module.scss';

export type SidebarProps = {
  open: boolean;
  drawer?: boolean;
};

export const Sidebar: React.FC<SidebarProps> = ({ open, drawer }) => {
  const i18n = useTextSnippets('sidebar');
  const dispatch = useAppDispatch();
  const panels = useAppSelector(selectPanels);
  const favoritesQuery = useSidebarFavorites();
  const navigate = useNavigate();

  const onToggleSidebar = useCallback(() => {
    dispatch(toggleSidebar());
  }, [dispatch]);

  useHotkeys(KEYCODES.TOGGLE_SIDEBAR, onToggleSidebar);

  const topLinks: SidebarLink[] = [
    { label: i18n.overview, href: '/overview' },
    { label: i18n.dashboards, href: '/dashboards' },
    { label: i18n.issueManager, href: '/issue-manager' },
    { label: i18n.inventory, href: '/inventory' },
    { label: i18n.alerts, href: '/alerts' },
    { label: i18n.metrics, href: '/metrics' },
  ];

  const bottomLinks = [
    !IS_PROD && {
      icon: 'GraphicalUserInterface',
      label: i18n.storybook,
      href: `/storybook-static`,
      target: '_blank',
    },
    // {
    //   icon: 'FilterColumns',
    //   label: 'Table',
    //   href: '/table',
    // },
    {
      label: i18n.tools,
      href: '/tools',
      icon: 'Tools',
    },
    { label: i18n.manage, icon: 'Management', href: '/management' },
  ].filter(Boolean) as SidebarLink[];

  const handleToggle = (id: PanelId, isExpanded: boolean) => {
    dispatch(togglePanel({ id, isExpanded }));
  };

  const handleNodeToggle = (id: PanelId, node: TreeNodeInfo, nodePath: number[], isExpanded: boolean) => {
    dispatch(toggleNode({ id, nodePath, isExpanded }));
  };

  const handleFavoriteClick = (id: PanelId, node: TreeNodeInfo) => {
    if (!node.hasCaret) {
      navigate(ROUTES.dashboardById.replace(':dashboardId', `${node.id}`));
    }
  };

  return (
    <div className={c(styles.sideBar, open && styles.sideBarOpen, drawer && styles.sideBarDrawer)}>
      <div className={styles.top}>
        <SidebarMenu links={topLinks} onClick={drawer ? onToggleSidebar : noop} />

        <div className={styles.hr} />
      </div>

      <div className={styles.middle}>
        <SidebarPanel
          id="favorites"
          loading={favoritesQuery.isLoading}
          title={i18n.favorites}
          contents={open ? panels.favorites.contents : []}
          expanded={panels.favorites.isExpanded}
          onToggle={handleToggle}
          onNodeToggle={handleNodeToggle}
          onNodeClick={handleFavoriteClick}
        />

        {/* <SidebarPanel */}
        {/*  id="shared" */}
        {/*  title={i18n.shared} */}
        {/*  contents={panels.shared.contents} */}
        {/*  expanded={panels.shared.isExpanded} */}
        {/*  onToggle={handleToggle} */}
        {/*  onNodeToggle={handleNodeToggle} */}
        {/* /> */}
      </div>

      <div className={styles.bottom}>
        <div className={styles.hr} />
        <SidebarMenu links={bottomLinks} onClick={drawer ? onToggleSidebar : noop} />
      </div>
    </div>
  );
};
