import { Panel } from '@components/common/Panel';
import { Tree, TreeNodeInfo } from '@components/common/Tree';
import { PanelId } from '@redux/sidebar';
import React, { FC } from 'react';
import LoadingSpinner from '../LoadingSpinner';

import styles from './Sidebar.module.scss';

export type SidebarPanelProps = {
  id: PanelId;
  title: string;
  contents: TreeNodeInfo[];
  expanded?: boolean;
  loading?: boolean;
  onToggle?: (id: PanelId, active: boolean) => void;
  onNodeToggle?: (id: PanelId, node: TreeNodeInfo, nodePath: number[], isExpanded: boolean) => void;
  onNodeClick?: (id: PanelId, node: TreeNodeInfo, nodePath: number[]) => void;
};

export const SidebarPanel: FC<SidebarPanelProps> = ({
  id,
  title,
  contents,
  expanded = false,
  loading = false,
  onToggle,
  onNodeToggle,
  onNodeClick,
}) => {
  const handleChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, active: boolean) => {
    onToggle?.(id, active);
  };

  const handleNodeToggle = (node: TreeNodeInfo, nodePath: number[], isExpanded: boolean) => {
    onNodeToggle?.(id, node, nodePath, isExpanded);
  };

  const handleNodeClick = (node: TreeNodeInfo, nodePath: number[]) => {
    onNodeClick?.(id, node, nodePath);
  };

  return (
    <Panel
      additionalClasses={{
        root: styles.sidebarPanel,
        header: styles.panelHeader,
        title: styles.panelTitle,
        content: styles.panelContent,
        active: styles.active,
      }}
      title={title}
      active={expanded}
      onChange={handleChange}
      icon={loading ? <LoadingSpinner /> : undefined}
    >
      <Tree
        contents={contents}
        onNodeToggle={handleNodeToggle}
        onNodeClick={handleNodeClick}
        additionalClass={styles.sidebarTree}
      />
    </Panel>
  );
};
