import React from 'react';
import { useLoadingContext } from 'react-router-loading';
import PageHeader from '@components/layout/PageHeader';
import { ScriptsPageTable } from './components/ScriptsPageTable';
import { scriptsPageSlice } from '@infrastructure/redux';
import { setDocumentTitle } from '@utils/dom';
import { lowerCase, startCase } from 'lodash';

export const ScriptsPage = () => {
  const loadingContext = useLoadingContext();

  setDocumentTitle('Scripts');

  return (
    <div className="page-container">
      <PageHeader
        level={2}
        breadcrumbs
        breadcrumbsProps={{
          omitRootLabel: true,
          transformLabel: (label) => {
            return startCase(lowerCase(label))
          }
        }}
      />

      <div className="page-body">
        <ScriptsPageTable onInitialLoadComplete={() => loadingContext.done()} actions={scriptsPageSlice.actions} />
      </div>
    </div>
  );
};
