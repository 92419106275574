import React from 'react';
import Icon, { IconNames } from '@components/common/Icon';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
import Button, { ButtonProps } from '@components/common/Button';
import c from 'classnames';

import styles from './NoElementsContainer.module.scss';

export type NoElementsContainerProps = {
  icon?: IconNames;
  iconClass?: string;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  buttonProps?: { loading: boolean; onClick: ButtonProps['onClick']; label: string };
  additionalClass?: string;
};

const NoElementsContainer: React.FC<NoElementsContainerProps> = ({
  icon,
  iconClass,
  title,
  description,
  buttonProps,
  additionalClass,
}) => {
  return (
    <div className={c(styles.noElementsContainer, additionalClass)}>
      {icon && <Icon name={icon} additionalClass={c(styles.iconClass, iconClass)} />}

      {typeof title === 'string' ? (
        <Headline centered additionalClass={styles.noEntityTitle}>
          {title}
        </Headline>
      ) : (
        title
      )}

      {description &&
        (typeof description === 'string' ? (
          <CopyText centered additionalClass={styles.noEntityDescription}>
            {description}
          </CopyText>
        ) : (
          description
        ))}

      {buttonProps && (
        <Button
          additionalClass={styles.addFirstEntityButton}
          loading={buttonProps.loading}
          variant="fillOutline"
          onClick={buttonProps.onClick}
          ariaLabel={buttonProps.label}
          size="s"
        >
          <div className="hbox">
            <Icon name="Add" size="s" additionalClass="mr-4" />
            <CopyText additionalClass={styles.addFirstEntityButtonLabel}>{buttonProps.label}</CopyText>
          </div>
        </Button>
      )}
    </div>
  );
};

export default NoElementsContainer;
