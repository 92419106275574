import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import { NoteWithEntity } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import React, { FC } from 'react';
import { SessionDetails } from './SessionDetails';
import { MWDetails } from './MWDetails';
import { ConfigDetails } from './ConfigDetails';

export type ActiveItemDetailsProps = DetailsPanelProps & {
  item?: NoteWithEntity | null;
};

const DETAILS_COMPONENTS = {
  session: SessionDetails,
  maintenance: MWDetails,
  config: ConfigDetails,
};

export const ActiveItemDetails: FC<ActiveItemDetailsProps> = props => {
  const DetailsComponent = props.item?.type && DETAILS_COMPONENTS[props.item.type as keyof typeof DETAILS_COMPONENTS];

  return DetailsComponent ? <DetailsComponent {...props} /> : null;
};
