import React, { FC } from 'react';
import useTextSnippets from '@services/useTextSnippets';
import { DefaultMotion } from '@services';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
// import Icon from '@components/common/Icon';
import shortcuts from './shortcuts';
import { isEmpty } from 'lodash';

import styles from './KeyboardShortcuts.module.scss';

const maybeSubstituteWithIcon = (keyCode: string) => {
  // TODO finalize when keyboard icons are exported
  return keyCode;
};

const Shortcut: FC<{ shortcut: string }> = ({ shortcut }) => {
  const parts = shortcut.split('-');

  return (
    <>
      {parts.map(keyCode => (
        <div className={styles.keyCode}>{maybeSubstituteWithIcon(keyCode)}</div>
      ))}
    </>
  );
};

const KeyboardShortcuts: React.FC = () => {
  const { title, description } = useTextSnippets('keyboardShortcuts');

  return (
    <div className={styles.keyboardShortcutsPage}>
      <DefaultMotion key="keyboardShortcuts" className={styles.introContainer}>
        <Headline variant="headline-5">{title}</Headline>
        <CopyText variant="copy-2" additionalClass="mb-48">
          {description}
        </CopyText>

        {Object.keys(shortcuts).map(
          groupTitle =>
            !isEmpty(shortcuts[groupTitle]) && (
              <div key={groupTitle} className={styles.group}>
                <CopyText variant="copy-2">{groupTitle}</CopyText>
                <div className={styles.groupBody}>
                  {Object.keys(shortcuts[groupTitle]).map(title => (
                    <React.Fragment key={title}>
                      <div className={styles.shortcutTitle}>{title}</div>
                      <div className={styles.shortcut}>
                        <Shortcut shortcut={shortcuts[groupTitle][title]} />
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )
        )}
      </DefaultMotion>
    </div>
  );
};

export default KeyboardShortcuts;
