import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import React from 'react';
import CopyText from '@components/typography/CopyText';
import { Widget } from '@components/layout/Widget';
import { ChartDataSource, ChartWidgetSubConfig } from '@infrastructure/redux/widgetPage';
import { getTimeSeriesPropsBySource } from '@components/pages/EntityPage/components/getWidgets';
import Error404Page from '@components/pages/Error404Page';
import ROUTES from '@infrastructure/routes';
import IconButton from '@components/common/IconButton';
import { useNavigate } from 'react-router-dom';
import { EntityStatusDot } from '@components/pages/EntityPage/components/EntityStatusDot';
import { TimeControl } from '@components/common/form/TimeControl';
import { DateRange } from '@blueprintjs/datetime';
import moment from 'moment';
import { AlertsAccordion } from './AlertsAccordion';
import { DEFAULT_TIMESERIES_WIDGET_CONFIG } from '@infrastructure/redux/widgetPage/constants';
import { EntityIcon } from '@components/pages/EntityPage/components/EntityIcon';
import { setDocumentTitle } from '@utils/dom';

import styles from './ChartDetails.module.scss';

export const ChartDetails: React.FC<{ entity: EntityDetails; source: ChartDataSource }> = ({ entity, source }) => {
  const dashboardWidget = getTimeSeriesPropsBySource(entity, source);

  const [dateRange, setDateRange] = React.useState<DateRange>([moment().subtract(1, 'day').toDate(), null]);
  // styling for default range vs. user selected differs
  const [isDateRangeUserSelected, setIsDateRangeUserSelected] = React.useState(false);

  const navigate = useNavigate();

  const handleDateRangeChange = (newDateRange: DateRange) => {
    setDateRange(newDateRange);
    setIsDateRangeUserSelected(true);
  };

  const handleDateRangeReset = () => {
    setDateRange([moment().subtract(1, 'day').toDate(), null]);
    setIsDateRangeUserSelected(false);
  };

  const handleBackClick = React.useCallback(() => {
    navigate(`${ROUTES.entityById.replace(':entityId', entity.id)}`);
  }, [entity.id, navigate]);

  if (!dashboardWidget) {
    return <Error404Page text="Cannot locate a data source" />;
  }

  const {
    config: { config, ...otherProps },
  } = dashboardWidget;

  setDocumentTitle(`${otherProps.name}, ${entity.name}`);

  return (
    <div className="page-container">
      <div className="page-header">
        <IconButton icon="Back" onClick={handleBackClick} size="xs" />
        <EntityIcon entity={entity} additionalClass="ml-20" />
        <CopyText variant="copy-2" additionalClass="ml-8">
          {entity.name}
        </CopyText>
        <EntityStatusDot entity={entity} additionalClass="ml-8" />

        <div className="ml-auto">
          <TimeControl
            active={isDateRangeUserSelected}
            dateRange={dateRange}
            onChange={handleDateRangeChange}
            onReset={handleDateRangeReset}
          />
        </div>
      </div>

      <div className="page-body">
        <Widget
          {...otherProps}
          config={
            {
              ...DEFAULT_TIMESERIES_WIDGET_CONFIG,
              ...config,
              showSummary: true,
              preRender: true,
              dateRange,
            } as ChartWidgetSubConfig
          }
          additionalClass={styles.widget}
        />

        <AlertsAccordion source={source} />
      </div>
    </div>
  );
};
