import Gravatar from '@components/common/Gravatar';
import Icon from '@components/common/Icon';
import { TableColumns } from '@components/common/Table';
import CopyText from '@components/typography/CopyText';
import { MaintenanceWindow } from '@infrastructure/api/BaseNClient/useMaintenanceWindowsQuery';
import { toIdMap } from '@utils/misc';
import c from 'classnames';
import moment from 'moment';
import Link, { Linkify } from '@components/common/Link';
import ROUTES from '@infrastructure/routes';
import { isActive } from '@utils/mw';

import styles from './MaintenanceTable.module.scss';

export const getTableColumns = (): TableColumns<MaintenanceWindow> => {
  return toIdMap([
    {
      id: 'name',
      label: 'Name',
      width: 250,
      sortable: true,
      valueRenderer: (mw: MaintenanceWindow) => (
        <div className="flex-1 truncate hbox text-dark">
          <Link href={`${ROUTES.singleMaintenanceWindow.replace(':id', mw.id)}`} additionalClass={styles.link}>
            <CopyText variant="copy-4" additionalClass="truncate">
              {mw.name}
            </CopyText>
          </Link>
        </div>
      ),
    },
    {
      id: 'reason',
      label: 'Reason',
    },
    {
      id: 'description',
      label: 'Description',
      valueRenderer: (mw: MaintenanceWindow) => {
        return (
          <Linkify target="_blank" rel="noopener noreferrer">
            {mw.description}
          </Linkify>
        );
      },
    },
    {
      id: 'schedules',
      label: 'Schedules',
      valueRenderer: ({ times }: MaintenanceWindow) => {
        const total = times?.length ?? 0;
        const active = times?.filter(isActive).length ?? 0;
        return (
          <>
            <Icon
              name="MaintenanceWindow"
              additionalClass={c(styles.scheduleIcon, active > 0 && styles.active)}
              size="s"
            />
            <span>{`${active} of ${total}`}</span>
          </>
        );
      },
    },
    {
      id: 'entityIds',
      label: 'Targets',
      valueRenderer: ({ entityIds }: MaintenanceWindow) => {
        return !entityIds?.length ? '-' : `${entityIds.length} ${entityIds.length === 1 ? 'entity' : 'entities'}`;
      },
    },
    {
      id: 'user',
      label: 'Creator',
      sortable: true,
      valueRenderer: ({ user }: MaintenanceWindow) => {
        return (
          <div className="w-full hbox">
            <Gravatar size={48} username={user} email={user} additionalClass="mr-8" />
            <CopyText variant="copy-4" additionalClass="truncate">
              {user}
            </CopyText>
          </div>
        );
      },
    },
    {
      id: 'last_modified',
      label: 'Last Modified',
      sortable: true,
      valueRenderer: ({ last_modified }: MaintenanceWindow) => {
        return moment(last_modified).fromNow();
      },
    },
  ]);
};
