import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SelectControl } from '@components/common/form/Select';
import { BaseSelectItem } from '@components/common/form/Select/BaseSelect';
import useTextSnippets from '@services/useTextSnippets';
import { ChartDataSource } from '@redux/widgetPage';
import { SubLevel } from '@components/layout/SubLevel';
import { EntityDetails } from '@redux/inventoryPage';
import { Metric } from '@infrastructure/api/BaseNClient/useMetricsQuery';

const useEntityOptions = (
  metricsets?: EntityDetails['metricsets'],
  group?: string | null,
  metricId?: string | null
) => {
  return useMemo(() => {
    const allMetrics = metricsets?.reduce((arr, { metrics }) => arr.concat(metrics), [] as Metric[]) ?? [];
    const selectedMetric = allMetrics.find(({ id }) => id === metricId);
    const selectedGroup = selectedMetric?.metricsets[0] ?? group;
    const groupOptions = metricsets?.map(item => ({ label: item.name, value: item.name })) ?? [];
    const metricOptions = (group ? metricsets?.find(({ name }) => name === group)?.metrics ?? [] : allMetrics).map(
      item => ({ label: item.name, value: item.id })
    );

    return {
      groupOptions,
      selectedGroup: selectedGroup ? { label: selectedGroup, value: selectedGroup } : null,
      metricOptions,
      selectedMetric: selectedMetric ? { label: selectedMetric.name, value: selectedMetric.id } : null,
    };
  }, [group, metricId, metricsets]);
};

export type MetricPickerProps = {
  source: ChartDataSource;
  metricsets?: EntityDetails['metricsets'];
  onChange?: (newSource: ChartDataSource) => void;
};

export const MetricPicker: FC<MetricPickerProps> = ({ source, metricsets, onChange }) => {
  const i18n = useTextSnippets('widgetPage');
  const [group, setGroup] = useState<string | null>(null);
  const { groupOptions, selectedGroup, metricOptions, selectedMetric } = useEntityOptions(
    metricsets,
    group,
    source?.metricId
  );

  useEffect(() => {
    setGroup(null);
  }, [source?.entityId]);

  const handleGroupChange = useCallback(
    (option: BaseSelectItem<string> | null) => {
      setGroup(option?.value ?? null);
    },
    [setGroup]
  );

  const handleMetricChange = useCallback(
    (option: BaseSelectItem | null) => {
      onChange?.({
        ...source,
        metricId: option ? `${option.value}` : undefined,
      });
    },
    [onChange, source]
  );

  return (
    <>
      <SubLevel depth={source.entityId ? 2 : 1} additionalClass="-mt-16">
        <SelectControl
          label={i18n.group}
          placeholder={i18n.selectGroup}
          options={groupOptions}
          onChange={handleGroupChange}
          value={selectedGroup}
          filterable
          clearable
          fullWidth
        />
      </SubLevel>

      {!!selectedGroup && (
        <SubLevel depth={source.entityId ? 2 : 1} additionalClass="-mt-16">
          <SelectControl
            label={i18n.metric}
            placeholder={i18n.selectMetric}
            options={metricOptions}
            onChange={handleMetricChange}
            value={selectedMetric}
            filterable
            clearable
            fullWidth
          />
        </SubLevel>
      )}
    </>
  );
};
