import React, { PropsWithChildren, useCallback } from 'react';
import c from 'classnames';
import { Select } from '@components/common/form/Select/Select';
import { BaseSelectItem } from '@components/common/form/Select/BaseSelect';
import TextControl from '@components/common/form/Input/TextControl';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '@components/common/Table/config';
import { RowsPerPageOption } from '@components/common/Table/types';
import VerticalSeparator from '@components/separators/VerticalSeparator';
import CopyText from '@components/typography/CopyText';
import useTextSnippets from '@services/useTextSnippets';
import { PaginationActions } from './components';
import styles from './TablePagination.module.scss';
import fillTemplate from '@utils/fillTemplate';
import { CypressTestsData, CypressUtilityType } from '@cypress';

export type TablePaginationProps = PropsWithChildren<{
  minimalMode?: boolean;
  page: number;
  rowsPerPage: number;
  rowCount: number;
  onGoToPage: (page: number) => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  rowsPerPageOptions?: RowsPerPageOption[];
  onRowsPerPageChange: (newRowsPerPage: number) => void;
}>;

const TablePagination: React.FC<TablePaginationProps> = ({
  page,
  minimalMode,
  rowsPerPage,
  rowCount,
  onGoToPage,
  onNextPage,
  onPreviousPage,
  onRowsPerPageChange,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
}) => {
  const i18n = useTextSnippets('table');
  const utilityTableTestDataConfig = CypressTestsData[CypressUtilityType.UTILITY_TABLE];

  const handleChangePage = useCallback(
    (newPage: number) => {
      if (newPage === page + 1) {
        onNextPage();
      } else if (newPage === page - 1) {
        onPreviousPage();
      } else {
        onGoToPage(newPage);
      }
    },
    [onGoToPage, onNextPage, page, onPreviousPage]
  );

  const handleRowsPerPageChange = useCallback(
    (option: BaseSelectItem | null) => {
      if (option) {
        onRowsPerPageChange(option.value as number);
      }
    },
    [onRowsPerPageChange]
  );

  const selectedRowsPerPage = rowsPerPageOptions.find(o => o.value === rowsPerPage);

  return (
    <div className={styles.pagination}>
      <div className={styles.paginationActions}>
        <PaginationActions count={rowCount} onChangePage={handleChangePage} page={page} rowsPerPage={rowsPerPage} />
      </div>

      {!!rowCount && (
        <CopyText
          variant="copy-4"
          color="dark"
          additionalClass="ml-auto mr-16"
          testid={utilityTableTestDataConfig.testIds.totalItemsCountLabel}
        >
          {fillTemplate(i18n.itemsTotal, { rowCount })}
        </CopyText>
      )}
      <VerticalSeparator additionalClass={styles.paginationSeparator} />
      <div className={styles.select}>
        <CopyText variant="copy-4" color="dark" additionalClass={c('min-w-60', minimalMode && 'hidden')}>
          {i18n.rowsPerPageLabel}
        </CopyText>
        <Select
          inPlace
          inputComponent={
            <TextControl
              variant="stealth"
              noInfo
              size="m"
              additionalClass={styles.paginationSelect}
              testid={utilityTableTestDataConfig.testIds.issuesPerPageInput}
            />
          }
          filterable={false}
          clearable={false}
          options={rowsPerPageOptions}
          value={selectedRowsPerPage}
          onChange={handleRowsPerPageChange}
        />
      </div>
    </div>
  );
};

export default TablePagination;
