import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import React, { FC, useCallback } from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';
import {
  InfiniteSelectControl,
  InfiniteSelectControlProps,
} from '@components/connected/InfiniteSelect/InfiniteSelectControl';
import { LogbookQueryRequest, LogbookQueryResponse, Note } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { TWO_MINUTES } from '@constants/date';
import { formatEpochDate } from '@utils/date';
import CopyText from '@components/typography/CopyText';

import styles from './VersionPicker.module.scss';

const itemToLabel = (item: Note | null) => formatEpochDate(item?.timestamp);

export type VersionPickerProps = Omit<InfiniteSelectControlProps<Note>, 'getItemsQuery' | 'itemToLabel'> & {
  entityId: string;
};

export const VersionPicker: FC<VersionPickerProps> = ({ entityId, value, ...props }) => {
  const queryClient = useQueryClient();
  const isQueryEnabled = typeof value === 'string';
  const [isValueLoading, setIsValueLoading] = React.useState(false);

  React.useEffect(
    () => {
      if (isQueryEnabled) {
        setIsValueLoading(true);
        const queryParams = {
          reasons: 'Configuration change',
          entity_ids: entityId,
          start: +value - TWO_MINUTES,
          end: +value + TWO_MINUTES,
        };

        queryClient
          .fetchQuery(['logbook', queryParams], () =>
            apiClient.get<LogbookQueryResponse>(ENDPOINTS.logbook, queryParams)
          )
          .then(({ notes }) => {
            setIsValueLoading(false);
            if (notes.length > 0) {
              props.onChange?.({ idx: 0, label: itemToLabel(notes[0]), value: notes[0] });
            }
          });
      } else if (value) {
        props.onChange?.({ idx: 0, label: itemToLabel(value.value), value: value.value });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getItemsQuery = useCallback(
    (start_index: number, end_index: number, filterStr?: string) => {
      const queryParams: LogbookQueryRequest = {
        start_index,
        end_index,
      };

      if (filterStr) {
        queryParams.general = filterStr;
      }

      return queryClient
        .fetchQuery(
          ['logbook', queryParams],
          () =>
            apiClient.get<LogbookQueryResponse>(ENDPOINTS.logbook, {
              ...queryParams,
              reasons: 'Configuration change',
              entity_ids: entityId,
              types: 'config',
            }),
          {
            ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<LogbookQueryResponse, Error>),
          }
        )
        .then(({ notes, total }) => {
          return { data: notes, total };
        });
    },
    [entityId, queryClient]
  );

  const rowContentsRenderer = useCallback((item: Note): JSX.Element => {
    return (
      <div className={styles.row}>
        <CopyText variant="copy-6">{itemToLabel(item)}</CopyText>
        <CopyText variant="copy-6" additionalClass="text-blue-gray-1 ml-auto">
          {item.author}
        </CopyText>
      </div>
    );
  }, []);

  return (
    <InfiniteSelectControl<Note>
      label="Version"
      {...props}
      value={typeof value === 'string' ? null : value}
      getItemsQuery={getItemsQuery}
      itemToLabel={itemToLabel}
      disabled={isQueryEnabled && isValueLoading}
      rowContentsRenderer={rowContentsRenderer}
    />
  );
};
