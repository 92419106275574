import React from 'react';
import c from 'classnames';

import styles from './style.module.scss';

export type TabProps = {
  active?: boolean;
  disabled?: boolean;
  title?: string;
  additionalClass?: string;
  onChange?: (e: React.MouseEvent<HTMLElement>, state: boolean) => void;
  testid?: string;
};

const Tab: React.FC<TabProps> = ({ active, disabled, additionalClass, onChange, title, children, testid }) => {
  const dataTestId = testid ?? title;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    onChange?.(e, true);
  };

  return (
    <div
      className={c(styles.tab, active && styles.active, disabled && styles.disabled, additionalClass)}
      onClick={!disabled ? handleClick : undefined}
      data-testid={dataTestId ? `Tab::${dataTestId}` : undefined}
    >
      {title ?? children}
    </div>
  );
};

export default Tab;
