import React from 'react';
import c from 'classnames';
import Icon, { IconNames } from '@components/common/Icon';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
import Card from '@components/common/Card/Card';

import styles from './ImageCard.module.scss';

type Props = {
  useIcon?: boolean;
  onClick?: () => void;
  iconName?: IconNames;
  image?: string;
  title: string;
  description: string;
  invertedColorScheme?: boolean;
};

const ImageCard: React.FC<Props> = ({ onClick, useIcon, iconName, image, title, invertedColorScheme, description }) => {
  return (
    <Card additionalClass={c(styles.imageCard, invertedColorScheme && styles.invertedColors)} onClick={onClick}>
      <div className="flex-col items-center flex-full">
        <div className={useIcon ? styles.iconContainer : styles.imageContainer}>
          {useIcon && iconName ? <Icon imageIcon name={iconName} additionalClass={styles.cardIcon} /> : null}
          {!useIcon && image ? <img src={image} alt="" /> : null}
        </div>
        <Headline
          variant="headline-5"
          additionalClass="text-center mt-24"
          color={invertedColorScheme ? 'white' : 'dark'}
        >
          {title}
        </Headline>
        <CopyText variant="copy-4" additionalClass="text-center mt-8" color={invertedColorScheme ? 'white' : 'dark'}>
          {description}
        </CopyText>
      </div>
    </Card>
  );
};

export default ImageCard;
