import { useEffect, useState } from 'react';
import c from 'classnames';
import useTextSnippets from '@services/useTextSnippets';
import { useAppSelector } from '@infrastructure/redux/store';
import { selectActiveIssueKey } from '@redux/issues';
import Headline from '@components/typography/Headline';
import { selectSidebarOpen } from '@redux/layout';
import { useBreakpoint } from '@services';

import styles from './AlertsHeader.module.scss';

export const AlertsHeader = () => {
    const [minimalMode, setMinimalMode] = useState(false);

    const {
        breakpoints: { isXl, isXxl },
    } = useBreakpoint();

    const i18n = useTextSnippets('header');

    const sidebarOpen = useAppSelector(selectSidebarOpen);
    const activeIssueKey = useAppSelector(selectActiveIssueKey);

    useEffect(() => {
        setMinimalMode(Boolean(!isXl || (!isXxl && sidebarOpen && activeIssueKey)));
    }, [isXl, isXxl, sidebarOpen, activeIssueKey]);

    return (
        <div className={c(styles.alertsHeader, minimalMode && styles.minimalHeader)}>
            <div className="flex items-center mb-16">
                <Headline variant="headline-6" additionalClass={styles.pageTitle}>
                    {i18n.alert}
                </Headline>
            </div>
        </div>
    );
};
