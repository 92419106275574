import { trimStart } from 'lodash';

type BaseHeaders = {
  Authorization?: string;
  'Content-Type': string;
};

export const baseHeaders: Readonly<BaseHeaders> = {
  'Content-Type': 'application/json; charset=utf-8',
};

const { pathname, hostname } = window.location;

export const IS_DEV = import.meta.env.MODE === 'development';
export const IS_CF_PAGES = /pages\.dev/.test(hostname);
export const IS_LOCAL = hostname === 'localhost';
export const IS_PROD = !(IS_DEV || IS_CF_PAGES || IS_LOCAL);

const API_HOST = `https://${IS_PROD ? hostname : import.meta.env.VITE_BETA_HOSTNAME}`;

/*
API will be present on the same domain as the user would access it:

quux.basen.com
quux.basen.com/v2/ -> whatever we have in the build directory
quux.basen.com/api -> reverse proxied to java api gateway

This assumption, that /api/ is on the same FQDN as the admin portal, happens to be true in all of our environments

@see: https://github.com/basen/BaseN-Platform/pull/1111#issuecomment-1090441907
*/
export const API_URL = `${API_HOST}/${trimStart(import.meta.env.VITE_API_PATHNAME, '/')}`;

// assumption here is that first part of the pathname will always be a name of a folder in which APP files are served
// from, e.g. /v2 or /build
export const BASE_NAME = IS_DEV || IS_CF_PAGES ? undefined : pathname.split('/')?.[1];

export const SUPPORT_EMAIL = 'support@basen.net';
export const SUPPORT_PHONE = '+358 9 8562 6653';
export const MAPTILER_KEY = import.meta.env.VITE_MAPTILER_KEY as string;

export const PUSH_API_URL = `${API_HOST}/${trimStart(import.meta.env.VITE_PUSH_API_PATHNAME, '/')}`;

export const STORAGE_PREFIX = 'basen';

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export const VITE_COMMIT_HASH = import.meta.env.VITE_COMMIT_HASH as string;
export const VITE_BUILD_TIME = import.meta.env.VITE_BUILD_TIME as string;

