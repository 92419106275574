import React, { FC, useMemo } from 'react';
import { IssueInfoProps } from './IssueInfo';
import c from 'classnames';
import useTextSnippets from '@services/useTextSnippets';
import { DataTable } from '@components/layout/DataTable/DataTable';
import ENDPOINTS from '@infrastructure/api/endpoints';
import moment from 'moment';
import { DEFAULT_HISTORY_LOG_INTERVAL } from '@constants/issue';
import { IssueWithDetails, selectActiveView } from '@infrastructure/redux/issues';
import { getTableColumns } from '@components/connected/IssueManager/utils';
import { useAppSelector } from '@infrastructure/redux/store';
import { DEFAULT_FILTERS, TableConfig } from '@components/common/Table';
import CopyText from '@components/typography/CopyText';
import { omit } from 'lodash';

import styles from './IssueHistory.module.scss';

export type IssueHistoryProps = IssueInfoProps;

export const IssueHistory: FC<IssueHistoryProps> = ({ issue, additionalClass }) => {
  const i18n = useTextSnippets();
  const issuesI18n = i18n.issues;
  const commonI18n = i18n.common;
  const activeView = useAppSelector(selectActiveView);

  const columns = useMemo(() => {
    return omit(getTableColumns(DEFAULT_FILTERS, i18n, activeView), ['entity']);
  }, [activeView, commonI18n, issuesI18n]);

  const params = useMemo(
    () => ({
      issue_id: issue.id,
      // how many milliseconds from now into past you want to search to
      history_period: moment.duration(...DEFAULT_HISTORY_LOG_INTERVAL.value).asMilliseconds(),
    }),
    [issue.id]
  );

  const tableConfig: Partial<TableConfig> = useMemo(
    () => ({
      selectable: false,
      sortable: false,
      hasMenu: true,
      hasToolbar: false,
      rowHeight: 'small',
      paginated: false,
    }),
    []
  );

  return (
    <div className={c(styles.issueHistory, additionalClass)}>
      <div className={styles.header}>
        <CopyText variant="copy-4" additionalClass="w-full">
          {issuesI18n.issueHistoryLog}
        </CopyText>
      </div>

      <div className="px-16 pb-16">
        <DataTable<IssueWithDetails>
          url={ENDPOINTS.issueHistory}
          params={params}
          columns={columns}
          config={tableConfig}
        />
      </div>
    </div>
  );
};
