import React, { FC, useCallback, useMemo, useRef } from 'react';
import { WidgetConfig } from '@redux/widgetPage';
import { Widget } from '@components/layout/Widget';
import Error404Page from '@components/pages/Error404Page';
import useTextSnippets from '@services/useTextSnippets';
import { WidgetPageHeader } from './components/WidgetPageHeader';
import { useDashboardContext } from '@redux/dashboards/context';
import { WidgetSidebar } from './components';
import { WidgetPageContext } from './WidgetPageContext';
import * as widgetSetups from '@components/widgets';
import { WidgetSetup } from './types';
import { useAppDispatch } from '@infrastructure/redux/store';
import { updateSubConfig } from '@redux/widgetPage';

import styles from './WidgetPage.module.scss';

export type WidgetDetailProps = {
  widgetConfig: WidgetConfig;
  isDraft?: boolean;
};

export const WidgetDetail: FC<WidgetDetailProps> = ({ widgetConfig }) => {
  const { dashboard } = useDashboardContext() ?? {};
  const widgetRef = useRef(null);
  const i18n = useTextSnippets('widgetPage');

  const dispatch = useAppDispatch();

  const { id, name, type, config } = widgetConfig;

  const setup: WidgetSetup = widgetSetups[type];
  const resolvedConfig = useMemo(() => ({ ...config, ...setup.editSubConfig }), [config, setup.editSubConfig]);

  const handleLoadError = useCallback(
    (_, source) => {
      dispatch(updateSubConfig({ source: { ...source, plotname: undefined } }));
    },
    [dispatch]
  );

  if (!dashboard) {
    return <Error404Page text={i18n.dashboardNotFound} />;
  }

  return (
    <WidgetPageContext.Provider value={{ widgetRef }}>
      <WidgetPageHeader dashboard={dashboard} widgetConfig={widgetConfig} />

      <div className={styles.widgetPage}>
        {type && <WidgetSidebar widgetConfig={widgetConfig} />}

        <div className={styles.preview}>
          <Widget
            key={type}
            ref={widgetRef}
            hideMenu
            id={id}
            type={type}
            name={name ?? i18n.newWidgetName}
            config={resolvedConfig}
            additionalClass={styles.widget}
            onLoadError={handleLoadError}
          />
        </div>
      </div>
    </WidgetPageContext.Provider>
  );
};
