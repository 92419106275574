import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadingContext } from 'react-router-loading';
import PageHeader from '@components/layout/PageHeader';
import { ConfigSearchTable } from './components/ConfigSearchTable';
import configsSlice from '@infrastructure/redux/configsPage/slice';
import { lowerCase, startCase } from 'lodash';
import Breadcrumbs from '@components/common/Breadcrumbs';
import Headline from '@components/typography/Headline';
import { useAppSelector } from '@infrastructure/redux/store';
import { selectQueryStringParams } from '@infrastructure/redux/location';
import { updateQueryString } from '@services/useHistory';
import { SearchConfigItem } from '@infrastructure/api/BaseNClient/useSearchConfigsQuery';
import { floor } from 'lodash';
import { TabProps, TabsPanel, TabsPanelProps } from '@components/common/Tabs';
import { ScriptsPageTable } from '../ScriptsPage/components/ScriptsPageTable';
import { scriptsPageSlice } from '@infrastructure/redux';
import ROUTES from '@infrastructure/routes';
import { toQueryStr } from '@utils/url';

import styles from './components/ConfigSearchTable.module.scss';

export const ConfigSearchPage = () => {
  const loadingContext = useLoadingContext();
  const { activeTabTitle } = useAppSelector(selectQueryStringParams);
  const navigate = useNavigate();

  const handleTabChange = React.useCallback((idx: number, tabProps: TabProps) => {
    updateQueryString({ activeTabTitle: tabProps.title });
  }, []);

  const tabs = useMemo(
    () => {

      return [
        {
          tabProps: {
            title: 'Config',
          },
          panel: <ConfigSearchTable onInitialLoadComplete={() => loadingContext.done()} actions={configsSlice.actions} />
        },
        {
          tabProps: {
            title: 'Scripts/Templates',
          },
          panel: <ScriptsPageTable onInitialLoadComplete={() => loadingContext.done()} actions={scriptsPageSlice.actions} />
        },
      ].filter(Boolean) as TabsPanelProps['tabs'];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlerunScript = useCallback(() => {
    navigate(ROUTES.runScript);
  }, [navigate]);

  const handleConfigDiff = useCallback(() => {
    navigate(ROUTES.configDiff);
  }, [navigate]);

  React.useEffect(() => {
    document.title = `Configuration Manager - BaseN Platform`;
  }, []);

  return (
    <div className="page-container">
      <Breadcrumbs additionalClasses={{ root: styles.breadcrumbs }} transformLabel={(label) => {
        return startCase(lowerCase(label))
      }} />
      <PageHeader
        level={2}
        title={
          <Headline variant="headline-6">
            Configuration Manager
          </Headline>
        }
        pageControls={[
          {
            id: 'configDiff',
            label: 'Compare Configs',
            ariaLabel: 'Compare Configs',
            icon: 'diff',
            onClick: handleConfigDiff,
          },
          {
            id: 'runScript',
            label: 'Run Script',
            ariaLabel: 'Run Script',
            icon: 'RunScript',
            onClick: handlerunScript,
          },
        ]}
      />
      <TabsPanel
        additionalClass={styles.tabsPanel}
        onChange={handleTabChange}
        activeTitle={activeTabTitle ?? 'Config'}
        tabs={tabs}
      />
    </div>
  );
};
