import { MultiSelect2 as MultiSelect } from '@blueprintjs/select';
import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import c from 'classnames';
import { MenuItem } from '@components/common/Menu';
import { identity } from 'lodash';
import useTextSnippets from '@services/useTextSnippets';
import { Tag } from '@components/common/Tag';
import { PopoverPosition } from '@blueprintjs/core';
import { GetItemQuery, InfiniteList, InfiniteListItemProps } from './InfiniteList';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import useDebouncedValue from '@services/useDebouncedValue';

import infiniteStyles from '@components/connected/InfiniteSelect/InfiniteList.module.scss';
import baseStyles from '@components/common/form/TagInput/TagInput.module.scss';
import styles from './InfiniteTagInput.module.scss';

export type InfiniteTagInputLegacyProps<TValue = any> = {
  placeholder?: string;
  value?: string[];
  getItemsQuery: GetItemQuery<TValue>;
  /** TValue to item, label transformer (defaults to identity) */
  itemToLabel?: (item: TValue) => string;
  /** TValue to item identifier transformer (defaults to itemToLabel) */
  itemToId?: (item: TValue) => string;
  onChange?: (value: string[]) => void;
  filterable?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: 'm' | 'l';
  matchTargetWidth?: boolean;
  additionalClasses?: {
    root?: string;
    input?: string;
  };
};

export function InfiniteTagInputLegacy<TValue = any>({
  onChange,
  value = [],
  fullWidth,
  filterable,
  matchTargetWidth = true,
  size = 'm',
  additionalClasses = {},
  getItemsQuery,
  itemToLabel = identity,
  ...props
}: InfiniteTagInputLegacyProps<TValue>) {
  const InfiniteTagInputComponent = useMemo(() => MultiSelect.ofType<TValue>(), []);

  const [filterStr, setFilterStr] = useState<string>('');
  const debouncedFilterStr = useDebouncedValue<string>(filterStr, 300);

  const itemToId = props.itemToId ?? itemToLabel;
  const i18n = useTextSnippets('select');

  const selectedItems = useRef<(TValue | string)[]>(value?.length ? [...value] : []);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    selectedItems.current = value?.length ? [...value] : [];
  }, [value, itemToId]);

  const handleItemSelect = useCallback(
    (item: TValue) => {
      selectedItems.current.push(itemToId(item));
      setIsOpen(false);
      onChange?.(selectedItems.current as string[]);
    },
    [onChange, itemToId]
  );

  const handleRemove = useCallback(
    (id: string) => {
      selectedItems.current = selectedItems.current.filter(selectedItem => selectedItem !== id);
      onChange?.(selectedItems.current as string[]);
    },
    [onChange]
  );

  const handlePopoverInteraction = useCallback((newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
  }, []);

  const handleQueryChange = (newQuery: string) => {
    setIsOpen(true);
    setFilterStr(newQuery ?? null);
  };

  const getFilteredItemsQuery = useCallback(
    (start_index: number, end_index: number, query?: string) => {
      return getItemsQuery(start_index, end_index, query).then(({ data, total }) => {
        const filteredData = data.filter((item: TValue) => !selectedItems.current.includes(itemToId(item)));
        return {
          data: filteredData,
          total: total - (data.length - filteredData.length),
        };
      });
    },
    [getItemsQuery, itemToId]
  );

  const renderTag = useCallback(
    (id: string) => (
      <Tag
        // variant={item.variant as BadgeVariant}
        // outlined={item.outlined}
        onRemove={props.disabled ? undefined : () => handleRemove(id)}
      >
        {id}
      </Tag>
    ),
    [handleRemove, props.disabled]
  );

  const renderItem = useCallback(
    ({ key, style, item }: InfiniteListItemProps<TValue>) => {
      const isLoaded = !!item;

      return (
        <div
          style={style}
          className={infiniteStyles.item}
          key={key}
          onClick={isLoaded ? () => handleItemSelect?.(item) : undefined}
        >
          {isLoaded ? itemToLabel(item) : <LoadingSpinner size="s" />}
        </div>
      );
    },
    [handleItemSelect, itemToLabel]
  );

  const renderList = () => {
    return (
      // NOTE: react-virtualized is not well suited for dynamic query updates, so we force re-create the list for each new query
      <InfiniteList<TValue>
        key={debouncedFilterStr ?? '__all'}
        getItemsQuery={getFilteredItemsQuery}
        itemToLabel={itemToLabel}
        rowRenderer={renderItem}
        filterStr={debouncedFilterStr ?? undefined}
      />
    );
  };

  const popoverProps = useMemo(() => {
    return {
      isOpen,
      minimal: true,
      fill: fullWidth,
      usePortal: false,
      position: PopoverPosition.BOTTOM_RIGHT,
      onInteraction: handlePopoverInteraction,
      popoverClassName: baseStyles.popover,
      matchTargetWidth,
    };
  }, [isOpen, handlePopoverInteraction, matchTargetWidth, fullWidth]);

  const tagInputProps = useMemo(
    () => ({
      tagProps: {
        onRemove: undefined, // this will remove default close button
      },
      className: c(additionalClasses.root, additionalClasses.input),
    }),
    [additionalClasses.input, additionalClasses.root]
  );

  return (
    // @ts-ignore - not passing in some props that are required (e.g. itemRenderer, onItemSelect)
    <InfiniteTagInputComponent
      {...props}
      fill={fullWidth}
      tagInputProps={tagInputProps}
      // @ts-ignore
      popoverProps={popoverProps}
      onQueryChange={handleQueryChange}
      className={c(baseStyles.tagInput, styles.infiniteTagInput, {
        [baseStyles.disabled]: props.disabled,
        // there's some kind of bug where the input retains disabled class even after disabld has been switched off
        // TODO: revise after upgrading to latest blueprint
        [styles.disabled]: props.disabled,
        [baseStyles.large]: size === 'l',
        [baseStyles.medium]: size === 'm',
      })}
      noResults={<MenuItem disabled text={i18n.noResults} />}
      openOnKeyDown
      resetOnSelect
      itemListRenderer={renderList}
      // types of the following properties doesn't match, so we shut up typescript to not whine about it
      // @ts-ignore
      selectedItems={selectedItems.current}
      // @ts-ignore
      tagRenderer={renderTag}
      // @ts-ignore
      onRemove={handleRemove}
      // items (e.g. options) irrelevant but required, we will use itemListRenderer instead to build our own list
      items={[]}
    />
  );
}
