import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { toSnakeCase } from '@utils/string';
import { getMinMaxAvg } from '@utils/chart';
import { MaintenanceWindow } from './useMaintenanceWindowsQuery';
import { Note } from './useLogbookQuery';
import { isUndefined } from 'lodash';

export type FilterDataRequest = {
  page?: string;
  plotname?: string;
  entityId?: string;
  alertId?: string;
  metric?: string;
  summaries?: boolean;
  start?: number;
  end?: number;
  maintenancewindow?: boolean;
  entitylogbook?: boolean;
  convert_enums?: boolean;
};

export type Summary = {
  avg: number;
  min: number;
  last: number;
  max: number;
};

export type Channel = {
  times: number[];
  values: (number | string | null)[]; // number or dash or null
  name: string;
  type: 'DOUBLE';
  summaries: Summary;
};

export type SingleMaintenanceWindow = Omit<MaintenanceWindow, 'times'> & {
  startTime: number;
  endTime: number;
  uid: string;
};

export type FilterDataResponse = {
  result: {
    channels: Channel[];
  };
  enums?: Record<string, string>;
  size: number;
  type: string;
  title: string;
  unit?: string;
  maintenancewindows?: MaintenanceWindow[];
  entity_logbook_notes?: Note[];
};

export type FilterDataError = {
  result: 'error';
  code: number;
  message: string;
};

export const useFilterDataQuery = <
  S extends FilterDataResponse = FilterDataResponse,
  F extends FilterDataError = FilterDataError
>(
  searchParams: FilterDataRequest = {},
  queryOptions: UseQueryOptions<S, F> = {}
): UseQueryResult<S, F> =>
  useQuery<S, F>(
    ['chart', searchParams],
    ({ signal }): Promise<S> =>
      apiClient
        .get<S>(
          ENDPOINTS.fetchChartData,
          {
            summaries: 'min,max,avg',
            // TODO: revise this once we reach consensus on how charts with enums should look and operate
            convert_enums: false,
            ...toSnakeCase(searchParams),
          },
          { signal }
        )
        .then(response => {
          // there are couple of problems with the response at the moment:
          // 1. it maybe an empty object - {}, if there's no data for the given period (for alert_id response it might be empty
          // response altogether)
          // 2. values in channels contain dashes - '-' for missing values, so when all values are just dashes, echarts cannot
          // figure out min/max values to draw Y axis
          // 3. summaries are missing when data is retrieved using alert_id
          // 4. when requesting data for a range into the future times/values for every channel are absent
          // 5. last values are missing from summaries

          // see 1.
          if (!response.result) {
            return {} as S;
          }

          // extract channels from the result
          const { channels, ...restOfResult } = response.result;

          // filter out channels that do not contain times or values (see 4.)
          let newChannels = channels?.filter(channel => channel.values?.length && channel.values?.length);

          // if summaries are missing, generate them manually
          const hasChannels = !!newChannels?.length;

          if (hasChannels) {
            newChannels = newChannels?.map(channel => {
              if (channel.summaries) {
                let { min, max, avg, last } = channel.summaries;
                if (isUndefined(last)) {
                  // +(undefined) === NaN, while +(null) === 0
                  last = +(channel.values?.[channel.values?.length - 1] ?? 0);
                }
                return {
                  ...channel,
                  summaries: { min, max, avg, last },
                };
              } else {
                const [min, max, avg] = getMinMaxAvg(channel.values) ?? [0, 1, 0.5];
                const last = +(channel.values?.[channel.values?.length - 1] ?? 0);
                return {
                  ...channel,
                  summaries: { min, max, avg, last },
                };
              }
            });

            return {
              ...response,
              result: {
                ...restOfResult,
                channels: newChannels,
              },
            };
          }

          return { ...response, result: restOfResult };
        }),
    {
      ...(QUERY_CONFIGS.defaultEnabled as UseQueryOptions<S, F>),
      ...queryOptions,
      // select: () => FILTERDATA_RESPONSE_FIXTURE as S,
    }
  );
