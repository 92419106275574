import palette from '@theme/palette';

export type PalettePreset =
  | keyof typeof palette.categorical
  | keyof typeof palette.sequential
  | keyof typeof palette.diverging;

export type PaletteType = keyof typeof palette;

/**
 * e.g.
 * {
 *    Blue: "sequential",
 *    BrownBlueGreen: "diverging",
 *    Finland: "categorical",
 *    Gray: "sequential",
 *    ...
 * }
 */
const presetToType = Array.from(Object.entries(palette)).reduce(
  (map, [type, presets]) => Object.keys(presets).reduce((obj, preset) => ({ ...obj, [preset]: type }), map),
  {}
) as Record<PalettePreset, PaletteType>;

export const presetToPalette = (preset: PalettePreset) => {
  const type = presetToType[preset];
  const colors = Object.values((palette as Record<PaletteType, Record<PalettePreset, any>>)[type][preset]);

  return {
    type,
    colors,
  };
};

export const getPaletteColor = (path: PalettePreset, idx: number) => {
  const colors = presetToPalette(path).colors;
  // amount of colors in palette is limited, so we need to cycle through them
  return colors[idx % colors.length];
};

export default palette as Record<PaletteType, Record<PalettePreset, any>>;
