import React from 'react';
import { DefaultMotion } from '@services';
import { PropsWithChildren } from 'react';
import c from 'classnames';

import styles from './StaticPage.module.scss';

export type StaticPageProps = PropsWithChildren<{
  additionalClass?: string;
}>;

export const StaticPage: React.FC<StaticPageProps> = ({ additionalClass, children }) => {
  return (
    <div className={c(styles.staticPage, additionalClass)}>
      <DefaultMotion key="StaticPage" className={styles.container}>
        {children}
      </DefaultMotion>
    </div>
  );
};
