import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Sort, RemoteStoredTableProps, RowHeight } from '@components/common/Table';
import { getFiltersConfig, getTableColumns } from './utils';
import useTextSnippets from '@services/useTextSnippets';
import { TablePageLayout } from '@components/layout/TablePageLayout/TablePageLayout';
import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import { SortingParams } from '@infrastructure/redux/types';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import {
  selectActiveViewActions,
  selectActiveView,
  selectQueryParams,
  selectIssuesAsArray,
  selectResolvedPagination,
  selectResolvedSorting,
  IssueViewAction,
  selectResolvedFilters,
  selectActiveIssue,
  updateRowHeight,
  selectRowHeight,
} from '@redux/issues';
import { Issue, IssueWithDetails } from '@infrastructure/redux/issues';
import { useIssuesQuery } from '@infrastructure/api/BaseNClient/useIssuesQuery';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { useIssueByIdQuery } from '@infrastructure/api/BaseNClient/useIssueByIdQuery';
import { IssueDetailsDrawer } from './components/IssueDetailsDrawer';
import { IssuesToolbar } from './components/IssuesToolbar/IssuesToolbar';
import { IssueRow } from './components/IssueRow';
import { getCustomTableActions, getDefaultTableActions } from './utils';
import { ActionModal, ActionModalProps } from './components/ActionModal';
import { IssueDownloadPopup, IssueDownloadPopupProps } from './components/IssueDownloadPopup';
import { FieldsConfig } from '@components/common/form/FormBuilder';
import { FilterableToolbar } from '@components/common/Table/components/FilterableToolbar';
import { isEmpty } from 'lodash';

export type IssueManagerProps = {
  actions: Dictionary<any>;
  showExtendedToolbar?: boolean;
  showDetailsInSidePanel?: boolean;
  onLoad?: () => void;
  entityId?: string;
};

export const IssueManager: React.FC<IssueManagerProps> = ({
  actions,
  showExtendedToolbar = true,
  showDetailsInSidePanel = true,
  onLoad,
  entityId,
}) => {
  const i18n = useTextSnippets();
  const issuesI18n = i18n.issues;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    updatePagination,
    updateSorting,
    updateFilters,
    updateSearch,
    issueSummaryLoaded,
    issuesLoaded,
    setActiveIssue,
  } = actions;

  const [modalProps, setModalProps] = useState<ActionModalProps | null>(null);
  const [downloadPopupProps, setDownloadPopupProps] = useState<IssueDownloadPopupProps | null>(null);

  const activeItem = useAppSelector(selectActiveIssue);
  const activeViewActions = useAppSelector(selectActiveViewActions);
  const items = useAppSelector(selectIssuesAsArray);
  const activeView = useAppSelector(selectActiveView);
  const filters = useAppSelector(selectResolvedFilters);
  const params = useAppSelector(selectQueryParams);
  const pagination = useAppSelector(selectResolvedPagination);
  const sorting = useAppSelector(selectResolvedSorting);
  const rowHeight = useAppSelector(selectRowHeight);

  const query = useIssuesQuery(
    {
      entityids: entityId,
      ...params,
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const activeItemQuery = useIssueByIdQuery({ issueId: activeItem?.id }, { enabled: !!activeItem });

  // react-query doesn't invoke onSuccess when data is retrieved from cache... WTF? :'/ so we have to useEffect
  useEffect(() => {
    const { data, summary, total } = query.data ?? { data: [], total: 0 };
    dispatch(updatePagination({ rowCount: total }));
    if (summary) {
      dispatch(issueSummaryLoaded(summary));
    }
    dispatch(issuesLoaded(data));
    dispatch(setActiveIssue(null));
    onLoad?.();
  }, [dispatch, issueSummaryLoaded, issuesLoaded, onLoad, query.data, setActiveIssue, updatePagination]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!activeItem && !modalProps) {
        query.refetch();
      }
    }, 30000)
    return () => clearInterval(interval)
  }, [activeItem, modalProps])

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(updatePagination({ page: newPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSortChange = useCallback(
    (sortBy: string | null, sort: Sort) => {
      dispatch(updateSorting({ sortBy: sortBy as SortingParams['sortBy'], sort }));
    },
    [dispatch, updateSorting]
  );

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      dispatch(updatePagination({ rowsPerPage: newRowsPerPage }));
    },
    [dispatch, updatePagination]
  );

  const handleRowHeightChange = useCallback(
    (newRowHeight: RowHeight) => {
      dispatch(updateRowHeight(newRowHeight));
    },
    [dispatch]
  );

  const handleNavigate = useCallback(() => {
    if (activeItem?.id) {
      navigate(ROUTES.issueById.replace(':issueId', activeItem?.id));
    }
  }, [activeItem?.id, navigate]);

  const handleClosePanel = useCallback(() => {
    dispatch(setActiveIssue(null));
  }, [dispatch, setActiveIssue]);

  const handleSearch = useCallback(
    (keyword?: string) => {
      dispatch(updateSearch(keyword));
      dispatch(updatePagination({ page: 1 }));
    },

    [dispatch, updatePagination, updateSearch]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Dictionary<any>) => {
      dispatch(updateFilters(newFilters));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch, updateFilters, updatePagination]
  );

  const handleDownloadPopupOpen = (selectedIssues: Issue[], actionTarget: HTMLElement) => {
    if (selectedIssues.length) {
      setDownloadPopupProps({
        params: { ids: selectedIssues.map(item => item.id).join(',') },
        target: actionTarget,
      });
    }
  };

  const handleDownloadPopupClose = () => {
    setDownloadPopupProps(null);
  };

  const handleModalClose = () => {
    setModalProps(null);
  };

  const handleCellClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, item: Issue) => {
      dispatch(setActiveIssue(activeItem?.id === item.id ? null : item.id));
    },
    [activeItem?.id, dispatch, setActiveIssue]
  );

  const handleAction = useCallback((action: string, selectedIssues: Issue[], viewAction?: IssueViewAction) => {
    setModalProps({
      action,
      issues: selectedIssues,
      viewAction,
    });
  }, []);

  const defaultActionHandlers: Record<string, any> = useMemo(
    () => ({
      acknowledge: (selectedIssues: Issue[]) => handleAction('acknowledge', selectedIssues),
      changeStatus: (selectedIssues: Issue[]) => handleAction('changeStatus', selectedIssues),
      changePriority: (selectedIssues: Issue[]) => handleAction('changePriority', selectedIssues),
      comment: (selectedIssues: Issue[]) => handleAction('comment', selectedIssues),
      customAction: (selectedIssues: Issue[], action?: IssueViewAction) =>
        handleAction('customAction', selectedIssues, action),
      download: handleDownloadPopupOpen,
    }),
    [handleAction]
  );

  const itemActions = useMemo(() => {
    const defaultActions = getDefaultTableActions(issuesI18n, defaultActionHandlers);
    const customActions = getCustomTableActions(defaultActionHandlers.customAction, activeViewActions);

    return defaultActions.concat(customActions);
  }, [issuesI18n, defaultActionHandlers, activeViewActions]);

  const filterConfig: FieldsConfig = useMemo(
    () => getFiltersConfig(filters, i18n, !!entityId),
    [filters, i18n, entityId]
  );

  const tableProps: RemoteStoredTableProps<Issue | IssueWithDetails> = useMemo(() => {
    const toolbarProps = {
      isLoading: query.isLoading || query.isFetching,
      showFiltersToggle: true,
      filters: filterConfig,
      keyword: filters.generalFilter,
      onSearch: handleSearch,
      onFiltersChange: handleFiltersChange,
    };

    const components: RemoteStoredTableProps<Issue | IssueWithDetails>['components'] = {
      TableRow: { component: IssueRow },
      TableToolbar: showExtendedToolbar
        ? {
          component: IssuesToolbar,
          props: {
            actions,
            ...toolbarProps,
          },
        }
        : {
          component: FilterableToolbar,
          props: toolbarProps,
        },
    };

    if (showExtendedToolbar) {
      components.TableToolbar = {
        component: IssuesToolbar,
        props: {
          actions,
          isLoading: query.isLoading || query.isFetching,
          showFiltersToggle: true,
          filters: filterConfig,
          keyword: filters.generalFilter,
          onSearch: handleSearch,
          onFiltersChange: handleFiltersChange,
        },
      };
    }

    return {
      name: `issue-manager-${activeView?.id ?? 'default'}`,
      items,
      columns: activeView ? getTableColumns(filters, i18n, activeView) : {},
      onRowsPerPageChange: handleRowsPerPageChange,
      onPageChange: handlePageChange,
      onSortChange: handleSortChange,
      onSearch: handleSearch,
      onCellClick: handleCellClick,
      onFiltersChange: handleFiltersChange,
      onRowHeightChange: handleRowHeightChange,
      highlightedId: activeItem?.id,
      itemActions,
      filterConfig,
      isLoading: query.isLoading || query.isFetching,
      config: {
        ...pagination,
        sort: sorting?.sort,
        sortBy: sorting?.sortBy,
        bordered: true,
        rowHeight,
      },
      sidebarFilters: false,
      components,
    };
  }, [
    query.isLoading,
    query.isFetching,
    filterConfig,
    filters,
    handleSearch,
    handleFiltersChange,
    showExtendedToolbar,
    actions,
    items,
    activeView,
    i18n,
    handleRowsPerPageChange,
    handlePageChange,
    handleSortChange,
    handleCellClick,
    handleRowHeightChange,
    activeItem?.id,
    itemActions,
    pagination,
    sorting?.sort,
    sorting?.sortBy,
    rowHeight,
  ]);

  const detailsPanelProps: DetailsPanelProps = useMemo(
    () => ({
      header: activeItem?.name,
      isOpen: !!activeItem,
      isLoading: activeItemQuery.isLoading || activeItemQuery.isFetching,
      onNavigate: handleNavigate,
      onClose: handleClosePanel,
      // additionalClass: styles.entityDetailsPanel,
      children: [<IssueDetailsDrawer key="issue-details" item={activeItemQuery?.data} />],
    }),
    [
      activeItem,
      activeItemQuery.isLoading,
      activeItemQuery.isFetching,
      activeItemQuery?.data,
      handleNavigate,
      handleClosePanel,
    ]
  );

  return !isEmpty(tableProps.columns) ? (
    <>
      <TablePageLayout
        tableProps={tableProps}
        detailsPanelProps={showDetailsInSidePanel ? detailsPanelProps : undefined}
      />
      {modalProps && <ActionModal isOpen {...modalProps} onClose={handleModalClose} />}
      {downloadPopupProps && <IssueDownloadPopup isOpen {...downloadPopupProps} onClose={handleDownloadPopupClose} />}
    </>
  ) : null;
};
