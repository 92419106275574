import React from 'react';
import { CodeEditor, CodeEditorHandle, CodeEditorProps } from './CodeEditor';
import { useField } from 'formik';

export type CodeEditorFieldProps = CodeEditorProps & {
  name: string;
};

export const CodeEditorField = ({ name, value, ...props }: CodeEditorFieldProps) => {
  const [field, , helpers] = useField<string>({ name, value });

  const editorRef = React.useRef<CodeEditorHandle | null>(null);

  const handleScriptChange = React.useCallback(() => {
    helpers.setValue(editorRef.current?.value ?? '');
  }, [helpers]);

  return <CodeEditor {...props} ref={editorRef} value={field.value ?? ''} onChange={handleScriptChange} />;
};
