import React, { useMemo, useCallback } from 'react';
import c from 'classnames';
import CopyText from '@components/typography/CopyText';
import {
  getStatusOptions,
  getPriorityOptions,
  UpdateIssueSuccessResponse,
  UpdateIssueParams,
  IssuePriority,
  IssueState,
  IssueWithDetails,
  Issue,
} from '@redux/issues';
import Icon from '@components/common/Icon';
import useTextSnippets from '@services/useTextSnippets';
import { Select } from '@components/common/form/Select/Select';
import { getPriorityColor, getPriorityIcon } from '@utils/issue';
import { BaseSelectItem } from '@components/common/form/Select/BaseSelect';
import { useApiClient } from '@infrastructure/api/useApiClient';
import { useQueryClient } from 'react-query';

import styles from './IssueDetailsDrawer.module.scss';

export type IssueDrawerActionBarProps = {
  issue: Issue | IssueWithDetails;
  additionalClass?: string;
};

const IssueDrawerActionBar: React.FC<IssueDrawerActionBarProps> = ({ issue, additionalClass }) => {
  const [mutationType, setMutationType] = React.useState<'' | 'status' | 'priority'>('');
  const issuesI18n = useTextSnippets('issues');
  const commonI18n = useTextSnippets('common');
  const statusOptions = getStatusOptions(commonI18n);
  const priorityOptions = getPriorityOptions(commonI18n);
  const updateIssueMutation = useApiClient<UpdateIssueSuccessResponse, UpdateIssueParams>('updateIssue');
  const popoverProps = useMemo(
    () => ({
      additionalClass: styles.dropdownSelectPopover,
    }),
    []
  );
  const queryClient = useQueryClient();

  const handleChangePriority = useCallback(
    (newPriority: BaseSelectItem | null) => {
      if (issue && newPriority) {
        setMutationType('priority');
        updateIssueMutation.mutate(
          {
            id: issue.id,
            priority: newPriority.value as IssuePriority,
          },
          {
            onSuccess: () => {
              queryClient.setQueryData(['issue', { issueId: issue.id }], {
                ...issue,
                priority: newPriority.value as IssuePriority,
              });
            },
          }
        );
      }
    },
    [issue, updateIssueMutation]
  );

  const handleChangeStatus = useCallback(
    (newStatus: BaseSelectItem | null) => {
      if (issue && newStatus) {
        setMutationType('status');
        updateIssueMutation.mutate(
          {
            id: issue.id,
            state: newStatus.value as IssueState,
          },
          {
            onSuccess: () => {
              queryClient.setQueryData(['issue', { issueId: issue.id }], {
                ...issue,
                state: newStatus.value as IssueState,
              });
            },
          }
        );
      }
    },
    [issue, updateIssueMutation]
  );

  return (
    <div className={c(styles.actionBarContainer, additionalClass, 'issue-action-bar')}>
      <div className={c(styles.actionBarCell, styles.dropdownCell)}>
        <CopyText variant="copy-3" color="dark" additionalClass={c(styles.dropdownLabel, 'inline-flex')}>
          {issuesI18n.priority}:
        </CopyText>
        <Icon size="xs" name={getPriorityIcon(issue.priority)} additionalClass={c(getPriorityColor(issue.priority))} />
        <Select
          loading={mutationType === 'priority' && updateIssueMutation.isLoading}
          inPlace
          popoverProps={popoverProps}
          additionalClasses={{ input: styles.dropdownSelect }}
          clearable={false}
          options={priorityOptions}
          value={priorityOptions.find(option => option.value === issue?.priority)}
          onChange={handleChangePriority}
        />
      </div>
      <div className={c(styles.actionBarCell, styles.dropdownCell)}>
        <CopyText variant="copy-3" color="dark" additionalClass={c(styles.dropdownLabel)}>
          {issuesI18n.state}:
        </CopyText>

        <Select
          loading={mutationType === 'status' && updateIssueMutation.isLoading}
          popoverProps={popoverProps}
          additionalClasses={{ input: styles.dropdownSelect }}
          clearable={false}
          options={statusOptions}
          value={statusOptions.find(option => option.value === issue?.state)}
          onChange={handleChangeStatus}
        />
      </div>
      {/* <div className={c(styles.actionBarCell, styles.buttonCell)}> */}
      {/*  <IconButton size="s" icon="Take" additionalClass={styles.actionButton} /> */}
      {/* </div> */}
      {/* <div className={c(styles.actionBarCell, styles.buttonCell)}> */}
      {/*  <IconButton size="s" icon="Ticket" additionalClass={styles.actionButton} /> */}
      {/* </div> */}
    </div>
  );
};

export default IssueDrawerActionBar;
