import Icon from '@components/common/Icon';
import { TableColumns } from '@components/common/Table';
import { FieldConfig, FieldsConfig } from '@components/common/form/FormBuilder';
import { Alert } from '@infrastructure/api/BaseNClient/useAlertsQuery';
import { Filters } from '@infrastructure/redux/types';
import { getPriorityColor, getPriorityIcon, timesAndValuesToStripes } from '@utils/issue';
import { toIdMap } from '@utils/misc';
import { capitalize } from '@utils/string';
import c from 'classnames';
import { AlertBar } from '@components/connected/IssueManager/components/AlertBar/AlertBar';
import CopyText from '@components/typography/CopyText';
import { AlertStatusBadge } from './AlertStatusBadge';
import { OptionsControlField, OptionsControlFieldProps } from '@components/connected/OptionsControl';
import { AlertHistory } from './AlertHistory';
import { formatValue } from '@utils/chart';
import { EntityLink } from '@components/pages/EntityPage/components/EntityLink';

export const getTableColumns = (): TableColumns<Alert> => {
  return toIdMap([
    {
      id: 'info.name',
      label: 'Name',
      width: 150,
    },
    {
      id: 'info.channel',
      label: 'Channel',
      width: 150,
    },
    {
      id: 'entity',
      label: 'Entity',
      resizable: true,
      valueRenderer({ entityid, entityname, parent_entityid, parent_entityname }: Alert) {
        return entityid && entityname ? (
          <EntityLink
            entity={{ id: entityid, name: entityname }}
            parentEntity={
              parent_entityid && parent_entityname ? { id: parent_entityid, name: parent_entityname } : undefined
            }
          />
        ) : null;
      },
    },
    {
      id: 'info.unit',
      label: 'Unit',
      width: 80,
      visible: false,
    },
    {
      type: 'custom',
      label: 'Alert Status',
      id: 'alertBar',
      width: 80,
      valueRenderer(item: Alert) {
        if (item.greenreds && item.greenreds.length && item.times?.length) {
          const stripes = timesAndValuesToStripes(item.times, item.greenreds);
          return <AlertBar cappedWidth stripes={stripes} additionalClass="justify-end" />;
        }

        return (
          <CopyText variant="copy-6" additionalClass="text-gray-3 text-center">
            No data
          </CopyText>
        );
      },
    },
    {
      id: 'info.status',
      label: 'Status',
      type: 'custom',
      width: 100,
      valueRenderer(item: Alert) {
        return <AlertStatusBadge item={item} />;
      },
    },
    {
      type: 'string',
      label: 'Priority',
      id: 'info.priority',
      width: 100,
      valueRenderer({ info }: Alert) {
        return info?.priority ? (
          <div className="flex items-center">
            <Icon
              size="s"
              name={getPriorityIcon(info.priority)}
              additionalClass={c(
                `inline-flex mr-8`,
                (info.priority && getPriorityColor(info.priority)) ?? 'text-alert-gray'
              )}
            />
            {capitalize(info.priority ?? 'none')}
          </div>
        ) : null;
      },
    },
    {
      id: 'value',
      label: 'Last Value',
      width: 80,
      valueRenderer(item: Alert) {
        if (!item.value || item.value === '-') {
          return '-';
        }

        return formatValue(+item.value, item.info?.unit, 'unit');
      },
    },
    {
      id: 'sparkline',
      label: 'History',
      type: 'custom',
      width: 100,
      valueRenderer(item: Alert) {
        // not all Alerts are associated with entities and for those that are not /filterdata endpoint responds with
        // an error - we don't want to attempt to show the history for those
        return item.info.entity ? <AlertHistory item={item} /> : null;
      },
    },
    {
      id: 'groups',
      label: 'Groups',
      visible: false,
      type: 'custom',
      valueRenderer({ groups }: Alert) {
        return groups?.length ? <div className="truncate">{groups.join(', ')}</div> : null;
      },
    },
  ]);
};

export const getFiltersConfig = (allFilters?: Filters): FieldsConfig => {
  const optionsRenderer = (config: FieldConfig) => (
    <OptionsControlField {...(config.props as OptionsControlFieldProps)} />
  );

  return [
    {
      type: 'custom',
      props: {
        name: 'customer_textid',
        label: 'Customer',
        property: 'customer_name',
        placeholder: 'All Customers',
        fullWidth: true,
        defaultValue: allFilters?.discreteFilters?.customer_textid,
      },
      renderer: optionsRenderer,
    },
    {
      type: 'toggle',
      props: {
        name: 'silent',
        label: 'Include silent',
        size: 'm',
        labelFirst: true,
        checked: allFilters?.discreteFilters?.silent,
      },
    },
  ];
};
