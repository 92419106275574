import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import { DashboardWithDetails, DashboardUpdateSuccessResponse, RawDashboard, resetDirty } from '@redux/dashboards';
import { mapDashboardToRaw } from '@redux/dashboards/data-mapper';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';
import { AppDispatch, useAppDispatch } from '@infrastructure/redux/store';

export function onUpdateDashboardSuccess(
  dispatch: AppDispatch,
  data: DashboardUpdateSuccessResponse,
  variables: DashboardWithDetails,
  context?: any
): void;
export function onUpdateDashboardSuccess(dispatch: AppDispatch) {
  dispatch(resetDirty());
}

export type UpdateDashboardMutationSettings = { noDefaultSuccess?: boolean };

export const useUpdateDashboardMutation = <
  T extends DashboardUpdateSuccessResponse = DashboardUpdateSuccessResponse,
  P extends DashboardWithDetails = DashboardWithDetails
>(
  { noDefaultSuccess }: UpdateDashboardMutationSettings = { noDefaultSuccess: false },
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> => {
  const dispatch = useAppDispatch();

  return useMutation<T, Error, P>(
    (dashboard): Promise<T> => {
      const data = { ...mapDashboardToRaw(dashboard) } as RawDashboard;

      return apiClient.put<T, RawDashboard>(ENDPOINTS.updateDashboard, {
        ...data,
        config: { ...data.config, draft: false },
      });
    },
    {
      ...mutationOptions,
      onSuccess: (...successParams) => {
        if (!noDefaultSuccess) {
          onUpdateDashboardSuccess(dispatch, ...successParams);
        }

        void mutationOptions.onSuccess?.(...successParams);
      },
    }
  );
};
