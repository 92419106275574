import { TableColumn, TableColumns, TableItemAction } from '@components/common/Table';
import { toIdMap } from '@utils/misc';
import CopyText from '@components/typography/CopyText';
import { formatEpochDate } from '@utils/date';
import { Filters } from '@infrastructure/redux/types';
import { Script } from '@infrastructure/api/BaseNClient/useScriptsQuery';
import Gravatar from '@components/common/Gravatar';

export const getTableColumns = (allFilters: Filters): TableColumns<Script> => {
  return toIdMap(
    [
      {
        id: 'name',
        label: 'Script Name',
        width: 200,
        sortable: true,
      },
      {
        id: 'last_modified',
        label: 'Last change on',
        sortable: true,
        valueRenderer: (d: Script) => {
          return formatEpochDate(d.last_modified);
        },
      },
      {
        id: 'author',
        label: 'Created by',
        type: 'custom',
        sortable: true,
        valueRenderer: ({ author }: Script) => {
          return (
            <div className="w-full hbox">
              <Gravatar size={48} username={author} email={author} additionalClass="mr-8" />
              <CopyText variant="copy-4" additionalClass="truncate">
                {author}
              </CopyText>
            </div>
          );
        },
      },
      {
        id: 'Editor',
        label: 'Edit by',
        type: 'custom',
        sortable: true,
        valueRenderer: ({ modified_by }: Script) => {
          return (
            <div className="w-full hbox">
              <Gravatar size={48} username={modified_by} email={modified_by} additionalClass="mr-8" />
              <CopyText variant="copy-4" additionalClass="truncate">
                {modified_by}
              </CopyText>
            </div>
          );
        },
      },
      {
        id: 'desciption',
        label: 'Content Preview',
        width: 300,
      },
    ].filter(Boolean) as TableColumn<Script>[]
  );
};

export const getTableActions = (
  handlers: Record<string, (selectedItems: Script[]) => void>
): TableItemAction<Script>[] => [
    {
      multiAction: false,
      label: 'Edit',
      id: 'edit',
      icon: 'Edit',
      handler: handlers.edit,
    },
    {
      multiAction: false,
      label: 'Run Script',
      id: 'runScript',
      icon: 'RunScript',
      handler: handlers.runScript,
    },
  ];
