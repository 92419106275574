import React, { forwardRef, ReactElement, RefObject } from 'react';
import Icon, { IconNames } from '@components/common/Icon';
import c from 'classnames';
import { InputType } from '@components/common/form/Input/types';
import { DefaultMotion } from '@services';
import { ControlChildProps } from '../Control/Control';

import styles from './Input.module.scss';

export type InputProps = ControlChildProps & {
  animate?: boolean;
  autoFocus?: boolean;
  type?: InputType;
  autoComplete?: string;
  placeholder?: string;
  maxLength?: number;
  icon?: IconNames | ReactElement<typeof Icon>;
  actionIcon?: IconNames | ReactElement<typeof Icon>;
  onAction?: () => void;
  additionalClass?: string; // @deprecated - use additionalClasses.root
  additionalClasses?: {
    root?: string;
    input?: string;
  };
  readOnly?: boolean;
  value?: string;
  ref?: RefObject<HTMLInputElement>;
  testid?: string;
  inputProps?: React.HTMLProps<HTMLInputElement>;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    animate = false,
    type = 'text',
    size = 'm',
    icon,
    actionIcon,
    onAction,
    disabled,
    additionalClass,
    additionalClasses = {},
    value = '',
    testid,
    inputProps = {},
    ...otherProps
  },
  ref
) {
  const hasIcon = !!icon;
  const hasActionIcon = !!actionIcon;

  const getIcon = (action?: boolean) => {
    const iconToUse = action ? actionIcon : icon;
    const iconClass = styles[action ? 'actionIcon' : 'icon'];
    const iconContent = (
      <span className={iconClass} onClick={action && !disabled && onAction ? onAction : undefined}>
        {typeof iconToUse === 'string' ? <Icon name={iconToUse} size={size === 'm' ? 's' : 'm'} /> : iconToUse}
      </span>
    );

    return animate ? (
      <DefaultMotion
        key={typeof iconToUse === 'string' ? iconToUse : 'input-icon'}
        initial={{ y: 0, opacity: 0 }}
        transition={{ delay: 0 }}
      >
        {iconContent}
      </DefaultMotion>
    ) : (
      iconContent
    );
  };

  return (
    <div
      className={c(
        styles.input,
        styles[type],
        {
          [styles.hasIcon]: hasIcon,
          [styles.hasActionIcon]: hasActionIcon,
          [styles.disabled]: disabled,
          [styles.large]: size === 'l',
          [styles.medium]: size === 'm',
        },
        additionalClasses.root,
        additionalClass
      )}
    >
      <input
        ref={ref}
        type={type}
        disabled={disabled}
        value={value}
        {...otherProps}
        {...inputProps}
        className={additionalClasses.input}
        data-testid={testid}
      />
      {icon && getIcon()}
      {actionIcon && getIcon(true)}
    </div>
  );
});
