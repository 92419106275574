import React from 'react';
import { useLoadingContext } from 'react-router-loading';
import { ConfigManager } from './ConfigManager';
import { useParams } from 'react-router-dom';
import Error404Page from '../Error404Page';

export const ConfigManagerPage = () => {
  const loadingContext = useLoadingContext();

  const { entityId } = useParams();

  if (!entityId) {
    return <Error404Page />;
  }

  return (
    <div className="page-container">
      <div className="page-body">
        <ConfigManager entityId={entityId} onInitialLoadComplete={() => loadingContext.done()} />
      </div>
    </div>
  );
};
