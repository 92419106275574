import { InfiniteListItem } from '@components/connected/InfiniteSelect/InfiniteList';
import { apiClient } from '@infrastructure/api';
import { EntitiesRequest, EntitiesResponse } from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { getEntityProperty } from '@utils/entity';
import React, { FC, useCallback } from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';
import { InfiniteSelectControl, InfiniteSelectControlProps } from '../InfiniteSelect/InfiniteSelectControl';
import CopyText from '@components/typography/CopyText';

const itemToLabel = (item: EntityDetails | null) => item?.name ?? '';

export const entityToOption = (entity: EntityDetails): InfiniteListItem<EntityDetails> => ({
  idx: -1,
  label: itemToLabel(entity),
  value: entity,
});

export type EntitiesControlProps = Omit<InfiniteSelectControlProps<EntityDetails>, 'getItemsQuery' | 'itemToLabel'> & {
  childrenOf?: string;
  parentOnly?: boolean;
  filterEmpty?: boolean;
};

export const EntitiesControl: FC<EntitiesControlProps> = ({
  childrenOf,
  parentOnly = true,
  filterEmpty = false,
  ...props
}) => {
  // TODO: add a query for a single item, to show a name in the label instead of id

  const queryClient = useQueryClient();

  const getItemsQuery = useCallback(
    (start_index: number, end_index: number, filterStr?: string) => {
      const queryParams: EntitiesRequest = {
        filter_empty_metrics: filterEmpty,
        start_index,
        end_index,
        details: true,
      };

      if (childrenOf) {
        queryParams.parent = childrenOf;
      } else if (parentOnly) {
        queryParams.only_parent = true;
      }

      if (filterStr?.length) {
        queryParams.name = `.*${filterStr}.*`;
      }

      return queryClient
        .fetchQuery(
          ['entities', queryParams],
          () => apiClient.get<EntitiesResponse<EntityDetails>>(ENDPOINTS.getEntities, queryParams),
          {
            ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<EntitiesResponse<EntityDetails>, Error>),
          }
        )
        .then(({ entities, total }) => {
          return { data: entities, total };
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [childrenOf, parentOnly, filterEmpty]
  );

  const rowContentsRenderer = useCallback((item: EntityDetails): JSX.Element => {
    const description = getEntityProperty(item, 'description');
    return (
      <div className="w-full hbox">
        <CopyText variant="copy-4">{itemToLabel(item)}</CopyText>
        {description && (
          <CopyText variant="copy-6" additionalClass="text-blue-gray-1 ml-auto">
            {description}
          </CopyText>
        )}
      </div>
    );
  }, []);

  return (
    <InfiniteSelectControl<EntityDetails>
      {...props}
      getItemsQuery={getItemsQuery}
      itemToLabel={itemToLabel}
      rowContentsRenderer={rowContentsRenderer}
    />
  );
};
