import React, { useEffect, useMemo } from 'react';
import { createIssuesSlice, DEFAULT_ISSUE_VIEW } from '@infrastructure/redux/issues';
import { Provider } from 'react-redux';
import { IssueManager, IssueManagerProps } from '@components/connected/IssueManager/IssueManager';
import { useIssueViewsQuery } from '@infrastructure/api/BaseNClient/useIssueViewsQuery';
import { createPortableStore } from '@utils/redux';

let componentCounter = 0;

export type PortableIssueManagerProps = Omit<IssueManagerProps, 'actions'> & {
  actionsPrefix?: string;
  viewId?: string;
};

export const PortableIssueManager: React.FC<PortableIssueManagerProps> = ({
  viewId = DEFAULT_ISSUE_VIEW.id,
  actionsPrefix = 'portableIssueManager',
  ...props
}) => {
  const issueViewsQuery = useIssueViewsQuery();

  const { store, actions } = useMemo(
    () => createPortableStore(createIssuesSlice(`${actionsPrefix}-${++componentCounter}`)),
    [actionsPrefix]
  );

  useEffect(() => {
    if (issueViewsQuery.data?.data.length) {
      store.dispatch(actions.issueViewsLoaded(issueViewsQuery.data?.data));
    }
  }, [issueViewsQuery.data, store, actions]);

  useEffect(() => {
    store.dispatch(actions.changeView(viewId));
  }, [actions, viewId, store]);

  return (
    // NOTE: overriding global store with local one to redirect all selectors and dispatcher
    <Provider store={store}>
      <IssueManager key={viewId} actions={actions} {...props} />
    </Provider>
  );
};
