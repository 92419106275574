import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import chroma from 'chroma-js';
import { range } from 'lodash';
import c from 'classnames';
import Tooltip from '@components/common/Tooltip';

import styles from './Palette.module.scss';

type ColorPaletteHandle = {
  colors: string[];
};

export type ColorPaletteProps = {
  title?: string;
  showTitle?: boolean;
  reverse?: boolean;
  stopColors: string[]; // hex colors, e.g. ['#55008C', '#8B572A']
  steps?: number;
  additionalClass?: string;
};

export const ColorPalette = forwardRef<ColorPaletteHandle, ColorPaletteProps>(function ColorPalette(
  { title, showTitle = true, stopColors, steps, reverse = false, additionalClass },
  ref
) {
  const palette = useMemo(() => {
    let colors = reverse ? [...stopColors].reverse() : stopColors;
    if (steps && colors.length !== steps) {
      const scale = chroma.scale(colors);
      colors = range(0, 100, 100 / steps).map(idx => scale(idx / 100).hex());
    }

    return colors;
  }, [stopColors, steps, reverse]);

  useImperativeHandle(ref, () => ({
    get colors() {
      return palette;
    },
  }));

  const isContinuous = !steps;

  const ColorPaletteComponent = (
    <div className={c(styles.colors, isContinuous ? styles.continuous : styles.discrete)}>
      {isContinuous ? (
        <div
          className={styles.rect}
          style={{ backgroundImage: `linear-gradient(to ${reverse ? 'left' : 'right'}, ${stopColors.join(',')})` }}
        />
      ) : (
        palette.map(color => <div key={color} className={styles.rect} style={{ backgroundColor: color }} />)
      )}
    </div>
  );

  return (
    <div className={c(styles.palette, additionalClass)}>
      {!showTitle && title ? (
        <Tooltip content={title}>{ColorPaletteComponent}</Tooltip>
      ) : (
        <>
          {title && <div className={styles.title}>{title}</div>}
          {ColorPaletteComponent}
        </>
      )}
    </div>
  );
});
