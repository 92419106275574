import React, { useMemo } from 'react';
import { useLoadingContext } from 'react-router-loading';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { WidgetDetail } from './WidgetDetail';
import { selectWidgetConfig, setConfig, setDefaultConfig } from '@redux/widgetPage';
import useTextSnippets from '@services/useTextSnippets';
import Error404Page from '../Error404Page';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { DashboardContext } from '@redux/dashboards/context';
import { Dashboard, RawDashboard, createSelectDashboardById, setDashboard, updateDashboard } from '@redux/dashboards';
import { useQueryClient } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { mapRawToDashboard } from '@infrastructure/redux/dashboards/data-mapper';
import { getURLQueryParams } from '@utils/url';

export const WidgetPage = () => {
  const loadingContext = useLoadingContext();
  const { dashboardId, widgetId } = useParams() as { dashboardId: string; widgetId: string };
  const i18n = useTextSnippets('widgetPage');
  const dispatch = useAppDispatch();
  const dashboard = useAppSelector(createSelectDashboardById(dashboardId ?? ''));
  const dashboardWidget = useMemo(
    () => (widgetId !== 'create' ? dashboard?.widgets.find(({ id }) => id === widgetId) : null),
    [dashboard, widgetId]
  );
  const widgetConfig = useAppSelector(selectWidgetConfig);
  const queryClient = useQueryClient();
  const { type } = getURLQueryParams();

  useEffect(() => {
    document.title = `Widget ${widgetConfig?.name ? `"${widgetConfig?.name}"` : ''} - BaseN Platform`;
  }, [widgetConfig?.name]);

  useEffect(
    () => {
      if (!dashboard?.widgets) {
        // we retrieve the dashboard with widget data only if it's not already loaded, because all widget operations
        // down the line are based on the assumption that the dashboard is already loaded into the redux store
        // NOTE: this is quite a dumb logic, but that's how it was developed originally
        const params = { dashboardId, widget_data: true };
        queryClient
          .fetchQuery(['dashboard', params], () => apiClient.get<RawDashboard>(ENDPOINTS.dashboardById, params))
          .then((dashboard: RawDashboard): Dashboard => mapRawToDashboard(dashboard))
          .then((dashboard: Dashboard) => {
            dispatch(setDashboard(dashboard));
            loadingContext.done();
          });
      } else {
        loadingContext.done();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (dashboardWidget) {
      const { id, config } = dashboardWidget;
      dispatch(setConfig({ ...config, id }));
    } else if (!widgetConfig) {
      dispatch(
        setDefaultConfig({
          type: type ?? 'timeseries',
          subType: 'line',
          isDraft: true,
        })
      );
    }
  }, [dashboardWidget, dispatch]);

  const contextValue = useMemo(() => ({ dashboard }), [dashboard]);

  if (!dashboard?.widgets) {
    return null;
  }

  if (dashboard?.widgets && !dashboardWidget && widgetId !== 'create') {
    return <Error404Page text={i18n.widgetNotFound} />;
  }

  return widgetConfig ? (
    <DashboardContext.Provider value={contextValue}>
      <WidgetDetail widgetConfig={widgetConfig} />
    </DashboardContext.Provider>
  ) : null;
};
