import { Box } from '@components/common/Box';
import { Form, TextField } from '@components/common/form';
import { WizardStepProps } from '@components/common/form/Wizard';
import CopyText from '@components/typography/CopyText';
import { isEmpty } from 'lodash';
import { z } from 'zod';

export const VariablesStep = ({
  name,
  title,
  validationSchema,
  initialValues: variables = {},
  ...props
}: WizardStepProps) => {
  return (
    <Form {...props} validationSchema={validationSchema} initialValues={variables} enableReinitialize>
      <Box additionalClass="overflow-y-auto">
        {!isEmpty(variables) ? (
          Object.keys(variables).map(key => <TextField key={key} name={key} label={`$${key}`} />)
        ) : (
          <CopyText>No variables in this script</CopyText>
        )}
      </Box>
    </Form>
  );
};

VariablesStep.defaultProps = {
  initialValues: {},
  validationSchema: z.record(z.string()),
};
