import Popover, { PopoverProps } from '@components/common/Popover';
import { AlertChannel, AlertConfig } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';
import { AlertChannelsIndicator } from './AlertChannelsIndicator';
import { getAlertChannelStats, getIconColor } from '@utils/alert';
import CopyText from '@components/typography/CopyText';
import Accordion from '@components/common/Accordion/Accordion';
import { Panel } from '@components/common/Panel';
import React from 'react';
import { AlertBar } from '@components/connected/IssueManager/components/AlertBar/AlertBar';
import { StripeColor, timesAndValuesToStripes } from '@utils/issue';
import { presetToPalette } from '@components/common/form/PalettePicker/paletteUtils';
import c from 'classnames';

import styles from './AlertsPopup.module.scss';
import alertStyles from '@components/connected/IssueManager/components/AlertBar/Alert.module.scss';

const { colors: palette } = presetToPalette('BaseN');

type AlertsPopupProps = PopoverProps & {
  alerts: AlertConfig[];
};

const ChannelRow = ({
  idx,
  channel,
  stripeColor,
}: {
  idx: number;
  channel: AlertChannel;
  stripeColor: StripeColor;
}) => {
  const { hasSilent } = getAlertChannelStats([channel]);

  return (
    <div className={styles.channel}>
      <div className={c(styles.horizontalStripe, alertStyles[`${stripeColor}-bg`])}></div>
      <AlertChannelsIndicator channels={[channel]} showBadge />
      <span
        className={styles.bullet}
        style={{
          backgroundColor: (palette[idx % palette.length] ?? 'transparent') as string,
        }}
      />
      <CopyText variant="copy-8">{channel.info.channel}</CopyText>
      <div className={styles.alertBar}>
        {channel.times && channel.greenreds ? (
          <AlertBar
            stripes={timesAndValuesToStripes(channel.times, channel.greenreds)}
            variant={hasSilent ? 'silent' : 'default'}
          />
        ) : null}
      </div>
    </div>
  );
};

const AlertConfigRow = ({ alert }: { alert: AlertConfig }) => {
  if (!alert.channels?.length) {
    return null;
  }

  const { total, alerting, isAlerting } = getAlertChannelStats(alert.channels);
  const channelStats = [<span key="total">{total} total</span>];

  if (isAlerting) {
    channelStats.push(
      <span key="separator" className="mx-4">
        |
      </span>
    );
    channelStats.push(
      <span key="alerting" className="text-red-base">
        {alerting} alerting
      </span>
    );
  }

  return (
    <div className="w-full gap-8 hbox">
      <AlertChannelsIndicator channels={alert.channels} showBadge />
      <CopyText variant="copy-6">{alert.info.name}</CopyText>
      {total > 0 && (
        <CopyText variant="copy-6" additionalClass="ml-auto">
          <span className="mr-8 text-blue-gray-base">Channels</span>
          {channelStats}
        </CopyText>
      )}
    </div>
  );
};

export const AlertsAccordion = ({
  alerts,
  isNotInteractive = false,
}: {
  alerts: AlertConfig[];
  isNotInteractive?: boolean;
}) => {
  const [activeRows, setActiveRows] = React.useState<boolean[]>(new Array(alerts.length).fill(isNotInteractive));

  return (
    <Accordion onStateChange={setActiveRows} variant="control">
      {alerts
        // there shouldn't be any, but technically they can, so if there are, ignore them
        .filter(alert => !!alert.channels?.length)
        .map((alert, idx) => {
          const stats = getAlertChannelStats(alert.channels);
          const color = getIconColor(stats);

          return (
            <Panel
              key={alert.id}
              title={<AlertConfigRow alert={alert} />}
              active={!!activeRows?.[idx]}
              additionalClasses={{ root: c(styles.panel, isNotInteractive && styles.isNotInteractive) }}
              collapsible={!isNotInteractive}
            >
              <div className={styles.channels}>
                <div className={c(styles.verticalStripe, alertStyles[`${color}-bg`])}></div>
                {alert.channels?.map((channel, idx) => (
                  <ChannelRow key={idx} idx={idx} channel={channel} stripeColor={color} />
                ))}
              </div>
            </Panel>
          );
        })}
    </Accordion>
  );
};

export const AlertsPopup = ({ alerts, ...props }: AlertsPopupProps) => {
  return (
    <Popover
      {...props}
      content={<AlertsAccordion alerts={alerts} />}
      noArrows
      usePortal
      hasBackdrop
      additionalClass={styles.popup}
    />
  );
};
