import { WizardStepProps } from '@components/common/form/Wizard';
import { Form, TextField } from '@components/common/form';
import { CodeEditorField } from '@components/common/CodeEditor/CodeEditorField';
import { addEditScriptSchema } from '../../AddEditScriptWizard.schema';

import styles from './AddScriptStep.module.scss';

export const AddScriptStep = ({ name, title, validationSchema, initialValues, ...props }: WizardStepProps) => {
  return (
    <Form
      {...props}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      additionalClass="h-full vbox"
    >
      <TextField name="name" label="Name" placeholder="Add a script name" required additionalClass="w-full" />

      <TextField
        name="description"
        label="Description"
        placeholder="Brief summary"
        additionalClass="w-full"
        multiline
      />

      <CodeEditorField name="script" autoFocus additionalClass={styles.codeEditor} />
    </Form>
  );
};

AddScriptStep.defaultProps = {
  validationSchema: addEditScriptSchema,
  initialValues: {
    name: '',
    script: '',
    description: '',
  },
};
