import { useValidatedQuery } from '@services/useValidatedQuery';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { z } from 'zod';
import ENDPOINTS from '../endpoints';
import QUERY_CONFIGS from '../query-configs';

const sessionEntrySchema = z.object({
  content: z.string(),
  timestamp: z.number(),
  direction: z.enum(['in', 'out', 'session']),
});

export const sessionByIdResponseSchema = z.object({
  result: z.string(),
  total: z.number(),
  data: z.array(sessionEntrySchema),
});

export type SessionByIdRequest = {
  entityId: string;
  user: string;
  sessionId: string;
  filter?: string;
  start?: number;
  end?: number;
  rawdata?: boolean;
};

export type SessionEntry = z.infer<typeof sessionEntrySchema>;

export type SessionDetailsEntry = {
  sessionId: string;
  entityId: string;
  user: string;
  timestamp: number;
  endtimestamp?: number;
  log: string;
};

export type SessionByIdResponse = z.infer<typeof sessionByIdResponseSchema>;

export const useSessionByIdQuery = <T extends SessionByIdResponse = SessionByIdResponse>(
  searchParams: SessionByIdRequest,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.sessionById, searchParams, sessionByIdResponseSchema, {
    ...(QUERY_CONFIGS.cacheDisabled as UseQueryOptions<T, Error>),
    ...queryOptions,
  });
};
