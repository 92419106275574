import React, { useCallback } from 'react';
import useTextSnippets from '@services/useTextSnippets';

import styles from './Support.module.scss';
import { DefaultMotion } from '@services';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
import fillTemplate from '@utils/fillTemplate';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from '@config';
import Card from '@components/common/Card/Card';

const Support: React.FC = () => {
  const supportI18n = useTextSnippets('support');

  const getMailLink = useCallback(
    i18nString => (
      <span
        dangerouslySetInnerHTML={{
          __html: fillTemplate(i18nString, {
            supportMail: `<a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`,
          }),
        }}
      />
    ),
    []
  );

  const getPhoneLink = useCallback(
    i18nString => (
      <span
        dangerouslySetInnerHTML={{
          __html: fillTemplate(i18nString, {
            supportPhone: `<a class="whitespace-nowrap" href="tel:${SUPPORT_PHONE}">${SUPPORT_PHONE}</a>`,
          }),
        }}
      />
    ),
    []
  );

  return (
    <div className={styles.supportPage}>
      <DefaultMotion key="support" className={styles.supportContainer}>
        <Headline centered variant="headline-5" color="dark" additionalClass="mt-48">
          {supportI18n.title}
        </Headline>
        <CopyText variant="copy-4" color="dark" additionalClass="mt-24">
          {getMailLink(supportI18n.normalRequests)}
        </CopyText>
        <Headline variant="headline-6" color="dark" additionalClass="mt-16">
          {supportI18n.emergencySupport}
        </Headline>
        <CopyText variant="copy-4" color="dark" additionalClass="mt-8">
          {supportI18n.emergencySupportOne}
        </CopyText>
        <CopyText variant="copy-4" color="dark" additionalClass="mt-16">
          {getMailLink(supportI18n.emergencySupportTwo)}
        </CopyText>
        <CopyText variant="copy-4" color="dark" additionalClass="mt-16 text-center tablet:text-left">
          {getPhoneLink(supportI18n.emergencySupportThree)}
        </CopyText>
        <div className={styles.supportCardContainer}>
          <Card centered additionalClass="col-span-2 tablet:col-span-1">
            <div className="flex-col items-center flex-full">
              <CopyText variant="copy-4" color="dark">
                {supportI18n.supportRequests}
              </CopyText>
              <CopyText variant="copy-1" color="ocean">
                {getMailLink(supportI18n.supportEmail)}
              </CopyText>
            </div>
          </Card>
          <Card centered additionalClass="col-span-2 tablet:col-span-1">
            <div className="flex-col items-center flex-full">
              <CopyText variant="copy-4" color="dark">
                {supportI18n.emergencySupportCall}
              </CopyText>
              <CopyText variant="copy-1" color="ocean">
                {getPhoneLink(supportI18n.supportPhone)}
              </CopyText>
            </div>
          </Card>
        </div>
      </DefaultMotion>
    </div>
  );
};

export default Support;
