import React, { ReactElement } from 'react';
import c from 'classnames';
import Icon, { IconNames } from '@components/common/Icon';
import Button from '@components/common/Button';

import styles from './Panel.module.scss';

export interface BasePanelProps {
  additionalClasses?: { root?: string; header?: string; title?: string; content?: string; active?: string };
  icon?: IconNames | ReactElement;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  active?: boolean;
  variant?: 'fill' | 'stealth' | 'menu' | 'control' | 'group';
  leftAlignToggler?: boolean;
  showBottomToggler?: boolean;
  collapsible?: boolean;
}

export interface ControlledPanelProps extends BasePanelProps {
  idx: number;
  onChange?: (e: React.MouseEvent<HTMLElement>, active: boolean, idx: number) => void;
}

export interface PanelProps extends BasePanelProps {
  idx?: number;
  onChange?: (e: React.MouseEvent<HTMLElement>, active: boolean, idx?: number) => void;
}

const Panel: React.FC<PanelProps> = ({
  additionalClasses = {},
  idx,
  icon,
  title,
  description,
  active = false,
  variant = 'stealth',
  leftAlignToggler = false,
  collapsible = true,
  showBottomToggler = false,
  onChange,
  children,
}) => {
  const hasDescription = !!description;

  const handleToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onChange?.(e, !active, idx);
  };

  return (
    <div
      className={c(
        styles.panel,
        styles[variant],
        active && c(styles.active, additionalClasses.active),
        additionalClasses.root
      )}
    >
      <div
        className={c(
          styles.header,
          collapsible && styles.collapsible,
          leftAlignToggler && styles.leftAlignToggler,
          additionalClasses.header,
          'header'
        )}
        onClick={collapsible ? handleToggle : undefined}
      >
        <div className={styles.text}>
          <div className={c(styles.title, additionalClasses.title, 'title')}>
            {icon && typeof icon === 'string' ? <Icon name={icon} /> : icon}
            {title}
          </div>
          {hasDescription && <div className={styles.description}>{description}</div>}
        </div>

        {collapsible && <Icon name="ChevronOpen" additionalClass={styles.toggler} />}
      </div>
      <div className={c(styles.body, 'body')}>
        <div className={c(styles.content, additionalClasses.content)}>{children}</div>

        {showBottomToggler && (
          <Button size="s" additionalClass="mx-auto block" onClick={handleToggle} variant="stealth">
            collapse
            <Icon name={active ? 'Collapse' : 'Expand'} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Panel;
