import React, { useCallback, FC, useMemo, useState } from 'react';
import c from 'classnames';
import getSchema, { ActionSchema } from './Action.schema';
import useTextSnippets from '@services/useTextSnippets';
import Icon from '@components/common/Icon';
import { useAppDispatch } from '@infrastructure/redux/store';
import {
  Issue,
  IssueActionMutationParams,
  getStatusOptions,
  getPriorityOptions,
  STATUS_OPTIONS,
  IssueDefaultActionTypes,
  updateIssueFields,
  IssueViewAction,
  IssueWithDetails,
  PRIORITY_OPTIONS,
} from '@redux/issues';
import Modal from '@components/common/Modal';
import useForm, { FormProvider } from '@components/common/form/useForm';
import { useMutation } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericOkApiResponse } from '@infrastructure/api/types';
import { SelectField, TextField } from '@components/common/form';
import CopyText from '@components/typography/CopyText';
import fillTemplate from '@utils/fillTemplate';
import { Classes } from '@blueprintjs/core';
import Button from '@components/common/Button';
import ActionModalTitle from './ActionModalTitle';
import { getPriorityColor, getPriorityIcon } from '@utils/issue';
import { setNotification } from '@redux/notifications';

import styles from './ActionModal.module.scss';

export type ActionModalProps = {
  isOpen?: boolean;
  action?: IssueDefaultActionTypes | string | null;
  issues: Array<Issue | IssueWithDetails>;
  viewAction?: IssueViewAction;
  onClose?: () => void;
};

export const ActionModal: FC<ActionModalProps> = ({ isOpen = false, action, issues, viewAction, onClose }) => {
  const dispatch = useAppDispatch();
  const [updatedCount, setUpdatedCount] = useState(0);
  const issuesI18n = useTextSnippets('issues');
  const commonI18n = useTextSnippets('common');
  const commentI18n = useTextSnippets('comment');
  const statusOptions = getStatusOptions(commonI18n);
  const priorityOptions = getPriorityOptions(commonI18n);

  const relevantData = useMemo(() => {
    if (action === 'changeStatus') {
      return STATUS_OPTIONS.OPEN;
    } else if (action === 'changePriority') {
      return PRIORITY_OPTIONS.LOW;
    } else {
      return viewAction?.script;
    }
  }, [action, viewAction?.script]);

  const updateIssue = useCallback(
    ({ comment, ...data }) =>
      apiClient
        .put<Issue, { id: string } & Partial<Issue>>(ENDPOINTS.updateIssue, {
          ...data,
          // don't send comment if it's empty (see UX-500)
          comment: comment || undefined,
        })
        .then(() => {
          setUpdatedCount(previousCount => previousCount + 1);

          dispatch(updateIssueFields(data));
        }),
    [dispatch]
  );

  const runScriptWithIssues = useCallback(
    data =>
      apiClient
        .post<GenericOkApiResponse, { issue_ids: string[]; page_name: string }>(ENDPOINTS.issueActionCall, data)
        .then(() => {
          setUpdatedCount(previousCount => previousCount + 1);
        }),
    []
  );

  const actionForm = useForm<ActionSchema>({
    validationSchema: getSchema(action === 'comment', commentI18n),
    initialValues: { comment: '', issueIds: issues.map(({ id }) => id), relevantData },
    enableReinitialize: true,
    validate: values => {
      const errors: Partial<ActionSchema> = {};

      if (action === 'comment' && !values.comment) {
        errors.comment = commentI18n.commentIsRequired;
      }

      return errors;
    },
    onSubmit: (values: ActionSchema) => {
      setUpdatedCount(0);

      const { comment, issueIds = [], relevantData } = values;

      let updatePromise;

      switch (action) {
        case 'comment': {
          updatePromise = Promise.allSettled(issueIds.map(id => updateIssue({ id, comment })));
          break;
        }
        case 'changePriority': {
          updatePromise = Promise.allSettled(issueIds.map(id => updateIssue({ id, comment, priority: relevantData })));
          break;
        }
        case 'changeStatus': {
          updatePromise = Promise.allSettled(issueIds.map(id => updateIssue({ id, comment, state: relevantData })));
          break;
        }
        case 'acknowledge': {
          updatePromise = Promise.allSettled(
            issueIds.map(id => updateIssue({ id, comment, state: STATUS_OPTIONS.OPEN }))
          );
          break;
        }
        case 'customAction': {
          updatePromise = runScriptWithIssues({ issue_ids: issueIds, page_name: relevantData })
            .then(() => {
              // only add comments if script ran successfully
              return Promise.allSettled(
                comment ? issueIds.map(id => updateIssue({ id, comment })) : [Promise.resolve()]
              );
            })
            .catch(() => {
              return Promise.allSettled([Promise.reject()]);
            });
          break;
        }
        default:
          return Promise.resolve();
      }

      return updatePromise.then((results = []) => {
        const failedCount = results.filter(result => result.status === 'rejected').length;

        if (failedCount === 0) {
          dispatch(
            setNotification({
              type: 'success',
              title: fillTemplate(issuesI18n.allIssuesUpdatedSuccess, { number: issueIds.length }),
            })
          );
        } else if (failedCount === issueIds.length || (action === 'customAction' && failedCount > 0)) {
          dispatch(
            setNotification({
              type: 'error',
              title: issuesI18n.allIssuesUpdatedError,
            })
          );
        } else {
          dispatch(
            setNotification({
              type: 'warning',
              title: fillTemplate(issuesI18n.allIssuesUpdatedPartialSuccess, {
                number: issueIds.length,
                updated: issueIds.length - failedCount,
              }),
            })
          );
        }

        onClose?.();
        setUpdatedCount(0);
      });
    },
  });

  const issueActionMutation = useMutation(
    `issue-action-${action!}`,
    (params: IssueActionMutationParams) =>
      apiClient.post<GenericOkApiResponse, IssueActionMutationParams>(ENDPOINTS.issueActionCall, params),
    {
      onSuccess: () => {
        actionForm.resetForm();
        onClose?.();
      },
    }
  );

  const getActionModalTitle = useCallback(
    (issues: Issue[]) => {
      switch (action) {
        case 'acknowledge': {
          const alreadyAcknowledgedIssues = (issues || []).filter(issue => issue.state !== STATUS_OPTIONS.NEW);

          return (
            <ActionModalTitle
              warning={
                alreadyAcknowledgedIssues.length > 0
                  ? fillTemplate(issuesI18n.alreadyAcknowledgedIssues, {
                      number: alreadyAcknowledgedIssues.length,
                      selected: issues.length,
                    })
                  : undefined
              }
              action={action}
              issues={issues}
            />
          );
        }
        case 'customAction':
        case 'changeStatus':
        case 'changePriority':
        case 'comment': {
          return (
            <ActionModalTitle
              label={action === 'customAction' ? viewAction?.label : undefined}
              action={action}
              issues={issues}
            />
          );
        }

        default:
          return '';
      }
    },
    [action, issuesI18n.alreadyAcknowledgedIssues, viewAction?.label]
  );

  const acceptLabel = useMemo(() => {
    if (actionForm.isSubmitting) {
      return (
        <span>
          {issuesI18n[action as keyof typeof issuesI18n]}&nbsp;{updatedCount}&nbsp;/&nbsp;{issues.length}
        </span>
      );
    }

    if (action === 'customAction') {
      return issuesI18n.customActionAcceptLabel;
    }

    return issuesI18n[action as keyof typeof issuesI18n];
  }, [actionForm.isSubmitting, issuesI18n, action, updatedCount, issues.length]);

  const actionContent = useMemo(() => {
    switch (action) {
      case 'changeStatus':
      case 'changePriority':
        return (
          <SelectField
            inPlace
            popoverProps={{
              additionalClass: styles.dropdownSelectPopover,
            }}
            additionalClasses={{ input: styles.dropdownSelect }}
            label={
              <span className="flex items-center p-0 m-0">
                {issuesI18n[`${action}Label` as keyof typeof issuesI18n]}
                {action === 'changePriority' && (
                  <Icon
                    size="s"
                    name={getPriorityIcon(actionForm.values.relevantData as Issue['priority'])}
                    additionalClass={c(
                      'ml-8',
                      getPriorityColor(actionForm.values.relevantData as Issue['priority']) || 'text-alert-gray'
                    )}
                  />
                )}
              </span>
            }
            name="relevantData"
            clearable={false}
            options={action === 'changePriority' ? priorityOptions : statusOptions}
          />
        );
      case 'acknowledge':
      case 'comment':
      case 'customAction':
      default:
        return null;
    }
  }, [actionForm.values.relevantData, action, issuesI18n, priorityOptions, statusOptions]);

  const actionFooterContent = useMemo(() => {
    switch (action) {
      case 'acknowledge':
        return (
          <div className="flex items-center mx-auto mobileLandscape:inline-flex mobileLandscape:mr-auto mobileLandscape:ml-0">
            <CopyText variant="copy-4" additionalClass="inline-flex mx-auto mobileLandscape:mx-0">
              {issuesI18n.statusChangeFromNewToOpen}
            </CopyText>
          </div>
        );
      default:
        return null;
    }
  }, [action, issuesI18n.statusChangeFromNewToOpen]);

  const actionModalFooter = useMemo(() => {
    const loading = actionForm.isSubmitting || issueActionMutation.isLoading;

    return (
      <div className={c(Classes.DIALOG_FOOTER_ACTIONS, styles.actionFooter)}>
        {actionFooterContent}
        <Button
          additionalClass={
            action === 'comment'
              ? 'max-w-fit-content'
              : 'max-w-fit-content mx-auto mobileLandscape:mx-0 mt-8 mobileLandscape:mt-0'
          }
          size="s"
          variant="fillBlueDark"
          onClick={actionForm.submitForm}
          ariaLabel="Accept Button"
          loading={loading}
          disabled={loading}
        >
          {acceptLabel}
        </Button>
      </div>
    );
  }, [
    acceptLabel,
    actionForm.isSubmitting,
    actionForm.submitForm,
    action,
    actionFooterContent,
    issueActionMutation.isLoading,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      usePortal
      customFooter={actionModalFooter}
      title={<div className="truncate copy-1">{getActionModalTitle(issues as Issue[])}</div>}
      onClose={onClose}
    >
      <FormProvider form={actionForm}>
        <div className="flex flex-col align-start">
          {actionContent}
          <TextField
            size="l"
            placeholder={action === 'comment' ? commentI18n.commentPlaceholder : commentI18n.optionalCommentPlaceholder}
            label={action === 'comment' ? commentI18n.comment : commentI18n.optionalComment}
            type="text"
            name="comment"
            autoComplete="off"
          />
        </div>
      </FormProvider>
    </Modal>
  );
};
