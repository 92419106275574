import { createSelector } from 'reselect';
import { AppState } from '@infrastructure/redux/store';
import { FeatureFlags, SettingsState } from './types';

const failSafeDefaults: FeatureFlags = {
  allowPasswordRecovery: false,
};
export const selectSettings = (state: AppState): SettingsState => state.settings;
export const selectAppSettings = createSelector(selectSettings, settings => settings.app);

export const selectFeatureFlags = createSelector(
  selectAppSettings,
  settings => (settings && settings.features) || failSafeDefaults
);

export const selectReleaseEmbedLoaded = createSelector(selectSettings, settings => settings.isReleaseEmbedLoaded);
