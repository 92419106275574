import { ALERT_COLORS } from '@constants/issue';
import c from 'classnames';
import { AlertColor } from '@infrastructure/api/BaseNClient/useAlertsQuery';
import React from 'react';
import Icon from '@components/common/Icon';

import styles from './StatusFilter.module.scss';
import alertStyles from '@components/connected/IssueManager/components/AlertBar/Alert.module.scss';

export type StatusFilterResponse = Partial<Record<AlertColor, boolean>>;

type StatusFilterProps = {
  selected?: StatusFilterResponse | AlertColor[];
  onToggle?: (color: StatusFilterResponse) => void;
  isDisabled?: boolean;
  additionalClass?: string;
};

export const StatusFilter = ({ selected = {}, onToggle, isDisabled, additionalClass }: StatusFilterProps) => {
  const normalizedSelected: StatusFilterProps['selected'] = React.useMemo(() => {
    return Array.isArray(selected) ? selected.reduce((acc, color) => ({ ...acc, [color]: true }), {}) : selected;
  }, [selected]);

  return (
    <div className={c(styles.statusFilter, additionalClass)}>
      <div className={styles.label}>
        <label>Alert Status</label>
      </div>
      <div className={c(styles.colorFilters, isDisabled && styles.isDisabled)}>
        {ALERT_COLORS.map(color => (
          <div
            className={c(styles.colorFilter, alertStyles[`${color}-bg`], normalizedSelected[color] && styles.selected)}
            key={color}
            onClick={() => onToggle?.({ ...normalizedSelected, [color]: !normalizedSelected[color] })}
          >
            {normalizedSelected[color] && <Icon name="InternalCheckmark" size="s" additionalClass="text-white" />}
          </div>
        ))}
      </div>
    </div>
  );
};
