import { CSSProperties } from 'react';
import {
  Sort,
  RowHeight,
  TableColumn,
  TableColumns,
  ColumnWidths,
  TableItemAction,
  ColumnVisibilities,
  ColumnsOrder,
} from './types';
import { MIN_CELL_WIDTH } from '@components/common/Table/config';
import { cloneDeep, constant, debounce, isUndefined, mergeWith, range } from 'lodash';
import { toIdMap } from '@utils/misc';
import { IconNames } from '../Icon';

import styles from './Table.module.scss';
import { StoredColumn } from '@infrastructure/api/BaseNClient/useTableViewQuery';

export function getColumnsProp<T>(
  columns: TableColumns<T>,
  prop: keyof TableColumn<T>,
  predicate: (col: TableColumn<T>) => boolean = constant(true),
  setter: (col: TableColumn<T>, prop: keyof TableColumn<T>, idx: number) => any = (col, prop) => col[prop]
) {
  return Object.values(columns)
    .filter(predicate)
    .map((col, idx) => ({
      id: col.id,
      [prop]: setter(col, prop, idx),
    }));
}

export function getColumnsVisibility<T>(columns: TableColumns<T>, visibleOnly = false): ColumnVisibilities {
  return Object.values(columns).reduce((obj, col) => {
    const isVisible = col.visible === undefined || col.visible;
    return (visibleOnly && isVisible) || !visibleOnly
      ? {
          ...obj,
          [col.id]: isVisible,
        }
      : obj;
  }, {});
}

export function getStorableColumnProps<T>(columns: TableColumns<T>) {
  return Object.values(columns).reduce((obj, { id: colId, order }, idx) => {
    const { visible, width } = columns[colId];

    const props: StoredColumn = {
      order: isUndefined(order) ? idx + 1 : order,
      visible: visible ?? true,
    };

    if (!isUndefined(width)) {
      props.width = width;
    }

    return {
      ...obj,
      [colId]: props,
    };
  }, {});
}

export function getOrderedColumns<T>(columns: TableColumn<T>[], enforceOrder = false): TableColumn<T>[] {
  return columns
    .map((col, idx) => ({
      ...col,
      order: col.order === undefined || enforceOrder ? idx + 1 : col.order,
    }))
    .sort((a, b) => a.order - b.order);
}

export function getColumnsOrder<T>(columns: TableColumns<T>): ColumnsOrder {
  return getOrderedColumns(Object.values(columns)).reduce((obj, { id, order }) => ({ ...obj, [id]: order }), {});
}

export function getColumnsWidths<T>(columns: TableColumns<T>, noUndefined = true): ColumnWidths {
  return Object.values(columns).reduce(
    (obj, col) => (!isUndefined(col.width) || !noUndefined ? { ...obj, [col.id]: col.width } : obj),
    {}
  );
}

export type FinalCellPropsParams<T> = {
  column: TableColumn<T>;
  resizable?: boolean;
  active?: boolean;
  bordered?: boolean;
  header?: boolean;
  style?: CSSProperties;
  selected?: boolean;
  rowHeight?: RowHeight;
};

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

export function descendingComparator<T extends Record<string, any>>(
  a: T,
  b: T,
  orderBy: string | null = null
): -1 | 0 | 1 {
  if (!orderBy) {
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  // TODO date type and other things

  return 0;
}

export function getComparator<T extends Record<string, any>>(
  sort: Sort,
  sortBy: string | null = null
): (a: T, b: T) => number {
  return sort === 'desc' ? (a, b) => descendingComparator(a, b, sortBy) : (a, b) => -descendingComparator(a, b, sortBy);
}

export function setResizeListeners(
  div: HTMLElement,
  table: HTMLTableElement,
  onWidthChange?: (newWidth: number) => void
) {
  let curCol: HTMLTableColElement | undefined;
  let pageX: number;
  let curColWidth: number;
  let newWidth: number;
  let tableWidth: number;

  const mouseDownListener = (e: MouseEvent) => {
    if (!curCol) {
      tableWidth = table.offsetWidth;
      // @ts-ignore
      curCol = e.target?.parentElement;
      pageX = e.pageX;
      curColWidth = newWidth = curCol!.offsetWidth;
    }
  };

  const mouseMoveListener = (e: MouseEvent) => {
    if (curCol) {
      const diffX = e.pageX - pageX!;

      if (curColWidth! + diffX > MIN_CELL_WIDTH) {
        curCol.style.width = `${curColWidth! + diffX}px`;
        table.style.width = `${tableWidth! + diffX}px`;
        newWidth = curColWidth! + diffX;
      }

      // if for some reason mouseUp doesn't trigger by itself (e.g. cursor moved out of the boundaries of the document or
      // window has lost a focus), call it manually once mouseMove events stop coming
      callMouseUpListener();
    }
  };

  const mouseUpListener = () => {
    if (curCol) {
      curCol = undefined;
      onWidthChange?.(newWidth);
    }
  };

  const callMouseUpListener = debounce(mouseUpListener, 500);

  div.addEventListener('mousedown', mouseDownListener);
  document.addEventListener('mousemove', mouseMoveListener);
  document.addEventListener('mouseup', mouseUpListener);

  return () => {
    div.removeEventListener('mousedown', mouseDownListener);
    document.removeEventListener('mousemove', mouseMoveListener);
    document.removeEventListener('mouseup', mouseUpListener);
  };
}

export function createResizeHandle() {
  const resizeHandle = document.createElement('div');

  resizeHandle.className = styles.resizeHandle;

  return resizeHandle;
}

export const generateColumns = (num: number) =>
  toIdMap(
    range(0, num).map(idx => ({
      id: `col_${idx}`,
      label: `col ${idx + 1}`,
      width: 100,
      type: 'string',
    }))
  ) as TableColumns<any>;

export const generateRows = (numOfRows: number, numOfColumns: number) =>
  range(0, numOfRows).map(rowIdx =>
    range(0, numOfColumns).reduce((obj, colIdx) => ({ ...obj, [`col_${colIdx}`]: `Row ${rowIdx}-${colIdx}` }), {})
  );

export const getRowHeightIcon = (forRowHeight: RowHeight): IconNames => {
  switch (forRowHeight) {
    case 'small':
      return 'Row32Px';
    case 'medium':
      return 'Row40Px';
    case 'large':
      return 'Row48Px';
    default:
      return 'Row40Px';
  }
};

export const isActionDisabled = (action: TableItemAction<any>, selectedItems: any[]) => {
  return (
    (action.multiAction === true && selectedItems.length <= 1) ||
    (action.multiAction === false && selectedItems.length > 1) ||
    (typeof action.disabled === 'function' && action.disabled(selectedItems)) ||
    action.disabled === true
  );
};

export const mergeWithPropMap = <T extends object>(
  columns: TableColumns<T> | {},
  propMap: Record<string, any>,
  propName: keyof TableColumn<T>
) => {
  return cloneDeep(mergeWith(columns, propMap, (objValue, srcValue) => ({ ...objValue, [propName]: srcValue })));
};
