import React, { useRef } from 'react';
import { Form, TextField, SubmitButton, FormSubmitOptions, FormikProps } from '@components/common/form';
import CopyText from '@components/typography/CopyText';
import Headline from '@components/typography/Headline';
import BaseNLogo from '@components/common/BaseNLogo';
import useTextSnippets from '@services/useTextSnippets';
import Notification from '@components/common/Notification';
import noop from '@utils/noop';

import PasswordComplexityBar from './PasswordComplexityBar';
import getSchema, { NewPasswordSchema } from './NewPassword.schema';
import styles from './style.module.scss';

export type NewPasswordFormProps = {
  submitError?: boolean;
  clearError?: () => void;
  initialValues?: NewPasswordSchema;
  onSubmit: (values: NewPasswordSchema, helpers: FormSubmitOptions<NewPasswordSchema>) => void | Promise<any>;
};

function NewPasswordForm({
  clearError = noop,
  submitError,
  initialValues = { newPassword: '', repeatNewPassword: '' },
  onSubmit,
}: NewPasswordFormProps) {
  const forgotPasswordSnippets = useTextSnippets('forgotPassword');
  const validationSchema = getSchema(forgotPasswordSnippets);
  const newPasswordFormRef = useRef<FormikProps<NewPasswordSchema>>(null);
  const {
    newPasswordTitle,
    codeVerificationDone,
    newPasswordLabel,
    repeatNewPasswordLabel,
    resetPasswordAndLogin,
    passwordFailed,
  } = forgotPasswordSnippets;
  const { trademarks } = useTextSnippets('copyRight');

  return (
    <div className={styles.forgotPasswordForm}>
      <div className="vbox">
        <BaseNLogo additionalClass={styles.logo} />
      </div>
      {submitError && (
        <div className={styles.errorContainer}>
          <Notification type="error" title={passwordFailed} onClose={clearError} />
        </div>
      )}
      <Form
        innerRef={newPasswordFormRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        additionalClass={styles.form}
      >
        <Headline additionalClass="mb-16 text-black" variant="headline-5">
          {newPasswordTitle}
        </Headline>
        <CopyText additionalClass="mb-16 text-black block" variant="copy-4">
          {codeVerificationDone}
        </CopyText>
        <TextField
          animate
          size="l"
          label={newPasswordLabel}
          type="password"
          noInfo
          name="newPassword"
          autoComplete="new-password"
        />
        <PasswordComplexityBar />
        <TextField
          animate
          size="l"
          label={repeatNewPasswordLabel}
          type="password"
          name="repeatNewPassword"
          autoComplete="new-password"
        />
        <SubmitButton size="l" variant="fillBlueDark" fullWidth additionalClass="mb-56">
          {resetPasswordAndLogin}
        </SubmitButton>
        <CopyText variant="copy-6" additionalClass={styles.trademarks}>
          {trademarks}
        </CopyText>
      </Form>
    </div>
  );
}

export default NewPasswordForm;
