import { z } from 'zod';
import ENDPOINTS from '../endpoints';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '../query-configs';
import { useValidatedQuery } from '@services/useValidatedQuery';

const searchConfigItemSchema = z.object({
  line: z.number().optional(), //! this potentially can return multiple lines, so gotta be string, but is number in the API
  name: z.string(),
  id: z.string(),
  configuration_time: z.number(),
  time: z.number(), // timestamp
  config: z.string(),
  config_user: z.string(),
});

const searchConfigsResponseSchema = z.object({
  result: z.array(searchConfigItemSchema),
  // TODO: make this required once the API is fixed
  total: z.number().optional(),
});

export type SearchConfigsRequest = {
  start_index?: number;
  end_index?: number;
  // this is "sql contains" match (with% appended and prepended), not java regexp search. use % for
  // 'wildcard match any number of chars' and _ for 'wildcard match single char'
  searchterm?: string;
};

export type SearchConfigItem = z.infer<typeof searchConfigItemSchema>;
export type SearchConfigsResponse = z.infer<typeof searchConfigsResponseSchema>;

export const useSearchConfigsQuery = <T extends SearchConfigsResponse = SearchConfigsResponse>(
  params: SearchConfigsRequest = {},
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.configSearch, params, searchConfigsResponseSchema, {
    ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
    ...queryOptions,
  });
};
