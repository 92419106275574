export const downloadFile = (data: string | File, fileName: string) => {
  const isFile = data instanceof File;
  const a = document.createElement('a');
  a.download = fileName;
  a.href = isFile ? URL.createObjectURL(data) : data;
  a.click();
  if (isFile) {
    URL.revokeObjectURL(a.href);
  }
};

export const loadFileAs = (
  file: Blob | File,
  type: 'text' | 'arraybuffer' | 'dataurl'
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();

    fr.onload = () => {
      resolve(fr.result);
    };

    fr.onerror = () => {
      const err = fr.error;
      fr.abort();
      reject(err);
    };

    switch (type) {
      case 'arraybuffer':
        fr.readAsArrayBuffer(file);
        break;
      case 'dataurl':
        fr.readAsDataURL(file);
        break;
      case 'text':
      default:
        fr.readAsText(file);
        break;
    }
  });
};
