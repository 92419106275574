import React, { useCallback, useState } from 'react';
import c from 'classnames';
import { TextControlUncontrolled } from '@components/common/form/Input/TextControl';
import Icon from '@components/common/Icon';
import useTextSnippets from '@services/useTextSnippets';
import { debounce } from 'lodash';
import { TableToolbarSearchProps } from '@components/common/Table/components/TableToolbar/components/TableToolbarSearch';
import { FiltersPopup } from '@components/common/Table/components/TableFilters/FiltersPopup';
import { FieldsConfig } from '@components/common/form/FormBuilder';
import IconButton from '@components/common/IconButton';

import styles from '@components/common/Table/components/TableToolbar/components/TableToolbarSearch.module.scss';

type FilterableToolbarSearchProps = TableToolbarSearchProps & {
  hasActiveFilters?: boolean;
  showFiltersToggle?: boolean;
  keyword?: string;
  placeHolder?: string;
  filters?: FieldsConfig;
  isLoading?: boolean;
  onFiltersViewToggle?: () => void;
  onFiltersChange?: (values: Dictionary<any>) => void;
};

export const FilterableToolbarSearch: React.FC<FilterableToolbarSearchProps> = ({
  minimal,
  additionalClass,
  filters,
  placeHolder,
  keyword,
  hasActiveFilters = false,
  showFiltersToggle = true,
  isLoading = false,
  onSearch,
  onFiltersViewToggle,
  onFiltersChange,
}) => {
  const i18n = useTextSnippets('common');

  const [popupTarget, setPopupTarget] = useState<DOMRect | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(e => {
      onSearch?.(e.target.value);
    }, 500),
    [onSearch]
  );

  const handleFiltersToggle = useCallback((e?: React.MouseEvent) => {
    setPopupTarget(e?.currentTarget.getBoundingClientRect() ?? null);
  }, []);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();

        handleSearch(e);
      }
    },
    [handleSearch]
  );

  return (
    <div className={c(styles.toolbarSearch, additionalClass)}>
      <TextControlUncontrolled
        // this is an uncontrolled debounced input so we cannot really feed a value directly
        key={!!keyword ? 'with-keyword' : 'without-keyword'}
        actionIcon={!!keyword ? <Icon name="Close" size="s" onClick={() => onSearch?.('')} /> : undefined}
        noInfo
        icon={<Icon name="Search" size="s" />}
        additionalClasses={{
          control: c(styles.filter, minimal && styles.minimal),
          textControl: styles.filterTextControl,
        }}
        onChange={handleSearch}
        onKeyDown={handleKeyDown}
        placeholder={placeHolder ?? i18n.search}
        defaultValue={keyword}
      />
      {showFiltersToggle && filters && (
        <IconButton
          icon={hasActiveFilters ? 'FilterActive' : 'Filter'}
          additionalClass={c(hasActiveFilters ? styles.activeFilters : undefined, 'mr-8')}
          size="s"
          title="Filters"
          onClick={handleFiltersToggle}
        />
      )}
      {!!popupTarget && filters && (
        <FiltersPopup
          isOpen
          isLoading={isLoading}
          filters={filters}
          target={popupTarget}
          onClose={() => setPopupTarget(null)}
          onSubmit={onFiltersChange}
          onFiltersViewToggle={onFiltersViewToggle}
        />
      )}
    </div>
  );
};
