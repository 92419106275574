import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { IssueManagerWidgetSubConfig, selectWidgetSubConfig, updateSubConfig } from '@redux/widgetPage';
import React, { useMemo } from 'react';
import { FieldsConfig, FormBuilder } from '@components/common/form/FormBuilder';
import useSchema from './OptionsTab.schema';
import { DEFAULT_ISSUE_MANAGER_WIDGET_CONFIG } from '@redux/widgetPage/constants';

export const OptionsTab = () => {
  const dispatch = useAppDispatch();

  const options =
    (useAppSelector(selectWidgetSubConfig) as IssueManagerWidgetSubConfig) ?? DEFAULT_ISSUE_MANAGER_WIDGET_CONFIG;

  const schema = useSchema();

  const fields: FieldsConfig = useMemo(
    () => [
      {
        type: 'group',
        title: 'Components',
        fields: [
          {
            type: 'toggle',
            props: {
              label: 'Show Extended Toolbar',
              name: 'showExtendedToolbar',
            },
          },
          {
            type: 'toggle',
            props: {
              label: 'Show Details in Side Panel',
              name: 'showDetailsInSidePanel',
            },
          },
        ],
      },
    ],
    []
  );

  const handleChange = (newOptions: IssueManagerWidgetSubConfig) => {
    dispatch(updateSubConfig(newOptions));
  };

  return (
    <FormBuilder<IssueManagerWidgetSubConfig>
      fields={fields}
      initialValues={options}
      onChange={handleChange}
      validationSchema={schema}
      validateOnChange
      enableReinitialize
      additionalClass="m-10"
    />
  );
};
