import Icon, { IconNames, IconProps } from '@components/common/Icon';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { getEntityProperty } from '@utils/entity';
import { titleCase } from '@utils/string';

export type EntityIconProps = Partial<IconProps> & {
  entity: EntityDetails;
};

export const EntityIcon = ({ entity, ...props }: EntityIconProps) => {
  const type = getEntityProperty(entity, 'entity_icon') ?? 'default-device';

  return <Icon {...props} name={titleCase(type) as IconNames} />;
};
