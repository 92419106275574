import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericOkApiResponse } from '../types';
import useTextSnippets from '@services/useTextSnippets';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { EntityCustomer } from '@redux/inventoryPage';

type GetEntityCustomersResponse = GenericOkApiResponse & {
  data: EntityCustomer[];
};

export const useEntityCustomersQuery = <T extends GetEntityCustomersResponse = GetEntityCustomersResponse>(
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  const i18n = useTextSnippets('widgetPage');
  const { getFailureHandler } = useGenericMutationHandlers();

  return useQuery<T, Error>(
    ['entity_customers'],
    (): Promise<T> => apiClient.get<T>(ENDPOINTS.getOptions.replace(':property', 'customer')),
    {
      ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
      onError: getFailureHandler(undefined, i18n.cannotRetrieveEntityCustomers),
      ...queryOptions,
    }
  );
};
