import Button, { ButtonProps } from '@components/common/Button';
import Icon from '@components/common/Icon';
import { BaseSelectItem } from '@components/common/form/Select';
import c from 'classnames';
import React from 'react';
import Popover from '@components/common/Popover';
import { Position } from '@blueprintjs/core';
import { Classes } from '@blueprintjs/select';
import { Menu, MenuItem } from '@components/common/Menu';

import styles from './MultiChoiceButton.module.scss';
import CopyText from '@components/typography/CopyText';

export type MultiChoiceButtonItem = BaseSelectItem & {
  description?: string;
};

export type MultiChoiceButtonProps = React.PropsWithChildren<
  Omit<ButtonProps, 'children' | 'onClick'> & {
    value?: MultiChoiceButtonItem;
    options: MultiChoiceButtonItem[];
    onClick?: (value: MultiChoiceButtonItem) => void;
    onChange?: (value: MultiChoiceButtonItem) => void;
    clickOnSelect?: boolean;
    additionalClass?: string;
  }
>;

export const MultiChoiceButton = ({
  children,
  options,
  onClick,
  onChange,
  disabled,
  clickOnSelect = true,
  additionalClass,
  ...props
}: MultiChoiceButtonProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleSelect = React.useCallback(
    (option: MultiChoiceButtonItem) => {
      onChange?.(option);
      setIsOpen(false);

      if (clickOnSelect) {
        onClick?.(option);
      }
    },
    [onChange, clickOnSelect, onClick]
  );

  const value = props.value || options?.[0];

  if (!value) {
    return null;
  }

  const hasMultipleOptions = options.length > 1;

  return (
    <div
      className={c(
        styles.multiChoiceButton,
        isOpen && !disabled && styles.isOpen,
        hasMultipleOptions && styles.hasMultipleOptions,
        additionalClass
      )}
    >
      <Button disabled={disabled} {...props} additionalClass={styles.mainButton} onClick={() => onClick?.(value)}>
        {children}
      </Button>

      {hasMultipleOptions && (
        <Popover
          minimal
          fill
          full
          enforceFocus
          isOpen={isOpen && !disabled}
          position={Position.BOTTOM_LEFT}
          additionalClass={c(Classes.SELECT_POPOVER, styles.popover)}
          content={
            <Menu className={styles.menu}>
              {options.map((option, idx) => {
                const isSelected = option.value === value.value;
                return (
                  <MenuItem
                    additionalClass={styles.menuItem}
                    key={idx}
                    text={
                      <div className={styles.menuItemContent}>
                        {isSelected && <Icon name="InternalCheckmark" additionalClass={styles.checkedIcon} />}
                        <CopyText variant="copy-3" additionalClass="w-full">
                          {option.label}
                        </CopyText>
                        <CopyText variant="copy-8" additionalClass="w-full">
                          {option.description}
                        </CopyText>
                      </div>
                    }
                    onClick={() => handleSelect(option)}
                  />
                );
              })}
            </Menu>
          }
        >
          <Button
            disabled={disabled}
            {...props}
            additionalClass={styles.toggler}
            onClick={!disabled ? handleToggle : undefined}
          >
            <Icon name="ChevronOpen" additionalClass={styles.togglerIcon} size="s" />
          </Button>
        </Popover>
      )}
    </div>
  );
};
