import React, { FC } from 'react';
import { useField } from 'formik';
import { ToggleButtonGroup, ToggleButton, ToggleButtonGroupProps } from '@components/common/form/ToggleButton';
import { BaseSelectItem } from '@components/common/form/Select';
import CopyText from '@components/typography/CopyText';
import c from 'classnames';

export type ToggleButtonFieldProps = {
  name: string;
  label?: string;
  size?: ToggleButtonGroupProps['size'];
  options: BaseSelectItem<string>[];
  additionalClass?: string;
};

export const ToggleButtonField: FC<ToggleButtonFieldProps> = ({ label, name, options, additionalClass, ...props }) => {
  const [field, , helpers] = useField<string>({ name });

  const handleStateChange = (newStates: boolean[]) => {
    helpers.setValue(newStates.map((_, idx) => options[idx].value).filter((key, idx) => !!newStates[idx])[0] ?? null);
  };

  return (
    <div className={c('items-start w-full vbox', additionalClass)}>
      {label && (
        <CopyText variant="copy-3" additionalClass="ml-4 mb-4">
          {label}
        </CopyText>
      )}
      <ToggleButtonGroup {...props} exclusive onStateChange={handleStateChange}>
        {options.map(({ label, value }: BaseSelectItem<string>) => (
          <ToggleButton key={value} active={value === field.value}>
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};
