import React from 'react';
import { store } from '@infrastructure/redux/store';
import 'core-js/features/promise';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AnimatePresence } from 'framer-motion';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ErrorBoundary from '@services/errors/ErrorBoundary';
import logger from '@infrastructure/logging/logger';
import Error500Page from '@components/pages/Error500Page';
import { ModalProvider } from '@services/useModal';
import { isBrowserCheck } from '@utils/util';
import smoothScroll from 'smoothscroll-polyfill';
import configureBlueprint from '@services/blueprint';
import { Provider } from 'react-redux';

import 'focus-visible';

import '@styles/scss/all.scss';
import { Main } from '@components/Main';

if (isBrowserCheck()) {
  smoothScroll.polyfill();
}

configureBlueprint();

const getErrorPage = () => <Error500Page />;

const App = () => {
  const queryClientRef = React.useRef<QueryClient>(
    new QueryClient({
      defaultOptions: {
        queries: {
          // https://github.com/TanStack/query/issues/520#issuecomment-763362361
          refetchOnWindowFocus: false,
        },
      },
    })
  );

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClientRef.current}>
        <ErrorBoundary
          FallbackComponent={getErrorPage}
          onError={error => logger.error(error, { origin: 'AppErrorBoundary' })}
        >
          <DndProvider backend={HTML5Backend}>
            <ModalProvider>
              <AnimatePresence exitBeforeEnter>
                <Main />
              </AnimatePresence>
            </ModalProvider>
          </DndProvider>
        </ErrorBoundary>
        <ReactQueryDevtools position="bottom-right" />
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
