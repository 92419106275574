import { InfoGrid, InfoGridItem } from '@components/layout/InfoGrid/InfoGrid';
import { SingleMaintenanceWindow } from '@infrastructure/api/BaseNClient/useFilterDataQuery';
import React from 'react';

export type MaintenancePopupProps = {
  mw: SingleMaintenanceWindow;
};

export const MaintenancePopup: React.FC<MaintenancePopupProps> = ({ mw }) => {
  const { name, id, reason, description } = mw;
  return (
    <InfoGrid
      additionalClass="m-0"
      data={
        [
          name && {
            label: 'Name',
            value: name,
          },
          {
            label: '#',
            value: id,
          },
          reason && {
            label: 'Reason',
            value: reason,
          },
          description && {
            label: 'Description',
            value: description,
          },
        ].filter(Boolean) as InfoGridItem[]
      }
    />
  );
};
