import React from 'react';
import DashboardDetail from './DashboardDetail';
import { useLoadingContext } from 'react-router-loading';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { Dashboard, RawDashboard, createSelectDashboardById, setDashboard } from '@redux/dashboards';
import { setDocumentTitle } from '@utils/dom';
import { useQueryClient } from 'react-query';
import { apiClient } from '@infrastructure/api';
import { mapRawToDashboard } from '@infrastructure/redux/dashboards/data-mapper';
import ENDPOINTS from '@infrastructure/api/endpoints';

export const DashboardPage = () => {
  const loadingContext = useLoadingContext();

  const { dashboardId } = useParams() as { dashboardId: string };
  const dashboard = useAppSelector(createSelectDashboardById(dashboardId ?? ''));
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (dashboard?.name) {
      setDocumentTitle(`${dashboard.name}`);
    }
  }, [dashboard?.name]);

  useEffect(
    () => {
      if (!dashboard?.widgets) {
        // we retrieve the dashboard with widget data only if it's not already loaded, because all widget operations
        // down the line are based on the assumption that the dashboard is already loaded into the redux store
        // NOTE: this is quite a dumb logic, but that's how it was developed originally
        const params = { dashboardId, widget_data: true };
        queryClient
          .fetchQuery(['dashboard', params], () => apiClient.get<RawDashboard>(ENDPOINTS.dashboardById, params))
          .then((dashboard: RawDashboard): Dashboard => mapRawToDashboard(dashboard))
          .then((dashboard: Dashboard) => {
            dispatch(setDashboard(dashboard));
            loadingContext.done();
          });
      } else {
        loadingContext.done();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return dashboard?.widgets ? <DashboardDetail dashboard={dashboard} /> : null;
};
