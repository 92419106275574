import React from 'react';
import Icon from '../Icon';

interface BaseNLogoProps {
  additionalClass?: string;
}

const BaseNLogo: React.FC<BaseNLogoProps> = ({ additionalClass = undefined }) => (
  <Icon additionalClass={additionalClass} name="BaseNLogo" />
);

export default BaseNLogo;
