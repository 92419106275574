import { flowRight } from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useDashboardCreateCommand } from './commands/useDashboardCreateCommand';
import { useWidgetDuplicateCommand } from './commands/useWidgetDuplicateCommand';
import { useWidgetRemoveCommand } from './commands/useWidgetRemoveCommand';
import withConfirmation, { WithConfirmation } from './withConfirmation';
import withFavoritesMenu, { WithFavoritesMenu } from './withFavoritesMenu';

type WrappedProps = PropsWithChildren<any>;

export type WithCommands = WithConfirmation &
  WithFavoritesMenu & {
    widgetRemoveCommand: ReturnType<typeof useWidgetRemoveCommand>;
    widgetDuplicateCommand: ReturnType<typeof useWidgetDuplicateCommand>;
    dashboardCreateCommand: ReturnType<typeof useDashboardCreateCommand>;
  };

function withCommands(WrappedComponent: React.ComponentType<WrappedProps & WithCommands>) {
  const ComponentWithCommands = ({
    confirmOperation,
    ...props
  }: WrappedProps & WithConfirmation & WithFavoritesMenu) => {
    const commands = {
      widgetRemoveCommand: useWidgetRemoveCommand(confirmOperation),
      widgetDuplicateCommand: useWidgetDuplicateCommand(confirmOperation),
      dashboardCreateCommand: useDashboardCreateCommand(),
      confirmOperation,
    };

    return <WrappedComponent {...props} {...commands} />;
  };

  ComponentWithCommands.displayName = `withCommands(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return flowRight([withConfirmation, withFavoritesMenu])(ComponentWithCommands);
}

export default withCommands;
