import { WidgetHandle } from '@infrastructure/redux/widgetPage';
import { createContext, RefObject, useContext } from 'react';

export type WidgetPageContextType = {
  widgetRef?: RefObject<WidgetHandle | null>;
};

export const WidgetPageContext = createContext<WidgetPageContextType>({});

export function useWidgetPageContext(): WidgetPageContextType {
  return useContext(WidgetPageContext);
}
