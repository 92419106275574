import React, { FC, useMemo, useEffect } from 'react';
import Error404Page from '@components/pages/Error404Page';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import ROUTES from '@infrastructure/routes';
import { BreadcrumbProps } from '@components/common/Breadcrumbs/Breadcrumb';
import useTextSnippets from '@services/useTextSnippets';
import { useParams } from 'react-router-dom';
import { useLoadingContext } from 'react-router-loading';
import { TabProps, TabsPanel, TabsPanelProps } from '@components/common/Tabs';
import Breadcrumbs from '@components/common/Breadcrumbs';
import { getEntityProperty } from '@utils/entity';
import { EntityStatusDot } from './components/EntityStatusDot';
import { EntityIcon } from './components/EntityIcon';
import { ConfigTab, LogbookTab, OverviewTab, InterfacesTab, IssuesTab, DevicesTab, MetricsTab } from './components';
import { updateQueryString } from '@services/useHistory';
import { useAppSelector } from '@infrastructure/redux/store';
import { selectQueryStringParams } from '@infrastructure/redux/location';
import { TerminalButton } from './components/TerminalButton';
import { setDocumentTitle } from '@utils/dom';
// import { RunSDUButton } from './components/RunSDUButton';

import styles from './EntityPage.module.scss';

const getEntityUrl = (id: string) => ROUTES.entityById.replace(':entityId', id);

export const EntityPage: FC = () => {
  const i18n = useTextSnippets('inventory');

  const { entityId } = useParams();
  const { activeTabTitle } = useAppSelector(selectQueryStringParams);

  const entity = useEntityQuery({ entityId, alerts: true, include_maintenance: true });
  const parentEntity = useEntityQuery({ entityId: entity.data?.parent }, { enabled: !!entity.data?.parent });
  const loadingContext = useLoadingContext();

  React.useEffect(() => {
    const entityName = [];

    if (entity?.data?.name) {
      entityName.unshift(entity.data.name);
    }

    if (parentEntity?.data?.name) {
      entityName.unshift(parentEntity.data.name);
    }

    setDocumentTitle(`${entityName.length ? `${entityName.join(' > ')}` : ''}`);
  }, [entity.data?.name, parentEntity?.data?.name]);

  useEffect(() => {
    if (entity.data) {
      loadingContext.done();
    }
  }, [entity?.data, loadingContext]);

  const handleTabChange = React.useCallback((idx: number, tabProps: TabProps) => {
    updateQueryString({ activeTabTitle: tabProps.title });
  }, []);

  const breadcrumbsProps = useMemo(
    () => ({
      items: entity.data
        ? ([
            { label: i18n.inventory, href: '/inventory' },
            entity.data.parent ? { label: parentEntity.data?.name, href: getEntityUrl(entity.data.parent) } : false,
            {
              label: entity.data.name,
              href: getEntityUrl(entity.data.id),
              current: true,
            },
          ].filter(Boolean) as BreadcrumbProps[])
        : [],
    }),
    [entity.data, parentEntity.data, i18n]
  );

  const tabs = useMemo(
    () => {
      const shouldShowSubEntities = (entity.data?.children?.length ?? 0) > 0;

      return [
        {
          tabProps: {
            title: 'Overview',
          },
          panel: entity.data ? <OverviewTab entity={entity.data} /> : null,
        },
        {
          tabProps: {
            title: 'Metrics',
          },
          panel: entity.data ? <MetricsTab entity={entity.data} /> : null,
        },
        shouldShowSubEntities && {
          tabProps: {
            title: 'Interfaces',
          },
          panel: entity.data ? <InterfacesTab entity={entity.data} /> : null,
        },
        shouldShowSubEntities && {
          tabProps: {
            title: 'Devices',
          },
          panel: entity.data ? <DevicesTab entity={entity.data} /> : null,
        },
        {
          tabProps: {
            title: 'Issues',
          },
          panel: entity.data ? <IssuesTab entity={entity.data} /> : null,
        },
        {
          tabProps: {
            title: 'Logbook',
          },
          panel: entity.data ? <LogbookTab entity={entity.data} /> : null,
        },
        !entity.data?.parent && {
          tabProps: {
            title: 'Config',
          },
          panel: entity.data ? <ConfigTab entity={entity.data} /> : null,
        },
      ].filter(Boolean) as TabsPanelProps['tabs'];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entity.data]
  );

  if (!entity.isLoading && !entity.data) {
    return <Error404Page text="Entity not found" />;
  }

  return !entity.isLoading ? (
    <div className={styles.entityPage}>
      <div className={styles.header}>
        <Breadcrumbs {...breadcrumbsProps} additionalClasses={{ root: styles.breadcrumbs }} />

        <Headline variant="headline-6" additionalClass="mb-8 flex items-center gap-8">
          <EntityIcon entity={entity.data} />
          {entity.data?.name}
          <EntityStatusDot entity={entity.data} />

          {/* // TODO uncomment once API catches up
          {!!entity.data.discoverable && <RunSDUButton entityId={entity.data.id} />} */}

          {!!entity.data.terminal_allowed && (
            <TerminalButton entity={entity.data} additionalClass="ml-auto">
              Open Terminal
            </TerminalButton>
          )}
        </Headline>
        <CopyText variant="copy-6">{getEntityProperty(entity.data, 'description')}</CopyText>
      </div>

      <TabsPanel
        additionalClass={styles.tabsPanel}
        onChange={handleTabChange}
        activeTitle={activeTabTitle ?? 'Overview'}
        tabs={tabs}
      />
    </div>
  ) : null;
};
