import React, {
  FC,
  useState,
  useEffect,
  useRef,
  RefObject,
  FocusEvent,
  ChangeEventHandler,
  KeyboardEventHandler,
} from 'react';
import c from 'classnames';
import { FieldHelperProps } from 'formik/dist/types';
import noop from '@utils/noop';
import Control, { ControlProps } from '../Control/Control';
import { Input, InputProps } from '../Input/Input';

import styles from './CodeControl.module.scss';

const BACKSPACE_KEY = 'Backspace';
const LEFT_ARROW_KEY = 'ArrowLeft';
const UP_ARROW_KEY = 'ArrowUp';
const RIGHT_ARROW_KEY = 'ArrowRight';
const DOWN_ARROW_KEY = 'ArrowDown';
const E_KEY = 'Enter';

type InputModes = 'text' | 'decimal' | 'search' | 'numeric' | 'tel' | 'email' | 'url';

export type CodeControlProps = ControlProps<string, (value: string) => void> &
  InputProps & {
    formHelpers?: FieldHelperProps<string>;
    fields: number;
    type: 'text' | 'number' | 'password' | 'tel';
    autoFocus?: boolean;
    forceUppercase?: boolean;
    filterKeyCodes?: string[];
    filterChars?: string[];
    filterCharsIsWhitelist?: boolean;
    pattern?: string;
    maxLength?: number;
    inputMode?: InputModes;
  };

const CodeControl: FC<CodeControlProps> = ({
  formHelpers,
  name,
  type = 'text',
  autoComplete = 'off',
  placeholder = '-',
  errorText,
  disabled,
  value = '',
  autoFocus = true,
  forceUppercase = false,
  fields = 4,
  additionalClass,
  filterKeyCodes = [],
  inputMode,
  filterChars = [],
  filterCharsIsWhitelist = false,
  onChange = noop,
  maxLength,
  ...props
}) => {
  const [values, setValues] = useState<string[]>((forceUppercase ? value.toUpperCase() : value).split(''));
  const inputRefMap = useRef<Record<number, RefObject<HTMLInputElement>>>(
    [...Array(fields).keys()].reduce((a, i) => ({ ...a, [i]: React.createRef<HTMLInputElement>() }), {})
  ).current;

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    const id = Number(e.currentTarget.dataset.id);
    const currentTarget = inputRefMap[id] && inputRefMap[id].current;
    const nextTarget = inputRefMap[id + 1] && inputRefMap[id + 1].current;
    const prevTarget = id > 0 && inputRefMap[id - 1] && inputRefMap[id - 1].current;

    if (filterKeyCodes.length > 0 && filterKeyCodes.includes(e.key)) {
      e.preventDefault();

      return;
    }

    switch (e.key) {
      case BACKSPACE_KEY:
        e.preventDefault();

        if (currentTarget) {
          const newValues = [...values];

          currentTarget.value = '';
          newValues[id] = '';

          const newValue = newValues.join('');
          setValues(newValues);
          onChange(newValue);

          if (formHelpers) {
            formHelpers.setValue(newValue, true);
          }
        }

        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }

        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();

        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }

        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();

        if (nextTarget) {
          nextTarget.focus();
          nextTarget.select();
        }

        break;

      case UP_ARROW_KEY:
        e.preventDefault();
        break;

      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;

      case E_KEY:
        if (e.currentTarget.type === 'number') {
          e.preventDefault();
          break;
        }

        break;

      default:
        break;
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    let newValue = String(e.target.value);

    if (forceUppercase) {
      newValue = newValue.toUpperCase();
    }

    if (type === 'number') {
      newValue = newValue.replace(/[^\d]/g, '');
    }

    /** Filter Chars */
    newValue = newValue
      .split('')
      .filter(currChar => (filterCharsIsWhitelist ? filterChars.includes(currChar) : !filterChars.includes(currChar)))
      .join('');

    let fullValue = newValue;
    const id = Number(e.target.dataset.id);

    if (newValue !== '') {
      const input = values.slice();

      if (newValue.length > 1) {
        newValue.split('').forEach((char, i) => {
          if (id + i < fields) {
            input[id + i] = char;
          }

          return false;
        });
      } else {
        input[id] = newValue;
      }

      input.forEach((s, i) => {
        const inputRef = inputRefMap[i].current;

        if (inputRef) {
          inputRef.value = s;
        }
      });

      const newTarget = id < input.length ? id + (fullValue.length === 1 ? 1 : fullValue.length - 1) : id;
      const newTargetElement = inputRefMap[newTarget] && inputRefMap[newTarget].current;

      if (newTargetElement) {
        newTargetElement.focus();
        newTargetElement.select();
      }

      fullValue = input.join('');

      setValues(fullValue.split(''));

      if (formHelpers) {
        formHelpers.setValue(fullValue, true);
      }
    }

    if (onChange && fullValue) {
      onChange(fullValue);
    }
  };

  useEffect(() => {
    setValues((forceUppercase ? value.toUpperCase() : value).split(''));
  }, [forceUppercase, value]);

  const valueFields = [...Array(fields).keys()];

  return (
    <>
      <div className={c(styles.codeControlContainer, !!errorText && styles.codeError, additionalClass)}>
        {valueFields.map(field => {
          const fieldValue = values[field] || '';

          return (
            <Control
              {...props}
              additionalClasses={{ control: styles.codeControl }}
              id={`${field}`}
              name={`input_${field}`}
              key={`input_${field}`}
              errorText={errorText}
              onFocus={e => (e as FocusEvent<HTMLInputElement>).target.select()}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              disabled={disabled}
              value={fieldValue}
            >
              <Input
                autoFocus={autoFocus && field === 0}
                data-id={`${field}`}
                ref={inputRefMap[field]}
                autoComplete={autoComplete}
                type={type === 'number' ? 'number' : 'text'}
                placeholder={placeholder}
                additionalClass={styles.codeInput}
                maxLength={fields === field + 1 ? 1 : fields}
              />
            </Control>
          );
        })}
      </div>
      {errorText && <div className={styles.info}>{errorText && <div className={styles.error}>{errorText}</div>}</div>}
    </>
  );
};

export default CodeControl;
