import { OptionsTab } from './components/OptionsTab';
import { DEFAULT_MAP_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { DataSourcePicker } from './components/DataSourcePicker';
import { WidgetSetup } from '@components/pages/WidgetPage/types';
import { MapWidget } from '@components/widgets/MapWidget';

export default {
  type: 'map',
  icon: 'Maps',
  i18nTitle: 'map',
  DataSourcePicker,
  OptionsTab,
  Widget: MapWidget,
  defaultSubConfig: DEFAULT_MAP_WIDGET_CONFIG,
} as WidgetSetup;
