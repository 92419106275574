import { useTableConfig } from '@components/common/Table/TableConfigContext';
import { Sparkline } from '@components/connected/Sparkline/Sparkline';
import { Alert } from '@infrastructure/api/BaseNClient/useAlertsQuery';
import colors from '@theme/colors';
import { getAlertChannelStats, getIconColor } from '@utils/alert';

import styles from './AlertHistory.module.scss';

export const AlertHistory = ({ item }: { item: Alert }) => {
  const { rowHeight } = useTableConfig();
  const colorName = item.color ?? getIconColor(getAlertChannelStats([item]));
  // use rowHeight as key to force re-render on rowHeight change
  return (
    <div key={rowHeight} className={styles.sparkline}>
      <Sparkline source={{ alertId: item.id }} color={colors.alert[colorName]} />
    </div>
  );
};
