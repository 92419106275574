import React from 'react';
import c from 'classnames';
import { Menu as BPMenu, MenuProps } from '@blueprintjs/core';

import styles from './style.module.scss';

export type Props = MenuProps;

const Menu: React.FC<Props> = ({ className, ...rest }) => <BPMenu className={c(styles.menu, className)} {...rest} />;

export default Menu;
