import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import React, { FC, useCallback } from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';
import {
  InfiniteSelectControl,
  InfiniteSelectControlProps,
} from '@components/connected/InfiniteSelect/InfiniteSelectControl';
import {
  LogbookQueryRequest,
  LogbookQueryResponse,
  NoteWithEntity,
} from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { formatEpochDate } from '@utils/date';
import CopyText from '@components/typography/CopyText';
import { SessionDetailsEntry } from '@infrastructure/api/BaseNClient/useSessionByIdQuery';

import styles from './SessionLogPicker.module.scss';

const itemToLabel = (item: SessionDetailsEntry | null) => formatEpochDate(item?.timestamp);

const toSessionDetailsEntry = (item: NoteWithEntity): SessionDetailsEntry => ({
  timestamp: item.timestamp,
  endtimestamp: item.endtimestamp,
  user: item.author!,
  entityId: item.entityid,
  sessionId: item.link!,
  log: '',
});

export type SessionLogPickerProps = Omit<
  InfiniteSelectControlProps<SessionDetailsEntry>,
  'value' | 'getItemsQuery' | 'itemToLabel'
> & {
  entityId: string;
  value?: SessionDetailsEntry | string | null;
};

export const SessionLogPicker: FC<SessionLogPickerProps> = ({ entityId, value, ...props }) => {
  const queryClient = useQueryClient();

  const getItemsQuery = useCallback(
    (start_index: number, end_index: number, filterStr?: string) => {
      const queryParams: LogbookQueryRequest = {
        start_index,
        end_index,
        types: 'session',
        entity_ids: entityId,
      };

      return queryClient
        .fetchQuery(
          ['sessionLogs', queryParams],
          () => apiClient.get<LogbookQueryResponse>(ENDPOINTS.logbook, queryParams),
          {
            ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<LogbookQueryResponse, Error>),
          }
        )
        .then(({ notes, total }) => {
          return { data: notes.map(toSessionDetailsEntry), total };
        });
    },
    [entityId, queryClient]
  );

  const rowContentsRenderer = useCallback((item: SessionDetailsEntry): JSX.Element => {
    return (
      <div className={styles.row}>
        <CopyText variant="copy-6">{itemToLabel(item)}</CopyText>
        <CopyText variant="copy-6" additionalClass="text-blue-gray-1 ml-auto">
          {item.user}
        </CopyText>
      </div>
    );
  }, []);

  return (
    <InfiniteSelectControl<SessionDetailsEntry>
      label="Session Log"
      {...props}
      value={value && typeof value === 'object' ? { label: itemToLabel(value), value } : value}
      getItemsQuery={getItemsQuery}
      itemToLabel={itemToLabel}
      rowContentsRenderer={rowContentsRenderer}
    />
  );
};
