import React, { forwardRef } from 'react';
import { WidgetComponentProps } from '@components/pages/WidgetPage/types';
import { DEFAULT_ISSUE_MANAGER_WIDGET_CONFIG } from '@infrastructure/redux/widgetPage/constants';
import { IssueManagerSource, WidgetHandle } from '@infrastructure/redux/widgetPage';
import { PortableIssueManager } from '@components/connected/IssueManager/PortableIssueManager';
import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';

export type IssueManagerWidgetProps = WidgetComponentProps;

export const IssueManagerWidget = forwardRef<WidgetHandle, IssueManagerWidgetProps>(({ config }, ref) => {
  const { showDetailsInSidePanel, showExtendedToolbar, source } = { ...DEFAULT_ISSUE_MANAGER_WIDGET_CONFIG, ...config };

  const { activeViewId } = (source ?? {}) as IssueManagerSource;

  return (
    <div className="w-full h-full p-8">
      {!!activeViewId ? (
        <PortableIssueManager
          key={activeViewId}
          viewId={activeViewId}
          actionsPrefix="issueManagerWidget"
          showDetailsInSidePanel={showDetailsInSidePanel}
          showExtendedToolbar={showExtendedToolbar}
        />
      ) : (
        <NoElementsContainer
          icon="EmptyIssueSource"
          title={
            <Headline centered variant="headline-6">
              Please select a <span className="text-blue-ocean">Data Source</span>
            </Headline>
          }
          description={<CopyText variant="copy-4">Data Source &gt; Issue View</CopyText>}
        />
      )}
    </div>
  );
});
