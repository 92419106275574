import useSocket, { ConnectOptions, Message } from '@infrastructure/push-api/useSocket';
import React from 'react';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import { CanvasAddon } from 'xterm-addon-canvas';
import { Socket as IoSocket } from 'socket.io-client';
import { debounce, isEqual, noop, trim } from 'lodash';
import { clear as clearOnElementResize, bind as onElementResize } from 'size-sensor';

type Dimensions = { cols: number; rows: number };

type UseTerminalProps = {
  token?: string;
  type?: 'vt100';
  columns?: number;
  rows?: number;
  entity: string;
  onError?: (error: Error) => void;
  onConnect?: () => void;
  onCopy?: (text?: string) => void;
  onPaste?: (text?: string) => void;
  onOutput?: (data: string) => void;
};

type UseTerminalResponse = {
  ref: React.Dispatch<HTMLDivElement>;
  isReady: boolean;
  isSocketActive: boolean;
  isConnecting?: boolean;
  isError: boolean;
  error: Error | null;
  connectTerminal: (options?: ConnectOptions) => Promise<IoSocket | null>;
  disconnectTerminal: () => void;
};

const addons: any = {
  fit: { name: 'fit', ctor: FitAddon, canChange: false },
};

export const useTerminal = ({ token, onError, onCopy, onPaste, onOutput, ...params }: UseTerminalProps) => {
  const [, forceUpdate] = React.useState(Date.now());
  const [isTerminalConnected, setIsTerminalConnected] = React.useState(false);

  const ttyRef = React.useRef<Terminal | null>(null);
  const [ttyContainerRef, setTtyContainerRef] = React.useState<HTMLDivElement | null>(null);

  const [dimensions, setDimensions] = React.useState<Dimensions | null>(null);

  const handleMessage = React.useCallback(
    ({ channel, data }: Message<string>) => {
      switch (channel) {
        case 'output':
          if (data) {
            const decodedData = atob(data);
            ttyRef.current?.write(decodedData);
            onOutput?.(decodedData);
          }
          break;
      }
    },
    [onOutput]
  );

  const handleDisconnect = React.useCallback(() => {
    setIsTerminalConnected(false);
  }, []);

  const socketState = useSocket({
    token,
    service: 'jumpstation',
    channels: ['output', 'terminal_connected'],
    onMessage: handleMessage,
    onError,
    onDisconnect: handleDisconnect,
    autoReconnect: false,
    autoConnect: false,
    ...params,
  });

  const responseRef = React.useRef<UseTerminalResponse>({
    ...socketState,
    ref: setTtyContainerRef,
    isSocketActive: socketState.isSocketActive,
    isError: socketState.isError,
    error: socketState.error,
    isConnecting: socketState.isConnecting || !dimensions,
    isReady: false,
    connectTerminal: () => Promise.resolve(null),
    disconnectTerminal: noop,
  });

  const handleInput = React.useCallback(
    (data: string) => {
      socketState?.sendMessage({ channel: 'input', data: btoa(data) });
    },
    [socketState]
  );

  const handleSelectionChange = React.useCallback(() => {
    const textToCopy = ttyRef.current?.getSelection();
    if (!textToCopy || !trim(textToCopy)) {
      return;
    }

    // copying to Clipboard programmatically might be denied for various reasons
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        onCopy?.(textToCopy);
      })
      .catch(() => {
        onCopy?.();
      });
  }, [onCopy]);

  const handleRightClick = React.useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      // Browser will request a permission to paste from clipboard and will throw an error if denied
      navigator.clipboard
        .readText()
        .then(text => {
          ttyRef.current?.paste(text);
          onPaste?.(text);
        })
        .catch(() => {
          onPaste?.();
        });
    },
    [onPaste]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = React.useCallback(
    debounce(() => {
      try {
        setDimensions(oldDimensions => {
          const newDimensions = addons.fit.instance!.proposeDimensions();
          if (!isEqual(oldDimensions, newDimensions)) {
            socketState?.sendMessage({
              channel: 'resize',
              data: { columns: newDimensions.cols, rows: newDimensions.rows },
            });
            ttyRef.current?.resize(newDimensions.cols, newDimensions.rows);
            return newDimensions;
          }
          return oldDimensions;
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    }, 500),
    [socketState]
  );

  const disconnectTerminal = React.useCallback(() => {
    ttyRef.current?.clear();
    socketState?.disconnectSocket();
  }, [socketState]);

  // there's now this additional step we need to undergo to actually connect to the terminal
  const connectTerminal = React.useCallback(
    (options?: ConnectOptions): Promise<IoSocket | null> => {
      return new Promise((resolve, reject) => {
        const { credentials, ...overrideParams } = options?.params || {};

        if (dimensions) {
          const socket = socketState?.connectSocket({
            ...options,
            params: {
              terminal_columns: dimensions.cols,
              terminal_rows: dimensions.rows,
              ...params,
              ...overrideParams,
            },
          });

          const attachEvents = () => {
            socket?.on('connect', handleConnect);
            socket?.on('terminal_connected', handleTerminalConnected);
            socket?.on('error', onError);
          };

          const detachEvents = () => {
            socket?.off('connect', handleConnect);
            socket?.off('terminal_connected', handleTerminalConnected);
            socket?.off('error', onError);
          };

          const handleConnect = () => {
            socketState?.sendMessage({
              channel: 'terminal_connect',
              data: credentials ?? null,
            });
          };

          const handleTerminalConnected = () => {
            detachEvents();
            setIsTerminalConnected(true);
            resolve(socket);
          };

          const onError = (error: Error) => {
            // the only way we can identify authorization error response to terminal_connect message
            if (error.message?.includes('User authentication failed')) {
              detachEvents();
              socketState?.disconnectSocket();
              setIsTerminalConnected(false);
              reject(error);
            }
          };

          attachEvents();
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dimensions]
  );

  React.useEffect(
    () => {
      let term: Terminal;
      if (ttyContainerRef) {
        term = new Terminal({
          fontFamily: 'Monaco, courier-new, courier, monospace',
          fontSize: 14,
          fontWeight: 'normal',
          lineHeight: 1,
        });
        term.open(ttyContainerRef);
        term.focus();
        term.onData(handleInput);

        term.loadAddon(new CanvasAddon());
        addons.fit.instance = new FitAddon();
        term.loadAddon(addons.fit.instance);
        addons.fit.instance!.fit();
        setDimensions(addons.fit.instance!.proposeDimensions());

        term.onSelectionChange(handleSelectionChange);
        term.element?.addEventListener('contextmenu', handleRightClick);
        onElementResize(ttyContainerRef, handleResize);

        ttyRef.current = term;
      }
      return () => {
        if (ttyRef.current) {
          ttyRef.current.dispose();
        }

        if (ttyContainerRef) {
          clearOnElementResize(ttyContainerRef);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ttyContainerRef]
  );

  React.useEffect(() => {
    responseRef.current = {
      ...responseRef.current,
      isSocketActive: socketState.isSocketActive,
      isError: socketState.isError,
      error: socketState.error,
      isConnecting: socketState.isConnecting || !dimensions,
      isReady: isTerminalConnected && socketState.isSocketActive && !!dimensions,
      connectTerminal,
      disconnectTerminal,
    };
    forceUpdate(Date.now());
  }, [
    isTerminalConnected,
    socketState.isSocketActive,
    socketState.isError,
    socketState.isConnecting,
    dimensions,
    connectTerminal,
    disconnectTerminal,
    socketState.error,
  ]);

  return responseRef.current;
};
