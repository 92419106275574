import React from 'react';
import Error500Page from '@components/pages/Error500Page';
import BasePage from '@components/pages/Management/components/BasePage';
import useTextSnippets from '@services/useTextSnippets';
import Grid from '@components/layout/Grid';
import ROUTES from '@infrastructure/routes';
import { ManagementCard } from './components/ManagementCard';

const Management: React.FC<{ error?: boolean }> = ({ error }) => {
  const managementI18n = useTextSnippets('management');

  React.useEffect(() => {
    document.title = `Management - BaseN Platform`;
  }, []);

  const managementSections = [
    {
      title: managementI18n.issueManager,
      description: managementI18n.issueManagerDescription,
      path: ROUTES.issueViewManager,
    },
    // {
    //   title: managementI18n.account,
    //   description: managementI18n.accountDescription,
    //   path: ROUTES.account,
    // },
  ];

  if (error) {
    return <Error500Page />;
  }

  return (
    <BasePage level={1}>
      <Grid noSpacing additionalClass="w-full gap-12 -mt-12">
        {managementSections.map(section => (
          <ManagementCard key={section.path} {...section} />
        ))}
      </Grid>
    </BasePage>
  );
};

export default Management;
