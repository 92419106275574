import React, { useMemo } from 'react';
import Icon, { IconNames } from '@components/common/Icon';
import CopyText from '@components/typography/CopyText';
import ActionIssueList from './ActionIssueList';
import HorizontalSeparator from '@components/separators/HorizontalSeparator';
import useTextSnippets from '@services/useTextSnippets';
import { Issue, IssueDefaultActionTypes } from '@redux/issues';

type Props = {
  warning?: string;
  action: IssueDefaultActionTypes;
  label?: string;
  issues: Issue[];
};

const ActionModalTitle: React.FC<Props> = ({ action, label, issues, warning }) => {
  const issuesI18n = useTextSnippets('issues');

  const icon: IconNames | null = useMemo(() => {
    switch (action) {
      case 'acknowledge':
        return 'Acknowledge';
      case 'changePriority':
        return 'ChangePriority';
      case 'changeStatus':
        return 'ChangeStatus';
      case 'comment':
        return 'Comment';
      default:
        return null;
    }
  }, [action]);

  const title: string = action === 'customAction' ? label! : issuesI18n[action as keyof typeof issuesI18n];

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center">
        {icon && <Icon name={icon} additionalClass="mr-4 text-dark" size="s" />}
        <CopyText variant="copy-1" color="dark">
          {title}
        </CopyText>
      </div>
      <ActionIssueList warning={warning} issues={issues} />
      <HorizontalSeparator additionalClass="mt-16" gutter={0} />
    </div>
  );
};

export default ActionModalTitle;
