export enum CypressTestType {
  SIDEBAR = 'SIDEBAR',
  ISSUE_MANAGER = 'ISSUE_MANAGER',
}

/**
 * The purpose of this is to store the test data of reusable components into a single place
 * instead to repeat it into each test that uses it
 */
export enum CypressUtilityType {
  UTILITY_TABLE = 'UTILITY_TABLE',
}

export type CypressTestData = {
  [CypressTestType.SIDEBAR]: SidebarTestDataConfig;
  [CypressTestType.ISSUE_MANAGER]: IssueManagerTestDataConfig;
  [CypressUtilityType.UTILITY_TABLE]: UtilityTableTestDataConfig;
};

interface CypressTestDataConfig {
  cssClasses: { [x: string]: string | object };
  testIds: { [x: string]: string | object };
}

// Core test data configurations

export interface SidebarTestDataConfig extends CypressTestDataConfig {
  cssClasses: {
    sideBarOpen: string;
  };
  testIds: {
    sidebarToggleButton: string;
  };
}

export interface IssueManagerTestDataConfig extends CypressTestDataConfig {
  cssClasses: {};
  testIds: {
    topBarTotalIssuesCountLabel: string;
    clearAllFiltersButton: string;
    filters: {
      newIssuesFilterButtonLabel: string;
      openIssuesFilterButtonLabel: string;
      trackingIssuesFilterButtonLabel: string;
      frozenIssuesFilterButtonLabel: string;
      closingIssuesFilterButtonLabel: string;
      closedIssuesFilterButtonLabel: string;
    };
    downloadIssues: {
      downloadButton: string;
      formatSelectionButtonsSuffix: string; // concatenated together with the format prefix within the injection in the dom
      formatSelectionButtons: {
        xlsx: string;
        csv: string;
      };
    };
    views: {
      viewSelectInput: string;
      selectOptionsPrefix: string;
      selectOptionsManageViews: string;
    };
  };
}

// Utility test data configurations

/**
 * This is a test data interface for the reusable `Table` component
 */
export interface UtilityTableTestDataConfig extends CypressTestDataConfig {
  cssClasses: {};
  testIds: {
    tableWrapper: string;
    totalItemsCountLabel: string;
    issuesPerPageInput: string;
    searchInputField: string;
    columns: {
      nameCellPrefix: string;
      commentCellPrefix: string;
    };
  };
}
