import React from 'react';
import useTextSnippets from '@services/useTextSnippets';
import { Menu, MenuItem } from '@components/common/Menu';
// import { Menu, MenuItem, MenuDivider } from '@components/common/Menu';
import styles from './style.module.scss';
import ROUTES from '@infrastructure/routes';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@infrastructure/redux/store';
import { selectReleaseEmbedLoaded } from '@infrastructure/redux/settings';
import { ReleaseBadge } from '@components/connected/ReleaseBadge';

const HelpMenu: React.FC = () => {
  // const { support, shortcuts, releaseNotes, licenses, patents } = useTextSnippets('help');
  const { support, shortcuts } = useTextSnippets('help');
  const navigate = useNavigate();
  const isReleaseEmbedLoaded = useAppSelector(selectReleaseEmbedLoaded);

  return (
    <Menu key="menu" className={styles.helpMenu}>
      <MenuItem tagName="span" text={support} onClick={() => navigate(ROUTES.support)} />
      <MenuItem tagName="span" text={shortcuts} label="^/#" onClick={() => navigate(ROUTES.keyboardShortcuts)} />
      {isReleaseEmbedLoaded && (
        <MenuItem
          className={styles.releaseNotesMenuItem}
          tagName="span"
          text={
            <span className={styles.releaseNotes}>
              Release Notes
              <ReleaseBadge />
            </span>
          }
          onClick={() => navigate(ROUTES.releaseNotes)}
        />
      )}

      {/* <MenuDivider /> */}
      {/* <MenuItem tagName="span" text={releaseNotes} /> */}
      {/* <MenuItem tagName="span" text={licenses} /> */}
      {/* <MenuItem tagName="span" text={patents} /> */}
    </Menu>
  );
};

export default HelpMenu;
