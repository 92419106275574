import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import React, { useEffect, useMemo, useCallback } from 'react';
import {
  selectItemsAsArray,
  selectPagination,
  selectQueryParams,
  selectSorting,
  selectResolvedFilters,
  selectAreLoaded,
} from '@redux/alertsPage';
import { Sort, RemoteStoredTableProps, RemoteStoredTable, RowHeight } from '@components/common/Table';
import { getFiltersConfig, getTableColumns } from './utils';
import { SortingParams } from '@infrastructure/redux/types';
import { DefaultMotion } from '@services/theme';
import Headline from '@components/typography/Headline';
import { FieldsConfig } from '@components/common/form/FormBuilder';
import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import { Alert, AlertColor, useAlertsQuery } from '@infrastructure/api/BaseNClient/useAlertsQuery';
import { StatusFilter, StatusFilterResponse } from './StatusFilter';

export type AlertsTableProps = {
  actions: Dictionary<any>;
  onInitialLoadComplete?: () => void;
};

export const AlertsTable: React.FC<AlertsTableProps> = ({ actions, onInitialLoadComplete }) => {
  const { updatePagination, updateSorting, updateFilters, updateSearch, updateItems, updateRowHeight } = actions;

  const dispatch = useAppDispatch();

  const areLoaded = useAppSelector(selectAreLoaded);
  const items = useAppSelector(selectItemsAsArray);
  const allFilters = useAppSelector(selectResolvedFilters);
  const pagination = useAppSelector(selectPagination);
  const sorting = useAppSelector(selectSorting);
  const params = useAppSelector(selectQueryParams);

  const alertsQuery = useAlertsQuery({
    details: true,
    silent: false,
    ...params,
  });

  useEffect(() => {
    if (alertsQuery.data?.alerts) {
      dispatch(updatePagination({ rowCount: alertsQuery.data.total }));
      dispatch(updateItems(alertsQuery.data?.alerts));
      onInitialLoadComplete?.();
    }
  }, [alertsQuery.data, dispatch, onInitialLoadComplete, updatePagination, updateItems]);

  const handleToggle = (colors: StatusFilterResponse) => {
    const colorFilters = Object.keys(colors)
      .filter(color => colors[color as AlertColor])
      .join(',');

    handleFiltersChange({ colors: colorFilters });
  };

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(updatePagination({ page: newPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSortChange = useCallback(
    (sortBy: string | null, sort: Sort) => {
      dispatch(updateSorting({ sortBy: sortBy as SortingParams['sortBy'], sort }));
    },
    [dispatch, updateSorting]
  );

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      dispatch(updatePagination({ rowsPerPage: newRowsPerPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSearch = useCallback(
    (keyword?: string) => {
      dispatch(updateSearch(keyword));
      dispatch(updatePagination({ page: 1 }));
    },

    [dispatch, updatePagination, updateSearch]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Dictionary<any>) => {
      dispatch(updateFilters(newFilters));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch, updateFilters, updatePagination]
  );

  const handleRowHeightChange = useCallback(
    (newRowHeight: RowHeight) => {
      dispatch(updateRowHeight(newRowHeight));
    },
    [dispatch]
  );

  const filterConfig: FieldsConfig = useMemo(() => getFiltersConfig(allFilters), [allFilters]);

  const tableProps: RemoteStoredTableProps<Alert> = useMemo(
    () => ({
      name: 'alertsTable',
      items,
      columns: getTableColumns(),
      onRowsPerPageChange: handleRowsPerPageChange,
      onPageChange: handlePageChange,
      onSortChange: handleSortChange,
      onSearch: handleSearch,
      onFiltersChange: handleFiltersChange,
      onRowHeightChange: handleRowHeightChange,
      filterConfig,
      keyword: allFilters.generalFilter,
      isLoading: alertsQuery.isLoading || alertsQuery.isFetching,
      config: {
        ...pagination,
        sort: sorting?.sort,
        sortBy: sorting?.sortBy,
        sortable: true,
        bordered: false,
        selectable: false,
      },
    }),
    [
      items,
      handleRowsPerPageChange,
      handlePageChange,
      handleSortChange,
      handleSearch,
      handleFiltersChange,
      handleRowHeightChange,
      filterConfig,
      allFilters.generalFilter,
      pagination,
      sorting?.sort,
      sorting?.sortBy,
      alertsQuery.isLoading,
      alertsQuery.isFetching,
    ]
  );

  return (
    <div className="h-full vbox">
      <div className="w-full mb-16 hbox">
        <StatusFilter
          selected={allFilters?.discreteFilters?.colors?.split(',').filter(Boolean) as AlertColor[]}
          onToggle={handleToggle}
          isDisabled={alertsQuery.isLoading}
          additionalClass="ml-auto"
        />
      </div>
      {!areLoaded ? (
        <NoElementsContainer icon="Alerts" title="Alerts" />
      ) : (
        <DefaultMotion key="alerts" className="w-full min-h-0">
          <RemoteStoredTable<Alert> {...tableProps} />
        </DefaultMotion>
      )}
    </div>
  );
};
