import React from 'react';
import { DataSourcePicker, EntityDataSourcePickerProps } from './DataSourcePicker';
import { ToggleSwitch } from '@components/common/form/ToggleSwitch';

export const UniversalDataSourcePicker = (props: EntityDataSourcePickerProps) => {
  const [pagePlotnameBased, setPagePlotnameBased] = React.useState(props.pagePlotnameBased ?? !!props.source?.plotname);

  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean = true) => {
    setPagePlotnameBased(checked);
  }, []);

  return (
    <div className="items-start w-full vbox">
      <ToggleSwitch
        label="Page/Plotname Based"
        checked={pagePlotnameBased}
        onChange={handleChange}
        size="s"
        additionalClass="mb-16"
      />
      <DataSourcePicker {...props} pagePlotnameBased={pagePlotnameBased} />
    </div>
  );
};
