import React, { ReactNode } from 'react';
import c from 'classnames';
import CopyText from '@components/typography/CopyText';
import Grid from '@components/layout/Grid';
import Card from '@components/common/Card/Card';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import styles from './ItemGridDND.module.scss';
import Icon from '@components/common/Icon';

type Props<T extends { id: string }> = {
  id: string;
  onDragEnd: (result: DropResult) => void;
  additionalClass?: string;
  showIndex?: boolean;
  items: Record<string, T> | T[];
  itemContentRenderer: ((item: T, index: number) => ReactNode) | ((item: T) => ReactNode);
  minimal?: boolean;
  title?: string;
  freezeFirstItem?: boolean;
};

function ItemGrid<T extends { id: string; order: number }>({
  additionalClass,
  onDragEnd,
  id,
  items,
  showIndex,
  itemContentRenderer,
  minimal,
  title,
  freezeFirstItem,
}: Props<T>) {
  const getMinimalItemContent = (item: T, index: number) =>
    freezeFirstItem && index === 0 ? (
      <div key={item.id} className={c(styles.simpleGridItem, 'cursor-not-allowed')}>
        <div className="flex flex-col mr-4">
          {showIndex && (
            <CopyText variant="copy-4" additionalClass="inline-flex text-blue-gray-base mb-2">
              #{index + 1}
            </CopyText>
          )}
          <Icon name="Move" size="s" additionalClass="text-transparent" />
        </div>
        <div className="copy-4 text-gray-4 w-full">{itemContentRenderer(item, index)}</div>
      </div>
    ) : (
      <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={c(styles.simpleGridItem, snapshot.isDragging && styles.dragging)}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <div className="flex flex-col mr-4">
              {showIndex && (
                <CopyText variant="copy-4" additionalClass="inline-flex text-blue-gray-base mb-2">
                  #{index + 1}
                </CopyText>
              )}
              <Icon name="Move" size="s" additionalClass="text-blue-gray-2" />
            </div>
            <div className="copy-4 w-full">{itemContentRenderer(item, index)}</div>
          </div>
        )}
      </Draggable>
    );

  const getItemContent = (item: T, index: number) =>
    freezeFirstItem && index === 0 ? (
      <Card key={item.id} additionalClass={styles.gridItem} elevation={1}>
        <div className="items-center flex-full p-12 px-8">
          <div className="flex items-center mr-8 h-full">
            <div className="flex flex-col cursor-not-allowed mr-4">
              {showIndex && (
                <CopyText variant="copy-4" additionalClass="inline-flex text-blue-gray-base mb-2">
                  #{index + 1}
                </CopyText>
              )}
              <Icon name="Move" size="m" additionalClass="text-gray-4 cursor-not-allowed" />
            </div>
          </div>
          <div className="flex flex-col flex-1 w-full">{itemContentRenderer(item, index)}</div>
        </div>
      </Card>
    ) : (
      <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
        {(provided, snapshot) => (
          <Card additionalClass={styles.gridItem} elevation={snapshot.isDragging ? 2 : 1} {...provided.draggableProps}>
            <div ref={provided.innerRef} className="items-center flex-full p-12 px-8">
              <div className="flex items-center mr-8 h-full">
                <div className="flex flex-col mr-4" {...provided.dragHandleProps}>
                  {showIndex && (
                    <CopyText variant="copy-4" additionalClass="inline-flex text-blue-gray-base mb-2">
                      {snapshot.draggingOver} #{index + 1}
                    </CopyText>
                  )}
                  <Icon name="Move" size="m" additionalClass="text-blue-gray-2" />
                </div>
              </div>
              <div className="flex flex-col flex-1 w-full">{itemContentRenderer(item, index)}</div>
            </div>
          </Card>
        )}
      </Draggable>
    );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {title && (
        <CopyText variant="copy-3" additionalClass="mb-8">
          {title}
        </CopyText>
      )}
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <Grid
            noSpacing
            additionalClass={c(styles.grid, minimal && styles.minimalGrid, additionalClass)}
            dndRef={provided.innerRef}
            dndActive={snapshot.isDraggingOver}
            dndProps={provided.droppableProps}
          >
            {[...(Array.isArray(items) ? items : Object.values(items))]
              .sort((a, b) => a.order - b.order)
              .map((item, index) => (minimal ? getMinimalItemContent(item, index) : getItemContent(item, index)))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default ItemGrid;
