import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import { GenericOptionsResponse, GenericPaginatedResponse } from '@infrastructure/api/types';
import React, { useCallback } from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';
import {
  InfiniteTagInputControlLegacy,
  InfiniteTagInputControlLegacyProps,
} from '../InfiniteSelect/InfiniteTagInputControlLegacy';
import { OptionsQueryRequest } from './OptionsChecklist';
import { OptionType } from './types';

export type OptionsTagInputProps = Omit<InfiniteTagInputControlLegacyProps, 'getItemsQuery' | 'itemToLabel'> & {
  property: OptionType;
};

const itemToLabel = (item: any): string => item?.name ?? '';

export function OptionsTagInput<T extends GenericOptionsResponse = GenericOptionsResponse>({
  property,
  filterable = true,
  ...props
}: OptionsTagInputProps) {
  const queryClient = useQueryClient();

  const getItemsQuery = useCallback(
    (start_index: number, end_index: number, filterStr?: string) => {
      const queryParams: OptionsQueryRequest = {
        start_index,
        end_index,
      };

      if (filterStr) {
        queryParams.filter = `.*${filterStr}.*`;
      }

      return queryClient.fetchQuery(
        [property, queryParams],
        () =>
          apiClient.get<GenericPaginatedResponse<T>>(ENDPOINTS.getOptions.replace(':property', property), queryParams),
        {
          ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<GenericPaginatedResponse<T>, Error>),
        }
      );
    },
    [queryClient, property]
  );

  return (
    <InfiniteTagInputControlLegacy<T>
      key={property}
      {...props}
      getItemsQuery={getItemsQuery}
      // @ts-ignore
      itemToLabel={itemToLabel}
      filterable={filterable}
    />
  );
}
