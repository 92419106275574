import React from 'react';
import { Form, CodeField, SubmitButton, FormSubmitOptions } from '@components/common/form';
import Link from '@components/common/Link';
import CopyText from '@components/typography/CopyText';
import Headline from '@components/typography/Headline';
import BaseNLogo from '@components/common/BaseNLogo';
import useTextSnippets from '@services/useTextSnippets';
import ROUTES from '@infrastructure/routes';
import Notification from '@components/common/Notification';
import { GenericOkApiResponse } from '@infrastructure/api/types';
import { useApiClient } from '@infrastructure/api/useApiClient';
import { forgotPasswordConfig } from '@redux/auth/config';
import fillTemplate from '@utils/fillTemplate';
import noop from '@utils/noop';

import { ForgotPasswordSchema } from './ForgotPasswordForm.schema';
import getSchema, { ForgotPasswordCodeSchema } from './ForgotPasswordCodeForm.schema';

import ResendLink from './ResendLink';
import styles from './style.module.scss';

export type ForgotPasswordFormProps = {
  email?: string;
  submitError?: boolean;
  clearError?: () => void;
  codeErrorCount?: number;
  resetCodeErrorCount?: () => void;
  onSubmit: (
    values: ForgotPasswordCodeSchema,
    helpers: FormSubmitOptions<ForgotPasswordCodeSchema>
  ) => void | Promise<any>;
};

function ForgotPasswordCodeForm({
  email = '',
  clearError = noop,
  submitError,
  codeErrorCount = 0,
  resetCodeErrorCount = noop,
  onSubmit,
}: ForgotPasswordFormProps) {
  const forgotPasswordSnippets = useTextSnippets('forgotPassword');
  const validationSchema = getSchema(forgotPasswordSnippets);
  const { codeTitle, codeSubtitle, forWho, goBack, validateCode, codeFailed, tooManyCodeFailures } =
    forgotPasswordSnippets;
  const forgotPasswordMutation = useApiClient<GenericOkApiResponse, ForgotPasswordSchema>('forgotPassword');
  const { trademarks } = useTextSnippets('copyRight');

  const handleResend = () => {
    forgotPasswordMutation.mutate({ email });
    resetCodeErrorCount();
    clearError();
  };

  const tooManyCodeSubmitFailures = codeErrorCount && codeErrorCount >= forgotPasswordConfig.MAX_CODE_FAILURES_ALLOWED;

  return (
    <div className={styles.forgotPasswordForm}>
      <div className="vbox">
        <BaseNLogo additionalClass={styles.logo} />
      </div>
      {submitError || tooManyCodeSubmitFailures ? (
        <div className={styles.errorContainer}>
          <Notification
            type="error"
            title={tooManyCodeSubmitFailures ? tooManyCodeFailures : codeFailed}
            onClose={tooManyCodeSubmitFailures ? undefined : clearError}
          />
        </div>
      ) : null}
      <Form<ForgotPasswordCodeSchema>
        initialValues={{ code: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        additionalClass={styles.form}
      >
        <Headline additionalClass="mb-16 text-black" variant="headline-5">
          {codeTitle}
        </Headline>
        <CopyText additionalClass="mb-32 text-black block" variant="copy-4">
          <span
            dangerouslySetInnerHTML={{
              __html: fillTemplate(codeSubtitle, { forAddress: email ? `<b> ${forWho} ${email}</b>` : '' }),
            }}
          />
        </CopyText>

        <CodeField size="l" name="code" fields={6} type="text" additionalClass={styles.codeField} />

        <ResendLink handleResend={handleResend} />

        <SubmitButton
          size="l"
          variant="fillBlueDark"
          fullWidth
          disableOnInvalid
          disabled={codeErrorCount >= forgotPasswordConfig.MAX_CODE_FAILURES_ALLOWED}
        >
          {validateCode}
        </SubmitButton>

        <Link centered href={ROUTES.login} additionalClass={styles.goBack} variant="classic">
          {goBack}
        </Link>

        <CopyText variant="copy-6" additionalClass={styles.trademarks}>
          {trademarks}
        </CopyText>
      </Form>
    </div>
  );
}

export default ForgotPasswordCodeForm;
