import React, { forwardRef } from 'react';
import { WidgetHandle } from '@redux/widgetPage/types';
import { TopologyMap, TopologyMapProps } from './TopologyMap';

export type TopologyMapWidgetProps = TopologyMapProps;

export const TopologyMapWidget = forwardRef<WidgetHandle, TopologyMapWidgetProps>((props, ref) => {
  // due to intricate async behavior of TopologyMap, we need to start with a clean state
  // whenever the source changes
  return (
    <TopologyMap
      ref={ref}
      key={props.config?.source ? JSON.stringify(props.config.source) : 'topology-map'}
      {...props}
    />
  );
});
