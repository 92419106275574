import type { Action } from '@reduxjs/toolkit';
import { EMPTY, Observable } from 'rxjs';
import { concatMap, filter, withLatestFrom } from 'rxjs/operators';
import { Epic, StateObservable } from 'redux-observable';
import { AppState } from '../store';
import { updateQueryString } from '@services/useHistory';

import dashboardsSlice from '@redux/dashboards';
import issuesSlice from '@redux/issues';
import inventoryPageSlice from '@redux/inventoryPage';
import mwListPageSlice from '@redux/mwListPage';
import logbookSlice from '@redux/logbook';
import metricsSlice from '@redux/metricsPage';
import alertsSlice from '@redux/alertsPage';

const MONITORED_SLICES = [
  inventoryPageSlice.name,
  alertsSlice.name,
  metricsSlice.name,
  issuesSlice.name,
  dashboardsSlice.name,
  logbookSlice.name,
  mwListPageSlice.name,
];

export const updateFiltersEpic: Epic = (actions$: Observable<Action>, state$: StateObservable<AppState>) =>
  actions$.pipe(
    filter(action => {
      const [sliceName, actionName] = action.type.split('/');
      return MONITORED_SLICES.includes(sliceName) && ['updateFilters', 'updateSearch'].includes(actionName);
    }),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
      const [sliceName] = action.type.split('/');
      updateQueryString(state[sliceName].filters);
      return EMPTY;
    })
  );

export const updatePaginationEpic: Epic = (actions$: Observable<Action>, state$: StateObservable<AppState>) =>
  actions$.pipe(
    filter(action => {
      const [sliceName, actionName] = action.type.split('/');
      return MONITORED_SLICES.includes(sliceName) && ['updatePagination'].includes(actionName);
    }),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
      const [sliceName] = action.type.split('/');
      const { page, rowsPerPage } = state[sliceName].pagination;
      updateQueryString({ page, rowsPerPage });
      return EMPTY;
    })
  );

export const updateSortingEpic: Epic = (actions$: Observable<Action>, state$: StateObservable<AppState>) =>
  actions$.pipe(
    filter(action => {
      const [sliceName, actionName] = action.type.split('/');
      return MONITORED_SLICES.includes(sliceName) && ['updateSorting'].includes(actionName);
    }),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
      const [sliceName] = action.type.split('/');
      updateQueryString(state[sliceName].sort);
      return EMPTY;
    })
  );

export const updateRowHeightEpic: Epic = (actions$: Observable<Action>, state$: StateObservable<AppState>) =>
  actions$.pipe(
    filter(action => {
      const [sliceName, actionName] = action.type.split('/');
      return MONITORED_SLICES.includes(sliceName) && ['updateRowHeight'].includes(actionName);
    }),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
      const [sliceName] = action.type.split('/');
      updateQueryString({ rowHeight: state[sliceName].rowHeight });
      return EMPTY;
    })
  );
