import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Issue, IssuesQueryKey, IssuesSummary } from '@redux/issues';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '../query-configs';
import { PaginatedQueryRequest } from './usePaginatedQuery';
import { GenericPaginatedResponse } from '../types';

export type IssuesQueryRequest = PaginatedQueryRequest & {
  general?: string;
  status?: string | string[];
  priority?: string;
  root_cause?: boolean;
  from_date?: string;
  to_date?: string;
  issueFields?: string;
  details?: boolean;
  groups?: boolean;
  ids?: string;
  include_children?: boolean;
  entityids?: string;
};

export type IssuesQueryResponse = GenericPaginatedResponse<Issue> & {
  data: Issue[];
  summary: IssuesSummary;
};

export const useIssuesQuery = <T extends IssuesQueryResponse = IssuesQueryResponse>(
  searchParams: IssuesQueryRequest = {},
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useQuery<T, Error>(
    [IssuesQueryKey, searchParams],
    (): Promise<T> => apiClient.get<T>(ENDPOINTS.issues, { ...searchParams, maximum: 500 }),
    {
      ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
      ...queryOptions,
    }
  );
};
