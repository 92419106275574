import React, { useMemo, useRef, FC, useState, useCallback } from 'react';
import useTextSnippets from '@services/useTextSnippets';
import Error500Page from '@components/pages/Error500Page';
import { DefaultMotion } from '@services';
import { IssueViewAction, IssueWithDetails, selectActiveViewActions } from '@redux/issues';
import IssueComments from '@components/connected/IssueManager/components/IssueDetailsDrawer/IssueComments';
import PageHeader, { PageControlProps } from '@components/layout/PageHeader';
import IconButton from '@components/common/IconButton';
import Popover from '@components/common/Popover';
import ROUTES from '@infrastructure/routes';
import { IssueInfo } from './components/IssueInfo';
import { Widget } from '@components/layout/Widget';
import { ActionModal, ActionModalProps } from '@components/connected/IssueManager/components/ActionModal';
import {
  IssueDownloadPopup,
  IssueDownloadPopupProps,
} from '@components/connected/IssueManager/components/IssueDownloadPopup';
import MoreMenu from './components/MoreMenu';
import { useAppSelector } from '@infrastructure/redux/store';
import { IssueHistory } from './components/IssueHistory';

import styles from './IssueDetail.module.scss';

type IssueDetailProps = {
  issue: IssueWithDetails;
};

const IssueDetail: FC<IssueDetailProps> = ({ issue }) => {
  const issueMenuRef = useRef<HTMLElement>(null);
  const i18n = useTextSnippets('issues');
  const [modalProps, setModalProps] = useState<ActionModalProps | null>(null);
  const [downloadPopupProps, setDownloadPopupProps] = useState<IssueDownloadPopupProps | null>(null);
  const activeViewActions = useAppSelector(selectActiveViewActions);

  const breadcrumbProps = useMemo(
    () =>
      issue
        ? {
            omitRootLabel: true,
            items: [
              { label: i18n.issueManager, href: ROUTES.issueManager },
              { label: issue.name, current: true },
            ],
          }
        : { omitRootLabel: true, items: [] },
    [issue, i18n.issueManager]
  );

  const chartConfig = useMemo(
    () =>
      issue.alertid
        ? {
            source: {
              alertId: issue.alertid,
            },
          }
        : null,
    [issue]
  );

  const handleAcknowledge = () => {
    setModalProps({
      action: 'acknowledge',
      issues: [issue],
    });
  };

  const handleAction = useCallback(
    (viewAction?: IssueViewAction) => {
      setModalProps({
        action: 'customAction',
        issues: [issue],
        viewAction,
      });
    },
    [issue]
  );

  const handleModalClose = () => {
    setModalProps(null);
  };

  const handleDownloadPopupOpen = (e: React.MouseEvent<HTMLElement>) => {
    setDownloadPopupProps({
      params: { ids: [issue.id].join(',') },
      target: e.currentTarget,
    });
  };

  const handleDownloadPopupClose = () => {
    setDownloadPopupProps(null);
  };

  if (!issue) {
    return <Error500Page text={i18n.issueNotFound} />;
  }

  return (
    <>
      <PageHeader
        breadcrumbs
        breadcrumbsProps={breadcrumbProps}
        level={2}
        pageControls={
          [
            issue.state === 'new' && {
              id: 'acknowledge',
              onClick: handleAcknowledge,
              ariaLabel: i18n.acknowledge,
              icon: 'Acknowledge',
              label: i18n.acknowledge,
            },
            {
              id: 'download',
              onClick: handleDownloadPopupOpen,
              ariaLabel: i18n.download,
              icon: 'Download',
              label: i18n.download,
            },
          ].filter(Boolean) as PageControlProps[]
        }
        right={
          activeViewActions.length ? (
            <div className={styles.rightIconContainer}>
              <Popover
                position="bottom"
                content={<MoreMenu actions={activeViewActions} onAction={handleAction} />}
                additionalClass={styles.popover}
                noArrows
                popoverRef={issueMenuRef}
                preserveTarget
              >
                <IconButton icon="MoreVertical" />
              </Popover>
            </div>
          ) : undefined
        }
      />
      <div className={styles.issueContainer}>
        <DefaultMotion key="issue-container" full>
          <div className={styles.row}>
            <IssueInfo issue={issue} additionalClass={styles.card} />

            {chartConfig && (
              <Widget
                type="timeseries"
                name="Measurement - Plot"
                config={chartConfig}
                hideMenu
                additionalClass="flex-1"
              />
            )}
          </div>

          <IssueHistory issue={issue} additionalClass={styles.card} />

          <IssueComments issue={issue} isDetailPage additionalClass={styles.card} />
        </DefaultMotion>
      </div>

      {modalProps && <ActionModal isOpen {...modalProps} onClose={handleModalClose} />}
      {downloadPopupProps && <IssueDownloadPopup isOpen {...downloadPopupProps} onClose={handleDownloadPopupClose} />}
    </>
  );
};

export default IssueDetail;
