import { splitAndStripEmpty } from '@utils/string';
import { RunScriptSchema } from './RunScriptWizard.schema';
import { BatchRunStatus, BatchStats } from '@infrastructure/push-api/useBatchRun.schema';
import { IconNames } from '@components/common/Icon';
import { Intent } from '@blueprintjs/core';
import { BatchParams } from '@infrastructure/push-api/useBatchRun';

export const toBatchParams = (params: RunScriptSchema): BatchParams => {
  return {
    username: params.review.username,
    password: params.review.password,
    // primary use case is for cisco devices (which won't have conditionals or loops), so it's ok to split by newlines
    script: splitAndStripEmpty(params.script.script).map(line => ({ command: line })),
    entities: params.targets.entities.map(entity => entity.id),
    variables: params.variables,
  };
};

export const summaryToIcon = (status: BatchRunStatus, summary: BatchStats): IconNames => {
  switch (true) {
    case status === 'aborted':
    case status === 'error':
    case summary.errors > 0:
    case summary.aborted > 0:
      return 'WorkstationError';
    case status === 'completed':
    case summary.running + summary.waiting === 0:
      return 'WorkstationSuccess';
    default:
      return 'WorkstationProgress';
  }
};

export const summaryToIntent = (status: BatchRunStatus, summary: BatchStats): Intent => {
  switch (true) {
    case status === 'aborted':
    case status === 'error':
    case summary.errors > 0:
    case summary.aborted > 0:
      return 'danger';
    case status === 'completed':
    case summary.running + summary.waiting === 0:
      return 'success';
    default:
      return 'primary';
  }
};
