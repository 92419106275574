import { Panel } from '@components/common/Panel';
import { AlertChannel, AlertConfig } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';
import CopyText from '@components/typography/CopyText';
import { ChannelTable } from './ChannelTable';
import { SwitchBaseChildProps } from '@components/common/SwitchBase/SwitchBase';
import { getAlertChannelStats } from '@utils/alert';
import { AlertTabs } from './AlertTabs/AlertTabs';
import { AlertsIndicator } from './AlertsIndicator';
import IconButton from '@components/common/IconButton';
import { Position } from '@blueprintjs/core';
import React from 'react';

import styles from './AlertDetailsPanel.module.scss';

type AlertDetailsPanelProps = SwitchBaseChildProps<{
  alert: AlertConfig;
  entityId?: string;
  onOverride?: (alert: AlertConfig, channel?: AlertChannel) => void;
}>;

export const AlertDetailsPanel: React.FC<AlertDetailsPanelProps> = ({ alert, entityId, onOverride, ...props }) => {
  const { total, alerting, isAlerting } = getAlertChannelStats(alert.channels);
  const channelStats = [<span key="total">{total} total</span>];

  const handleOverrideClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      onOverride?.(alert);
    },
    [alert, onOverride]
  );

  if (isAlerting) {
    channelStats.push(
      <span key="separator" className="mx-4">
        |
      </span>
    );
    channelStats.push(
      <span key="alerting" className="text-red-base">
        {alerting} alerting
      </span>
    );
  }

  const alertHeader = (
    <div className={styles.alertHeader}>
      {alert && <AlertsIndicator alerts={[alert]} variant="tooltip" showBadge />}
      <CopyText variant="copy-6" additionalClass="ml-24">
        {alert.info.name}
      </CopyText>
      {total > 0 && (
        <CopyText variant="copy-6" additionalClass="ml-24">
          <span className="mr-8 text-blue-gray-base">Channels</span>
          {channelStats}
        </CopyText>
      )}

      <IconButton
        icon="BulkOverride"
        onClick={handleOverrideClick}
        title="Bulk Override"
        tooltipProps={{ targetProps: { className: 'ml-auto' }, position: Position.TOP }}
      />
    </div>
  );

  return (
    <Panel leftAlignToggler title={alertHeader} variant="stealth" {...props}>
      {alert.channels?.length && (
        <>
          <CopyText variant="copy-3" additionalClass="mb-16">
            Alerting channels
          </CopyText>

          <ChannelTable
            channels={alert.channels}
            onOverride={(channel: AlertChannel) => onOverride?.(alert, channel)}
          />
        </>
      )}

      <AlertTabs alert={alert} entityId={entityId} />
    </Panel>
  );
};
