import Toolbar from '@components/common/Toolbar';
import c from 'classnames';
import React from 'react';
import { RowsPerPageOption } from '../types';
import TablePagination from './TablePagination/TablePagination';

import styles from './BottomToolbar.module.scss';

export type BottomToolbarProps = {
  paginated?: boolean;
  minimalMode?: boolean;
  page: number;
  rowCount: number;
  rowsPerPageOptions: RowsPerPageOption[];
  rowsPerPage: number;
  onChangePage: (newPage: number) => void;
  onChangeRowsPerPage: (newRowsPerPage: number) => void;
  additionalClass?: string;
};

export const BottomToolbar: React.FC<BottomToolbarProps> = ({
  minimalMode = false,
  paginated = true,
  page,
  onChangePage,
  rowsPerPage,
  onChangeRowsPerPage,
  rowCount,
  rowsPerPageOptions,
  additionalClass,
}) => {
  return (
    <Toolbar additionalClass={c(styles.bottomToolbar, additionalClass)}>
      {paginated && (
        <TablePagination
          minimalMode={minimalMode}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onGoToPage={newPage => onChangePage(newPage)}
          onPreviousPage={() => onChangePage(page - 1)}
          onNextPage={() => onChangePage(page + 1)}
          onRowsPerPageChange={newRowsPerPage => onChangeRowsPerPage(newRowsPerPage)}
          rowCount={rowCount}
        />
      )}
    </Toolbar>
  );
};
