import React, { useCallback, useState } from 'react';
import SearchBar from '@components/common/SearchBar';
import VerticalSeparator from '@components/separators/VerticalSeparator';
import IconButton from '@components/common/IconButton';
import Drawer from '@components/common/Drawer';
import ActiveSuggestions from '@components/common/SearchBar/components/ActiveSuggestions';
import { SearchHit } from '@infrastructure/api/BaseNClient/useGlobalSearch';

type Props = {
  open: boolean;
  onClose: () => void;
};

const MobileSearchOverlay: React.FC<Props> = ({ open, onClose }) => {
  const [results, setResults] = useState<SearchHit[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const onSuggestionClick = useCallback(
    (suggestion: SearchHit) => {
      setSearchTerm(suggestion.name);
      onClose();
    },
    [onClose]
  );

  const onResults = (params: { data: SearchHit[]; searchTerm: string }) => {
    setSearchTerm(params.searchTerm);
    setResults(params.data);
  };

  return (
    <Drawer
      additionalClasses={{ root: 'w-full' }}
      isOpen={open}
      position="left"
      onClose={onClose}
      size="100%"
      portalClassName="z-50"
    >
      <div className="flex flex-col flex-1">
        <div className="flex items-center justify-center pl-16">
          <IconButton ariaLabel="close-search" icon="Back" onClick={onClose} />
          <VerticalSeparator small />
          <SearchBar noIcon onResults={onResults} hideInteraction />
        </div>
        <ActiveSuggestions
          inline
          suggestions={{ data: results }}
          searchTerm={searchTerm}
          onSuggestionClick={onSuggestionClick}
        />
      </div>
    </Drawer>
  );
};

export default MobileSearchOverlay;
