import Card from '@components/common/Card/Card';
import ReactDiffViewer, { ReactDiffViewerProps } from 'react-diff-viewer-continued';
import { useDeviceConfigHistoryQuery } from '@infrastructure/api/BaseNClient/useDeviceConfigHistoryQuery';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { TWO_MINUTES } from '@constants/date';
import React from 'react';
import { CodeEditor } from '@components/common/CodeEditor/CodeEditor';
import { StoredToggleButtonGroup } from '@components/common/form/ToggleButton/StoredToggleButtonGroup';
import { retrieveItem } from '@utils/storage';
import c from 'classnames';
import {
  ComputedLineInformation,
  DiffType,
  computeLineInformation,
} from 'react-diff-viewer-continued/lib/compute-lines';
import { DiffStat } from './DiffStat';

import styles from './DiffPanel.module.scss';

const SPLIT_VIEW_STORAGE_KEY = 'cm-split-view';
const ONLY_DIFF_STORAGE_KEY = 'cm-only-diff';

type Diff = {
  added: number;
  removed: number;
  modified: number;
};

type DiffPanelProps = Omit<ReactDiffViewerProps, 'newValue' | 'oldValue'> & {
  entityId1?: string;
  version1?: string;
  entityId2?: string;
  version2?: string;
};

export const DiffPanel = ({ entityId1, version1, entityId2, version2, ...otherProps }: DiffPanelProps) => {
  const [splitView, setSplitView] = React.useState<string>(retrieveItem(SPLIT_VIEW_STORAGE_KEY) ?? 'Split');
  const [onlyDiff, setOnlyDiff] = React.useState<string>(retrieveItem(ONLY_DIFF_STORAGE_KEY) ?? 'Only diff');

  const isQuery1Enabled = !!(entityId1 && version1);
  const isQuery2Enabled = !!((entityId1 !== entityId2 || version1 !== version2) && version2);

  const isSingleView = !entityId2 && !version2;

  const config1Query = useDeviceConfigHistoryQuery(
    {
      entityId: entityId1!,
      start: (+version1! - TWO_MINUTES).toString(),
      end: (+version1! + TWO_MINUTES).toString(),
    },
    {
      enabled: isQuery1Enabled,
    }
  );

  const config2Query = useDeviceConfigHistoryQuery(
    {
      entityId: entityId2 ?? entityId1!,
      start: (+version2! - TWO_MINUTES).toString(),
      end: (+version2! + TWO_MINUTES).toString(),
    },
    {
      enabled: isQuery1Enabled && isQuery2Enabled,
    }
  );

  const isLoading = config1Query.isLoading || config2Query.isLoading;
  const isError = config1Query.isError || config2Query.isError;
  const areConfigsAvailable = !!(config1Query.data?.result?.[0]?.config && config2Query.data?.result?.[0]?.config);

  const diff = React.useMemo(() => {
    const result = {
      added: 0,
      removed: 0,
      modified: 0,
    };

    if (areConfigsAvailable) {
      const info: ComputedLineInformation = computeLineInformation(
        config2Query.data?.result?.[0]?.config!,
        config1Query.data?.result?.[0]?.config!
      );
      return info.lineInformation.reduce((stats, { right, left }) => {
        if (left?.type === DiffType.REMOVED || right?.type === DiffType.REMOVED) {
          stats.removed++;
        }

        if (left?.type === DiffType.ADDED || right?.type === DiffType.ADDED) {
          stats.added++;
        }

        if (left?.type === DiffType.CHANGED || right?.type === DiffType.CHANGED) {
          stats.modified++;
        }

        return stats;
      }, result);
    }

    return result;
  }, [areConfigsAvailable, config1Query.data?.result, config2Query.data?.result]);

  const renderHeader = () => {
    return (
      <div className="w-full hbox">
        <div className="copy-4">Configuration</div>

        {!isSingleView && !isLoading && !isError && areConfigsAvailable && (
          <div className="gap-16 ml-auto hbox">
            <DiffStat variant="added" amount={diff.added ?? 0} />
            <DiffStat variant="removed" amount={diff.removed ?? 0} />
            {/* <DiffStat variant="modified" amount={diff.modified ?? 0} /> */}

            <StoredToggleButtonGroup
              name="cm-split-view"
              options={['Unified', 'Split']}
              onChange={setSplitView}
              value={splitView}
            />
            <StoredToggleButtonGroup
              name="cm-only-diff"
              options={['All lines', 'Only diff']}
              onChange={setOnlyDiff}
              value={onlyDiff}
            />
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner centered additionalClass="p-40" />;
    }

    if (isError) {
      return <div className={c(styles.info, styles.error)}>Error loading config</div>;
    }

    if (!isQuery1Enabled) {
      return <div className={styles.info}>Please select device and version</div>;
    }

    if (isSingleView) {
      return config1Query.data?.result?.[0]?.config ? (
        <CodeEditor value={config1Query.data.result[0].config} readOnly additionalClass={styles.codeEditor} />
      ) : (
        <div className={c(styles.info, styles.error)}>Config not found</div>
      );
    }

    if (!isQuery2Enabled) {
      return <div className={styles.info}>Select a version to compare to</div>;
    }

    if (config1Query.data?.result?.[0]?.config === config2Query.data?.result?.[0]?.config) {
      return (
        <div className="items-stretch w-full vbox">
          <div className={styles.info}>Configurations are identical</div>
          <CodeEditor value={config1Query.data?.result?.[0]?.config} readOnly additionalClass={styles.codeEditor} />
        </div>
      );
    }

    return config2Query.data?.result?.[0]?.config ? (
      <ReactDiffViewer
        {...otherProps}
        newValue={config1Query.data?.result?.[0].config}
        oldValue={config2Query.data?.result?.[0].config}
        splitView={splitView === 'Split'}
        showDiffOnly={onlyDiff === 'Only diff'}
      />
    ) : (
      <div className={c(styles.info, styles.error)}>Config not found</div>
    );
  };

  return (
    <Card header={renderHeader()} additionalClass={styles.diffPanel} noPadding>
      {renderContent()}
    </Card>
  );
};

DiffPanel.defaultProps = {
  splitView: true,
  showDiffOnly: true,
  styles: {
    wordDiff: {
      padding: 0,
    },
  },
};
