import { GenericOptionsResponse } from '@infrastructure/api/types';
import { useField } from 'formik';
import { OptionsTagInput, OptionsTagInputProps } from './OptionsTagInput';

export type OptionsTagInputFieldProps = Omit<OptionsTagInputProps, 'value' | 'onChange'> & {
  name?: string;
};

export function OptionsTagInputField<T extends GenericOptionsResponse = GenericOptionsResponse>({
  name,
  ...props
}: OptionsTagInputFieldProps) {
  const [field, , helpers] = useField<string[]>({ name: name ?? props.property });

  const handleChange = (items: string[]) => {
    helpers.setValue(items);
  };

  return <OptionsTagInput<T> {...props} {...field} onChange={handleChange} />;
}
