import React, { useState, useEffect, useCallback } from 'react';
import c from 'classnames';
import ROUTES from '@infrastructure/routes';
import { selectToken, useAuth } from '@redux/auth';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import Drawer, { DrawerSize } from '@components/common/Drawer';
import { useBreakpoint } from '@services';
import Header from '../Header';
import { Sidebar } from '../Sidebar';
import PageErrorBoundary from './components/PageErrorBoundary';
import styles from './style.module.scss';
import { selectSidebarOpen, setSidebarOpen, toggleSidebar } from '@redux/layout';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { apiClient } from '@infrastructure/api';
import { useSettings } from '@redux/settings';

const Layout: React.FC = ({ children }) => {
  useSettings();
  const location = useLocation();
  const token = useAppSelector(selectToken);

  // const isErrorRoute = pathname.startsWith('/_error') || pathname.startsWith('/500') || pathname.startsWith('/404');
  // const isUnauthenticatedRoute = pathname.startsWith(ROUTES.login) || pathname.startsWith(ROUTES.forgotPassword);

  if (!token) {
    return <Navigate replace to={ROUTES.login} state={{ from: location }} />;
  }

  // make sure that API client has the latest token
  apiClient.setToken(token, true);

  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
};

export const ErrorLayout: React.FC = ({ children }) => (
  <div className={styles.layoutContainer}>
    <div className={styles.cleanContentWrapper}>{children}</div>
  </div>
);

export const BaseLayout: React.FC = ({ children }) => {
  const {
    breakpoints: { isMobileLandscape, isDesktop },
  } = useBreakpoint();
  const sidebarOpen = useAppSelector(selectSidebarOpen);
  const [showMobileSidebar, setShowMobileSidebar] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onToggleSidebar = useCallback(() => dispatch(toggleSidebar()), [dispatch]);

  useEffect(() => {
    // start with sidebar closed on anything below desktop
    if (!isDesktop) {
      dispatch(setSidebarOpen(false));
    }

    setShowMobileSidebar(!isDesktop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowMobileSidebar(!isDesktop);
  }, [isDesktop]);

  return (
    <div className={styles.layoutContainer}>
      <Header />

      <PageErrorBoundary>
        <div className={styles.contentWrapper}>
          {showMobileSidebar ? (
            <Drawer
              size={isMobileLandscape ? DrawerSize.STANDARD : DrawerSize.LARGE}
              isOpen={!isDesktop && sidebarOpen}
              onClose={onToggleSidebar}
              position="left"
              additionalClasses={{
                body: styles.sidebarDrawer,
                dialogBody: styles.sidebarDrawerBody,
              }}
            >
              <Sidebar open={sidebarOpen} drawer />
            </Drawer>
          ) : (
            <Sidebar open={sidebarOpen} />
          )}
          <PageErrorBoundary>
            <div className={c(styles.mainContent, sidebarOpen && styles.mainContentWithSidebar)}>{children}</div>
          </PageErrorBoundary>
        </div>
      </PageErrorBoundary>
    </div>
  );
};

export const StandaloneLayout = ({ children }: React.PropsWithChildren<{}>) => {
  useSettings();
  const location = useLocation();
  const token = useAppSelector(selectToken);

  if (!token) {
    return <Navigate replace to={ROUTES.login} state={{ from: location }} />;
  }

  // make sure that API client has the latest token
  apiClient.setToken(token, true);

  return (
    <div className={styles.layoutContainer}>
      <PageErrorBoundary>{children}</PageErrorBoundary>
    </div>
  );
};

export const UnauthenticatedLayout: React.FC = ({ children }) => {
  useSettings();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate replace to={ROUTES.home} />;
  }

  return (
    <div className={styles.layoutContainer}>
      <PageErrorBoundary>
        <div className={styles.mainLoginContent}>{children}</div>
      </PageErrorBoundary>
    </div>
  );
};

export default Layout;
