import { useEffect, useCallback, useRef } from 'react';
import { useState } from 'react';
import { connect, Socket as IoSocket } from 'socket.io-client';
import { PUSH_API_URL } from '@config';
import { curry } from 'lodash';

export type ConnectOptions = {
  token?: string;
  channels?: string[]; // channels to subscribe to and route to onMessage callback
  service?: 'issues' | 'alerts' | 'echo' | 'entitystates' | 'routestates' | 'jumpstation' | 'jumpstation_batch';
  params?: Record<string, any>;
};

export type Message<T = any> = {
  channel: string;
  data?: T;
};

type UseSocketProps<T extends Message> = ConnectOptions & {
  onMessage?: (message: T) => void;
  onError?: (error: Error) => void;
  onConnect?: (socket?: IoSocket | null) => void;
  onDisconnect?: () => void;
  autoReconnect?: boolean;
  autoConnect?: boolean;
};

export type UseSocketResponse = {
  isSocketActive: boolean;
  isConnecting?: boolean;
  isError: boolean;
  error: Error | null;
  reconnectionAttempt: number;
  connectSocket: (options?: ConnectOptions) => IoSocket | null;
  disconnectSocket: () => void;
  sendMessage: (message: Message) => void;
};

export default function useSocket<T extends Message>({
  token,
  service,
  channels = ['message'],
  params = {},
  onMessage,
  onError,
  onConnect,
  onDisconnect,
  autoConnect = true,
  autoReconnect = true,
}: UseSocketProps<T>): UseSocketResponse {
  const [, forceUpdate] = useState(Date.now());
  const socketRef = useRef<IoSocket | null>(null);

  // we memoize the response to avoid unnecessary re-renders
  const responseRef = useRef<UseSocketResponse>({
    isSocketActive: false,
    isError: false,
    error: null,
    reconnectionAttempt: 0,
    connectSocket: (options?: ConnectOptions) => init(options),
    disconnectSocket: () => destroy(),
    sendMessage: ({ channel, data }) => socketRef.current?.emit(channel, data),
    isConnecting: autoConnect,
  });

  const handleConnect = () => {
    responseRef.current = {
      ...responseRef.current,
      isSocketActive: true,
      isError: false,
      error: null,
      isConnecting: false,
    };
    onConnect?.(socketRef.current);
    forceUpdate(Date.now());
  };

  const handleDisconnect = useCallback(() => {
    responseRef.current.isSocketActive = false;
    responseRef.current.isConnecting = false;
    onDisconnect?.();
    if (!autoReconnect) {
      destroy();
    } else {
      forceUpdate(Date.now());
    }
  }, [autoReconnect, onDisconnect]);

  const handleConnectError = useCallback(
    (error: Error) => {
      responseRef.current.isSocketActive = false;
      responseRef.current.isError = true;
      responseRef.current.error = error;
      onError?.(error);
      forceUpdate(Date.now());
    },
    [onError]
  );

  const handleReconnectAttempt = useCallback((reconnectionAttempt: number) => {
    responseRef.current.isSocketActive = false;
    responseRef.current.reconnectionAttempt = reconnectionAttempt;
    responseRef.current.isConnecting = true;
    forceUpdate(Date.now());
  }, []);

  const handleMessage = useCallback(
    (channel: string, data: any) => {
      onMessage?.({
        channel,
        data,
      } as T);
    },
    [onMessage]
  );

  const messageCallbackRefs = useRef(channels?.map(channel => ({ channel, callback: curry(handleMessage)(channel) })));

  const init = useCallback(
    (options?: ConnectOptions) => {
      if (!token) {
        console.error('No token provided for socket connection');
        return null;
      }

      if (!socketRef.current) {
        let socket = connect(PUSH_API_URL, {
          transports: ['websocket'],
          reconnection: autoReconnect,
          reconnectionAttempts: 5,
          ...options,
          query: {
            service,
            token,
            ...params,
            ...options?.params,
          },
        });

        socket.on('disconnect', handleDisconnect);
        socket.on('connect', handleConnect);
        socket.on('connect_error', handleConnectError);
        socket.io.on('reconnect_attempt', handleReconnectAttempt);
        socket.on('error', handleConnectError);
        messageCallbackRefs.current.forEach(({ channel, callback }) => socket.on(channel, callback));

        socketRef.current = socket;
        responseRef.current.isConnecting = true;

        forceUpdate(Date.now());
      }
      return socketRef.current;
    },
    // we do not support changing props on an established socket session
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const destroy = useCallback(
    () => {
      const socket = socketRef.current;

      if (socket) {
        socket.off('disconnect', handleDisconnect);
        socket.off('connect', handleConnect);
        socket.off('connect_error', handleConnectError);
        socket.io.off('reconnect_attempt', handleReconnectAttempt);
        socket.off('error', handleConnectError);
        messageCallbackRefs.current.forEach(({ channel, callback }) => socket.off(channel, callback));
        socket.close();
      }

      socketRef.current?.close();
      socketRef.current = null;

      responseRef.current = {
        ...responseRef.current,
        isSocketActive: false,
        isError: false,
        error: null,
        isConnecting: false,
      };

      forceUpdate(Date.now());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Initialization Hook
  useEffect(() => {
    if (!socketRef.current && autoConnect) {
      init();
    }

    return destroy;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return responseRef.current;
}
