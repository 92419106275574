import { useValidatedQuery } from '@services/useValidatedQuery';
import { UseQueryOptions, UseQueryResult, useMutation, useQueryClient } from 'react-query';
import { z } from 'zod';
import ENDPOINTS from '../endpoints';
import { apiClient } from '..';
import { useAppDispatch } from '@infrastructure/redux/store';
import { setNotification } from '@infrastructure/redux/notifications';

export const storedColumnSchema = z.object({
  order: z.number().optional(),
  width: z.number().or(z.string()).optional(),
  visible: z.boolean().optional(),
});

export const storedConfigSchema = z.object({
  rowsPerPage: z.number().optional(),
  rowHeight: z.enum(['small', 'medium', 'large']).optional(),
  sort: z.union([z.literal('asc'), z.literal('desc'), z.literal(false)]).optional(),
  sortBy: z.string().nullable().optional(),
  filtersInPopup: z.boolean().optional(),
});

export const storedTableLayoutSchema = z.object({
  $version: z.number().optional(),
  columns: z.record(storedColumnSchema).optional(),
  config: storedConfigSchema.optional(),
  filters: z.record(z.any()).optional(),
});

const tableViewRequestSchema = z.object({
  filter: z.string().optional(),
  start_index: z.number().optional(),
  end_index: z.number().optional(),
  maximum: z.number().optional(),
});

const tableViewResponseSchema = z.object({
  result: z.string(),
  total: z.number(),
  data: z.array(storedTableLayoutSchema),
});

export type StoredColumn = z.infer<typeof storedColumnSchema>;
export type StoredTableConfig = z.infer<typeof storedConfigSchema>;
export type StoredTableLayout = z.infer<typeof storedTableLayoutSchema>;

export type TableViewRequest = z.infer<typeof tableViewRequestSchema>;
export type TableViewResponse = z.infer<typeof tableViewResponseSchema>;

export const useTableViewsQuery = <T extends TableViewResponse = TableViewResponse>(
  searchParams: TableViewRequest,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.settings, searchParams, tableViewResponseSchema, queryOptions);
};

export const useTableViewByKeyQuery = <T extends StoredTableLayout = StoredTableLayout>(
  key: string,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.settingsByKey, { key }, storedTableLayoutSchema, queryOptions);
};

export const useUpdateStoredConfigMutation = (key: string) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const endpoint = ENDPOINTS.settingsByKey.replace(':key', key);

  return useMutation((newLayout: StoredTableLayout) => apiClient.put(endpoint, newLayout), {
    onSuccess: () => {
      queryClient.invalidateQueries(endpoint);
    },
    onError: () => {
      dispatch(
        setNotification({
          type: 'error',
          title: 'Operation failed',
          description: `Failed to update '${key}' table settings`,
        })
      );
    },
  });
};
