import React, { FC, useEffect } from 'react';
import Error404Page from '@components/pages/Error404Page';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import { useLoadingContext } from 'react-router-loading';
import { getURLQueryParams } from '@utils/url';
import { pick } from 'lodash';
import { ChartDataSource } from '@infrastructure/redux/widgetPage';
import { ChartDetails } from './components/ChartDetails';

export const ChartPage: FC = () => {
  const source = pick(getURLQueryParams(), ['page', 'plotname', 'metricId', 'alertId', 'entityId']) as ChartDataSource;
  const entityQuery = useEntityQuery({ entityId: source.entityId, alerts: true, include_maintenance: true });
  const loadingContext = useLoadingContext();

  useEffect(() => {
    if (entityQuery.data) {
      loadingContext.done();
    }
  }, [entityQuery?.data, loadingContext]);

  if (!entityQuery.isLoading && !entityQuery.data) {
    return <Error404Page text="Entity not found" />;
  }

  return !entityQuery.isLoading ? <ChartDetails entity={entityQuery.data} source={source} /> : null;
};
