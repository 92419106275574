import React from 'react';
import { createSelector } from 'reselect';
import { AppState } from '@infrastructure/redux/store';
import { IssuesState } from './types';
import sortBy from 'lodash/sortBy';
import CopyText from '@components/typography/CopyText';
import { IssuesQueryRequest } from '@infrastructure/api/BaseNClient/useIssuesQuery';
import { DEFAULT_TABLE_CONFIG, StoredTableConfig } from '@components/common/Table';
import { selectQueryStringParams } from '../location';
import { retrieveItem } from '@utils/storage';
import { PaginationParams, SortingParams } from '@infrastructure/redux/types';
import { isEmpty } from 'lodash';
import { constructIssueListQueryParamsFromView } from '@components/connected/IssueManager/utils';
import { DEFAULT_ISSUE_VIEW } from './config';

export const ISSUES_TABLE_ID = `table-settings-issues`;

export const selectIssueRoot = (state: AppState): IssuesState => state.issues;

export const selectIssues = createSelector(selectIssueRoot, _ => _.items);
export const selectIssuesOrder = createSelector(selectIssueRoot, _ => _.itemsOrder);

export const selectIssuesAsArray = createSelector(selectIssues, selectIssuesOrder, (items, order) =>
  order.map(id => items[id])
);
export const selectIssueViews = createSelector(selectIssueRoot, _ => _.views);
export const selectIssueViewsLength = createSelector(selectIssueViews, issueViews => Object.keys(issueViews).length);

export const createSelectIssueViewById = (id: string) => createSelector(selectIssueViews, map => map[id]);

export const selectIssueDialogs = createSelector(selectIssueRoot, _ => _.dialogs);
export const selectActiveViewKey = createSelector(selectIssueRoot, _ => _.activeView);
export const selectActiveView = createSelector(
  selectIssueViews,
  selectActiveViewKey,
  (views, activeKey) => views[activeKey] ?? DEFAULT_ISSUE_VIEW
);
export const selectActiveViewActions = createSelector(selectActiveView, activeView =>
  activeView?.actions ? sortBy(activeView.actions, 'order') : []
);
export const selectActiveViewGroups = createSelector(selectActiveView, activeGroup => activeGroup.filters.groups);
export const selectActiveIssueKey = createSelector(selectIssueRoot, _ => _.activeIssue);
export const selectActiveIssue = createSelector(selectActiveIssueKey, selectIssues, (activeIssueKey, issues) =>
  activeIssueKey ? issues[activeIssueKey] : null
);
export const selectGroups = createSelector(selectIssueRoot, _ => _.groups);
export const selectSummary = createSelector(
  selectIssueRoot,
  _ =>
    _.summary ?? {
      status_counts: {
        new: 0,
        open: 0,
        frozen: 0,
        tracking: 0,
        closing: 0,
        closed: 0,
      },
      priority_counts: {
        none: 0,
        low: 0,
        normal: 0,
        high: 0,
        critical: 0,
      },
    }
);
export const selectCustomFields = createSelector(selectIssueRoot, _ => _.customFields);
export const selectIssueScriptOptions = createSelector(selectIssueRoot, _ =>
  Object.keys(_.actions || {}).map(id => {
    const action = _.actions[id];

    return {
      key: id,
      label: (
        <div className="flex flex-col w-full truncate">
          <CopyText variant="copy-6" additionalClass="text-gray-1 truncate max-w-full">
            {action.name}
          </CopyText>
          <CopyText variant="copy-8" additionalClass="text-blue-gray-base whitespace-normal">
            {action.description}
          </CopyText>
        </div>
      ),
      value: action.name,
    };
  })
);
export const selectActiveIssueComments = createSelector(selectActiveIssue, activeIssue =>
  (activeIssue?.events || []).filter(event => event.queue === 'COMMENT')
);

export const createSelectIssueById = (id: string) => createSelector(selectIssues, map => map[id]);
export const createSelectViewById = (id: string) => createSelector(selectIssueViews, map => map[id]);

export const selectIssueViewOptions = createSelector(selectIssueViews, issueViews =>
  Object.keys(issueViews).map(viewKey => ({
    label: issueViews[viewKey].name,
    value: issueViews[viewKey].id,
  }))
);

export const selectNoIssuesAvailable = createSelector(selectIssues, issues => Object.keys(issues).length === 0);

// the following might seem exessive, but in reality it's the way to memoize ¯\_(ツ)_/¯
export const selectFilters = createSelector(selectIssueRoot, _ => _.filters);
export const selectResolvedFilters = createSelector(selectFilters, _ => _);

export const selectPagination = createSelector(selectIssueRoot, _ => _.pagination);
export const selectResolvedPagination = createSelector(
  selectPagination,
  selectQueryStringParams,
  (pagination, params) => {
    const storedParams = retrieveItem<StoredTableConfig>(ISSUES_TABLE_ID);
    return {
      rowsPerPage: +(
        params.rowsPerPage ??
        pagination?.rowsPerPage ??
        storedParams?.rowsPerPage ??
        DEFAULT_TABLE_CONFIG.rowsPerPage
      ),
      page: +(params.page ?? pagination?.page ?? DEFAULT_TABLE_CONFIG.page),
      rowCount: pagination?.rowCount,
    } as PaginationParams;
  }
);

export const selectSorting = createSelector(selectIssueRoot, _ => _.sort);
export const selectResolvedSorting = createSelector(selectSorting, selectQueryStringParams, (sort, params) => {
  const storedParams = retrieveItem<StoredTableConfig>(ISSUES_TABLE_ID);
  return {
    sortBy: params.sortBy ?? sort?.sortBy ?? storedParams?.sortBy,
    sort: params.sort ?? sort?.sort ?? storedParams?.sort,
  } as SortingParams;
});

export const selectTotalIssueCount = createSelector(
  selectResolvedPagination,
  _ => _.rowCount ?? DEFAULT_TABLE_CONFIG.rowCount
);

export const selectRowHeight = createSelector(selectIssueRoot, _ => _.rowHeight);

export const selectQueryParams = createSelector(
  selectResolvedFilters,
  selectActiveView,
  selectResolvedPagination,
  selectResolvedSorting,
  (filters, activeView, pagination, sorting) => {
    const params: IssuesQueryRequest = {
      include_children: true,
    };

    if (filters.generalFilter) {
      // issues endpoint currently seems to not support regexes, but apparently uses them internally
      // params.general = `.*${filters.generalFilter}.*`;
      params.general = filters.generalFilter;
    }

    if (!isEmpty(filters.discreteFilters)) {
      const { state, ...discreteFilters } = filters.discreteFilters;

      // there's a mismatch between property name and corresponding filter key ¯\_(ツ)_/¯
      if (state) {
        discreteFilters.status = state;
      }

      Object.keys(discreteFilters).forEach((filterKey: string) => {
        if (discreteFilters[filterKey]) {
          // @ts-ignore
          params[filterKey] = discreteFilters[filterKey];
        }
      });
    }

    params.start_index = (pagination.page - 1) * pagination.rowsPerPage;
    params.end_index = (pagination.page - 1) * pagination.rowsPerPage + pagination.rowsPerPage;

    if (sorting.sort && sorting.sortBy) {
      params.order = `${sorting.sort === 'desc' ? '-' : ''}${sorting.sortBy}`;
    }

    return Object.assign(params, constructIssueListQueryParamsFromView(activeView));
  }
);
