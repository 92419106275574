import React, { useState } from 'react';
import { BaseSelectItem } from './BaseSelect';
import { SelectControl, SelectControlProps } from './SelectControl';

export type SelectControlUncontrolledProps<ItemType extends BaseSelectItem = BaseSelectItem> =
  SelectControlProps<ItemType>;

export const SelectControlUncontrolled = <ItemType extends BaseSelectItem = BaseSelectItem>({
  onChange,
  ...props
}: SelectControlUncontrolledProps<ItemType>) => {
  const [value, setValue] = useState<ItemType | null>(null);

  const handleChange = (item: ItemType | null) => {
    setValue(item);
    onChange?.(item);
  };

  return <SelectControl<ItemType> {...props} value={value} onChange={handleChange} />;
};
