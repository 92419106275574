import HorizontalTitleSeparator from '@components/separators/HorizontalTitleSeparator/HorizontalTitleSeparator';
import React, { FC } from 'react';
import { Field } from './Field';
import { FieldGroupConfig } from './types';

const renderGroup = ({ title, fields }: FieldGroupConfig): JSX.Element => (
  <>
    <HorizontalTitleSeparator title={title} variant="copy-4" additionalClass="mt-8 mb-16" />
    {fields.map(fieldConfig => (
      <Field key={fieldConfig.props.name} {...fieldConfig} />
    ))}
  </>
);

export const FieldGroup: FC<FieldGroupConfig> = ({ renderer, ...config }) => {
  return renderer ? renderer(config, renderGroup) : renderGroup(config);
};
