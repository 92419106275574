import { useBatchRun } from '@infrastructure/push-api/useBatchRun';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { selectToken } from '@infrastructure/redux/auth';
import React from 'react';
import { setNotification } from '@infrastructure/redux/notifications';
import { RunScriptSchema } from './RunScriptWizard.schema';
import Notification from '@components/common/Notification';
import { Box } from '@components/common/Box';
import { ProgressFeedback } from './ProgressFeedback';
import { toBatchParams } from './utils';

import styles from './ProgressWrapper.module.scss';

type ProgressWrapperProps = {
  runningParams: RunScriptSchema;
  onStart?: (batchId: string) => void;
  onDisconnect?: (isCompleted?: boolean) => void;
};

export const ProgressWrapper = ({ runningParams, onStart, onDisconnect }: ProgressWrapperProps) => {
  const token = useAppSelector(selectToken);
  const dispatch = useAppDispatch();

  const handleError = React.useCallback(
    (error: any) => {
      dispatch(
        setNotification({
          type: 'error',
          title: 'Batch run failed',
          description: error.message,
        })
      );
    },
    [dispatch]
  );

  const { status, script, startTime, endTime, progress, summary, abort } = useBatchRun({
    token,
    params: toBatchParams(runningParams),
    onError: handleError,
    onStart,
    onDisconnect,
  });

  const handleAbort = React.useCallback(() => {
    abort();
  }, [abort]);

  return (
    <div className={styles.progressWrapper}>
      <Notification type="info" description="Please, keep this window open until the job is done." />

      <ProgressFeedback details={{ status, startTime, endTime, progress, summary, script }} onAbort={handleAbort} />

      <Box header="Script" additionalClass={styles.scriptBox}>
        <pre className={styles.script}>{runningParams.script.script}</pre>
      </Box>
    </div>
  );
};
