import React, { PropsWithChildren, ReactElement, useCallback, useMemo, useState } from 'react';
import c from 'classnames';
import Toolbar from '@components/common/Toolbar';
import useTextSnippets from '@services/useTextSnippets';
import IconButton from '@components/common/IconButton';
import {
  TableColumns,
  TableItemAction,
  ColumnWidths,
  ColumnVisibilities,
  ColumnsOrder,
} from '@components/common/Table/types';
import CopyText from '@components/typography/CopyText';
import fillTemplate from '@utils/fillTemplate';
import Icon from '@components/common/Icon';
import Tooltip from '@components/common/Tooltip';
import { Menu, MenuItem } from '@components/common/Menu';
import { PopoverPosition } from '@blueprintjs/core';
import Popover from '@components/common/Popover';
import { Classes } from '@blueprintjs/popover2';
import ColumnToggleAndOrderPopover from './components/ColumnToggleAndOrderPopover';
import { useTableConfig } from '@components/common/Table/TableConfigContext';
import { getRowHeightIcon, isActionDisabled } from '../../utils';

import styles from './TableToolbar.module.scss';

export type TableToolbarProps<T extends object = object> = PropsWithChildren<{
  additionalClass?: string;
  selectedItems: T[];
  columns: TableColumns<T>;
  itemActions?: TableItemAction<T>[];
  onColumnVisibilityToggle?: (colVisibility: ColumnVisibilities) => void;
  onColumnOrderChange?: (colOrder: ColumnsOrder) => void;
  onColumnResize?: (colWidths: ColumnWidths) => void;
  onToggleRowHeight: () => void;
}>;

export function TableToolbar<T extends object = object>({
  additionalClass,
  columns,
  selectedItems,
  itemActions,
  onColumnVisibilityToggle,
  onToggleRowHeight,
  onColumnOrderChange,
  onColumnResize,
  children,
}: TableToolbarProps<T>): ReactElement | null {
  const { selectable, paginated, rowHeight, columnsToggleable, rowHeightToggleable } = useTableConfig();
  const allColumns = useMemo(() => Object.values(columns), [columns]);
  const tableTexts = useTextSnippets('table');
  const [columnsOpen, setColumnsOpen] = useState(false);

  const handleColumnsClick = () => {
    setColumnsOpen(true);
  };

  const handleClose = useCallback(() => {
    setColumnsOpen(false);
  }, []);

  const topActions = useMemo(() => itemActions?.filter(action => !action.collapse), [itemActions]);
  const collapsedActions = useMemo(() => itemActions?.filter(action => action.collapse), [itemActions]);

  return (
    <>
      <Toolbar additionalClass={c(styles.toolbar, additionalClass)}>
        {selectable && (
          <div className={styles.leftButtons}>
            <CopyText variant="copy-6" additionalClass="desktop:mx-16 mx-8">
              {selectedItems.length === 0 && tableTexts.noItemsSelected}
              {selectedItems.length === 1 && `${tableTexts.oneItemSelected}`}
              {selectedItems.length > 1 && fillTemplate(tableTexts.itemsSelected, { number: selectedItems.length })}
            </CopyText>

            {selectedItems.length > 0 && !!(topActions?.length || collapsedActions?.length) && (
              <div className={styles.tableItemActionsContainer}>
                {topActions?.map(itemAction => {
                  const isDisabled = isActionDisabled(itemAction, selectedItems);

                  return (
                    <Tooltip
                      // disabled={isDisabled}
                      content={isDisabled ? itemAction.hint! : itemAction.label!}
                      key={itemAction.id}
                      position="bottom"
                      usePortal
                      noIndicatorClass
                      renderTarget={({ isOpen, ...props }) => (
                        <div {...props} className={c(props.className, 'mr-8')}>
                          <IconButton
                            disabled={isDisabled}
                            onClick={(e: React.MouseEvent<HTMLElement>) =>
                              itemAction.handler(selectedItems, e.currentTarget)
                            }
                            variant="stealth"
                            icon={itemAction.icon!}
                            additionalClass={styles.toolbarButton}
                            size="s"
                          />
                        </div>
                      )}
                    />
                  );
                })}

                {!!collapsedActions?.length && (
                  <Popover
                    position={PopoverPosition.BOTTOM}
                    small
                    content={
                      <Menu>
                        {collapsedActions.map(itemAction => {
                          const isDisabled = isActionDisabled(itemAction, selectedItems);
                          return (
                            <MenuItem
                              disabled={isDisabled}
                              key={itemAction.id}
                              onClick={(e: React.MouseEvent<HTMLElement>) =>
                                itemAction.handler(selectedItems, e.currentTarget)
                              }
                              text={itemAction.label}
                              icon={itemAction.icon ? <Icon name={itemAction.icon} size="s" /> : null}
                            />
                          );
                        })}
                      </Menu>
                    }
                    noArrows
                    preserveTarget
                  >
                    <IconButton
                      variant="stealth"
                      icon="MoreVertical"
                      size="xs"
                      additionalClass={c(styles.toolbarButton, Classes.POPOVER2_TARGET)}
                    />
                  </Popover>
                )}
              </div>
            )}
          </div>
        )}

        <div className={styles.centerButtons}>{children}</div>

        {(columnsToggleable || rowHeightToggleable || paginated) && (
          <div className={styles.rightButtons}>
            {allColumns.length > 1 && columnsToggleable && (
              <ColumnToggleAndOrderPopover<T>
                columns={columns}
                onClose={handleClose}
                isOpen={columnsOpen}
                onColumnVisibilityToggle={onColumnVisibilityToggle}
                onColumnOrderChange={onColumnOrderChange}
                onColumnResize={onColumnResize}
              >
                <IconButton
                  additionalClass="inline-flex"
                  title={tableTexts.toggleColumns}
                  icon="FilterColumns"
                  onClick={handleColumnsClick}
                />
              </ColumnToggleAndOrderPopover>
            )}
            {rowHeightToggleable && (
              <IconButton
                additionalClass="inline-flex"
                title={tableTexts.toggleRowHeight}
                icon={getRowHeightIcon(rowHeight)}
                onClick={onToggleRowHeight}
              />
            )}
          </div>
        )}
      </Toolbar>
    </>
  );
}
