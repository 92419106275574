import { TimeSeriesWidgetSubConfig } from '@redux/widgetPage/types';
import { merge } from 'lodash';
import { ChartConfigGenerator } from '../types';
import getLineConfig from './line';

const getConfig: ChartConfigGenerator<TimeSeriesWidgetSubConfig> = props => {
  const config = getLineConfig(props);

  // using lodash merge here, since we do not want to concat series arrays
  const echartsConfig = merge(config.echartsConfig, {
    series: props?.channels?.map(() => ({
      type: 'bar',
    })),
  });

  return {
    ...config,
    echartsConfig,
  };
};

export default getConfig;
