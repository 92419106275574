import CopyText from '@components/typography/CopyText';
import { apiClient } from '@infrastructure/api';
import { PaginatedQueryRequest } from '@infrastructure/api/BaseNClient/usePaginatedQuery';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import { GenericOptionsResponse, GenericPaginatedResponse } from '@infrastructure/api/types';
import { isUndefined, startCase } from 'lodash';
import React, { useCallback } from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';
import { InfiniteChecklist, InfiniteChecklistProps } from '../InfiniteSelect/InfiniteChecklist';
import { OptionType } from './types';
import PanelUncontrolled from '@components/common/Panel/PanelUncontrolled';
import { BasePanelProps } from '@components/common/Panel';
import c from 'classnames';

import styles from './OptionsChecklist.module.scss';

export type OptionsQueryRequest = PaginatedQueryRequest & {
  filter?: string;
};

export type OptionsChecklistProps = Omit<InfiniteChecklistProps, 'getItemsQuery' | 'itemToLabel' | 'itemToId'> & {
  label?: string;
  variant?: BasePanelProps['variant'];
  property: OptionType;
  collapsed?: boolean;
  collapsible?: boolean;
  keepState?: boolean;
  additionalClass?: string;
};

const itemToLabel = (item: any): string => item?.name ?? '';

export function OptionsChecklist<T extends GenericOptionsResponse = GenericOptionsResponse>({
  label,
  variant = 'control',
  property,
  filterable = true,
  collapsed = false,
  collapsible = true,
  keepState = true,
  additionalClass,
  ...props
}: OptionsChecklistProps) {
  const queryClient = useQueryClient();

  const getItemsQuery = useCallback(
    (start_index: number, end_index: number, filterStr?: string) => {
      const queryParams: OptionsQueryRequest = {
        start_index,
        end_index,
      };

      if (filterStr) {
        queryParams.filter = `.*${filterStr}.*`;
      }

      return queryClient.fetchQuery(
        [property, queryParams],
        () =>
          apiClient.get<GenericPaginatedResponse<T>>(ENDPOINTS.getOptions.replace(':property', property), queryParams),
        {
          ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<GenericPaginatedResponse<T>, Error>),
        }
      );
    },
    [queryClient, property]
  );

  const rowContentsRenderer = useCallback((item: T): JSX.Element => {
    return (
      <div className={styles.row}>
        <div className={styles.name} title={itemToLabel(item)}>
          {itemToLabel(item)}
        </div>
        {!isUndefined(item?.entities) && (
          <CopyText variant="copy-6" additionalClass={styles.counter}>
            {item.entities}
          </CopyText>
        )}
      </div>
    );
  }, []);

  return (
    <PanelUncontrolled
      title={label ?? startCase(property)}
      variant={variant}
      additionalClasses={{ root: c(styles.optionsChecklist, additionalClass) }}
      active={!collapsed}
      collapsible={collapsible}
      keepState={keepState}
    >
      <InfiniteChecklist<T>
        key={property}
        filterable={filterable}
        {...props}
        getItemsQuery={getItemsQuery}
        itemToLabel={itemToLabel}
        rowContentsRenderer={rowContentsRenderer}
      />
    </PanelUncontrolled>
  );
}
