import { UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericOkApiResponse } from '../types';
import { z } from 'zod';
import { useValidatedQuery } from '@services/useValidatedQuery';
import {
  ALERT_PRIORITIES,
  ALERT_TYPES,
  ALERT_TIMES,
  ALERT_ACCESS,
  ALERT_VISIBILITY,
  MATCH_ALL,
} from '@constants/alert';

export const ALERT_OVERRIDE_DEFAULTS = {
  id: 1,
  alert: 'Active',
  time_nodata: '10min',
  priority: 'none',
  active_time: '10min',
  green: '0',
  red: '0',
  channels: MATCH_ALL,
  comment: {
    visibility: 'standard',
    access: 'control',
    text: '',
  },
} as AlertOverride;

export const alertTypeSchema = z.enum(ALERT_TYPES);
export const timesSchema = z.enum(ALERT_TIMES);
export const prioritySchema = z.enum(ALERT_PRIORITIES);
export const accessSchema = z.enum(ALERT_ACCESS);
export const visibilitySchema = z.enum(ALERT_VISIBILITY);

export const commentSchema = z.object({
  text: z.string().optional(),
  access: accessSchema.optional(),
  visibility: visibilitySchema.optional(),
});

export const alertOverrideSchema = z.object({
  expires: z.number().optional(),
  green: z.string().optional(),
  red: z.string().optional(),
  groups: z.string().optional(),
  alert_atl: z.string().optional(),
  active_time: z.string().optional(),
  channels: z.string().optional(),
  alert: alertTypeSchema.optional(),
  escalate_red: z.string().optional(),
  time_nodata: timesSchema.optional(),
  comment: commentSchema.optional(),
  id: z.number(), // more like an index of the order in sequence of overrides
  priority: prioritySchema.optional(),
  escalate_priority: prioritySchema.optional(),
});

export const alertOverridesSchema = z.object({
  id: z.string(),
  overrides: z.array(alertOverrideSchema),
  // not sure what the following ones are for, but we do not rely on them
  name: z.string().optional(),
  page: z.string().optional(),
});

const alertOverridesResponseSchema = z.object({
  result: z.string(),
  alerts: z.array(alertOverridesSchema),
});

type GetAlertOverridesRequest = { id: string } | { name: string; page: string };
type GetAlertOverrideResponse = z.infer<typeof alertOverridesResponseSchema> & GenericOkApiResponse;

export type AlertType = z.infer<typeof alertTypeSchema>;
export type AlertTime = z.infer<typeof timesSchema>;
export type AlertCommentAccess = z.infer<typeof accessSchema>;
export type AlertCommentVisibility = z.infer<typeof visibilitySchema>;
export type AlertOverride = z.infer<typeof alertOverrideSchema>;
export type AlertOverrides = z.infer<typeof alertOverridesSchema>;

export const useAlertOverridesQuery = <T extends GetAlertOverrideResponse = GetAlertOverrideResponse>(
  searchParams: GetAlertOverridesRequest,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(
    ENDPOINTS.alertOverride,
    { alerts: [searchParams] },
    alertOverridesResponseSchema,
    {
      httpMethod: 'post',
      ...(QUERY_CONFIGS.cacheDisabled as UseQueryOptions<T, Error>),
      ...queryOptions,
    }
  );
};
