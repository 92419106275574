import React from 'react';
import { ControlledPanelProps } from '../Panel/Panel';
import SwitchBase, { SwitchBaseProps } from '../SwitchBase/SwitchBase';
import c from 'classnames';

import styles from './style.module.scss';

type PassThroughProps = Pick<ControlledPanelProps, 'icon' | 'variant'>;
export type AccordionProps = SwitchBaseProps<ControlledPanelProps, PassThroughProps>;

const Accordion: React.FC<AccordionProps> = ({ additionalClass, ...props }) => (
  <SwitchBase<ControlledPanelProps, PassThroughProps>
    {...props}
    additionalClass={c(styles.accordion, additionalClass)}
  />
);

export default Accordion;
