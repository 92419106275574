import { Issue, IssueCustomField, IssueGroup, IssueView, IssueViewAction } from '@redux/issues/types';
import flatten from 'lodash/flatten';
import { BadgeVariant } from '@components/common/Badge';
import { ITextSnippets } from '@services/useTextSnippets';
import {
  DEFAULT_FIELDS,
  PRIORITY_OPTIONS,
  STATUS_OPTIONS,
  getPriorityOptions,
  getStatusOptions,
} from '@redux/issues/config';
import { TableColumn, TableColumns, TableItemAction } from '@components/common/Table';
import Icon from '@components/common/Icon';
import { getPriorityColor, getPriorityIcon, timesAndValuesToStripes } from '@utils/issue';
import CopyText from '@components/typography/CopyText';
import { capitalize } from '@utils/string';
import { AlertBar } from './components/AlertBar/AlertBar';
import moment from 'moment';
import { uniq } from 'lodash';
import c from 'classnames';
import { IssueStateBadge } from './components/IssueStateBadge';
import { Filters } from '@infrastructure/redux/types';
import { FieldConfig, FieldsConfig } from '@components/common/form/FormBuilder';
import { EntitiesField, EntitiesFieldProps } from '../EntitiesControl/EntitiesField';
import { toIdMap } from '@utils/misc';
import { EntityLink } from '@components/pages/EntityPage/components/EntityLink';

const getPriorityFilter = (filters: Filters, i18n: ITextSnippets): FieldConfig => ({
  type: 'select',
  props: {
    name: 'priority',
    label: i18n.issues.priority,
    defaultValue: filters.discreteFilters.priority ?? '',
    options: getPriorityOptions(i18n.common),
    inline: false,
    clearable: true,
    placeholder: 'All Priorities',
    popoverProps: { usePortal: false }, // NOTE: this will prevent popup from closing on selection
  },
});

const getStatusFilter = (filters: Filters, i18n: ITextSnippets): FieldConfig => ({
  type: 'select',
  props: {
    name: 'state',
    label: i18n.issues.status,
    defaultValue: filters.discreteFilters.state ?? '',
    options: getStatusOptions(i18n.common),
    inline: false,
    placeholder: 'All Statuses',
    clearable: true,
    popoverProps: { usePortal: false }, // NOTE: this will prevent popup from closing on selection
  },
});

export const getCustomTableActions = (
  handler: (selectedItems: Issue[], action: IssueViewAction) => void,
  customActions: IssueViewAction[]
): TableItemAction<Issue>[] =>
  customActions.map(action => ({
    collapse: true,
    label: action.label,
    id: action.script,
    handler: (selectedItems: Issue[]) => handler(selectedItems, action),
  }));

export const getDefaultTableActions = (
  issuesI18n: ITextSnippets['issues'],
  handlers: Record<string, (selectedItems: Issue[]) => void>
): TableItemAction<Issue>[] => [
  {
    collapse: false,
    label: issuesI18n.acknowledge,
    id: 'acknowledge',
    icon: 'Acknowledge',
    handler: handlers.acknowledge,
  },
  {
    collapse: false,
    label: issuesI18n.changeStatus,
    id: 'changeStatus',
    icon: 'ChangeStatus',
    handler: handlers.changeStatus,
  },
  {
    collapse: false,
    label: issuesI18n.changePriority,
    id: 'changePriority',
    icon: 'ChangePriority',
    handler: handlers.changePriority,
  },
  {
    collapse: false,
    label: issuesI18n.comment,
    id: 'comment',
    icon: 'Comment',
    handler: handlers.comment,
  },
  {
    collapse: false,
    label: issuesI18n.download,
    id: 'download',
    icon: 'Download',
    handler: handlers.download,
  },
];

const priorityOrder = [
  PRIORITY_OPTIONS.CRITICAL,
  PRIORITY_OPTIONS.HIGH,
  PRIORITY_OPTIONS.NORMAL,
  PRIORITY_OPTIONS.LOW,
  PRIORITY_OPTIONS.NONE,
];

const stateOrder = [
  STATUS_OPTIONS.NEW,
  STATUS_OPTIONS.OPEN,
  STATUS_OPTIONS.TRACKING,
  STATUS_OPTIONS.FROZEN,
  STATUS_OPTIONS.CLOSING,
  STATUS_OPTIONS.CLOSED,
];

export const getDefaultTableColumns = (filters: Filters, i18n: ITextSnippets): TableColumns<Issue> =>
  toIdMap([
    {
      type: 'string',
      label: i18n.issues.priority,
      id: 'priority',
      sortable: true,
      width: 100,
      getSorter: (sort, sortBy) => (a: Issue, b: Issue) => {
        const inverse = sort === 'asc' && sortBy === 'priority';

        return a.priority && b.priority
          ? priorityOrder.indexOf(inverse ? a.priority : b.priority) -
              priorityOrder.indexOf(inverse ? b.priority : a.priority)
          : 0;
      },
      valueRenderer(item: Issue) {
        return (
          <div className="w-full hbox">
            <Icon
              size="s"
              name={getPriorityIcon(item.priority)}
              additionalClass={c(`inline-flex mr-8`, (item && getPriorityColor(item.priority)) ?? 'text-alert-gray')}
            />
            <CopyText variant={item.state === 'new' ? 'copy-5' : 'copy-6'} additionalClass="truncate">
              {capitalize(item.priority ?? 'none')}
            </CopyText>
          </div>
        );
      },
      filter: {
        fields: [getPriorityFilter(filters, i18n)],
      },
    },
    {
      type: 'custom',
      label: i18n.issues.entity,
      id: 'entity',
      sortable: true,
      valueRenderer(item: Issue) {
        if (!item.entity_data) {
          return null;
        }

        const entity = {
          id: item.entity_data.id,
          name: item.entity_data.name,
        };
        const parentEntity =
          item.parent_entity_id && item.parent_entity
            ? {
                id: item.parent_entity_id,
                name: item.parent_entity,
              }
            : undefined;

        return (
          <EntityLink
            entity={entity}
            parentEntity={parentEntity}
            variant={item.state === 'new' ? 'copy-5' : 'copy-6'}
          />
        );
      },
    },
    {
      type: 'string',
      label: i18n.issues.state,
      id: 'state',
      sortable: true,
      width: 100,
      resizable: true,
      valueRenderer(item: Issue) {
        return <IssueStateBadge issue={item} />;
      },
      getSorter: (sort, sortBy) => (a: Issue, b: Issue) => {
        const inverse = sort === 'asc' && sortBy === 'state';

        return a.state && b.state
          ? stateOrder.indexOf(inverse ? a.state : b.state) - stateOrder.indexOf(inverse ? b.state : a.state)
          : 0;
      },
      filter: {
        fields: [getStatusFilter(filters, i18n)],
      },
      filtering: filters.discreteFilters.status !== undefined,
    },
    {
      type: 'string',
      label: i18n.issues.group,
      id: 'group',
      sortable: true,
    },
    {
      type: 'custom',
      label: i18n.issues.alerts,
      id: 'alerts',
      valueRenderer(item: Issue) {
        if (item.alert_data && item.alert_data.greenreds && item.alert_data.greenreds.length > 0) {
          const stripes = timesAndValuesToStripes(item.alert_data.times, item.alert_data.greenreds);

          return <AlertBar cappedWidth stripes={stripes} additionalClass="justify-end" />;
        }

        return (
          <CopyText variant="copy-6" additionalClass="text-gray-3 text-right">
            {i18n.issues.noData}
          </CopyText>
        );
      },
    },
    {
      type: 'date',
      label: i18n.issues.created,
      id: 'created',
      sortable: true,
    },
    {
      type: 'date',
      label: i18n.issues.lastUpdate,
      id: 'last_update',
      valueRenderer(item: Issue) {
        return moment(item.last_update).fromNow();
      },
    },
    {
      type: 'string',
      label: i18n.issues.lastValue,
      id: 'last_value',
    },
    {
      type: 'string',
      label: i18n.issues.externalReference,
      id: 'extref',
      sortable: true,
    },
  ]);

export const getCustomTableColumn = (column: string): TableColumn<Issue> => ({
  type: 'string',
  id: column,
  valueRenderer: (item: Issue) => {
    const fieldParts = getCustomFieldParts(column);

    if (fieldParts.entityType && fieldParts.fieldName) {
      if (fieldParts.entityType === 'alert') {
        const data = item.alert_data && item.alert_data[fieldParts.fieldName as keyof typeof item.alert_data];

        if (data) {
          return data;
        }
      } else if (fieldParts.entityType) {
        const data = item.entity_data && item.entity_data[fieldParts.fieldName as keyof typeof item.entity_data];

        if (data) {
          return data;
        }
      }
    }

    return null;
  },
  label: getCustomFieldLabel(column),
});

export const getTableColumns = (filters: Filters, i18n: ITextSnippets, activeView: IssueView): TableColumns<Issue> => {
  const defaultTableColumns = getDefaultTableColumns(filters, i18n);
  const {
    filters: { custom_fields, fields },
    field_order,
  } = activeView;

  const columns = fields
    .map(field => defaultTableColumns[field])
    .concat(custom_fields.map(customField => getCustomTableColumn(customField)));

  return field_order.reduce((acc, c, i) => {
    const matchingCol = columns.find(col => col?.id === c);

    if (matchingCol) {
      acc[c] = matchingCol;
      acc[c].order = i;
    }

    return acc;
  }, {} as TableColumns<Issue>);
};

export function constructIssueListQueryParamsFromView(view?: IssueView | null): Record<string, string> {
  const filters = view?.filters || {
    groups: [],
    custom_fields: [],
  };

  const issuesQueryParams: Record<string, string> = {
    issueFields: (filters.custom_fields || []).join(','),
  };

  if ((filters.groups?.length ?? 0) > 0) {
    issuesQueryParams.groups = filters.groups.join(',');
  }

  return issuesQueryParams;
}

export function getAvailableIssueGroupOptions(allGroups: IssueGroup[], viewGroups: IssueGroup[]) {
  const rawOptions = uniq(flatten(allGroups.map(group => [group, `!${group}`])));

  viewGroups.forEach(viewGroup => {
    const replaceIndex = viewGroup.includes('!')
      ? rawOptions.indexOf(viewGroup.replace('!', ''))
      : rawOptions.indexOf(`!${viewGroup}`);

    if (replaceIndex > -1) {
      rawOptions.splice(replaceIndex, 1);
    }
  });

  return rawOptions.map(option => ({
    key: option,
    value: option,
    label: option,
    outlined: true,
    variant: (option.includes('!') ? 'error' : 'info') as BadgeVariant,
  }));
}

export function getCustomFieldParts(key: string) {
  return {
    entityType: key.includes('alert') ? 'alert' : 'entity',
    fieldName: key.split('.')[1],
  };
}

export function getCustomFieldLabel(key: string) {
  return key
    .split('.')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
    .join(' ');
}

export function getIssueViewFieldLabel(id: string, issueViewKeysI18n: ITextSnippets['issueViewKeys']) {
  return issueViewKeysI18n[id as keyof typeof issueViewKeysI18n] || getCustomFieldLabel(id);
}

export function getAvailableCustomFieldOptions(allCustomFields: IssueCustomField[]) {
  return allCustomFields.map(customField => ({
    key: customField.key,
    value: customField.key,
    label: getCustomFieldLabel(customField.key),
    outlined: true,
    variant: 'info' as BadgeVariant,
  }));
}

export function getAvailableFieldOptions(issueViewKeysI18n: ITextSnippets['issueViewKeys']) {
  return DEFAULT_FIELDS.map(field => ({
    mandatory: field.id === 'entity',
    key: field.id,
    value: field.id,
    label: getIssueViewFieldLabel(field.id, issueViewKeysI18n),
    outlined: true,
    variant: field.id === 'entity' ? undefined : ('info' as BadgeVariant),
  }));
}

export const getFiltersConfig = (
  filters: Filters,
  i18n: ITextSnippets,
  hideEntityFilter: boolean = false
): FieldsConfig => {
  return [
    getPriorityFilter(filters, i18n),
    getStatusFilter(filters, i18n),
    !hideEntityFilter && {
      type: 'custom',
      props: {
        name: 'entityids',
        label: 'Entities',
        defaultValue: filters.discreteFilters.entityids ?? '',
        fullWidth: true,
        placeholder: 'All Entities',
        filterable: true,
      },
      renderer: (config: FieldConfig) => <EntitiesField {...(config.props as EntitiesFieldProps)} />,
    },
  ].filter(Boolean) as FieldsConfig;
};
