import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { apiClient } from '@infrastructure/api';
import QUERY_CONFIGS from '../query-configs';
import { GenericPaginatedResponse } from '../types';

export type PaginatedQueryRequest = {
  start_index?: number;
  end_index?: number;
  order?: string;
  maximum?: number;
};

export const usePaginatedQuery = <T extends object = object, P extends PaginatedQueryRequest = PaginatedQueryRequest>(
  url: string,
  params: P = {} as P,
  queryOptions: UseQueryOptions<GenericPaginatedResponse<T>, Error> = {}
): UseQueryResult<GenericPaginatedResponse<T>, Error> => {
  return useQuery<GenericPaginatedResponse<T>, Error>(
    [url, { ...params }],
    (): Promise<GenericPaginatedResponse<T>> => apiClient.get<GenericPaginatedResponse<T>>(url, params),
    {
      ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<GenericPaginatedResponse<T>, Error>),
      ...queryOptions,
      keepPreviousData: true,
    }
  );
};
