import { InfoGrid, InfoGridItem } from '@components/layout/InfoGrid/InfoGrid';
import { DEFAULT_DATETIME_FORMAT } from '@constants/date';
import { Note } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import moment from 'moment';
import React from 'react';

import styles from './LogbookPopup.module.scss';

export type LogbookPopupProps = {
  note: Note;
};

export const LogbookPopup: React.FC<LogbookPopupProps> = ({ note }) => {
  const data = React.useMemo<InfoGridItem[]>(
    () =>
      [
        {
          label: 'Reason',
          value: note.reason,
        },
        note.text && {
          label: 'Description',
          value: note.text,
        },
        {
          label: 'Author',
          value: note.author,
        },
        {
          label: 'Created at',
          value: moment(note.timestamp).format(DEFAULT_DATETIME_FORMAT),
        },
      ].filter(Boolean) as InfoGridItem[],
    [note]
  );

  return <InfoGrid additionalClass={styles.logbookPopup} data={data} size="s" />;
};
