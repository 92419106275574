import React, {
  ChangeEvent,
  FocusEvent,
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import c from 'classnames';
import { Boundary, Classes, Position, OverflowListProps, OverflowList } from '@blueprintjs/core';
import { Menu, MenuItem } from '@components/common/Menu';
import Popover, { PopoverProps } from '@components/common/Popover';
import IconButton from '@components/common/IconButton';
import Icon from '@components/common/Icon';
import useTextSnippets from '@services/useTextSnippets';
import { Breadcrumb, BreadcrumbProps } from './Breadcrumb';
import { convertBreadcrumb } from './utils';
import { useLocation } from 'react-router-dom';
import { ToggleInput } from '../form/ToggleInput/ToggleInput';

import styles from './Breadcrumbs.module.scss';

type InternalBreadcrumbProps = BreadcrumbProps & {
  index: number;
};

export interface BreadcrumbsProps {
  minVisibleItems?: number;
  /** if this is sent, the component becomes controlled and no longer tied to router */
  items?: BreadcrumbProps[];
  collapseFrom?: Boundary;
  breadcrumbInputValue?: string;
  onLastBreadcrumbChange?: ChangeEventHandler<HTMLInputElement>;
  onLastBreadcrumbBlur?: FocusEventHandler<HTMLInputElement>;
  inputError?: string;
  renderLastAsInput?: boolean;
  overflowListProps?: Partial<OverflowListProps<BreadcrumbProps>>;
  popoverProps?: PopoverProps;
  /** The title for the very first breadcrumb pointing to the root. */
  rootLabel?: string;
  /** The path for the very first breadcrumb -- what is root?. */
  rootPath?: string;
  /** Boolean indicator whether the root label should be omitted. Example: true Default: false */
  omitRootLabel?: boolean;
  /** Boolean indicator if the labels should be displayed as uppercase. Example: true Default: false */
  labelsToUppercase?: boolean | undefined;
  /** A transformation function that allows to customize the label strings. Receives the label string and has to return a string or React Component */
  transformLabel?: ((title: string) => React.ReactNode) | undefined;
  /** Classes to be used */
  additionalClasses?: {
    root?: string;
    list?: string;
    item?: string;
    activeItem?: string;
  };
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  minVisibleItems = 1,
  additionalClasses = {},
  overflowListProps = {},
  popoverProps = {},
  collapseFrom = Boundary.START,
  items,
  rootLabel,
  rootPath = '/',
  onLastBreadcrumbChange,
  onLastBreadcrumbBlur,
  inputError,
  breadcrumbInputValue = '',
  renderLastAsInput = false,
  omitRootLabel = true,
  labelsToUppercase = false,
  transformLabel,
}) => {
  const { rootLabel: defaultRootLabel } = useTextSnippets('breadcrumbs');
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (!items) {
      const linkPath = location.pathname.split('/');
      linkPath.shift();
      const pathArray = linkPath.map((path, i: number) => ({
        label: convertBreadcrumb(path, labelsToUppercase, transformLabel),
        href: `/${linkPath.slice(0, i + 1).join('/')}`,
      }));
      if (!omitRootLabel) {
        pathArray.unshift({
          label: convertBreadcrumb(rootLabel || defaultRootLabel, labelsToUppercase, transformLabel),
          href: rootPath,
        });
      }
      setBreadcrumbs(pathArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelsToUppercase, omitRootLabel, rootLabel, transformLabel, items]);

  const visibleItemRenderer = (breadcrumbProps: InternalBreadcrumbProps, index: number) => {
    const isCurrent = breadcrumbItemsToUse.length - 1 === breadcrumbProps.index;

    if (isCurrent && renderLastAsInput) {
      return (
        <li key={index}>
          <ToggleInput
            enforceFocusOnInvalid
            initialToggle={false}
            size="l"
            name="breadcrumb-input"
            value={breadcrumbInputValue}
            additionalClass="w-full"
            errorText={inputError}
            onChange={e => onLastBreadcrumbChange?.(e as ChangeEvent<HTMLInputElement>)}
            onKeyDown={e => e.key === 'Enter' && e.currentTarget.blur()}
            onBlur={e => onLastBreadcrumbBlur?.(e as FocusEvent<HTMLInputElement>)}
          />
        </li>
      );
    }

    return (
      <li key={index}>
        <Breadcrumb current={isCurrent} {...breadcrumbProps} />
      </li>
    );
  };

  const renderOverflowBreadcrumb = ({ label, icon, ...props }: BreadcrumbProps, index: number) => {
    return <MenuItem {...props} icon={icon ? <Icon name={icon} /> : undefined} text={label} key={index} />;
  };

  const overflowRenderer = (overflowItems: BreadcrumbProps[]) => {
    const collapsedFromStart = collapseFrom === Boundary.START;
    const position = !collapsedFromStart ? Position.BOTTOM_RIGHT : Position.BOTTOM_LEFT;
    let orderedItems = overflowItems;
    if (collapsedFromStart) {
      // If we're collapsing from the start, the menu should be read from the bottom to the
      // top, continuing with the breadcrumbs to the right. Since this means the first
      // breadcrumb in the props must be the last in the menu, we need to reverse the overflow
      // order.
      orderedItems = overflowItems.slice().reverse();
    }

    return (
      <li>
        <Popover
          position={position}
          disabled={orderedItems.length === 0}
          content={<Menu>{orderedItems.map(renderOverflowBreadcrumb)}</Menu>}
          small
          {...popoverProps}
        >
          <div>
            <IconButton icon="MoreHorizontal" variant="fillOutline" size="xs" round={false} additionalClass="mr-4" />
          </div>
        </Popover>
        {collapsedFromStart && <Icon name="ArrowRight" size="s" additionalClass={styles.separator} />}
      </li>
    );
  };

  const breadcrumbItemsToUse: InternalBreadcrumbProps[] = useMemo(() => {
    let itemsToUse;

    if (items?.length) {
      const breadcrumbItems = items.map(item => ({
        label:
          typeof item.label === 'string'
            ? convertBreadcrumb(item.label, labelsToUppercase, transformLabel)
            : item.label,
        href: item.href,
      }));

      if (!omitRootLabel) {
        breadcrumbItems.unshift({
          label: convertBreadcrumb(rootLabel || defaultRootLabel, labelsToUppercase, transformLabel),
          href: rootPath,
        });
      }

      itemsToUse = breadcrumbItems;
    } else {
      itemsToUse = breadcrumbs || [];
    }

    return itemsToUse.map((item, index) => ({ ...item, index }));
  }, [omitRootLabel, items, breadcrumbs, defaultRootLabel, rootPath, rootLabel, labelsToUppercase, transformLabel]);

  // const onMobileBack = () => {
  //   const path =
  //     breadcrumbItemsToUse &&
  //     breadcrumbItemsToUse.length >= 2 &&
  //     breadcrumbItemsToUse[breadcrumbItemsToUse.length - 2].href;
  //
  //   void router.push(path || rootPath);
  // };

  if (breadcrumbItemsToUse.length === 0) {
    return null;
  }

  return (
    <div className={c(styles.crumbContainer, additionalClasses.root)}>
      <div className={c(Classes.BREADCRUMBS, styles.mobileBreadcrumbs)}>
        {/* <IconButton */}
        {/*  icon="Back" */}
        {/*  variant="stealth" */}
        {/*  size="xs" */}
        {/*  round={false} */}
        {/*  additionalClass="mr-4" */}
        {/*  onClick={onMobileBack} */}
        {/* /> */}
        <OverflowList
          collapseFrom={collapseFrom}
          minVisibleItems={minVisibleItems}
          tagName="ol"
          {...overflowListProps}
          className={c(Classes.BREADCRUMBS, additionalClasses.list)}
          items={breadcrumbItemsToUse}
          overflowRenderer={overflowRenderer}
          visibleItemRenderer={visibleItemRenderer}
        />
      </div>
      <OverflowList
        collapseFrom={collapseFrom}
        minVisibleItems={minVisibleItems}
        tagName="ol"
        {...overflowListProps}
        className={c(Classes.BREADCRUMBS, additionalClasses.list, styles.desktopBreadcrumbs)}
        items={breadcrumbItemsToUse}
        overflowRenderer={overflowRenderer}
        visibleItemRenderer={visibleItemRenderer}
      />
    </div>
  );
};

export default Breadcrumbs;
