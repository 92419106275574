import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { onLoginSuccess, useLoginMutation } from '@infrastructure/api/BaseNClient/useLoginMutation';
import { selectAccount } from '@redux/auth/selectors';
import { AccountInfo } from '@redux/auth/types';
import { updateToken } from '@redux/auth/slice';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import { removeUserToken } from '@utils/token';
import { apiClient } from '@infrastructure/api';

export function useAuth() {
  const dispatch = useAppDispatch();
  const login = useLoginMutation();
  const account = useAccount();
  const navigate = useNavigate();

  const isAdmin = account?.isAdmin ?? false;
  const isAuthenticated = Boolean(account?.token?.data);

  const logout = useCallback(() => {
    dispatch(updateToken({ token: null }));
    removeUserToken();
    apiClient.setToken(null, true);
    navigate(ROUTES.login);
  }, [dispatch, navigate]);

  return {
    login,
    account,
    onLoginSuccess,
    logout,
    isLoading: login.isLoading,
    isAdmin,
    isAuthenticated,
  };
}

export function useAccount(): AccountInfo | null {
  return useAppSelector(selectAccount);
}

export function useIsAuthenticated(): boolean {
  const account = useAccount();

  return Boolean(account?.token);
}
