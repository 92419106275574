import React, { useCallback, useState } from 'react';
import {
  Formik,
  Form as FormikForm,
  FormikValues,
  FormikConfig,
  FormikHelpers,
  FieldInputProps,
  FormikProps as FormikLibProps,
  FormikHandlers,
  FormikErrors,
} from 'formik';
import ValidationHelper from './BaseNValidationHelper';
import { ValidationError } from 'yup';
import { noop } from 'lodash';
import { isZodSchema } from '@utils/type';
import { ZodError } from 'zod';
import { toFormikError } from '@utils/error';

export type FormSubmitOptions<Schema> = FormikHelpers<Schema>;
export type FieldProps<Value = string> = FieldInputProps<Value>;
export type FieldOnChange = FormikHandlers['handleChange'];
export type FieldOnBlur = FormikHandlers['handleBlur'];

export type FormProps<Schema extends FormikValues = FormikValues> = Omit<
  FormikConfig<Schema>,
  'children' | 'onSubmit'
> & {
  children?: React.ReactNode;
  additionalClass?: string;
  onChange?: (newValues: Schema) => void;
  onSubmit?: (newValues: Schema, formikHelpers: FormikHelpers<Schema>) => void | Promise<any>;
  onError?: (error: FormikErrors<Schema>) => void;
};

export type FormikProps<Schema> = FormikLibProps<Schema>;

export function Form<Schema extends FormikValues = FormikValues>({
  validationSchema,
  onChange,
  onSubmit = noop,
  onError,
  additionalClass,
  children,
  ...props
}: FormProps<Schema>) {
  const [canValidate, setCanValidate] = useState<boolean>(true);

  // Formik doesn't provide onChange handler, so we monkey patch around to get one here
  const handleValidate = useCallback(
    (newValues: Schema) => {
      // since we are hooking into default validation, we now need to provide our own validating mechanism
      if (validationSchema) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const schema = typeof validationSchema === 'function' ? validationSchema() : validationSchema;

        return (
          isZodSchema(schema)
            ? schema.parseAsync(newValues)
            : schema.validate(schema.cast(newValues), {
                // there seems to be no alternative to abortEarly in Zod, so we need to disable it
                abortEarly: false,
                stripUnknown: true,
                strict: false,
              })
        )
          .then((validatedValues: Schema) => {
            onChange?.(validatedValues);
            return true;
          })
          .catch((error: ValidationError | ZodError) => {
            const formikError = toFormikError<Schema>(error);
            onError?.(formikError);
            return formikError;
          });
      }

      onChange?.(newValues);

      return Promise.resolve(true);
    },
    [onChange, onError, validationSchema]
  );

  return (
    <Formik<Schema>
      validateOnChange={canValidate}
      validateOnBlur={canValidate}
      validate={handleValidate}
      onSubmit={onSubmit}
      {...props}
    >
      <FormikForm className={additionalClass}>
        <ValidationHelper<Schema> setCanValidate={setCanValidate}>{children}</ValidationHelper>
      </FormikForm>
    </Formik>
  );
}
