const palette = {
  categorical: {
    // Categorical colors are not ordered. Use these for categorical scales. Do not use these for ordinal, interval,
    // or ratio scales.These are designed to be visually distinct from one another.
    BaseN: {
      0: '#2182C3',
      1: '#8FC5EA',
      2: '#F3C44A',
      3: '#4A988C',
      4: '#A23182',
      5: '#BCDE85',
      6: '#F68230',
      7: '#C6B3D3',
      8: '#FCAC8F',
      9: '#8B572A',
    },
    Finland: {
      0: '#334655',
      1: '#6AA1BE',
      2: '#92BEA3',
      3: '#C6DECC',
      4: '#AB6040',
      5: '#DD9767',
      6: '#4A6B59',
      7: '#6D8650',
      8: '#643B82',
      9: '#8F71A4',
    },
    Colorful: {
      0: '#2F8DFA',
      1: '#1FD0BF',
      2: '#EB648B',
      3: '#F8C753',
      4: '#EB7E30',
      5: '#A93790',
      6: '#049587',
      7: '#1553B6',
      8: '#55008C',
      9: '#8B572A',
    },
  },
  sequential: {
    // One color sequential palettes to represent numeric values.
    Blue: {
      1: '#ECF4F8',
      // TODO: populate ones in between when time allows
      9: '#1C3D4B',
    },
    Green: {
      1: '#F4FAEB',
      9: '#395014',
    },
    Orange: {
      1: '#FDEDE7',
      9: '#5F2E1C',
    },
    Purple: {
      1: '#F4EDF8',
      9: '#432B4F',
    },
    Red: {
      1: '#FDF2F1',
      9: '#5F2827',
    },
    Gray: {
      1: '#FFFFFF',
      9: '#000000',
    },
    // The best sequential palettes will be perceptually uniform, meaning that the relative discriminability of two
    // colors is proportional to the difference between the corresponding data values. Ideally BaseN includes,
    // alongside one color sequential palettes,  five perceptually uniform sequential colormaps: "viridis", "magma",
    // "turbo", “Rocket” and "Mako".
    Virdis: {
      1: '#FAE655',
      2: '#D5E04E',
      3: '#8FCE63',
      4: '#5EB47E',
      5: '#49968B',
      6: '#40778C',
      7: '#3F5688',
      8: '#433279',
      9: '#3D0A51',
    },
    Magma: {
      1: '#FCFDBF',
      2: '#FDDEA0',
      3: '#FE9F6D',
      4: '#F1605D',
      5: '#C43C75',
      6: '#8C2981',
      7: '#57157E',
      8: '#20114B',
      9: '#000004',
    },
    Turbo: {
      1: '#7A0403',
      2: '#D23105',
      3: '#FB8022',
      4: '#EDD03A',
      5: '#A3FD3D',
      6: '#31F199',
      7: '#29BBEC',
      8: '#466BE3',
      9: '#30123B',
    },
    Rocket: {
      1: '#150E27',
      2: '#431B45',
      3: '#6E1F56',
      4: '#9F1A5B',
      5: '#CC1F4D',
      6: '#EA4840',
      7: '#F37C56',
      8: '#F6AB84',
      9: '#F9DAC4',
    },
    Mako: {
      1: '#1A0F1A',
      2: '#322344',
      3: '#403A75',
      4: '#3A5B9A',
      5: '#357CA3',
      6: '#369EAA',
      7: '#44BBAD',
      8: '#7FD7B1',
      9: '#C6EBD1',
    },
  },
  diverging: {
    // Diverging colors also have numeric meaning. They're useful when dealing with negative values or ranges that
    // have two extremes with a baseline in the middle. Diverging palettes are a pair of 2 gradations of colors
    // that meet in the center.
    GreenYellowRed: {
      1: '#259E24',
      2: '#76C23E',
      3: '#AAD459',
      4: '#D7E679',
      5: '#FFF99D',
      6: '#FDD16D',
      7: '#FCA649',
      8: '#F87637',
      9: '#DA3132',
    },
    RedYellowGreen: {
      1: '#DA3132',
      2: '#F87637',
      3: '#FCA649',
      4: '#FDD16D',
      5: '#FFF99D',
      6: '#D7E679',
      7: '#AAD459',
      8: '#76C23E',
      9: '#259E24',
    },
    RedBlue: {
      1: '#631122',
      2: '#B03E3C',
      3: '#D8856C',
      4: '#F5D2BD',
      5: '#F7F7F7',
      6: '#C9DEEB',
      7: '#7DAECF',
      8: '#3D72AE',
      9: '#13315F',
    },
    RedYellowBlue: {
      1: '#9B202C',
      2: '#D1523C',
      3: '#EB8F5D',
      4: '#F8D48F',
      5: '#FFFDC4',
      6: '#DCEEF5',
      7: '#9CC4DC',
      8: '#5F87BA',
      9: '#323991',
    },
    BrownBlueGreen: {
      1: '#562E09',
      2: '#9E5F1C',
      3: '#CFA75B',
      4: '#F2E2B3',
      5: '#F2F2F2',
      6: '#AFE0D8',
      7: '#53A99C',
      8: '#1C6E64',
      9: '#084037',
    },
  },
};

export default palette;
