import Card from '@components/common/Card/Card';
import { InfoGrid } from '@components/layout/InfoGrid/InfoGrid';
import { useDeviceConfigParamsByIdQuery } from '@infrastructure/api/BaseNClient/useDeviceConfigParamsByIdQuery';
import LoadingSpinner from '@components/layout/LoadingSpinner';

import styles from './DeviceDetails.module.scss';

type DeviceDetailsProps = {
  entityId: string;
  onTerminalOpen?: () => void;
};

export const DeviceDetails = ({ entityId, onTerminalOpen }: DeviceDetailsProps) => {
  const deviceQuery = useDeviceConfigParamsByIdQuery(entityId);

  return (
    <Card additionalClass={styles.infoPanel}>
      {deviceQuery.isLoading ? (
        <LoadingSpinner centered additionalClass="p-20" />
      ) : (
        <InfoGrid
          variant="bordered"
          size="l"
          data={[
            {
              label: 'Host',
              value: deviceQuery.data?.host,
            },
            {
              label: 'Device ID',
              value: deviceQuery.data?.entityid,
            },
            {
              label: 'Agent',
              value: deviceQuery.data?.agent || '-',
            },
            {
              label: 'Protocol',
              value: deviceQuery.data?.protocol || '-',
            },
          ]}
        />
      )}
    </Card>
  );
};
