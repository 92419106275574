import Icon from '@components/common/Icon';
import IconButton from '@components/common/IconButton';
import Modal, { ModalProps } from '@components/common/Modal';
import CopyText from '@components/typography/CopyText';
import { BulkOverrideForm } from './OverrideForm';
import { ALERT_OVERRIDE_DEFAULTS } from '@infrastructure/api/BaseNClient/useAlertOverrideQuery';
import { AlertChannel, AlertConfig } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import {
  AlertOverrideMutationResponse,
  useAlertOverrideMutation,
} from '@infrastructure/api/BaseNClient/useAlertOverrideMutation';
import React from 'react';
import { getChannelOverride } from '@utils/alert';

export type OverridePopupProps = ModalProps & {
  alert: AlertConfig;
  channel?: AlertChannel;
};

export const OverridePopup = ({ alert, channel, isOpen, onClose }: OverridePopupProps) => {
  const submitMutation = useAlertOverrideMutation();
  const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();

  const isGlobalOverride = !channel;
  const override = isGlobalOverride
    ? alert.overrides?.global
    : alert?.overrides?.channels
    ? getChannelOverride(alert.overrides.channels, channel.info.channel)
    : null;
  const isEditing = !!override;

  const handleSubmit = React.useCallback(
    (values, { setSubmitting }) => {
      // BE puts the burden of figuring out proper idx and structure of the overrides on the FE ¯\_(ツ)_/¯
      // Behold! the ugly crap is coming...
      const request = {
        alerts: [
          {
            id: alert.id,
            overrides: [values],
          },
        ],
        save_type: 'upsert',
      };

      submitMutation.mutate(request, {
        onSuccess: getSuccessHandler((res: AlertOverrideMutationResponse) => {
          setSubmitting(false);
          onClose?.();
        }),
        onError: getFailureHandler(() => setSubmitting(false)),
      });
    },
    [alert.id, submitMutation, getSuccessHandler, getFailureHandler, onClose]
  );

  return (
    <Modal
      isOpen={isOpen}
      usePortal
      onClose={onClose}
      customHeader={
        <div className="w-full hbox">
          <Icon name={isEditing ? 'Edit' : isGlobalOverride ? 'BulkOverride' : 'SingleOverride'} size="m" />
          <CopyText variant="copy-1" additionalClass="ml-8">
            {isGlobalOverride ? `Bulk override` : `Override "${channel?.info.channel}" channel`}
          </CopyText>
          <IconButton icon="Close" onClick={() => onClose?.()} additionalClass="ml-auto" size="xs" />
        </div>
      }
      customFooter={null}
    >
      <BulkOverrideForm
        alertOverride={alert.overrides?.global ? alert.overrides?.global : ALERT_OVERRIDE_DEFAULTS}
        onFormSubmit={handleSubmit}
      />
    </Modal>
  );
};
