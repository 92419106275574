import React from 'react';
import { SwitchBaseChildProps } from '@components/common/SwitchBase/SwitchBase';
import Button from '@components/common/Button';
import { CronGenerator } from '@components/common/CronGenerator/CronGenerator';
import { DateRange } from '@blueprintjs/datetime';

type AddScheduleButtonProps = SwitchBaseChildProps<{
  onAdd?: (schedule: string | DateRange) => void;
}>;

export const AddScheduleButton: React.FC<AddScheduleButtonProps> = ({ active, onChange, onAdd }) => {
  const handleModeToggle = (e: React.MouseEvent<HTMLElement>) => {
    onChange?.(e, !active);
  };

  return active ? (
    <CronGenerator showCustomTab={false} enableRecurring={false} onSubmit={onAdd} onCancel={handleModeToggle} />
  ) : (
    <Button onClick={handleModeToggle} variant="fillOutline" additionalClass="mr-auto mt-8">
      Add Another Schedule
    </Button>
  );
};
