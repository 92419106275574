import { object, TypeOf } from 'yup';
import { useMemo } from 'react';
import useTextSnippets from '@services/useTextSnippets';

const useSchema = () => {
  const i18n = useTextSnippets('widgetPage');

  // TODO: currently this schema is for chart widget only, adapt to other types once they appear
  const schema = object({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, [i18n]);
};

export interface ConfigEditorSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
