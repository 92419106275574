import React from 'react';
import Error500Page from '@components/pages/Error500Page';
import Grid from '@components/layout/Grid';
import ROUTES from '@infrastructure/routes';
import { ManagementCard } from '../Management/components/ManagementCard';
import BasePage from './BasePage';

const Tools: React.FC<{ error?: boolean }> = ({ error }) => {
  React.useEffect(() => {
    document.title = `Tools - BaseN Platform`;
  }, []);

  const sections = [
    {
      title: 'Maintenance Window',
      description: 'Schedule a maintenance period for an Entity or group to perform any maintenance activity.',
      path: ROUTES.maintenanceWindows,
    },
    {
      title: 'Logbook',
      description: 'Keep track of all the events, changes and important information per entity.',
      path: ROUTES.logbook,
    },
    {
      title: 'Configuration Manager',
      description: 'Table of configs | Scripts and Templates | Run Script Wizard | Open Terminal',
      path: ROUTES.configSearch,
    },
  ];

  if (error) {
    return <Error500Page />;
  }

  return (
    <BasePage level={1}>
      <Grid noSpacing additionalClass="w-full gap-12 -mt-12">
        {sections.map(section => (
          <ManagementCard key={section.path} {...section} />
        ))}
      </Grid>
    </BasePage>
  );
};

export default Tools;
