import React from 'react';
import { QueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary as ReactErrorBoundary, ErrorBoundaryPropsWithComponent } from 'react-error-boundary';

type ErrorBoundaryProps = Pick<ErrorBoundaryPropsWithComponent, 'FallbackComponent' | 'onError' | 'onReset'> & {
  resetQueries?: boolean;
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  FallbackComponent,
  onError,
  onReset,
  resetQueries = true,
  children,
}) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset: reactQueryReset }) => (
        <ReactErrorBoundary
          FallbackComponent={FallbackComponent}
          onError={onError}
          onReset={(...args) => {
            if (resetQueries) {
              reactQueryReset();
            }
            onReset?.(...args);
          }}
        >
          {children}
        </ReactErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export default ErrorBoundary;
