import { z } from 'zod';

export const addEditScriptSchema = z.object({
  name: z.string().min(1, 'Please add a name for the script. This will help other users identify it'),
  description: z.string().optional(),
  script: z.string().min(1, 'Please add a script'),
});

export const targetsSchema = z.object({
  entities: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
      })
    )
    .optional(),
});

export const payloadSchema = z
  .object({
    entities: z.array(z.string()).optional(),
  })
  .merge(addEditScriptSchema);

export const addEditScriptWizardSchema = z.object({
  script: addEditScriptSchema,
  targets: targetsSchema,
});

export type AddEditSchema = z.infer<typeof addEditScriptSchema>;
export type Payload = z.infer<typeof payloadSchema>;
export type AddEidtScriptWizardValues = z.infer<typeof addEditScriptWizardSchema>;
