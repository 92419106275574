import { object, TypeOf, bool } from 'yup';
import { useMemo } from 'react';

const useSchema = () => {
  const schema = object({
    showExtendedToolbar: bool(),
    showDetailsInSidePanel: bool(),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, []);
};

export interface OptionsTabSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
