import { useTableConfig } from '@components/common/Table/TableConfigContext';
import {
  OptionsChecklistField,
  OptionsChecklistFieldProps,
  OptionsControlFieldProps,
} from '@components/connected/OptionsControl';
import { OptionsTagInputField } from '@components/connected/OptionsControl/OptionsTagInputField';
import React from 'react';

export type OptionsFilterFieldProps = OptionsControlFieldProps | OptionsChecklistFieldProps;

export const OptionsFilterField: React.FC<OptionsFilterFieldProps> = props => {
  const { filtersInPopup } = useTableConfig();

  return !!filtersInPopup ? (
    <OptionsTagInputField {...(props as OptionsControlFieldProps)} />
  ) : (
    <OptionsChecklistField {...(props as OptionsChecklistFieldProps)} />
  );
};
