import React, { forwardRef } from 'react';
import c from 'classnames';
import { ControlChildProps } from '../Control/Control';

import styles from './Input.module.scss';

export type TextAreaProps = ControlChildProps<string, (e: React.ChangeEvent<HTMLTextAreaElement>) => void> & {
  placeholder?: string;
  maxLength?: number;
  value?: string;
  additionalClasses?: {
    root?: string;
    input?: string;
  };
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ placeholder, size = 'm', value = '', additionalClasses = {}, ...otherProps }, ref) => (
    <div
      className={c(
        styles.textarea,
        {
          [styles.large]: size === 'l',
          [styles.medium]: size === 'm',
        },
        additionalClasses.root
      )}
    >
      <textarea ref={ref} placeholder={placeholder} value={value} {...otherProps} className={additionalClasses.input} />
    </div>
  )
);

TextArea.displayName = 'TextArea';
