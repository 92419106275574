import { FormikProps, FormikValues } from 'formik';
import { noop } from 'lodash';
import React, { Ref } from 'react';

// NOTE: this thing doesn't really do anything useful, just a stub for Formik
export const useWizardHandle = (innerRef?: Ref<FormikProps<FormikValues>>, onSubmit?: any) => {
  // the following handle doesn't fully conform to FormikProps<Schema> but it's enough for our use case
  React.useImperativeHandle(innerRef as unknown as any, () => {
    const setSubmitting = noop;

    const handle = {
      submitForm() {
        // @ts-ignore
        onSubmit?.({}, { setSubmitting });
        // return a resolved Promise here for compatibility with Formik
        return Promise.resolve();
      },
      setSubmitting,
      isSubmitting: false,
      isValid: true,
    };

    return handle;
  });
};
