import { Table, TableColumn } from '@components/common/Table';
import { EntityProgress } from '@infrastructure/push-api/useBatchRun.schema';
import { toIdMap } from '@utils/misc';
import React from 'react';
import Link from '@components/common/Link';
import ROUTES from '@infrastructure/routes';
import CopyText from '@components/typography/CopyText';
import { formatEpochDate } from '@utils/date';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import Icon from '@components/common/Icon';
import Tooltip from '@components/common/Tooltip';
import IconButton from '@components/common/IconButton';
import { routeToSiteUrl, toQueryStr } from '@utils/url';
import { useAccount } from '@infrastructure/redux/auth';

import styles from './ProgressTable.module.scss';

type ProgressTableProps = {
  items: EntityProgress[];
};

export const ProgressTable = ({ items }: ProgressTableProps) => {
  const account = useAccount();

  const handleNavigate = React.useCallback(
    (item: EntityProgress) => {
      window.open(
        routeToSiteUrl(
          `${ROUTES.sessionLogs.replace(':entityId', item.entity)}?${toQueryStr({
            sessionId: item.session_id,
            user: account?.username ?? account?.email,
          })}`
        ),
        '_blank',
        'noopener,noreferrer'
      );
    },
    [account]
  );

  const columns = React.useMemo(
    () =>
      toIdMap([
        {
          id: 'device',
          label: 'Device',
          width: 200,
          sortable: false,
          valueRenderer: (item: EntityProgress) => (
            <div className="flex flex-row items-center flex-1 truncate text-dark">
              <Link
                href={`${ROUTES.entityById.replace(':entityId', item.entity)}`}
                target="_blank"
                additionalClass={styles.link}
              >
                <CopyText variant="copy-4" additionalClass="truncate">
                  {item.name}
                </CopyText>
              </Link>
            </div>
          ),
        },
        {
          id: 'start_time',
          label: 'Start time',
          sortable: false,
          width: 150,
          valueRenderer: (item: EntityProgress) => formatEpochDate(item.start_time),
        },
        {
          id: 'end_time',
          label: 'End time',
          sortable: false,
          width: 150,
          valueRenderer: (item: EntityProgress) => (item.end_time ? formatEpochDate(item.end_time) : '-'),
        },
        {
          id: 'state',
          label: 'State',
          sortable: false,
          width: 100,
          align: 'center' as TableColumn<EntityProgress>['align'],
          valueRenderer: (item: EntityProgress) => {
            switch (item.state) {
              case 'completed':
                return <Icon name="SuccessMessage" size="s" additionalClass="text-green-success mx-auto" />;
              case 'error':
                return (
                  <Tooltip content={item.error ?? 'Unknown error occurred'} additionalClass="mx-auto">
                    <Icon name="ErrorMessage" size="s" additionalClass="text-red-error" />
                  </Tooltip>
                );
              case 'running':
              default:
                return <LoadingSpinner size="s" additionalClass="mx-auto" centered />;
            }
          },
        },
        {
          id: 'actions',
          label: 'Actions',
          sortable: false,
          width: 80,
          valueRenderer: (item: EntityProgress) => {
            if (['completed', 'error'].includes(item.state)) {
              return (
                <IconButton
                  icon="NewTab"
                  onClick={() => handleNavigate(item)}
                  size="xs"
                  tooltipProps={{ className: 'mx-auto' }}
                  title="Open session log"
                />
              );
            }

            return null;
          },
        },
      ]),
    [handleNavigate]
  );

  return (
    <Table<EntityProgress>
      items={items}
      columns={columns}
      config={{ selectable: false, hasToolbar: false, paginated: false }}
    />
  );
};
