import React, { useCallback, useRef } from 'react';
import c from 'classnames';
import { DateInput as BPDateInput, DateInputProps as BPDateInputProps } from '@blueprintjs/datetime';
import Icon from '@components/common/Icon';
import { formatDate, parseDate } from '@utils/date';
import dayPickerProps from './dayPickerProps';

import styles from './style.module.scss';

export type DateInputOwnProps = {
  size?: 'm' | 'l';
  showIcon?: boolean;
  placeholder?: string;
  additionalClass?: string; // @deprecated - use additionalClasses.root
  additionalClasses?: {
    root?: string;
    input?: string;
  };
};

export type DateInputProps = Omit<BPDateInputProps, 'formatDate' | 'parseDate' | 'dayPickerProps'> & DateInputOwnProps;

export const DateInput: React.FC<DateInputProps> = ({
  showIcon = true,
  placeholder = `e.g. 09-06-2021`,
  size = 'm',
  additionalClass,
  additionalClasses = {},
  disabled = false,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleAction = useCallback(() => {
    if (inputRef.current) {
      // since we add own icon and BPDateInput doesn't have imperative interface - open Popover by focusing an input
      inputRef.current.focus();
    }
  }, []);

  return (
    <BPDateInput
      inputProps={{ inputRef, className: additionalClasses.input }}
      canClearSelection
      highlightCurrentDay
      showActionsBar
      closeOnSelection
      className={c(
        styles.dateInput,
        showIcon && styles.hasIcon,
        {
          [styles.large]: size === 'l',
          [styles.medium]: size === 'm',
        },
        additionalClasses.root ?? additionalClass
      )}
      placeholder={placeholder}
      {...props}
      rightElement={
        showIcon ? (
          <Icon name="Calendar" onClick={handleAction} additionalClass={c(disabled && styles.disabled)} />
        ) : undefined
      }
      dayPickerProps={dayPickerProps}
      parseDate={parseDate}
      formatDate={formatDate}
      disabled={disabled}
    />
  );
};
