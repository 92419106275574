import Gravatar from '@components/common/Gravatar';
import { TableColumns } from '@components/common/Table';
import { FieldConfig, FieldsConfig } from '@components/common/form/FormBuilder';
import CopyText from '@components/typography/CopyText';
import { NOTE_TYPES, NoteWithEntity } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { Filters } from '@infrastructure/redux/types';
import { toIdMap } from '@utils/misc';
import moment from 'moment';
import { ReasonPickerField, ReasonPickerFieldProps } from '@components/pages/MWCreatePage/components/ReasonPickerField';
import { EntitiesField, EntitiesFieldProps } from '@components/connected/EntitiesControl/EntitiesField';
import Link, { Linkify } from '@components/common/Link';
import { SelectField, SelectFieldProps } from '@components/common/form';
import ROUTES from '@infrastructure/routes';

export const getTableColumns = (): TableColumns<NoteWithEntity> => {
  return toIdMap([
    {
      id: 'timestamp',
      label: 'Time',
      width: 120,
      valueRenderer({ timestamp }: NoteWithEntity) {
        return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      id: 'customer',
      label: 'Customer',
      valueRenderer({ entity }: NoteWithEntity) {
        return entity?.customer ?? '';
      },
    },
    {
      id: 'entity',
      label: 'Entity',
      valueRenderer({ entity }: NoteWithEntity) {
        return entity ? (
          <Link
            variant="black"
            href={ROUTES.entityById.replace(':entityId', entity.id)}
            additionalClass="flex-grow min-w-0 truncate"
          >
            {entity.name}
          </Link>
        ) : null;
      },
    },
    {
      id: 'type',
      label: 'Type',
      width: 80,
      valueRenderer({ type }: NoteWithEntity) {
        return type ?? '';
      },
    },
    {
      id: 'reason',
      label: 'Reason',
    },
    {
      id: 'text',
      label: 'Description',
      type: 'custom',
      truncated: false,
      width: 250,
      valueRenderer: (note: NoteWithEntity) => {
        return (
          <div className="inline-block w-full truncate whitespace-nowrap">
            <Linkify target="_blank" rel="noopener noreferrer">
              {note.text}
            </Linkify>
          </div>
        );
      },
    },
    {
      id: 'user',
      label: 'Creator',
      type: 'custom',
      valueRenderer: ({ author }: NoteWithEntity) => {
        return (
          <div className="w-full hbox">
            <Gravatar size={48} username={author} email={author} additionalClass="mr-8" />
            <CopyText variant="copy-4" additionalClass="truncate">
              {author}
            </CopyText>
          </div>
        );
      },
    },
  ]);
};

export const getFiltersConfig = (filters?: Filters): FieldsConfig => {
  return [
    {
      type: 'custom',
      props: {
        name: 'types',
        label: 'Type',
        fullWidth: true,
        placeholder: 'All Types',
        defaultValue: filters?.discreteFilters?.types || undefined,
        options: NOTE_TYPES.map(type => ({ label: type, value: type })),
        clearable: true,
      },
      renderer(config: FieldConfig) {
        return <SelectField {...(config.props as SelectFieldProps)} />;
      },
    },
    {
      type: 'custom',
      props: {
        name: 'reasons',
        label: 'Reason',
        fullWidth: true,
        placeholder: 'All Reasons',
        defaultValue: filters?.discreteFilters?.reasons || undefined,
        clearable: true,
      },
      renderer(config: FieldConfig) {
        return <ReasonPickerField {...(config.props as ReasonPickerFieldProps)} />;
      },
    },
    {
      type: 'custom',
      props: {
        name: 'entity_ids',
        label: 'Entities',
        defaultValue: filters?.discreteFilters?.entityids ?? '',
        fullWidth: true,
        placeholder: 'All Entities',
        filterable: true,
      },
      renderer: (config: FieldConfig) => <EntitiesField {...(config.props as EntitiesFieldProps)} />,
    },
  ];
};
