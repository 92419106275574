import { snakeCase, startCase } from 'lodash';
import invariant from './invariant';

const whitespaceRegex = /\s/g;

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export function escapeRe(str: string): string {
  // $& means the whole matched string
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function capitalize(str: string): string {
  invariant(typeof str === 'string', `capitalize accepts only strings`);

  if (str.length > 1) {
    return str.charAt(0).toUpperCase() + str.substring(1);
  }

  return str.toUpperCase();
}

export function decapitalize(str: string): string {
  invariant(typeof str === 'string', `decapitalize accepts only strings`);

  if (str.length > 1) {
    return str.charAt(0).toLowerCase() + str.substring(1);
  }

  return str.toLowerCase();
}

export function eliminateWhitespace(str: string): string {
  invariant(typeof str === 'string', `eliminateWhitespace accepts only strings`);

  return str.replace(whitespaceRegex, '').trim();
}

export const toSnakeCase = (obj: Record<string, any>) =>
  Object.keys(obj).reduce((newObj, key) => ({ ...newObj, [snakeCase(key)]: obj[key] }), {});

export const titleCase = (str: string) => eliminateWhitespace(startCase(str));

export const splitAndStripEmpty = (str: string, separator: string | RegExp = /\r?\n/) =>
  str
    .split(separator)
    .map(s => s.trim())
    .filter(Boolean);
