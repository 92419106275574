import React, { useCallback } from 'react';
import c from 'classnames';
import { Input, InputProps } from './Input';
import { isUndefined } from 'lodash';

import styles from './NumberInput.module.scss';

export type NumberInputProps = InputProps & {
  noArrows?: boolean;
};

export const NumberInput: React.FC<NumberInputProps> = ({ noArrows = true, onChange, inputProps, ...props }) => {
  const { min, max } = inputProps ?? {};

  const handleChange = useCallback(
    e => {
      const value = e.target.value !== '' && !isNaN(+e.target.value) ? +e.target.value : undefined;

      if (isUndefined(value) || ((!min || +min <= value) && (!max || +max >= value))) {
        onChange?.(e);
      }
    },
    [onChange, min, max]
  );

  return (
    <Input
      {...props}
      additionalClass={c(styles.numberInput, noArrows && styles.noArrows, props.additionalClass)}
      inputProps={inputProps}
      onChange={handleChange}
      type="number"
    />
  );
};
