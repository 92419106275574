import React, { useState, useCallback } from 'react';
import { Issue, IssueWithDetails } from '@redux/issues';
import CopyText from '@components/typography/CopyText';
import Button from '@components/common/Button';
import { Collapse, Intent, PopoverPosition } from '@blueprintjs/core';
import Icon from '@components/common/Icon';
import useTextSnippets from '@services/useTextSnippets';
import fillTemplate from '@utils/fillTemplate';
import Tooltip from '@components/common/Tooltip';
import c from 'classnames';

import styles from './ActionListIssue.module.scss';

type Props = {
  issues: (IssueWithDetails | Issue)[];
  warning?: string;
};

const ActionIssueList: React.FC<Props> = ({ warning, issues = [] }) => {
  const [open, setOpen] = useState(false);
  const commonI18n = useTextSnippets('common');
  const issuesI18n = useTextSnippets('issues');
  const onlyOneIssue = issues.length === 1;

  const toggleList = useCallback(() => {
    setOpen(!open);
  }, [open]);

  if (onlyOneIssue) {
    return (
      <CopyText variant="copy-4" ellipsis>
        {issues[0].name}
      </CopyText>
    );
  }

  return (
    <div className={styles.list}>
      <div className="flex items-center">
        <CopyText variant="copy-4">{fillTemplate(issuesI18n.selectedIssues, { number: issues.length })}</CopyText>
        {warning && (
          <Tooltip
            position={PopoverPosition.BOTTOM_LEFT}
            popoverClassName="max-w-7xs"
            intent={Intent.NONE}
            noIndicatorClass
            content={warning}
            renderTarget={props => (
              <div {...props} className={c(props.className, 'flex mx-2')}>
                <Icon name="WarningMessage" additionalClass="text-yellow-warning" size="s" />
              </div>
            )}
          />
        )}
        <Button variant="link" onClick={toggleList}>
          {open ? commonI18n.hideAll : commonI18n.showAll}
        </Button>
      </div>
      <div className={styles.nestedListContainer}>
        <Collapse isOpen={open} className="mt-4">
          <div className={styles.nestedList}>
            {issues.map(issue => (
              <CopyText variant="copy-4" key={issue.id} ellipsis>
                {issue.name}
              </CopyText>
            ))}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default ActionIssueList;
