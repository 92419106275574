import Icon from '@components/common/Icon';
import { MultiChoiceButton, MultiChoiceButtonItem, MultiChoiceButtonProps } from '@components/common/MultiChoiceButton';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import ROUTES from '@infrastructure/routes';
import { retrieveItem, storeItem } from '@utils/storage';
import { routeToSiteUrl } from '@utils/url';
import React from 'react';

const OPTIONS: MultiChoiceButtonItem[] = [
  {
    label: 'Terminal Tab',
    value: 'open-terminal-tab',
    description: 'Open terminal in a new tab',
  },
  {
    label: 'Terminal Window',
    value: 'open-terminal-window',
    description: 'Open terminal in a new undecorated window',
  },
];

export type TerminalButtonProps = Omit<MultiChoiceButtonProps, 'options' | 'value' | 'onChange'> & {
  entity: EntityDetails;
};

export const TerminalButton = ({ entity, children, ...props }: TerminalButtonProps) => {
  const [value, setValue] = React.useState<MultiChoiceButtonItem>(
    retrieveItem<MultiChoiceButtonItem>('terminal-button-option') ?? OPTIONS[0]
  );

  const handleOpenTerminal = React.useCallback(
    (option: MultiChoiceButtonItem) => {
      if (option.value === 'open-terminal-tab') {
        window.open(routeToSiteUrl(ROUTES.terminal.replace(':entityId', entity.id)), '_blank');
      } else {
        window.open(
          routeToSiteUrl(ROUTES.standaloneTerminal.replace(':entityId', entity.id)),
          `Terminal ${Date.now()}`,
          'width=800,height=600,resizable=yes,location=no'
        );
      }
    },
    [entity]
  );

  const handleDefaultChange = React.useCallback((value: MultiChoiceButtonItem) => {
    setValue(value);
    storeItem('terminal-button-option', value);
  }, []);

  return (
    <MultiChoiceButton
      {...props}
      options={OPTIONS}
      value={value}
      onChange={handleDefaultChange}
      onClick={handleOpenTerminal}
    >
      <Icon name="Terminal" additionalClass="mr-8" />
      {children}
    </MultiChoiceButton>
  );
};
