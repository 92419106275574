import { UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '../query-configs';
import { useValidatedQuery } from '@services/useValidatedQuery';
import z from 'zod';
import ENDPOINTS from '../endpoints';
import { PaginatedQueryRequest } from './usePaginatedQuery';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';

export const NOTE_TYPES = [
  'config',
  'session',
  'maintenance',
  'event', // deprecated in favor of 'maintenance'
] as const;

const noteTypeSchema = z.enum(NOTE_TYPES);

const noteSchema = z.object({
  id: z.number(),
  reason: z.string(),
  author: z.string().optional(),
  entityid: z.string(),
  text: z.string(),
  priority: z.number(),
  timestamp: z.number(),
  created: z.number(),
  updated: z.number().optional(),
  // TODO: maybe split the type based on this field
  type: noteTypeSchema.optional(),
  link: z.string().optional(),
  endtimestamp: z.number().optional(),
});

const responseSchema = z.object({
  notes: z.array(noteSchema),
  total: z.number(),
});

export type Note = z.infer<typeof noteSchema>;
export type NoteType = z.infer<typeof noteTypeSchema>;
export type NoteWithEntity = Note & {
  entity?: EntityDetails;
};

export type LogbookQueryRequest = PaginatedQueryRequest & {
  start?: number;
  end?: number;
  reasons?: string;
  entity_ids?: string;
  types?: string;
  general?: string;
};
export type LogbookQueryResponse = z.infer<typeof responseSchema>;

export const useLogbookQuery = <T extends LogbookQueryResponse = LogbookQueryResponse>(
  params: LogbookQueryRequest = {},
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.logbook, params, responseSchema, {
    ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
    ...queryOptions,
  });
};
