import { useBatchByIdQuery } from '@infrastructure/api/BaseNClient/useScriptBatchApi';
import { useParams } from 'react-router-dom';
import { ProgressFeedback } from '../ScriptRunPage/components/ProgressFeedback';
import { Box } from '@components/common/Box';
import Error404Page from '../Error404Page';

import styles from '@components/pages/ScriptRunPage/components/ProgressWrapper.module.scss';

export const ScriptBatchPage = () => {
  const { batchId } = useParams<{ batchId: string }>();

  const query = useBatchByIdQuery(
    { batchId: batchId!, entities: 'all', entity_names: true },
    { enabled: !!batchId, refetchInterval: 2000 }
  );

  if (!query.isLoading && !query.data) {
    return <Error404Page />;
  }

  return query.isLoading ? null : (
    <div className="page-container">
      <div className="page-body">
        <div className={styles.progressWrapper}>
          <ProgressFeedback details={query.data} />

          <Box header="Script" additionalClass={styles.scriptBox}>
            <pre className={styles.script}>{query.data.script}</pre>
          </Box>
        </div>
      </div>
    </div>
  );
};
