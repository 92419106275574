import useTextSnippets from '@services/useTextSnippets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { selectWidgetSubConfig, TopologyMapWidgetSubConfig, updateSubConfig } from '@redux/widgetPage';
import React, { useMemo } from 'react';
import { FieldsConfig, FormBuilder } from '@components/common/form/FormBuilder';
import useSchema from './OptionsTab.schema';
import { DEFAULT_TOPOLOGY_MAP_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { useWidgetPageContext } from '@components/pages/WidgetPage/WidgetPageContext';
import Button from '@components/common/Button';
import Icon from '@components/common/Icon';

export const OptionsTab = () => {
  const i18n = useTextSnippets('widgetPage');
  const dispatch = useAppDispatch();

  const { widgetRef } = useWidgetPageContext();

  const options =
    (useAppSelector(selectWidgetSubConfig) as TopologyMapWidgetSubConfig) ?? DEFAULT_TOPOLOGY_MAP_WIDGET_CONFIG;

  const schema = useSchema();

  const fields: FieldsConfig = useMemo(
    () => [
      {
        type: 'group',
        title: 'Components',
        fields: [
          {
            type: 'toggle',
            props: {
              label: i18n.showEntityLabels,
              name: 'showEntityLabels',
            },
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChange = (newOptions: TopologyMapWidgetSubConfig) => {
    dispatch(updateSubConfig(newOptions));
  };

  const handleSavePositions = () => {
    const newConfig = widgetRef?.current?.getConfig?.();
    if (newConfig) {
      dispatch(updateSubConfig(newConfig));
    }
  };

  return (
    <FormBuilder<TopologyMapWidgetSubConfig>
      fields={fields}
      initialValues={options}
      onChange={handleChange}
      validationSchema={schema}
      validateOnChange
      enableReinitialize
      additionalClass="m-10"
    >
      <Button onClick={handleSavePositions} variant="outlineActive" additionalClass="mt-32">
        <Icon name="Save" additionalClass="mr-8" /> Save topology
      </Button>
    </FormBuilder>
  );
};
