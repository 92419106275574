import { range } from 'lodash';
import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@components/common/form/ToggleButton';

import styles from './WeekdayPicker.module.scss';

export const WEEKDAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export type WeekdayPickerProps = {
  label?: string;
  value?: boolean[];
  onChange?: (days: boolean[]) => void;
};

export const WeekdayPicker: React.FC<WeekdayPickerProps> = ({ label, value = [], onChange }) => {
  return (
    <div className={styles.weekdayPicker}>
      {label && <label className={styles.label}>{label}</label>}
      <ToggleButtonGroup onStateChange={onChange} exclusive={false} round={false} size="s">
        {range(0, 6).map(idx => (
          <ToggleButton key={idx} active={value[idx]} additionalClass={styles.dayButton}>
            {WEEKDAYS[idx]}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};
