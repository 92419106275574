import { PayloadAction } from '@reduxjs/toolkit';
import {
  IssuesState,
  Issue,
  IssueWithDetails,
  IssueStatusFilter,
  IssueView,
  IssueCustomField,
  IssueGroup,
  IssueAction,
  IssuesSummary,
} from './types';
import { PaginationParams } from '@infrastructure/redux/types';
import { DEFAULT_PAGINATION } from '@components/common/Table/config';
import { createCustomSlice, selectInitialView } from '@utils/redux';
import { RowHeight } from '@components/common/Table';

const initialState: IssuesState = {
  activeView: selectInitialView(),
  items: {},
  itemsOrder: [],
  views: {},
  summary: null,
  groups: [],
  actions: {},
  dialogs: {},
  customFields: [],
  activeIssue: null,
  filters: {
    generalFilter: '',
    discreteFilters: {},
  },
  pagination: null,
  sort: null,
  rowHeight: 'medium',
};

const reducers = {
  issuesLoaded(state: IssuesState, { payload }: PayloadAction<Issue[]>) {
    const { items, order } = payload.reduce(
      (acc, c) => ({
        items: {
          ...acc.items,
          [c.id]: (state.items[c.id] as IssueWithDetails)?.detailsLoaded ? state.items[c.id] : c,
        },
        order: [...acc.order, c.id],
      }),
      {
        items: {},
        order: [],
      } as { items: Record<string, Issue | IssueWithDetails>; order: string[] }
    );

    state.items = items;
    state.itemsOrder = order;
  },

  issueActionsLoaded(state: IssuesState, { payload }: PayloadAction<IssueAction[]>) {
    state.actions = payload.reduce((acc, action) => {
      acc[action.name] = action;

      return acc;
    }, {} as IssuesState['actions']);
  },

  issueViewsLoaded(state: IssuesState, { payload }: PayloadAction<IssueView[]>) {
    state.views = payload.reduce((acc, view) => {
      acc[view.id] = view;

      return acc;
    }, {} as IssuesState['views']);

    if (!state.activeView && Object.keys(state.views).length > 0) {
      state.activeView = Object.keys(state.views)[0];
    }
  },

  issueFieldsLoaded(state: IssuesState, { payload }: PayloadAction<IssueCustomField[]>) {
    state.customFields = payload;
  },

  issueGroupsLoaded(state: IssuesState, { payload }: PayloadAction<IssueGroup[]>) {
    state.groups = payload;
  },

  issueSummaryLoaded(state: IssuesState, { payload }: PayloadAction<IssuesSummary>) {
    if (!state.summary) {
      state.summary = payload;
    }
  },

  updateIssue(state: IssuesState, { payload }: PayloadAction<IssueWithDetails>) {
    // updates about all issues are pumped through socket connection, we do not want them to pollute the state
    // so we accept only those that are currently visible
    if (state.items[payload.id]) {
      state.items[payload.id] = { ...payload, detailsLoaded: true };
    }
  },

  updateOrAppendIssue(state: IssuesState, { payload }: PayloadAction<IssueWithDetails>) {
    state.items[payload.id] = { ...payload, detailsLoaded: true };
  },

  setActiveIssue(state: IssuesState, { payload }: PayloadAction<string | null>) {
    state.activeIssue = payload;
  },

  changeView(state: IssuesState, { payload }: PayloadAction<string>) {
    state.activeView = payload;
    state.items = {};
    state.itemsOrder = [];
    state.summary = null;
    state.pagination = null;
    state.sort = null;
    state.activeIssue = null;
    state.filters.discreteFilters = {};
    state.filters.generalFilter = '';
  },

  updateView(state: IssuesState, { payload }: PayloadAction<IssueView>) {
    state.views[payload.id] = payload;
  },

  addView(state: IssuesState, { payload }: PayloadAction<IssueView>) {
    state.views[payload.id] = payload;
  },

  deleteView(state: IssuesState, { payload }: PayloadAction<string>) {
    delete state.views[payload];

    if (state.activeView === payload) {
      state.activeView = Object.keys(state.views)[0];
      state.activeIssue = null;
      state.filters.discreteFilters = {};
      state.filters.generalFilter = '';
      state.filters.discreteFilters = Object.keys(state.filters.discreteFilters).reduce((acc, filterKey) => {
        return {
          ...acc,
          [filterKey]: { ...state.filters.discreteFilters[filterKey], renderer: undefined, value: null },
        };
      }, {});
    }
  },

  updateIssueFields(state: IssuesState, { payload }: PayloadAction<{ id: string } & Partial<IssueWithDetails>>) {
    state.items[payload.id] = { ...state.items[payload.id], ...payload };
  },

  updateStatusFilter(state: IssuesState, { payload: status }: PayloadAction<IssueStatusFilter>) {
    state.filters.discreteFilters.state = status;
  },

  updateSearch(state: IssuesState, { payload }: PayloadAction<string | undefined>) {
    state.filters.generalFilter = payload ?? '';
  },

  updateFilters(state: IssuesState, { payload }: PayloadAction<Dictionary<any>>) {
    state.filters.discreteFilters = payload;
  },

  updatePagination(state: IssuesState, { payload }: PayloadAction<Partial<PaginationParams>>) {
    state.pagination = {
      ...DEFAULT_PAGINATION,
      ...state.pagination,
      ...payload,
    };
  },

  updateSorting(state: IssuesState, { payload }: PayloadAction<IssuesState['sort']>) {
    state.sort = payload;
  },

  updateRowHeight(state: IssuesState, { payload }: PayloadAction<RowHeight>) {
    state.rowHeight = payload;
  },
};

export const createIssuesSlice = (actionPrefix?: string) =>
  createCustomSlice({
    name: 'issues',
    initialState,
    reducers,
    actionPrefix,
  });

const issuesSlice = createIssuesSlice();

export const {
  addView,
  changeView,
  deleteView,
  updateSearch,
  updateFilters,
  updateStatusFilter,
  issueActionsLoaded,
  issueFieldsLoaded,
  issueGroupsLoaded,
  issueSummaryLoaded,
  issuesLoaded,
  issueViewsLoaded,
  setActiveIssue,
  updateIssue,
  updateOrAppendIssue,
  updateIssueFields,
  updateView,
  updatePagination,
  updateSorting,
  updateRowHeight,
} = issuesSlice.actions;

export default issuesSlice;
