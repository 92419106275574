import { object, string, TypeOf } from 'yup';
import { ITextSnippets } from '@services/useTextSnippets';
import { useMemo } from 'react';

const useSchema = (i18nKeys: ITextSnippets['login']) => {
  const schema = object({
    username: string().required(i18nKeys.usernameRequired),
    password: string().required(i18nKeys.passwordRequired),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, [i18nKeys]);
};

export interface LoginSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
