import { TreeNodeInfo } from '@components/common/Tree';
import { ROOT_FOLDER_NAME } from '@components/layout/FavoritesMenu/constants';
import { Dashboard } from '@redux/dashboards';
import { Favorite } from '@redux/sidebar';

function dashboardToTreeNode(dashboard: Dashboard): TreeNodeInfo {
  return {
    id: dashboard.id,
    label: dashboard.name,
    hasCaret: false,
    icon: 'Dashboard',
  };
}

function buildChildNodes(favorite: Favorite, byId: Record<number, Favorite>): TreeNodeInfo[] {
  const { children, members } = favorite;
  const childNodes: TreeNodeInfo[] = [];

  // dashboards first
  members?.forEach((dashboard: Dashboard | string) => {
    if (typeof dashboard === 'object' && dashboard?.id) {
      childNodes.push(dashboardToTreeNode(dashboard));
    }
  });

  // ... then folders
  children?.forEach((childId: number) => childNodes.push(favoriteToTreeNode(byId[childId], byId)));

  return childNodes;
}

export function favoriteToTreeNode(favorite: Favorite, byId: Record<number, Favorite>): TreeNodeInfo {
  return {
    id: favorite.id,
    label: favorite.name,
    hasCaret: true,
    childNodes: buildChildNodes(favorite, byId),
  };
}

export const favoritesToTreeContents = (items: Favorite[]): TreeNodeInfo[] => {
  const byId = items.reduce((map, item) => ({ [item.id]: item, ...map }), {});
  const rootFolder = items.find(item => item.name === ROOT_FOLDER_NAME);

  return items.reduce((tree, item) => {
    if (!item.parent) {
      if (item !== rootFolder) {
        tree.push(favoriteToTreeNode(item, byId));
      } else {
        const leafs = item.members?.map(dashboard => dashboardToTreeNode(dashboard as Dashboard)) ?? [];
        // eslint-disable-next-line prefer-spread
        tree.unshift.apply(tree, leafs);
      }
    } else if (item.parent === rootFolder?.id) {
      tree.unshift(favoriteToTreeNode(item, byId));
    }

    return tree;
  }, [] as TreeNodeInfo[]);
};
