import CopyText from '@components/typography/CopyText';
import { LINE_WIDTHS } from '@constants/map';
import React, { useCallback, useEffect, useState } from 'react';
import { EntityMarker } from './EntityMarker';
import c from 'classnames';
import { Arrow, ArrowProps } from './Arrow';
import HorizontalTitleSeparator from '@components/separators/HorizontalTitleSeparator/HorizontalTitleSeparator';
import { Widget } from '@components/layout/Widget';
import Link from '@components/common/Link';
import ROUTES from '@infrastructure/routes';
import { formatValue } from '@utils/chart';
import { Connection } from '../../types';

import styles from './RoutePopupContent.module.scss';

export type RoutePopupContentProps = {
  route: Connection;
  rtl?: boolean;
};

export const RoutePopupContent: React.FC<RoutePopupContentProps> = ({ route, rtl = true }) => {
  const width = route.width && LINE_WIDTHS.includes(+route.width) ? +route.width : 1;

  const [isGraphOpen, setIsGraphOpen] = useState(false);

  useEffect(() => {
    setIsGraphOpen(false);
  }, [route.id]);

  const handleGraphToggle = useCallback(() => {
    setIsGraphOpen(state => !state);
  }, []);

  return (
    <div className={styles.routePopup}>
      <div className={c(styles.connection, rtl && styles.rtl)}>
        <EntityMarker color={route.from_alert_color ?? route.alert_color} type={route.from_icon} />
        <Arrow
          color={route.alert_color}
          width={width as ArrowProps['width']}
          dir={rtl ? 'right' : 'left'}
          additionalClass="mx-8"
          title={route.width_bps ? `${formatValue(route.width_bps, 'bps', 'unit')}` : undefined}
        />
        <EntityMarker color={route.to_alert_color ?? route.alert_color} type={route.to_icon} />
      </div>
      <div className={styles.titles}>
        <CopyText variant="copy-8" additionalClass="ml-4">
          <Link href={ROUTES.entityById.replace(':entityId', route.from)}>{route.from_name}</Link>
        </CopyText>
        <CopyText variant="copy-8" additionalClass="ml-4">
          <Link href={ROUTES.entityById.replace(':entityId', route.to)}>{route.to_name}</Link>
        </CopyText>
      </div>

      {route.alert_id && (
        <>
          <HorizontalTitleSeparator centered>
            <span className={styles.link} onClick={handleGraphToggle}>
              {isGraphOpen ? 'Hide a graph' : 'Show a graph'}
            </span>
          </HorizontalTitleSeparator>

          {isGraphOpen && (
            <div className={styles.chartContainer}>
              <Widget
                type="timeseries"
                config={{
                  type: 'line',
                  source: { alertId: route.alert_id },
                  horizontalLegend: true,
                  hasToolbar: false,
                }}
                additionalClass={styles.chart}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
