import { IconNames } from '@components/common/Icon';
import { AlertChannel, AlertConfig } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';
import { StripeColor } from './issue';
import { BadgeVariant } from '@components/common/Badge';
import {
  ALERT_OVERRIDE_DEFAULTS,
  AlertOverride,
  AlertOverrides,
} from '@infrastructure/api/BaseNClient/useAlertOverrideQuery';
import { merge, sortBy } from 'lodash';
import { ItemWithId } from './misc';

export type Stats = {
  total: number;
  alerting: number;
  nonAlerting: number;
  escalated: number;
  silent: number;
  nodata: number;
  hasSilent: boolean;
  isAlerting: boolean;
  hasEscalated: boolean;
  hasNodata?: boolean;
  isUnderMaintenance?: boolean;
};

export const getAlertChannelStats = (channels: AlertChannel[] = []): Stats => {
  const stats = {
    total: channels?.length ?? 0,
    alerting: 0, // inlcudes escalated
    escalated: 0,
    silent: 0,
    nodata: 0,
    isUnderMaintenance: false,
  };

  channels?.forEach(channel => {
    if (channel.color === 'red') {
      stats.alerting++;
    } else if (channel.color === 'gray') {
      stats.nodata++;
    }

    if (channel.silent && channel.silent !== 'not silent') {
      stats.silent++;

      if (channel.silent === 'maintenance silent') {
        // if one channel is under maintenance than the whole alert is under maintenance
        stats.isUnderMaintenance = true;
      }
    }

    if (channel.greenred && channel.greenred > 1) {
      stats.escalated++;
    }
  });

  return {
    ...stats,
    nonAlerting: stats.total - stats.alerting,
    isAlerting: stats.alerting > 0,
    hasEscalated: stats.escalated > 0,
    hasSilent: stats.silent > 0,
    hasNodata: stats.nodata > 0,
  };
};

export const getAlertStats = (alerts: AlertConfig[]): Stats => {
  const stats = {
    total: alerts?.length ?? 0,
    alerting: 0, // inlcudes escalated
    escalated: 0,
    silent: 0,
    nodata: 0,
    isUnderMaintenance: false,
  };

  alerts?.forEach(alert => {
    const channelsStats = getAlertChannelStats(alert.channels);

    if (channelsStats.isAlerting) {
      stats.alerting++;
    }

    if (channelsStats.hasEscalated) {
      stats.escalated++;
    }

    if (channelsStats.hasSilent) {
      stats.silent++;
    }

    if (channelsStats.hasNodata) {
      stats.nodata++;
    }

    if (channelsStats.isUnderMaintenance) {
      stats.isUnderMaintenance = true;
    }
  });

  return {
    ...stats,
    nonAlerting: stats.total - stats.alerting,
    isAlerting: stats.alerting > 0,
    hasEscalated: stats.escalated > 0,
    hasSilent: stats.silent > 0,
    hasNodata: stats.nodata > 0,
  };
};

export const getIconName = ({ hasEscalated, isAlerting, hasSilent }: Stats): IconNames => {
  if (hasEscalated) {
    return 'AlertingEscalated';
  } else if (hasSilent) {
    return 'AlertOff';
  } else if (isAlerting) {
    return 'AlertOn';
  }
  return 'NotificationsFill';
};

export const getIconColor = ({ isAlerting, hasSilent, hasNodata, isUnderMaintenance }: Stats): StripeColor => {
  if (hasNodata) {
    return 'gray';
  } else if (isUnderMaintenance) {
    return 'maintenance';
  } else if (hasSilent) {
    return 'black';
  } else if (isAlerting) {
    return 'red';
  }
  return 'green';
};

export const getOtherColor = ({
  isAlerting,
  alerting,
  hasSilent,
  silent,
  hasNodata,
  nodata,
  isUnderMaintenance,
}: Stats): StripeColor => {
  if (isUnderMaintenance) {
    return 'maintenance';
  } else if (hasSilent && silent > 1) {
    return 'black';
  } else if (isAlerting && alerting > 1) {
    return 'red';
  } else if (hasNodata && nodata > 1) {
    return 'gray';
  }
  return 'green';
};

export const getTitle = ({ hasEscalated, hasNodata, isAlerting, hasSilent }: Stats, defaultTitle?: string): string => {
  if (hasSilent) {
    return 'Silent';
  }

  if (hasNodata) {
    return 'No data';
  }

  if (hasEscalated) {
    return 'Escalated!';
  }

  if (isAlerting) {
    return 'Alerting';
  } else if (defaultTitle) {
    return defaultTitle;
  } else {
    return 'All good';
  }
};

export const getBadgeVariant = ({ hasNodata, isAlerting, hasSilent }: Stats): BadgeVariant => {
  if (hasNodata || hasSilent) {
    return 'gray';
  }

  if (isAlerting) {
    return 'error';
  }

  return 'success';
};

export const getExplanation = ({ total, alerting, silent, nodata, isUnderMaintenance }: Stats): string => {
  const parts = [`${total} item${total > 1 ? 's' : ''}`];

  if (alerting > 0) {
    parts.push(`${alerting} alerting`);
  }

  if (silent > 0) {
    parts.push(`${silent} silent`);
  }

  if (nodata > 0) {
    parts.push(`${nodata} nodata`);
  }

  if (isUnderMaintenance) {
    parts.push('under maintenance');
  }

  return parts.join(', ');
};

export const getDefaultAlertOverride = (alert: AlertConfig): AlertOverrides => ({
  id: alert.id,
  overrides: [
    {
      ...ALERT_OVERRIDE_DEFAULTS,
    },
  ],
});

export const squashObjects = <T = ItemWithId>(overrides: T[], predicate?: (item: T) => boolean): T => {
  // sort ASC by id, and then merge all overrides into one - last value wins
  return merge({}, ...sortBy(predicate ? overrides.filter(predicate) : overrides, ['id']));
};

// a naive implementation of PCRE to JS regex conversion, but should be enough for our use case
export const convertPCRE2Regex = (pattern: string): RegExp => {
  // replace the \Q and \E with empty strings to remove them
  const cleanedPattern = pattern.replace(/\\Q|\\E/g, '');
  return new RegExp(cleanedPattern);
};

export const convertStringToPCRE = (str: string): string => {
  return `^\\Q${str}\\E$`;
};

// assuming here that global override (if present) precedes channel override
export const getChannelOverride = (override: AlertOverride[], channel: string): AlertOverride => {
  return squashObjects(override, item => (item.channels ? convertPCRE2Regex(item.channels).test(channel) : true));
};

export const getGlobalOverride = (override: AlertOverride[]): AlertOverride => {
  return squashObjects(override, item => !item.channels || item.channels === '.*');
};
