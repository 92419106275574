import React from 'react';
import Control, { ControlProps } from '@components/common/form/Control/Control';
import { BaseSelectItem } from '@components/common/form/Select/BaseSelect';
import { PalettePicker, PalettePickerProps } from './PalettePicker';

export type PalettePickerControlProps = ControlProps<BaseSelectItem | null, (item: BaseSelectItem) => void> &
  PalettePickerProps;

export const PalettePickerControl: React.FC<PalettePickerControlProps> = ({ options, ...props }) => {
  return (
    <Control {...props}>
      <PalettePicker options={options} />
    </Control>
  );
};
