import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import useTextSnippets from '@services/useTextSnippets';
import { ChartDataSource } from '@redux/widgetPage';
import { SubLevel } from '@components/layout/SubLevel';
import { DataSourcePickerProps } from '@components/pages/WidgetPage/types';
import { EntitiesControl, entityToOption } from '@components/connected/EntitiesControl/EntitiesControl';
import { InfiniteListItem } from '@components/connected/InfiniteSelect/InfiniteList';
import { Entity } from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import { EntityDetails } from '@redux/inventoryPage';
import { PagePlotnamePicker } from './PagePlotnamePicker';
import { MetricPicker } from './MetricPicker';

export type EntityDataSourcePickerProps = DataSourcePickerProps & {
  source: ChartDataSource | null;
  pagePlotnameBased?: boolean;
};

export const DataSourcePicker: FC<EntityDataSourcePickerProps> = ({
  source = null,
  onChange,
  onNameChange,
  pagePlotnameBased = true,
}) => {
  const chartDataSource = source;
  const i18n = useTextSnippets('widgetPage');
  const sourceRef = useRef<ChartDataSource>(chartDataSource ?? {});
  const entityRef = useRef<EntityDetails | null>(null);
  const subEntityRef = useRef<EntityDetails | null>(null);

  const entityQuery = useEntityQuery(
    {
      entityId: chartDataSource?.entityId,
      include_children: true,
      include_child_metricsets: true,
    },
    {
      // need to disable cache, 'cause onSuccess doesn't get invoked for cached queries ¯\_(ツ)_/¯
      cacheTime: 0,
      staleTime: 0,
      onSuccess(response: EntityDetails) {
        if (!response.parent) {
          entityRef.current = response;
          subEntityRef.current = null;
        } else {
          subEntityRef.current = response;
        }
      },
    }
  );

  const parentQuery = useEntityQuery(
    { entityId: subEntityRef.current?.parent, include_children: true, include_child_metricsets: true },
    {
      // need to disable cache, 'cause onSuccess doesn't get invoked for cached queries ¯\_(ツ)_/¯
      cacheTime: 0,
      staleTime: 0,
      onSuccess(response: EntityDetails) {
        entityRef.current = response;
      },
      enabled: !entityRef.current && !!subEntityRef.current,
    }
  );

  const isLoading = entityQuery.isLoading || parentQuery.isLoading;

  useEffect(() => {
    sourceRef.current = chartDataSource ?? {};
    if (!chartDataSource?.entityId) {
      entityRef.current = null;
      subEntityRef.current = null;
    }
  }, [chartDataSource]);

  const handleEntityChange = useCallback(
    (option: InfiniteListItem<Entity> | null) => {
      if (option) {
        onChange?.({
          entityId: `${option.value.id}`,
          page: undefined,
          plotname: undefined,
          metricId: undefined,
        });
        onNameChange?.(option.value.name);
      } else {
        entityRef.current = null;
        subEntityRef.current = null;
        onChange?.(null);
        onNameChange?.(null);
      }
    },
    [onChange]
  );

  const handleSubEntityChange = useCallback(
    (option: InfiniteListItem<Entity> | null) => {
      if (option) {
        onChange?.({
          entityId: `${option.value.id}`,
          page: undefined,
          plotname: undefined,
          metricId: undefined,
        });
        onNameChange?.([entityRef.current?.name, option.value.name].filter(Boolean).join(', '));
      } else if (entityRef.current) {
        subEntityRef.current = null;
        onChange?.({
          entityId: `${entityRef.current.id}`,
          page: undefined,
          plotname: undefined,
          metricId: undefined,
        });
        onNameChange?.(entityRef.current?.name ?? null);
      } else {
        subEntityRef.current = null;
        onChange?.(null);
      }
    },
    [onChange]
  );

  const handleDataSourceChange = useCallback(
    (newSource: ChartDataSource) => {
      onChange?.(newSource);
      onNameChange?.(
        [entityRef.current?.name, subEntityRef.current?.name, newSource.page, newSource.plotname ?? newSource.metricId]
          .filter(Boolean)
          .join(', ')
      );
    },
    [onChange]
  );

  return (
    <>
      <EntitiesControl
        label={i18n.entity}
        placeholder={i18n.selectEntity}
        onChange={handleEntityChange}
        value={chartDataSource?.entityId && entityRef.current ? entityToOption(entityRef.current) : null}
        loading={isLoading}
        parentOnly
        filterable
        clearable
        fullWidth
        filterEmpty={!pagePlotnameBased}
      />

      {chartDataSource?.entityId && !!entityRef.current?.children && (
        <SubLevel additionalClass="-mt-16">
          <EntitiesControl
            label={i18n.subEntity}
            placeholder={i18n.selectSubEntity}
            onChange={handleSubEntityChange}
            value={subEntityRef.current ? entityToOption(subEntityRef.current) : null}
            loading={isLoading}
            childrenOf={entityRef.current.id}
            filterable
            clearable
            fullWidth
            filterEmpty={!pagePlotnameBased}
          />
        </SubLevel>
      )}

      {chartDataSource?.entityId &&
        !!(entityRef.current || subEntityRef.current) &&
        (pagePlotnameBased ? (
          <PagePlotnamePicker
            source={chartDataSource}
            plots={subEntityRef.current ? subEntityRef.current.plots : entityRef.current?.plots}
            onChange={handleDataSourceChange}
          />
        ) : (
          <MetricPicker
            source={chartDataSource}
            metricsets={subEntityRef.current ? subEntityRef.current.metricsets : entityRef.current?.metricsets}
            onChange={handleDataSourceChange}
          />
        ))}
    </>
  );
};
