import { OptionsTab } from './components/OptionsTab';
import { DEFAULT_CHART_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { WidgetSetup } from '@components/pages/WidgetPage/types';
import { ChartWidget } from '@components/widgets/ChartWidget';
import { UniversalDataSourcePicker } from './components/UniversalDataSourcePicker';

export default {
  type: 'chart',
  OptionsTab,
  DataSourcePicker: UniversalDataSourcePicker,
  Widget: ChartWidget,
  defaultSubConfig: DEFAULT_CHART_WIDGET_CONFIG,
} as WidgetSetup;
