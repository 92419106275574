import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';
import { GenericOkApiResponse } from '@infrastructure/api/types';
import { AccountUpdateData } from '@redux/auth';

export type UpdateAccountMutationSettings = { noDefaultSuccess?: boolean };

export const useUpdateAccountMutation = <
  T extends GenericOkApiResponse = GenericOkApiResponse,
  P extends AccountUpdateData = AccountUpdateData
>(
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> => {
  return useMutation<T, Error, P>(
    (params): Promise<T> => apiClient.put<T, AccountUpdateData>(ENDPOINTS.updateAccount, params),
    {
      ...mutationOptions,
    }
  );
};
