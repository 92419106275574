import React, { FC, useEffect } from 'react';
import Error404Page from '@components/pages/Error404Page';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import { useLoadingContext } from 'react-router-loading';
import { getURLQueryParams } from '@utils/url';
import { pick } from 'lodash';
import { ChartDataSource } from '@infrastructure/redux/widgetPage';
import { useAlertsQuery } from '@infrastructure/api/BaseNClient/useAlertsQuery';
import { ChartDetails } from '../ChartPage/components/ChartDetails';

export const AlertPage: FC = () => {
  const source = pick(getURLQueryParams(), ['alertId', 'entityId']) as ChartDataSource;

  const alertQuery = useAlertsQuery({
    ids: source.alertId,
    details: true,
  });

  const entityQuery = useEntityQuery(
    { entityId: source.entityId, alerts: true, include_maintenance: true },
    { enabled: !!source.entityId || !!alertQuery.data?.alerts?.[0].entityid }
  );
  const loadingContext = useLoadingContext();

  useEffect(() => {
    if (entityQuery.data) {
      loadingContext.done();
    }
  }, [entityQuery?.data, loadingContext]);

  if ((!alertQuery.isLoading && !alertQuery.data?.alerts?.[0].entityid) || !entityQuery.data) {
    return <Error404Page text="Entity not found" />;
  }

  return !entityQuery.isLoading ? <ChartDetails entity={entityQuery.data} source={source} /> : null;
};
