const ENDPOINTS = {
  addTag: '/usertag',
  appSettings: '/info',
  categories: '/categories/',
  createDashboard: '/dashboard',
  createIssueView: '/issues/issue_views',
  dashboardById: '/dashboard/:dashboardId',
  dashboards: '/dashboard/',
  deleteDashboard: '/dashboard/:dashboardId',
  deleteIssueView: '/issues/issue_views/:id',
  deleteWidget: '/widget/:widgetId',
  fetchChartData: '/filterdata',
  forgotPassword: '/pwreset',
  forgotPasswordCode: '/pwreset/validate',
  getAlerts: '/alerts',
  alertOverride: '/alerts/alertoverride',
  getOptions: '/entities/summary/:property',
  getEntities: '/entities/', // see https://jira.basen.net/browse/BN-8877
  getEntity: '/entities/:entityId',
  getMapTopology: '/map/entity_topology',
  getWidget: '/widget/:widgetId',
  issueActionCall: '/issues/runScriptWithIssues',
  issueActions: '/issues/issue_actions',
  issueById: '/issues/:issueId',
  issueFields: '/issues/issueFields',
  issueGroups: '/issues/issuegroups',
  issueHistory: '/issues/history',
  issues: '/issues',
  issueViews: '/issues/issue_views',
  login: '/users/renew_token',
  newPassword: '/users/change_password',
  postWidget: '/widget',
  putWidget: '/widget/:widgetId',
  search: '/search/:searchTerm',
  toggleDashboardFavorite: '/dashboard/:dashboardId/category/:categoryId',
  updateAccount: '/users/:id',
  updateDashboard: '/dashboard/:id',
  updateIssue: '/issues/:id',
  updateIssueView: '/issues/issue_views/:id',
  maintenanceWindows: '/maintenancewindow',
  maintenanceWindowById: '/maintenancewindow/:id',
  metrics: '/metrics',
  metricsets: '/metricsets',
  logbook: '/logbook',
  logbookById: '/logbook/:entityId/:noteId',
  deviceConfigParams: '/configuration/snapshot/device',
  deviceConfigParamsById: '/configuration/:entityId/snapshot/device/',
  deviceConfigHistory: '/configuration/:entityId/snapshot/device/history',
  queueConfigForBackup: '/configuration/:entityId/snapshot/device/refresh',
  configSearch: '/configuration/snapshot/device/search',
  sessionById: '/jumpstation/:entityId/:user/:sessionId/log',
  connectionCheck: '/jumpstation/:entityId/check_connection',
  batchById: '/jumpstation/batch/log/:batchId',
  scripts: '/scripts',
  scriptById: '/scripts/:id',
  scriptByName: '/pages/scripts/:name',
  settingsByKey: '/settings/:key',
  settings: '/settings',
  userById: '/users/:userId',
  me: '/me',
} as const;

export default ENDPOINTS;
