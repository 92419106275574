import React, { Children } from 'react';
import c from 'classnames';
import { Intent } from '@blueprintjs/core';
import { Classes, Tooltip2 as BPTooltip, Tooltip2Props } from '@blueprintjs/popover2';

import styles from './style.module.scss';

/**
 * https://blueprintjs.com/docs/versions/4/#core/components/popover.props
 * Relevant props:
 * disabled - bool
 * content - string or JSX, what goes into the tooltip?
 * minimal - bool, toggle arrow for tooltip
 * usePortal - bool, use for complex positioned or overflow disabled elements, performance tradeoff
 */
export type TooltipProps = { additionalClass?: string; noIndicatorClass?: boolean } & Tooltip2Props;

const Tooltip: React.FC<TooltipProps> = ({ additionalClass, noIndicatorClass = true, children, ...rest }) => (
  <BPTooltip
    className={c(!noIndicatorClass && Classes.TOOLTIP2_INDICATOR, additionalClass)}
    {...rest}
    popoverClassName={c(
      styles.tooltip,
      rest.popoverClassName,
      (!rest.intent || rest.intent === Intent.NONE) && styles.noIntentTooltip
    )}
    children={(!rest.renderTarget && Children.count(children) && <div>{children}</div>) || undefined}
  />
);

export default Tooltip;
