import Icon, { IconNames } from '@components/common/Icon';
import CopyText from '@components/typography/CopyText';
import { noop } from '@utils/util';
import React, { FC } from 'react';
import c from 'classnames';
import Headline from '@components/typography/Headline';

import styles from './ShortcutList.module.scss';

export type ShortcutProps = {
  id?: string;
  icon?: IconNames;
  title: string;
  description?: string;
  active?: boolean;
  onClick?: (id: string) => void;
};

const Shortcut: FC<ShortcutProps> = ({ id, icon, title, description, active = false, onClick = noop }) => {
  return (
    <li className={c(styles.shortcut, active && styles.active)} onClick={() => onClick(id ?? title)}>
      {icon && <Icon name={icon} />}
      <CopyText variant="copy-3" additionalClass={styles.title}>
        {title}
      </CopyText>
      {description && (
        <CopyText variant="copy-6" additionalClass={styles.description}>
          {description}
        </CopyText>
      )}
    </li>
  );
};

export type ShortcutListProps = {
  title?: string;
  shortcuts: ShortcutProps[] | ShortcutListProps[];
  variant?: 'full' | 'minimal' | 'buttonLike';
  additionalClass?: string;
};

export const ShortcutList: FC<ShortcutListProps> = ({ title, shortcuts, variant = 'minimal', additionalClass }) => {
  const isNested = Array.isArray((shortcuts as ShortcutListProps[])[0]?.shortcuts);

  return (
    <div className={c(styles.shortcutList, additionalClass, styles[variant])}>
      {title && (
        <Headline variant="headline-5" additionalClass={styles.mainTitle}>
          {title}
        </Headline>
      )}
      {isNested ? (
        (shortcuts as ShortcutListProps[]).map(({ title: subTitle, shortcuts: subShortucts }: ShortcutListProps) => (
          <>
            <CopyText variant="copy-3" additionalClass={styles.subTitle}>
              {subTitle}
            </CopyText>
            <ul className={styles.subShortcuts}>
              {(subShortucts as ShortcutProps[]).map((props: ShortcutProps) => (
                <Shortcut key={props.id ?? props.title} {...props} />
              ))}
            </ul>
          </>
        ))
      ) : (
        <ul>
          {(shortcuts as ShortcutProps[]).map((props: ShortcutProps) => (
            <Shortcut key={props.id ?? props.title} {...props} />
          ))}
        </ul>
      )}
    </div>
  );
};
