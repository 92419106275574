import React from 'react';
import Icon, { IconNames } from '@components/common/Icon';
import { titleCase } from '@utils/string';
import ROUTES from '@infrastructure/routes';
import Link from '@components/common/Link';
import { SearchHit } from '@infrastructure/api/BaseNClient/useGlobalSearch';

import styles from './SearchSuggestion.module.scss';

type AutoSuggestionProps = {
  suggestion: SearchHit;
  searchTerm: string;
  onClick: (suggestion: SearchHit) => void;
};

export function getSuggestionUrl(suggestion: SearchHit) {
  switch (suggestion.type) {
    case 'dashboard':
      return ROUTES.dashboardById.replace(':dashboardId', suggestion.id);
    case 'issue':
      return ROUTES.issueById.replace(':issueId', suggestion.id);
    case 'alert':
      return ROUTES.alertById.replace(':alertId', suggestion.id);
    case 'entity':
    default:
      return ROUTES.entityById.replace(':entityId', suggestion.id);
  }
}

function getSuggestionIcon(suggestion: SearchHit): IconNames | null {
  switch (suggestion.type) {
    case 'dashboard':
      return 'Dashboard';

    case 'issue':
      return 'Issues';

    case 'alert':
      return 'AlertOn';

    case 'entity':
      return titleCase(suggestion.icon ?? 'default-device') as IconNames;

    default:
      return null;
  }
}

function highlightSearchTerm(name: string, term: string) {
  const re = new RegExp(term.replace(/\*+/g, '.*'), 'ig');
  return name.replace(re, `<span class="${styles.highlight}">$&</span>`);
}

function getSuggestionTitle(suggestion: SearchHit, term: string) {
  const suggestionIcon = getSuggestionIcon(suggestion);

  const name = [<span dangerouslySetInnerHTML={{ __html: highlightSearchTerm(suggestion.name, term) }} />];
  if (suggestion.parent_name) {
    name.unshift(
      <span dangerouslySetInnerHTML={{ __html: highlightSearchTerm(suggestion.parent_name, term) }} />,
      <Icon name="ChevronRight" size="xs" additionalClass="mx-4" />
    );
  }

  switch (suggestion.type) {
    case 'entity':
      return (
        <>
          <div className={styles.info}>
            {suggestionIcon && <Icon size="s" name={suggestionIcon} additionalClass={styles.suggestionIcon} />}
            <div className={styles.suggestionName}>{name}</div>
            {suggestion.description && <div className={styles.description}>{suggestion.description}</div>}
          </div>
          {suggestion.customer && <div className={styles.customer}>{suggestion.customer}</div>}
        </>
      );
    default:
      return (
        <div className={styles.info}>
          {suggestionIcon && <Icon size="s" name={suggestionIcon} additionalClass={styles.suggestionIcon} />}
          <div className={styles.suggestionName}>{name}</div>
        </div>
      );
  }
}

const SearchSuggestion: React.FC<AutoSuggestionProps> = ({ suggestion, searchTerm, onClick }) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      // react only on left button click
      if (e.button === 0) {
        e.preventDefault();
        e.stopPropagation();
        onClick?.(suggestion);
      }
    },
    [onClick, suggestion]
  );

  return (
    <Link
      href={getSuggestionUrl(suggestion)}
      additionalClass={styles.suggestionContainer}
      onClick={handleClick}
      // @ts-ignore className here will propagate down to the a element, is required in SearchBar to recognize relatedTarget
      // TODO: whole thing is terrible idea, we should refactor this
      className={`suggestion${suggestion.id}`}
    >
      {getSuggestionTitle(suggestion, searchTerm)}
    </Link>
  );
};

export default SearchSuggestion;
