import { round } from 'lodash';
import invariant from './invariant';

type UnitDefinition = {
  unit: string;
  step: number;
  prefix?: string;
};

type UnitSetup = {
  idx: number;
  defs: UnitDefinition[];
  aliases?: string[];
};

const units: Record<UnitDefinition['unit'], UnitSetup> = {};

export const getUnitDef = (unit: string = ''): UnitDefinition => {
  if (!units[unit]) {
    return {
      unit,
      step: 0,
    };
  }

  const { idx, defs } = units[unit];

  return defs[idx];
};

export const unitConverter = (fromUnit: string, toUnit: string, amount: number, precision = 2): number => {
  invariant(
    units[fromUnit],
    `unitConverter: no definition for '${fromUnit}', be sure to register it first with registerUnit`
  );

  invariant(
    units[toUnit],
    `unitConverter: no definition for '${toUnit}', be sure to register it first with registerUnit`
  );

  if (fromUnit === toUnit) {
    return round(amount, precision);
  }

  const from = units[fromUnit];
  const to = units[toUnit];

  const isDownCasting = from.idx > to.idx;

  const fromIdx = isDownCasting ? to.idx : from.idx;
  const toIdx = isDownCasting ? from.idx : to.idx;

  let ratio: number = 1;
  for (let i = fromIdx + 1; i <= toIdx; i++) {
    ratio *= from.defs[i].step;
  }

  return round(isDownCasting ? amount * ratio : amount / ratio, precision);
};

export const normalizeValue = (
  amount: number,
  unit: UnitDefinition['unit'] = '',
  precision = 2
): [number, UnitDefinition['unit'], string | undefined] => {
  invariant(units[unit], `normalizeValue: no definition for '${unit}', be sure to register it first with registerUnit`);

  const setup = units[unit];

  const aliasIdx = setup.aliases?.findIndex((alias: string) => alias === unit) ?? -1;

  let idx = setup.idx;
  let value = amount;
  let finalUnit = unit;
  let finalPrefix = setup.defs[idx].prefix;

  while (value < 0.1 && idx >= 0 && setup.defs[idx].step > 0) {
    value *= setup.defs[idx].step;
    idx--;
    const def = setup.defs[idx];
    finalUnit = aliasIdx < 0 ? def.unit : units[def.unit].aliases?.[aliasIdx] ?? def.unit;
    finalPrefix = def.prefix;
  }

  idx = setup.idx + 1;

  while (idx < setup.defs.length && value > setup.defs[idx].step) {
    const def = setup.defs[idx];
    value /= def.step;
    finalUnit = aliasIdx < 0 ? def.unit : units[def.unit].aliases?.[aliasIdx] ?? def.unit;
    finalPrefix = def.prefix;
    idx++;
  }

  return [round(value, precision), finalUnit, finalPrefix];
};

export const registerUnit = (
  defs: UnitDefinition[] | Record<UnitDefinition['unit'], number>,
  aliasMap: Record<UnitDefinition['unit'], string[]> = {}
) => {
  const arrDefs = Array.isArray(defs)
    ? defs
    : Object.keys(defs).reduce((arr, unit) => [...arr, { unit, step: defs[unit] }], [] as UnitDefinition[]);

  // for every unit definition and alias we store reference to full setup for instant lookup
  arrDefs.forEach(({ unit }, idx) => {
    const setup = {
      idx,
      defs: arrDefs,
      aliases: aliasMap[unit],
    };

    units[unit] = setup;

    if (aliasMap[unit]?.length) {
      aliasMap[unit].forEach((alias: string) => {
        units[alias] = setup;
      });
    }
  });
};

export const getBetterUnit = (anyValue: number | string, unit: string = ''): string => {
  const value = +anyValue;

  invariant(!Number.isNaN(value), `getBetterUnit: value '${anyValue}' is not a number`);

  try {
    const [, newUnit] = normalizeValue(value, unit);

    return newUnit;
  } catch (ex: any) {
    return unit;
  }
};

const DEFAULT_PREFIXES = ['p', 'n', 'µ', 'm', '', 'k', 'M', 'G', 'T'];

export const generateDefs = (base: string, step: number = 1000, prefixes: string[] = DEFAULT_PREFIXES) =>
  prefixes.reduce((defs, prefix: string) => [...defs, { unit: `${prefix}${base}`, step, prefix }], [
    { unit: base, step: 0 },
  ] as UnitDefinition[]);

export const generateAliases = (base: string, aliases: string[], prefixes: string[] = DEFAULT_PREFIXES) =>
  prefixes.reduce(
    (defs, prefix: string) => ({ ...defs, [`${prefix}${base}`]: aliases.map(alias => `${prefix}${alias}`) }),
    {
      [base]: aliases,
    }
  );

// we still need to survive without any units
registerUnit(generateDefs(''));

registerUnit(
  {
    nanoseconds: 0,
    milliseconds: 1000,
    seconds: 1000,
    minutes: 60,
    hours: 60,
    days: 24,
    years: 365,
  },
  {
    seconds: ['s', 'sec', 'secs', 'second', 'Sec', 'Second', 'Seconds'],
    milliseconds: ['ms'],
    nanoseconds: ['ns'],
    minutes: ['min', 'minute', 'Min', 'Minute', 'Minutes'],
    hours: ['h', 'hour', 'Hour', 'Hours'],
    days: ['d', 'day', 'Day', 'Days'],
    years: ['y', 'year', 'Year', 'Years'],
  }
);
registerUnit(generateDefs('bytes', 1024), generateAliases('bytes', ['B', 'byte', 'Byte', 'Bytes']));
registerUnit(generateDefs('bytes/s', 1024));
registerUnit(generateDefs('characters'));
registerUnit(generateDefs('bps'), generateAliases('bps', ['bit/s']));
registerUnit(
  generateDefs('packets/s'),
  generateAliases('packets/s', ['pkt/s', 'pkts/s', 'packet/s', 'Packet/s', 'Packets/s'])
);
registerUnit(
  generateDefs('errors/s'),
  generateAliases('errors/s', ['error/s', 'err/s', 'errs/s', 'Errors/s', 'Errs/s'])
);
registerUnit(generateDefs('discards/s'), generateAliases('discards/s', ['discard/s', 'Discard/s', 'Discards/s']));
registerUnit(generateDefs('frames/s'), generateAliases('frames/s', ['frame/s', 'Frame/s', 'Frames/s']));
registerUnit(generateDefs('timeticks'));
