import React from 'react';
import c from 'classnames';
import styles from './style.module.scss';
import { DroppableProvidedProps } from 'react-beautiful-dnd';

type Props = {
  dndRef?: (element: HTMLElement | null) => any;
  additionalClass?: string;
  noSpacing?: boolean;
  dndProps?: DroppableProvidedProps;
  dndActive?: boolean;
};

const Grid: React.FC<Props> = ({ additionalClass, dndRef, dndProps = {}, noSpacing, dndActive, children }) => (
  <div
    ref={dndRef}
    {...dndProps}
    className={c(styles.gridContainer, noSpacing && styles.noSpacing, dndActive && styles.dragging, additionalClass)}
  >
    {children}
  </div>
);

export default Grid;
