import { object, string, TypeOf, bool } from 'yup';
import { useMemo } from 'react';
import { SamplingMethod } from '../types';

const useSchema = () => {
  const schema = object({
    palette: string(),
    showZoomSlider: bool(),
    horizontalLegend: bool(),
    showSamplingMethodSwitch: bool(),
    showMaintenanceWindows: bool(),
    showLogbookNotes: bool(),
    showAlerts: bool(),
    samplingMethod: string().oneOf(Object.values(SamplingMethod)),
    showWatermark: bool(),
    showSummary: bool(),
    showDateRange: bool(),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, []);
};

export interface OptionsTabSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
