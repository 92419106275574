import useTextSnippets from '@services/useTextSnippets';
import Modal, { ModalProps } from '@components/common/Modal';
import CopyText from '@components/typography/CopyText';
import React, { useRef, useState } from 'react';

type HandleConfirmProps = {
  title?: string;
  contents: string | React.ReactNode;
  modalProps?: Partial<ModalProps>;
};

export type ConfirmOperation = (confirmProps: HandleConfirmProps) => Promise<boolean>;

export type WithConfirmation = {
  confirmOperation: ConfirmOperation;
};

function withConfirmation<P>(WrappedComponent: React.ComponentType<P & WithConfirmation>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithConfirmationProps = (props: P) => {
    const i18n = useTextSnippets('common');

    const [isOpen, setIsOpen] = useState(false);
    const confirmPropsRef = useRef<HandleConfirmProps | null>(null);
    const resolveRef = useRef<Function | null>(null);

    const confirmOperation = (confirmProps: HandleConfirmProps): Promise<boolean> => {
      confirmPropsRef.current = { title: i18n.areYouSure, ...confirmProps };
      setIsOpen(true);

      return new Promise(resolve => {
        resolveRef.current = resolve;
      });
    };

    const handleConfirm = () => {
      confirmPropsRef.current = null;
      setIsOpen(false);
      resolveRef.current?.(true);
      resolveRef.current = null;
    };

    const handleClose = () => {
      confirmPropsRef.current = null;
      setIsOpen(false);
      resolveRef.current?.(false);
      resolveRef.current = null;
    };

    const getContent = () => {
      if (typeof confirmPropsRef.current?.contents === 'string') {
        return <CopyText variant="copy-4">{confirmPropsRef.current?.contents}</CopyText>;
      }

      return confirmPropsRef.current?.contents;
    };

    return (
      <>
        <WrappedComponent {...props} confirmOperation={confirmOperation} />
        {isOpen && (
          <Modal
            isOpen={isOpen}
            onClose={handleClose}
            onAccept={handleConfirm}
            title={<CopyText variant="copy-1">{confirmPropsRef.current?.title}</CopyText>}
            {...(confirmPropsRef.current?.modalProps || {})}
          >
            {getContent()}
          </Modal>
        )}
      </>
    );
  };

  ComponentWithConfirmationProps.displayName = `withConfirmation(${displayName})`;

  return ComponentWithConfirmationProps;
}

export default withConfirmation;
