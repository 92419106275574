import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { SettingsState } from './types';
import { AppInfo } from '@infrastructure/api/BaseNClient/useSettingsData';

const initialState: SettingsState = {
  app: null,
  isReleaseEmbedLoaded: false,
};

const settingsSlice = createSlice<SettingsState, SliceCaseReducers<SettingsState>, 'settings'>({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings(state, { payload }: PayloadAction<AppInfo>) {
      state.app = payload;
    },

    updateReleaseEmbedLoaded(state, { payload }: PayloadAction<boolean>) {
      state.isReleaseEmbedLoaded = payload;
    },
  },
});

export const { updateSettings, updateReleaseEmbedLoaded } = settingsSlice.actions;

export default settingsSlice;
