import React from 'react';
import issuesSlice, { setActiveIssue } from '@redux/issues';
import { useAppDispatch } from '@infrastructure/redux/store';
import Error500Page from '@components/pages/Error500Page';
import { IssueManager } from '@components/connected/IssueManager/IssueManager';
import { useHotkeys } from 'react-hotkeys-hook';
import { KEYCODES } from '@constants/keyboardShortcutsConfig';
import IssueManagerHeader from './components/IssueManagerHeader/IssueManagerHeader';

type IssueManagementProps = { error?: boolean };

const IssueManagement: React.FC<IssueManagementProps> = ({ error }) => {
  const dispatch = useAppDispatch();

  useHotkeys(KEYCODES.CLOSE_IM_SIDEBAR, () => {
    dispatch(setActiveIssue(null));
  });

  if (error) {
    return <Error500Page />;
  }

  return (
    <div className="page-container">
      <IssueManagerHeader />
      <div className="page-body">
        <IssueManager showExtendedToolbar showDetailsInSidePanel actions={issuesSlice.actions} />
      </div>
    </div>
  );
};

export default IssueManagement;
