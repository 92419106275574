import { TimeField, SelectField } from '@components/common/form';
import CopyText from '@components/typography/CopyText';
import { range } from 'lodash';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MonthlyMode = () => {
  return (
    <>
      <div className="flex flex-row items-center gap-8 mb-16">
        <TimeField label="From" name="startTime" useAmPm={false} />
        <TimeField label="To" name="endTime" useAmPm={false} />
      </div>

      <div className="flex flex-row items-center gap-4 mb-16">
        <SelectField
          name="monthRepeatInterval"
          label="Repeat every"
          options={range(1, 12).map(month => ({ label: MONTHS[month - 1], value: month }))}
          inline
          noInfo
          clearable={false}
          additionalClasses={{ control: 'w-208' }}
        />
        <CopyText variant="copy-4">month(s)</CopyText>
      </div>

      <div className="flex flex-row items-center gap-4">
        <SelectField
          name="dayRepeatInterval"
          label="On"
          options={range(1, 31).map(day => ({ label: day, value: day }))}
          inline
          noInfo
          clearable={false}
          additionalClasses={{ control: 'w-100' }}
        />
        <CopyText variant="copy-4">day</CopyText>
      </div>
    </>
  );
};
