import React, { useEffect } from 'react';
import { FormSubmitOptions } from '@components/common/form';
import { useAuth, AccountData } from '@redux/auth';
import ROUTES from '@infrastructure/routes';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { selectFeatureFlags } from '@redux/settings';
import LoginForm from './LoginForm/LoginForm';
import { LoginSchema } from './LoginForm/LoginForm.schema';
import { useNavigate, useLocation } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const auth = useAuth();
  const { allowPasswordRecovery } = useAppSelector(selectFeatureFlags);
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as any)?.from?.pathname ?? ROUTES.home;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { isAuthenticated } = auth;

    if (isAuthenticated) {
      navigate(from);
    }
  }, [navigate, auth, from]);

  const login = (values: LoginSchema, helpers: FormSubmitOptions<LoginSchema>) => {
    auth.login.mutate(values as AccountData, {
      onSuccess: response => auth.onLoginSuccess(dispatch, response.token),

      onError: () => {
        helpers.setSubmitting(false);
      },
    });
  };

  const clearError = () => {
    auth.login.reset();
  };

  return (
    <LoginForm
      onSubmit={login}
      submitError={auth.login.isError}
      showForgotPassword={allowPasswordRecovery}
      clearError={clearError}
    />
  );
};

export default LoginPage;
