import { createSelector } from 'reselect';
import { AppState } from '@infrastructure/redux/store';
import { DashboardWithDetails, DashboardsState, DashboardSortingParams } from './types';
import { DashboardsQueryRequest } from '@infrastructure/api/BaseNClient/useDashboardsQuery';
import { DEFAULT_TABLE_CONFIG, StoredTableConfig } from '@components/common/Table';
import { retrieveItem } from '@utils/storage';
import { PaginationParams } from '@infrastructure/redux/types';
import { selectQueryStringParams } from '../location';
import { isEmpty } from 'lodash';

export const DASHBOARDS_SETTINGS_ID = `table-settings-dashboards`;

export const selectSliceRoot = (state: AppState): DashboardsState => state.dashboards;

export const selectDashboards = createSelector(selectSliceRoot, _ => _.items);
export const selectDashboardsAsArray = createSelector(selectDashboards, items => Object.values(items));
export const selectDashboardDialogs = createSelector(selectSliceRoot, _ => _.dialogs);
export const selectActiveItem = createSelector(selectSliceRoot, _ => _.currentlyActiveItem);
export const selectActiveItems = createSelector(selectSliceRoot, _ => _.currentlyActiveItems);
export const selectCurrentDashboardState = createSelector(selectSliceRoot, _ => _.currentState);

// the following might seem exessive, but in reality it's the way to memoize ¯\_(ツ)_/¯
export const selectFilters = createSelector(selectSliceRoot, _ => _.filters);
export const selectResolvedFilters = createSelector(selectFilters, _ => _);

export const selectPagination = createSelector(selectSliceRoot, _ => _.pagination);
export const selectResolvedPagination = createSelector(
  selectPagination,
  selectQueryStringParams,
  (pagination, params) => {
    const storedParams = retrieveItem<StoredTableConfig>(DASHBOARDS_SETTINGS_ID);
    return {
      rowsPerPage: +(
        params.rowsPerPage ??
        pagination?.rowsPerPage ??
        storedParams?.rowsPerPage ??
        DEFAULT_TABLE_CONFIG.rowsPerPage
      ),
      page: +(params.page ?? pagination?.page ?? DEFAULT_TABLE_CONFIG.page),
      rowCount: pagination?.rowCount,
    } as PaginationParams;
  }
);

export const selectSorting = createSelector(selectSliceRoot, _ => _.sort);
export const selectResolvedSorting = createSelector(selectSorting, selectQueryStringParams, (sort, params) => {
  const storedParams = retrieveItem<StoredTableConfig>(DASHBOARDS_SETTINGS_ID);
  return {
    sortBy: params.sortBy ?? sort?.sortBy ?? storedParams?.sortBy,
    sort: params.sort ?? sort?.sort ?? storedParams?.sort,
  } as DashboardSortingParams;
});

export const createSelectDashboardById = (id: string) => createSelector(selectDashboards, map => map[id]);

export const createSelectIsFavoriteDashboard = (dashboardId: string) =>
  createSelector(
    selectDashboards,
    (dashboards: Record<string, DashboardWithDetails>) => dashboards[dashboardId]?.tags?.includes('favorite') ?? false
  );

export const selectNoDashboardsAvailable = createSelector(
  selectDashboards,
  dashboards => Object.keys(dashboards).length === 0
);

export const selectAreDashboardsLoaded = createSelector(selectSliceRoot, _ => _.areLoaded);

export const selectQueryParams = createSelector(
  selectResolvedFilters,
  selectResolvedPagination,
  selectResolvedSorting,
  (filters, pagination, sorting) => {
    const params: DashboardsQueryRequest = {};

    if (filters.generalFilter) {
      // params.general = `.*${filters.generalFilter}.*`;
      params.general = filters.generalFilter;
    }

    if (!isEmpty(filters.discreteFilters)) {
      Object.keys(filters.discreteFilters).forEach(filterKey => {
        // @ts-ignore
        params[filterKey] = filters.discreteFilters[filterKey];
      });
    }

    params.start_index = (pagination.page - 1) * pagination.rowsPerPage;
    params.end_index = (pagination.page - 1) * pagination.rowsPerPage + pagination.rowsPerPage;

    if (sorting.sort && sorting.sortBy) {
      params.order = `${sorting.sort === 'desc' ? '-' : ''}${sorting.sortBy}`;
    }

    return params;
  }
);
