import React from 'react';
import { IssueView } from '@redux/issues';
import CopyText from '@components/typography/CopyText';
import { Tag } from '@components/common/Tag';
import useTextSnippets from '@services/useTextSnippets';

type Props = {
  view: IssueView;
};

const IssueViewGroups: React.FC<Props> = ({ view }) => {
  const issueViewManagerI18n = useTextSnippets('issueViewManager');
  const hasGroups = view.filters.groups && view.filters.groups.length > 0;

  return (
    <div className="hidden items-center w-full tablet:flex">
      <CopyText variant="copy-3" additionalClass="flex mr-8">
        {issueViewManagerI18n.groups}:
        {!hasGroups && <span className="ml-4 copy-4 text-gray-4">{issueViewManagerI18n.allGroups}</span>}
      </CopyText>
      {hasGroups && (
        <div className="flex flex-wrap flex-1 items-center mb-4">
          {view.filters.groups.slice(0, 4).map(group => (
            <Tag variant={group.includes('!') ? 'error' : 'info'} key={group} additionalClass="mr-8 mt-4">
              {group}
            </Tag>
          ))}
          {view.filters.groups.length > 4 && (
            <Tag variant="info" key="moreGroupsHidden" additionalClass="mr-8 mt-4">
              ...
            </Tag>
          )}
        </div>
      )}
    </div>
  );
};

export default IssueViewGroups;
