import React, { ReactNode, useCallback, useMemo } from 'react';
import useTextSnippets from '@services/useTextSnippets';
import Button from '@components/common/Button';
import ItemGridDND from '@components/common/ItemGridDND/ItemGridDND';
import { DropResult } from 'react-beautiful-dnd';
import { reorder } from '@utils/dndUtils';
import styles from './ItemGridAdder.module.scss';
import IconButton from '@components/common/IconButton';
import { FieldArray, FieldArrayRenderProps } from 'formik';

type Props<T extends { id: string; order: number }> = {
  title: string;
  description: string | ReactNode | JSX.Element;
  addLabel?: string;
  showIndex?: boolean;
  formContext?: string;
  items: T[];
  onReorder: (items: T[]) => void;
  itemRenderer: (item: T, index: number) => ReactNode | JSX.Element;
  onAdd: (index: number) => T;
  onRemove?: (index: number) => void;
};

function ItemGridAdder<T extends { id: string; order: number }>({
  onAdd,
  title,
  description,
  showIndex,
  items,
  formContext,
  onReorder,
  itemRenderer,
  addLabel,
  onRemove,
}: Props<T>) {
  const commonI18n = useTextSnippets('common');
  const addButtonLabel = addLabel || commonI18n.addItem;

  const onAddItem = useCallback(
    (arrayHelpers?: FieldArrayRenderProps) => {
      const newItem = onAdd(items.length);

      arrayHelpers?.push(newItem);
    },
    [items.length, onAdd]
  );

  const onRemoveItem = useCallback(
    (index: number, arrayHelpers?: FieldArrayRenderProps) => {
      onRemove?.(index);
      arrayHelpers?.remove(index);
    },
    [onRemove]
  );

  const getRowRenderer = useCallback(
    // eslint-disable-next-line react/no-unstable-nested-components,react/display-name
    (arrayHelpers?: FieldArrayRenderProps) => (item: T, index: number) =>
      (
        <div className="flex items-center">
          {itemRenderer(item, index)}
          <IconButton
            onClick={() => onRemoveItem(index, arrayHelpers)}
            icon="RemoveCircleOutline"
            additionalClass="text-red-error p-2 ml-4"
            size="m"
          />
        </div>
      ),
    [itemRenderer, onRemoveItem]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;

      if (!destination || !source || source.index === undefined || destination.index === source.index) {
        return;
      }

      const newItemOrder = reorder(items, source.index, destination.index);

      onReorder(newItemOrder);
    },
    [items, onReorder]
  );

  const getItemGrid = useCallback(
    (arrayHelpers?: FieldArrayRenderProps) =>
      items.length > 0 ? (
        <ItemGridDND<T>
          showIndex={showIndex}
          minimal
          id="issue-custom-actions-grid"
          onDragEnd={onDragEnd}
          additionalClass={styles.grid}
          items={items}
          itemContentRenderer={getRowRenderer(arrayHelpers)}
        />
      ) : null,
    [showIndex, items, onDragEnd, getRowRenderer]
  );

  const wrappedItemGrid = useMemo(
    () =>
      formContext ? (
        <FieldArray
          name={formContext}
          render={arrayHelpers => (
            <>
              {getItemGrid(arrayHelpers)}
              <Button
                size="s"
                onClick={() => onAddItem(arrayHelpers)}
                variant="outline"
                additionalClass="max-w-fit-content mb-16 mt-8"
              >
                {addButtonLabel}
              </Button>
            </>
          )}
        />
      ) : (
        getItemGrid()
      ),
    [addButtonLabel, formContext, getItemGrid, onAddItem]
  );

  return (
    <div className="flex flex-col w-full">
      {title}
      {description}
      {wrappedItemGrid}
      {!formContext && (
        <Button variant="fillBlueDark" onClick={() => onAddItem()} additionalClass="max-w-fit-content my-16">
          {addButtonLabel}
        </Button>
      )}
    </div>
  );
}

export default ItemGridAdder;
