import React, { ReactElement, useCallback, useMemo } from 'react';
import useTextSnippets from '@services/useTextSnippets';
import c from 'classnames';
import Icon from '@components/common/Icon';
import { FieldsConfig, FormBuilder } from '@components/common/form/FormBuilder';
import { SubmitButton } from '@components/common/form';
import IconButton from '@components/common/IconButton';
import CopyText from '@components/typography/CopyText';
import Tooltip from '@components/common/Tooltip';
import { useTableConfig } from '../../TableConfigContext';

import styles from './FiltersDrawer.module.scss';

export type FiltersDrawerProps = {
  isOpen: boolean;
  isLoading?: boolean;
  filters: FieldsConfig;
  onChange?: (filters?: any) => void;
  onSubmit: (filters?: any) => void;
  onFiltersViewToggle?: () => void;
};

export function FiltersDrawer({
  isOpen,
  filters,
  onChange,
  onSubmit,
  onFiltersViewToggle,
}: FiltersDrawerProps): ReactElement | null {
  const { loading: isLoading = false } = useTableConfig();

  const i18n = useTextSnippets();

  const handleApply = useCallback(
    (values?: Dictionary<any>) => {
      onSubmit(values);
    },
    [onSubmit]
  );

  const modifiedFilters = useMemo(
    () =>
      filters.map(field =>
        field.type === 'group'
          ? field
          : {
              ...field,
              additionalClass: c(styles.field, field.additionalClass),
              props: {
                ...field.props,
                variant: 'menu',
                additionalClass: c(styles.sideFilter, field.props.additionalClass),
              },
            }
      ) as FieldsConfig,
    [filters]
  );

  return (
    <div className={c(styles.filtersDrawer, isOpen && styles.isOpen)}>
      <div className={styles.header}>
        <CopyText variant="copy-1">
          <Icon name="Filter" additionalClass="mr-4" size="m" />
          {i18n.table.filters}
        </CopyText>

        {onFiltersViewToggle && (
          <Tooltip content={i18n.table.filtersInPopup}>
            <IconButton
              icon="Minimize"
              onClick={onFiltersViewToggle}
              additionalClass="self-justify-end text-blue-gray"
              size="xs"
            />
          </Tooltip>
        )}
      </div>

      <FormBuilder
        fields={modifiedFilters}
        onSubmit={handleApply}
        onChange={onChange}
        additionalClass={styles.form}
        isSubmitting={isLoading}
      >
        <div className={styles.actions}>
          <SubmitButton size="s" variant="fillBlueDark" ariaLabel={i18n.modal.apply} fullWidth>
            {i18n.modal.apply}
          </SubmitButton>
        </div>
      </FormBuilder>
    </div>
  );
}
