import { useField } from 'formik';
import React, { useCallback } from 'react';
import TimeInput from '../TimeInput/TimeInput';
import { DateControl, DateControlProps } from './DateControl';

export type DateTimeFieldProps = DateControlProps & {
  name: string;
  useAmPm?: boolean;
  disabled?: boolean;
};

export const DateTimeField: React.FC<DateTimeFieldProps> = ({
  name,
  label,
  useAmPm = false,
  disabled = false,
  ...props
}) => {
  const [field, , { setValue }] = useField<Date | null>({ name });

  const handleDateChange = useCallback(
    (newDate: Date | null) => {
      const oldDate = field?.value ? new Date(field.value) : null;
      if (newDate && oldDate) {
        newDate.setHours(oldDate.getHours(), oldDate.getMinutes());
      }
      setValue(newDate);
    },
    [setValue, field.value]
  );

  const handleTimeChange = useCallback(
    (date: Date) => {
      const newDate = field.value ? new Date(field.value) : null;
      if (newDate && date) {
        newDate.setHours(date.getHours(), date.getMinutes());
      }
      setValue(newDate);
    },
    [setValue, field.value]
  );

  return (
    <div className="flex flex-row gap-4">
      <DateControl
        inline
        label={label}
        additionalClasses={{ root: 'w-140' }}
        {...props}
        {...field}
        value={field.value ? new Date(field.value) : undefined}
        onChange={handleDateChange}
        disabled={disabled}
      />
      <TimeInput
        showArrowButtons={false}
        useAmPm={useAmPm}
        onChange={handleTimeChange}
        disabled={disabled || !field.value}
        value={field.value ? new Date(field.value) : undefined}
      />
    </div>
  );
};
