import React from 'react';
import Control, { ControlProps } from '../Control/Control';
import { TagInput, TagInputProps, TagType } from './TagInput';

export type TagInputControlProps<ItemType extends TagType = TagType> = ControlProps<
  ItemType[],
  (items: ItemType[]) => void
> &
  TagInputProps<ItemType>;

export function TagInputControl<ItemType extends TagType = TagType>({
  options,
  ...props
}: TagInputControlProps<ItemType>) {
  return (
    <Control {...props}>
      <TagInput<ItemType> options={options} {...props} />
    </Control>
  );
}
