/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import c from 'classnames';
import { Input, InputProps } from '@components/common/form/Input';
import Popover, { PopoverProps } from '@components/common/Popover';
import Icon from '@components/common/Icon';
import {
  Classes,
  QueryList,
  IQueryListRendererProps,
  ItemRenderer,
  ItemListRenderer,
  ItemPredicate,
} from '@blueprintjs/select';
import { Position } from '@blueprintjs/core';
import { MenuItem } from '@components/common/Menu';
import useTextSnippets from '@services/useTextSnippets';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { escapeRe } from '@utils/string';
import { BaseSelectItem } from './types';

import styles from './Select.module.scss';

// for backward compatibility
export type { BaseSelectItem } from './types';

export type BaseSelectProps<ItemType extends BaseSelectItem = BaseSelectItem> = {
  additionalClass?: string; // @deprecated - use additionalClasses.root
  additionalClasses?: {
    root?: string;
    input?: string;
    popover?: string;
  };
  valueAsSelectedLabel?: boolean;
  disabled?: boolean;
  filterable?: boolean;
  clearable?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  loading?: boolean;
  onChange?: (item: ItemType | null) => void;
  onQueryChange?: (query: string) => void;
  optionRenderer: ItemRenderer<ItemType>;
  itemListRenderer?: ItemListRenderer<ItemType>;
  itemPredicate?: ItemPredicate<ItemType>;
  options: ItemType[];
  value?: ItemType | null;
  popoverProps?: Partial<PopoverProps>;
  inputComponent?: React.ReactElement;
  noChevron?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
};

export type BaseSelectInputProps = InputProps & {
  defaultInputComponent?: React.ReactElement;
};

export function BaseSelect<ItemType extends BaseSelectItem = BaseSelectItem>({
  additionalClass,
  additionalClasses = {},
  disabled,
  valueAsSelectedLabel,
  filterable,
  clearable = true,
  fullWidth = false,
  noChevron,
  loading,
  onChange,
  onQueryChange,
  optionRenderer,
  itemListRenderer,
  itemPredicate,
  options,
  value,
  inputComponent,
  popoverProps,
  inputRef,
  ...props
}: BaseSelectProps<ItemType>) {
  const [query, setQuery] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const internalInputRef = inputRef ?? React.createRef<HTMLInputElement>();
  const queryListRef = useRef<QueryList<ItemType> | null>(null);
  const SelectQueryList = useMemo(() => QueryList.ofType<ItemType>(), []);
  const i18n = useTextSnippets('select');
  const isDisabled = disabled || loading;
  const hasClear = clearable && (query || value);

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(false);
    setQuery(null);
    onChange?.(null);
  };

  const handleSelect = (item: ItemType) => {
    setIsOpen(false);
    setQuery(null);
    onChange?.(item);
  };

  const handleKeyDown = () => {
    setIsOpen(true);
  };

  const handlePopoverInteraction = (newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
  };

  const handleQueryChange = (newQuery: string) => {
    if (newQuery) {
      setQuery(newQuery);
      onQueryChange?.(newQuery);
    }
  };

  const handleOpened = () => {
    if (filterable) {
      internalInputRef.current?.focus();
    }

    queryListRef.current?.scrollActiveItemIntoView();
  };

  const filterOptions =
    itemPredicate ??
    ((q: string, item: ItemType) =>
      new RegExp(escapeRe(q), 'i').test(typeof item.label === 'string' ? item.label : `${item.value}`));

  const renderQueryList = (listProps: IQueryListRendererProps<ItemType>) => {
    const inputProps = {
      ...props,
      ref: internalInputRef,
      onChange: listProps.handleQueryChange,
      onKeyDown: filterable ? handleKeyDown : undefined,
      // eslint-disable-next-line no-nested-ternary
      value: filterable && query !== null ? listProps.query : valueAsSelectedLabel ? value?.value : value?.label,
      additionalClasses: {
        root: c(styles.baseSelectInput, isOpen && styles.isOpen, additionalClass),
        input: c(additionalClasses.input, hasClear && styles.hasClear),
      },
      actionIcon: loading ? (
        <LoadingSpinner centered />
      ) : (
        <>
          {hasClear && (
            <Icon
              name="Close"
              additionalClass="mr-8"
              onClick={!isDisabled ? handleClear : undefined}
              size="xs"
              testid="BaseSelect::Close"
            />
          )}
          {!noChevron && (
            <Icon
              name="ChevronOpen"
              additionalClass={styles.toggler}
              size="s"
              onClick={!isDisabled ? handleToggle : undefined}
              testid="BaseSelect::Toggler"
            />
          )}
        </>
      ),
      readOnly: !filterable,
      disabled,
    };

    const defaultInputComponent = (
      <Input {...inputProps} value={inputProps.value as string} type="text" testid="BaseSelect::Input" />
    );

    return (
      <Popover
        minimal
        fill={fullWidth}
        full
        enforceFocus={!filterable}
        isOpen={isOpen}
        position={Position.BOTTOM_LEFT}
        additionalClass={c(Classes.SELECT_POPOVER, styles.popover, additionalClasses.popover, listProps.className)}
        content={
          <div onKeyDown={listProps.handleKeyDown} onKeyUp={listProps.handleKeyUp}>
            {listProps.itemList}
          </div>
        }
        onOpened={handleOpened}
        onInteraction={!isDisabled ? handlePopoverInteraction : undefined}
        {...popoverProps}
      >
        <div onKeyDown={listProps.handleKeyDown} onKeyUp={listProps.handleKeyUp}>
          {inputComponent ? React.cloneElement(inputComponent, { ...inputProps }) : defaultInputComponent}
        </div>
      </Popover>
    );
  };

  return (
    <SelectQueryList
      className={additionalClasses.root ?? additionalClass}
      initialActiveItem={value ?? undefined}
      items={options}
      itemRenderer={optionRenderer}
      itemListRenderer={itemListRenderer}
      onItemSelect={handleSelect}
      onQueryChange={handleQueryChange}
      resetOnQuery
      resetOnSelect
      ref={queryListRef}
      renderer={renderQueryList}
      itemsEqual="value"
      itemPredicate={filterOptions}
      noResults={<MenuItem disabled text={i18n.noResults} />}
      query={query ?? ''}
    />
  );
}
