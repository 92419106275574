import React from 'react';
import c from 'classnames';
import Icon from '@components/common/Icon';
import { useRadioGroup } from './RadioGroupContext';

import styles from './Radio.module.scss';

export type RadioProps = {
  id?: string;
  label?: string;
  name?: string;
  color?: 'blueBase' | 'dark';
  value?: string | boolean;
  checked?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  size?: 's' | 'm' | 'l';
  additionalClass?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const Radio: React.FC<RadioProps> = ({
  id,
  label,
  name,
  checked,
  color = 'blueBase',
  disabled,
  size = 'm',
  additionalClass,
  ...otherProps
}) => {
  const group = useRadioGroup();
  const isChecked = checked ?? (group ? group?.value === otherProps.value : false);
  const isDisabled = disabled ?? (group ? group?.disabled : false);
  const handleChange = otherProps.onChange ?? group?.onChange;
  const inputName = name ?? (group ? group?.name : id);
  const state = isChecked ? 'checked' : 'unchecked';

  return (
    <label
      htmlFor={id}
      className={c(
        styles.radio,
        styles[state],
        styles[color],
        {
          [styles.disabled]: isDisabled,
          [styles.noLabel]: !label,
          [styles.small]: size === 's',
          [styles.medium]: size === 'm',
          [styles.large]: size === 'l',
        },
        additionalClass
      )}
    >
      <span className={styles.container}>
        <input
          id={id}
          name={inputName}
          type="radio"
          checked={isChecked}
          disabled={isDisabled}
          onChange={handleChange}
          {...otherProps}
          value={typeof otherProps.value === 'boolean' ? String(otherProps.value) : otherProps.value}
        />
        <span className={styles.icons}>
          <Icon name="RadioButtonUnselected" additionalClass={c(styles.iconRadioUnchecked)} />
          <Icon name="InternalDotRadioButton" additionalClass={c(styles.iconDot)} />
        </span>
      </span>
      {label}
    </label>
  );
};

export default Radio;
