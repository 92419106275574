import React, { ReactElement, ReactNode, useCallback, useState, useMemo, useEffect } from 'react';
import Popover from '@components/common/Popover';
import { Position } from '@blueprintjs/core';
import CopyText from '@components/typography/CopyText';
import { Checkbox } from '@components/common/form/Checkbox';
import useTextSnippets from '@services/useTextSnippets';
import { ColumnWidths, TableColumns } from '@components/common/Table/types';
import HorizontalSeparator from '@components/separators/HorizontalSeparator';
import Button from '@components/common/Button';
import Icon from '@components/common/Icon';
import Draggable from '@components/common/Draggable/Draggable';
import { getColumnsWidths, getOrderedColumns } from '@components/common/Table/utils';
import c from 'classnames';
import { TextControlUncontrolled } from '@components/common/form/Input/TextControl';
import { debounce, mapValues } from 'lodash';
import { ColumnVisibilities, ColumnsOrder, MIN_CELL_WIDTH, TableColumn } from '@components/common/Table';

import styles from './ColumnToggleAndOrderPopover.module.scss';

type ColumnHidePageProps<T extends object> = {
  columns: TableColumns<T>;
  onClose: () => void;
  isOpen: boolean;
  children: ReactNode;
  onColumnVisibilityToggle?: (colVisibility: ColumnVisibilities) => void;
  onColumnOrderChange?: (colOrder: ColumnsOrder) => void;
  onColumnResize?: (colWidths: ColumnWidths) => void;
};

export default function ColumnToggleAndOrderPopover<T extends object>({
  columns,
  onClose,
  isOpen,
  children,
  onColumnVisibilityToggle,
  onColumnOrderChange,
  onColumnResize,
}: ColumnHidePageProps<T>): ReactElement | null {
  const commonI18n = useTextSnippets('common');
  const [internalColumns, setInternalColumns] = useState<TableColumn<T>[]>(getOrderedColumns(Object.values(columns)));

  const checkedCount = useMemo(
    () => Object.values(columns).reduce((acc, val) => acc + (val.visible ? 0 : 1), 0),
    [columns]
  );

  // if there are any external updates to columns (e.g. column is resized), we need to update internal ref
  useEffect(() => {
    setInternalColumns(getOrderedColumns<T>(Object.values(columns)));
  }, [columns]);

  const handleMoveColumn = useCallback((dragIndex: number, hoverIndex: number) => {
    setInternalColumns(internalColumns => {
      const dragColumn = internalColumns[dragIndex];
      const newInternalColumns = [...internalColumns];

      newInternalColumns.splice(dragIndex, 1);
      newInternalColumns.splice(hoverIndex, 0, dragColumn);
      return newInternalColumns;
    });
  }, []);

  // commit changes and update table
  const handleDragEnd = () => {
    onColumnOrderChange?.(internalColumns.reduce((acc, val, idx) => ({ ...acc, [val.id]: idx }), {}));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleColumnResize = useCallback(
    debounce((colId: keyof TableColumns<T>, newWidth: string) => {
      const colWidths = getColumnsWidths(columns);
      colWidths[colId] = +newWidth < MIN_CELL_WIDTH ? MIN_CELL_WIDTH : +newWidth;
      onColumnResize?.(colWidths);
    }, 500),
    [onColumnResize, columns]
  );

  const handleColumnVisibilityToggle = useCallback(
    (colId: keyof TableColumns<T>, oldVisible: boolean) => {
      onColumnVisibilityToggle?.({
        ...(mapValues(columns, 'visible') as ColumnVisibilities),
        [colId]: !oldVisible,
      });
    },
    [onColumnVisibilityToggle, columns]
  );

  const handleReset = useCallback(() => {
    onColumnOrderChange?.({});
    onColumnVisibilityToggle?.({});
    onColumnResize?.({});
    // after reset it's problematic to update values in the popup (because of unfortunate architecture decisions in the past)
    // so we just close it
    onClose();
  }, [onColumnOrderChange, onColumnVisibilityToggle, onColumnResize, onClose]);

  const onlyOneOptionLeft = checkedCount + 1 >= Object.keys(columns).length;

  const hideColumnsContent = (
    <div className={styles.columnsPopOver}>
      <div className={styles.header}>
        <CopyText variant="copy-3">Columns</CopyText>
        <CopyText variant="copy-3">Size (px)</CopyText>
      </div>

      <div className={styles.list}>
        {internalColumns.map((col, index) => {
          const componentProps = {
            additionalClass: c(styles.columnRow, styles.draggable, index === 0 && styles.first),
            id: col.id,
            key: col.id,
            index,
            accept: 'table-column',
            moveItem: handleMoveColumn,
            onDragEnd: handleDragEnd,
          };

          return (
            <Draggable {...componentProps}>
              <Checkbox
                additionalClass={styles.column}
                disabled={!col || (col.visible && onlyOneOptionLeft)}
                checked={!col || col.visible || col.visible === undefined}
                label={col.label}
                onChange={() =>
                  handleColumnVisibilityToggle(col.id, !col || col.visible === undefined ? true : col.visible)
                }
                size="m"
              />
              <TextControlUncontrolled
                noInfo
                type="number"
                additionalClasses={{ textControl: styles.input }}
                size="m"
                defaultValue={`${col.width ?? ''}`}
                disabled={col.resizable === false}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  handleColumnResize(col.id, e.currentTarget.value)
                }
              />
            </Draggable>
          );
        })}
      </div>

      <HorizontalSeparator noSpacing additionalClass={styles.separator} />

      <Button variant="link" onClick={handleReset}>
        <div className="flex items-center justify-center h-56">
          <Icon name="Reset" size="m" additionalClass="mr-8" />
          <CopyText variant="copy-3">{commonI18n.resetSystemDefault}</CopyText>
        </div>
      </Button>
    </div>
  );

  return internalColumns.length > 1 ? (
    <Popover
      onClose={onClose}
      position={Position.BOTTOM_LEFT}
      content={hideColumnsContent}
      isOpen={isOpen}
      noArrows
      preserveTarget
      additionalClass={styles.popover}
    >
      {children}
    </Popover>
  ) : null;
}
