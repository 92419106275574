import React from 'react';
import { useLoadingContext } from 'react-router-loading';
import PageHeader from '@components/layout/PageHeader';
import { Logbook } from './components/Logbook';
import logbookSlice from '@infrastructure/redux/logbook/slice';
import { lowerCase, startCase } from 'lodash';

export const LogbookPage = () => {
  const loadingContext = useLoadingContext();

  React.useEffect(() => {
    document.title = `Logbook - BaseN Platform`;
  }, []);

  return (
    <div className="page-container">
      <PageHeader
        level={2}
        breadcrumbs
        breadcrumbsProps={{
          omitRootLabel: true,
          transformLabel: (label) => {
            return startCase(lowerCase(label))
          }
        }}
      />

      <div className="page-body">
        <Logbook onInitialLoadComplete={() => loadingContext.done()} actions={logbookSlice.actions} />
      </div>
    </div>
  );
};
