import CopyText from '@components/typography/CopyText';
import { IssueWithDetails } from '@redux/issues';
import React, { FC, useMemo } from 'react';
import c from 'classnames';
import Link from '@components/common/Link';
import ROUTES from '@infrastructure/routes';
import moment from 'moment';
import useTextSnippets from '@services/useTextSnippets';
import { timesAndValuesToStripes } from '@utils/issue';
import { InfoGrid, InfoGridProps } from '@components/layout/InfoGrid/InfoGrid';
import { filterTruthy } from '@utils/misc';
import { IssueDrawerActionBar } from '@components/connected/IssueManager/components/IssueDetailsDrawer';
import { AlertBar } from '@components/connected/IssueManager/components/AlertBar/AlertBar';
import { formatEpochDate } from '@utils/date';

import styles from './IssueInfo.module.scss';

export type IssueInfoProps = {
  hideHeader?: boolean;
  issue: IssueWithDetails;
  additionalClass?: string;
};

export const IssueInfo: FC<IssueInfoProps> = ({ issue, hideHeader, additionalClass }) => {
  const issuesI18n = useTextSnippets('issues');

  const data = useMemo(
    () =>
      issue
        ? filterTruthy([
            issue.entityid && {
              label: 'Entity',
              value: (
                <Link href={ROUTES.entityById.replace(':entityId', issue.entityid)} variant="black">
                  {issue.entity_name}
                </Link>
              ),
            },
            {
              label: 'Group',
              value: issue.group,
            },
            {
              label: 'Created',
              value: formatEpochDate(issue.created),
            },
            {
              label: 'Last Update',
              value: moment(issue.last_update).fromNow(),
            },
            ...(issue.alertid
              ? filterTruthy([
                  {
                    label: 'Alert Status',
                    value: !!issue.alert_data?.greenreds?.length ? (
                      <AlertBar
                        additionalClass={styles.alertBar}
                        cappedWidth
                        stripes={timesAndValuesToStripes(issue.alert_data.times, issue.alert_data.greenreds)}
                      />
                    ) : (
                      <CopyText variant="copy-6" additionalClass="text-gray-3">
                        {issuesI18n.noData}
                      </CopyText>
                    ),
                  },
                  issue.alert_data?.info?.name && {
                    label: 'Alert Name',
                    value: issue.alert_data.info?.name,
                  },
                  {
                    label: 'Alert ID',
                    value: issue.alertid,
                  },
                ])
              : []),
          ])
        : ([] as InfoGridProps['data']),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [issue]
  );

  return (
    <div className={c(styles.issueInfo, additionalClass)}>
      {!hideHeader && (
        <div className={styles.header}>
          <div className="w-full">
            {issue.entity_data && (
              <CopyText variant="copy-6" additionalClass="truncate">
                {issue.entity_data?.name}
              </CopyText>
            )}
            <CopyText variant="copy-1" additionalClass="truncate">
              {issue.name}
            </CopyText>
          </div>
        </div>
      )}

      <IssueDrawerActionBar issue={issue} />

      <InfoGrid data={data} />
    </div>
  );
};
