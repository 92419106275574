/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import c from 'classnames';
import { Tab, TabProps, Tabs } from '@components/common/Tabs';
import useScrollAware from '@services/useScrollAware';

import styles from './TabsPanel.module.scss';

type TabPanelProps = {
  tabProps: TabProps;
  panel: React.ReactNode;
};

export type TabsPanelProps = {
  tabs: TabPanelProps[];
  activeIdx?: number;
  activeTitle?: string;
  additionalClass?: string;
  onChange?: (idx: number, tabProps: TabProps) => void;
};

export const TabsPanel: FC<TabsPanelProps> = ({ tabs, activeIdx = 0, activeTitle, onChange, additionalClass }) => {
  const scrollRef = useScrollAware(styles.isScrolling);

  if (activeTitle) {
    activeIdx = tabs.findIndex(({ tabProps }) => tabProps.title === activeTitle);
  }

  const handleTabChange = (e: React.MouseEvent<HTMLElement>, idx: number) => {
    onChange?.(idx, tabs[idx].tabProps);
  };

  return (
    <div className={c(styles.tabsPanel, additionalClass)}>
      <Tabs additionalClass={c(styles.tabs, 'tabspanel-header')} onChange={handleTabChange}>
        {tabs.map(({ tabProps }: TabPanelProps, idx) => (
          <Tab key={idx} {...tabProps} active={idx === activeIdx} />
        ))}
      </Tabs>
      <div ref={scrollRef} className={c(styles.panel, 'tabspanel-body')}>
        {tabs[activeIdx].panel ? (
          <div className={c(styles.container, 'tabspanel-container')}>{tabs[activeIdx].panel}</div>
        ) : null}
      </div>
    </div>
  );
};
