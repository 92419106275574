import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import useTextSnippets from '@services/useTextSnippets';
import { PaginatedQueryRequest } from './usePaginatedQuery';
import { UseAbortableQuery } from './types';
import React from 'react';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { AlertConfig, useAlertConfigsQuery } from './useAlertConfigsQuery';
import { toIdMap } from '@utils/misc';

export type EntitiesRequest = PaginatedQueryRequest & {
  name?: string; // A java Pattern to match entity names with. Only entities with a name matching the given pattern will be returned by the list command\
  details?: boolean; // Include all entity details
  coordinates?: boolean; // Include entity coordinates
  alerts?: boolean; // Include alert IDs
  parent?: string;
  only_parent?: boolean;
  filter_empty_metrics?: boolean;
  terminal_allowed?: boolean;
  ids?: string;
  [data: string]: any;
};

export type Entity = {
  name: string;
  id: string;
  customer: string; // numeric
  parentid?: string;
};

export type EntitiesResponse<T = Entity> = {
  entities: T[];
  size?: number;
  total: number;
  result: string;
};

export const useEntitiesQuery = <T = EntitiesResponse>(
  searchParams: EntitiesRequest = {},
  { useAbortSignal = true, ...queryOptions }: UseAbortableQuery<T> = {}
): UseQueryResult<T, Error> => {
  const i18n = useTextSnippets('widgetPage');
  const { getFailureHandler } = useGenericMutationHandlers();

  return useQuery<T, Error>(
    ['entities', searchParams],
    ({ signal }): Promise<T> =>
      apiClient.get<T>(
        ENDPOINTS.getEntities,
        {
          // alerts=true makes the query veeeery slow
          // see: https://basencorp.atlassian.net/browse/UX-666
          alerts: false,
          ...searchParams,
        },
        {
          // passing a signal through will force cancel previous query with the same key
          signal: useAbortSignal ? signal : undefined,
        }
      ),
    {
      ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
      onError: getFailureHandler(undefined, i18n.cannotRetrieveEntities),
      ...queryOptions,
    }
  );
};

export type EntityWithAlerts = EntityDetails & {
  alerts?: AlertConfig[];
};

type EntitiesWithAlertsResponse = EntitiesResponse<EntityWithAlerts>;

type UseEntitiesWithAlertsQueryResult = { isLoading: boolean; data?: EntitiesWithAlertsResponse };

export const useEntitiesWithAlertsQuery = <T extends EntitiesWithAlertsResponse = EntitiesWithAlertsResponse>(
  searchParams: EntitiesRequest = {},
  { useAbortSignal = true, ...queryOptions }: UseAbortableQuery<T> = {}
): UseEntitiesWithAlertsQueryResult => {
  const [returnData, setReturnData] = React.useState<UseEntitiesWithAlertsQueryResult>({
    isLoading: false,
    data: undefined,
  });

  const entitiesQuery = useEntitiesQuery<T>(
    {
      alerts: true,
      ...searchParams,
    },
    queryOptions
  );

  const alertIds = entitiesQuery.data?.entities
    ?.reduce((ids, item) => ids.concat(item.alert_ids ?? []), [] as string[])
    .join(',');

  const alertsQuery = useAlertConfigsQuery(
    {
      ids: alertIds,
      silent: true,
      events: true,
      details: true,
      overrides: true,
    },
    {
      enabled: !!alertIds,
    }
  );

  const data = React.useMemo(() => {
    if (entitiesQuery.data && alertsQuery.data) {
      const alertsById: Record<string, AlertConfig> = toIdMap(alertsQuery.data.alerts);

      const entities =
        entitiesQuery.data?.entities.map(item => {
          const alerts = item.alert_ids?.reduce(
            (arr, alertId) => (!alertsById[alertId] ? arr : [...arr, alertsById[alertId]]),
            [] as AlertConfig[]
          );
          return {
            ...item,
            alerts,
          };
        }) ?? [];

      return {
        ...entitiesQuery.data,
        entities,
      };
    } else {
      return entitiesQuery.data;
    }
  }, [alertsQuery.data, entitiesQuery.data]);

  React.useEffect(() => {
    if (entitiesQuery.isLoading || alertsQuery.isLoading) {
      setReturnData({ isLoading: true });
    } else {
      setReturnData({ isLoading: false, data });
    }
  }, [data]);

  return returnData;
};
