import React from 'react';
import { Layout } from 'react-grid-layout';
import { useAppDispatch } from '@infrastructure/redux/store';
import { DashboardWithDetails } from '@redux/dashboards';
import { setDefaultConfig, WidgetType } from '@redux/widgetPage';
import VisualizationShortcutList from '@components/pages/WidgetPage/components/WidgetList';
import useTextSnippets from '@services/useTextSnippets';
import IconButton from '@components/common/IconButton';
import { useDashboardContext } from '@redux/dashboards/context';
import ROUTES from '@infrastructure/routes';
import invariant from '@utils/invariant';
import { useNavigate } from 'react-router-dom';

import styles from './PlaceholderWidget.module.scss';

type Props = { layout?: Layout; dashboard: DashboardWithDetails; onClose?: () => void };

const PlaceholderWidget: React.FC<Props> = ({ dashboard, onClose }) => {
  const dispatch = useAppDispatch();
  const i18n = useTextSnippets('widgetPage');
  const context = useDashboardContext();
  const navigate = useNavigate();

  invariant(context?.dashboard.id, `PlaceholderWidget cannot be used outside of DashboardContext`);

  const handleClick = (id: string) => {
    const [widgetType, extension] = id.split(':');
    dispatch(
      setDefaultConfig({
        type: widgetType as WidgetType,
        subType: extension,
        isDraft: true,
      })
    );
    navigate(`${ROUTES.widget.replace(':dashboardId', dashboard.id).replace(':widgetId', 'create')}`);
    onClose?.();
  };

  return (
    <div className={styles.placeholderWidget}>
      <IconButton icon="Close" additionalClass={styles.closeIcon} onClick={() => onClose?.()} size="xs" />
      <VisualizationShortcutList
        title={i18n.chooseType}
        onItemClick={handleClick}
        shortcutListProps={{ variant: 'minimal' }}
      />
    </div>
  );
};

export default PlaceholderWidget;
