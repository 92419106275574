import { Button, ButtonProps } from '@components/common/Button';
import React, { FC } from 'react';
import { useFormikContext } from 'formik';

export type SubmitButtonProps = Omit<ButtonProps, 'type'> & { disableOnInvalid?: boolean; disableOnClean?: boolean };

export const SubmitButton: FC<SubmitButtonProps> = ({
  disableOnInvalid = true,
  disableOnClean = true,
  disabled,
  ...props
}) => {
  const { isSubmitting, isValidating, isValid, dirty } = useFormikContext();

  return (
    <Button
      {...props}
      type="submit"
      loading={isSubmitting}
      disabled={
        disabled || isSubmitting || isValidating || (disableOnInvalid && !isValid) || (disableOnClean && !dirty)
      }
    />
  );
};
