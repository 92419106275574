import { STORAGE_PREFIX } from '@config';
import { isObject } from 'lodash';
import { isZodSchema } from './type';
import logger from '@infrastructure/logging/logger';
import { mergeAndReplaceArrays } from './misc';

export function storeItem<T = any>(key: string, value: T, shouldMerge = false) {
  const fqdnKey = `${STORAGE_PREFIX}_${key}`;

  if (shouldMerge) {
    const oldValue = retrieveItem<T>(key);
    if (isObject(value) && isObject(oldValue)) {
      const newValue = mergeAndReplaceArrays(oldValue, value);
      window.localStorage.setItem(fqdnKey, JSON.stringify(newValue));
      return;
    }
  }
  window.localStorage.setItem(fqdnKey, JSON.stringify(value));
}

export function retrieveItem<T = string>(key: string): T | null {
  const fqdnKey = `${STORAGE_PREFIX}_${key}`;

  const item = window.localStorage.getItem(fqdnKey);

  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item) as T;
  } catch (ex) {
    return item as unknown as T;
  }
}

export function removeItem(key: string) {
  const fqdnKey = `${STORAGE_PREFIX}_${key}`;
  window.localStorage.removeItem(fqdnKey);
}

export function retrieveValidatedItem<T = object>(key: string, schema: any, removeIfInvalid = true): T | null {
  const item = retrieveItem(key);

  if (!item) {
    return null;
  }

  try {
    return isZodSchema(schema)
      ? schema.parse(item)
      : schema.validateSync(schema.cast(item), {
          abortEarly: true,
          stripUnknown: true,
          strict: false,
        });
  } catch (err: any) {
    if (removeIfInvalid) {
      removeItem(key);
    }

    const message = `Failed to validate ${key}: ${
      isZodSchema(schema) ? `${err.errors[0].message}, at path '${err.errors[0].path.join('.')}'` : err.message
    }${removeIfInvalid ? ', and removed from storage' : ''}`;
    logger.error(message);
    return null;
  }
}
