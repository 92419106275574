import { escapeRegExp, get, isEmpty, isNil, set, trim, trimEnd, trimStart } from 'lodash';
import { Location } from 'react-router-dom';
import { mergeNoEmpty, withoutUndefined } from './misc';
import { BASE_NAME } from '@config';
import { ZodError, ZodSchema } from 'zod';

const flattenObject = (obj: Record<string, any>, prefix: string, shouldEncodeURI = true): string =>
  Object.keys(obj)
    .map(key =>
      shouldEncodeURI
        ? `${encodeURIComponent(prefix)}.${encodeURIComponent(key)}=${encodeURIComponent(obj[key] as string)}`
        : `${prefix}.${key}=${obj[key]}`
    )
    .join('&');

export const toQueryStr = (obj: Record<string, any>, shouldEncodeURI = true): string =>
  Object.keys(obj)
    .reduce((query: string[], key: string): string[] => {
      if (isNil(obj[key])) {
        return query;
      }

      if (Array.isArray(obj[key])) {
        return [
          ...query,
          ...obj[key].map((value: any) =>
            shouldEncodeURI
              ? `${encodeURIComponent(key)}[]=${encodeURIComponent(value as string)}`
              : `${key}[]=${value}`
          ),
        ];
      }

      if (typeof obj[key] === 'object') {
        return [...query, flattenObject(obj[key], key, shouldEncodeURI)];
      }

      return [
        ...query,
        shouldEncodeURI ? `${encodeURIComponent(key)}=${encodeURIComponent(obj[key] as string)}` : `${key}=${obj[key]}`,
      ];
    }, [])
    .join('&');

const parseValue = (value: any) => {
  if (value === 'null') {
    return null;
  } else if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  } else if (!isNaN(Number(value)) && value !== '') {
    return Number(value);
  } else {
    return decodeURIComponent(value);
  }
};

export const queryResponseValidator =
  <T>(schema: ZodSchema) =>
  (response: any): T => {
    try {
      return schema.parse(response);
    } catch (err: any) {
      if (err instanceof ZodError) {
        const message = `${err.errors[0].message}, at path '${err.errors[0].path.join('.')}'`;
        throw new Error(message);
      }
      throw err;
    }
  };

export const fromQueryStr = <T = any>(queryString: string, shouldDecodeURI: boolean = true, schema?: any) => {
  const result = queryString.split('&').reduce((obj, pair) => {
    if (!pair) {
      return obj;
    }

    const [key, value] = pair.split('=').map(item => (shouldDecodeURI ? decodeURIComponent(item) : item));

    const parsedValue = parseValue(value);

    if (key.endsWith('[]')) {
      const arrayKey = key.slice(0, -2);
      const existingValue = get(obj as any, arrayKey, []);
      set(obj as any, arrayKey, [...existingValue, parsedValue]);
      return obj;
    }

    set(obj as any, key, parsedValue);
    return obj;
  }, {} as T);

  return schema ? queryResponseValidator<T>(schema)(result) : result;
};

export const prepareMVTUrl = (url: string, query: any = {}, baseUrl = '/') => {
  let preparedUrl = url;
  if (!url.startsWith(baseUrl)) {
    preparedUrl = `${trimEnd(baseUrl, '/')}${preparedUrl}`;
  }

  if (!new RegExp(`${escapeRegExp('/{z}/{x}/{y}')}$`).test(preparedUrl)) {
    preparedUrl = `${trimEnd(preparedUrl, '/')}/{z}/{x}/{y}`;
  }

  return isEmpty(query) ? preparedUrl : `${preparedUrl}?${toQueryStr(withoutUndefined(query, true))}`;
};

export function flattenPaths(params: Record<string, any>, prefix: string[] = []): Record<string, any> {
  return Object.keys(params).reduce((flattenParams, key) => {
    const value = params[key];
    const newPrefix = [...prefix, key];

    if (typeof value === 'object' && !isEmpty(value) && !Array.isArray(value)) {
      return { ...flattenParams, ...flattenPaths(value, newPrefix) };
    }

    return { ...flattenParams, [newPrefix.join('.')]: value };
  }, {} as Record<string, any>);
}

export function replacePlaceholders(
  str: string,
  values: Record<string, string | number | boolean | null | undefined>,
  placeholderRe = /:([a-z][\w]+)/gi
): [newStr: string, newValues: Record<string, string | number | boolean | null | undefined>] {
  // auto-replace :placeholder with a matching value or ignore
  const newValues = { ...values };

  const newStr = str?.replace(placeholderRe, ($0, $1: string) => {
    if (newValues[$1]) {
      const value = newValues[$1];

      delete newValues[$1];

      return value as string;
    }

    return $0;
  });

  return [newStr, newValues];
}

export function getURLQueryParams(url?: string | URL | Location): Record<string, any> {
  const location = typeof url === 'string' ? new URL(url) : url ?? window.location;
  const queryString = fromQueryStr(trimStart(location.search, '?'), false);
  return queryString;
}

export const replaceQuery = (partialQuery: Record<string, any>, url?: string): URL => {
  const location = new URL(url ?? window.location.href);
  const merged = mergeNoEmpty(getURLQueryParams(location), partialQuery);
  const params = withoutUndefined(flattenPaths(merged), true);

  location.search = toQueryStr(params, false);

  return location;
};

export const urlToLocation = ({ pathname, hash, search }: URL): Partial<Location> => ({
  pathname,
  hash,
  search,
});

export const routeToSiteUrl = (route: string) =>
  [window.location.origin, BASE_NAME, route]
    .filter(Boolean)
    .map((value: any) => trim(value, '/'))
    .join('/');
