import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { SearchParamType } from '@infrastructure/api/BaseNClient/types';
import invariant from '@utils/invariant';
import { baseHeaders, API_URL } from '@config';
import { replacePlaceholders } from '@utils/url';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TooManyRequests = 429,
  InternalServerError = 500,
}

class ApiClient {
  private instance: AxiosInstance | null = null;

  private authToken: string | null = null;

  private get apiClient(): AxiosInstance {
    return this.instance != null ? this.instance : this.initClient();
  }

  private get token(): string | null {
    return this.authToken;
  }

  private set token(newToken: string | null) {
    this.authToken = newToken;
  }

  initClient() {
    const client = axios.create({
      baseURL: API_URL,
      headers: { ...baseHeaders, Authorization: this.token ? `Bearer ${this.token}` : '' },
    });

    client.interceptors.response.use(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      (response: AxiosResponse) => (response && response.data) || response,
      this.handleError
    );

    this.instance = client;

    return client;
  }

  setToken(newToken: string | null, refresh: boolean = false) {
    if (!this.token || (this.token && refresh)) {
      this.token = newToken;

      this.initClient();
    }
  }

  request<T = any, R = T>({ url: configUrl, params: configParams, ...config }: AxiosRequestConfig): Promise<R> {
    invariant(configUrl, `apiClient.request: url is required`);

    const [url, params] = replacePlaceholders(configUrl, configParams);

    return this.apiClient.request<T, R>({
      ...config,
      [['POST', 'PUT', 'PATCH'].includes(config.method as string) ? 'data' : 'params']: params,
      url,
    });
  }

  get<R, P = SearchParamType>(url: string, params?: P, config?: AxiosRequestConfig): Promise<R> {
    return this.request<R>({
      ...config,
      method: 'GET',
      url,
      params,
    });
  }

  post<R, P = SearchParamType>(url: string, params?: P, config?: AxiosRequestConfig): Promise<R> {
    return this.request<R>({
      ...config,
      method: 'POST',
      url,
      params,
    });
  }

  patch<R, P = SearchParamType>(url: string, params?: P, config?: AxiosRequestConfig): Promise<R> {
    return this.request<R>({
      ...config,
      method: 'PATCH',
      url,
      params,
    });
  }

  put<R, P = SearchParamType>(url: string, params?: P, config?: AxiosRequestConfig): Promise<R> {
    return this.request<R>({
      ...config,
      method: 'PUT',
      url,
      params,
    });
  }

  delete<R, P = SearchParamType>(url: string, params?: P, config?: AxiosRequestConfig): Promise<R> {
    return this.request<R>({
      ...config,
      method: 'DELETE',
      url,
      params,
    });
  }

  // we will end up using this. here almost certainly, disable for now
  // eslint-disable-next-line class-methods-use-this
  private handleError(error: AxiosError) {
    const { response } = error;

    // TODO: perhaps handle the errors somehow - logout on unauthorized requests for example
    switch (response?.status as StatusCode) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.NotFound: {
        // Handle NotFound
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle NotFound
        break;
      }
      default: {
        // Handle Generic Errors
        break;
      }
    }

    return Promise.reject(error);
  }
}

export const apiClient = new ApiClient();
