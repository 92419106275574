import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationState, NotificationType } from './types';
import { uniqueId } from 'lodash';

const initialState: NotificationState = {
  items: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification(state, { payload }: PayloadAction<NotificationType>) {
      state.items.push({ delay: 5000, ...payload, id: uniqueId('note-') });
    },

    dismissNotification(state, { payload }: PayloadAction<NotificationType>) {
      state.items = state.items.filter(notification => notification.id !== payload.id);
    },
  },
});

export const { setNotification, dismissNotification } = notificationsSlice.actions;

export default notificationsSlice;

export * from './selectors';
export * from './types';
