import React, { ReactElement } from 'react';
import { StoredTableLayout, useUpdateStoredConfigMutation } from '@infrastructure/api/BaseNClient/useTableViewQuery';
import { FilterableTableProps } from './FilterableTable';
import { StoredTable } from './StoredTable';
import { useQueryClient } from 'react-query';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';
import { mergeWithUndefined } from '@utils/misc';
import { getConfigFromURL } from '@utils/redux';

export type RemoteStoredTableProps<T extends object> = FilterableTableProps<T> & {
  name: string;
};

export function RemoteStoredTable<T extends object>({
  name,
  ...props
}: RemoteStoredTableProps<T>): ReactElement | null {
  const tableSettingsId = `table-settings-${name}`;

  const queryClient = useQueryClient();

  const updateStoredConfigMutation = useUpdateStoredConfigMutation(tableSettingsId);

  const retrieveLayout = React.useCallback(
    async (defaultLayout: StoredTableLayout): Promise<StoredTableLayout> => {
      const params = { key: tableSettingsId };
      return queryClient.fetchQuery([ENDPOINTS.settingsByKey, params], () =>
        apiClient.get<StoredTableLayout>(ENDPOINTS.settingsByKey, params).then(remoteLayout =>
          // remotely stored layout from BE is merged on top of default layout and then a config from query string, which
          // has the highest precedence is merged on top of that
          mergeWithUndefined(defaultLayout, remoteLayout, {
            config: getConfigFromURL(),
          })
        )
      );
    },
    [queryClient, tableSettingsId]
  );

  const storeLayout = React.useCallback(
    async (layout: StoredTableLayout) => {
      await updateStoredConfigMutation.mutateAsync(layout);
    },
    [updateStoredConfigMutation]
  );

  return <StoredTable<T> {...props} storeLayout={storeLayout} retrieveLayout={retrieveLayout} />;
}
