import { Badge } from '@components/common/Badge';
import Icon from '@components/common/Icon';
import { TableColumns } from '@components/common/Table';
import { FieldConfig, FieldsConfig } from '@components/common/form/FormBuilder';
import { Metric, MetricImportance } from '@infrastructure/api/BaseNClient/useMetricsQuery';
import { Filters } from '@infrastructure/redux/types';
import { toArray, toIdMap } from '@utils/misc';
import { MetricsTagInputField, MetricsTagInputFieldProps } from './MetricsTagInputField';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import Link from '@components/common/Link';
import ROUTES from '@infrastructure/routes';
import CopyText from '@components/typography/CopyText';
import { memoize } from 'lodash';
import { MetricPlot } from './MetricPlot';
import { Widget } from '@components/layout/Widget';
import { DateRange } from '@blueprintjs/datetime';
import { getEntityProperty } from '@utils/entity';

import styles from './MetricsTable.module.scss';

const importanceToBadgeVariant = (importance: MetricImportance) => {
  switch (importance) {
    case 'HERO':
      return 'warning';
    case 'NORMAL':
      return 'success';
    case 'LOW':
      return 'info';
    case 'NONE':
    default:
      return 'default';
  }
};

// export const getMetric = (entity: EntityDetails, metricsetName: string, metricId: string[]): Metric | null => {
//   const metricset = entity.metricsets?.find(({ name }: Metricset) => name === metricsetName);

//   if (metricset) {
//     return metricset.metrics?.find(({ id }: Metric) => metricId.includes(id)) ?? null;
//   }

//   return null;
// };

const metricRetriever = (entity: EntityDetails, metricId: string | string[]): Metric | null => {
  metricId = toArray(metricId);

  for (const { metrics } of entity.metricsets) {
    for (const metric of metrics) {
      if (metricId.includes(metric.id)) {
        return metric;
      }
    }
  }
  return null;
};

export const getMetric = memoize(
  metricRetriever,
  (entity: EntityDetails, metricId: string | string[]) => `${entity.id}-${toArray(metricId).join(',')}`
); // memoize to avoid repeating drill downs for matching metric

export const getTableListColumns = (filters: Filters, dateRange: DateRange): TableColumns<EntityDetails> => {
  const metricIds = Array.isArray(filters.discreteFilters?.metricId)
    ? filters.discreteFilters.metricId
    : filters.discreteFilters?.metricId
      ? [filters.discreteFilters.metricId]
      : [];

  const metricColumns = metricIds?.map((metricId: string) => ({
    id: metricId,
    label: 'plot',
    width: 300,
    resizable: false,
    valueRenderer: (item: EntityDetails) => {
      const metric = getMetric(item, metricId);

      return metric ? (
        <div className="p-6">
          <div className={styles.chartContainer}>
            <Widget
              type="timeseries"
              config={{
                type: 'line',
                hasToolbar: false,
                showAlerts: false,
                horizontalLegend: true,
                showWatermark: false,
                showDateRange: false,
                source: { entityId: item.id, metricId },
                dateRange,
              }}
              additionalClass={styles.chart}
            />
          </div>
        </div>
      ) : null;
    },
  }));

  return toIdMap([
    {
      id: 'name',
      label: 'Name',
      sortable: true,
      valueRenderer: (item: EntityDetails) => (
        <div className="flex flex-row items-center flex-1 truncate text-dark">
          <Link
            href={`${ROUTES.entityById.replace(':entityId', item.id)}`}
            testid={`Entity::${item.name}`}
            additionalClass={styles.link}
          >
            <CopyText variant="copy-4" additionalClass="truncate">
              {item.parent_name}
            </CopyText>
          </Link>
        </div>
      ),
    },
    {
      id: 'importance',
      label: 'Importance',
      width: 100,
      tyoe: 'custom',
      valueRenderer: (item: EntityDetails) => {
        const metric = getMetric(item, filters.discreteFilters.metricId);

        return metric ? (
          <Badge size="s" variant={importanceToBadgeVariant(metric.importance)}>
            {metric.importance}
          </Badge>
        ) : null;
      },
    },
    {
      id: 'metricsets',
      label: 'Metricsets',
      type: 'custom',
      width: 150,
      valueRenderer: (item: EntityDetails) => {
        const metric = getMetric(item, filters.discreteFilters.metricId);

        return metric ? (
          <div className="flex flex-wrap gap-4 py-4">
            {metric.metricsets.map(metricset => (
              <Badge key={metricset}>{metricset}</Badge>
            ))}
          </div>
        ) : null;
      },
    },
    ...metricColumns,
    {
      id: 'group',
      label: 'Metric Group',
      valueRenderer: (item: EntityDetails) => {
        const metric = getMetric(item, filters.discreteFilters.metricId);
        return metric?.group;
      },
    },
    {
      id: 'type',
      label: 'Type',
      valueRenderer: (item: EntityDetails) => {
        const metric = getMetric(item, filters.discreteFilters.metricId);
        return metric?.type;
      },
    },
    {
      id: 'global',
      label: 'Global',
      width: 50,
      valueRenderer: (item: EntityDetails) => {
        const metric = getMetric(item, filters.discreteFilters.metricId);
        return metric?.global ? <Icon name="InternalCheckmark" additionalClass="text-green-base" /> : null;
      },
    },
    {
      id: 'vendor',
      label: 'Vendor',
      valueRenderer: (item: EntityDetails) => {
        const metric = getMetric(item, filters.discreteFilters.metricId);
        return metric?.vendor;
      },
    },
    {
      id: 'source',
      label: 'Source',
      valueRenderer: (item: EntityDetails) => {
        const metric = getMetric(item, filters.discreteFilters.metricId);
        return metric?.source;
      },
    },
  ]);
};

export const getTableGridColumns = (filters: Filters, dateRange: DateRange): TableColumns<EntityDetails> => {
  const metricIds = Array.isArray(filters.discreteFilters?.metricId)
    ? filters.discreteFilters.metricId
    : filters.discreteFilters?.metricId
      ? [filters.discreteFilters.metricId]
      : [];

  const metricColumns = metricIds?.map((metricId: string) => ({
    id: metricId,
    label: metricId,
    type: 'custom',
    width: 300,
    resizable: false,
    valueRenderer: (item: EntityDetails) => {
      const metric = getMetric(item, metricId);

      return metric ? (
        <div className="p-6">
          <div className={styles.chartContainer}>
            <Widget
              type="timeseries"
              config={{
                type: 'line',
                hasToolbar: false,
                showAlerts: false,
                horizontalLegend: true,
                showWatermark: false,
                showDateRange: false,
                source: { entityId: item.id, metricId },
                dateRange,
              }}
              additionalClass={styles.chart}
            />
          </div>
        </div>
      ) : null;
    },
  }));

  return toIdMap([
    {
      id: 'name',
      label: 'Name',
      sortable: true,
      width: 220,
      type: 'custom',
      valueRenderer: (item: EntityDetails) => {
        const description = getEntityProperty(item, 'description');
        return (
          <div className="flex flex-col justify-start flex-1 h-full py-16 truncate items-left text-dark">
            <Link
              href={`${ROUTES.entityById.replace(':entityId', item.id)}`}
              testid={`Entity::${item.parent_name}`}
              additionalClass={styles.link}
              block
            >
              <CopyText variant="copy-4" additionalClass="truncate block">
                {item.parent_name}
              </CopyText>
            </Link>
            {description && (
              <CopyText variant="copy-6" additionalClass="text-blue-gray mt-8">
                {description}
              </CopyText>
            )}
          </div>
        );
      },
    },
    ...metricColumns,
  ]) as TableColumns<EntityDetails>;
};

export const getFiltersConfig = (filters?: Filters): FieldsConfig => {
  return [
    // {
    //   type: 'custom',
    //   props: {
    //     name: 'metricset',
    //     label: 'Metricset',
    //     placeholder: 'Pick a Metricset...',
    //     fullWidth: true,
    //     defaultValue: filters?.discreteFilters?.metricset,
    //   },
    //   renderer: (config: FieldConfig) => <MetricsetPickerField {...(config.props as MetricsetPickerFieldProps)} />,
    // },
    {
      type: 'custom',
      props: {
        name: 'metricId',
        label: 'Metrics',
        fullWidth: true,
        placeholder: 'Pick one or more Metrics...',
        // placeholder: !filters?.discreteFilters?.metricset
        //   ? 'Metricset required to enable this field...'
        //   : 'Pick one or more Metrics...',
        // metricset: filters?.discreteFilters?.metricset,
        // disabled: !filters?.discreteFilters?.metricset,
        defaultValue: toArray(filters?.discreteFilters?.metricId),
      },
      renderer: (config: FieldConfig) => <MetricsTagInputField {...(config.props as MetricsTagInputFieldProps)} />,
    },
    {
      type: 'text',
      props: {
        label: 'Vendor',
        name: 'vendor',
        placeholder: 'Type in a Vendor name...',
        inline: false,
        defaultValue: filters?.discreteFilters?.vendor,
      },
    },
    {
      type: 'text',
      props: {
        label: 'Group',
        name: 'group',
        placeholder: 'Type in a Group name...',
        inline: false,
        defaultValue: filters?.discreteFilters?.group,
      },
    },
  ] as FieldsConfig;
};
