import React, { Children, cloneElement, PropsWithChildren, ReactElement, ReactNode } from 'react';
import c from 'classnames';
import Icon from '@components/common/Icon';
import Tooltip from '@components/common/Tooltip';
import { isDomElement } from '@utils/dom';

import styles from './Control.module.scss';

export type ControlChildProps<Value = string, OnChange = (e: React.ChangeEvent<HTMLInputElement>) => void> = {
  id?: string;
  name?: string;
  value?: Value;
  defaultValue?: Value;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: OnChange;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent) => void;
  size?: 'm' | 'l';
  readOnly?: boolean;
  additionalClasses?: {
    root?: string;
    input?: string;
    body?: string;
  };
};

export type ControlProps<
  Value = string,
  OnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
> = ControlChildProps<Value, OnChange> & {
  label?: string | ReactNode;
  errorText?: string;
  variant?: 'outlined' | 'stealth';
  additionalClasses?: {
    control?: string;
    child?: string;
    info?: string;
  };
  noInfo?: boolean;
  inline?: boolean;
  required?: boolean;
  helperText?: string;
};

function Control<Value = string, OnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void>({
  id,
  name,
  disabled,
  label,
  noInfo = false,
  inline = false,
  required = false,
  errorText = '',
  helperText,
  variant = 'outlined',
  additionalClasses = {},
  children,
  ...props
}: PropsWithChildren<ControlProps<Value, OnChange>>) {
  const hasError = !!errorText;

  const childProps: any = {
    id: id ?? name,
    name,
    disabled,
    ...props,
  };

  if (!isDomElement(children)) {
    childProps.additionalClasses = {
      ...additionalClasses,
      root: c(additionalClasses.child, additionalClasses.root),
      input: c(additionalClasses.input, styles.focusable),
    };
  }

  return (
    <div
      className={c(
        styles.control,
        styles[variant],
        hasError && styles.hasError,
        disabled && styles.disabled,
        childProps.readOnly && styles.readOnly,
        inline && styles.inline,
        additionalClasses.control
      )}
    >
      {label && (
        <label htmlFor={id ?? name}>
          {label}
          {required ? <span className="text-red-base"> *</span> : null}
          {helperText && (
            <Tooltip content={helperText}>
              <Icon name="Help" additionalClass="ml-6" size="s" />
            </Tooltip>
          )}
        </label>
      )}
      <div className={c(styles.body, additionalClasses.body)}>
        {cloneElement(Children.only(children) as ReactElement, childProps)}

        {!noInfo && (
          <div className={c(styles.info, additionalClasses.info)}>
            {errorText && <div className={styles.error}>{errorText}</div>}
          </div>
        )}
      </div>
    </div>
  );
}

export default Control;
