import React, { ReactNode, useState } from 'react';
import Drawer from '@components/common/Drawer';
import { useBreakpoint } from '@services';

type Props = {
  children: ReactNode;
};

const MobileDrawerMenu: React.FC<Props> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(true);
  const {
    breakpoints: { isTablet },
  } = useBreakpoint();

  if (isTablet) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <Drawer isOpen={mobileOpen} size="auto" onClose={() => setMobileOpen(false)}>
      {children}
    </Drawer>
  );
};

export default MobileDrawerMenu;
