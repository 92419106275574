import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@infrastructure/redux/store';
import { nodeFromPath } from '@components/common/Tree';
import { PanelId, SidebarState, Favorite } from './types';
import { favoritesToTreeContents } from '@utils/favorite';

const initialState: SidebarState = {
  panels: {
    favorites: {
      isExpanded: true,
      contents: [],
    },
    shared: {
      isExpanded: true,
      contents: [
        {
          id: 0,
          label: 'Server Team',
          hasCaret: true,
          childNodes: [
            {
              id: 1,
              label: 'Dashboard Name 01',
              icon: 'Dashboard',
            },
          ],
        },
      ],
    },
  },
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    populateFavoritesPanel(state, { payload }: PayloadAction<Favorite[]>) {
      state.panels.favorites.contents = favoritesToTreeContents(payload);
    },

    togglePanel(state, { payload: { id, isExpanded } }: PayloadAction<{ id: PanelId; isExpanded: boolean }>) {
      state.panels[id].isExpanded = isExpanded;
    },

    toggleNode(
      state,
      { payload: { id, nodePath, isExpanded } }: PayloadAction<{ id: PanelId; nodePath: number[]; isExpanded: boolean }>
    ) {
      const node = nodeFromPath(nodePath, state.panels[id].contents);
      node.isExpanded = isExpanded;
    },

    reset() {
      return initialState;
    },
  },
});

export const selectPanels = (state: AppState) => state.sidebar.panels;

export const { togglePanel, toggleNode, reset, populateFavoritesPanel } = sidebarSlice.actions;
export default sidebarSlice;

export * from './types';
