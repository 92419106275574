import React from 'react';
import { AlertConfig } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';
import c from 'classnames';
import moment from 'moment';

import styles from './DetailsTab.module.scss';

type DetailsTabProps = {
  alert: AlertConfig;
};

export const DetailsTab = ({ alert }: DetailsTabProps) => {
  const config = [
    {
      title: 'Alert Status',
      details: {
        'Alert type': alert.active ? 'active' : 'silent',
      },
    },
    {
      title: 'Rules',
      details: {
        'Green (s)': alert.greens?.join(', ') ?? 'None',
        'Red (s)': alert.reds?.join(', ') ?? 'None',
        'Red count': alert.reds?.length ?? 0,
        'No data': alert.nodataStrategy,
      },
    },
    {
      title: 'Time',
      details: {
        Time: `${moment.duration(alert.alertTime ?? 0, 'seconds').asMinutes()} min`,
        'Time closing': `${moment.duration(alert.alertTimeClosing ?? 0, 'seconds').asMinutes()} min`,
        'Time nodata': `${moment.duration(alert.alertTimeNodata ?? 0, 'seconds').asMinutes()} min`,
        'Extra time': `${moment.duration(alert.alertTimeExtra ?? 0, 'seconds').asHours()} h`,
      },
    },
    {
      title: 'Misc',
      details: {
        ATL: alert.atlName,
        Category: alert.category,
      },
    },
  ];

  return (
    <div className={styles.detailsTab}>
      {config.map(item => (
        <div key={item.title} className={styles.column}>
          <div className={styles.title}>{item.title}</div>
          <div className={c(styles.details, styles.infoGrid)}>
            {Object.entries(item.details).map(([label, value]) => (
              <React.Fragment key={label}>
                <div className={styles.label}>{label}</div>
                <div className={styles.value} title={typeof value === 'string' ? value : undefined}>
                  {value}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}

      {!!alert.groups?.length && (
        <div className={styles.column}>
          <div className={styles.title}>Groups</div>
          <div className={styles.details}>
            {alert.groups.map(group => (
              <div key={group} className={styles.value} title={group}>
                {group}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
