import c from 'classnames';
import { capitalize } from 'lodash';

import styles from './DiffStat.module.scss';

type DiffStatProps = {
  variant: 'added' | 'removed' | 'modified';
  amount: number;
};

export const DiffStat = ({ variant, amount }: DiffStatProps) => {
  return (
    <div className={styles.diffStat}>
      <div className={c(styles.icon, styles[variant])} />
      <div className={styles.label}>{capitalize(variant)}</div>
      <div className={styles.amount}>{amount}</div>
    </div>
  );
};
