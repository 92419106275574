/* eslint-disable react/button-has-type */
import React, { forwardRef, MouseEventHandler, PropsWithChildren } from 'react';
import c from 'classnames';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import Icon, { IconNames } from '../Icon';
import Tooltip, { TooltipProps } from '../Tooltip';
import { Position } from '@blueprintjs/core';

import styles from './IconButton.module.scss';

export type IconButtonVariant =
  | 'icon'
  | 'fill'
  | 'fillBlueLight'
  | 'fillBlueDark'
  | 'fillOutline'
  | 'outline'
  | 'stealth';

export type IconButtonProps = {
  ariaLabel?: string;
  icon: IconNames;
  variant?: IconButtonVariant;
  round?: boolean;
  size?: 'xs' | 's' | 'r' | 'm' | 'l';
  onClick?: MouseEventHandler;
  disabled?: boolean;
  keepInteractive?: boolean; // disabled attribute on the <button> tag disables all interactions
  loading?: boolean;
  id?: string;
  tabIndex?: number;
  title?: string;
  additionalClass?: string;
  testid?: string;
  tooltipProps?: Partial<TooltipProps>;
};

const IconButton = forwardRef<HTMLButtonElement, PropsWithChildren<IconButtonProps>>(function iconButton(
  {
    icon,
    size = 's',
    variant = 'icon',
    round = true,
    disabled = false,
    keepInteractive = false,
    loading = false,
    title,
    ariaLabel,
    additionalClass,
    onClick,
    testid,
    tooltipProps = { position: Position.TOP },
    ...otherProps
  },
  ref
) {
  const dataTestId = testid ?? ariaLabel ?? icon;

  const button = (
    <button
      className={c(
        styles.iconButton,
        styles[variant],
        {
          [styles.round]: round,
          [styles.disabled]: disabled,
          [styles.xs]: size === 'xs',
          [styles.small]: size === 's',
          [styles.regular]: size === 'r',
          [styles.medium]: size === 'm',
          [styles.large]: size === 'l',
        },
        additionalClass
      )}
      disabled={!keepInteractive && (disabled || loading)}
      type="button"
      onClick={disabled ? undefined : onClick}
      ref={ref}
      data-testid={dataTestId ? dataTestId : undefined}
      aria-label={ariaLabel ?? icon}
      {...otherProps}
    >
      {loading ? <LoadingSpinner additionalClass={styles.loadingSpinner} /> : null}
      <Icon name={icon} additionalClass={c({ [styles.loadingIcon]: loading })} />
    </button>
  );

  return title ? (
    <Tooltip additionalClass={styles.tooltip} {...tooltipProps} content={title}>
      {button}
    </Tooltip>
  ) : (
    button
  );
});

export default IconButton;
