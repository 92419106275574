import React, { useMemo } from 'react';
import { Form, FormProps } from '@components/common/form';
import { FormikValues } from 'formik';
import { FieldsConfig, FormBuilderProps } from './types';
import { FormBody } from './FormBody';
import { isEmpty } from 'lodash';

export function extractValues<Schema extends FormikValues = FormikValues>(fields: FieldsConfig): Schema {
  return fields.reduce((values, field) => {
    if (field.type === 'group') {
      return { ...values, ...extractValues(field.fields) };
    } else {
      // @ts-ignore TODO: make sure that defaultValue is a prop on every field
      return {
        ...values,
        [field.props.name]: field.type === 'toggle' ? !!field.props.checked : field.props.defaultValue,
      };
    }
  }, {} as Schema);
}

export function updateValues<Schema extends FormikValues = FormikValues>(
  fieldsConfig: FieldsConfig,
  partialValues: Schema
): FieldsConfig {
  return partialValues && !isEmpty(partialValues)
    ? (fieldsConfig.map(field => {
        if (field.type === 'group') {
          return {
            ...field,
            fields: updateValues(field.fields, partialValues),
          };
        } else if (field.type === 'toggle') {
          return {
            ...field,
            props: {
              ...field.props,
              checked: partialValues[field.props.name],
            },
          };
        } else {
          return {
            ...field,
            props: {
              ...field.props,
              defaultValue: partialValues[field.props.name],
            },
          };
        }
      }) as FieldsConfig)
    : fieldsConfig;
}

export function FormBuilder<Schema extends FormikValues = FormikValues>({
  fields,
  initialValues,
  children,
  isSubmitting,
  enableReinitialize = true,
  ...formProps
}: FormBuilderProps<Schema>) {
  const generatedInitialValues: Schema = useMemo(
    () => (initialValues ? initialValues : extractValues(fields)),
    [initialValues, fields]
  );

  return (
    <Form<Schema> initialValues={generatedInitialValues} enableReinitialize={enableReinitialize} {...formProps}>
      <FormBody<Schema> fields={fields} isSubmitting={isSubmitting} />
      {children}
    </Form>
  );
}
