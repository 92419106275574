import englishTextSnippets from '@infrastructure/translations/languages/english';
import { ITextSnippets } from '@services/useTextSnippets';

function getI18N<T extends keyof ITextSnippets>(key: T): ITextSnippets[T];
function getI18N(): ITextSnippets;

function getI18N<T extends keyof ITextSnippets>(key: T | undefined = undefined) {
  const data = englishTextSnippets;
  const returnValue = key ? data[key] : data;

  return returnValue;
}

export default getI18N;
