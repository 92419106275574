import * as PopperJS from '@popperjs/core';
import React, { FC, useState, useRef, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { Overlay, Classes } from '@blueprintjs/core';
import c from 'classnames';

import styles from './DetachedPopover.module.scss';

export type DetachedPopoverProps = {
  isOpen?: boolean;
  target?: Element | PopperJS.VirtualElement | DOMRect | null;
  hasArrow?: boolean;
  usePortal?: boolean;
  additionalClass?: string;
  onClose?: () => void;
};

const normalizeTarget = (target?: Element | PopperJS.VirtualElement | DOMRect | null) =>
  target instanceof DOMRect ? { getBoundingClientRect: () => target } : target;

export const DetachedPopover: FC<DetachedPopoverProps> = ({
  isOpen,
  hasArrow = true,
  usePortal = true,
  target,
  additionalClass,
  onClose,
  children,
}) => {
  const targetRef = useRef(normalizeTarget(target));
  const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);
  const { styles: popoverStyles, attributes: popoverAttrs } = usePopper(targetRef.current, popperEl);

  useEffect(() => {
    targetRef.current = normalizeTarget(target);
  }, [target]);

  return (
    <Overlay
      isOpen={isOpen}
      canEscapeKeyClose
      canOutsideClickClose
      hasBackdrop={false}
      autoFocus
      shouldReturnFocusOnClose
      usePortal={usePortal}
      className={c(Classes.OVERLAY_SCROLL_CONTAINER, styles.overlay)}
      onClose={onClose}
    >
      <div
        className={c(styles.detachedPopover, additionalClass)}
        ref={setPopperEl}
        style={popoverStyles.popper}
        {...popoverAttrs.popper}
      >
        {children}
        {hasArrow && <div data-popper-arrow className={styles.arrow} style={popoverStyles.arrow} />}
      </div>
    </Overlay>
  );
};
