import Control, { ControlProps } from '@components/common/form/Control/Control';
import React, { useMemo } from 'react';
import { InfiniteTagInputLegacy, InfiniteTagInputLegacyProps } from './InfiniteTagInputLegacy';

export type InfiniteTagInputControlLegacyProps<TValue = any> = Omit<
  ControlProps,
  'onChange' | 'value' | 'defaultValue'
> &
  InfiniteTagInputLegacyProps<TValue> & {
    filterable?: boolean;
  };

export function InfiniteTagInputControlLegacy<TValue = any>({
  placeholder,
  getItemsQuery,
  itemToLabel,
  ...props
}: InfiniteTagInputControlLegacyProps<TValue>) {
  const childProps = useMemo(
    () => ({
      placeholder,
      getItemsQuery,
      itemToLabel,
      disabled: props.disabled,
    }),
    [placeholder, getItemsQuery, itemToLabel, props.disabled]
  );

  return (
    <Control {...props}>
      <InfiniteTagInputLegacy<TValue> {...childProps} />
    </Control>
  );
}
