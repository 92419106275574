import Card from '@components/common/Card/Card';
import Icon from '@components/common/Icon';
import CopyText from '@components/typography/CopyText';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { formatEpochDate } from '@utils/date';
import { useLogbookQuery } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import React from 'react';
import { useQueryClient } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { useAppDispatch } from '@infrastructure/redux/store';
import { setNotification } from '@infrastructure/redux/notifications';
import { BackupStatus } from '@components/pages/ConfigDiffPage/components/BackupStatus';
import { CooldownButton } from '@components/common/CooldownButton';

import styles from './StatusBar.module.scss';

export type StatusBarProps = {
  entityId: string;
};

export const StatusBar = ({ entityId }: StatusBarProps) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const changesQuery = useLogbookQuery({
    entity_ids: entityId,
    reasons: 'Configuration change',
    start_index: 0,
    end_index: 1,
  });

  const handleBackupClick = React.useCallback(() => {
    setIsLoading(true);
    queryClient
      .fetchQuery(['backup'], () =>
        apiClient.get<{ result: string }>(ENDPOINTS.queueConfigForBackup.replace(':entityId', entityId))
      )
      .then(({ result }) => {
        if (result.toLocaleLowerCase() === 'ok') {
          dispatch(setNotification({ type: 'success', title: `Device has been queued for backup` }));
        } else {
          dispatch(setNotification({ type: 'error', title: `Error: ${result}` }));
        }
      })
      .catch((err: any) => {
        dispatch(setNotification({ type: 'error', title: `Error: ${err.message}` }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, entityId, queryClient]);

  const renderContents = () => {
    if (changesQuery.isLoading) {
      return <LoadingSpinner />;
    }

    if (changesQuery.isError || !changesQuery.data?.notes.length) {
      return <CopyText variant="copy-4">No snapshot was found</CopyText>;
    }

    return (
      <div className="w-full gap-8 hbox">
        <CopyText variant="copy-4">Backup Status</CopyText>
        <BackupStatus note={changesQuery.data.notes[0]} />
        <CopyText variant="copy-4" additionalClass="text-blue-gray-base">
          Last Backup
        </CopyText>
        <CopyText variant="copy-4">{formatEpochDate(changesQuery.data.notes[0].timestamp)}</CopyText>

        <CooldownButton
          name={`run-backup-${entityId}`}
          variant="fillOutline"
          additionalClass="ml-auto"
          size="s"
          onClick={handleBackupClick}
          disabled={isLoading}
          cooldown={60 * 2}
        >
          {isLoading ? <LoadingSpinner centered size="m" /> : <Icon name="Reset" additionalClass="mr-8" />}
          Run Backup
        </CooldownButton>
      </div>
    );
  };

  return <Card additionalClass={styles.statusBar} header={renderContents()} />;
};
