import Card from '@components/common/Card/Card';
import { DeviceConfig } from '@infrastructure/api/BaseNClient/useDeviceConfigHistoryQuery';
import CopyText from '@components/typography/CopyText';
import Button from '@components/common/Button';
import Icon from '@components/common/Icon';
import { CodeEditor } from '@components/common/CodeEditor/CodeEditor';
import { formatEpochDate } from '@utils/date';
import { floor } from 'lodash';
import { CopyToClipboardButton } from '@components/connected/CopyToClipboardButton/CopyToClipboardButton';

import styles from './DeviceConfigPanel.module.scss';

export type DeviceConfigPanelProps = {
  title?: string;
  config?: DeviceConfig;
  onDiffClick?: (config: DeviceConfig) => void;
};

export const DeviceConfigPanel = ({ config, onDiffClick }: DeviceConfigPanelProps) => {
  const hasConfig = !!config?.config;

  const title = config?.time ? `Config Version: ${floor(config.time / 1000)}` : 'Config';

  const renderHeader = () => {
    return hasConfig ? (
      <>
        <CopyText variant="copy-4">{title}</CopyText>
        <CopyText variant="copy-6" additionalClass="text-blue-gray-base">
          {formatEpochDate(config?.time)}
        </CopyText>
        <div className="gap-8 ml-auto hbox">
          {onDiffClick && (
            <Button variant="fillOutline" additionalClass="ml-auto" size="s" onClick={() => onDiffClick(config)}>
              <Icon name="NewTab" additionalClass="mr-8" />
              Diff
            </Button>
          )}
          <CopyToClipboardButton textToCopy={config.config} variant="fillOutline" round={false} />
        </div>
      </>
    ) : (
      <CopyText variant="copy-4">{title}</CopyText>
    );
  };

  return (
    <Card header={renderHeader()} additionalClass={styles.configPanel} noPadding>
      {hasConfig ? (
        <CodeEditor value={config.config} readOnly additionalClass={styles.codeEditor} />
      ) : (
        <CopyText variant="copy-4" additionalClass="p-10">
          Config hasn't been found
        </CopyText>
      )}
    </Card>
  );
};
