import { useCallback, useEffect, useRef } from 'react';
import noop from '@utils/noop';

export default function useInterval(callback: () => any, delay: number = 1000, stop = false) {
  const savedCallback = useRef<() => any>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const tick = useCallback(() => {
    savedCallback.current?.();
  }, [savedCallback]);

  useEffect(() => {
    tick();

    if (!stop) {
      const intervalId = setInterval(tick, delay);

      return () => clearInterval(intervalId);
    }

    return noop;
  }, [stop, tick, delay]);
}
