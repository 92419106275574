import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIMEZONE } from '@constants/date';
import { DateRange } from '@blueprintjs/datetime';
import { SupportedEvents } from '@components/common/Chart/types';
import { ChartConfigGeneratorProps, ChartData } from '@components/widgets/ChartWidget/types';
import { Channel, SingleMaintenanceWindow } from '@infrastructure/api/BaseNClient/useFilterDataQuery';
import colors from '@theme/colors';
import { TitleComponentOption } from 'echarts';
import { cloneDeep, isEmpty, isUndefined, merge, round } from 'lodash';
import moment from 'moment';
import { getBetterUnit, getUnitDef, normalizeValue, unitConverter } from './unitConverter';
import { BaseChartWidgetSubConfig, ChartDataSource } from '@infrastructure/redux/widgetPage';
import { inDateRange } from './date';
import { Connection } from '@components/widgets/MapWidget/types';
import { TopologyRoute } from '@infrastructure/api/BaseNClient/useEntitiesGeoJSONQuery';
import { MaintenanceWindow } from '@infrastructure/api/BaseNClient/useMaintenanceWindowsQuery';
import { parseSVG } from 'svg-path-parser';

export const dateRangeToString = (
  dateRange: DateRange,
  timezone = DEFAULT_TIMEZONE,
  format = DEFAULT_DATETIME_FORMAT
) => `${moment.tz(dateRange[0], timezone).format(format)} - ${moment.tz(dateRange[1], timezone).format(format)}`;

export const dateRangeTitle = (
  dateRange: DateRange,
  timezone: string = DEFAULT_TIMEZONE,
  options: TitleComponentOption = {}
) =>
  merge(
    {
      text: dateRangeToString(dateRange, timezone),
      textStyle: {
        color: colors.blue.gray.base,
        fontSize: 12,
        fontWeight: 'normal',
      },
      left: 'center',
      bottom: 0,
    },
    options
  );

export const getMinMaxAvg = (arr?: any[]): [number, number, number] | null => {
  const result = arr?.reduce((minMax, value) => {
    if (typeof value !== 'number') {
      return minMax;
    }
    const min = !minMax[0] || minMax[0] > value ? value : minMax[0];
    const max = !minMax[1] || minMax[1] < value ? value : minMax[1];

    return [min, max];
  }, []);

  return result?.length ? [...(result as [number, number]), (result[0] + result[1]) / 2] : null;
};

export const getMinMaxAvgFromChannels = (channels: Channel[]) =>
  getMinMaxAvg(
    channels?.reduce((minMax, { values }) => {
      return minMax.concat(getMinMaxAvg(values) ?? []);
    }, [] as number[])
  ) ?? [];

export const formatValue = (anyValue: number | string, unit?: string, append?: 'unit' | 'prefix', precision = 2) => {
  const value = +anyValue;

  if (Number.isNaN(value)) {
    return null;
  }

  try {
    const [newValue, newUnit, newPrefix = ''] = normalizeValue(value, unit, precision);

    return append ? `${newValue}${append === 'unit' ? ` ${newUnit}` : newPrefix}` : newValue;
  } catch (ex: any) {
    const def = getUnitDef(unit);

    return append
      ? `${round(value, precision)}${append === 'unit' ? ` ${def.unit}` : def.prefix ?? ''}`
      : round(value, precision);
  }
};

export const normalizeData = ({
  unit,
  channels,
}: Pick<ChartData, 'unit' | 'channels'>): Pick<ChartData, 'unit' | 'channels'> => {
  if (unit && channels?.length) {
    const betterUnit = getBetterUnitFromChannels(channels, unit);

    return {
      channels: convertValuesInChannels(channels, unit, betterUnit),
      unit: betterUnit,
    };
  } else {
    return {
      unit,
      channels,
    };
  }
};

export const getBetterUnitFromChannels = (channels: Channel[], unit: string): string => {
  let minMax = getMinMaxAvgFromChannels(channels);
  if (!minMax?.length) {
    minMax = [0, 1, 0.5];
  }
  const [min, max] = minMax;
  const avg = (min + max) / 2;

  return getBetterUnit(avg, unit) ?? unit;
};

export const convertValue = (fromUnit: string, toUnit: string, amount: number, precision = 2): number => {
  try {
    return unitConverter(fromUnit, toUnit, amount, precision);
  } catch (ex: any) {
    return amount;
  }
};

export const convertValuesInChannels = (channels: Channel[], fromUnit: string, toUnit: string) =>
  channels.map((channel: Channel) => ({
    ...channel,
    values: channel.values?.map((value: any) =>
      typeof value === 'number' ? convertValue(fromUnit, toUnit, value) : value
    ),
  }));

export function bindEvents(chartInstance: echarts.ECharts, eventProps: SupportedEvents, on = true) {
  if (!isEmpty(eventProps)) {
    Object.keys(eventProps).forEach((propName: any) => {
      if (/^on\w+/.test(propName)) {
        chartInstance[on ? 'on' : 'off'](
          propName.replace(/^on/, '').toLocaleLowerCase(),
          // @ts-ignore
          eventProps[propName as keyof SupportedEvents]
        );
      }
    });
  }
}

export const normalizeLegacySource = (source: any | null) => {
  if (!source) {
    return null;
  }

  const newSource = cloneDeep(source) as any;

  if (newSource.group) {
    newSource.page = newSource.group;
    delete newSource.group;
  }

  if (newSource.metric) {
    newSource.plotname = newSource.metric;
    delete newSource.metric;
  }

  return newSource as ChartDataSource;
};

export const flattenMaintenanceWindows = (
  maintenaceWindows: MaintenanceWindow[],
  dateRange?: DateRange
): Record<string, SingleMaintenanceWindow> => {
  const registry: Record<string, SingleMaintenanceWindow> = {};

  maintenaceWindows.forEach(({ times, ...props }) => {
    times
      ?.filter(
        // @ts-ignore - maintenance windows can be reccurring, but the following snippet effectively filters them out as well
        ({ startTime, endTime }) =>
          // for now BE occasionally returns startTime or endTime of 0 ¯\_(ツ)_/¯  so filter that crap out
          startTime && endTime && (!dateRange || inDateRange(startTime, dateRange) || inDateRange(endTime, dateRange))
      )
      // @ts-ignore
      .forEach(({ startTime, endTime }, idx) => {
        const uid = `${props.id}-${idx}`;
        registry[uid] = {
          ...props,
          // clip maintenance window ranges to not outflow the dateRange if given
          startTime: dateRange?.[0] ? Math.max(startTime, dateRange[0].getTime()) : startTime,
          endTime: dateRange?.[1] ? Math.min(endTime, dateRange[1].getTime()) : endTime,
          uid,
        };
      });
  });

  return registry;
};

// {
//   "reason": "Maintenance",
//   "atls": {
//       "name": "Scriptable Test",
//       "groups": [
//           {
//               "name": "kajtzu@basen",
//               "rows": [
//                   {
//                       "startms": 1654851863567,
//                       "endms": 1654855463567
//                   }
//               ],
//               "silent": "true"
//           }
//       ],
//       "locale": "fi,FI",
//       "timezone": "Europe/Helsinki",
//       "enabled": "true"
//   },
//   "name": "Scriptable Test",
//   "active": false,
//   "description": "Test window"
// }

export const toMaintenanceWindow = ({ id, reason, atls, name, description }: any) => ({
  reason,
  name,
  description,
  id,
  times: atls.groups.map(({ rows }: any) => rows),
});

export const isNowInMaintenanceWindow = (maintenaceWindows: MaintenanceWindow[]) => {
  const now = Date.now();
  const flattenedMaintenanceWindows = Object.values(flattenMaintenanceWindows(maintenaceWindows));

  return !!flattenedMaintenanceWindows.find(({ startTime, endTime }) =>
    inDateRange(now, [new Date(startTime), new Date(endTime)])
  );
};

export const getPositions = (data?: any) => {
  const positions: any = {};

  data?.graph.eachNode((node: any) => {
    const [x, y] = data.getItemLayout(node.dataIndex);
    positions[node.id] = { x, y };
  });

  return positions;
};

export const topoToGeoConnection = (
  {
    bidirectional,
    id,
    from,
    from_name,
    from_icon,
    to,
    to_name,
    to_icon,
    alert_color,
    width,
    width_bps,
    alert_value,
    alert_id,
    status,
    from_alert_color,
    to_alert_color,
  }: TopologyRoute,
  rtl: boolean
): Connection => {
  const connection = {
    id,
    width,
    width_bps,
    alert_id,
    alert_value,
    alert_color: alert_color ?? 'gray',
    status,
    from,
    to,
    from_name,
    to_name,
    from_icon,
    to_icon,
    from_alert_color,
    to_alert_color,
    index: 1,
  };

  return rtl && bidirectional
    ? {
        ...connection,
        id: `${id}-rtl`,
        from: to,
        from_name: to_name,
        from_icon: to_icon,
        from_alert_color: to_alert_color,
        to: from,
        to_name: from_name,
        to_icon: from_icon,
        to_alert_color: from_alert_color,
      }
    : connection;
};

export const isValidChartDataSource = (source?: ChartDataSource | null) =>
  !!(
    (source?.entityId && source?.page && source?.plotname) ||
    (source?.entityId && source?.metricId) ||
    source?.alertId
  );

// from https://github.com/hughsk/svg-path-parser/issues/11#issuecomment-283196371
function pathData(data: any[]): string {
  const params = ['rx', 'ry', 'xAxisRotation', 'largeArc', 'sweep', 'x1', 'y1', 'x2', 'y2', 'x', 'y'];
  let lastCode: any;
  return data
    .map(function (cmd) {
      const a: any[] = [];
      params.forEach(function (param) {
        if (param in cmd) {
          const val = cmd[param] * 1; // *1 for true/false values on arc
          if (a.length && val >= 0) a.push(',');
          a.push(val);
        }
      });
      const result = (lastCode === cmd.code ? (a[0] < 0 ? '' : ',') : cmd.code) + a.join('');
      lastCode = cmd.code;
      return result;
    })
    .join('');
}

export function echartizePath(path: string): string {
  // somehow ECharts doesn't understand SVG paths exported from Figma, so we deconstruct
  // them here and then construct them back, which magically makes them work ¯\_(ツ)_/¯
  return pathData(parseSVG(path));
}

export function extractSvgPath(svgString: string): string[] | null {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
  const paths = svgDoc.querySelectorAll('path');
  if (paths.length > 0) {
    return Array.from(paths).map(path => path.getAttribute('d') || '');
  }
  return null;
}

export async function loadSvgIcon(iconPath: string) {
  const svgPath = `@components/common/Icon/icons/${iconPath}.svg?raw`;
  const svgString = await import(/* @vite-ignore */ svgPath);
  return echartizePath(extractSvgPath(svgString)?.[0] ?? '');
}

const MIN_CHANNELS_FOR_VERTICAL_LEGEND = 4;

export function isHorizontalLegend<SubConfig extends BaseChartWidgetSubConfig = BaseChartWidgetSubConfig>({
  options,
  channels,
}: ChartConfigGeneratorProps<SubConfig> = {}): boolean {
  // NOTE: for backward compatibility
  if (!options?.legendType && !isUndefined(options?.horizontalLegend)) {
    return !!options?.horizontalLegend;
  }

  switch (options?.legendType) {
    case 'horizontal':
      return true;

    case 'vertical':
      return false;

    case 'auto':
    default:
      return channels?.length && channels.length > MIN_CHANNELS_FOR_VERTICAL_LEGEND ? false : true;
  }
}
