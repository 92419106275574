import { createCustomSlice, createDataTableReducers, createInitialDataTableState } from '@utils/redux';
import { DisplayMode, MetricsPageState, MetricsStoredTableConfig } from './types';
import { METRICS_TABLE_ID } from './selectors';
import { EntityDetails } from '../inventoryPage';
import { PayloadAction } from '@reduxjs/toolkit';
import { getURLQueryParams } from '@utils/url';
import { history } from '@services/useHistory';
import { retrieveItem } from '@utils/storage';

function selectInitialDisplayMode(tableId: string) {
  const { displayMode } = getURLQueryParams(history.location);
  const storedParams = retrieveItem<MetricsStoredTableConfig>(tableId);
  return displayMode ?? storedParams?.displayMode ?? 'grid';
}

const initialState: MetricsPageState = {
  mode: selectInitialDisplayMode(METRICS_TABLE_ID),
  ...createInitialDataTableState(METRICS_TABLE_ID),
};

const reducers = {
  updateDisplayMode: (state: MetricsPageState, action: PayloadAction<DisplayMode>) => {
    state.mode = action.payload;
  },

  ...createDataTableReducers<EntityDetails>(initialState),
};

export const createMetricsSlice = (actionPrefix?: string) =>
  createCustomSlice({
    name: 'metricsPage',
    initialState,
    reducers,
    actionPrefix,
  });

const metricsSlice = createMetricsSlice();

export const {
  updateItems,
  updatePagination,
  updateSearch,
  updateSorting,
  updateFilters,
  updateDisplayMode,
  updateRowHeight,
  reset,
} = metricsSlice.actions;

export default metricsSlice;
