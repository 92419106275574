import {
  MaintenanceWindow,
  RecurringSchedule,
  Schedule,
} from '@infrastructure/api/BaseNClient/useMaintenanceWindowsQuery';
import cronParser from 'cron-parser';
import moment from 'moment';
import cronstrue from 'cronstrue';
import { DateRange } from '@blueprintjs/datetime';
import { isEmpty } from 'lodash';

export const isActive = (schedule: Schedule | RecurringSchedule, currentTime: number = Date.now()) => {
  // @ts-ignore
  let { startTime, endTime, recurrences } = schedule;

  if (startTime && endTime) {
    return startTime >= currentTime && currentTime <= endTime;
  } else if (recurrences?.length) {
    return recurrences.some((recurrence: string) => {
      // BaseN has 7 places in the every CRON string, 7th being a year placeholder, but we only care about the first 6
      /*
            *    *    *    *    *    *    *
            ┬    ┬    ┬    ┬    ┬    ┬    ┬
            │    │    │    │    │    |    |
            │    │    │    │    │    |    └── year (optional)
            │    │    │    │    │    └─────── day of week (0 - 7, 1L - 7L) (0 or 7 is Sun)
            │    │    │    │    └──────────── month (1 - 12)
            │    │    │    └───────────────── day of month (1 - 31, L)
            │    │    └────────────────────── hour (0 - 23)
            │    └─────────────────────────── minute (0 - 59)
            └──────────────────────────────── second (0 - 59, optional)
      */
      const interval = cronParser.parseExpression(recurrence.split(/\s+/).slice(0, 6).join(' '), {
        utc: true,
      });

      const nextTime = interval.next().getTime();
      // we take +/- 1 minute to account for the fact that the cron parser will return the next time in the future
      startTime = nextTime - 60 * 1000;
      endTime = nextTime + 60 * 1000;

      return startTime >= currentTime && currentTime <= endTime;
    });
  } else {
    return false;
  }
};

export const formValuesToMW = (
  { general, schedules, targets }: any,
  originalMW?: MaintenanceWindow
): Omit<MaintenanceWindow, 'id'> => {
  return {
    // we need to send along some of the legacy values as they were, otherwise they will be lost
    ...originalMW,
    name: general.name,
    reason: general.reason,
    description: general.description,
    entityIds: targets.entities?.map((entity: any) => entity.id),
    entityNames: targets.entities?.map((entity: any) => entity.name),
    global: !!targets.global,
    routing: !!targets.routing,
    times: schedules.times.map((schedule: any) =>
      typeof schedule === 'string'
        ? { recurrences: [schedule] }
        : {
          startTime: schedule[0].getTime(),
          endTime: schedule[1].getTime(),
        }
    ),
    // need to add rules for backwards compatibility, otherwise entityIds will be ignored
    rules: targets.entities?.map((entity: any) => ({ entityid: entity.id })) || [],
  };
};

export const mwToFormValues = (mw: MaintenanceWindow) => {
  return {
    general: {
      name: mw.name,
      reason: mw.reason,
      description: mw.description,
    },
    schedules: {
      times: mw.times?.map(fromBaseNFormat),
    },
    targets: {
      global: !!mw.global,
      routing: !!mw.routing,
      entities: mw.entityIds?.map((id: string) => ({ id })),
    },
  };
};

export const isLegacyMW = (mw?: MaintenanceWindow) => {
  // if at least one rule has no entityId, it's a legacy MW (for details check comments for mwSchema)
  const isLegacy =
    // it can be []
    !mw?.rules?.length ||
    // it can be [{}]
    (!isEmpty(mw.rules[0]) && !!mw?.rules?.find(rule => !rule.entityid));

  return isLegacy;
};

export const fromBaseNFormat = (schedule: Schedule | RecurringSchedule): string | DateRange => {
  // @ts-ignore
  const isOnce = schedule.startTime && schedule.endTime;
  // @ts-ignore
  return isOnce ? [new Date(schedule.startTime), new Date(schedule.endTime)] : schedule.recurrences[0];
};

export const toHumanReadable = (schedule: string | DateRange, verbose = false) => {
  const isOnce: boolean = typeof schedule !== 'string';

  return isOnce
    ? `Once, between ${schedule[0] ? moment(schedule[0]).format('MMMM Do, HH:mm') : '...'} and ${schedule[1]
      ? moment(schedule[1]).format(moment(schedule[0]).diff(schedule[1], 'days') === 0 ? 'HH:mm' : 'MMMM Do, HH:mm')
      : '...'
    }`
    : cronstrue
      .toString(schedule as string, { use24HourTimeFormat: true, verbose })
      // cheap hack to produce a more realistic prompt
      // TODO: revise if we ever enable a 'locale' option above
      .replace('Every second', 'Recurring');
};
