import { DateRange } from '@blueprintjs/datetime';
import useTextSnippets from '@services/useTextSnippets';
import Button from '@components/common/Button';
import { DateRangePicker } from '@components/common/form/DatePicker';
import Icon from '@components/common/Icon';
import Popover from '@components/common/Popover';
import moment from 'moment';
import React, { FC, useState } from 'react';
import c from 'classnames';

import styles from './TimeControl.module.scss';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export type TimeControlProps = {
  label?: string;
  dateRange: DateRange;
  size?: 's' | 'm' | 'l';
  active?: boolean;
  onChange?: (dateRange: DateRange) => void;
  onReset?: () => void;
};

export const TimeControl: FC<TimeControlProps> = ({
  label,
  dateRange: defaultDateRange,
  size = 's',
  active = false,
  onChange,
  onReset,
}) => {
  const [dateRange, setDateRange] = React.useState<DateRange>(defaultDateRange);
  const [isOpen, setIsOpen] = useState(false);
  const i18n = useTextSnippets('dashboards');

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (newDateRange: DateRange) => {
    if (newDateRange[0] && newDateRange[1]) {
      setIsOpen(false);
      onChange?.(newDateRange);
    }
    setDateRange(newDateRange);
  };

  const handleReset = () => {
    setIsOpen(false);
    onReset?.();
  };

  const handlePopoverInteraction = (newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
  };

  return (
    <Popover
      isOpen={isOpen}
      content={
        <DateRangePicker
          value={dateRange}
          showActionsBar
          showTimePicker
          onChange={handleChange}
          onReset={handleReset}
        />
      }
      additionalClass={styles.popover}
      onInteraction={handlePopoverInteraction}
    >
      <Button
        variant="stealth"
        additionalClass={c(styles.timeControl, active && styles.active)}
        onClick={handleClick}
        size={size}
      >
        <Icon name={active ? 'TimeControlActive' : 'Clock'} size="r" additionalClass={styles.icon} />
        <span className={styles.title}>{label ?? i18n.timeControl}</span>
        <span className={c(styles.toHide, 'mr-8')}>:</span>
        <span className={styles.toHide}>{moment(dateRange[0]).format(DATE_FORMAT)}</span>
        <Icon name="Forward" size="xs" additionalClass={c(styles.toHide, 'mx-8')} />
        <span className={styles.toHide}>{moment(dateRange[1]).format(DATE_FORMAT)}</span>
      </Button>
    </Popover>
  );
};
