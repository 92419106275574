import Icon from '@components/common/Icon';
import Button from '@components/common/Button';
import { ProgressBar as BNProgressBar } from '@blueprintjs/core';
import React from 'react';
import { BatchRunDetails } from '@infrastructure/push-api/useBatchRun.schema';
import CopyText from '@components/typography/CopyText';
import { capitalize } from 'lodash';
import c from 'classnames';
import { formatEpochDate } from '@utils/date';
import { summaryToIcon, summaryToIntent } from './utils';
import { Box } from '@components/common/Box';
import { ProgressTable } from './ProgressTable';
import { DownloadButton } from '@components/connected/DownloadButton';
import { API_URL } from '@config';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { DEFAULT_DOWNLOAD_FORMATS } from '@components/layout/TablePageLayout/constants';

import styles from './ProgressFeedback.module.scss';

const BATCH_DOWNLOAD_FORMATS = {
  ...DEFAULT_DOWNLOAD_FORMATS,
  csv_entities: 'text/csv',
  json: 'application/json',
};

export type ProgressFeedbackProps = {
  details: BatchRunDetails;
  onAbort?: () => void;
};

export const ProgressFeedback = ({ details, onAbort }: ProgressFeedbackProps) => {
  const { status, summary, startTime, endTime, progress } = details;

  const icon = summaryToIcon(details.status, details.summary);

  return (
    <div className={styles.progressFeedback}>
      <div className={styles.box}>
        {!!onAbort && status === 'running' && (
          <Button variant="outline" size="s" onClick={onAbort} additionalClass={styles.actionButton}>
            <Icon name="Block" additionalClass="mr-8" />
            Abort job
          </Button>
        )}

        {status === 'completed' && (
          <DownloadButton
            url={`${API_URL}/${ENDPOINTS.batchById}`}
            formats={BATCH_DOWNLOAD_FORMATS}
            additionalClass={styles.actionButton}
          />
        )}

        <Icon name={icon} additionalClass={styles.icon} />

        {!summary?.total ? null : (
          <div className={styles.progressBar}>
            <div className="w-full mb-4 hbox">
              <CopyText variant="copy-4" additionalClass={c(styles.status, styles[summaryToIntent(status, summary)])}>
                {capitalize(status)}
              </CopyText>
              <CopyText variant="copy-4" additionalClass="ml-auto text-blue-gray">{`${
                summary.total - (summary.running + summary.waiting)
              } of ${summary.total} completed`}</CopyText>
            </div>

            <BNProgressBar
              intent={summaryToIntent(status, summary)}
              value={(summary.total - (summary.running + summary.waiting)) / summary.total}
              stripes={false}
              animate
            />

            <div className="w-full mt-4 hbox text-blue-gray">
              <CopyText variant="copy-6">{`Started: ${formatEpochDate(startTime)}`}</CopyText>
              <CopyText variant="copy-6" additionalClass="ml-auto">
                {`Ended: ${endTime ? formatEpochDate(endTime) : 'N/A'}`}
              </CopyText>
            </div>
          </div>
        )}
      </div>

      <Box header="Targets" noPadding additionalClass={styles.targetsBox}>
        <ProgressTable items={Object.values(progress)} />
      </Box>
    </div>
  );
};
