import React, { SyntheticEvent } from 'react';
import c from 'classnames';
import Button from '@components/common/Button';
import { Classes, Dialog as BPDialog, DialogProps as BPDialogProps } from '@blueprintjs/core';
import useTextSnippets from '@services/useTextSnippets';
import { useTheme } from '@services';

import styles from './Modal.module.scss';

export type ModalProps = Omit<BPDialogProps, 'onClose'> & {
  additionalClasses?: {
    root?: string;
    header?: string;
    body?: string;
    footer?: string;
  };
  // confirm button loading
  loading?: boolean;
  // confirm button disabled
  disabled?: boolean;
  customFooter?: React.ReactNode;
  customHeader?: React.ReactNode;
  danger?: boolean;
  acceptLabel?: string;
  cancelLabel?: string;
  onAccept?: (event?: SyntheticEvent<HTMLElement>) => void;
  // original event handler requires event, but we don't need it
  onClose?: (event?: React.SyntheticEvent<HTMLElement>) => void;
};

/**
 * Our Props:
 * additionalClasses - 4 points to attach custom styles, root, header, body, footer
 * acceptLabel - string
 * cancelLabel - string
 * danger - bool - red main button
 * customFooter - JSX - overwrite default footer if needed
 * customHeader - JSX - overwrite default header if needed
 * onAccept - handler
 *
 * https://blueprintjs.com/docs/versions/4/#core/components/dialog.props
 * Relevant props:
 * autoFocus - bool - default true
 * backdropClassName - string
 * canEscapeKeyClose - boolean - default true
 * canOutsideClickClose - boolean - default true
 * enforceFocus - boolean - default false
 * icon - we should put JSX only - we excluded icons from blueprint bundle - this only renders if title is passed in
 *   - otherwise customHeader needs to be used.
 * isCloseButtonShown - bool - default true
 * isOpen - bool
 * lazy - bool - default true
 * onClose - handler
 * onClosed - handler
 * onClosing - handler
 * onOpened - handler
 * onOpening - handler
 * portalClassName - string
 * portalContainer - element - default is body
 * title - string or JSX - effectively controls default header presence
 * transitionDuration - number - should leave this to tailwind project theme defaults
 * usePortal - bool - default true
 */
const Modal: React.FC<ModalProps> = ({
  children,
  acceptLabel,
  cancelLabel,
  additionalClasses = {},
  customHeader,
  customFooter,
  onAccept,
  onClose,
  danger,
  title,
  loading,
  disabled,
  isCloseButtonShown,
  ...rest
}) => {
  const { cancelLabel: defaultCancelLabel, acceptLabel: defaultAcceptLabel } = useTextSnippets('modal');
  const defaultDuration = useTheme('transitionDuration.DEFAULT');

  return (
    <BPDialog
      className={c(styles.dialog, additionalClasses.root)}
      title={customHeader ? undefined : title}
      onClose={onClose}
      isCloseButtonShown={isCloseButtonShown}
      transitionDuration={parseInt(defaultDuration, 10)}
      {...rest}
      backdropClassName={styles.backdrop}
    >
      {customHeader ? <div className={c(Classes.DIALOG_HEADER, additionalClasses.header)}>{customHeader}</div> : null}

      <div className={c(Classes.DIALOG_BODY, additionalClasses.body)}>{children}</div>

      {customFooter === null ? null : customFooter ? (
        <div className={c(Classes.DIALOG_FOOTER, additionalClasses.footer)}>{customFooter}</div>
      ) : (
        <div className={Classes.DIALOG_FOOTER}>
          <div className={c(Classes.DIALOG_FOOTER_ACTIONS, 'pb-16')}>
            <Button size="s" variant="fillOutline" onClick={onClose} ariaLabel="Close Button" additionalClass="mr-16">
              {cancelLabel || defaultCancelLabel}
            </Button>
            <Button
              size="s"
              variant={danger ? 'fillRedDark' : 'fillBlueDark'}
              onClick={onAccept}
              ariaLabel="Accept Button"
              loading={loading}
              disabled={loading || disabled}
            >
              {acceptLabel || defaultAcceptLabel}
            </Button>
          </div>
        </div>
      )}
    </BPDialog>
  );
};

export default Modal;
