import React, { FC, useRef, useState } from 'react';
import c from 'classnames';
import { loadFileAs } from '@utils/file';
import { Button, ButtonVariant } from '../Button';
import Icon, { IconNames } from '../Icon';
import IconButton, { IconButtonVariant } from '../IconButton';

import styles from './style.module.scss';

export type ImportButtonProps = {
  icon?: IconNames;
  title?: string;
  iconOnly?: boolean;
  variant?: IconButtonVariant | ButtonVariant;
  size: 's' | 'm' | 'l';
  preloadAs?: 'text' | 'dataurl' | 'arraybuffer';
  accept?: string[];
  onChange?: (file: File | string | ArrayBuffer | null) => void;
  additionalClass?: string;
};

export const ImportButton: FC<ImportButtonProps> = ({
  icon,
  title = 'Import',
  iconOnly = false,
  variant,
  size,
  accept = [], // array of extensions (without a dot or asterisk)
  onChange,
  preloadAs,
  additionalClass,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file || !onChange) {
      return;
    }

    if (!preloadAs) {
      onChange(file);
    } else {
      setIsLoading(true);
      const result = await loadFileAs(file, preloadAs);
      setIsLoading(false);
      onChange(result);
    }
  };

  return (
    <div className={c(styles.importButton, additionalClass)}>
      <input
        className={styles.fileInput}
        type="file"
        accept={`.${accept.join(',.')}`}
        ref={fileInputRef}
        onChange={handleChange}
      />
      {iconOnly && icon ? (
        <IconButton
          variant={variant as IconButtonVariant}
          size={size}
          icon={icon}
          title={title}
          onClick={handleClick}
          loading={isLoading}
          additionalClass={styles.button}
          round={false}
        />
      ) : (
        <Button
          onClick={handleClick}
          variant={variant as ButtonVariant}
          size={size}
          loading={isLoading}
          additionalClass={styles.button}
        >
          {icon && <Icon name={icon} additionalClass="mr-4" />}
          {title}
        </Button>
      )}
    </div>
  );
};
