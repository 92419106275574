import React, { FC, useState, useEffect, useRef } from 'react';
import { Button, ButtonProps } from '../Button';
import { removeItem, retrieveItem, storeItem } from '@utils/storage';

export type CooldownButtonProps = ButtonProps & {
  cooldown: number; // cooldown time in seconds
  name?: string;
};

export const CooldownButton: FC<CooldownButtonProps> = ({ name, children, cooldown, onClick, disabled, ...props }) => {
  const key = name ? `cooldown-${name}` : '';
  const initialTime = React.useMemo(() => ((key ? retrieveItem<number>(key) ?? 0 : 0) - Date.now()) / 1000, [key]);

  const [remainingTime, setRemainingTime] = useState(initialTime > 0 ? initialTime : 0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (remainingTime > 0) {
      intervalRef.current = setInterval(() => {
        setRemainingTime(prev => prev - 1);
      }, 1000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      if (key) {
        removeItem(key);
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [key, remainingTime]);

  // cleanup
  useEffect(() => {
    if (key && !initialTime) {
      removeItem(key);
    }
  }, [initialTime, key]);

  const handleClick = React.useCallback(
    (e: any) => {
      onClick?.(e);
      setRemainingTime(cooldown);
      if (key) {
        storeItem(key, Date.now() + cooldown * 1000);
      }
    },
    [cooldown, key, onClick]
  );

  return (
    <Button onClick={handleClick} {...props} disabled={disabled || remainingTime > 0}>
      {remainingTime > 0 ? (
        <>
          {children} ({Math.ceil(remainingTime)}s)
        </>
      ) : (
        children
      )}
    </Button>
  );
};
