import { object, string, TypeOf } from 'yup';

import { ITextSnippets } from '@services/useTextSnippets';
import { useMemo } from 'react';

const useSchema = (i18nKeys: ITextSnippets['forgotPassword']) => {
  const schema = object({
    code: string().required(i18nKeys.codeRequired).min(6, i18nKeys.codeRequired),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, [i18nKeys]);
};

export interface ForgotPasswordCodeSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
