import React, { FC, useImperativeHandle } from 'react';
import sizeMe, { SizeMe } from 'react-sizeme';
import cx from 'classnames';
import { Layout, Layouts, Responsive } from 'react-grid-layout';
import { DASHBOARD_CONFIGS } from '@redux/dashboards/configs';
import styles from './styles.module.scss';
import { UncontrolledGridLayoutProps } from './types';
import { Screen } from '@services/useBreakpoint';

sizeMe.noPlaceholders = true;

const UncontrolledGridLayout: FC<UncontrolledGridLayoutProps> = props => {
  const { additionalClass, items, gridProps, imperativeGridRef } = props;

  const onLayoutChange = (layout: Layout[], newLayouts: Layouts) => {
    props.onLayoutChange?.(layout, newLayouts);
  };

  const onBreakpointChange = (newBreakpoint: Screen, newCols: number) => {
    props.onBreakpointChange?.(newBreakpoint, newCols);
  };

  // empty for now .. might come into play later, we'll see
  useImperativeHandle(imperativeGridRef, () => ({}));

  return (
    <SizeMe>
      {({ size }) => (
        <Responsive
          {...DASHBOARD_CONFIGS.defaultGridConfig}
          width={(size.width as number) || 0}
          className={cx(styles.layout, additionalClass)}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          {...gridProps}
        >
          {Object.keys(items || {}).map(itemKey => {
            return (
              <div className={cx(styles.item, 'item')} key={itemKey}>
                {items}
              </div>
            );
          })}
        </Responsive>
      )}
    </SizeMe>
  );
};

export default UncontrolledGridLayout;
