import Button from '@components/common/Button';
import { FieldsConfig } from '@components/common/form/FormBuilder';
import { Tag } from '@components/common/Tag';
import CopyText from '@components/typography/CopyText';
import { CypressTestsData, CypressTestType } from '@cypress';
import useTextSnippets from '@services/useTextSnippets';
import { isFalsy } from '@utils/misc';
import { clone, isArray, isUndefined } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import styles from './CurrentFiltersBar.module.scss';

const clearFilter = (filter: any, valueToRemove?: any) => {
  const newFilter = clone(filter);

  if (filter.type === 'toggle') {
    newFilter.props.checked = false;
  } else if (isArray(filter.props.defaultValue)) {
    const newValue = !isUndefined(valueToRemove)
      ? filter.props.defaultValue.filter((value: any) => value !== valueToRemove)
      : undefined;

    newFilter.props.defaultValue = newValue?.length ? newValue : undefined;
  } else {
    newFilter.props.defaultValue = undefined;
  }

  return newFilter;
};

export type CurrentFiltersBarProps = {
  filters: FieldsConfig;
  onClearFilters?: (filters: FieldsConfig) => void;
};

export const CurrentFiltersBar: React.FC<CurrentFiltersBarProps> = ({ filters, onClearFilters }) => {
  const commonI18n = useTextSnippets('common');
  const issueManagerTestDataConfig = CypressTestsData[CypressTestType.ISSUE_MANAGER];

  const hasActiveFilters = useMemo(() => {
    return filters.find(({ type, props }: any) =>
      type === 'toggle' ? props.checked : !isFalsy(props.defaultValue, false)
    );
  }, [filters]);

  const handleClearFilter = useCallback(
    (filterKey: string, valueToRemove: any) => {
      onClearFilters?.(
        filters.map((filter: any) => (filter.props.name === filterKey ? clearFilter(filter, valueToRemove) : filter))
      );
    },
    [filters, onClearFilters]
  );

  const handleClearAllFilters = useCallback(() => {
    onClearFilters?.(filters.map((filter: any) => clearFilter(filter)));
  }, [filters, onClearFilters]);

  const renderTag = React.useCallback(
    (name: string, label: string, value: any) => (
      <Tag
        key={`${name}:${value}`}
        variant="info"
        onRemove={() => handleClearFilter(name, value)}
        additionalClass="my-4 mr-8 rounded-full"
      >
        {`${label}${typeof value !== 'boolean' ? `: ${value}` : ''}`}
      </Tag>
    ),
    [handleClearFilter]
  );

  return hasActiveFilters ? (
    <div className={styles.currentFiltersBar}>
      <CopyText variant="copy-4" color="dark" additionalClass="mr-8 whitespace-nowrap">
        {commonI18n.appliedFilters}
      </CopyText>

      <div className={styles.filters}>
        {filters.map(({ type, props }: any) => {
          if (type === 'toggle') {
            return props.checked ? renderTag(props.name, props.label, true) : null;
          }

          if (isFalsy(props.defaultValue, false)) {
            return null;
          }

          return isArray(props.defaultValue) ? (
            <React.Fragment key={`fragment-${props.name}`}>
              {props.defaultValue.map((nextValue: any) => renderTag(props.name, props.label, nextValue))}
            </React.Fragment>
          ) : (
            renderTag(props.name, props.label, props.defaultValue)
          );
        })}
      </div>

      <Button
        variant="link"
        onClick={() => handleClearAllFilters()}
        size="s"
        additionalClass="ml-8"
        testid={issueManagerTestDataConfig.testIds.clearAllFiltersButton}
      >
        {commonI18n.clearAllFilters}
      </Button>
    </div>
  ) : null;
};
