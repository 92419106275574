import React, { forwardRef } from 'react';
import c from 'classnames';
import Icon from '@components/common/Icon';

import styles from './style.module.scss';

const ResizeHandle = forwardRef<HTMLDivElement>((props, ref) => {
  // https://github.com/react-grid-layout/react-resizable/compare/v3.0.3...v3.0.4
  // @ts-ignore TODO
  const { handleAxis, ...propsToPass } = props;

  return (
    <div {...propsToPass} ref={ref} className={c('react-resizable-handle', styles.resizeHandle)}>
      <Icon name="Resize" size="s" />
    </div>
  );
});

ResizeHandle.displayName = 'React-Resizable-Handle';

export default ResizeHandle;
