import styles from './style.module.scss';

const {
  container,
  wrapper,
  interactionDisabled,
  navBar,
  navButtonPrev,
  navButtonNext,
  navButtonInteractionDisabled,
  months,
  month,
  caption,
  weekdays,
  weekdaysRow,
  weekday,
  weekNumber,
  body,
  week,
  day,
  footer,
  todayButton,
  today,
  selected,
  disabled,
  outside,
} = styles;

export default {
  classNames: {
    container, // The container element
    wrapper, // The wrapper element, used for keyboard interaction
    interactionDisabled, // Added to the container when there's no interaction with the calendar

    navBar, // The navigation bar with the arrows to switch between months
    navButtonPrev, // Button to switch to the previous month
    navButtonNext, // Button to switch to the next month
    navButtonInteractionDisabled, // Added to the navbuttons when disabled with fromMonth/toMonth props

    months, // Container of the months table
    month, // The month's main table
    caption, // The caption element, containing the current month's name and year
    weekdays, // Table header displaying the weekdays names
    weekdaysRow, // Table row displaying the weekdays names
    weekday, // Cell displaying the weekday name
    weekNumber,
    body, // Table's body with the weeks
    week, // Table's row for each week
    day, // The single day cell

    footer, // The calendar footer (only with todayButton prop)
    todayButton, // The today button (only with todayButton prop)

    /* default modifiers */
    today, // Added to the day's cell for the current day
    selected, // Added to the day's cell specified in the "selectedDays" prop
    disabled, // Added to the day's cell specified in the "disabledDays" prop
    outside, // Added to the day's cell outside the current month
  },
};
