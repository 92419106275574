import { useMutation, UseMutationResult } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericNotOkApiResponse, GenericOkApiResponse } from '../types';
import { Note } from './useLogbookQuery';

type UseLogbookAddEditMutationRequest = Omit<Note, 'id' | 'author'> & { id?: string };

export const useLogbookAddEditMutation = <
  S extends GenericOkApiResponse = GenericOkApiResponse,
  F extends GenericNotOkApiResponse = GenericNotOkApiResponse,
  V extends UseLogbookAddEditMutationRequest = UseLogbookAddEditMutationRequest
>(
  mutationOptions = {}
): UseMutationResult<S, F, V> =>
  useMutation<S, F, V>(
    (body: V): Promise<S> => apiClient[body.id ? 'put' : 'post'](ENDPOINTS.logbook, body),
    mutationOptions
  );
