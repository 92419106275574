import { ZodError, ZodType } from 'zod';

export const isFunction = (obj: any) => typeof obj === 'function';

/**
 * allows to grab keys and values of the arbitrary object as types, e.g.:
 *
 * const KeyToVal = t({
 *   MyKey1: 'myValue1',
 *   MyKey2: 'myValue2',
 * });
 *
 * type Keys = keyof typeof KeyToVal;
 * type Values = typeof KeyToVal[Keys]; //  "myValue1" | "myValue2"
 *
 * credits: https://stackoverflow.com/a/53662389
 */
export function typify<V extends string, T extends { [key in string]: V }>(o: T): T {
  return o;
}

export const isZodSchema = (obj: any) => obj instanceof ZodType;

export const isZodError = (obj: any) => obj instanceof ZodError;

/*
Example of ZodError:
  [
    {
      "code": "invalid_type",
      "expected": "number",
      "received": "string",
      "path": [
        "two",
        "four"
      ],
      "message": "Expected number, received string"
    }
  ]
*/
export const zodErrorToString = (err: ZodError) =>
  `${err.errors[0].message}, at path '${err.errors[0].path.join('.')}'`;
