import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { COOKIES, LOCAL_STORAGE } from '@constants/storage';
import { AccountInfo, DecodedJWTData } from '@infrastructure/redux/auth';

export function getUserToken(): string | undefined {
  return Cookies.get(COOKIES.token);
}

export function setUserToken(token: string) {
  return Cookies.set(COOKIES.token, token);
}

export function removeUserToken() {
  Cookies.remove(COOKIES.token);
}

export function getUserRememberMe(): { username: string } {
  const storedUsername = localStorage.getItem(LOCAL_STORAGE.rememberMe);

  return {
    username: storedUsername ? ((JSON.parse(storedUsername) as { username: string }) || {}).username : '',
  };
}

export function checkTokenValid(token?: string) {
  try {
    const account = token && jwt_decode(token);

    return !!account;
  } catch (e) {
    return false;
  }
}

export const tokenToAccount = (token: string | undefined = getUserToken(), isTemporary = false): AccountInfo | null => {
  if (token) {
    const decodedData = jwt_decode(token) as DecodedJWTData;
    const {
      sub: id,
      tz: timezone,
      loc: locale,
      rlm: realm,
      nam: name,
      fnm: firstname,
      lnm: lastname,
      usr: username,
      eml: email,
      adm: isAdmin,
      grp: groups,
    } = decodedData;

    return {
      token: {
        data: token,
        decodedData,
        isTemporary,
      },
      id,
      isAdmin,
      name,
      username,
      realm,
      firstname,
      lastname,
      email,
      locale,
      timezone,
      groups,
    };
  }
  return null;
};
