import { useHotkeys } from 'react-hotkeys-hook';
import { BaseSelectItem, SelectControlUncontrolled } from '../form/Select';
import React from 'react';
import { useGlobalSearchQuery, SearchHit } from '@infrastructure/api/BaseNClient/useGlobalSearch';
import { KEYCODES } from '@constants/keyboardShortcutsConfig';
import useTextSnippets from '@services/useTextSnippets';
import useDebouncedValue from '@services/useDebouncedValue';
import { Input } from '@components/common/form/Input';
import { Menu, MenuItem } from '@components/common/Menu';
import { groupBy } from 'lodash';
import { ItemRendererProps, ItemListRendererProps } from '@blueprintjs/select';
import SearchSuggestion, { getSuggestionUrl } from './components/SearchSuggestion';
import Icon from '../Icon';
import CopyText from '@components/typography/CopyText';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { useNavigate } from 'react-router-dom';

import styles from './GlobalSearch.module.scss';

type GlobalSearchProps = {
  disabled?: boolean;
  noIcon?: boolean;
};

export const GlobalSearch = ({ disabled, noIcon }: GlobalSearchProps) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebouncedValue<string>(searchTerm, 500);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const i18n = useTextSnippets('header');
  const navigate = useNavigate();

  useHotkeys(KEYCODES.FOCUS_SEARCH, (e: KeyboardEvent) => {
    if (inputRef.current) {
      inputRef.current.focus();
      e.stopPropagation();
      e.preventDefault();
    }
  });

  const { data: suggestions, isFetching: isSearching } = useGlobalSearchQuery(debouncedSearchTerm);

  const handleSuggestionClick = React.useCallback((suggestion: SearchHit) => {
    setSearchTerm(suggestion.name);
  }, []);

  const handleChange = React.useCallback(
    (item: BaseSelectItem<SearchHit> | null) => {
      if (item) {
        setSearchTerm(item.value.name);
        navigate(getSuggestionUrl(item.value));
      }
    },
    [navigate]
  );

  const options = React.useMemo(
    () => suggestions?.data.map(item => ({ label: item.name, value: item })) ?? [],
    [suggestions]
  );

  const inputComponent = (
    <Input icon={noIcon ? undefined : <Icon name="Search" size="m" />} type="text" name="search" disabled={disabled} />
  );

  const optionRenderer = (option: BaseSelectItem<SearchHit>, { handleClick, modifiers }: ItemRendererProps) => {
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={[option.value.type, option.value.id].join('-')}
        onClick={handleClick}
        text={<SearchSuggestion suggestion={option.value} searchTerm={searchTerm} onClick={handleSuggestionClick} />}
        additionalClass={styles.menuItem}
      />
    );
  };

  const itemListRenderer = ({
    items,
    renderItem,
    menuProps,
    itemsParentRef,
  }: ItemListRendererProps<BaseSelectItem<SearchHit>>) => {
    const suggestionsByCategories = groupBy(items, 'value.type');

    if (isSearching) {
      return <LoadingSpinner centered additionalClass="p-40" />;
    }

    if (!items.length) {
      return (
        <div className={styles.suggestionsContainer}>
          <CopyText variant="copy-2" additionalClass="text-gray-4">
            {i18n.noResults}
          </CopyText>
        </div>
      );
    }

    return (
      <Menu {...menuProps} ulRef={itemsParentRef} className={styles.menu}>
        {Object.keys(suggestionsByCategories).map(type => {
          const suggestionsByCategory = suggestionsByCategories[type as keyof typeof suggestionsByCategories];

          return (
            <>
              {/* keyboard navigation is not working with MenuDivider, TODO: uncomment if fixed
              <MenuDivider className={styles.searchCategoryWrapper} title={i18n[type as keyof typeof i18n]} /> */}
              {suggestionsByCategory.map(renderItem)}
            </>
          );
        })}
      </Menu>
    );
  };

  return (
    <SelectControlUncontrolled<BaseSelectItem<SearchHit>>
      onQueryChange={setSearchTerm}
      inputComponent={inputComponent}
      options={options}
      filterable
      placeholder={i18n.searchPlaceholder}
      fullWidth
      additionalClasses={{ root: styles.searchBar, popover: styles.popover }}
      noInfo
      variant="stealth"
      loading={isSearching}
      itemListRenderer={itemListRenderer}
      optionRenderer={optionRenderer}
      noChevron
      inputRef={inputRef}
      onChange={handleChange}
      itemPredicate={() => true}
    />
  );
};
