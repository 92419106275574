import { useMutation, UseMutationResult } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericNotOkApiResponse, GenericOkApiResponse } from '../types';
import { MaintenanceWindow } from './useMaintenanceWindowsQuery';

type UseMWSubmitMutationRequest = Omit<MaintenanceWindow, 'id'> & { id?: string };

export type UseMWSubmitMutationResponse = GenericOkApiResponse & {
  id: string;
};

export const useMWSubmitMutation = <
  S extends UseMWSubmitMutationResponse = UseMWSubmitMutationResponse,
  F extends GenericNotOkApiResponse = GenericNotOkApiResponse,
  V extends UseMWSubmitMutationRequest = UseMWSubmitMutationRequest
>(
  mutationOptions = {}
): UseMutationResult<S, F, V> =>
  useMutation<S, F, V>(
    (body: V): Promise<S> => apiClient[body.id ? 'patch' : 'post'](ENDPOINTS.maintenanceWindows, body),
    mutationOptions
  );
