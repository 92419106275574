import { Filters } from '@infrastructure/redux/types';
import { RowsPerPageOption, InternalTableConfig, RowHeight } from './types';

export const defaultRowHeights: Record<RowHeight, string> = {
  small: '2rem',
  medium: '2.5rem',
  large: '3rem',
};

export const defaultRowHeightsPx: Record<RowHeight, number> = {
  small: 32,
  medium: 40,
  large: 48,
};

export const rowHeightMap: Record<RowHeight, number> = {
  small: 0,
  medium: 1,
  large: 2,
};

export const DEFAULT_ROWS_PER_PAGE_OPTIONS: RowsPerPageOption[] = [
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '200', value: 200 },
  { label: '500', value: 500 },
];

export const DEFAULT_CELL_WIDTH = 150;
export const MIN_CELL_WIDTH = 80;
export const defaultCheckboxCellWidth = 34;

export const DEFAULT_PAGINATION = {
  page: 1,
  rowsPerPage: 50,
  rowCount: 0,
};

export const STORABLE_DEFAULT_TABLE_CONFIG = {
  rowHeight: 'medium',
  page: 1,
  rowsPerPage: 50,
};

export const STORABLE_CONFIG_PROPS = ['rowHeight', 'page', 'rowsPerPage', 'sort', 'sortBy'];

export const DEFAULT_TABLE_CONFIG: InternalTableConfig = {
  paginated: true,
  rowHeight: 'medium',
  rowHeightToggleable: true,
  columnsToggleable: true,
  sortable: false,
  hasToolbar: true,
  selectable: true,
  resizable: true,
  ...DEFAULT_PAGINATION,
  rowsPerPageOptions: [...DEFAULT_ROWS_PER_PAGE_OPTIONS],
  stretchable: true,
  filtersInPopup: true,
};

export const DEFAULT_FILTERS: Filters = {
  generalFilter: '',
  discreteFilters: {},
};
