import { TopologyMapWidgetSubConfig } from '@redux/widgetPage/types';
import { merge } from 'lodash';
import colors from '@theme/colors';

const getConfig = ({ showEntityLabels }: TopologyMapWidgetSubConfig) => {
  const baseConfig = {
    animation: false, // IMPORTANT: no animation whatsoever (see layoutAnimation below)
    tooltip: {
      show: false,
    },
    series: [
      {
        type: 'graph',
        animation: false,
        layout: 'force',
        draggable: false,
        roam: true, // whether to enable mouse zooming and translating
        nodeScaleRatio: 0.8,
        scaleLimit: {
          min: 0.2,
          max: 3,
        },
        symbol: 'circle',
        symbolSize: 24,
        edgeSymbol: ['none', 'arrow'],
        edgeSymbolSize: [0, 8],
        autoCurveness: true,
        label: {
          show: showEntityLabels,
          position: 'bottom',
          color: colors.dark,
          fontSize: 10,
          borderColor: colors.blue.gray[3],
          borderRadius: 77,
          borderType: 'solid',
          borderWidth: 1,
          padding: [4, 16],
          backgroundColor: colors.blue.gray.background,
        },
        labelLayout: {
          hideOverlap: true,
        },
        edgeLabel: {
          show: false,
        },
        force: {
          repulsion: 200,
          edgeLength: 100,
          // this has to be false, or we won't be able to switch from force to none and keep the positions
          layoutAnimation: false,
        },
        emphasis: {
          scale: false,
          label: {
            show: showEntityLabels,
          },
        },
      },
    ],
  };

  const echartsConfig = merge(baseConfig) as echarts.EChartsOption;

  return {
    echartsConfig,
  };
};

export default getConfig;
