import React, { useMemo } from 'react';
import { TableConfig } from '@components/common/Table';
import { ItemWithId } from '@utils/misc';
import { DetailsPanel, DetailsPanelProps } from './DetailsPanel';
import { RemoteStoredTable, RemoteStoredTableProps } from '@components/common/Table/RemoteStoredTable';

export type TablePageLayoutProps<T extends ItemWithId = ItemWithId> = {
  tableProps: RemoteStoredTableProps<T>;
  detailsPanelProps?: DetailsPanelProps;
};

export function TablePageLayout<T extends ItemWithId = ItemWithId>({
  tableProps,
  detailsPanelProps,
}: TablePageLayoutProps<T>) {
  const finalTableConfig: Partial<TableConfig> = useMemo(
    () => ({
      selectable: true,
      sortable: true,
      hasMenu: true,
      hasToolbar: true,
      rowHeight: 'small',
      paginated: true,
      ...tableProps.config,
    }),
    [tableProps.config]
  );

  return (
    <div className="flex flex-row w-full h-full min-w-0 min-h-0">
      <div className="w-full min-w-0">
        <RemoteStoredTable<T> {...tableProps} config={finalTableConfig} />
      </div>
      {detailsPanelProps && <DetailsPanel {...detailsPanelProps} />}
    </div>
  );
}
