import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { z } from 'zod';
import ENDPOINTS from '../endpoints';
import { apiClient } from '../ApiClient';
import logger from '@infrastructure/logging/logger';
import { zodErrorToString } from '@utils/type';
import { BatchRunDetails, BatchRunStatus } from '@infrastructure/push-api/useBatchRun.schema';

const statusStateSchema = z.enum(['running', 'completed', 'error']);

const jobSchema = z.object({
  parent: z.string().optional(),
  startTime: z.number(),
  endTime: z.number().optional(),
  state: statusStateSchema,
  entity: z.string(),
  entity_id: z.string(),
  session_id: z.string(),
});

const responseSchema = z.object({
  id: z.number(),
  entities: z.array(jobSchema),
  pending_count: z.number(),
  entity_count: z.number(),
  running_count: z.number(),
  completed_count: z.number(),
  error_count: z.number(),
  aborted_count: z.number(),
  startTime: z.number(),
  endTime: z.number().optional(),
  user: z.string(),
  script: z.string(),
});

type BatchByIdRequest = {
  batchId: string;
  entity_names?: boolean; // If true, entity names are also added to the entity list
  entities?: 'all' | 'failed' | 'none';
};
type BatchByIdResponse = z.infer<typeof responseSchema>;

const summaryToBatchStatus = (summary: BatchRunDetails['summary']): BatchRunStatus => {
  if (summary.errors > 0) {
    return 'error';
  }
  if (summary.aborted > 0) {
    return 'aborted';
  }
  if (summary.completed === summary.total) {
    return 'completed';
  }
  return 'running';
};

const batchByIdResponseToBatchRunDetails = (response: BatchByIdResponse): BatchRunDetails => {
  const summary = {
    running: response.running_count,
    total: response.entity_count,
    waiting: response.pending_count,
    completed: response.completed_count,
    errors: response.error_count,
    aborted: response.aborted_count,
  };
  return {
    status: summaryToBatchStatus(summary),
    startTime: response.startTime,
    endTime: response.endTime ?? null,
    progress: response.entities.reduce(
      (acc, entity) => ({
        ...acc,
        [entity.entity_id]: {
          state: entity.state,
          start_time: entity.startTime,
          end_time: entity.endTime,
          entity: entity.entity_id,
          name: entity.entity,
          session_id: entity.session_id,
        },
      }),
      {}
    ),
    summary,
    script: response.script,
  };
};

export const useBatchByIdQuery = (
  params: BatchByIdRequest,
  queryOptions: UseQueryOptions<BatchRunDetails, Error> = {}
): UseQueryResult<BatchRunDetails, Error> => {
  const endpoint = ENDPOINTS.batchById;
  return useQuery(
    [endpoint, params],
    () =>
      apiClient
        .get<BatchByIdResponse>(endpoint, params)
        .then(response => {
          try {
            return responseSchema.parse(response);
          } catch (err: any) {
            const message = zodErrorToString(err);
            logger.error(message);
            throw new Error(message);
          }
        })
        .then(batchByIdResponseToBatchRunDetails),
    // @ts-ignore - I don't get what TS and react-query want from me here
    queryOptions
  );
};
