import React, { FC, useCallback, useContext } from 'react';
// import { RouteLabelsLayer } from './RouteLabelsLayer';
import { RouteDirLayer } from './RouteDirLayer';
import { RouteLinesLayer } from './RouteLinesLayer';
import { MapboxMap, MapLayerMouseEvent } from 'react-map-gl';
import { MapContext } from 'react-map-gl/dist/esm/components/map';
import { FQDNId } from '@components/common/Map/types';

export type RoutesLayerProps = {
  id: string;
  sourceId: string;
  sourceLayerId: string;
  beforeId?: string;
  onHover?: (e: MapLayerMouseEvent) => void;
};

export const RoutesLayer: FC<RoutesLayerProps> = ({ id, onHover, ...props }) => {
  const map: MapboxMap = useContext(MapContext).map.getMap();

  // we hide all labels by default and show only when corresponding route is hovered
  const handleHover = useCallback(
    (e: MapLayerMouseEvent) => {
      const featureId = e.features?.[0]?.id;
      if (featureId) {
        const fqdnId: FQDNId = { source: props.sourceId, id: featureId.toString(), sourceLayer: props.sourceLayerId };
        const hoverState = map.getFeatureState(fqdnId)?.hover;
        map.setFeatureState(fqdnId, { hover: !hoverState });
      }
      onHover?.(e);
    },
    [map, props.sourceId, props.sourceLayerId, onHover]
  );

  return (
    <>
      <RouteLinesLayer id={id} {...props} onHover={handleHover} />
      <RouteDirLayer id={`${id}-dir`} {...props} />
      {/* <RouteLabelsLayer id={`${id}-labels`} {...props} /> */}
    </>
  );
};
