import React, { forwardRef, MouseEventHandler, PropsWithChildren, ReactNode, SyntheticEvent } from 'react';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import c from 'classnames';
import styles from './Button.module.scss';

export type ButtonVariant =
  | 'link'
  | 'fill'
  | 'fillBlueLight'
  | 'fillBlueDark'
  | 'fillRedDark'
  | 'fillOutline'
  | 'outline'
  | 'outlineActive'
  | 'stealth'
  | 'stealthRed';

export type ButtonProps = {
  variant?: ButtonVariant;
  onClick?: MouseEventHandler | ((event?: SyntheticEvent<HTMLElement>) => void);
  disabled?: boolean;
  ariaLabel?: string;
  type?: 'button' | 'submit' | 'reset';
  testid?: string;
  id?: string;
  additionalClass?: string;
  loading?: boolean;
  fullWidth?: boolean;
  size?: 's' | 'm' | 'l';
  children: ReactNode;
};

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(function button(
  {
    children,
    variant = 'fill',
    onClick,
    disabled,
    ariaLabel,
    loading = false,
    type = 'button',
    testid,
    id,
    additionalClass,
    fullWidth,
    size = 'm',
  },
  ref
) {
  const dataTestId = testid ?? ariaLabel;

  return (
    <button
      onClick={onClick}
      className={c(
        styles.button,
        styles[variant],
        {
          [styles.loading]: loading,
          [styles.fullWidth]: fullWidth,
          [styles.small]: size === 's',
          [styles.medium]: size === 'm',
          [styles.large]: size === 'l',
        },
        additionalClass
      )}
      disabled={disabled || loading}
      // eslint-disable-next-line react/button-has-type
      type={type}
      aria-label={ariaLabel ?? type}
      data-testid={dataTestId ? dataTestId : undefined}
      id={id}
      ref={ref}
    >
      {loading ? <LoadingSpinner centered additionalClass={styles.loadingSpinner} /> : null}
      {children ? <span className={styles.label}>{children}</span> : null}
    </button>
  );
});

export default Button;
