import React, { useState } from 'react';
import c from 'classnames';
import useTextSnippets from '@services/useTextSnippets';
import CopyText from '@components/typography/CopyText';
import Popover from '@components/common/Popover';
import IconButton from '@components/common/IconButton';
import HelpMenu from '../HelpMenu';
import styles from './style.module.scss';
import { ReleaseBadge } from '@components/connected/ReleaseBadge';

type Props = {
  small?: boolean;
  title?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};

const Help: React.FC<Props> = ({ title, onOpen, onClose, small }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { help } = useTextSnippets('help');

  const onOpening = () => {
    setOpen(true);
    onOpen?.();
  };

  const onClosing = () => {
    setOpen(false);
    onClose?.();
  };

  return (
    <Popover
      position={small ? 'bottom-left' : 'bottom'}
      content={<HelpMenu />}
      additionalClass={styles.helpPopover}
      noArrows
      onOpening={onOpening}
      onClosing={onClosing}
      usePortal={false}
    >
      <div className={styles.help}>
        <IconButton
          ariaLabel="help-menu"
          onClick={() => {}}
          icon="Help"
          size="s"
          additionalClass={small ? styles.smallIcon : ''}
        />
        {title && (
          <CopyText variant="copy-4" additionalClass={c(styles.title, open && styles.active)}>
            {help}
          </CopyText>
        )}
        <ReleaseBadge />
      </div>
    </Popover>
  );
};

export default Help;
