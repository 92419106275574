import { MVTLayer, MVTLayerProps } from '@components/common/Map';
import React, { FC, useMemo } from 'react';
import * as x from '@utils/map';
import { LayerSpecification } from 'maplibre-gl';

export type EntityIconsLayerProps = Omit<MVTLayerProps, 'type'>;

export const EntityIconsLayer: FC<EntityIconsLayerProps> = props => {
  const layout = useMemo(
    () =>
      ({
        'icon-image': x.ternary(x.ifProp('icon'), x.prop('icon'), `default-device`),
        'icon-allow-overlap': true,
        'icon-rotation-alignment': 'map',
      } as LayerSpecification['layout']),
    []
  );

  return <MVTLayer {...props} type="symbol" layout={layout} />;
};
