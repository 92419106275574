import { UseQueryOptions } from 'react-query';
import { z } from 'zod';
import { useValidatedQuery } from '@services/useValidatedQuery';
import QUERY_CONFIGS from '../query-configs';
import ENDPOINTS from '../endpoints';
import { PaginatedQueryRequest } from './usePaginatedQuery';

export const configParamsSchema = z.object({
  protocol: z.string().optional(),
  agent: z.string().optional(),
  password: z.string(),
  host: z.string().optional(),
  id: z.string().optional(),
  type: z.string().optional(),
  user: z.string().optional(),
  entityid: z.string(),
});

export const configParamsResponseSchema = z.object({
  result: z.array(configParamsSchema),
  total: z.number(),
});

export type ConfigParamsRequest = PaginatedQueryRequest & {
  agent_id?: string;
  host?: string;
};

export type ConfigParams = z.infer<typeof configParamsSchema>;
export type ConfigParamsResponse = z.infer<typeof configParamsResponseSchema>;

export const useDeviceConfigParamsByIdQuery = <T extends ConfigParamsResponse = ConfigParamsResponse>(
  queryParams: ConfigParamsRequest,
  queryOptions: UseQueryOptions<T, Error> = {}
) => {
  return useValidatedQuery<T, Error>(ENDPOINTS.deviceConfigParamsById, queryParams, configParamsResponseSchema, {
    ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
    ...queryOptions,
  });
};
