import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import { AppDispatch, useAppDispatch } from '@infrastructure/redux/store';
import { ForgotPasswordCodeSchema } from '@components/pages/ForgotPassword/ForgotPasswordForm/ForgotPasswordCodeForm.schema';
import { ForgotPasswordSchema } from '@components/pages/ForgotPassword/ForgotPasswordForm/ForgotPasswordForm.schema';
import { GenericApiResponse } from '@infrastructure/api/types';
import { updateToken } from '@redux/auth';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';

export function onForgotPasswordCodeSuccess(
  dispatch: AppDispatch,
  data: ForgotPasswordCodeValidationResponse,
  variables: ForgotPasswordCodeSchema,
  context?: any
): void;
export function onForgotPasswordCodeSuccess(dispatch: AppDispatch, accountInfo: ForgotPasswordCodeValidationResponse) {
  // we received a temporary token, which we'll use to grab password policy settings for realm
  dispatch(updateToken({ token: accountInfo.token, isTemporary: true }));
  apiClient.setToken(accountInfo.token);

  // other than setting the above, we don't need to do anything - the query set up under the base layout
  // for grabbing user info will immediately know if a token was assigned (user "logged in") and will grab what we need
}

export type ForgotPasswordCodeHandlerSettings = { noDefaultSuccess?: boolean };
export type ForgotPasswordCodeValidationResponse = GenericApiResponse & { token: string };
export type ForgotPasswordCodeParams = ForgotPasswordCodeSchema & ForgotPasswordSchema;

export const useForgotPasswordCodeHandler = <
  T extends ForgotPasswordCodeValidationResponse = ForgotPasswordCodeValidationResponse,
  P extends ForgotPasswordCodeParams = ForgotPasswordCodeParams
>(
  { noDefaultSuccess }: ForgotPasswordCodeHandlerSettings = { noDefaultSuccess: false },
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> => {
  const dispatch = useAppDispatch();

  return useMutation<T, Error, P>(
    (forgotPasswordParams): Promise<T> => apiClient.post<T, P>(ENDPOINTS.forgotPasswordCode, forgotPasswordParams),
    {
      ...mutationOptions,
      onSuccess: (...successParams) => {
        if (!noDefaultSuccess) {
          onForgotPasswordCodeSuccess(dispatch, ...successParams);
        }

        void mutationOptions.onSuccess?.(...successParams);
      },
    }
  );
};
