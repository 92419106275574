import type { Action } from '@reduxjs/toolkit';
import { EMPTY, Observable } from 'rxjs';
import { concatMap, filter, withLatestFrom } from 'rxjs/operators';
import { Epic, StateObservable } from 'redux-observable';
import { AppState } from '../store';
import { updateQueryString } from '@services/useHistory';

import issuesSlice from '@redux/issues';

const MONITORED_SLICES = [issuesSlice.name];

export const updateViewEpic: Epic = (actions$: Observable<Action>, state$: StateObservable<AppState>) =>
  actions$.pipe(
    filter(action => {
      const [sliceName, actionName] = action.type.split('/');
      return MONITORED_SLICES.includes(sliceName) && ['changeView'].includes(actionName);
    }),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
      const [sliceName] = action.type.split('/');
      updateQueryString({ view: state[sliceName].activeView });
      return EMPTY;
    })
  );
