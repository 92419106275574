import { UseQueryOptions } from 'react-query';
import { z } from 'zod';
import { useValidatedQuery } from '@services/useValidatedQuery';
import QUERY_CONFIGS from '../query-configs';
import ENDPOINTS from '../endpoints';
import { ResponseError } from './common/errorSchema';
import { PaginatedQueryRequest } from './usePaginatedQuery';

const configSchema = z.object({
  time: z.number(),
  config: z.string(),
});

const responseSchema = z.object({
  result: z.array(configSchema).optional(),
  // total is not viable on this endpoint, since data comes from a logstore
  // total: z.number().optional(),
});

export type DeviceConfigRequest = PaginatedQueryRequest & {
  entityId: string;
  latest?: boolean;
  start?: string;
  end?: string;
};

export type DeviceConfig = z.infer<typeof configSchema>;
export type DeviceConfigResponse = z.infer<typeof responseSchema>;

export const useDeviceConfigHistoryQuery = <T extends DeviceConfigResponse = DeviceConfigResponse>(
  params: DeviceConfigRequest,
  queryOptions: UseQueryOptions<T, ResponseError> = {}
) => {
  return useValidatedQuery<T, ResponseError>(ENDPOINTS.deviceConfigHistory, params, responseSchema, {
    ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, ResponseError>),
    ...queryOptions,
  });
};
