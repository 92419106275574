import { ItemRendererProps } from '@blueprintjs/select';
import c from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { MenuItem } from '@components/common/Menu';
import { BaseSelect, BaseSelectItem, BaseSelectProps } from './BaseSelect';
import styles from './Select.module.scss';
import Icon, { IconNames } from '@components/common/Icon';
import useTextSnippets from '@services/useTextSnippets';

export type SelectProps<ItemType extends BaseSelectItem = BaseSelectItem> = Omit<
  BaseSelectProps<ItemType>,
  'optionRenderer'
> & {
  addCreateNew?: boolean;
  onCreateNew?: () => void;
  createNewIcon?: IconNames | false;
  createNewLabel?: string;
  inPlace?: boolean;
  matchTargetWidth?: boolean;
  doNotDismissPopover?: boolean;
  optionsTestIdPrefix?: string;
  optionsCreateNewTestId?: string;
  optionRenderer?: BaseSelectProps<ItemType>['optionRenderer'];
};

export function Select<ItemType extends BaseSelectItem = BaseSelectItem>({
  addCreateNew = false,
  onCreateNew,
  createNewIcon,
  createNewLabel,
  inPlace,
  options,
  doNotDismissPopover,
  optionsTestIdPrefix,
  optionsCreateNewTestId,
  matchTargetWidth = true,
  ...props
}: SelectProps<ItemType>) {
  const selectI18n = useTextSnippets('select');

  const optionRenderer = useCallback(
    (option: BaseSelectItem, { handleClick, modifiers }: ItemRendererProps) => {
      if (option.value === '__create-new') {
        return (
          <MenuItem
            additionalClass={styles.createNewItem}
            key={option.value}
            icon={createNewIcon === false ? undefined : <Icon name={createNewIcon || 'Add'} />}
            text={createNewLabel || selectI18n.defaultAddNewLabel}
            active={false}
            onClick={onCreateNew}
            shouldDismissPopover={false}
            testid={optionsCreateNewTestId}
          />
        );
      }

      return (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          selected={option.value === props.value?.value}
          key={option.value}
          onClick={handleClick}
          text={option.label}
          shouldDismissPopover={!doNotDismissPopover}
          testid={optionsTestIdPrefix ? `${optionsTestIdPrefix}-${option.value}` : undefined}
        />
      );
    },
    [
      props.value?.value,
      doNotDismissPopover,
      optionsTestIdPrefix,
      createNewIcon,
      createNewLabel,
      selectI18n.defaultAddNewLabel,
      onCreateNew,
      optionsCreateNewTestId,
    ]
  );

  const popoverProps = useMemo(() => {
    const config = { matchTargetWidth, ...props.popoverProps };

    if (inPlace) {
      config.usePortal = true;
      config.small = true;
      config.preserveTarget = true;
      config.additionalClass = c(styles.popover, styles.smallPopover, config.additionalClass);
    }

    return config;
  }, [props.popoverProps, inPlace, matchTargetWidth]);

  const finalOptions = useMemo(() => {
    if (addCreateNew) {
      return options.concat({
        label: createNewLabel || selectI18n.defaultAddNewLabel,
        value: '__create-new',
      } as ItemType);
    }

    return options;
  }, [addCreateNew, createNewLabel, options, selectI18n.defaultAddNewLabel]);

  return (
    <BaseSelect<ItemType>
      options={finalOptions}
      {...props}
      popoverProps={popoverProps}
      optionRenderer={props.optionRenderer ?? optionRenderer}
    />
  );
}
