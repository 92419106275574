import { hasAtLeastOneProperty } from '@utils/entity';
import { EntityDetails } from '@redux/inventoryPage/types';
import { ChartDataSource, DashboardWidget, WidgetConfig, WidgetType } from '@redux/widgetPage';
import { Screen } from '@services/useBreakpoint';
import { Layout, Layouts } from 'react-grid-layout';
import { ENABLE_PAGE_PLOTNAME_CHARTS } from '@constants/entity';
import { uniqBy } from 'lodash';
import { Metric } from '@infrastructure/api/BaseNClient/useMetricsQuery';
import { Metricset } from '@infrastructure/api/BaseNClient/useMetricsetsQuery';

type WidgetTemplate = { meta: Partial<Record<Screen, Layout>>; widget: DashboardWidget };

type GetWidgetProps = {
  w?: number;
  minW?: number;
  h?: number;
  minH?: number;
  widget: WidgetConfig;
};

type GridLayoutWidgets = { layouts: Layouts; widgets: DashboardWidget[] };

const getWidget = ({ widget, ...meta }: GetWidgetProps): WidgetTemplate => ({
  meta: {
    tablet: {
      i: widget.id!,
      x: 0,
      y: 0,
      w: 12,
      h: 15,
      ...meta,
    },
    xxs: {
      i: widget.id!,
      x: 0,
      y: 0,
      w: 12,
      h: 15,
      ...meta,
    },
  },
  widget: {
    id: widget.id!,
    config: widget,
  },
});

const pagePlotnameToChartWidget = (entity: EntityDetails, filterBy?: string[] | null) => {
  return Object.keys(entity.plots).reduce((arr: WidgetTemplate[], plotname: string) => {
    const page = entity.plots[plotname];

    return !filterBy?.length || filterBy.includes(page)
      ? arr.concat(
          getWidget({
            widget: {
              id: `${plotname}`.replace(/\s+/g, '-').toLowerCase(),
              name: `${page}: ${plotname}`,
              type: 'timeseries',
              config: {
                source: {
                  entityId: entity.id,
                  page,
                  plotname,
                },
              },
            },
          })
        )
      : arr;
  }, []);
};

const metricToChartWidget = (entity: EntityDetails, filterBy?: string[] | null) => {
  const templates =
    entity.metricsets?.reduce((arr: WidgetTemplate[], metricset: Metricset) => {
      return !filterBy?.length || filterBy.includes(metricset.name)
        ? arr.concat(
            metricset.metrics?.map((metric: Metric) => {
              return getWidget({
                widget: {
                  id: metric.id,
                  name: `${metricset.name}: ${metric.name}`,
                  type: 'timeseries',
                  config: {
                    source: {
                      entityId: entity.id,
                      metricId: metric.id,
                    },
                  },
                },
              });
            }) ?? []
          )
        : arr;
    }, []) ?? [];

  return templates;
};

export const getInfoWidget = (entity: EntityDetails) =>
  getWidget({
    w: 12,
    minW: 3,
    h: 10,
    widget: {
      id: 'entity-details',
      name: 'Information',
      type: 'entity' as WidgetType,
      config: {
        source: {
          entityId: entity.id,
        },
      },
    },
  });

export const getInterfacesWidget = (entity: EntityDetails) =>
  getWidget({
    h: 15,
    w: 12,
    widget: {
      id: 'interfaces',
      name: 'Interfaces',
      type: 'interfaces' as WidgetType,
      config: {
        source: {
          entityId: entity.id,
        },
      },
    },
  });

export const getWidgets = (
  entity: EntityDetails,
  filterBy: string[] | null = null,
  pagePlotnameBased = ENABLE_PAGE_PLOTNAME_CHARTS
): GridLayoutWidgets => {
  const widgets = pagePlotnameBased
    ? pagePlotnameToChartWidget(entity, filterBy)
    : metricToChartWidget(entity, filterBy);

  // convert to the weird format required by GridLayout
  return {
    layouts: widgets.reduce((obj, { meta }: any) => {
      Object.keys(meta).forEach((key: string) => {
        if (!obj[key]) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = [];
        }
        obj[key].push(meta[key]);
      });

      return obj;
    }, {} as Layouts),

    widgets: widgets.map(({ widget }) => widget),
  };
};

export const getWidgetsCombined = (entity: EntityDetails, filterBy: string[] | null = null): GridLayoutWidgets => {
  const widgets = uniqBy(
    [...metricToChartWidget(entity, filterBy), ...pagePlotnameToChartWidget(entity, filterBy)],
    'widget.config.name'
  );

  // convert to the weird format required by GridLayout
  return {
    layouts: widgets.reduce((obj, { meta }: any) => {
      Object.keys(meta).forEach((key: string) => {
        if (!obj[key]) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = [];
        }
        obj[key].push(meta[key]);
      });

      return obj;
    }, {} as Layouts),

    widgets: widgets.map(({ widget }) => widget),
  };
};

export const getWidgetsByGroup = (entity: EntityDetails, filterBy: string[] | null = null) => {
  const { widgets } = getWidgetsCombined(entity, filterBy);

  return widgets.reduce((groups: Record<string, DashboardWidget[]>, widget: DashboardWidget) => {
    const name = widget.config.name?.split(/:\s*/)[0];

    if (!name) {
      return groups;
    }

    if (!groups[name]) {
      // eslint-disable-next-line no-param-reassign
      groups[name] = [];
    }

    groups[name].push(widget);
    return groups;
  }, {});
};

export const getOptionsFromWidgets = (widgets: DashboardWidget[]) => {
  const metricsetsCount = widgets.reduce((count: Record<string, number>, widget: DashboardWidget) => {
    const name = widget.config.name?.split(/:\s*/)[0];
    if (!name || ['Information', 'Interfaces'].includes(name)) {
      return count;
    }

    if (!count[name]) {
      // eslint-disable-next-line no-param-reassign
      count[name] = 0;
    }

    count[name]++;
    return count;
  }, {});

  return Object.keys(metricsetsCount).map(name => ({
    label: name,
    value: name,
    metrics: metricsetsCount[name],
  }));
};

export const getTimeSeriesPropsBySource = (entity: EntityDetails, source: ChartDataSource): DashboardWidget | null => {
  const { widgets } = getWidgetsCombined(entity);

  return (
    widgets.find(({ config }: DashboardWidget) => {
      const widgetType = config?.type as WidgetType;
      const widgetSource = config.config?.source as ChartDataSource;

      return (
        widgetType === 'timeseries' &&
        ((source.metricId && widgetSource?.metricId === source.metricId) ||
          (source.plotname && widgetSource?.plotname === source.plotname))
      );
    }) ?? null
  );
};
