import {
  ChartWidgetSubConfig,
  MapWidgetSubConfig,
  TopologyMapWidgetSubConfig,
  WidgetType,
  WidgetSubConfig,
  PieWidgetSubConfig,
  TimeSeriesWidgetSubConfig,
  IssueManagerWidgetSubConfig,
} from './types';
import { AggregateMethod } from '@components/widgets/PieWidget/types';
import chartConfigs from '@components/widgets/ChartWidget/configs';
import { SamplingMethod } from '@components/widgets/TimeSeriesWidget/types';
import { DEFAULT_ZOOM, MAP_CENTER } from '@constants/map';

export const DEFAULT_PALETTE = 'BaseN';

export const DEFAULT_MAP_WIDGET_CONFIG: MapWidgetSubConfig = {
  zoom: DEFAULT_ZOOM,
  latitude: MAP_CENTER[0],
  longitude: MAP_CENTER[1],
  source: null,
  mode: 'LIGHT',
  showZoomControl: true,
  showEntityTooltip: true,
};

export const DEFAULT_CHART_WIDGET_CONFIG: ChartWidgetSubConfig = {
  type: 'line',
  source: null,
  echartsConfig: chartConfigs.line().echartsConfig,
  showZoomSlider: false,
  horizontalLegend: false,
  palette: DEFAULT_PALETTE,
  hasToolbar: true,
  showSummary: false,
};

export const DEFAULT_PIE_WIDGET_CONFIG: PieWidgetSubConfig = {
  source: null,
  echartsConfig: chartConfigs.pie().echartsConfig,
  showAggregateMethodSwitch: false,
  aggregateMethod: AggregateMethod.AVG,
  horizontalLegend: false,
  palette: DEFAULT_PALETTE,
  showWatermark: true,
  hasToolbar: true,
  showSummary: false,
};

export const DEFAULT_TIMESERIES_WIDGET_CONFIG: TimeSeriesWidgetSubConfig = {
  type: 'line',
  source: null,
  echartsConfig: chartConfigs.line().echartsConfig,
  showZoomSlider: false,
  legendType: 'auto',
  palette: DEFAULT_PALETTE,
  showSamplingMethodSwitch: false,
  showMaintenanceWindows: true,
  showLogbookNotes: true,
  showAlerts: true,
  samplingMethod: SamplingMethod.LargestTriangleThreeBucket,
  showWatermark: true,
  hasToolbar: true,
  showSummary: false,
  showDateRange: true,
};

export const DEFAULT_TOPOLOGY_MAP_WIDGET_CONFIG: TopologyMapWidgetSubConfig = {
  showEntityLabels: true,
  areNodesDraggable: false,
};

export const DEFAULT_ISSUE_MANAGER_WIDGET_CONFIG: IssueManagerWidgetSubConfig = {
  showExtendedToolbar: true,
  showDetailsInSidePanel: true,
};

export const DEFAULT_CONFIG_BY_TYPE: Partial<Record<WidgetType, WidgetSubConfig>> = {
  chart: DEFAULT_CHART_WIDGET_CONFIG,
  pie: DEFAULT_PIE_WIDGET_CONFIG,
  map: DEFAULT_MAP_WIDGET_CONFIG,
  topology_map: DEFAULT_TOPOLOGY_MAP_WIDGET_CONFIG,
  timeseries: DEFAULT_TIMESERIES_WIDGET_CONFIG,
  issue_manager: DEFAULT_ISSUE_MANAGER_WIDGET_CONFIG,
};
