import React, { useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import c from 'classnames';
import { maybePrettify } from '@utils/json';
import { highlightSpecialChars, drawSelection, highlightActiveLine, EditorView } from '@codemirror/view';
import { EditorState } from '@codemirror/state';
import { history } from '@codemirror/history';
import { foldGutter } from '@codemirror/fold';
import { indentOnInput } from '@codemirror/language';
import { lineNumbers, highlightActiveLineGutter } from '@codemirror/gutter';
import { bracketMatching } from '@codemirror/matchbrackets';
import { closeBrackets } from '@codemirror/closebrackets';
import { highlightSelectionMatches } from '@codemirror/search';
import { autocompletion } from '@codemirror/autocomplete';
import { rectangularSelection } from '@codemirror/rectangular-selection';
import { defaultHighlightStyle } from '@codemirror/highlight';
import { json } from '@codemirror/lang-json';
import { keywordDecorator } from './MatchDecoratorPlugin';

import styles from './CodeEditor.module.scss';

export type CodeEditorProps = {
  additionalClass?: string;
  wordWrap?: boolean;
  value?: string;
  onChange?: (doc: EditorState['doc']) => void;
  autoFocus?: boolean;
  readOnly?: boolean;
  scrollToLine?: number;
  highlightMatches?: string[];
};

export type CodeEditorHandle = {
  readonly value?: string;
};

export const CodeEditor = forwardRef<CodeEditorHandle, CodeEditorProps>(
  (
    {
      value,
      additionalClass,
      wordWrap = true,
      readOnly = false,
      onChange,
      autoFocus = false,
      scrollToLine,
      highlightMatches,
    },
    ref
  ) => {
    const parentRef = useRef<HTMLDivElement | null>(null);
    const editorRef = useRef<EditorView | null>(null);

    useEffect(() => {
      if (parentRef.current) {
        const initialDoc = maybePrettify(value ?? '');

        const state = EditorState.create({
          doc: initialDoc,
          extensions: [
            lineNumbers(),
            highlightActiveLineGutter(),
            highlightSpecialChars(),
            history(),
            foldGutter(),
            drawSelection(),
            EditorState.allowMultipleSelections.of(true),
            indentOnInput(),
            defaultHighlightStyle.fallback,
            bracketMatching(),
            closeBrackets(),
            autocompletion(),
            rectangularSelection(),
            highlightActiveLine(),
            highlightSelectionMatches(),
            json(),
            wordWrap && EditorView.lineWrapping,
            readOnly && EditorState.readOnly.of(true),
            !!onChange &&
              EditorView.updateListener.of(v => {
                if (v.docChanged) {
                  onChange(v.state.doc);
                }
              }),
            !!highlightMatches?.length && keywordDecorator(highlightMatches),
          ].filter(Boolean) as any,
        });

        editorRef.current = new EditorView({
          state,
          parent: parentRef.current,
        });

        if (scrollToLine && scrollToLine > 0) {
          // not sure why this is required (since initialization is sync?),  but otherwise it doesn't work
          setTimeout(() => {
            if (editorRef.current) {
              const pos = editorRef.current.state.doc.line(scrollToLine).from;
              editorRef.current.dispatch({
                effects: EditorView.scrollIntoView(pos, { y: 'start' }),
              });
            }
          }, 0);
        }

        if (autoFocus) {
          editorRef.current.focus();
        }
      }

      return () => {
        if (editorRef.current) {
          editorRef.current.destroy();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (editorRef.current) {
        const currentValue = editorRef.current.state.doc.toString();
        if (currentValue !== value) {
          editorRef.current.dispatch({
            changes: {
              from: 0,
              to: currentValue.length,
              insert: value,
            },
          });
        }
      }
    }, [value]);

    useImperativeHandle(ref, () => ({
      get value() {
        return editorRef.current?.state.doc.toString();
      },
    }));

    return <div className={c(styles.codeEditor, additionalClass)} ref={parentRef} />;
  }
);

CodeEditor.displayName = 'ForwardRefCodeEditor';
