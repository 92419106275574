import plugin from 'tailwindcss/plugin';
import typography from './typography';
import { HEADER_HEIGHT, PAGE_PLUS_HEADER_HEIGHT } from './constants';

export default [
  plugin(({ addComponents, theme }) => {
    const flexContainer = {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 0%',
      padding: `${theme('spacing.12')}`,
      height: 'auto',
      width: '100%',
      position: 'relative',
      '@screen tablet': {
        padding: `${theme('spacing.16')}`,
      },
    };

    addComponents({
      '.stacked-component': {
        margin: '0 auto',
        padding: `0 ${theme('spacing.15')}`,
        width: '100%',
        maxWidth: theme('screens.large'),
      },
      '.flex-container': flexContainer,
      '.flex-full': {
        display: 'flex',
        flex: '1 1 0%',
        height: '100%',
      },
      '.hbox': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minWidth: 0,
        minHeight: 0,
      },
      '.vbox': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minWidth: 0,
        minHeight: 0,
      },
      '.page-container': {
        width: '100%',
        display: 'flex',
        minHeight: PAGE_PLUS_HEADER_HEIGHT,
        flexDirection: 'column',
        flex: '1 1 0%',
        height: '100%',
        padding: 0,
        overflow: 'hidden',
        backgroundColor: theme('colors.white'),
      },
      '.page-body': {
        minHeight: 0,
        overflow: 'auto',
        backgroundColor: theme('colors.blue.gray.background'),
        padding: `${theme('spacing.16')} ${theme('spacing.24')}`,
        ...flexContainer,
      },
      '.page-header': {
        height: HEADER_HEIGHT,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 24px',
        borderStyle: 'solid',
        borderWidth: '0 0 1px 0',
        borderColor: theme('colors.blue.gray.6'),
      },
      '.mobile-only': {
        display: 'flex',
        '@screen tablet': {
          display: 'none',
        },
      },
      '.no-mobile': {
        display: 'none',
        '@screen tablet': {
          display: 'flex',
        },
      },
    });
  }),

  plugin(({ addComponents, theme }) => {
    addComponents(typography.plugins(theme));
  }),
];
