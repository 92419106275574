import { useWizardContext } from '@components/common/form/Wizard/WizardContext';
import { Box } from '@components/common/Box';
import CopyText from '@components/typography/CopyText';
import Button from '@components/common/Button';
import React from 'react';
import { RunScriptSchema } from '../RunScriptWizard.schema';
import { AuthForm } from './AuthForm';
import { WizardStepProps, useWizardHandle } from '@components/common/form/Wizard';
import { initialAuthValues, authFormSchema } from './ReviewStep.schema';
import IconButton from '@components/common/IconButton';
import { replacePlaceholders } from '@utils/url';

import styles from './ReviewStep.module.scss';

const previewVariables = (script: string, vars: Record<string, any> = {}) =>
  replacePlaceholders(script, vars, /\${(\w+)}/gi)?.[0];

export const ReviewStep = ({ innerRef, onSubmit, onChange }: WizardStepProps) => {
  useWizardHandle(innerRef, onSubmit);
  const { valuesRef } = useWizardContext();
  const { targets, script } = valuesRef.current as RunScriptSchema;

  const [showTargets, setShowTargets] = React.useState(false);
  const [shouldPreviewVars, setShouldPreviewVars] = React.useState(false);

  const handleTargetsToggle = React.useCallback(() => {
    setShowTargets(state => !state);
  }, []);

  const handlePreviewVars = React.useCallback(() => {
    setShouldPreviewVars(prev => !prev);
  }, []);

  // NOTE: many entities can be selected as targets, but the current assumption is that if auth is successful for
  // one of them, it will be successful for all of them
  return (
    <div className={styles.reviewStep}>
      <div className={styles.authCredentials}>
        <AuthForm onSuccess={onChange} entityId={targets.entities[0].id} />
      </div>

      {!!targets.entities?.length && (
        <Box header="Targets" additionalClass="mb-16">
          <CopyText variant="copy-4">
            <span className="mr-8">{`${targets.entities?.length} ${
              targets.entities?.length === 1 ? 'entity' : 'entities'
            } selected`}</span>
            <Button variant="link" size="s" onClick={handleTargetsToggle}>{`${
              showTargets ? 'Hide all' : 'Show all'
            }`}</Button>
          </CopyText>

          {showTargets && (
            <div className={styles.targets}>
              {targets.entities?.map(entity => (
                <CopyText variant="copy-6" key={entity?.name}>
                  {entity?.name}
                </CopyText>
              ))}
            </div>
          )}
        </Box>
      )}

      <Box
        header={
          <>
            Script{' '}
            <IconButton
              icon={shouldPreviewVars ? 'PasswordHidden' : 'PasswordVisible'}
              title={shouldPreviewVars ? 'Show placeholders' : 'Replace variables'}
              tooltipProps={{ targetProps: { className: 'ml-auto' } }}
              size="s"
              onClick={handlePreviewVars}
            />
          </>
        }
        additionalClass={styles.scriptBox}
        noPadding
      >
        <pre className={styles.script}>
          {shouldPreviewVars ? previewVariables(script.script, valuesRef.current.variables) : script.script}
        </pre>
      </Box>
    </div>
  );
};

ReviewStep.defaultProps = {
  validationSchema: authFormSchema,
  initialValues: initialAuthValues,
};
