import { defaultBreakpoints } from '@redux/dashboards/configs';
import { RawDashboard, Dashboard, NewDashboard, NewRawDashboard, DashboardWithDetails } from './types';
import { isEmpty } from 'lodash';
import { DashboardWidget } from '@redux/widgetPage';
import { addLayoutToLayouts, fixInvalidLayouts } from './dashboard-utils';
import { TEMP_WIDGET_LAYOUT } from '.';

export function mapRawToDashboard(dashboard: RawDashboard): Dashboard {
  const baseMeta = Object.keys(defaultBreakpoints).reduce(
    (baseLayouts, bp) => ({
      ...baseLayouts,
      [bp]: [],
    }),
    {}
  ) as Dashboard['meta'];

  if (!dashboard.widgets?.length) {
    return { ...dashboard, meta: baseMeta };
  }

  // @ts-ignore
  const newMeta = dashboard.widgets.reduce((layouts: Dashboard['meta'], { id }: DashboardWidget) => {
    const widgetLayouts = dashboard.meta[id];
    if (isEmpty(widgetLayouts)) {
      // there was a period when UI contained a bug that would cause creation of widgets with empty meta,
      // without this workaround such widgets would be either ignored or when not ignored would cause dashboard
      // layout manager to hang and halt whole browser
      return addLayoutToLayouts(layouts, { ...TEMP_WIDGET_LAYOUT, i: id });
    }

    Object.keys(widgetLayouts).forEach(bpKey => {
      // for a widget that was added through a dashboard update, `i` can still be set to TEMP_WIDGET_ID
      layouts[bpKey].push({ ...widgetLayouts[bpKey], i: id }); // make sure that meta contains proper ID
    });

    return layouts;
  }, baseMeta as Dashboard['meta']);

  return { ...dashboard, meta: fixInvalidLayouts(newMeta) };
}

export function mapDashboardToRaw(
  dashboard: DashboardWithDetails | Dashboard | NewDashboard
): RawDashboard | NewRawDashboard {
  const rawDashboardMeta: RawDashboard['meta'] = {};

  Object.keys(dashboard.meta).forEach(bpKey => {
    const layouts = dashboard.meta[bpKey];

    layouts.forEach(layout => {
      if (!rawDashboardMeta[layout.i]) {
        rawDashboardMeta[layout.i] = {};
      }

      rawDashboardMeta[layout.i][bpKey] = layout;
    });
  });

  return {
    ...dashboard,
    meta: rawDashboardMeta,
    widgets: dashboard.widgets,
  };
}
