import { ChartConfigGenerator } from '../types';
import echarts from 'echarts';
import { mergeAndConcatArrays } from '@utils/misc';
import { presetToPalette } from '@components/common/form/PalettePicker/paletteUtils';
import { zip, max as mathMax, min as mathMin } from 'lodash';
import { dateRangeTitle, formatValue, isHorizontalLegend } from '@utils/chart';
import { TimeSeriesWidgetSubConfig } from '@redux/widgetPage/types';
import { SupportedEvents } from '@components/common/Chart/types';

import watermarkImage from '@assets/images/watermark.png';

import styles from '../ChartWidget.module.scss';

const getConfig: ChartConfigGenerator<TimeSeriesWidgetSubConfig> = props => {
  const { channels = [], unit, options, timezone, dateRange } = props ?? {};
  const events: SupportedEvents = {};

  // TODO maybe estimate potential width of items in the legend
  const hasChannels = !!channels?.length;
  const { colors } = presetToPalette(options?.palette ?? 'BaseN');

  // we need to know min/max before-hand unfortunately, 'cause otherwise we cannot render an adequate chart, when values
  // in the response contain only '-' characters, that BE uses to denote an absense of the value
  const min = hasChannels ? mathMin(channels.map(({ summaries }) => summaries.min)) : 0;
  const max = hasChannels ? mathMax(channels.map(({ summaries }) => summaries.max)) : 1;

  let legendTableHeight = 0;
  let dateRangeTitleHeight = options?.showDateRange ? 30 : 5;

  const horizontalLegend = isHorizontalLegend(props);

  let gridLeft = 20;
  let gridRight = options?.showSummary || horizontalLegend ? 0 : 130;
  let gridTop = options?.showSummary || !horizontalLegend ? 25 : 45;
  let gridBottom = (options?.showZoomSlider ? 40 : 0) + dateRangeTitleHeight + legendTableHeight;

  const baseConfig = {
    animation: false,
    title: options?.showDateRange && dateRange ? dateRangeTitle(dateRange, timezone, { bottom: gridBottom - 30 }) : {},
    color: colors,
    grid: {
      left: gridLeft,
      right: gridRight,
      top: gridTop,
      bottom: gridBottom,
      containLabel: true,
      borderColor: '#ccc',
    },
    legend: {
      show: !options?.showSummary,
      type: 'scroll',
      padding: 0,
      right: 5,
      top: 10,
      icon: 'circle',
      tooltip: {
        show: true,
        trigger: 'item',
      },
      // make sure that legend only contains channels that are actually present in the data
      // (we might be adding custom series onto the chart)
      data: channels?.map(({ name }) => name),
    },
    // IMPORTANT: toolbox needs to be defined and shown for takeGlobalCursor/dataZoomSelect action become functional
    toolbox: {
      show: true,
      top: -50, // negative offset allows us to move whole toolbox off the canvas
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
      },
    },
    tooltip: {
      trigger: 'axis',
      // triggerOn: 'none',
      axisPointer: {
        type: 'cross',
      },
      renderMode: 'html',
      className: styles.timeseriesTooltip,
      appendToBody: true, // escape boundaries of the Widget container
      enterable: true, // won't be able to scroll the overflowing content otherwise
      valueFormatter: channels?.length ? (value: number | string) => formatValue(value, unit, 'unit') : null,
    },
    xAxis: {
      type: 'time',
      min: dateRange?.[0]?.getTime() ?? 'dataMin',
      max: dateRange?.[1]?.getTime() ?? 'dataMax',
      axisLabel: {
        hideOverlap: true,
      },
    },
    yAxis: {
      name: unit ?? '',
      nameLocation: 'center',
      nameGap: 30, // gap is measured from the grid lines for some reason, not the end of label
      nameTextStyle: {
        fontStyle: 'italic',
        fontSize: 10,
      },
      type: 'value',
      axisLabel: {
        rotate: 90,
        hideOverlap: true,
      },
      // so basically if there are no channels or channels are there, but do not contain a single adequate value (e.g. all
      // dashes for example), then we use artificial min/max, otherwise we don't want to show it at all ('cause it's ugly)
      min: !hasChannels || !min ? 0 : null,
      max: !hasChannels || !max ? 1 : null,
    },
  };

  const seriesConfig = {
    series: channels?.map(({ name, values, times }, idx) => ({
      type: 'line',
      name,
      data: zip(times ?? [], values ?? []),
      showSymbol: true,
      symbol: 'circle',
      symbolSize: 1,
      sampling: options?.samplingMethod,
      step: 'middle',
    })),
  };

  const legendConfig = horizontalLegend
    ? {
        legend: {
          orient: 'horizontal',
          itemGap: 20,
        },
      }
    : {
        legend: {
          orient: 'vertical',
          width: 100,
          textStyle: {
            width: 90,
            overflow: 'truncate',
          },
        },
      };

  const zoomSliderConfig = options?.showZoomSlider
    ? {
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
          {
            show: true,
            type: 'slider',
            start: 0,
            end: 100,
            left: gridLeft - 15, // see nameGap
            right: gridRight,
            bottom: gridBottom - 60,
            showDetail: false,
          },
        ],
      }
    : {
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
        ],
      };

  const watermarkConfig = options?.showWatermark
    ? {
        graphic: {
          type: 'image',
          right: 50,
          bottom: 23,
          style: {
            image: watermarkImage,
          },
        },
      }
    : {};

  const echartsConfig = mergeAndConcatArrays(
    baseConfig,
    seriesConfig,
    legendConfig,
    zoomSliderConfig,
    watermarkConfig
  ) as echarts.EChartsOption;

  return {
    echartsConfig,
    events,
  };
};

export default getConfig;
