import { useField } from 'formik';
import React, { useCallback } from 'react';
import TimeInput, { TimeInputProps } from './TimeInput';

import styles from './TimeInput.module.scss';

export type TimeFieldProps = TimeInputProps & {
  id?: string;
  name: string;
  label?: string;
  required?: boolean;
};

export const TimeField: React.FC<TimeFieldProps> = ({ id, label, name, required = false, ...props }) => {
  const [field, , helpers] = useField<number>({ name });

  const handleChange = useCallback(
    (newDate: Date) => {
      helpers.setValue(newDate.getTime());
    },
    [helpers]
  );

  const value = field.value ? new Date(field.value) : null;

  return (
    <div className={styles.timeField}>
      {label && (
        <label htmlFor={id ?? name}>
          {label}
          {required ? <span className="text-red-base"> *</span> : null}
        </label>
      )}
      <TimeInput showArrowButtons={false} {...props} onChange={handleChange} value={value} />
    </div>
  );
};
