import { DownloadPopup, DownloadPopupProps } from '@components/connected/DownloadButton';
import { API_URL } from '@config';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { toQueryStr } from '@utils/url';
import React from 'react';

export type InventoryDownloadPopupProps = Omit<DownloadPopupProps, 'url'> & {
  params: Record<string, any>;
};

export const InventoryDownloadPopup: React.FC<InventoryDownloadPopupProps> = ({ params, ...props }) => {
  const url = `${API_URL}/${ENDPOINTS.getEntities}/?${toQueryStr(params)}`;

  return <DownloadPopup {...props} url={url} fileName="inventory" />;
};
