import React, { FC, useState } from 'react';
import { Input, InputProps } from './Input';

export type PasswordProps = Omit<InputProps, 'actionIcon'>;

export const Password: FC<PasswordProps> = props => {
  const [show, setShow] = useState(false);

  const handleAction = () => {
    setShow(!show);
  };

  return (
    <Input
      {...props}
      type={show ? 'text' : 'password'}
      onAction={handleAction}
      actionIcon={show ? 'PasswordVisible' : 'PasswordHidden'}
      additionalClass="min-w-5xs"
    />
  );
};
