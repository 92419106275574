import React, { FC, PropsWithChildren } from 'react';
import c from 'classnames';

import styles from './SubLevel.module.scss';

export type SubLevelProps = {
  additionalClass?: string;
  depth?: number;
};

export const SubLevel: FC<PropsWithChildren<any>> = ({ additionalClass, children }) => {
  return <div className={c(styles.subLevel, additionalClass)}>{children}</div>;
};
