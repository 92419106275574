import { useTableConfig } from '../TableConfigContext';
import { TableColumn, TableMouseEventHandler } from '../types';
import c from 'classnames';
import { TableCell, TableCheckboxCell } from './index';
import { CypressTestsData, CypressUtilityType } from '@cypress';
import React from 'react';

import styles from './TableRow.module.scss';

export type TableRowProps<T extends object = object> = {
  columns: TableColumn<T>[];
  item: T;
  isActive?: boolean;
  isHighlighted?: boolean;
  isSelected?: boolean;
  id: string;
  idx: number;
  onSelect?: (item: T) => void;
  onCellClick?: TableMouseEventHandler<T>;
  onContextMenu?: (e: React.MouseEvent<HTMLElement>, item: T) => void;
  additionalClass?: string;
};

export function TableRow<T extends object = object>({
  item,
  isActive,
  isSelected,
  isHighlighted,
  id,
  idx,
  columns,
  onSelect,
  onCellClick,
  onContextMenu,
  additionalClass,
}: TableRowProps<T>) {
  const { selectable, bordered, resizable, rowHeight, stretchable } = useTableConfig();
  const utilityTableTestDataConfig = CypressTestsData[CypressUtilityType.UTILITY_TABLE];
  const isClickable = !!onCellClick;

  return (
    <tr
      key={id}
      className={c(
        styles.tableRow,
        selectable && styles.selectableRow,
        isSelected && styles.selectedRow,
        isHighlighted && styles.highlightedRow,
        isClickable && styles.clickableRow,
        additionalClass
      )}
    >
      {selectable && (
        <TableCheckboxCell<T>
          onToggle={() => {
            onSelect?.(item);
          }}
          item={item}
          checked={isSelected}
          active={isActive}
        />
      )}

      {columns.map((col, colIdx) => (
        <TableCell<T>
          key={col.id ?? colIdx}
          rowHeight={rowHeight}
          bordered={bordered}
          resizable={resizable}
          selected={isSelected}
          active={!selectable && colIdx === 0 && isActive}
          column={col}
          item={item}
          colKey={col.id}
          rowIdx={idx}
          colIdx={colIdx}
          onClick={onCellClick}
          onContextMenu={onContextMenu}
          testid={
            col.id === 'name'
              ? `${utilityTableTestDataConfig.testIds.columns.nameCellPrefix}-${idx}`
              : col.id === 'comment'
              ? `${utilityTableTestDataConfig.testIds.columns.commentCellPrefix}-${idx}`
              : undefined
          }
        />
      ))}
      {!stretchable && <td className={styles.stretcher} />}
    </tr>
  );
}
