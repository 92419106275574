import React, { useMemo, FC } from 'react';
import { SizeMe } from 'react-sizeme';
import c from 'classnames';
import { Responsive } from 'react-grid-layout';
import ResizeHandle from '@components/layout/GridLayout/components/ResizeHandle';
import { DASHBOARD_CONFIGS, widgetDragHandleClass } from '@redux/dashboards/configs';
import { ControlledGridLayoutProps } from './types';

import styles from './styles.module.scss';

const GridLayout: FC<ControlledGridLayoutProps> = ({
  items,
  itemRenderer,
  additionalClass,
  onLayoutChange,
  onBreakpointChange,
  onWidthChange,
  layouts,
  breakpoints,
  cols,
  gridProps = {},
  locked,
  noResize,
}) => {
  const gridBreakpoints = useMemo(() => breakpoints || DASHBOARD_CONFIGS.defaultBreakpoints, [breakpoints]);
  const gridCols = useMemo(() => cols || DASHBOARD_CONFIGS.defaultCols, [cols]);

  const gridChildren = useMemo(
    () =>
      items.map(item => (
        <div key={item.i} className={c(styles.itemContainer)}>
          {itemRenderer(item)}
        </div>
      )),
    [items, itemRenderer]
  );

  return (
    <SizeMe noPlaceholder>
      {({ size }) => (
        <Responsive
          {...DASHBOARD_CONFIGS.defaultGridConfig}
          draggableHandle={`.${widgetDragHandleClass}`}
          breakpoints={gridBreakpoints}
          cols={gridCols}
          // number below doesn't matter, what matters is that it should larger than 0 to avoid grid-layout from going crazy
          width={(size.width as number) || 800}
          className={c(styles.layout, additionalClass)}
          layouts={layouts}
          onWidthChange={onWidthChange}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          isDraggable={!locked}
          isResizable={!noResize && !locked}
          resizeHandle={<ResizeHandle />}
          {...gridProps}
        >
          {gridChildren}
        </Responsive>
      )}
    </SizeMe>
  );
};

export default GridLayout;
