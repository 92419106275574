import Card from '@components/common/Card/Card';
import CopyText from '@components/typography/CopyText';
import { useNavigate } from 'react-router-dom';

import styles from './ManagementCard.module.scss';

type Props = {
  title: string;
  description: string;
  path: string;
};

export const ManagementCard: React.FC<Props> = ({ title, description, path }) => {
  const navigate = useNavigate();

  return (
    <Card onClick={() => navigate(path)} additionalClass={styles.managementCard}>
      <CopyText variant="copy-1">{title}</CopyText>
      <CopyText variant="copy-4" additionalClass="mt-8">
        {description}
      </CopyText>
    </Card>
  );
};
