import { FormikErrors, FormikValues } from 'formik';
import { ValidationError } from 'yup';
import { ZodError } from 'zod';
import { isZodError } from './type';

// Formik expects key/value pairs of field name and list of errors for it
export function toFormikError<T extends FormikValues = FormikValues>(
  error: ValidationError | ZodError
): FormikErrors<T> {
  if (isZodError(error)) {
    return (error as ZodError).issues.reduce(
      (acc, { path, message }) => ({ ...acc, [path.join('.')]: [message] }),
      {} as FormikErrors<T>
    );
  } else {
    // `inner` is an array of ValidationErrors throw earlier in the validation chain. When the `abortEarly`
    // option is false this is where you can inspect each error thrown
    return (error as ValidationError).inner.reduce(
      (acc, { path, errors }) => (path ? { ...acc, [path]: errors } : acc),
      {} as FormikErrors<T>
    );
  }
}
