import React from 'react';
import { ScriptPicker } from './ScriptPicker';
import { Script } from '@infrastructure/api/BaseNClient/useScriptsQuery';
import CopyText from '@components/typography/CopyText';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import Button from '@components/common/Button';
import Icon from '@components/common/Icon';
import { ScriptDetails, useScriptByIdQuery } from '@infrastructure/api/BaseNClient/useScriptByIdQuery';

import styles from './ScriptTemplates.module.scss';

type PickScriptStepProps = {
  value?: string | Script | null;
  onSelect?: (script: ScriptDetails) => void;
};

export const ScriptTemplates = ({ value, onSelect }: PickScriptStepProps) => {
  const [selectedTemplate, setSelectedTemplate] = React.useState<Script | null>(
    value && typeof value !== 'string' ? value : null
  );

  const query = useScriptByIdQuery(selectedTemplate?.id ?? '', { enabled: !!selectedTemplate?.id });

  return (
    <div className={styles.scriptTemplates}>
      <ScriptPicker value={value} onChange={setSelectedTemplate} filterable />

      {!!selectedTemplate && (
        <div className="w-full gap-8 vbox">
          <div className="w-full hbox">
            <CopyText variant="copy-3" additionalClass="mr-16">
              Preview
            </CopyText>
            <CopyText variant="copy-6" additionalClass="truncate">
              {selectedTemplate.name}
            </CopyText>
          </div>
          {query.isLoading ? (
            <LoadingSpinner size="m" />
          ) : query.data ? (
            <>
              <pre className={styles.script}>{query.data?.script}</pre>
              <Button
                additionalClass="ml-auto mt-16"
                size="s"
                variant="fillOutline"
                onClick={() => onSelect?.(query.data!)}
              >
                Add Script <Icon name="ArrowRight" additionalClass="ml-8" size="s" />
              </Button>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};
