import { OptionsTab } from './components/OptionsTab';
import { WidgetSetup } from '@components/pages/WidgetPage/types';
import { TimeSeriesWidget } from '@components/widgets/TimeSeriesWidget';
import { DEFAULT_TIMESERIES_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { UniversalDataSourcePicker } from '../ChartWidget/components/UniversalDataSourcePicker';

export default {
  type: 'timeseries',
  subTypes: ['line', 'bar'],
  icon: 'Timeseries',
  i18nTitle: 'timeSeries',
  OptionsTab,
  DataSourcePicker: UniversalDataSourcePicker,
  Widget: TimeSeriesWidget,
  defaultSubConfig: DEFAULT_TIMESERIES_WIDGET_CONFIG,
} as WidgetSetup;
