import {
  TopologyEntity,
  TopologyRoute,
  useEntitiesGeoJSONQuery,
} from '@infrastructure/api/BaseNClient/useEntitiesGeoJSONQuery';
import React, { useMemo, useState, forwardRef, useImperativeHandle, useRef, useEffect, useCallback } from 'react';
import { Chart, ChartHandle } from '@components/common/Chart';
import colors from '@theme/colors';
import { MapDataSource, WidgetHandle } from '@redux/widgetPage/types';
import c from 'classnames';
import { DEFAULT_TOPOLOGY_MAP_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import CopyText from '@components/typography/CopyText';
import { flattenPaths } from '@utils/url';
import { ChartClickEvent, ChartMousMoveEvent } from '@components/common/Chart/types';
import chroma from 'chroma-js';
import { WidgetComponentProps } from '@components/pages/WidgetPage/types';
import getConfig from './configs/graph';
import { debounce, endsWith, merge } from 'lodash';
import { getPositions, topoToGeoConnection } from '@utils/chart';
import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import Headline from '@components/typography/Headline';
import { MapWidgetPopup, MapWidgetPopupProps } from '../MapWidget/components/MapWidgetPopup';

import styles from './TopologyMap.module.scss';

export type TopologyMapProps = WidgetComponentProps;

export const TopologyMap = forwardRef<WidgetHandle, TopologyMapProps>(({ config, additionalClass }, ref) => {
  const chartRef = useRef<ChartHandle | null>(null);
  const [isChartRendered, setIsChartRendered] = useState(false);

  const resolvedConfig = useMemo(
    () => ({
      ...DEFAULT_TOPOLOGY_MAP_WIDGET_CONFIG,
      ...config,
    }),
    [config]
  );
  const mapDataSource = resolvedConfig.source as MapDataSource;
  const isValidSource = !!mapDataSource?.customerIds?.length || !!mapDataSource?.entityIds?.length;

  const [popup, setPopup] = useState<MapWidgetPopupProps | null>(null);

  const query = useEntitiesGeoJSONQuery({
    ids: mapDataSource?.entityIds?.join(','),
    customer_ids: mapDataSource?.customerIds?.join(','),
    ...(mapDataSource?.dataFilter ? flattenPaths(mapDataSource.dataFilter, ['data']) : undefined),
  });

  const { echartsConfig } = useMemo(() => {
    if (query.data) {
      const { entities, routes } = query.data;

      const curvenessRegistry: Record<string, number> = {};

      const entityToNode = ({ id, name, alert_color }: TopologyEntity, position?: { x: number; y: number }) => {
        const isFixed = !!position;

        const itemStyle = {
          shadowColor: 'rgba(0, 0, 0, 0.1)',
          shadowBlur: 5,
          color: colors.alert[alert_color ?? 'gray'],
          borderColor: colors.white,
          borderWidth: 1,
          borderType: 'solid',
        };

        const params = {
          id,
          name,
          itemStyle,
          emphasis: {
            itemStyle,
          },
        };

        return !isFixed ? params : { ...params, fixed: true, x: position?.x, y: position?.y };
      };

      const routeToLink = (route: TopologyRoute, rtl = false) => {
        const { id, from, to, alert_color, width } = route;
        const color = colors.alert[alert_color ?? 'gray'] ?? colors.alert.gray;

        const fqdnId = `${from}-${to}${rtl ? `-rtl` : ''}`;
        const curveness = curvenessRegistry[fqdnId] ?? 0.1;

        curvenessRegistry[fqdnId] = curveness + 0.05;

        const lineStyle = {
          color,
          width: width || 1,
          curveness,
          shadowBlur: 3,
          shadowColor: chroma(color).alpha(0.5).css(),
          opacity: 1,
        };

        return {
          id: rtl ? `${id}-rtl` : id,
          source: rtl ? to : from,
          target: rtl ? from : to,
          lineStyle,
          empasis: {
            lineStyle,
          },
          // IMPORTANT: technically, this is not a valid property, but we use it to pass a route info to the popup,
          // alternative solution would be to keep a local map of route ids to route objects, but this is more convenient
          // TODO: maybe revise if this ever breaks due to changes in echarts internals
          properties: topoToGeoConnection(route, rtl),
        };
      };

      const nodes = entities?.map(entity => entityToNode(entity, resolvedConfig.positions?.[entity.id]));
      const links = routes
        ?.map(route => routeToLink(route))
        .concat(
          routes.filter(({ bidirectional }: TopologyRoute) => bidirectional).map(route => routeToLink(route, true))
        );

      return merge(
        getConfig({
          showEntityLabels: resolvedConfig.showEntityLabels,
        }),
        {
          echartsConfig: {
            series: [
              {
                data: nodes,
                links,
              },
            ],
          },
        }
      );
    }
    return {
      echartsConfig: {},
    };
  }, [query.data, resolvedConfig.positions, resolvedConfig.showEntityLabels]);

  // we start as layout=force to let the nodes be positioned automatically and then switch to layout=none to
  // make them properly draggable
  useEffect(() => {
    if (isChartRendered) {
      const nodes = chartRef.current?.getConfig()?.series?.[0].data ?? [];
      const positions = getPositions(chartRef.current?.getModel()?.getSeriesByIndex(0)?.getData());

      if (nodes.length) {
        chartRef.current?.setConfig(
          merge(echartsConfig, {
            series: [
              {
                layout: 'none',
                draggable: resolvedConfig.areNodesDraggable,
                data: nodes.map((node: any) => ({ ...node, fixed: true, ...positions?.[node.id] })),
              },
            ],
          }),
          { notMerge: false }
        );
      }
    }
  }, [echartsConfig, isChartRendered, resolvedConfig.areNodesDraggable]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFinished = useCallback(
    debounce(() => {
      const { layout: currentLayout, data: nodes } = chartRef.current?.getConfig()?.series?.[0] ?? {};

      if (currentLayout === 'force' && nodes?.length) {
        setIsChartRendered(true);
      }
    }, 100),
    []
  );

  const handleClick = useCallback(({ data, dataType, event }: ChartClickEvent) => {
    if (dataType === 'node') {
      setPopup({
        x: event.event.x,
        y: event.event.y,
        type: 'entity',
        childProps: {
          name: data.name,
          entityId: data.id,
        },
      });
    }
  }, []);

  const handleMouseMove = useCallback(({ data, dataType, event }: ChartMousMoveEvent) => {
    if (dataType === 'edge') {
      setPopup({
        x: event.event.x,
        y: event.event.y,
        type: 'route',
        childProps: {
          route: data.properties,
          rtl: endsWith(data.id, 'rtl'),
        },
      });
    }
  }, []);

  const handleClosePopup = useCallback(() => {
    setPopup(null);
  }, []);

  useImperativeHandle(ref, () => ({
    getConfig: () => ({
      ...resolvedConfig,
      areNodesDraggable: false, // we don't nodes to be draggable beyond the edit mode
      positions: getPositions(chartRef.current?.getModel().getSeriesByIndex(0).getData()),
    }),
  }));

  return isValidSource ? (
    <>
      <Chart
        ref={chartRef}
        loading={query.isLoading}
        echartsConfig={echartsConfig}
        additionalClass={c(styles.topologyChart, additionalClass)}
        onClick={handleClick}
        onFinished={handleFinished}
        onMouseMove={handleMouseMove}
      />
      {popup && <MapWidgetPopup key={popup.type} {...popup} onClose={handleClosePopup} />}
    </>
  ) : (
    <div className={styles.noDataSource}>
      <div className={styles.noDataSourceWrapper}>
        <NoElementsContainer
          icon="EmptyTopoSource"
          title={
            <Headline centered variant="headline-6">
              Please select a <span className="text-blue-ocean">Data Source</span>
            </Headline>
          }
          description={<CopyText variant="copy-4">Data Source &gt; Customer</CopyText>}
        />
      </div>
    </div>
  );
});
