import { useMemo, useState } from 'react';
import { getColsForBP } from '@redux/dashboards/configs';
import { capitalize } from '@utils/string';
import useResizeEffect from './useResizeEffect';
import screens from '@theme/screens';

export type Screens = typeof screens;
export type Screen = keyof Screens;

const isBrowser = typeof window !== 'undefined';

export default function useBreakpoint(noResize?: boolean): {
  screens: Screens;
  screenWidths: Record<Screen, number>;
  cols: Record<Screen, number>;
  breakpoints: Record<string, boolean>;
  getCurrentBreakpoint: () => Screen;
} {
  const screenWidths = useMemo(() => {
    const bps: Record<string, number> = {};

    Object.keys(screens).forEach(screen => {
      bps[screen] = parseInt(screens[screen as Screen].replace('px', ''), 10);
    });

    return bps;
  }, []);

  const cols = useMemo(() => {
    const bps: Record<string, number> = {};

    Object.keys(screens).forEach(screen => {
      bps[screen] = getColsForBP(screen);
    });

    return bps;
  }, []);

  const calculateBreakpoints = () => {
    const bps: Record<string, boolean> = {};

    Object.keys(screenWidths).forEach(screenWidth => {
      bps[`is${capitalize(screenWidth)}`] = isBrowser ? window.innerWidth >= screenWidths[screenWidth] : false;
    });

    // TODO: add comment here on why xxs is always true
    bps.isXxs = true;

    return bps;
  };

  const calculatedBreakpoints = useMemo(calculateBreakpoints, [screenWidths]);

  const getCurrentBreakpoint = (): Screen => {
    let currentBreakpoint = 'tablet';
    let biggestBreakpointValue = 0;

    if (isBrowser) {
      Object.keys(screenWidths).forEach(bp => {
        const bpValue = screenWidths[bp];

        if (bpValue > biggestBreakpointValue && window.innerWidth >= bpValue) {
          biggestBreakpointValue = bpValue;
          currentBreakpoint = bp;
        }
      });
    }

    return currentBreakpoint as Screen;
  };

  const [breakpoints, setBreakpoints] = useState(() => calculatedBreakpoints);

  useResizeEffect(() => {
    if (isBrowser && !noResize) {
      setBreakpoints(calculateBreakpoints());
    }
  });

  return {
    breakpoints,
    screens,
    screenWidths,
    cols,
    getCurrentBreakpoint,
  };
}
