import React, { useState, useCallback } from 'react';
import CopyText from '@components/typography/CopyText';
import Button from '@components/common/Button';
import { Collapse } from '@blueprintjs/core';
import useTextSnippets from '@services/useTextSnippets';
import fillTemplate from '@utils/fillTemplate';
import styles from './DashboardList.module.scss';
import { Dashboard, DashboardWithDetails } from '@redux/dashboards';

type Props = {
  dashboards: (Dashboard | DashboardWithDetails)[];
};

const DashboardList: React.FC<Props> = ({ dashboards = [] }) => {
  const [open, setOpen] = useState(false);
  const dashboardsI18n = useTextSnippets('dashboards');
  const commonI18n = useTextSnippets('common');
  const onlyOneDashboard = dashboards.length === 1;

  const toggleList = useCallback(() => {
    setOpen(!open);
  }, [open]);

  if (onlyOneDashboard) {
    return (
      <CopyText variant="copy-4" ellipsis>
        {dashboards[0].name}
      </CopyText>
    );
  }

  return (
    <div className={styles.list}>
      <div className="flex items-center">
        <CopyText variant="copy-4">
          {fillTemplate(dashboardsI18n.selectedDashboards, { number: dashboards.length })}
        </CopyText>
        <Button variant="link" onClick={toggleList}>
          {open ? commonI18n.hideAll : commonI18n.showAll}
        </Button>
      </div>
      <div className={styles.nestedListContainer}>
        <Collapse isOpen={open} className="mt-4">
          <div className={styles.nestedList}>
            {dashboards.map(dashboard => (
              <CopyText variant="copy-4" key={dashboard.id} ellipsis>
                {dashboard.name}
              </CopyText>
            ))}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DashboardList;
