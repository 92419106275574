import React from 'react';
import useTextSnippets from '@services/useTextSnippets';
import { IconCard, ImageCard } from './components';
import styles from './Overview.module.scss';
import { DefaultMotion } from '@services';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
import ROUTES from '@infrastructure/routes';
import { useNavigate } from 'react-router-dom';
import overviewDashboardsImage from '@assets/images/overview-dashboards.png';
import overviewIMImage from '@assets/images/overview-issue-manager.png';
import overviewInventory from '@assets/images/overview-inventory.png';

const Overview: React.FC = () => {
  const i18n = useTextSnippets('overview');
  const navigate = useNavigate();

  React.useEffect(() => {
    document.title = `Overview - BaseN Platform`;
  }, []);

  return (
    <div className={styles.overviewPage}>
      <DefaultMotion key="overviewGrid" className={styles.introContainer}>
        <div className={styles.overviewHeader}>
          <Headline variant="headline-4" color="dark" additionalClass="text-center">
            {i18n.welcome}
          </Headline>
          <CopyText variant="copy-2" color="dark" additionalClass="text-center mt-8 tablet:mt-4">
            {i18n.welcomeDescription}
          </CopyText>
        </div>

        <div className={styles.intro}>
          <ImageCard
            onClick={() => navigate(ROUTES.dashboards)}
            image={overviewDashboardsImage}
            title={i18n.dashboards}
            description={i18n.dashboardsDescription}
          />
          <ImageCard
            onClick={() => navigate(ROUTES.issueManager)}
            image={overviewIMImage}
            title={i18n.issueManager}
            description={i18n.issueManagerDescription}
          />
          <ImageCard
            onClick={() => navigate(ROUTES.inventory)}
            title={i18n.inventory}
            description={i18n.inventoryDescription}
            image={overviewInventory}
          />
        </div>

        <CopyText variant="copy-2" color="dark" additionalClass="inline-block mt-32">
          {i18n.alreadyAvailable}
        </CopyText>

        <div className={styles.intro}>
          <IconCard icon="OverviewFavorites" title={i18n.favorites} description={i18n.favoritesDescription} />
          <IconCard
            icon="OverviewUserSettings"
            title={i18n.userSettings}
            description={i18n.userSettingsDescription}
            onClick={() => navigate(ROUTES.account)}
          />
          <IconCard
            icon="OverviewFullyResponsivePlatform"
            title={i18n.responsive}
            description={i18n.responsiveDescription}
            invertedColorScheme
          />
          <IconCard
            icon="OverviewShared"
            title={i18n.logbook}
            description={i18n.logbookDescription}
            onClick={() => navigate(ROUTES.tools)}
          />
          <IconCard
            icon="OverviewMetrics"
            title={i18n.alerts}
            description={i18n.alertsDescription}
            onClick={() => navigate(ROUTES.alerts)}
          />
        </div>

        <CopyText variant="copy-1" color="dark" additionalClass="inline-block mt-32">
          {i18n.comingSoon}
        </CopyText>

        <div className={styles.intro}>
          <IconCard icon="OverviewWidgets" title={i18n.newWidgets} description={i18n.newWidgetsDescription} />
        </div>

        <CopyText variant="copy-6" additionalClass={styles.trademark}>
          {i18n.trademark}
        </CopyText>
      </DefaultMotion>
    </div>
  );
};

export default Overview;
