import React, { useCallback } from 'react';
import ReactLinkify from 'react-linkify';

export type LinkifyProps = React.ComponentPropsWithoutRef<'a'>;

export const Linkify: React.FC<LinkifyProps> = ({ children, ...props }) => {
  const componentDecorator = useCallback(
    (decoratedHref, decoratedText, key) => (
      <a href={decoratedHref} key={key} {...props}>
        {decoratedText}
      </a>
    ),
    [props]
  );

  return <ReactLinkify componentDecorator={componentDecorator}>{children}</ReactLinkify>;
};
