import { GenericOptionsResponse } from '@infrastructure/api/types';
import { useField } from 'formik';
import { OptionsChecklist, OptionsChecklistProps } from './OptionsChecklist';

export type OptionsChecklistFieldProps = Omit<OptionsChecklistProps, 'value' | 'onChange'> & {
  name?: string;
};

const itemToLabel = (item: any): string => item?.name ?? '';

export function OptionsChecklistField<T extends GenericOptionsResponse = GenericOptionsResponse>({
  name,
  ...props
}: OptionsChecklistFieldProps) {
  const [field, , helpers] = useField<string[]>({ name: name ?? props.property });

  const handleChange = (items: T[]) => {
    helpers.setValue(items.map(item => (typeof item === 'string' ? item : itemToLabel(item))));
  };

  return <OptionsChecklist<T> {...props} {...field} onChange={handleChange} />;
}
