import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import chartsConfigs from '@components/widgets/ChartWidget/configs';
import {
  ChartWidgetType,
  ChartWidgetSubConfig,
  RouteAlerts,
  WidgetConfig,
  WidgetSubConfig,
  WidgetType,
  WidgetPageState,
} from '@redux/widgetPage/types';
import { DEFAULT_CONFIG_BY_TYPE, DEFAULT_TIMESERIES_WIDGET_CONFIG } from './constants';

const initialState: WidgetPageState = {
  isDirty: false,
  activeTab: 0,
  widgetConfig: null,
};

const widgetPageSlice = createSlice({
  name: 'widgetPage',
  initialState,
  reducers: {
    setState(state, action: PayloadAction<WidgetPageState>) {
      return action.payload;
    },

    setActiveTab(state, action: PayloadAction<number>) {
      state.activeTab = action.payload;
    },

    setConfig(state, action: PayloadAction<WidgetConfig>) {
      state.widgetConfig = action.payload;
    },

    setDefaultConfig(state, action: PayloadAction<{ type: WidgetType; subType?: string; isDraft?: boolean }>) {
      const { type, subType, isDraft = false } = action.payload;

      let config: WidgetSubConfig | null;
      switch (type) {
        case 'timeseries': {
          const chartType = (subType ?? 'line') as ChartWidgetType;
          config = {
            ...DEFAULT_TIMESERIES_WIDGET_CONFIG,
            type: chartType,
            echartsConfig: chartsConfigs[chartType]().echartsConfig,
          } as ChartWidgetSubConfig;
          break;
        }

        default: {
          config = {
            ...DEFAULT_CONFIG_BY_TYPE[type],
          };
        }
      }

      state.isDirty = true;
      state.widgetConfig = {
        id: !isDraft ? state.widgetConfig?.id : undefined, // retain id if present
        type,
        config,
      };
    },

    updateName(state, action: PayloadAction<string>) {
      if (state.widgetConfig?.config) {
        state.isDirty = true;
        state.widgetConfig.name = action.payload;
      }
    },

    updateSubConfig(state, action: PayloadAction<Partial<WidgetSubConfig>>) {
      if (state.widgetConfig) {
        state.isDirty = true;
        if (state.widgetConfig.config) {
          Object.assign(state.widgetConfig.config, action.payload);
        } else {
          state.widgetConfig.config = action.payload;
        }
      }
    },

    resetDirty(state) {
      state.isDirty = false;
    },

    reset() {
      return initialState;
    },

    setRouteAlerts(state, action: PayloadAction<RouteAlerts>) {
      state.routeAlerts = action.payload ?? [];
    },
  },
});

export const {
  updateName,
  setActiveTab,
  setDefaultConfig,
  setConfig,
  updateSubConfig,
  reset,
  resetDirty,
  setState,
  setRouteAlerts,
} = widgetPageSlice.actions;

export default widgetPageSlice;
