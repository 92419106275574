import { StripeColor } from '@utils/issue';
import React, { FC } from 'react';
import c from 'classnames';
import colors from '@theme/colors';

import styles from './StatusDot.module.scss';

export type StatusDotProps = {
  color?: StripeColor;
  size?: 'xs' | 'm' | 's' | 'l';
  additionalClass?: string;
};

export const StatusDot: FC<StatusDotProps> = ({ color = 'gray', size = 'm', additionalClass }) => (
  <div
    className={c(additionalClass, styles.statusDot, {
      [styles.extraSmall]: size === 'xs',
      [styles.small]: size === 's',
      [styles.medium]: size === 'm',
    })}
    style={{ backgroundColor: colors.alert[color] }}
  />
);
