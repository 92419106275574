import {
  InfiniteTagInputControlLegacy,
  InfiniteTagInputControlLegacyProps,
} from '@components/connected/InfiniteSelect/InfiniteTagInputControlLegacy';
import { apiClient } from '@infrastructure/api';
import { Metric, MetricsQueryRequest, MetricsQueryResponse } from '@infrastructure/api/BaseNClient/useMetricsQuery';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import React, { useCallback } from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';

export type MetricsTagInputProps = Omit<InfiniteTagInputControlLegacyProps, 'getItemsQuery' | 'itemToLabel'> & {
  metricset?: string;
};

const itemToLabel = (item: Metric) => item.id;

export function MetricsTagInput({ filterable = true, metricset, ...props }: MetricsTagInputProps) {
  const queryClient = useQueryClient();

  const getItemsQuery = useCallback(
    (start_index: number, end_index: number, filterStr?: string) => {
      const queryParams: MetricsQueryRequest = {
        start_index,
        end_index,
        metricset,
      };

      if (filterStr) {
        queryParams.general = filterStr;
      }

      return queryClient.fetchQuery(
        ['metrics', queryParams],
        () => apiClient.get<MetricsQueryResponse>(ENDPOINTS.metrics, queryParams),
        {
          ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<MetricsQueryResponse, Error>),
        }
      );
    },
    [queryClient, metricset]
  );

  return (
    <InfiniteTagInputControlLegacy<Metric>
      {...props}
      getItemsQuery={getItemsQuery}
      filterable={filterable}
      itemToLabel={itemToLabel}
    />
  );
}
