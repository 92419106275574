import useTextSnippets from '@services/useTextSnippets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { MapWidgetSubConfig, selectWidgetSubConfig, updateSubConfig } from '@redux/widgetPage';
import { noop, range, startCase } from 'lodash';
import React, { useMemo } from 'react';
import { FieldsConfig, FormBuilder } from '@components/common/form/FormBuilder';
import useSchema from './OptionsTab.schema';
import { DEFAULT_MAP_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { VECTOR_TILES } from '@components/common/Map/types';

export const OptionsTab = () => {
  const i18n = useTextSnippets('widgetPage');
  const dispatch = useAppDispatch();

  const options = (useAppSelector(selectWidgetSubConfig) as MapWidgetSubConfig) ?? DEFAULT_MAP_WIDGET_CONFIG;

  const schema = useSchema();

  const fields = useMemo(
    () =>
      [
        {
          type: 'group',
          title: 'Components',
          fields: [
            {
              type: 'toggle',
              props: {
                label: 'Zoom Control',
                name: 'showZoomControl',
              },
            },
            {
              type: 'toggle',
              props: {
                label: 'Entity Tooltip',
                name: 'showEntityTooltip',
              },
            },
          ],
        },
        {
          type: 'group',
          title: 'Viewport',
          fields: [
            {
              type: 'select',
              props: {
                label: 'Theme',
                name: 'mode',
                placeholder: 'Choose Tile Theme',
                options: Object.keys(VECTOR_TILES).map(mode => ({
                  label: startCase(mode.toLowerCase()),
                  value: mode,
                })),
                clearable: false,
              },
            },
            {
              type: 'select',
              props: {
                label: i18n.zoom,
                name: 'zoom',
                options: range(1, 15).map(zoom => ({
                  label: zoom.toString(),
                  value: zoom.toString(),
                })),
              },
            },
            // {
            //   type: 'number',
            //   label: i18n.latitude,
            //   name: 'latitude',
            //   fullWidth: false,
            // },
            // {
            //   type: 'number',
            //   label: i18n.longitude,
            //   name: 'longitude',
            // },
          ],
        },
      ] as FieldsConfig,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChange = (newOptions: MapWidgetSubConfig) => {
    dispatch(updateSubConfig(newOptions));
  };

  return (
    <FormBuilder<MapWidgetSubConfig>
      fields={fields}
      initialValues={options}
      onSubmit={noop}
      onChange={handleChange}
      validationSchema={schema}
      validateOnChange
      enableReinitialize
      additionalClass="m-10"
    />
  );
};
