import Icon from '@components/common/Icon';
import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import { RemoteStoredTable, RemoteStoredTableProps } from '@components/common/Table';
import CopyText from '@components/typography/CopyText';
import Headline from '@components/typography/Headline';
import {
  MaintenanceWindow,
  useMaintenanceWindowsQuery,
  RecurringSchedule,
  Schedule,
} from '@infrastructure/api/BaseNClient/useMaintenanceWindowsQuery';
import { DefaultMotion } from '@services/theme';
import React, { useEffect, useMemo, useState } from 'react';
import { TableColumns } from '@components/common/Table';
import { toIdMap } from '@utils/misc';
import Link from '@components/common/Link';
import ROUTES from '@infrastructure/routes';
import { fromBaseNFormat } from '@utils/mw';
import { CronString } from '@components/common/CronGenerator/components/CronString';

import styles from './UpcomingMWs.module.scss';

const getUpcomingMWsTableColumns = (): TableColumns<MaintenanceWindow> => {
  return toIdMap([
    {
      id: 'name',
      label: 'Name',
      width: 100,
      sortable: true,
      valueRenderer: (mw: MaintenanceWindow) => (
        <div className="flex flex-row items-center flex-1 truncate text-dark">
          <Link href={`${ROUTES.singleMaintenanceWindow.replace(':id', mw.id)}`} additionalClass={styles.link}>
            <CopyText variant="copy-4" additionalClass="truncate">
              {mw.name}
            </CopyText>
          </Link>
        </div>
      ),
    },
    {
      id: 'schedules',
      label: 'Schedules',
      width: 250,
      type: 'custom',
      truncated: false,
      valueRenderer: ({ times }: MaintenanceWindow) => {
        return (
          <div className="py-4">
            {times?.map((item: Schedule | RecurringSchedule, idx: number) => (
              <CronString key={idx} schedule={fromBaseNFormat(item)} />
            ))}
          </div>
        );
      },
    },
    {
      id: 'description',
      label: 'Description',
    },
    {
      id: 'reason',
      label: 'Reason',
    },
  ]);
};

export const UpcomingMWs: React.FC = () => {
  const [areLoaded, setAreLoaded] = useState(false);

  const query = useMaintenanceWindowsQuery({ future: true });

  useEffect(() => {
    setAreLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!query.isLoading && !query.isFetching]);

  const tableProps: RemoteStoredTableProps<MaintenanceWindow> = useMemo(
    () => ({
      name: 'upcomingMWs',
      items: query.data?.maintenancewindows ?? [],
      columns: getUpcomingMWsTableColumns(),
      isLoading: query.isLoading || query.isFetching,
      config: {
        sortable: false,
        hasToolbar: false,
        paginated: false,
        selectable: false,
        bordered: false,
      },
      sidebarFilters: true,
    }),
    [query.data?.maintenancewindows, query.isLoading, query.isFetching]
  );

  return (
    <DefaultMotion key="inventory" className="mb-40">
      <Headline variant="headline-6" additionalClass="mb-16 flex flex-row items-center">
        <Icon name="MaintenanceWindow" additionalClass="text-blue-ocean mr-8" size="m" />
        <span>Upcoming Maintenance</span>
      </Headline>
      {areLoaded && !query.data?.maintenancewindows.length ? (
        <NoElementsContainer
          icon="CalendarRich"
          title={<CopyText variant="copy-4">No upcoming maintenance windows.</CopyText>}
        />
      ) : (
        <RemoteStoredTable<MaintenanceWindow> {...tableProps} />
      )}
    </DefaultMotion>
  );
};
