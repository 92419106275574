import { retrieveItem, storeItem } from '@utils/storage';
import { snakeCase } from 'lodash';
import React, { useState } from 'react';
import Panel, { PanelProps } from './Panel';
import { uniqueId } from '@blueprintjs/core/lib/esm/common/utils';

export type PanelUncontrolledProps = PanelProps & {
  keepState?: boolean;
};

const PanelUncontrolled: React.FC<PanelUncontrolledProps> = ({
  active: defaultActive,
  keepState = true,
  onChange,
  ...otherProps
}) => {
  const panelSettingsId = `${
    typeof otherProps.title === 'string' ? `panel-${snakeCase(otherProps.title.toLowerCase())}` : uniqueId('panel-')
  }`;
  const [active, setActive] = useState(
    keepState ? retrieveItem<boolean>(panelSettingsId) ?? defaultActive : defaultActive
  );

  const handleToggle = (e: React.MouseEvent<HTMLElement>, state: boolean) => {
    setActive(state);
    if (keepState) {
      storeItem<boolean>(panelSettingsId, state);
    }
    onChange?.(e, state);
  };

  return <Panel {...otherProps} active={active} onChange={handleToggle} />;
};

export default PanelUncontrolled;
