import React from 'react';
import { AddEditScriptWizard } from './AddEditScriptWizard';
import { useLocation, useParams } from 'react-router-dom';
import { setDocumentTitle } from '@utils/dom';
import { useScriptByIdQuery } from '@infrastructure/api/BaseNClient/useScriptByIdQuery';
import { useLoadingContext } from 'react-router-loading';

export const AddEditScriptsPage = () => {
  const { scriptId } = useParams();
  const { state } = useLocation();
  const isEditing = !!scriptId;
  const loadingContext = useLoadingContext();

  const scriptQuery = useScriptByIdQuery(scriptId ?? '', { enabled: isEditing });

  setDocumentTitle(`Add Script`);

  React.useEffect(() => {
    if (scriptQuery.data) {
      setDocumentTitle(`Edit "${scriptQuery.data.name}" Script`);
      loadingContext.done();
    }
  }, [scriptQuery.data, loadingContext]);

  const initialValues = React.useMemo(() => {
    return {
      script: {
        name: scriptQuery.data?.name ?? '',
        description: scriptQuery.data?.description ?? '',
        script: scriptQuery.data?.script ?? '',
      },
      targets: {
        entities: scriptQuery.data?.entities ?? (state as any)?.entities ?? [],
      },
    };
  }, [scriptQuery.data, state]);

  return (
    <div className="page-container">
      {scriptQuery.isLoading ? null : <AddEditScriptWizard scriptId={scriptId} initialValues={initialValues} />}
    </div>
  );
};
