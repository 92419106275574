import { Form, SelectField, SubmitButton, TextField } from '@components/common/form';
import HorizontalSeparator from '@components/separators/HorizontalSeparator';
import CopyText from '@components/typography/CopyText';
import { ALERT_ACCESS, ALERT_PRIORITIES, ALERT_TIMES, ALERT_TYPES, ALERT_VISIBILITY } from '@constants/alert';
import { ToggleButtonField } from './ToggleButtonField';
import { arrToOptions } from '@utils/misc';
import { alertOverrideRequestSchema } from '@infrastructure/api/BaseNClient/useAlertOverrideMutation';
import { AlertOverride } from '@infrastructure/api/BaseNClient/useAlertOverrideQuery';
import { FormikHelpers } from 'formik';

type BulkOverrideFormProps = {
  alertOverride: AlertOverride;
  onFormSubmit?: (newValues: AlertOverride, formikHelpers: FormikHelpers<AlertOverride>) => void | Promise<any>;
};

export const BulkOverrideForm = ({ alertOverride, onFormSubmit }: BulkOverrideFormProps) => {
  return (
    <Form initialValues={alertOverride} validationSchema={alertOverrideRequestSchema} onSubmit={onFormSubmit}>
      <HorizontalSeparator noSpacing additionalClass="pt-16" />

      <CopyText variant="copy-4">Alert properties</CopyText>

      <ToggleButtonField
        label="Alert type"
        name="alert"
        options={arrToOptions(ALERT_TYPES)}
        additionalClass="my-16"
        size="m"
      />

      <div className="gap-16 hbox">
        <TextField
          label="Green"
          name="green"
          placeholder="0.0"
          additionalClasses={{ textControl: 'w-full' }}
          type="number"
        />
        <TextField
          label="Red"
          name="red"
          placeholder="0.0"
          additionalClasses={{ textControl: 'w-full' }}
          type="number"
        />
      </div>

      <div className="gap-16 hbox">
        <SelectField
          label="Alert activation time"
          name="active_time"
          options={arrToOptions(ALERT_TIMES)}
          fullWidth
          clearable
        />
        <SelectField
          label="Alert nodata time"
          name="time_nodata"
          options={arrToOptions(ALERT_TIMES)}
          fullWidth
          clearable
        />
      </div>

      <SelectField label="Priority" name="priority" options={arrToOptions(ALERT_PRIORITIES)} fullWidth clearable />

      <HorizontalSeparator noSpacing additionalClass="pt-16" />

      <CopyText variant="copy-4" additionalClass="mb-16">
        Comment
      </CopyText>

      <div className="gap-16 hbox">
        <SelectField label="Access" name="comment.access" options={arrToOptions(ALERT_ACCESS)} fullWidth />
        <SelectField label="Visibility" name="comment.visibility" options={arrToOptions(ALERT_VISIBILITY)} fullWidth />
      </div>

      <TextField
        multiline
        name="comment.text"
        placeholder="Leave a small comment about this modification"
        additionalClasses={{ textControl: 'w-full' }}
      />

      <div className="mt-16 hbox">
        <SubmitButton additionalClass="ml-auto" variant="fillBlueDark" disableOnClean={false}>
          Apply
        </SubmitButton>
      </div>
    </Form>
  );
};
