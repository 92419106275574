import { COMMON_DATA_KEYS } from '@constants/entity';
import useTextSnippets from '@services/useTextSnippets';
import Button from '@components/common/Button';
import Control from '@components/common/form/Control/Control';
import { BaseSelectItem, SelectControl } from '@components/common/form/Select';
import IconButton from '@components/common/IconButton';
import CopyText from '@components/typography/CopyText';
import React, { FC, useState } from 'react';
import { OptionsControl } from '@components/connected/OptionsControl';
import { OptionType } from '@components/connected/OptionsControl/types';
import { InfiniteListItem } from '@components/connected/InfiniteSelect/InfiniteList';

import styles from './CustomFilters.module.scss';

type KeyValue = {
  key: string;
  value: any;
};

const keyToOption = (key: string) => ({ label: key, value: key });
const keyOptions = COMMON_DATA_KEYS.map(keyToOption);
const toKeyValues = (arr: KeyValue[]) =>
  arr.reduce((obj, { key, value }) => (key && value ? { ...obj, [key]: value } : obj), {});

type FilterRowProps = {
  idx: number;
  keyValue: KeyValue;
  onChange: (idx: number, keyValue: KeyValue) => void;
  onRemove: (idx: number) => void;
};

const FilterRow: FC<FilterRowProps> = ({ idx, keyValue: { key, value }, onChange, onRemove }) => {
  const i18n = useTextSnippets('widgetPage');

  const handleKeyChange = (newKey: BaseSelectItem | null) => {
    onChange(idx, { key: `${newKey?.value ?? ''}`, value: '' });
  };

  const handleValueChange = (item: InfiniteListItem | null) => {
    onChange(idx, { key, value: item?.label ?? '' });
  };

  const handleRemove = () => {
    onRemove(idx);
  };

  return (
    <div className={styles.filterRow}>
      <SelectControl
        placeholder={i18n.chooseEntityProp}
        options={keyOptions}
        value={keyToOption(key)}
        onChange={handleKeyChange}
        noInfo
        fullWidth
      />
      <span className="mx-8">{i18n.is}</span>
      <OptionsControl
        placeholder={i18n.value}
        property={key as OptionType}
        disabled={!key}
        onChange={handleValueChange}
        value={value}
        noInfo
      />
      <IconButton icon="RemoveCircleOutline" additionalClass="text-red-error ml-4" size="s" onClick={handleRemove} />
    </div>
  );
};

type CustomFiltersProps = {
  keyValues?: Record<string, any>;
  onChange: (keyValues: Record<string, any>) => void;
};

export const CustomFilters: FC<CustomFiltersProps> = ({ keyValues = {}, onChange }) => {
  const i18n = useTextSnippets('widgetPage');
  const [data, setData] = useState<KeyValue[]>(Object.keys(keyValues).map(key => ({ key, value: keyValues[key] })));

  const handleChange = (idxToChange: number, keyValue: KeyValue) => {
    const newData = data.map((item, idx) => (idx === idxToChange ? keyValue : item));
    setData(newData);
    onChange?.(toKeyValues(newData));
  };

  const handleRemove = (idx: number) => {
    data.splice(idx, 1);
    setData([...data]);
    onChange?.(toKeyValues(data));
  };

  const handleAdd = () => {
    setData([...data, { key: '', value: '' }]);
  };

  return (
    <Control label="Custom Filters">
      <div className={styles.customFilters}>
        <CopyText variant="copy-4" additionalClass="mb-12 ml-4">
          {i18n.filterByEntityProp}
        </CopyText>
        {data.map((keyValue, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <FilterRow key={idx} idx={idx} keyValue={keyValue} onChange={handleChange} onRemove={handleRemove} />
        ))}
        <Button variant="outline" onClick={handleAdd} additionalClass="mt-12" size="s">
          {i18n.addCustomFilter}
        </Button>
      </div>
    </Control>
  );
};
