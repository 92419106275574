import { ChartDataSource, TimeSeriesWidgetSubConfig, WidgetHandle } from '@redux/widgetPage';
import { DEFAULT_TIMESERIES_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { merge } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { ChartWidget, ChartWidgetProps } from '../ChartWidget';
import chartConfigs from '../ChartWidget/configs';
import { ChartConfigGenerator, ChartConfigGeneratorProps } from '../ChartWidget/types';
import themeColors from '@theme/colors';
import { renderToString } from 'react-dom/server';
import { ChartClickEvent, SupportedEvents } from '@components/common/Chart/types';
import { flattenMaintenanceWindows, isHorizontalLegend, isValidChartDataSource } from '@utils/chart';
import { MaintenancePopup } from './components/MaintenancePopup';
import { AlertEvent } from '@infrastructure/api/BaseNClient/useAlertsQuery';
import { DateRange } from '@blueprintjs/datetime';
import echarts from 'echarts';
import icons from './icons';
import colors from '@theme/colors';
import { Note } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { LogbookPopup } from './components/LogbookPopup';
import { AlertConfig, useAlertConfigsQuery } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';
import { useWidgetContext } from '@components/layout/Widget/WidgetContext';
import { AlertsIndicator } from '@components/pages/ChartPage/components/AlertsIndicator';
import { AlertsAccordion } from '@components/pages/ChartPage/components/AlertsPopup';
import { getPaletteColor } from '@components/common/form/PalettePicker/paletteUtils';

const getAlertsConfig = (alerts: AlertConfig[], chartConfig: ChartConfigGeneratorProps<TimeSeriesWidgetSubConfig>) => {
  const alertData: any[] = [];

  alerts.forEach(alert => {
    alert.events?.forEach(({ type, time }: AlertEvent) => {
      if (type === 'error') {
        alertData.push({
          value: [time, 0],
          // we pass along an alert object to the tooltip formatter
          metaInfo: alert,
        });
      }
    });
  });

  return {
    type: 'custom',
    name: 'alerts',
    data: alertData,
    renderItem: function (_params, api) {
      const coord = api.coord([api.value(0), api.value(1)]);
      const yMin = isHorizontalLegend(chartConfig) ? 30 : 10;
      const yMax = api.coord([api.value(0), 0])[1];

      return {
        type: 'group',
        children: [
          {
            type: 'line',
            shape: {
              x1: coord[0],
              y1: yMin,
              x2: coord[0],
              y2: yMax,
            },
            style: {
              stroke: colors.alert.red,
              fill: '#fff',
              lineWidth: 0.5,
              lineDash: [4, 1],
            },
          },
          {
            type: 'path',
            shape: {
              pathData: icons.AlertOn,
              x: -8,
              y: -8,
              width: 16,
              height: 16,
            },
            position: [coord[0], yMin],
            style: {
              fill: colors.alert.red,
            },
          },
        ],
      };
    },
  } as echarts.CustomSeriesOption;
};

export const getLogbookNotesConfig = (
  notes: Note[],
  chartConfig: ChartConfigGeneratorProps<TimeSeriesWidgetSubConfig>
) => {
  const logbookData: any[] = [];

  notes.forEach(note => {
    logbookData.push({
      value: [note.timestamp, 0],
      // we pass along the note object to the tooltip formatter
      metaInfo: note,
    });
  });

  return {
    type: 'custom',
    name: 'logbook-notes',
    data: logbookData,
    renderItem: function (params, api) {
      const coord = api.coord([api.value(0), api.value(1)]);
      const yMin = isHorizontalLegend(chartConfig) ? 30 : 10;

      return {
        type: 'rect',
        shape: {
          width: 8,
          height: 8,
        },
        position: [coord[0], yMin],
        rotation: Math.PI / 4,
        style: {
          fill: colors.blue.ocean,
        },
      };
    },
  } as echarts.CustomSeriesOption;
};

export type TimeSeriesWidgetProps = Omit<ChartWidgetProps, 'config' | 'echartsConfigMiddleware'> & {
  config?: TimeSeriesWidgetSubConfig | null;
  echartsConfigMiddleware?: ChartConfigGenerator<TimeSeriesWidgetSubConfig>;
};

export const TimeSeriesWidget = forwardRef<WidgetHandle, TimeSeriesWidgetProps>(({ inView, config, ...props }, ref) => {
  const chartConfig = {
    ...DEFAULT_TIMESERIES_WIDGET_CONFIG,
    ...config,
  };
  const chartSource = chartConfig.source as ChartDataSource;
  const isValidSource = isValidChartDataSource(chartSource);
  const { entityId, metricId, page, alertId } = chartSource ?? {};
  const [currentDateRange, setCurrentDateRange] = useState<DateRange | null>(null);

  const context = useWidgetContext();

  const alertsQuery = useAlertConfigsQuery(
    {
      ids: alertId,
      entity_id: entityId,
      metric_id: metricId,
      pages: page,
      start: currentDateRange?.[0]?.getTime(),
      end: currentDateRange?.[1]?.getTime(),
      events: true,
      silent: true,
    },
    { enabled: !!(config?.showAlerts && inView && currentDateRange?.[0] && currentDateRange?.[1]) && isValidSource }
  );

  useEffect(() => {
    context?.setTitle(
      !!alertsQuery.data?.alerts.length ? (
        <div className="w-full hbox">
          <AlertsIndicator alerts={alertsQuery.data.alerts} variant="popup" />
          <div className="ml-8 truncate">{context.props?.name}</div>
        </div>
      ) : (
        context.props?.name
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertsQuery.data]);

  const queryParamsMiddleware = useCallback(
    params => ({
      ...params,
      maintenancewindow: !!config?.showMaintenanceWindows,
      entitylogbook: !!config?.showLogbookNotes,
    }),
    [config?.showMaintenanceWindows, config?.showLogbookNotes]
  );

  const echartsConfigMiddleware: ChartConfigGenerator<TimeSeriesWidgetSubConfig> = useCallback(
    (params = {}) => {
      // @ts-ignore - type is in there, but typing is too complicated for now
      const type: 'line' | 'bar' = params.options?.type ?? 'line';
      const defaultConfig = chartConfigs[type](params);
      const seriesConfig = defaultConfig.echartsConfig;
      const { options, dateRange, maintenancewindows, entity_logbook_notes, enums } = params;

      setCurrentDateRange(dateRange ?? null);

      let events: SupportedEvents = {};

      // NOTE: this turned out very glitchy, so will return to the default behavior for now
      // events.onMouseMove = function (params: ChartMousMoveEvent) {
      //   // having tooltip.triggerOn set to 'none' globally we disable automatic tooltips and then show it only when
      //   // the line itself is hovered
      //   if (params.componentType === 'series' && params.componentSubType === 'line') {
      //     // @ts-ignore
      //     this.dispatchAction(
      //       {
      //         type: 'showTip',
      //         x: params.event.offsetX,
      //         y: params.event.offsetY,
      //         seriesIndex: params.seriesIndex,
      //         dataIndex: params.dataIndex,
      //       },
      //       { flush: true, silent: false }
      //     );
      //   }
      // };

      // events.onMouseOut = function (params: ChartMouseOutEvent) {
      //   if (params.componentType === 'series' && params.componentSubType === 'line') {
      //     // @ts-ignore
      //     this.dispatchAction({
      //       type: 'hideTip',
      //     });
      //   }
      // };

      // add Maintenance Windows
      if (options?.showMaintenanceWindows && maintenancewindows?.length && seriesConfig.series?.length) {
        const mwRegistry = flattenMaintenanceWindows(maintenancewindows, dateRange);
        const data = Object.values(mwRegistry).map(({ uid, startTime, endTime }) => [
          {
            xAxis: dateRange?.[0] ? Math.max(startTime, dateRange[0].getTime()) : startTime,
            // we are going to use a name on start item as an id to retrieve info for the popup
            name: uid,
            label: {
              show: false,
            },
          },
          {
            xAxis: dateRange?.[1] ? Math.min(endTime, dateRange[1].getTime()) : endTime,
          },
        ]) as echarts.MarkAreaComponentOption['data'];

        if (data?.length) {
          seriesConfig.series[0].markArea = {
            silent: false,
            itemStyle: {
              color: themeColors.blue.ocean,
              opacity: 0.1,
            },
            data,
          };

          events.onClick = function (params: ChartClickEvent) {
            // markArea doesn't support tooltip (at least it is not documented) so we are creating our own
            if (params.componentType === 'markArea') {
              // @ts-ignore
              this.dispatchAction(
                {
                  type: 'showTip',
                  x: params.event.offsetX,
                  y: params.event.offsetY,
                  tooltip: {
                    enterable: true,
                    triggerOn: 'click',
                    show: true,
                    content: renderToString(<MaintenancePopup mw={mwRegistry[params.data.name]} />),
                    position: 'top',
                  },
                },
                { flush: true, silent: false }
              );
            }
          };
        }
      }

      // add Logbook Notes
      if (options?.showLogbookNotes && entity_logbook_notes?.length) {
        seriesConfig.series.push(getLogbookNotesConfig(entity_logbook_notes, params));

        events.onClick = function (params: ChartClickEvent) {
          if (params.seriesName === 'logbook-notes') {
            // @ts-ignore
            this.dispatchAction(
              {
                type: 'showTip',
                x: params.event.offsetX,
                y: params.event.offsetY,
                tooltip: {
                  enterable: true,
                  triggerOn: 'click',
                  show: true,
                  borderColor: colors.blue.ocean,
                  borderWidth: 1,
                  content: renderToString(<LogbookPopup note={params.data.metaInfo} />),
                  position: 'top',
                },
              },
              { flush: true, silent: false }
            );
          }
        };
      }

      // add Alerts
      if (options?.showAlerts && alertsQuery.data?.alerts.length) {
        seriesConfig.series.push(getAlertsConfig(alertsQuery.data.alerts, params));

        events.onClick = function (params: ChartClickEvent) {
          if (params.seriesName === 'alerts') {
            // @ts-ignore
            this.dispatchAction(
              {
                type: 'showTip',
                x: params.event.offsetX,
                y: params.event.offsetY,
                tooltip: {
                  enterable: true,
                  triggerOn: 'click',
                  show: true,
                  content: renderToString(<AlertsAccordion alerts={[params.data.metaInfo]} isNotInteractive />),
                  position: 'top',
                  padding: 0,
                  margin: 0,
                },
              },
              { flush: true, silent: false }
            );
          }
        };
      }

      if (enums && seriesConfig.series?.length) {
        seriesConfig.series[0].markLine = {
          silent: false,
          symbol: 'none',
          data: Object.keys(enums).map((level, idx) => {
            const color = getPaletteColor('RedYellowGreen', idx);
            return {
              yAxis: Number(level) ?? 0,
              label: {
                show: true,
                formatter: enums[level],
                color,
                position: 'insideMiddleTop',
              },
              lineStyle: {
                type: 'solid',
                color,
                opacity: 0.5,
              },
            };
          }),
        };
      }

      return {
        echartsConfig: merge(defaultConfig.echartsConfig, seriesConfig) as echarts.EChartsOption,
        events: merge(defaultConfig.events, events),
      };
    },
    [alertsQuery.data]
  );

  return (
    <ChartWidget
      ref={ref}
      {...props}
      inView={inView}
      config={chartConfig}
      queryParamsMiddleware={queryParamsMiddleware}
      echartsConfigMiddleware={echartsConfigMiddleware}
    />
  );
});
