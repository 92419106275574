import { UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericOkApiResponse } from '../types';
import { z } from 'zod';
import {
  alertEventSchema,
  colorSchema,
  GetAlertsRequest,
  prioritySchema,
  silenceReasonSchema,
  valueSchema,
} from './useAlertsQuery';
import { useValidatedQuery } from '@services/useValidatedQuery';
import { alertOverrideSchema } from './useAlertOverrideQuery';

const channelInfoSchema = z.object({
  unit: z.string(),
  channel: z.string(),
  comment: z.string().optional(),
  state: z.string(), // new,ack,track,unknown,closing,warn,ok
  status: z.string(), // null,red,orange,yellow,lime,green - same as color?
});

const channelSchema = z.object({
  silent: silenceReasonSchema.optional(),
  color: colorSchema,
  active: z.boolean().optional(), // whether alert is silenced or not
  entity: z.string().optional(), // entity id
  metric: z.string().optional(),
  times: z.array(z.number()).optional(),
  time: z.number().optional(),
  value: valueSchema.optional(),
  values: z.array(valueSchema).optional(),
  greenreds: z.array(z.number()).optional(),
  greenred: z.number().optional(),
  info: channelInfoSchema,
});

const alertConfigInfoSchema = z.object({
  name: z.string(),
  comment: z.string().optional(),
  page: z.string(),
  priority: prioritySchema,
  customer: z.string().optional(),
});

const alertConfigSchema = z.object({
  id: z.string(),
  parentid: z.string().optional(),
  hasOverrides: z.boolean(),
  info: alertConfigInfoSchema,
  groups: z.array(z.string()).optional(),
  events: z.array(alertEventSchema).optional(),
  channels: z.array(channelSchema).optional(),
  active: z.boolean().optional(),
  alertTimeClosing: z.number().optional(),
  alertTime: z.number().optional(),
  alertTimeExtra: z.number().optional(),
  alertTimeNodata: z.number().optional(),
  atlName: z.string().optional(),
  category: z.string().optional(),
  nodataStrategy: z.string().optional(),
  greens: z.array(z.number()).optional(),
  reds: z.array(z.number()).optional(),
  overrides: z
    .object({
      channels: z.array(alertOverrideSchema).optional(),
      global: alertOverrideSchema.optional(),
    })
    .optional(),
});

export type AlertPriority = z.infer<typeof prioritySchema>;
export type AlertChannel = z.infer<typeof channelSchema>;
export type AlertConfigInfo = z.infer<typeof alertConfigInfoSchema>;
export type AlertConfig = z.infer<typeof alertConfigSchema>;

type GetAlertConfigsRequest = GetAlertsRequest & {
  channel_array?: boolean; // return channels for the given alert config as array
};

const getAlertConfigsResponseSchema = z.object({
  alerts: z.array(alertConfigSchema),
  version: z.number().optional(),
  count: z.number().optional(),
  total: z.number(),
  errors: z.number().optional(),
});

type GetAlertConfigsResponse = z.infer<typeof getAlertConfigsResponseSchema> & GenericOkApiResponse;

export const useAlertConfigsQuery = <T extends GetAlertConfigsResponse = GetAlertConfigsResponse>(
  // NOTE: channel_array is used to return channels for the given alert config as array - cannot be overriden,
  // useAlertsQuery to get alerts in legacy format
  searchParams: Omit<GetAlertConfigsRequest, 'channel_array'> = {},
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(
    ENDPOINTS.getAlerts,
    { silent: true, ...searchParams, channel_array: true },
    getAlertConfigsResponseSchema,
    {
      ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
      ...queryOptions,
    }
  );
};
