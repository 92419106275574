import { dateRangeTitle, formatValue } from '@utils/chart';
import { presetToPalette } from '@components/common/form/PalettePicker/paletteUtils';
import { PieWidgetSubConfig } from '@redux/widgetPage';
import { ChartConfigGenerator } from '../types';
import watermarkImage from '@assets/images/watermark.png';
import { mergeAndConcatArrays } from '@utils/misc';

const getConfig: ChartConfigGenerator<PieWidgetSubConfig> = ({
  channels = [],
  options,
  dateRange,
  timezone,
  unit,
} = {}) => {
  const { colors } = presetToPalette(options?.palette ?? 'BaseN');
  const aggregateMethod = options?.aggregateMethod ?? 'avg';

  const baseConfig = {
    animation: false,
    color: colors,
    title: dateRange ? dateRangeTitle(dateRange, timezone) : {},
    grid: {
      top: 50,
      left: 10,
      right: 10,
      containLabel: true,
      borderColor: '#ccc',
    },
    legend: {
      orient: 'horizontal',
      type: 'scroll',
      padding: 0,
      right: 10,
      top: 10,
      icon: 'circle',
      tooltip: {
        show: true,
        trigger: 'item',
      },
    },
    tooltip: {
      trigger: 'item',
      renderMode: 'html',
      appendToBody: true, // escape boundaries of the Widget container
      enterable: true,
      valueFormatter: channels?.length ? (value: number | string) => formatValue(value, unit, 'unit') : null,
    },
    series: [
      {
        type: 'pie',
        data: channels?.map(({ name, summaries }) => ({
          value: !isNaN(summaries[aggregateMethod])
            ? formatValue(summaries[aggregateMethod]!, unit)
            : summaries[aggregateMethod],
          name,
        })),
        radius: '50%',
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  const watermarkConfig = options?.showWatermark
    ? {
        graphic: {
          type: 'image',
          right: 50,
          bottom: 23,
          style: {
            image: watermarkImage,
          },
        },
      }
    : {};

  const echartsConfig = mergeAndConcatArrays(baseConfig, watermarkConfig) as echarts.EChartsOption;

  return {
    echartsConfig,
  };
};

export default getConfig;
