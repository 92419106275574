import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { createLogbookSlice } from '@infrastructure/redux/logbook';
import { createPortableStore } from '@utils/redux';
import { ChangesTable, ChangesTableProps } from './ChangesTable';

let componentCounter = 0;

export type PortableChangesTableProps = Omit<ChangesTableProps, 'actions'> & {
  actionsPrefix?: string;
  entityId?: string;
};

export const PortableChangesTable: React.FC<PortableChangesTableProps> = ({
  actionsPrefix = 'portableChangesTable',
  ...props
}) => {
  const { store, actions } = useMemo(
    () => createPortableStore(createLogbookSlice(`${actionsPrefix}-${++componentCounter}`)),
    [actionsPrefix]
  );

  return (
    // NOTE: overriding global store with local one to redirect all selectors and dispatcher
    <Provider store={store}>
      <ChangesTable actions={actions} {...props} />
    </Provider>
  );
};
