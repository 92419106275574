import React, { useEffect } from 'react';
import IssueDetail from './IssueDetail';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import {
  createSelectIssueById,
  IssueWithDetails,
  selectQueryParams,
  setActiveIssue,
  updateOrAppendIssue,
} from '@redux/issues';
import { useLoadingContext } from 'react-router-loading';
import { useIssueByIdQuery } from '@infrastructure/api/BaseNClient/useIssueByIdQuery';

export const IssuePage = () => {
  const loadingContext = useLoadingContext();

  const { issueId } = useParams() as { issueId: string };
  const issue = useAppSelector(createSelectIssueById(issueId ?? '')) as IssueWithDetails;
  const queryParams = useAppSelector(selectQueryParams);
  const isQueryEnabled = !!issueId && !issue?.detailsLoaded;
  const dispatch = useAppDispatch();

  const issueQuery = useIssueByIdQuery({ issueId, issueFields: queryParams.issueFields }, { enabled: isQueryEnabled });

  if (issue?.detailsLoaded) {
    loadingContext.done();
  }

  useEffect(() => {
    if (issueQuery.data) {
      dispatch(updateOrAppendIssue(issueQuery.data));
      dispatch(setActiveIssue(issueId));
      loadingContext.done();
    }
  }, [issueQuery.data, dispatch, issueId, loadingContext]);

  return issue?.detailsLoaded ? <IssueDetail issue={issue} /> : null;
};
