import arrowRightGreenIcon from '@assets/images/map/map-connection-left-green.png';
import arrowLeftGreenIcon from '@assets/images/map/map-connection-right-green.png';
import arrowRightLimeIcon from '@assets/images/map/map-connection-left-lime.png';
import arrowLeftLimeIcon from '@assets/images/map/map-connection-right-lime.png';
import arrowRightYellowIcon from '@assets/images/map/map-connection-left-yellow.png';
import arrowLeftYellowIcon from '@assets/images/map/map-connection-right-yellow.png';
import arrowRightOrangeIcon from '@assets/images/map/map-connection-left-orange.png';
import arrowLeftOrangeIcon from '@assets/images/map/map-connection-right-orange.png';
import arrowRightRedIcon from '@assets/images/map/map-connection-left-red.png';
import arrowLeftRedIcon from '@assets/images/map/map-connection-right-red.png';
import arrowRightGrayIcon from '@assets/images/map/map-connection-left-grey.png';
import arrowLeftGrayIcon from '@assets/images/map/map-connection-right-grey.png';

export default {
  'arrow-right-green': {
    width: 16,
    height: 8,
    src: arrowRightGreenIcon as string,
  },
  'arrow-left-green': {
    width: 16,
    height: 8,
    src: arrowLeftGreenIcon as string,
  },
  'arrow-right-lime': {
    width: 16,
    height: 8,
    src: arrowRightLimeIcon as string,
  },
  'arrow-left-lime': {
    width: 16,
    height: 8,
    src: arrowLeftLimeIcon as string,
  },
  'arrow-right-yellow': {
    width: 16,
    height: 8,
    src: arrowRightYellowIcon as string,
  },
  'arrow-left-yellow': {
    width: 16,
    height: 8,
    src: arrowLeftYellowIcon as string,
  },
  'arrow-right-orange': {
    width: 16,
    height: 8,
    src: arrowRightOrangeIcon as string,
  },
  'arrow-left-orange': {
    width: 16,
    height: 8,
    src: arrowLeftOrangeIcon as string,
  },
  'arrow-right-red': {
    width: 16,
    height: 8,
    src: arrowRightRedIcon as string,
  },
  'arrow-left-red': {
    width: 16,
    height: 8,
    src: arrowLeftRedIcon as string,
  },
  'arrow-right-gray': {
    width: 16,
    height: 8,
    src: arrowRightGrayIcon as string,
  },
  'arrow-left-gray': {
    width: 16,
    height: 8,
    src: arrowLeftGrayIcon as string,
  },
};
