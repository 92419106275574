import React, { useMemo } from 'react';
import { PasswordPolicy } from '@redux/auth';
import CopyText from '@components/typography/CopyText';
import useTextSnippets from '@services/useTextSnippets';
import FieldInfo from '@components/common/form/FieldInfo';
import { forgotPasswordConfig } from '@redux/auth/config';
import { useFormikContext } from 'formik';
import { Variant } from 'framer-motion';
import fillTemplate from '@utils/fillTemplate';
import { DefaultMotion, useTheme } from '@services';
import { mediumPasswordRegexTemplate, NewPasswordSchema, strongPasswordRegexTemplate } from './NewPassword.schema';
import styles from './style.module.scss';

type Props = {
  passwordPolicy?: PasswordPolicy;
};

type PasswordComplexityAnimate = 'strong' | 'medium' | 'weak' | 'none';

const passwordComplexityAnimate: Record<PasswordComplexityAnimate, PasswordComplexityAnimate> = {
  strong: 'strong',
  medium: 'medium',
  weak: 'weak',
  none: 'none',
};

const PasswordComplexityBar: React.FC<Props> = ({ passwordPolicy }) => {
  const colors = useTheme<Record<string, Record<string, string>>>('colors');
  const { values, errors } = useFormikContext<NewPasswordSchema>();
  const { weakPassword, goodPassword, greatPassword, passwordHint } = useTextSnippets('forgotPassword');
  const minPasswordLength = passwordPolicy?.minPasswordLength || forgotPasswordConfig.DEFAULT_MIN_PASSWORD_LENGTH;

  const complexityAnimateVariants: Record<PasswordComplexityAnimate, Variant> = useMemo(
    () => ({
      none: { width: '0%', backgroundColor: colors.gray['4'] },
      weak: { width: '33%', backgroundColor: colors.red.error },
      medium: { width: '66%', backgroundColor: colors.yellow.base },
      strong: { width: '100%', backgroundColor: colors.green.success },
    }),
    [colors]
  );

  const passwordChecks = useMemo(
    () => ({
      medium: new RegExp(fillTemplate(mediumPasswordRegexTemplate, { minLength: minPasswordLength })),
      strong: new RegExp(fillTemplate(strongPasswordRegexTemplate, { minLength: minPasswordLength })),
    }),
    [minPasswordLength]
  );

  const password = values?.newPassword;
  const passwordIsMedium = password && passwordChecks.medium.exec(password);
  const passwordIsStrong = password && passwordChecks.strong.exec(password);

  let passwordTypeMessage;

  if (password) {
    passwordTypeMessage = weakPassword;

    if (passwordIsStrong) {
      passwordTypeMessage = greatPassword;
    } else if (passwordIsMedium) {
      passwordTypeMessage = goodPassword;
    }
  }

  const passwordError = errors && errors.newPassword;

  const getComplexityAnimate = (): PasswordComplexityAnimate => {
    if (passwordIsStrong) {
      return passwordComplexityAnimate.strong;
    }

    if (passwordIsMedium) {
      return passwordComplexityAnimate.medium;
    }

    return password ? passwordComplexityAnimate.weak : passwordComplexityAnimate.none;
  };

  return (
    <>
      <div className={styles.passwordComplexityBarContainer}>
        <DefaultMotion
          key="password-complexity"
          animate={getComplexityAnimate()}
          variants={complexityAnimateVariants}
          initial={passwordComplexityAnimate.none}
          exit={passwordComplexityAnimate.none}
          transition={{ delay: 0.2 }}
          className={styles.passwordComplexityBar}
        />
      </div>
      {passwordError && <FieldInfo text={passwordError} error />}
      <div className={styles.passwordComplexityMessage}>
        {passwordTypeMessage && (
          <CopyText variant="copy-6" additionalClass={styles.passwordType}>
            {passwordTypeMessage}
          </CopyText>
        )}
        <CopyText variant="copy-6" additionalClass="inline">
          {' '}
          {fillTemplate(passwordHint, { minLength: minPasswordLength })}
        </CopyText>
      </div>
    </>
  );
};

export default PasswordComplexityBar;
