import { object, TypeOf, number, bool, string } from 'yup';
import { useMemo } from 'react';
import { VECTOR_TILES } from '@components/common/Map/types';

const useSchema = () => {
  const schema = object({
    zoom: number().required(),
    latitude: number().required(),
    longitude: number().required(),
    showZoomControl: bool(),
    showEntityTooltip: bool(),
    mode: string().oneOf(Object.keys(VECTOR_TILES)),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, []);
};

export interface OptionsTabSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
