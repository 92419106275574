import React from 'react';
import c from 'classnames';
import { TimePicker as BPTimePicker, TimePickerProps as BPTimePickerProps } from '@blueprintjs/datetime';

import styles from './TimeInput.module.scss';

export type TimeInputProps = BPTimePickerProps & {
  additionalClass?: string;
};

const TimeInput: React.FC<TimeInputProps> = ({ additionalClass, showArrowButtons = true, ...props }) => (
  <div className={c(styles.timePickerContainer, showArrowButtons && styles.withArrows)}>
    <BPTimePicker
      showArrowButtons={showArrowButtons}
      selectAllOnFocus
      useAmPm
      className={c(styles.timePicker, additionalClass)}
      {...props}
    />
  </div>
);

export default TimeInput;
