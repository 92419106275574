import React, { FC } from 'react';
import { useField } from 'formik';
import RadioGroupControl, { RadioGroupControlProps } from './RadioGroupControl';

export type RadioGroupFieldProps = RadioGroupControlProps & {
  name: string;
};

export const RadioGroupField: FC<RadioGroupFieldProps> = ({ size, ...props }) => {
  const [field, { error, touched }] = useField(props);

  return <RadioGroupControl size={size} {...props} {...field} errorText={touched ? error : undefined} />;
};
