import { Cluster, ClusterType } from '@components/widgets/MapWidget/ClusterCache';
import { VectorTileLayer } from '@mapbox/vector-tile';
import colors from '@theme/colors';
import chroma from 'chroma-js';
import { ExpressionSpecificationArray } from 'maplibre-gl';
import invariant from './invariant';

export const prop = (name: string): ExpressionSpecificationArray => ['get', name];

export const ifProp = (name: string): ExpressionSpecificationArray => ['!=', prop(name), null];

export const concat = (...args: (string | ExpressionSpecificationArray)[]): ExpressionSpecificationArray => [
  'concat',
  ...args,
];

export const ifEq = (name: string, valueToCheck: any): ExpressionSpecificationArray => ['==', prop(name), valueToCheck];

export const ifState = (name: string): ExpressionSpecificationArray => ['boolean', ['feature-state', name], false];

export const ternary = (
  exp: ExpressionSpecificationArray,
  ifTrue: string | number | ExpressionSpecificationArray,
  ifFalse: string | number | ExpressionSpecificationArray
): ExpressionSpecificationArray => ['case', exp, ifTrue, ifFalse];

export const filter = (propName: string, value: any, op: string = '=='): ExpressionSpecificationArray => [
  op,
  propName,
  value,
];

export const color = (colorName: string | ExpressionSpecificationArray, alpha = 1) => [
  'case',
  ...Object.keys(colors.alert).reduce(
    (map: ExpressionSpecificationArray[], key: string) =>
      [
        ...map,
        ['==', colorName, key],
        [
          'rgba',
          // @ts-ignore
          ...chroma(colors.alert[key] as string)
            .alpha(alpha)
            .rgba(),
        ],
      ] as ExpressionSpecificationArray[],
    []
  ),
  ['rgba', ...chroma(colors.alert.gray).alpha(alpha).rgba()],
];

export const colorFromProp = (name: string, alpha = 1): ExpressionSpecificationArray => color(prop(name), alpha);

export const width = (widthName: string = 'width', defaultValue = 1) =>
  ternary(['in', prop(widthName), ['literal', [1, 2, 3, 4]]], prop(widthName), defaultValue);

export const prepareCoords = (coords: [number, number] | string): { lat: number; lng: number } => {
  const arr =
    typeof coords === 'string'
      ? coords
          .trim()
          .split(/\s*,s*/)
          .map(Number)
      : coords;

  invariant(
    Array.isArray(arr) && arr.length === 2,
    `prepareCoords: coords should be array of exactly two numbers - latitude and longitude`
  );

  return { lat: arr[0], lng: arr[1] };
};

export const add = (
  operand1: number | ExpressionSpecificationArray,
  operand2: number | ExpressionSpecificationArray
): ExpressionSpecificationArray => ['+', operand1, operand2];

export const mul = (
  operand1: number | ExpressionSpecificationArray,
  operand2: number | ExpressionSpecificationArray
): ExpressionSpecificationArray => ['*', operand1, operand2];

export const layersToClusters = (layers: VectorTileLayer, type: ClusterType, zoom: number = -1): Cluster[] => {
  const clusters: Cluster[] = [];

  // VectorTileLayer is not an array, although it has a length property
  for (let i = 0; i < layers.length; i++) {
    const {
      // @ts-ignore - doesn't recognize latitude/longitude props although it has type of { [name: string]: any; }
      properties: { latitude: lat, longitude: lng, entities, alerting, maintenance, entity_ids, name },
    } = layers.feature(i).toGeoJSON(0, 0, 0);

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const id = `${zoom}:${lat}:${lng}:cluster`;

    clusters.push({
      id,
      lng,
      lat,
      entities,
      alerting,
      maintenance,
      entityIds: entity_ids?.split(','),
      name,
      zoom,
      type,
    } as Cluster);
  }

  return clusters;
};
