import { createCustomSlice, createDataTableReducers, createInitialDataTableState } from '@utils/redux';
import { CONFIGS_TABLE_ID } from './selectors';
import { ConfigsPageState } from './types';
import { SearchConfigItem } from '@infrastructure/api/BaseNClient/useSearchConfigsQuery';

const initialState: ConfigsPageState = createInitialDataTableState(CONFIGS_TABLE_ID);

export const createConfigsSlice = (actionPrefix?: string) =>
  createCustomSlice({
    name: 'configsPage',
    initialState,
    reducers: createDataTableReducers<SearchConfigItem>(initialState),
    actionPrefix,
  });

const configsSlice = createConfigsSlice();

export const { updateItems, updatePagination, updateSearch, updateSorting, updateFilters, updateRowHeight, reset } =
  configsSlice.actions;

export default configsSlice;
