import { OptionsTab } from './components/OptionsTab';
import { DEFAULT_PIE_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { WidgetSetup } from '@components/pages/WidgetPage/types';
import { PieWidget } from '@components/widgets/PieWidget';
import { UniversalDataSourcePicker } from '../ChartWidget/components/UniversalDataSourcePicker';

export default {
  type: 'pie',
  icon: 'PieChart',
  i18nTitle: 'pieChart',
  OptionsTab,
  DataSourcePicker: UniversalDataSourcePicker,
  Widget: PieWidget,
  defaultSubConfig: DEFAULT_PIE_WIDGET_CONFIG,
} as WidgetSetup;
