import { isBrowserCheck } from '@utils/util';

const scrollbarWidth = (element?: Element): number => {
  // No SSR, obviously
  if (element && isBrowserCheck()) {
    const scrollDiv = document.createElement('div');
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;');

    element.appendChild(scrollDiv);

    const scrollbarWidthValue = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    element.removeChild(scrollDiv);

    return scrollbarWidthValue;
  }

  return 0;
};

export default scrollbarWidth;
