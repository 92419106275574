import React from 'react';
import cn from 'classnames';
import Icon, { IconSize } from '@components/common/Icon';
import styles from './style.module.scss';

export type LoadingSpinnerProps = {
  additionalClass?: string;
  centered?: boolean;
  size?: IconSize;
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ additionalClass, centered, size = 'm' }) => (
  <div className={cn(styles.loadingSpinner, additionalClass, centered && styles.centered)}>
    <Icon name="Loading" additionalClass="animate-spin" size={size} />
  </div>
);

export default LoadingSpinner;
