import React from 'react';
import c from 'classnames';

import styles from './NetworkStatusIndicator.module.scss';

export type NetworkStatusIndicatorVariant = 'default' | 'ok' | 'error';

export type NetworkStatusIndicatorProps = {
  size?: 'xs' | 's' | 'm' | 'l' | 'auto';
  variant?: NetworkStatusIndicatorVariant;
  additionalClass?: string;
};

const NetworkStatusIndicator: React.FC<NetworkStatusIndicatorProps> = ({
  size = 'm',
  variant = 'default',
  additionalClass,
}) => {
  return (
    <span
      className={c(
        styles.indicator,
        styles[variant],
        {
          [styles.xs]: size === 'xs',
          [styles.small]: size === 's',
          [styles.medium]: size === 'm',
          [styles.large]: size === 'l',
          [styles.auto]: size === 'auto',
        },
        additionalClass
      )}
    />
  );
};

export default NetworkStatusIndicator;
