import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { z } from 'zod';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';

const typeSchema = z.enum(['entity', 'dashboard', 'issue', 'alert']);

const searchHitSchema = z.object({
  name: z.string(),
  parent_name: z.string().optional(),
  id: z.string(),
  type: typeSchema,
  description: z.string().optional(),
  customer: z.string().optional(),
  icon: z.string().optional(),
});

export type SearchHit = z.infer<typeof searchHitSchema>;
export type GlobalSearchQueryResponse = { data: SearchHit[]; result?: 'ok' };

export const useGlobalSearchQuery = <T extends GlobalSearchQueryResponse = GlobalSearchQueryResponse>(
  searchTerm: string,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  const { getFailureHandler } = useGenericMutationHandlers();

  return useQuery<T, Error>(['search', searchTerm], () => apiClient.get(ENDPOINTS.search, { searchTerm }), {
    ...queryOptions,
    enabled: !!searchTerm && searchTerm.length > 2,
    onError: getFailureHandler(undefined, 'Search query has failed'),
  });
};
