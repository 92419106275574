import React, { FC } from 'react';
import Control, { ControlProps } from '../Control/Control';
import RadioGroup, { RadioGroupProps } from './RadioGroup';

export type RadioGroupControlProps = ControlProps<string, (e: React.ChangeEvent<HTMLInputElement>) => void> &
  RadioGroupProps;

const RadioGroupControl: FC<RadioGroupControlProps> = ({ inline, children, ...props }) => (
  <Control {...props}>
    <RadioGroup inline={inline}>{children}</RadioGroup>
  </Control>
);

export default RadioGroupControl;
