import { DownloadPopup, DownloadPopupProps } from '@components/connected/DownloadButton';
import { API_URL } from '@config';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { toQueryStr } from '@utils/url';
import React from 'react';

export type IssueDownloadPopupProps = Omit<DownloadPopupProps, 'url'> & {
  params: Record<string, any>;
};

export const IssueDownloadPopup: React.FC<IssueDownloadPopupProps> = ({ params, ...props }) => {
  const url = `${API_URL}/${ENDPOINTS.issues}/?${toQueryStr(params)}`;

  return <DownloadPopup {...props} url={url} fileName="issues" />;
};
