import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import React, { FC, useState } from 'react';

export const NoNotes: FC<{ onCreateNew?: () => void }> = ({ onCreateNew }) => {
  const [isLoading, setIsloading] = useState(false);

  const handleCreateNew = async () => {
    setIsloading(true);
    onCreateNew?.();
    setIsloading(false);
  };

  return (
    <NoElementsContainer
      icon="AddNote"
      title="Logbook"
      description="Keep track of all the events, changes and important information per entity."
      buttonProps={{
        loading: isLoading,
        label: 'Add Note',
        onClick: handleCreateNew,
      }}
    />
  );
};
