import { AlertChannel } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';
import {
  getAlertChannelStats,
  getIconColor,
  getIconName,
  getOtherColor,
  getExplanation,
  getTitle,
  getBadgeVariant,
} from '@utils/alert';
import React from 'react';
import Icon from '@components/common/Icon';
import c from 'classnames';
import Tooltip from '@components/common/Tooltip';
import { Badge } from '@components/common/Badge';
import { TITLE_BY_COLOR } from './constants';

import styles from './AlertsIndicator.module.scss';
import alertStyles from '@components/connected/IssueManager/components/AlertBar/Alert.module.scss';

export type AlertChannelsIndicatorProps = {
  channels: AlertChannel[];
  showTooltip?: boolean;
  showBadge?: boolean;
  size?: 's' | 'm';
};

export const AlertChannelsIndicator: React.FC<AlertChannelsIndicatorProps> = ({
  channels = [],
  showTooltip,
  showBadge,
  size = 'm',
}) => {
  const stats = React.useMemo(() => getAlertChannelStats(channels), [channels]);
  let colorName = channels.length === 1 && channels[0].color ? channels[0].color : getIconColor(stats);
  const otherColor = getOtherColor(stats);
  const otherTextColor = otherColor === 'gray' ? 'light' : 'dark';

  const indicator = (
    <div className={styles.alertIndicator}>
      <Icon name={getIconName(stats)} size="s" additionalClass={alertStyles[colorName]} />
      {stats.total > 1 && (
        <div className={c(styles.moreCircle, alertStyles[`${otherColor}-bg`], styles[otherTextColor])}>
          <span>{`+${stats.total - 1}`}</span>
        </div>
      )}
      {showBadge && (
        <Badge variant={colorName ?? getBadgeVariant(stats)} additionalClass="ml-8" size={size}>
          {getTitle(stats, TITLE_BY_COLOR[colorName])}
        </Badge>
      )}
    </div>
  );

  return showTooltip ? <Tooltip content={getExplanation(stats)}>{indicator}</Tooltip> : indicator;
};
