import { InfoGrid, InfoGridItem } from '@components/layout/InfoGrid/InfoGrid';
import { Note } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import React from 'react';
import { formatEpochDate } from '@utils/date';
import { BackupStatus } from './BackupStatus';
import { Gravatar } from '@components/common/Gravatar';
import CopyText from '@components/typography/CopyText';
import { floor } from 'lodash';

type VersionDetailsProps = {
  note: Note;
};

export const VersionDetails = ({ note }: VersionDetailsProps) => {
  const data = React.useMemo(
    () =>
      [
        { label: 'Backup', value: <BackupStatus note={note} /> },
        note?.timestamp && {
          label: 'Version',
          value: floor(note.timestamp / 1000),
        },
        {
          label: 'Changed on',
          value: formatEpochDate(note?.timestamp),
        },
        !!note?.author && {
          label: 'User',
          value: (
            <div className="w-full hbox">
              <Gravatar size={40} username={note.author} email={note.author} additionalClass="mr-8" />
              <CopyText variant="copy-4" additionalClass="truncate">
                {note.author}
              </CopyText>
            </div>
          ),
        },
      ].filter(Boolean) as InfoGridItem[],
    [note]
  );

  return (
    <div className="w-full pt-8">
      <InfoGrid data={data} size="m" variant="description" />
    </div>
  );
};
