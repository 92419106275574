import { MVTLayer, MVTLayerProps } from '@components/common/Map';
import React, { FC, useMemo } from 'react';
import * as x from '@utils/map';
import { LayerSpecification } from 'maplibre-gl';

export type EntityLabelsLayerProps = Omit<MVTLayerProps, 'type'>;

export const EntityLabelsLayer: FC<EntityLabelsLayerProps> = props => {
  const layout = useMemo(
    () =>
      ({
        'symbol-sort-key': 10,
        'symbol-placement': 'point',
        'text-anchor': 'top',
        'text-font': ['Open Sans Regular'],
        'text-size': 10,
        'text-allow-overlap': false,
        'text-max-width': 10,
        'text-field': x.prop('name'),
        'icon-image': 'tag',
        'icon-text-fit': 'both',
        'icon-offset': [0, 15],
        'icon-allow-overlap': false,
      } as LayerSpecification['layout']),
    []
  );

  const paint = useMemo(
    () => ({
      'text-translate': [0, 16],
      // 'text-opacity': x.ternary(x.ifState('hover'), 1, 0),
    }),
    []
  );

  return <MVTLayer {...props} type="symbol" layout={layout} paint={paint} />;
};
