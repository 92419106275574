import { Radio, RadioGroup } from '@components/common/form/Radio';
import HorizontalTitleSeparator from '@components/separators/HorizontalTitleSeparator/HorizontalTitleSeparator';
import { WidgetType } from '@redux/widgetPage';
import { capitalize } from 'lodash';
import React from 'react';

export type SubTypesSectionProps = {
  type: WidgetType;
  subTypes: string[];
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function SubTypesSection({ type, subTypes, value, onChange }: SubTypesSectionProps) {
  return (
    <>
      <div className="mt-12">
        <HorizontalTitleSeparator title="Subtype" variant="copy-4" additionalClass="mt-8 mb-16" />
      </div>
      <RadioGroup name="subtype" value="line" onChange={onChange}>
        {subTypes.map(subType => (
          <Radio
            key={subType}
            label={`${capitalize(subType)} ${capitalize(type)}`}
            value={`${type}:${subType}`}
            size="s"
            checked={subType === value}
          />
        ))}
      </RadioGroup>
    </>
  );
}
