import React, { useEffect, useState, useMemo, useRef, useCallback, forwardRef } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { DateRange } from '@blueprintjs/datetime';
import CopyText from '@components/typography/CopyText';
import {
  FilterDataRequest,
  FilterDataResponse,
  useFilterDataQuery,
} from '@infrastructure/api/BaseNClient/useFilterDataQuery';
import useTextSnippets from '@services/useTextSnippets';
import { ChartWidgetSubConfig } from '@redux/widgetPage';
import chartConfigs from './configs';
import { DEFAULT_CHART_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { Chart, ChartHandle } from '@components/common/Chart';
import { ToggleButtonGroup, ToggleButton } from '@components/common/form/ToggleButton';
import IconButton from '@components/common/IconButton';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { useDashboardContext } from '@redux/dashboards/context';
import { getMatchingInterval, inDateRange } from '@utils/date';
import { useAccount } from '@redux/auth';
import { DEFAULT_TIMEZONE, COMMON_DATE_INTERVALS, Interval } from '@constants/date';
import { downloadFile } from '@utils/file';
import { capitalize, identity } from 'lodash';
import { dateRangeToString, isValidChartDataSource, normalizeData, normalizeLegacySource } from '@utils/chart';
import { SummaryRow, SummaryView } from './components/SummaryView';
import c from 'classnames';
import { ChartConfigGenerator, ChartData } from './types';
import { WidgetComponentProps } from '@components/pages/WidgetPage/types';
import { WidgetHandle } from '@redux/widgetPage/types';
import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import Headline from '@components/typography/Headline';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { LegendSelectChangedEvent } from '@components/common/Chart/types';
import { CHART_REFRESH_INTERVAL } from '@constants/chart';

import styles from './ChartWidget.module.scss';

export type ChartWidgetProps = Omit<WidgetComponentProps, 'config'> & {
  config?: ChartWidgetSubConfig | null;
  additionalClass?: string;
  echartsConfigMiddleware?: ChartConfigGenerator;
  queryParamsMiddleware?: (params?: FilterDataRequest) => FilterDataRequest | undefined;
};

export const ChartWidget = forwardRef<WidgetHandle, ChartWidgetProps>(
  (
    {
      additionalClass,
      inView = false,
      echartsConfigMiddleware,
      queryParamsMiddleware = identity,
      onLoadSuccess,
      onLoadError,
      ...props
    },
    ref
  ) => {
    const chartRef = useRef<ChartHandle | null>(null);
    const account = useAccount();
    const [isSummaryView, setIsSummaryView] = useState(false);
    const [isZoomRangeMode, setIsZoomRangeMode] = useState(false);
    const [echartsData, setEChartsData] = useState<ChartData | null>(null);

    // when showSummary=true, we need to know what channels are visible in the chart to reflect that in SummaryView
    // which basically plays role of the chart legend
    const [channelsVisible, setChannelsVisible] = useState<Record<string, boolean> | null>(null);

    const i18n = useTextSnippets('widgetPage');
    const i18nCommon = useTextSnippets('common');
    const config = props.config ?? DEFAULT_CHART_WIDGET_CONFIG;
    const {
      source,
      type = 'line',
      dateRange,
      hasToolbar,
      showSummary,
      // not using the following below, but extracting from options, that are passed along to real config generator
      // echartsConfig: _, // TODO: technically if this is changed from outside it should rerender the chart accordingly
    } = config;
    // normalization is required 'cause page/plotname were initially mistakenly named group/metric and many configs still have them
    const chartDataSource = normalizeLegacySource(source); // TODO: maybe drop eventually when not required anymore
    const isValidSource = isValidChartDataSource(chartDataSource);

    const timezone = account?.timezone ?? moment.tz.guess() ?? DEFAULT_TIMEZONE;
    const { dateRange: globalDateRange } = useDashboardContext() ?? {};

    const [isValidResponse, setIsValidResponse] = useState(true);
    const [currentInterval, setCurrentInterval] = useState<Interval | null>(COMMON_DATE_INTERVALS[0]);
    const [currentDateRange, setCurrentDateRange] = useState<DateRange>(
      dateRange ?? globalDateRange ?? [moment().subtract(1, 'day').toDate(), null]
    );

    const { getFailureHandler } = useGenericMutationHandlers();

    const q = useFilterDataQuery(
      queryParamsMiddleware({
        ...chartDataSource,
        start: currentDateRange?.[0] ? moment(currentDateRange[0]).tz(timezone, true).valueOf() : undefined,
        end: currentDateRange?.[1] ? moment(currentDateRange[1]).tz(timezone, true).valueOf() : undefined,
      }),
      {
        keepPreviousData: true,
        enabled: inView && isValidSource,
        onError: getFailureHandler((error: any) => {
          setIsValidResponse(false);
          onLoadError?.(error, chartDataSource);
        }),
        onSuccess: (data: FilterDataResponse) => {
          setIsValidResponse(true);
          onLoadSuccess?.<FilterDataResponse>(data, chartDataSource);
        },
        refetchInterval: inView && inDateRange(new Date(), currentDateRange) ? CHART_REFRESH_INTERVAL : false,
        refetchIntervalInBackground: false,
      }
    );

    const { echartsConfig, events } = useMemo(
      () => {
        const params = {
          ...echartsData,
          options: config,
          dateRange: currentDateRange,
          timezone,
        };
        return echartsConfigMiddleware ? echartsConfigMiddleware(params) : chartConfigs[type](params);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [echartsData, config, currentDateRange, echartsConfigMiddleware]
    );

    useEffect(() => {
      if (dateRange || globalDateRange) {
        const newdateRange = (dateRange ?? globalDateRange)!;
        setCurrentDateRange(newdateRange);
        setCurrentInterval(getMatchingInterval(newdateRange) ?? null);
      }
    }, [dateRange, globalDateRange]);

    // channels and unit might require adjustment before passing to Chart, so we have to store and process them separately
    useEffect(() => {
      const channels = q.data?.result?.channels ?? [];
      const unit = q.data?.unit ?? '';

      setEChartsData(unit ? { ...q.data, ...normalizeData({ channels, unit }) } : { ...q.data, channels, unit });
      // when legend is invisible in the chart, its selected prop will not contain any info about channels, so we
      // generate some defaults and assume that by default all channels are selected
      if (!channelsVisible) {
        setChannelsVisible(
          channels.length ? channels.reduce((acc, channel) => ({ ...acc, [channel.name]: true }), {}) : null
        );
      }
    }, [q.data]);

    const handleIntervalChange = useCallback((e: any, idx: number) => {
      const newInterval = COMMON_DATE_INTERVALS[idx];

      setCurrentDateRange(range => {
        // keep endDate and adjust startDate to new interval
        const endDate = range[1] ?? moment().add(1, 'minute').toDate();
        const startDate = moment(endDate)
          .subtract(...newInterval.value)
          .toDate();

        return [startDate, endDate];
      });
      setCurrentInterval(newInterval);
    }, []);

    const handleShiftLeft = useCallback(() => {
      if (currentInterval?.value) {
        setCurrentDateRange(range => {
          const startDate = moment(range[0])
            .subtract(...currentInterval.value)
            .toDate();
          const endDate = config?.showZoomSlider ? range[1] ?? new Date() : range[0];

          return [startDate, endDate];
        });
      }
    }, [config?.showZoomSlider, currentInterval?.value]);

    const handleShiftRight = useCallback(() => {
      if (currentInterval?.value) {
        setCurrentDateRange(range => {
          const oneMinInFuture = moment().add(1, 'minute').toDate();
          const endDate = moment(range[1] ?? oneMinInFuture)
            .add(...currentInterval.value)
            .toDate();
          const startDate = config?.showZoomSlider ? range[0] : range[1] ?? oneMinInFuture;

          return [startDate, endDate];
        });
      }
    }, [config?.showZoomSlider, currentInterval?.value]);

    const handleViewToggle = useCallback(() => {
      setIsSummaryView(currentState => !currentState);
    }, []);

    const handleZoomRangeModeToggle = useCallback(() => {
      setIsZoomRangeMode(currentState => {
        chartRef.current?.setDataZoomMode(!currentState);
        return !currentState;
      });
    }, []);

    const handleReset = useCallback(() => {
      if (globalDateRange) {
        setCurrentDateRange(globalDateRange);
        setCurrentInterval(getMatchingInterval(globalDateRange) ?? null);
      }
    }, [globalDateRange]);

    const handleDownload = useCallback(() => {
      const dataUrl = chartRef.current?.saveAsImage({ type: 'png', backgroundColor: 'white' });
      if (dataUrl) {
        downloadFile(dataUrl, `${capitalize(type)}Chart - ${dateRangeToString(currentDateRange)}`);
      }
    }, [currentDateRange, type]);

    const handleLegendSelectChanged = useCallback(
      (e: LegendSelectChangedEvent) => {
        // inverting original logic here to mimic legends behaviour in old UI:
        // so clicking on a particular legend will deselect all others, unless there are none selected,
        // in that case clicking on a legend will reselect all again; clicking on unselected legend will
        // simply select it
        const areAllUnselected = Object.values(e.selected).every(selected => !selected);
        const wasSelected = !e.selected[e.name];
        if (areAllUnselected && wasSelected) {
          chartRef.current?.setLegend(true);
        } else if (wasSelected) {
          chartRef.current?.setLegend(false);
          chartRef.current?.setLegend(true, e.name);
        } else {
          chartRef.current?.setLegend(true, e.name);
        }

        if (config.showSummary) {
          setChannelsVisible(chartRef.current?.getConfig()?.legend[0].selected);
        }
      },
      [config.showSummary]
    );

    const handleSummaryLegendClicked = useCallback(
      (item: SummaryRow) => {
        const selected = {
          ...channelsVisible,
          // handleLegendSelectChanged is triggered after clicked legend is already toggled
          [item.name]: !channelsVisible?.[item.name],
        };

        handleLegendSelectChanged({
          type: 'legendselectchanged',
          name: item.name,
          selected,
        });
      },
      [channelsVisible, handleLegendSelectChanged]
    );

    // gain performance by not rendering anything until we have data - important when there are many charts on the page
    if (!inView || ((q.isLoading || q.isFetching) && !config.preRender)) {
      return <LoadingSpinner centered additionalClass="p-20" />;
    }

    if (!isValidSource) {
      return (
        <NoElementsContainer
          icon="EmptyChartSource"
          title={
            <Headline centered variant="headline-6">
              Please select a <span className="text-blue-ocean">Data Source</span>
            </Headline>
          }
          description={
            <CopyText variant="copy-4" centered>
              Data Source &gt; Select an Entity
            </CopyText>
          }
        />
      );
    }

    if (!isValidResponse) {
      return (
        <NoElementsContainer
          icon="InvalidChartSource"
          title={
            <Headline centered variant="headline-6">
              <span className="text-alert-red">Data source is not valid</span>
            </Headline>
          }
          description={
            <CopyText variant="copy-4" centered>
              Nothing to show. Please select another data source.
            </CopyText>
          }
        />
      );
    }

    return (
      <>
        {hasToolbar && (
          <div className={styles.toolbar}>
            <ToggleButtonGroup
              onChange={handleIntervalChange}
              exclusive
              variant="icon"
              round={false}
              additionalClass={styles.intervalButtons}
            >
              {COMMON_DATE_INTERVALS.map(({ label }) => (
                <ToggleButton
                  key={label}
                  active={currentInterval?.label === label}
                  additionalClass={styles.toggleButton}
                >
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            <div className={styles.nav}>
              <IconButton icon="ArrowLeft" size="xs" onClick={handleShiftLeft} disabled={!currentInterval} />
              <IconButton icon="ArrowRight" size="xs" onClick={handleShiftRight} disabled={!currentInterval} />
            </div>

            <div className={styles.actionButtons}>
              <IconButton
                icon={isZoomRangeMode ? 'ZoomOut' : 'ZoomIn'}
                size="xs"
                onClick={handleZoomRangeModeToggle}
                title={isZoomRangeMode ? i18nCommon.reset : i18n.zoom}
              />
              {!showSummary && (
                <IconButton
                  icon={isSummaryView ? 'Timeseries' : 'DataView'}
                  size="xs"
                  onClick={handleViewToggle}
                  title={isSummaryView ? i18n.switchToTimeSeries : i18n.switchToTable}
                  disabled={!q.data?.result?.channels?.length}
                />
              )}
              <IconButton
                icon="Reset"
                size="xs"
                onClick={handleReset}
                disabled={!globalDateRange}
                keepInteractive
                title={i18n.resetToDefaultDateRange}
              />
              <IconButton icon="Download" size="xs" onClick={handleDownload} title={i18n.downloadAsImage} />
            </div>
          </div>
        )}

        <div className={c(styles.chartWidgetBody, additionalClass, 'chart-widget-body')}>
          {isSummaryView ? (
            <SummaryView
              channels={echartsData?.channels ?? []}
              unit={echartsData?.unit}
              isLoading={q.isLoading}
              additionalClass="min-h-chart"
              options={config}
            />
          ) : (
            <>
              <Chart
                ref={chartRef}
                loading={q.isLoading}
                echartsConfig={echartsConfig}
                additionalClass={styles.chart}
                onLegendSelectChanged={handleLegendSelectChanged}
                {...events}
              />
              {showSummary && !!echartsData?.channels?.length && (
                <SummaryView
                  channels={echartsData.channels}
                  unit={echartsData.unit}
                  isLoading={q.isLoading}
                  options={config}
                  onChannelSelected={handleSummaryLegendClicked}
                  channelsSelected={channelsVisible}
                />
              )}
            </>
          )}
        </div>
      </>
    );
  }
);
