import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import c from 'classnames';
import { motion, MotionProps, usePresence } from 'framer-motion';
import scrollbarWidth from '@utils/detectScrollbarWidth';

export type DefaultMotionProps = MotionProps & {
  measureScroll?: boolean;
  children?: React.ReactNode;
  key: string;
  scrollRef?: { getScrollWidth: number };
  className?: string;
  full?: boolean;
};

export type MotionHandler = {
  getScrollWidth: () => number;
};

export const DefaultMotion = forwardRef<MotionHandler, DefaultMotionProps>(function DefaultMotion(
  { className, scrollRef, children, full, measureScroll, ...rest },
  ref
) {
  const motionRef = useRef<HTMLDivElement>(null);
  const [isPresent, safeToRemove] = usePresence();

  const measureScrollbarWidth = () => {
    return motionRef.current ? scrollbarWidth(motionRef.current) : 0;
  };

  useImperativeHandle(ref, () => ({
    getScrollWidth: () => (measureScroll ? measureScrollbarWidth() : 0),
  }));

  useEffect(() => {
    if (!isPresent && typeof safeToRemove === 'function') {
      setTimeout(() => safeToRemove(), 1000);
    }
  }, [isPresent, safeToRemove]);

  return (
    <motion.div
      ref={motionRef}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
      className={c(className, full && 'w-full h-full')}
      {...rest}
    >
      {children}
    </motion.div>
  );
});
