import { DateRange } from '@blueprintjs/datetime';
import React from 'react';
import IconButton from '@components/common/IconButton';
import { CronGenerator } from '@components/common/CronGenerator/CronGenerator';
import { SwitchBaseChildProps } from '@components/common/SwitchBase/SwitchBase';
import { CronString } from '@components/common/CronGenerator/components/CronString';

import styles from './ScheduleItem.module.scss';

export type ScheduleItemProps = SwitchBaseChildProps<{
  isEditable?: boolean;
  isRemovable?: boolean;
  schedule: string | DateRange;
  onEdit?: (newSchedule: string | DateRange) => void;
  onRemove?: () => void;
}>;

export const ScheduleItem: React.FC<ScheduleItemProps> = ({
  active,
  onChange,
  isEditable = true,
  isRemovable = true,
  schedule,
  onEdit,
  onRemove,
}) => {
  const handleModeToggle = (e: React.MouseEvent<HTMLElement>) => {
    onChange?.(e, !active);
  };

  return isEditable && active ? (
    <CronGenerator
      onSubmit={onEdit}
      onCancel={handleModeToggle}
      showCustomTab={false}
      enableRecurring={false}
      value={schedule}
    />
  ) : (
    <div className={styles.schedule}>
      <CronString schedule={schedule} />

      <div className={styles.actions}>
        {isEditable && (
          <div className={styles.action}>
            <IconButton icon="Edit" size="s" onClick={handleModeToggle} additionalClass={styles.removeBtn} />
          </div>
        )}
        {isRemovable && (
          <div className={styles.action}>
            <IconButton icon="Delete" size="s" onClick={onRemove} additionalClass={styles.removeBtn} />
          </div>
        )}
      </div>
    </div>
  );
};
