import React, { FC, useCallback, useMemo } from 'react';
import { SelectControl } from '@components/common/form/Select';
import { BaseSelectItem } from '@components/common/form/Select/BaseSelect';
import useTextSnippets from '@services/useTextSnippets';
import { uniq } from 'lodash';
import { ChartDataSource } from '@redux/widgetPage';
import { SubLevel } from '@components/layout/SubLevel';
import { EntityDetails } from '@redux/inventoryPage';

const useEntityOptions = (plots?: Record<string, string>, page?: string, plotname?: string | null) => {
  return useMemo(() => {
    const pageOptions = plots ? uniq(Object.values(plots)).map(item => ({ label: item, value: item })) : [];
    const selectedPage = page ? { label: page, value: page } : null;
    const plotnameOptions =
      plots && page
        ? uniq(Object.keys(plots).filter(item => plots[item] === page)).map(item => ({ label: item, value: item }))
        : [];
    const selectedPlotname = plotname ? { label: plotname, value: plotname } : null;

    return { pageOptions, selectedPage, plotnameOptions, selectedPlotname };
  }, [page, plotname, plots]);
};

export type PagePlotnamePickerProps = {
  source: ChartDataSource;
  plots?: EntityDetails['plots'];
  onChange?: (newSource: ChartDataSource) => void;
};

export const PagePlotnamePicker: FC<PagePlotnamePickerProps> = ({ source, plots, onChange }) => {
  const i18n = useTextSnippets('widgetPage');

  const { pageOptions, selectedPage, plotnameOptions, selectedPlotname } = useEntityOptions(
    plots,
    source?.page,
    source?.plotname
  );

  const handlePageChange = useCallback(
    (option: BaseSelectItem | null) => {
      onChange?.({
        entityId: source.entityId,
        page: option ? `${option.value}` : undefined,
        plotname: undefined,
      });
    },
    [onChange, source]
  );

  const handlePlotnameChange = useCallback(
    (option: BaseSelectItem | null) => {
      onChange?.({
        ...source,
        plotname: option ? `${option.value}` : undefined,
      });
    },
    [onChange, source]
  );

  return (
    <>
      <SubLevel depth={source.entityId ? 2 : 1} additionalClass="-mt-16">
        <SelectControl
          label={i18n.page}
          placeholder={i18n.selectPage}
          options={pageOptions}
          onChange={handlePageChange}
          value={selectedPage}
          filterable
          clearable
          fullWidth
        />
      </SubLevel>

      {!!selectedPage && (
        <SubLevel depth={source.entityId ? 2 : 1} additionalClass="-mt-16">
          <SelectControl
            label={i18n.plotname}
            placeholder={i18n.selectPlotname}
            options={plotnameOptions}
            onChange={handlePlotnameChange}
            value={selectedPlotname}
            filterable
            clearable
            fullWidth
          />
        </SubLevel>
      )}
    </>
  );
};
