import { Note } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { BACKUP_SUCCESS_STATUS } from '../constants';
import Icon from '@components/common/Icon';

export const BackupStatus = ({ note }: { note: Note }) =>
  note.text === BACKUP_SUCCESS_STATUS ? (
    <Icon name="SuccessMessage" additionalClass="text-green-base" />
  ) : (
    <Icon name="ErrorMessage" additionalClass="text-alert-red" />
  );
