import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import {
  DashboardsQueryKey,
  DashboardUpdateSuccessResponse,
  DashboardWithDetails,
  deleteDashboardEnd,
  removeDashboard,
  renameDashboardEnd,
  selectActiveItem,
  selectActiveItems,
  selectDashboardDialogs,
  updateDashboardField,
} from '@redux/dashboards';
import CopyText from '@components/typography/CopyText';
import { NotificationVariant, setNotification } from '@redux/notifications';
import useTextSnippets from '@services/useTextSnippets';
import { useApiClient } from '@infrastructure/api/useApiClient';
import Modal from '@components/common/Modal';
import { TextField } from '@components/common/form';
import useForm, { FormProvider } from '@components/common/form/useForm';
import ROUTES from '@infrastructure/routes';
import fillTemplate from '@utils/fillTemplate';
import getSchema, { DashboardSchema } from '@redux/dashboards/dashboardSchema';
import DashboardList from '@components/pages/Dashboards/DashboardManagement/components/DashboardList/DashboardList';
import { useNavigate, matchPath, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';

const DashboardDialogManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const i18n = useTextSnippets('dashboards');
  const commonI18n = useTextSnippets('common');
  const dialogs = useAppSelector(selectDashboardDialogs);
  const dashboard = useAppSelector(selectActiveItem);
  const dashboards = useAppSelector(selectActiveItems);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const deleteDashboardMutation = useApiClient('deleteDashboard');
  const updateDashboardNameMutation = useApiClient<DashboardUpdateSuccessResponse, DashboardWithDetails>(
    'updateDashboard'
  );

  const getModal = useCallback(
    ({ danger, loading, disabled, isOpen, onClose, onAccept, title, acceptLabel, content }) => (
      <Modal
        icon={null}
        isCloseButtonShown={false}
        isOpen={isOpen}
        onClose={onClose}
        onAccept={onAccept}
        title={
          <CopyText variant="copy-1" additionalClass="truncate">
            {title}
          </CopyText>
        }
        loading={loading}
        disabled={disabled}
        acceptLabel={acceptLabel}
        danger={danger}
      >
        {content}
      </Modal>
    ),
    []
  );

  const addNotification = (type: NotificationVariant, title: string) => {
    dispatch(
      setNotification({
        type,
        title,
      })
    );
  };

  const deleteModal = getModal({
    isOpen: dialogs.deleteActive && dashboards.length > 0,
    title:
      (dashboards &&
        dashboards.length > 0 &&
        (dashboards.length === 1
          ? fillTemplate(i18n.deleteDashboardTitle, { name: dashboards[0].name })
          : i18n.deleteMultipleDashboardsTitle)) ||
      '',
    loading: deleteDashboardMutation.isLoading,
    acceptLabel: dashboards.length > 1 ? i18n.deleteDashboards : i18n.deleteDashboard,
    danger: true,
    content: (
      <div className="flex flex-col align-start">
        {dashboards.length > 1 && <DashboardList dashboards={dashboards} />}
        <CopyText variant="copy-4">{commonI18n.cannotBeUndoneWarning}</CopyText>
        <CopyText variant="copy-4" additionalClass="mt-10">
          {i18n.deleteDashboardMessage}
        </CopyText>
      </div>
    ),
    onClose: () => dispatch(deleteDashboardEnd()),
    onAccept: () => {
      if (dashboards && dashboards.length > 0) {
        dashboards.forEach((db, idx) => {
          deleteDashboardMutation.mutate(db.id, {
            // TODO tech debt: we only tackle the last item in the list for promise / notifications and redux follow-up ... etc.
            onSuccess: async () => {
              addNotification(
                'success',
                dashboards.length > 1 ? i18n.dashboardsDeleted : fillTemplate(i18n.dashboardDeleted, { name: db.name })
              );

              if (matchPath(ROUTES.dashboardById, location.pathname)) {
                navigate(ROUTES.dashboards, { replace: true });
              }

              dashboards.forEach(afterSuccessDB => {
                dispatch(removeDashboard(afterSuccessDB.id));
              });

              dispatch(deleteDashboardEnd());
            },
            onError: () => {
              addNotification(
                'error',
                dashboards.length > 1 && idx === dashboards.length
                  ? i18n.dashboardFailDeleteMultiple
                  : fillTemplate(i18n.dashboardFailDelete, { name: db.name })
              );
            },
          });
        });
      }
    },
  });

  const dashboardRenameForm = useForm<DashboardSchema>({
    validationSchema: getSchema(i18n),
    initialValues: { name: (dashboard && dashboard.name) || '' },
    enableReinitialize: true,
    onSubmit: (values: DashboardSchema) => {
      updateDashboardNameMutation.mutate(
        { ...dashboard!, name: values.name as string },
        {
          onSuccess: () => {
            dispatch(updateDashboardField({ field: 'name', id: dashboard!.id, value: values.name! }));
            addNotification('success', i18n.dashboardUpdated);
            dispatch(renameDashboardEnd());

            // we cannot know on what page is the dashboard that we just renamed, so we invalidate them all
            queryClient.invalidateQueries(DashboardsQueryKey);
          },
          onError: () => {
            addNotification('error', i18n.dashboardFailUpdate);
          },
        }
      );
    },
  });

  const renameModal = getModal({
    isOpen: dialogs.renameActive && dashboard !== null,
    title: i18n.renameDashboardTitle,
    acceptLabel: commonI18n.saveChanges,
    loading: dashboardRenameForm.isSubmitting || updateDashboardNameMutation.isLoading,
    disabled: Object.keys(dashboardRenameForm.errors).length > 0,
    content: (
      <FormProvider form={dashboardRenameForm}>
        <div className="flex flex-col align-start">
          <TextField size="l" label={commonI18n.name} type="text" name="name" autoComplete="off" />
        </div>
      </FormProvider>
    ),
    onClose: () => dispatch(renameDashboardEnd()),
    onAccept: () => dashboardRenameForm.submitForm(),
  });

  return (
    <>
      {deleteModal}
      {renameModal}
    </>
  );
};

export default DashboardDialogManager;
