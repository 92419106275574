import { useMutation, UseMutationResult } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericNotOkApiResponse, GenericOkApiResponse } from '../types';

export type DashboardFavoriteRequest = {
  categoryId: number;
  dashboardId: string;
  favorite?: boolean;
};

export const useDashboardFavoriteMutation = <
  S extends GenericOkApiResponse = GenericOkApiResponse,
  F extends GenericNotOkApiResponse = GenericNotOkApiResponse,
  V extends DashboardFavoriteRequest = DashboardFavoriteRequest
>(
  mutationOptions = {}
): UseMutationResult<S, F, V> => {
  return useMutation<S, F, V>(
    ({ favorite = false, ...body }: V): Promise<S> =>
      apiClient[favorite ? 'put' : 'delete'](ENDPOINTS.toggleDashboardFavorite, body),
    mutationOptions
  );
};
