import React from 'react';
import ErrorBoundary from '@services/errors/ErrorBoundary';
import logger from '@infrastructure/logging/logger';
import { ErrorPage } from './ErrorPage';

export const PageErrorBoundary: React.FC = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={({ resetErrorBoundary }) => <ErrorPage onReset={resetErrorBoundary} />}
      onReset={() => {
        window.location.reload();
      }}
      onError={error => {
        logger.error(error, {
          origin: 'PageErrorBoundary',
        });
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
