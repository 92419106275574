import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import {
  selectItemsAsArray,
  selectPagination,
  selectQueryParams,
  selectSorting,
  selectResolvedFilters,
  selectAreLoaded,
} from '@redux/logbook';
import { Sort, RemoteStoredTableProps, RowHeight } from '@components/common/Table';
import { getFiltersConfig, getTableColumns } from './utils';
import { SortingParams } from '@infrastructure/redux/types';
import { useLogbookQuery, Note } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { NoNotes } from './NoNotes';
import { EntitiesResponse, useEntitiesQuery } from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import { toIdMap } from '@utils/misc';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { AddEditNotePopup } from './AddEditNotePopup';
import Button from '@components/common/Button';
import Icon from '@components/common/Icon';
import Headline from '@components/typography/Headline';
import { FieldsConfig } from '@components/common/form/FormBuilder';
import { TablePageLayout } from '@components/layout/TablePageLayout/TablePageLayout';
import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import { ActiveItemDetails } from './ActiveItemDetails';
import { uniq, uniqueId } from 'lodash';
import CopyText from '@components/typography/CopyText';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import { toQueryStr } from '@utils/url';
import Error500Page from '@components/pages/Error500Page';

export type LogbookProps = {
  entityId?: string;
  actions: Dictionary<any>;
  showDetailsInSidePanel?: boolean;
  onInitialLoadComplete?: () => void;
};

export const Logbook: React.FC<LogbookProps> = ({
  entityId,
  actions,
  showDetailsInSidePanel = true,
  onInitialLoadComplete,
}) => {
  const { updatePagination, updateSorting, updateFilters, updateSearch, updateItems, updateRowHeight } = actions;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const areLoaded = useAppSelector(selectAreLoaded);
  const items = useAppSelector(selectItemsAsArray);
  const allFilters = useAppSelector(selectResolvedFilters);
  const pagination = useAppSelector(selectPagination);
  const sorting = useAppSelector(selectSorting);
  const params = useAppSelector(selectQueryParams);

  const [activeItem, setActiveItem] = useState<Note | null>(null);
  const [isAddEditPopupOpen, setIsAddEditPopupOpen] = useState(false);

  const notesQuery = useLogbookQuery({
    ...params,
    entity_ids: entityId,
  });

  const entitiesQuery = useEntitiesQuery<EntitiesResponse<EntityDetails>>(
    {
      ids: uniq(notesQuery?.data?.notes?.map(note => note.entityid)).join(',') ?? '',
      details: true,
    },
    { enabled: !!notesQuery.data?.notes?.length }
  );

  useEffect(() => {
    if (notesQuery.data?.notes) {
      dispatch(updatePagination({ rowCount: notesQuery.data.total }));

      if (entitiesQuery.data?.entities) {
        const entityMap = toIdMap(entitiesQuery.data.entities);
        const items = notesQuery.data.notes.map(note => ({
          ...note,
          // TODO: remove once all notes have unique IDs
          id: uniqueId('note-'),
          entity: entityMap[note.entityid],
        }));
        dispatch(updateItems(items));
        onInitialLoadComplete?.();
      }
    }
  }, [notesQuery.data, dispatch, onInitialLoadComplete, entitiesQuery.data?.entities, updatePagination, updateItems]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(updatePagination({ page: newPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSortChange = useCallback(
    (sortBy: string | null, sort: Sort) => {
      dispatch(updateSorting({ sortBy: sortBy as SortingParams['sortBy'], sort }));
    },
    [dispatch, updateSorting]
  );

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      dispatch(updatePagination({ rowsPerPage: newRowsPerPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSearch = useCallback(
    (keyword?: string) => {
      dispatch(updateSearch(keyword));
      dispatch(updatePagination({ page: 1 }));
    },

    [dispatch, updatePagination, updateSearch]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Dictionary<any>) => {
      dispatch(updateFilters(newFilters));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch, updateFilters, updatePagination]
  );

  const handleRowHeightChange = useCallback(
    (newRowHeight: RowHeight) => {
      dispatch(updateRowHeight(newRowHeight));
    },
    [dispatch, updateRowHeight]
  );

  const handleCreateNew = useCallback(() => {
    setIsAddEditPopupOpen(true);
  }, []);

  const handlePopupClose = useCallback(() => {
    setIsAddEditPopupOpen(false);
  }, []);

  const handleClosePanel = useCallback(() => {
    setActiveItem(null);
  }, []);

  const handleCellClick = useCallback((e, item) => {
    setActiveItem(item);
  }, []);

  const handleNavigate = useCallback(() => {
    if (activeItem?.link) {
      switch (activeItem?.type) {
        case 'session':
          navigate(
            `${ROUTES.sessionLogs.replace(':entityId', activeItem.entityid)}?${toQueryStr({
              sessionId: activeItem.link,
              user: activeItem.author,
              fromPage: entityId ? 'entity' : 'global'
            })}`
          );
          break;

        case 'maintenance':
          navigate(ROUTES.singleMaintenanceWindow.replace(':id', activeItem.link));
          break;
      }
    }
  }, [activeItem, navigate]);

  const filterConfig: FieldsConfig = useMemo(() => getFiltersConfig(allFilters), [allFilters]);

  const tableProps: RemoteStoredTableProps<Note> = useMemo(
    () => ({
      name: 'logbook',
      highlightedId: activeItem?.id,
      items,
      columns: getTableColumns(),
      onRowsPerPageChange: handleRowsPerPageChange,
      onPageChange: handlePageChange,
      onSortChange: handleSortChange,
      onSearch: handleSearch,
      onFiltersChange: handleFiltersChange,
      onRowHeightChange: handleRowHeightChange,
      filterConfig,
      keyword: allFilters.generalFilter,
      onCellClick: showDetailsInSidePanel ? handleCellClick : undefined,
      config: {
        ...pagination,
        sort: sorting?.sort,
        sortBy: sorting?.sortBy,
        loading: notesQuery.isLoading || notesQuery.isFetching,
        sortable: true,
        bordered: false,
        selectable: false,
      },
    }),
    [
      activeItem,
      items,
      handleRowsPerPageChange,
      handlePageChange,
      handleSortChange,
      handleSearch,
      handleFiltersChange,
      handleRowHeightChange,
      filterConfig,
      allFilters.generalFilter,
      showDetailsInSidePanel,
      handleCellClick,
      pagination,
      sorting?.sort,
      sorting?.sortBy,
      notesQuery.isLoading,
      notesQuery.isFetching,
    ]
  );

  const detailsPanelProps: DetailsPanelProps = useMemo(
    () => ({
      header: (
        <div className="truncate hbox whitespace-nowrap">
          <CopyText variant="copy-3" additionalClass="mr-8">
            ID:
          </CopyText>
          <CopyText variant="copy-4">{activeItem?.link}</CopyText>
        </div>
      ),
      isOpen: !!activeItem,
      onNavigate: handleNavigate,
      onClose: handleClosePanel,
      children: [<ActiveItemDetails key="note-details" item={activeItem} />],
    }),
    [activeItem, handleClosePanel, handleNavigate]
  );

  if (notesQuery.isError || entitiesQuery.isError) {
    return <Error500Page />;
  }

  return (
    <>
      <div className="h-full vbox">
        <div className="w-full mb-16 hbox">
          <Headline variant="headline-6">Logbook</Headline>
          <Button
            onClick={handleCreateNew}
            ariaLabel="Add Note"
            additionalClass="ml-auto"
            variant="fillBlueDark"
            size="s"
          >
            <Icon name="Add" additionalClass="mr-8" />
            Add Note
          </Button>
        </div>

        {!areLoaded ? null : !items.length ? (
          <NoNotes onCreateNew={handleCreateNew} />
        ) : (
          <TablePageLayout
            tableProps={tableProps}
            detailsPanelProps={
              showDetailsInSidePanel &&
                activeItem?.type &&
                ['session', 'maintenance', 'config'].includes(activeItem.type)
                ? detailsPanelProps
                : undefined
            }
          />
        )}
      </div>

      {isAddEditPopupOpen && <AddEditNotePopup isOpen onClose={handlePopupClose} entityId={entityId} />}
    </>
  );
};
