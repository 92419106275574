import React, { FC, useEffect, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import c from 'classnames';
import * as echarts from 'echarts';
import { clear as clearOnElementResize, bind as onElementResize } from 'size-sensor';
import defaultTheme from './defaultTheme';
import { SupportedEvents } from './types';
import { bindEvents } from '@utils/chart';
import { isEmpty, isUndefined } from 'lodash';
import { LoadingOverlay } from '@components/common/Overlay';

import styles from './Chart.module.scss';

echarts.registerTheme('defaultTheme', defaultTheme);

export type ChartHandle = {
  getConfig: () => any; // wonnabe echarts.EChartsOption

  setConfig: (config: any, options?: UpdateProps) => void;

  getModel: () => any;

  saveAsImage: (opts?: {
    type?: 'png' | 'jpg' | 'svg';
    pixelRatio?: number;
    backgroundColor?: string;
    connectedBackgroundColor?: string;
    excludeComponents?: string[];
  }) => string;

  setDataZoomMode: (state: boolean) => void;

  setLegend: (state: boolean, name?: string) => void;
};

export type InitProps = {
  devicePixelRatio?: number;
  renderer?: 'svg' | 'canvas';
  useDirtyRect?: boolean; // Since `5.0.0`
  locale?: string;
  width?: number;
  height?: number;
};

export type UpdateProps = {
  notMerge?: boolean;
  lazyUpdate?: boolean;
  replaceMerge?: string | string[];
  silent?: boolean;
};

type ChartTheme = 'defaultTheme';

export type ChartProps = {
  echartsConfig?: any; // could have been echarts.EChartsOption, but complaints too much on union types
  loading?: boolean;
  theme?: ChartTheme;
  initProps?: InitProps;
  updateProps?: UpdateProps;
  additionalClass?: string;
  ref?: React.ForwardedRef<ChartHandle>;
} & SupportedEvents;

export const Chart: FC<ChartProps> = forwardRef<ChartHandle, ChartProps>(
  (
    {
      echartsConfig,
      loading = false,
      theme = 'defaultTheme',
      initProps,
      updateProps,
      children,
      additionalClass,
      ...eventProps
    },
    ref
  ) => {
    const chartRef = useRef<echarts.ECharts | null>(null);
    const prevEventProps = useRef<SupportedEvents>(eventProps);

    const [chartContainer, setChartContainer] = useState<HTMLDivElement | null>(null);

    useEffect(
      () => {
        if (chartContainer) {
          chartRef.current = echarts.init(chartContainer, theme, { renderer: 'canvas', ...initProps });

          // it's recommended to register the callbacks for such an event before setOption in case the callbacks
          // may not be called as expected due to the timing issue when the animation is disabled.
          bindEvents(chartRef.current, eventProps);

          if (!isEmpty(echartsConfig)) {
            chartRef.current.setOption(echartsConfig, { silent: true, notMerge: true, ...updateProps });
          }

          onElementResize(chartContainer, () => {
            try {
              chartRef.current?.resize();
            } catch (e) {
              // eslint-disable-next-line no-console
              console.warn(e);
            }
          });
        }

        // cleanup on unmount
        return () => {
          if (chartRef.current) {
            chartRef.current.dispose();
          }
          if (chartContainer) {
            clearOnElementResize(chartContainer);
          }
          chartRef.current = null;
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [chartContainer]
    );

    // make sure we re-bind events (might happen after data is loaded and config re-generated accordingly)
    useEffect(() => {
      if (chartRef.current) {
        bindEvents(chartRef.current, prevEventProps.current, false);
        bindEvents(chartRef.current, (prevEventProps.current = eventProps), true);
      }
    }, [eventProps]);

    useEffect(
      () => {
        if (!isEmpty(echartsConfig)) {
          chartRef.current?.setOption(echartsConfig, { silent: true, notMerge: true, ...updateProps });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [echartsConfig]
    );

    useEffect(() => {
      chartRef.current?.resize({ width: initProps?.width, height: initProps?.height });
    }, [initProps?.width, initProps?.height]);

    useImperativeHandle(ref, () => ({
      getConfig() {
        // @ts-ignore
        return chartRef.current?.getOption() ?? null;
      },

      setConfig(config: any, options?: UpdateProps) {
        chartRef.current?.setOption(config, options);
      },

      getModel() {
        // @ts-ignore
        return chartRef.current?.getModel() ?? null;
      },

      saveAsImage(opts) {
        // @ts-ignore
        return chartRef.current.getConnectedDataURL(opts);
      },

      setDataZoomMode(state: boolean) {
        chartRef.current?.dispatchAction({
          type: 'takeGlobalCursor',
          key: 'dataZoomSelect',
          dataZoomSelectActive: state,
        });
      },

      setLegend(state?: boolean, name?: string) {
        if (name) {
          chartRef.current?.dispatchAction({
            type: state ? 'legendSelect' : 'legendUnSelect',
            name,
          });
        } else if (!isUndefined(state)) {
          chartRef.current?.dispatchAction({
            type: 'legendAllSelect',
          });
          if (!state) {
            // legendAllSelect followed by legendInverseSelect is the same as legendAllUnSelect (which doesn't exist)
            chartRef.current?.dispatchAction({
              type: 'legendInverseSelect',
            });
          }
        } else {
          chartRef.current?.dispatchAction({
            type: 'legendInverseSelect',
          });
        }
      },
    }));

    return (
      <div className={c(styles.chart, additionalClass, 'chart')}>
        <div className={styles.chartContainer} ref={setChartContainer}>
          {children}
        </div>
        {loading && <LoadingOverlay size="l" />}
      </div>
    );
  }
);

Chart.displayName = 'ForwardRefChart';
