import { ToggleButton, ToggleButtonGroup } from '@components/common/form/ToggleButton';
import { ChartWidgetType, PieWidgetSubConfig } from '@redux/widgetPage';
import { DEFAULT_PIE_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ChartWidget, ChartWidgetProps } from '../ChartWidget';
import { AggregateMethod } from './types';
import c from 'classnames';

export type PieWidgetProps = Omit<ChartWidgetProps, 'config'> & {
  config?: PieWidgetSubConfig | null;
};

export const PieWidget: FC<PieWidgetProps> = ({ config, ...props }) => {
  const {
    aggregateMethod: defaultAggregateMethod,
    showAggregateMethodSwitch,
    ...otherConfig
  } = { ...DEFAULT_PIE_WIDGET_CONFIG, ...config };
  const [aggregateMethod, setAggregateMethod] = useState(defaultAggregateMethod);

  useEffect(() => {
    setAggregateMethod(defaultAggregateMethod);
  }, [defaultAggregateMethod]);

  const handleChange = (e: React.MouseEvent<HTMLElement>, idx: number) => {
    setAggregateMethod(Object.values(AggregateMethod)[idx]);
  };

  const pieConfig = useMemo(
    () => ({ ...otherConfig, type: 'pie' as ChartWidgetType, aggregateMethod }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aggregateMethod, otherConfig]
  );

  return (
    <>
      <ChartWidget
        // without this layout won't update and switch will appear outside the boundaries of the widget
        key={showAggregateMethodSwitch ? 'with-switch' : 'no-switch'}
        {...props}
        config={pieConfig}
        additionalClass={c(showAggregateMethodSwitch && 'mb-30')}
      />
      {showAggregateMethodSwitch && (
        <ToggleButtonGroup onChange={handleChange} additionalClass="absolute bottom-10">
          {Object.values(AggregateMethod).map(method => (
            <ToggleButton key={method} active={method === aggregateMethod}>
              {method}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </>
  );
};
