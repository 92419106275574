import React, { useEffect, useState } from 'react';
import { FormSubmitOptions } from '@components/common/form';
import { useIsAuthenticated } from '@redux/auth';
import ROUTES from '@infrastructure/routes';
import { useAppSelector } from '@infrastructure/redux/store';
import { selectFeatureFlags } from '@redux/settings';
import { useApiClient } from '@infrastructure/api/useApiClient';
import { GenericOkApiResponse } from '@infrastructure/api/types';
import {
  ForgotPasswordCodeParams,
  ForgotPasswordCodeValidationResponse,
} from '@infrastructure/api/BaseNClient/useForgotPasswordCodeHandler';
import { ChangePasswordParams, NewPasswordApiResponse } from '@infrastructure/api/BaseNClient/useNewPasswordHandler';
import { DefaultMotion } from '@services';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import ForgotPasswordCodeForm from './ForgotPasswordForm/ForgotPasswordCodeForm';
import NewPasswordForm from './ForgotPasswordForm/NewPasswordForm';
import { ForgotPasswordSchema } from './ForgotPasswordForm/ForgotPasswordForm.schema';
import { ForgotPasswordCodeSchema } from './ForgotPasswordForm/ForgotPasswordCodeForm.schema';
import { NewPasswordSchema } from './ForgotPasswordForm/NewPassword.schema';
import { useNavigate, useParams } from 'react-router-dom';
import { useSettingsData } from '@infrastructure/api/BaseNClient';

const ForgotPasswordPage: React.FC = () => {
  const [codeErrorCount, setCodeErrorCount] = useState<number>(0);
  const [mailSent, setMailSent] = useState<boolean>(false);
  const [codeValidated, setCodeValidated] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const settingsQuery = useSettingsData();
  const userAuthenticated = useIsAuthenticated();
  const { allowPasswordRecovery } = useAppSelector(selectFeatureFlags);
  const forgotPasswordMutation = useApiClient<GenericOkApiResponse, ForgotPasswordSchema>('forgotPassword');
  const forgotPasswordCodeMutation = useApiClient<ForgotPasswordCodeValidationResponse, ForgotPasswordCodeParams>(
    'forgotPasswordCode'
  );
  const newPasswordMutation = useApiClient<NewPasswordApiResponse, ChangePasswordParams>('newPassword');
  const navigate = useNavigate();
  const params = useParams();

  const initialFormValues = {
    email: params.email ?? '',
  };

  useEffect(() => {
    // just redirect on client side if user manually navigates here and the feature is actually not supported on the portal
    if (settingsQuery.isSuccess && !allowPasswordRecovery) {
      // we've grabbed settings and feature is not supported, take the user out of here
      navigate(userAuthenticated ? ROUTES.home : ROUTES.login);
    }
  }, [settingsQuery, userAuthenticated, allowPasswordRecovery, navigate]);

  // email submit watch
  useEffect(() => {
    const { isSuccess, variables } = forgotPasswordMutation;

    if (isSuccess && !mailSent) {
      setMailSent(true);
      setEmail((variables?.email as string) || '');
    }
  }, [forgotPasswordMutation, mailSent]);

  // code submit watch
  useEffect(() => {
    const { isSuccess } = forgotPasswordCodeMutation;

    if (isSuccess && !codeValidated) {
      setCodeValidated(true);
    }
  }, [forgotPasswordCodeMutation, codeValidated]);

  const handleForgotPassword = (values: ForgotPasswordSchema, helpers: FormSubmitOptions<ForgotPasswordSchema>) => {
    forgotPasswordMutation.mutate(values, {
      onError: () => {
        helpers.setSubmitting(false);
      },
    });
  };

  const handleForgotPasswordCode = (
    values: ForgotPasswordCodeSchema,
    helpers: FormSubmitOptions<ForgotPasswordCodeSchema>
  ) => {
    forgotPasswordCodeMutation.mutate(
      { code: values.code, email },
      {
        onError: () => {
          helpers.setSubmitting(false);
          setCodeErrorCount(codeErrorCount + 1);
        },
      }
    );
  };

  const resetCodeErrorCount = () => setCodeErrorCount(0);

  const handleNewPasswordSubmit = (values: NewPasswordSchema, helpers: FormSubmitOptions<NewPasswordSchema>) => {
    newPasswordMutation.mutate(
      { password: values.newPassword!, email },
      {
        onError: () => {
          helpers.setSubmitting(false);
        },
      }
    );
  };

  const clearError = () => {
    forgotPasswordMutation.reset();
  };

  const clearCodeError = () => {
    forgotPasswordCodeMutation.reset();
  };

  const clearPasswordError = () => {
    newPasswordMutation.reset();
  };

  let motionKey = 'mailNotSent';

  if (mailSent && codeValidated) {
    motionKey = 'codeValidated';
  } else if (mailSent) {
    motionKey = 'mailSent';
  }

  return (
    <DefaultMotion key={motionKey}>
      {!mailSent && (
        <ForgotPasswordForm
          onSubmit={handleForgotPassword}
          initialValues={initialFormValues}
          submitError={forgotPasswordMutation.isError}
          clearError={clearError}
        />
      )}
      {mailSent && !codeValidated && (
        <ForgotPasswordCodeForm
          email={email}
          codeErrorCount={codeErrorCount}
          resetCodeErrorCount={resetCodeErrorCount}
          onSubmit={handleForgotPasswordCode}
          submitError={forgotPasswordCodeMutation.isError}
          clearError={clearCodeError}
        />
      )}
      {mailSent && codeValidated && (
        <NewPasswordForm
          onSubmit={handleNewPasswordSubmit}
          submitError={newPasswordMutation.isError}
          clearError={clearPasswordError}
        />
      )}
    </DefaultMotion>
  );
};

export default ForgotPasswordPage;
