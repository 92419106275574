import React from 'react';
import c from 'classnames';
import CopyText, { CopyTextType } from '@components/typography/CopyText';

import styles from './HorizontalTitleSeparator.module.scss';

type Props = {
  title?: string | React.ReactNode;
  variant?: CopyTextType;
  additionalClass?: string;
  centered?: boolean;
};

const HorizontalTitleSeparator: React.FC<Props> = ({
  variant = 'copy-1',
  additionalClass,
  title,
  centered = false,
  children,
}) => (
  <div className={c(styles.container, centered && styles.isCentered, additionalClass)}>
    <CopyText variant={variant} color="dark" additionalClass={styles.title}>
      {title ?? children}
    </CopyText>
    <div className={styles.horizontalTitleSeparator} />
  </div>
);

export default HorizontalTitleSeparator;
