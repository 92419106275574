import React, { PropsWithChildren } from 'react';
import c from 'classnames';
import Drawer, { DrawerSize } from '@components/common/Drawer';
import { useBreakpoint } from '@services';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import IconButton from '@components/common/IconButton';
import CopyText from '@components/typography/CopyText';

import styles from './DetailsPanel.module.scss';

export type DetailsPanelProps = PropsWithChildren<{
  isOpen?: boolean;
  isLoading?: boolean;
  header?: React.ReactNode;
  onNavigate?: () => void;
  onClose?: () => void;
  additionalClass?: string;
}>;

export const DetailsPanel: React.FC<DetailsPanelProps> = ({
  isOpen = false,
  isLoading,
  onNavigate,
  onClose,
  header,
  children,
  additionalClass,
}) => {
  const {
    breakpoints: { isDesktop },
  } = useBreakpoint();

  const drawerContents = (
    <div className={c(styles.container, isLoading && 'justify-center')}>
      {isLoading ? (
        <LoadingSpinner centered />
      ) : (
        <>
          <div className={styles.drawerHeader}>
            <div className={styles.headerLinkContainer}>
              {header &&
                (typeof header === 'string' ? (
                  <CopyText variant="copy-6" color="dark" additionalClass={styles.headerText}>
                    {header}
                  </CopyText>
                ) : (
                  header
                ))}
            </div>
            <div className={styles.headerActionContainer}>
              {onNavigate && <IconButton size="s" icon="NewTab" onClick={onNavigate} />}
              {onClose && <IconButton size="s" icon="Close" onClick={onClose} />}
            </div>
          </div>
          {children}
        </>
      )}
    </div>
  );

  return isDesktop ? (
    <div className={c(styles.drawerContainer, isOpen && [styles.isOpen, 'isOpen'], additionalClass)}>
      {drawerContents}
    </div>
  ) : (
    <Drawer
      size={DrawerSize.LARGE}
      isOpen={isOpen}
      onClose={onClose}
      position="right"
      className={styles.drawerContainer}
      additionalClasses={{ root: additionalClass, body: styles.drawerBody, dialogBody: styles.drawerDialogBody }}
    >
      {drawerContents}
    </Drawer>
  );
};
