import React, { FC, useRef, useState } from 'react';
import { CodeEditor, CodeEditorHandle } from '@components/common/CodeEditor/CodeEditor';
import IconButton from '@components/common/IconButton';
import { ImportButton } from '@components/common/ImportButton/ImportButton';
import useTextSnippets from '@services/useTextSnippets';
import { WidgetSubConfig } from '@redux/widgetPage';
import c from 'classnames';
import HorizontalTitleSeparator from '@components/separators/HorizontalTitleSeparator/HorizontalTitleSeparator';
import { debounce } from 'lodash';
import { downloadFile } from '@utils/file';
import useSchema from './ConfigEditor.schema';

import styles from './ConfigEditor.module.scss';

export type ConfigEditorProps = {
  title?: string;
  defaultConfig?: WidgetSubConfig | null;
  onChange?: (config: WidgetSubConfig) => void;
  additionalClass?: string;
};

export const ConfigEditor: FC<ConfigEditorProps> = ({ title, defaultConfig, onChange, additionalClass }) => {
  const [error, setError] = useState('');
  const editorRef = useRef<CodeEditorHandle | null>(null);
  const [config, setConfig] = useState(JSON.stringify(defaultConfig, undefined, 2) ?? '');
  const i18n = useTextSnippets('widgetPage');
  const schema = useSchema();

  const handleExport = () => {
    const currentConfig = editorRef.current?.value;
    if (currentConfig) {
      const fileName = 'widget-config.json';
      downloadFile(new File([currentConfig], fileName, { type: 'application/json' }), fileName);
    }
  };

  const handleImport = (jsonStr: any) => {
    setConfig(jsonStr as string);
  };

  const handleChange = debounce(() => {
    const value = editorRef.current?.value ?? '';
    try {
      const json = JSON.parse(value);
      const newConfig = schema.validateSync(json, {
        abortEarly: true,
      });
      setError('');

      onChange?.({ ...newConfig, isTampered: true } as WidgetSubConfig);
    } catch (ex) {
      setError(`Invalid config: ${(ex as SyntaxError).message}`);
    }
  }, 500);

  return (
    <div className={c(styles.configEditor, additionalClass)}>
      <div className={styles.header}>
        <HorizontalTitleSeparator title={title ?? i18n.configEditorTitle} variant="copy-4" />

        <div className={styles.toolbar}>
          <ImportButton
            variant="fillOutline"
            icon="Import"
            title={i18n.importJSON}
            accept={['json']}
            preloadAs="text"
            onChange={handleImport}
            iconOnly
            additionalClass="ml-8"
            size="s"
          />
          <IconButton
            variant="fillOutline"
            icon="Download"
            title={i18n.exportJSON}
            onClick={handleExport}
            additionalClass="ml-8"
            round={false}
            size="s"
          />
        </div>
      </div>

      <CodeEditor
        ref={editorRef}
        value={config}
        onChange={handleChange}
        autoFocus
        additionalClass={styles.codeEditor}
      />

      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};
