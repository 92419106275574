import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { Favorite } from '@redux/sidebar';
import ENDPOINTS from '../endpoints';
import { apiClient } from '..';
import { GenericOkApiResponse } from '../types';
import { MAX_FAVORITES_TO_RETRIEVE } from '@components/layout/FavoritesMenu/constants';
import QUERY_CONFIGS from '../query-configs';

type FavoritesResponse = GenericOkApiResponse & {
  data: (Omit<Favorite, 'members'> & { members: string[] })[];
};

export const useFavoritesQuery = <T extends FavoritesResponse = FavoritesResponse>(
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useQuery<T, Error>(
    'favorites',
    (): Promise<T> =>
      apiClient.get<T>(ENDPOINTS.categories, {
        category_type: 'favorite',
        target_type: 'dashboard',
        ignoreglobal: true,
        maximum: MAX_FAVORITES_TO_RETRIEVE,
      }),
    {
      ...(QUERY_CONFIGS.stale as UseQueryOptions<T, Error>),
      ...queryOptions,
    }
  );
};
