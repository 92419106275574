import React, { useMemo } from 'react';
import { ShortcutList, ShortcutListProps } from '@components/pages/WidgetPage/components/ShortcutList';
import useTextSnippets from '@services/useTextSnippets';
import * as widgetSetups from '@components/widgets';
import { get } from 'lodash';
import { ALLOWED_WIDGETS } from '@constants/dashboard';
import { WidgetType } from '@infrastructure/redux/widgetPage';

type Props = {
  title?: string;
  onItemClick: (id: string) => void;
  shortcutListProps?: Partial<ShortcutListProps>;
  activeShortcut?: string;
};

const WidgetList: React.FC<Props> = ({
  title,
  onItemClick,
  activeShortcut = 'timeseries:line',
  shortcutListProps = {},
}) => {
  const i18n = useTextSnippets();

  const widgetList = useMemo<ShortcutListProps>(
    () => ({
      title,
      shortcuts: ALLOWED_WIDGETS.map((id: WidgetType) => {
        const { i18nTitle = '', icon, subTypes } = widgetSetups[id];
        return {
          id,
          title: get(i18n, i18nTitle, get(i18n, `widgetPage.${i18nTitle}`)),
          icon,
          active: subTypes?.length
            ? subTypes.map(type => [id, type].join(':')).includes(activeShortcut)
            : activeShortcut === id,
          onClick: onItemClick,
        };
      }),
    }),
    [i18n, onItemClick, title, activeShortcut]
  );

  return <ShortcutList {...widgetList} {...shortcutListProps} additionalClass="mb-16" />;
};

export default WidgetList;
