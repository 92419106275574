import { z } from 'zod';

export default z
  .object({
    global: z.boolean().optional().default(false),
    routing: z.boolean().optional(),
    entities: z
      .array(
        z.object({
          id: z.string(),
          name: z.string(),
        })
      )
      .optional(),
  })
  .refine(data => data.global || data.entities?.length, {
    message: "Either 'global' must be true or at least one entity is required",
  });
