import React from 'react';
import c from 'classnames';
import Icon, { IconNames } from '@components/common/Icon';
import { NotificationType, NotificationVariant } from '@redux/notifications';
import { Tag } from '@components/common/Tag';
import CopyText from '@components/typography/CopyText';
import styles from './Notification.module.scss';

const type2icon: Record<NotificationVariant, IconNames> = {
  success: 'SuccessMessage',
  error: 'ErrorMessage',
  warning: 'WarningMessage',
  info: 'InfoMessage',
};

export type Props = { onClose?: () => void; additionalClass?: string } & (NotificationType | undefined);

const Notification: React.FC<Props> = ({ additionalClass, type, title, description, onClose }) => {
  return (
    <Tag
      variant={type}
      size="auto"
      onRemove={onClose}
      ContentComponent="div"
      additionalClass={c(styles.notification, additionalClass)}
    >
      <div className={styles.container}>
        <Icon name={type2icon[type]} additionalClass={styles.notificationIcon} />
        <div className={styles.textContainer}>
          {title && (
            <CopyText variant="copy-3" color="inherit">
              {title}
            </CopyText>
          )}
          {description && (
            <CopyText
              variant="copy-6"
              color="inherit"
              additionalClass={c(styles.description, !title && styles.noTitle)}
            >
              {description}
            </CopyText>
          )}
        </div>
      </div>
    </Tag>
  );
};

export default Notification;
