import { InterfaceAlertName } from '@components/connected/InterfacesTable/types';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { toIdMap } from '@utils/misc';
import { useMemo } from 'react';
import { UseQueryOptions } from 'react-query';
import QUERY_CONFIGS from '../query-configs';
import { UseAbortableQuery } from './types';
import { Alert, useAlertsQuery } from './useAlertsQuery';
import { EntitiesRequest, EntitiesResponse, useEntitiesQuery } from './useEntitiesQuery';

export const useEntitiesAndAlertsQuery = (
  params: EntitiesRequest,
  queryOptions: UseAbortableQuery<EntitiesResponse<EntityDetails>> = {}
) => {
  const entities = useEntitiesQuery<EntitiesResponse<EntityDetails>>(
    {
      ...params,
      details: true,
      alerts: true,
    },
    {
      keepPreviousData: true,
      ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<EntitiesResponse<EntityDetails>, Error>),
      ...queryOptions,
    }
  );

  const alertIds = entities.data?.entities
    ?.reduce((ids, item) => ids.concat(item.alert_ids ?? []), [] as string[])
    .join(',');

  const alerts = useAlertsQuery(
    {
      ids: alertIds,
    },
    {
      enabled: !!alertIds,
    }
  );

  const data = useMemo(
    () => {
      const alertsById = toIdMap<Alert>(alerts.data?.alerts);

      return (
        entities.data?.entities.map(item => {
          const entityAlerts =
            item.alert_ids?.reduce((obj, alertId) => {
              const alert = alertsById[alertId];

              return alert ? { ...obj, [`${alert.info.name}:${alert.info.channel}`]: alert } : obj;
            }, {} as Record<InterfaceAlertName, Alert>) ?? {};

          return {
            ...item,
            alerts: entityAlerts,
          };
        }) ?? []
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alerts.data]
  );

  return {
    data,
    isLoading: entities.isLoading || alerts.isLoading,
  };
};
