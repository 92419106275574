import { UseMutationResult, UseQueryResult } from 'react-query';
import useErrorHandler from '@services/errors/useErrorHandler';
import { Endpoint, MutationEndpointHandler, QueryEndpointHandler } from './BaseNClient/types';
import {
  useCreateDashboardMutation,
  useCreateIssueViewMutation,
  useCreateWidgetMutation,
  useDashboardByIdQuery,
  useDashboardsQuery,
  useDeleteDashboardMutation,
  useFilterDataQuery,
  useForgotPasswordCodeHandler,
  useForgotPasswordHandler,
  useIssuesQuery,
  useLoginMutation,
  useNewPasswordHandler,
  useSettingsData,
  useUpdateAccountMutation,
  useUpdateDashboardMutation,
  useUpdateIssueMutation,
  useUpdateIssueViewMutation,
} from './BaseNClient';
import { FilterDataResponse } from './BaseNClient/useFilterDataQuery';

const endpointHandlers = {
  appSettings: useSettingsData,
  createDashboard: useCreateDashboardMutation,
  createIssueView: useCreateIssueViewMutation,
  dashboardById: useDashboardByIdQuery,
  dashboards: useDashboardsQuery,
  deleteDashboard: useDeleteDashboardMutation,
  fetchChartData: useFilterDataQuery,
  forgotPassword: useForgotPasswordHandler,
  forgotPasswordCode: useForgotPasswordCodeHandler,
  issues: useIssuesQuery,
  login: useLoginMutation,
  newPassword: useNewPasswordHandler,
  postWidget: useCreateWidgetMutation,
  updateAccount: useUpdateAccountMutation,
  updateDashboard: useUpdateDashboardMutation,
  updateIssue: useUpdateIssueMutation,
  updateIssueView: useUpdateIssueViewMutation,
};

type GenericUseApiClient<T, Ep extends keyof typeof endpointHandlers> = Parameters<
  | MutationEndpointHandler<Endpoint, T, typeof endpointHandlers[Ep]>
  | QueryEndpointHandler<Endpoint, T, typeof endpointHandlers[Ep]>
>;

type GenericApiClientResponse<T, P> = UseMutationResult<T, Error, P> | UseQueryResult<T, Error>;

// @deprecated DO NOT EXTEND THIS ANYMORE
export function useApiClient<T>(
  ...args: Parameters<QueryEndpointHandler<'appSettings', T, typeof useSettingsData>>
): UseQueryResult<T, Error>;
export function useApiClient<T>(
  ...args: Parameters<QueryEndpointHandler<'dashboards', T, typeof useDashboardsQuery>>
): UseQueryResult<T, Error>;
export function useApiClient<T>(
  ...args: Parameters<QueryEndpointHandler<'issues', T, typeof useIssuesQuery>>
): UseQueryResult<T, Error>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'postWidget', T, Variables, typeof useCreateWidgetMutation>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'createDashboard', T, Variables, typeof useCreateDashboardMutation>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'deleteDashboard', T, Variables, typeof useDeleteDashboardMutation>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'updateDashboard', T, Variables, typeof useUpdateDashboardMutation>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T>(
  ...args: Parameters<QueryEndpointHandler<'dashboardById', T, typeof useDashboardByIdQuery>>
): UseQueryResult<T, Error>;
export function useApiClient<T = FilterDataResponse>(
  ...args: Parameters<QueryEndpointHandler<'fetchChartData', T, typeof useFilterDataQuery>>
): UseQueryResult<T, Error>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'login', T, Variables, typeof useLoginMutation>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'forgotPassword', T, Variables, typeof useForgotPasswordHandler>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'forgotPasswordCode', T, Variables, typeof useForgotPasswordCodeHandler>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'newPassword', T, Variables, typeof useNewPasswordHandler>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'updateIssue', T, Variables, typeof useUpdateIssueMutation>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'updateIssueView', T, Variables, typeof useUpdateIssueViewMutation>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'updateAccount', T, Variables, typeof useUpdateAccountMutation>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, Variables>(
  ...args: Parameters<MutationEndpointHandler<'createIssueView', T, Variables, typeof useCreateIssueViewMutation>>
): UseMutationResult<T, Error, Variables>;
export function useApiClient<T, P = any>(
  endpoint: keyof typeof endpointHandlers,
  params?: GenericUseApiClient<T, typeof endpoint>[1],
  queryOptions?: GenericUseApiClient<T, typeof endpoint>[2]
): GenericApiClientResponse<T, P> {
  const throwError = useErrorHandler();

  if (!endpointHandlers[endpoint]) {
    throwError(new Error(`No endpoint handler for endpoint ${endpoint}`));
  }

  // @ts-ignore
  return endpointHandlers[endpoint]<T, P>(params, queryOptions) as GenericApiClientResponse<T, P>;
}
