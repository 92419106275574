import React, { FC } from 'react';
import {
  TextField,
  SelectField,
  ToggleField,
  PalettePickerField,
  RadioGroupField,
  TextFieldProps,
  RadioGroupFieldProps,
  ToggleFieldProps,
  PalettePickerFieldProps,
  SelectFieldProps,
} from '@components/common/form';
import { FieldConfig } from './types';
import c from 'classnames';
import { Radio } from '@components/common/form/Radio';
import invariant from '@utils/invariant';

import styles from './Field.module.scss';

const renderField = (config: FieldConfig) => {
  const {
    type,
    props: { options, ...props },
  } = config;
  invariant(props.name, `Make sure that every field config has a 'name' property: ${JSON.stringify(config)}`);

  switch (type) {
    case 'select':
      return (
        <SelectField
          additionalClasses={{ control: c(styles.selectField) }}
          inline
          fullWidth
          {...(props as SelectFieldProps)}
          options={options ?? []}
        />
      );

    case 'palette':
      return <PalettePickerField inline fullWidth {...(props as PalettePickerFieldProps)} options={options ?? []} />;

    case 'toggle':
      return <ToggleField size="s" additionalClass="mb-16" {...(props as ToggleFieldProps)} />;

    case 'textarea':
      return <TextField inline {...(props as TextFieldProps)} multiline />;

    case 'radiogroup':
      return (
        <RadioGroupField inline {...(props as RadioGroupFieldProps)}>
          {options?.map(({ label, value }: any) => (
            <Radio key={value} value={value} label={label} size="s" />
          ))}
        </RadioGroupField>
      );

    case 'password':
    case 'email':
    case 'text':
    default:
      return <TextField inline {...(props as TextFieldProps)} />;
  }
};

export const Field: FC<FieldConfig> = ({ renderer, additionalClass, ...config }) => {
  return (
    <div className={c(styles.field, additionalClass)}>
      {renderer ? renderer(config, renderField) : renderField(config)}
    </div>
  );
};
