import invariant from './invariant';

const findAncestor = (el: HTMLElement, predicate: (node: HTMLElement) => boolean): HTMLElement | null => {
  if (predicate(el)) {
    return el;
  }

  return el.parentNode instanceof Node ? findAncestor(el.parentNode as HTMLElement, predicate) : null;
};

export const getChildIndex = (el: HTMLElement, wrapperEl: HTMLElement | null): number => {
  if (!wrapperEl) {
    return -1;
  }

  const child = findAncestor(el, node => node.parentNode === wrapperEl);

  invariant(child instanceof Node, `Cannot locate an immediate child`);

  return Array.prototype.indexOf.call(wrapperEl.children, child);
};

export const setDocumentTitle = (title: string, suffix = 'BaseN Platform') => {
  document.title = [title, suffix].filter(Boolean).join(' - ');
};

export function isDomElement(element: any): element is HTMLElement {
  return typeof element.type === 'string';
}
