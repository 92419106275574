import { Wizard, WizardProps } from '@components/common/form/Wizard';
import Icon from '@components/common/Icon';
import { TargetsStep } from '../MWCreatePage/components';
import { AddScriptStep } from './components/steps/AddScriptStep';
import { AddEidtScriptWizardValues, Payload, targetsSchema } from './AddEditScriptWizard.schema';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useAppDispatch } from '@infrastructure/redux/store';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { setNotification } from '@infrastructure/redux/notifications';
import { ReviewStep } from './components/steps/ReviewStep';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';

export type AddEditScriptWizardProps = Omit<WizardProps, 'children'> & {
  scriptId?: string;
  onSubmit?: (values: AddEidtScriptWizardValues, options: { setIsSubmitting: (state: boolean) => void }) => void;
};

export const AddEditScriptWizard = ({ scriptId, initialValues }: AddEditScriptWizardProps) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isEditing = !!scriptId;

  const addEditScriptMutation = useMutation((data: Payload) =>
    isEditing ? apiClient.put(ENDPOINTS.scriptById, { ...data, id: scriptId }) : apiClient.post(ENDPOINTS.scripts, data)
  );

  const handleSubmit = React.useCallback(
    (values: AddEidtScriptWizardValues, { setIsSubmitting }) => {
      addEditScriptMutation.mutate(
        {
          ...values.script,
          entities: values.targets?.entities?.map(entity => entity.id),
        },
        {
          onSuccess: () => {
            setIsSubmitting(false);
            queryClient.invalidateQueries([ENDPOINTS.scripts]);
            dispatch(
              setNotification({
                type: 'success',
                title: `Script ${isEditing ? 'updated' : 'added'} successfully`,
              })
            );
            navigate(ROUTES.scripts);
          },
          onError: (error: any) => {
            setIsSubmitting(false);
            dispatch(
              setNotification({
                type: 'error',
                title: `Failed to ${isEditing ? 'update' : 'add'} script: ${
                  error.response?.data?.detail ?? error.message
                }`,
              })
            );
          },
        }
      );
    },
    [addEditScriptMutation, dispatch, isEditing, navigate, queryClient]
  );

  return (
    <Wizard
      onSubmit={handleSubmit}
      submitButtonText={
        <>
          <Icon name="CodeEditor" additionalClass="mr-8" />
          {isEditing ? 'Update' : 'Create'}
        </>
      }
    >
      <AddScriptStep
        name="script"
        title={`${isEditing ? 'Edit' : 'Add'} script`}
        initialValues={initialValues.script}
      />

      <TargetsStep
        name="targets"
        title="Targets"
        showGlobal={false}
        showRouting={false}
        initialValues={{ entities: initialValues.targets.entities, global: false, routing: false }}
        validationSchema={targetsSchema}
        queryParams={{ terminal_allowed: true }}
      />

      <ReviewStep name="review" title="Review" />
    </Wizard>
  );
};
