import { object, string, TypeOf } from 'yup';

import useTextSnippets from '@services/useTextSnippets';
import { useMemo } from 'react';

const useSchema = () => {
  const i18n = useTextSnippets('common');

  const schema = object({
    name: string().required(i18n.nameRequired),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, [i18n]);
};

export interface WidgetNameSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
