import { UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '../query-configs';
import { useValidatedQuery } from '@services/useValidatedQuery';
import z from 'zod';
import ENDPOINTS from '../endpoints';

const issueActionSchema = z.object({
  name: z.string(),
  description: z.string(),
});

const issueViewActionSchema = z.object({
  label: z.string(),
  script: z.string(),
  id: z.string(),
  order: z.number(),
});

const issueVisibilitySchema = z.enum(['shared', 'private']);

const issueCustomFieldSchema = z.object({
  data_type: z.enum(['string', 'number']),
  key: z.string(),
});

const issueViewSchema = z.object({
  id: z.preprocess(val => String(val), z.string()),
  name: z.string(),
  order: z.number(),
  visibility: issueVisibilitySchema,
  field_order: z.array(z.string()),
  description: z.string(),
  sort_column: z.string(),
  filters: z.object({
    groups: z.array(z.string()),
    custom_fields: z.array(z.string()),
    fields: z.array(z.string()),
  }),
  actions: z.array(issueViewActionSchema),
  // ¯\_(ツ)_/¯ technically it should only be 'small', 'medium', 'large', but occasionally server returns an empty string
  row_height: z.string().optional(),
  rows_per_page: z.number().optional(),
});

issueViewSchema.transform(obj => {
  // @ts-ignore
  if (obj.row_height === '') {
    delete obj.row_height;
  }
  return obj;
});

const issuesViewQueryResponseSchema = z.object({
  data: z.array(issueViewSchema),
});

export type IssueAction = z.infer<typeof issueActionSchema>;
export type IssueViewAction = z.infer<typeof issueViewActionSchema>;
export type IssueVisibility = z.infer<typeof issueVisibilitySchema>;
export type IssueCustomField = z.infer<typeof issueCustomFieldSchema>;

// TODO: remove this once BE is fixed
export type IssueView = Omit<z.infer<typeof issueViewSchema>, 'row_height'> & {
  row_height?: 'small' | 'medium' | 'large';
};
export type IssueViewsQueryResponse = z.infer<typeof issuesViewQueryResponseSchema>;

export const useIssueViewsQuery = <T extends IssueViewsQueryResponse = IssueViewsQueryResponse>(
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.issueViews, {}, issuesViewQueryResponseSchema, {
    ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
    ...queryOptions,
  });
};
