import React, { FC, useMemo } from 'react';
import useTextSnippets from '@services/useTextSnippets';
import { DataSourcePickerProps } from '@components/pages/WidgetPage/types';
import { useNavigate } from 'react-router';
import { useIssueViewsQuery } from '@infrastructure/api/BaseNClient/useIssueViewsQuery';
import { DEFAULT_ISSUE_VIEW } from '@infrastructure/redux/issues';
import ROUTES from '@infrastructure/routes';
import { BaseSelectItem, SelectControl } from '@components/common/form/Select';
import { IssueManagerSource } from '@infrastructure/redux/widgetPage';

export const DataSourcePicker: FC<DataSourcePickerProps> = ({ source, onChange, onNameChange }) => {
  const i18n = useTextSnippets('issues');
  const navigate = useNavigate();
  const { activeViewId } = (source ?? {}) as IssueManagerSource;

  const issueViews = useIssueViewsQuery();

  const issueViewOptionsWithDefault = useMemo(() => {
    const options =
      issueViews.data?.data.map(({ name: label, id: value }) => ({
        label,
        value,
      })) ?? [];
    options.unshift({ label: i18n.defaultIssueManager, value: DEFAULT_ISSUE_VIEW.id });
    return options;
  }, [issueViews.data, i18n]);

  const activeView = useMemo(
    () => issueViewOptionsWithDefault.find(({ value }) => value === activeViewId),
    [issueViewOptionsWithDefault, activeViewId]
  );

  const handleChangeIssueView = (option: BaseSelectItem | null) => {
    onChange?.(
      option
        ? {
            activeViewId: `${option.value}`,
          }
        : null
    );
    if (typeof option?.label === 'string') {
      onNameChange?.(option.label);
    }
  };

  return (
    <SelectControl
      label={i18n.issueView}
      placeholder={i18n.pickIssueViewOrCreate}
      loading={issueViews.isLoading}
      addCreateNew
      createNewLabel={i18n.manageViews}
      createNewIcon={false}
      onCreateNew={() => navigate(ROUTES.issueViewManager)}
      filterable
      fullWidth
      clearable={false}
      options={issueViewOptionsWithDefault}
      value={activeView}
      onChange={handleChangeIssueView}
      required
    />
  );
};
