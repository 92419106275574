import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { history } from '@services/useHistory';
import { LocationState } from './types';

const initialState: LocationState = {
  location: history.location,
  prevLocation: null,
  prevDocumentTitle: null,
  action: history.action,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    updateLocation(state, { payload }: PayloadAction<LocationState>) {
      const prevLocation = state.location;
      return { ...payload, prevLocation: prevLocation ?? state.location, prevDocumentTitle: document.title };
    },

    reset() {
      return initialState;
    },
  },
});

export const { updateLocation, reset } = locationSlice.actions;
export default locationSlice;

export * from './types';
