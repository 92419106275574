import { EntitiesRequest } from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import { withoutUndefined } from '@utils/misc';
import { isArray, isEmpty } from 'lodash';
import { Filters, PaginationParams, SortingParams } from '../types';

export const generateParams = (filters: Filters, pagination: PaginationParams, sorting?: SortingParams) => {
  const params: EntitiesRequest = {};

  if (filters.generalFilter) {
    // entities endpoint currently seems to not support regexes, but apparently uses them internally
    // params.general = `.*${filters.generalFilter}.*`;
    params.general = filters.generalFilter;
  }

  if (!isEmpty(filters.discreteFilters)) {
    const { terminal_allowed, ...dataFilters } = filters.discreteFilters;

    if (terminal_allowed) {
      params.terminal_allowed = terminal_allowed;
    }

    Object.keys(dataFilters).forEach((filterKey: any) => {
      params[`data.${filterKey}`] = isArray(dataFilters[filterKey])
        ? dataFilters[filterKey].join(',')
        : dataFilters[filterKey];
    });
  }

  params.start_index = (pagination.page - 1) * pagination.rowsPerPage;
  params.end_index = (pagination.page - 1) * pagination.rowsPerPage + pagination.rowsPerPage;

  if (sorting?.sort && sorting?.sortBy) {
    params.order = `${sorting.sort === 'desc' ? '-' : ''}${sorting.sortBy}`;
  }

  return withoutUndefined(params, true);
};
