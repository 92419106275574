import React from 'react';
import { RunScriptWizard } from './components/RunScriptWizard';
import { RunScriptSchema } from './components/RunScriptWizard.schema';
import { ProgressWrapper } from './components/ProgressWrapper';
import { updateQueryString } from '@services/useHistory';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';

export const ScriptRunPage = () => {
  const navigate = useNavigate();

  const [runningParams, setRunningParams] = React.useState<RunScriptSchema | null>(null);

  const handleStart = React.useCallback((batchId: string) => {
    updateQueryString({ batchId });
  }, []);

  const redirectToStaticPage = React.useCallback(() => {
    const batchId = new URLSearchParams(window.location.search).get('batchId');
    if (batchId) {
      navigate(ROUTES.trackScript.replace(':batchId', batchId));
    }
  }, [navigate]);

  const handleDisconnect = React.useCallback(
    isCompleted => {
      if (!isCompleted) {
        redirectToStaticPage();
      }
    },
    [redirectToStaticPage]
  );

  React.useEffect(() => {
    redirectToStaticPage();
  }, [redirectToStaticPage]);

  return (
    <div className="page-container">
      <div className="page-body">
        {runningParams ? (
          <ProgressWrapper runningParams={runningParams} onStart={handleStart} onDisconnect={handleDisconnect} />
        ) : (
          <RunScriptWizard onSubmit={setRunningParams} />
        )}
      </div>
    </div>
  );
};
