import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import { EntityDataSource } from '@redux/widgetPage';
import React, { FC } from 'react';
import { WidgetComponentProps } from '@components/pages/WidgetPage/types';
import c from 'classnames';
import { InterfacesTable } from '@components/connected/InterfacesTable/InterfacesTable';

import styles from './InterfacesWidget.module.scss';

export type InterfacesWidgetProps = WidgetComponentProps;

export const InterfacesWidget: FC<InterfacesWidgetProps> = ({ config, additionalClass }) => {
  const { source } = config ?? {};
  const entityDataSource = source as EntityDataSource;
  const entity = useEntityQuery({ entityId: entityDataSource.entityId, include_children: true });

  return (
    <div className={c(styles.interfacesWidget, additionalClass)}>
      <InterfacesTable entity={entity.data} />
    </div>
  );
};
