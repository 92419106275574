import React from 'react';
import Button from '@components/common/Button';
import Icon from '@components/common/Icon';
import CopyText from '@components/typography/CopyText';
import { DownloadPopup, DownloadPopupProps } from './DownloadPopup';
import useTextSnippets from '@services/useTextSnippets';

export type DownloadButtonProps = Omit<DownloadPopupProps, 'target'> & {
  additionalClass?: string;
};

export const DownloadButton = ({ additionalClass, ...popupProps }: DownloadButtonProps) => {
  const i18n = useTextSnippets('common');
  const [target, setTarget] = React.useState<HTMLElement | null>(null);

  const handleDownloadClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setTarget(e.currentTarget);
  }, []);

  return (
    <>
      <Button
        variant="outline"
        onClick={handleDownloadClick}
        ariaLabel={i18n.download}
        size="s"
        additionalClass={additionalClass}
      >
        <div className="hbox">
          <Icon name="Download" size="s" additionalClass="mobileLandscape:mr-4" />
          <CopyText variant="copy-5" additionalClass="hidden mobileLandscape:inline-block">
            {i18n.download}
          </CopyText>
        </div>
      </Button>

      {target && <DownloadPopup {...popupProps} isOpen target={target} onClose={() => setTarget(null)} />}
    </>
  );
};
