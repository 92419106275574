import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  authSlice,
  settingsSlice,
  dashboardsSlice,
  notificationsSlice,
  sidebarSlice,
  layoutSlice,
  issuesSlice,
  logbookSlice,
  mwListPageSlice,
  inventoryPageSlice,
  metricsSlice,
  alertsSlice,
  widgetPageSlice,
  scriptsPageSlice,
} from './';
import locationSlice, { updateLocation } from '@redux/location/slice';
import { history } from '@services/useHistory';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics';
import { DEVTOOLS_OPTIONS } from '@constants/redux';
import configsSlice from './configsPage/slice';
import { IS_DEV } from '@config';

export type ReturnTypeByKey<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => infer A ? A : never;
}[keyof T];

export type ActionsType = ReturnTypeByKey<typeof locationSlice.actions> | ReturnTypeByKey<typeof authSlice.actions>;

const reducer = {
  [locationSlice.name]: locationSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [dashboardsSlice.name]: dashboardsSlice.reducer,
  [widgetPageSlice.name]: widgetPageSlice.reducer,
  [sidebarSlice.name]: sidebarSlice.reducer,
  [layoutSlice.name]: layoutSlice.reducer,
  [issuesSlice.name]: issuesSlice.reducer,
  [inventoryPageSlice.name]: inventoryPageSlice.reducer,
  [mwListPageSlice.name]: mwListPageSlice.reducer,
  [logbookSlice.name]: logbookSlice.reducer,
  [metricsSlice.name]: metricsSlice.reducer,
  [alertsSlice.name]: alertsSlice.reducer,
  [configsSlice.name]: configsSlice.reducer,
  [scriptsPageSlice.name]: scriptsPageSlice.reducer,
};

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer,
  // inject redux-observable
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(epicMiddleware),
  devTools: IS_DEV ? DEVTOOLS_OPTIONS : undefined,
});

// ...and activate epics
epicMiddleware.run(rootEpic);

export const getStore = () => store;

export type AppStore = typeof store;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

// subscribe to browser history changes and reflect them in the central store
history.listen(update => {
  store.dispatch(updateLocation(update));
});
