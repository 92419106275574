import { PortableLogbook } from '@components/pages/Logbook/components/PortableLogbook';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';

export const LogbookTab: React.FC<{ entity: EntityDetails }> = ({ entity }) => {
  return (
    <div className="overflow-auto bg-blue-gray-background min-h-0 h-full">
      <PortableLogbook entityId={entity?.id} />
    </div>
  );
};
