import React from 'react';
import { Dashboard } from '@redux/dashboards/types';
import moment from 'moment';
import CopyText from '@components/typography/CopyText';
// import { Badge } from '@components/common/Badge';
import { TableColumns } from '@components/common/Table/types';
import { ITextSnippets } from '@services/useTextSnippets';
import ROUTES from '@infrastructure/routes';
import Link from '@components/common/Link';

import styles from './DashboardManagement.module.scss';

export const getTableColumns = (i18n: ITextSnippets['dashboards']): TableColumns<Dashboard> => {
  return [
    {
      id: 'name',
      label: i18n.name,
      sortable: true,
      resizable: true,
      valueRenderer: (d: Dashboard) => (
        <div className="flex flex-row items-center flex-1 truncate text-dark">
          <Link
            href={`${ROUTES.dashboardById.replace(':dashboardId', d.id)}`}
            additionalClass={styles.dashboardLink}
            testid={`Dashboard::${d.name}`}
          >
            <CopyText variant="copy-4" additionalClass="truncate">
              {d.name}
            </CopyText>
          </Link>
        </div>
      ),
    },
    {
      id: 'modified',
      label: i18n.modified,
      valueRenderer(item: Dashboard) {
        return moment(item.modified).fromNow();
      },
      width: 150,
    },
    {
      id: 'user',
      label: i18n.owner,
      valueRenderer: (d: Dashboard) => d.username || d.user,
      width: 100,
    },
    // {
    //   id: 'access',
    //   label: i18n.access,
    //   valueRenderer: () => {
    //     const value = Math.random() > 0.5 ? 'Public' : 'Private';
    //
    //     return (
    //       <Badge
    //         size="m"
    //         additionalClass={
    //           value === 'Public'
    //             ? 'text-green-success bg-green-light border-1 border-solid border-green-success'
    //             : 'text-blue-info bg-blue-infoLight border-1 border-solid border-blue-info'
    //         }
    //       >
    //         {value}
    //       </Badge>
    //     );
    //   },
    //   width: 50,
    // },
  ].reduce((config, column) => ({ ...config, [column.id]: column }), {});
};
