import React from 'react';
import c from 'classnames';
import { MenuItem as BPMenuItem, MenuItemProps } from '@blueprintjs/core';
import styles from './style.module.scss';

/**
 * https://blueprintjs.com/docs/versions/4/#core/components/menu.menu-item
 * Relevant props:
 * active - bool
 * multiline - bool
 * disabled - bool
 * href - can be turned into a link
 * text - sse `text` prop for the content of the menu item itself
 * icon - your own or BP one
 * label - string - hotkeys
 * children - will be rendered in a __submenu__ that appears when hovering or clicking on this menu item
 */

export type Props = { disabledMenu?: boolean; additionalClass?: string; testid?: string } & MenuItemProps;

const MenuItem: React.FC<Props> = ({ additionalClass, disabledMenu, testid, ...rest }) => (
  <BPMenuItem
    data-testid={testid}
    className={c(styles.menuItem, disabledMenu && styles.disabledMenu, additionalClass)}
    {...rest}
  />
);

export default MenuItem;
