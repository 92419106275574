import { Overlay, Classes } from '@blueprintjs/core';
import useTextSnippets from '@services/useTextSnippets';
import Button from '@components/common/Button';
import Icon from '@components/common/Icon';
import { useDashboardFavoriteMutation } from '@infrastructure/api/BaseNClient';
import useFlags from '@services/useFlags';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import React, { useState, FC, useMemo, useRef } from 'react';
import { usePopper } from 'react-popper';
import { useQueryClient } from 'react-query';
import LoadingSpinner from '../LoadingSpinner';
import { FavoritesList } from './FavoritesList';
import { NewFavoriteForm } from './NewFavoriteForm';
import c from 'classnames';
import useFavoritesFor from './useFavoritesFor';
import { useFavoriteFolderCreateMutation } from '@infrastructure/api/BaseNClient/useFavoriteFolderCreateMutation';
import { ROOT_FOLDER_NAME } from './constants';
import useBreakpoint from '@services/useBreakpoint';
import Drawer from '@components/common/Drawer';
import { DashboardsQueryKey } from '@redux/dashboards';

import styles from './FavoritesMenu.module.scss';
import useIsMounted from '@services/useIsMounted';

export declare type VirtualElement = {
  getBoundingClientRect: () => DOMRect;
  contextElement?: Element;
};

export type FavoritesMenuProps = {
  isOpen?: boolean;
  dashboardId: string;
  targetEl: Element | VirtualElement | null;
  onClose: () => void;
  additionalClass?: string;
};

export const FavoritesMenu: FC<FavoritesMenuProps> = ({
  isOpen = false,
  dashboardId,
  targetEl,
  onClose,
  additionalClass,
}) => {
  const i18n = useTextSnippets('favorites');

  const queryClient = useQueryClient();
  const favorites = useFavoritesFor(dashboardId);
  const toggleFavoriteMutation = useDashboardFavoriteMutation();
  const createMutation = useFavoriteFolderCreateMutation();
  const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();
  const { setLoading, dataTrue } = useFlags();
  const isMounted = useIsMounted();

  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>(null);
  const { styles: popoverStyles, attributes: popoverAttrs } = usePopper(targetEl, popperEl);

  const contentAreaRef = useRef<HTMLUListElement | null>(null);

  const [isCreating, setIsCreating] = useState(false);

  const {
    breakpoints: { isDesktop },
  } = useBreakpoint();

  const folders = useMemo(
    // merge in loading states
    () => favorites.data?.map(item => ({ ...item, loading: dataTrue.includes(item.id.toString()) })) ?? [],
    [favorites.data, dataTrue]
  );

  const handleChange = (id: number, checked: boolean) => {
    setLoading(`${id}`, true);

    const toggleFavorite = () => {
      toggleFavoriteMutation.mutate(
        {
          dashboardId,
          categoryId: id,
          favorite: checked,
        },
        {
          onSuccess: getSuccessHandler(() => {
            // technically we should invalidate/remove only the query of the current dashboards page, but
            // since a page number is not available here, we remove all of them
            queryClient.invalidateQueries(DashboardsQueryKey);

            void Promise.all([
              // invalidating this updates items in the FavoritesMenu
              queryClient.invalidateQueries(['dashboard', { dashboardId }]),
              // the following should invalidate and trigger re-rendering of favorites tree in the sidebar
              queryClient.invalidateQueries('favorites'),
              queryClient.invalidateQueries([DashboardsQueryKey, { category_types: 'favorite' }]),
            ]).then(() => {
              if (isMounted()) {
                setLoading(`${id}`, false);
              }
            });
          }),
          onError: getFailureHandler(
            () => {
              if (isMounted()) {
                setLoading(`${id}`, false);
              }
            },
            undefined,
            true
          ),
        }
      );
    };

    if (id === 0) {
      // need to create root folder first, 'cause apparently it doesn't exist yet
      createMutation.mutate(
        { name: ROOT_FOLDER_NAME },
        {
          onSuccess: getSuccessHandler(() => {
            void queryClient.invalidateQueries('favorites').then(() => {
              toggleFavorite();
            });
          }),
          onError: getFailureHandler(),
        }
      );
    } else {
      toggleFavorite();
    }
  };

  const handleCreateNew = () => {
    setIsCreating(true);
    // scroll the last item in the view
    if (contentAreaRef.current) {
      contentAreaRef.current.scrollTop = contentAreaRef.current.scrollHeight;
    }
  };

  const handleNewCreated = () => {
    void queryClient.invalidateQueries('favorites').then(() => {
      setIsCreating(false);
      // scroll the last item in the view
      if (contentAreaRef.current) {
        contentAreaRef.current.scrollTop = contentAreaRef.current.scrollHeight;
      }
    });
  };

  const handleCancelCreate = () => {
    setIsCreating(false);
  };

  const menu = favorites.isLoading ? (
    <LoadingSpinner centered additionalClass={styles.spinner} />
  ) : (
    <>
      <FavoritesList onChange={handleChange} folders={folders} contentAreaRef={contentAreaRef} />
      {isCreating && <NewFavoriteForm onSuccess={handleNewCreated} onCancel={handleCancelCreate} />}

      <div className={styles.footer}>
        <Button variant="link" onClick={handleCreateNew} size="s">
          <Icon name="Folder" additionalClass="mr-8" size="s" />
          {i18n.createNewFolder}
        </Button>
      </div>
    </>
  );

  return isDesktop ? (
    <Overlay
      isOpen={isOpen}
      canEscapeKeyClose
      canOutsideClickClose
      hasBackdrop={false}
      autoFocus
      shouldReturnFocusOnClose
      usePortal
      className={c(Classes.OVERLAY_SCROLL_CONTAINER, styles.overlay)}
      onClose={onClose}
    >
      <div
        className={c(styles.favoritesMenu, additionalClass)}
        ref={setPopperEl}
        style={popoverStyles.popper}
        {...popoverAttrs.popper}
      >
        {menu}
      </div>
    </Overlay>
  ) : (
    <Drawer isOpen={isOpen} size="auto" onClose={onClose} additionalClasses={{ body: styles.drawerBody }}>
      <div className={c(styles.favoritesMenu, additionalClass)}>{menu}</div>
    </Drawer>
  );
};
