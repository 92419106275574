import React from 'react';
import { useField } from 'formik';
import { SelectControl, SelectControlProps } from './SelectControl';
import { BaseSelectItem } from './BaseSelect';

export type SelectFieldProps<ItemType extends BaseSelectItem = BaseSelectItem> = Omit<
  SelectControlProps<ItemType>,
  'value' | 'defaultValue' | 'onChange'
> & {
  name: string;
  defaultValue?: number | string;
  type?: 'number' | 'text';
};

export function SelectField<ItemType extends BaseSelectItem = BaseSelectItem>({
  defaultValue,
  options,
  size,
  placeholder,
  type = 'text',
  ...props
}: SelectFieldProps<ItemType>) {
  const [field, { error, touched }, helpers] = useField({ ...props, defaultValue });

  const handleChange = (item: BaseSelectItem | null) => {
    helpers.setValue(item?.value !== undefined ? (type === 'number' ? +item.value : item.value) : undefined);
  };

  const selectedOption = options.find((option: BaseSelectItem) => [`${field.value}`].includes(option.value.toString()));

  return (
    <SelectControl<ItemType>
      options={options}
      size={size}
      placeholder={placeholder}
      {...props}
      {...field}
      value={selectedOption}
      errorText={touched ? error : undefined}
      onChange={handleChange}
    />
  );
}
