import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import {
  selectItemsAsArray,
  selectPagination,
  selectQueryParams,
  selectSorting,
  selectResolvedFilters,
  selectAreLoaded,
} from '@redux/configsPage';
import { Sort, RemoteStoredTableProps, RowHeight } from '@components/common/Table';
import { getTableActions, getTableColumns } from './utils';
import { getFiltersConfig } from '@components/pages/Inventory/utils';
import { SortingParams } from '@infrastructure/redux/types';
import Headline from '@components/typography/Headline';
import { TablePageLayout } from '@components/layout/TablePageLayout/TablePageLayout';
import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import { ActiveItemDetails } from './ActiveItemDetails';
import CopyText from '@components/typography/CopyText';
import { SearchConfigItem, useSearchConfigsQuery } from '@infrastructure/api/BaseNClient/useSearchConfigsQuery';
import { floor } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import { toQueryStr } from '@utils/url';
import { FieldsConfig } from '@components/common/form/FormBuilder';
import Button from '@components/common/Button';
import Icon from '@components/common/Icon';
import useTextSnippets from '@services/useTextSnippets';
import { ConfigDownloadPopupProps, ConfigDownloadPopup } from './ConfigDownloadPopup';

export type ConfigSearchTableProps = {
  actions: Dictionary<any>;
  showDetailsInSidePanel?: boolean;
  onInitialLoadComplete?: () => void;
};

export const ConfigSearchTable: React.FC<ConfigSearchTableProps> = ({
  actions,
  showDetailsInSidePanel = true,
  onInitialLoadComplete,
}) => {
  const { updatePagination, updateSorting, updateFilters, updateSearch, updateItems, updateRowHeight } = actions;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const areLoaded = useAppSelector(selectAreLoaded);
  const items = useAppSelector(selectItemsAsArray);
  const allFilters = useAppSelector(selectResolvedFilters);
  const pagination = useAppSelector(selectPagination);
  const sorting = useAppSelector(selectSorting);
  const params = useAppSelector(selectQueryParams);
  const [downloadPopupProps, setDownloadPopupProps] = useState<ConfigDownloadPopupProps | null>(null);
  const [activeItem, setActiveItem] = useState<SearchConfigItem | null>(null);

  const query = useSearchConfigsQuery({
    ...params,
  });

  const commonI18n = useTextSnippets('common');
  const queryParams = useAppSelector(selectQueryParams);

  const handleDownloadClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDownloadPopupProps({
      params: queryParams,
      target: e.currentTarget,
    });
  };

  useEffect(() => {
    if (query.data?.result) {
      dispatch(updatePagination({ rowCount: query.data.total ?? query.data.result.length }));
      dispatch(updateItems(query.data?.result));
      onInitialLoadComplete?.();
    }
  }, [dispatch, onInitialLoadComplete, query.data, updateItems, updatePagination]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(updatePagination({ page: newPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSortChange = useCallback(
    (sortBy: string | null, sort: Sort) => {
      dispatch(updateSorting({ sortBy: sortBy as SortingParams['sortBy'], sort }));
    },
    [dispatch, updateSorting]
  );

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      dispatch(updatePagination({ rowsPerPage: newRowsPerPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSearch = useCallback(
    (keyword?: string) => {
      dispatch(updateSearch(keyword));
      dispatch(updatePagination({ page: 1 }));
    },

    [dispatch, updatePagination, updateSearch]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Dictionary<any>) => {
      dispatch(updateFilters(newFilters));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch, updateFilters, updatePagination]
  );

  const handleRowHeightChange = useCallback(
    (newRowHeight: RowHeight) => {
      dispatch(updateRowHeight(newRowHeight));
    },
    [dispatch, updateRowHeight]
  );

  const handleClosePanel = useCallback(() => {
    setActiveItem(null);
  }, []);

  const handleCellClick = useCallback((e, item) => {
    setActiveItem(item);
  }, []);

  const handleNavigate = useCallback(() => {
    if (activeItem?.time) {
      navigate(
        `${ROUTES.configDiff}?${toQueryStr({
          entityId1: activeItem.id,
          version1: floor(activeItem.time / 1000),
          fromPage: 'configSearch'
        })}`
      );
    }
  }, [activeItem, navigate]);

  const actionHandlers: Record<string, any> = useMemo(
    () => ({
      runScript: (selectedItems: SearchConfigItem[]) =>
        navigate(ROUTES.runScript, { state: { entities: selectedItems.map(({ id, name }) => ({ id, name })) } }),
      diff: (selectedItems: SearchConfigItem[]) =>
        navigate(
          `${ROUTES.configDiff}?${toQueryStr({
            entityId1: selectedItems[0].id,
            version1: floor(selectedItems[0].time / 1000),
            entityId2: selectedItems[1].id,
            version2: floor(selectedItems[1].time / 1000),
            fromPage: 'configSearch'
          })}`
        ),
    }),
    [navigate]
  );

  const filterConfig: FieldsConfig = useMemo(() => getFiltersConfig(allFilters), [allFilters]);

  const itemActions = useMemo(() => getTableActions(actionHandlers), [actionHandlers]);

  const tableProps: RemoteStoredTableProps<SearchConfigItem> = useMemo(
    () => ({
      name: 'configsSearch',
      placeHolder: 'Search in Configs',
      highlightedId: activeItem?.id,
      items,
      itemActions,
      columns: getTableColumns(allFilters),
      onRowsPerPageChange: handleRowsPerPageChange,
      onPageChange: handlePageChange,
      onSortChange: handleSortChange,
      onSearch: handleSearch,
      onFiltersChange: handleFiltersChange,
      onRowHeightChange: handleRowHeightChange,
      filterConfig,
      keyword: allFilters.generalFilter,
      onCellClick: showDetailsInSidePanel ? handleCellClick : undefined,
      isLoading: query.isLoading || query.isFetching,
      config: {
        ...pagination,
        sort: sorting?.sort,
        sortBy: sorting?.sortBy,

        sortable: true,
        bordered: false,
        selectable: true,
        resizable: true,
      },
    }),
    [
      activeItem?.id,
      items,
      itemActions,
      allFilters,
      handleRowsPerPageChange,
      handlePageChange,
      handleSortChange,
      handleSearch,
      handleFiltersChange,
      handleRowHeightChange,
      showDetailsInSidePanel,
      handleCellClick,
      filterConfig,
      pagination,
      sorting?.sort,
      sorting?.sortBy,
      query.isLoading,
      query.isFetching,
    ]
  );

  const detailsPanelProps: DetailsPanelProps = useMemo(
    () => ({
      header: (
        <div className="truncate hbox whitespace-nowrap">
          <CopyText variant="copy-3" additionalClass="mr-8">
            Version:
          </CopyText>
          {activeItem?.time && <CopyText variant="copy-4">{floor(activeItem.time / 1000)}</CopyText>}
        </div>
      ),
      isOpen: !!activeItem,
      onNavigate: handleNavigate,
      onClose: handleClosePanel,
      children: [
        <ActiveItemDetails key="note-details" item={activeItem} highlightMatches={[allFilters.generalFilter]} />,
      ],
    }),
    [activeItem, handleNavigate, handleClosePanel, allFilters.generalFilter]
  );

  return (
    <>
      <div className="flex flex-col h-full min-h-0">
        <div className="flex items-center mb-16">
          <Headline variant="headline-6" additionalClass="mb-16">
            Configs
          </Headline>
          <div className="ml-auto">
            <Button variant="outline" onClick={handleDownloadClick} ariaLabel={commonI18n.download} size="s">
              <div className="hbox">
                <Icon name="Download" size="s" additionalClass="mobileLandscape:mr-4" />
                <CopyText variant="copy-5" additionalClass="hidden mobileLandscape:inline-block">
                  {commonI18n.download}
                </CopyText>
              </div>
            </Button>
          </div>
          {downloadPopupProps && (
            <ConfigDownloadPopup isOpen {...downloadPopupProps} onClose={() => setDownloadPopupProps(null)} />
          )}
        </div>

        {!areLoaded ? null : (
          <TablePageLayout
            tableProps={tableProps}
            detailsPanelProps={showDetailsInSidePanel ? detailsPanelProps : undefined}
          />
        )}
      </div>
    </>
  );
};
