import { useMutation, UseMutationResult } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { WidgetMutationError, WidgetMutationSuccess } from './useCreateWidgetMutation';

export type WidgetDeleteRequest = {
  id: string;
};

export const useDeleteWidgetMutation = <
  S extends WidgetMutationSuccess = WidgetMutationSuccess,
  F extends WidgetMutationError = WidgetMutationError,
  V extends WidgetDeleteRequest = WidgetDeleteRequest
>(
  mutationOptions = {}
): UseMutationResult<S, F, V> =>
  useMutation<S, F, V>(
    (params: V): Promise<S> =>
      apiClient.delete(ENDPOINTS.deleteWidget, {
        widgetId: params.id,
      }),
    mutationOptions
  );
