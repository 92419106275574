import { FilterableToolbar, FilterableToolbarProps } from '@components/common/Table/components/FilterableToolbar';
import TextControl from '@components/common/form/Input/TextControl';
import { Select } from '@components/common/form/Select/Select';
import CopyText from '@components/typography/CopyText';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { DisplayMode } from '@infrastructure/redux/metricsPage';
import React from 'react';
import { BaseSelectItem } from '@components/common/form/Select';

import styles from './MetricsTableToolbar.module.scss';

const DISPLAY_MODE_OPTIONS: BaseSelectItem<DisplayMode>[] = [
  { label: 'List', value: 'list' },
  { label: 'Grid', value: 'grid' },
];

export type MetricsTableToolbarProps = FilterableToolbarProps<EntityDetails> & {
  displayMode?: DisplayMode;
  onDisplayModeChange?: (newMode: DisplayMode) => void;
};

export function MetricsTableToolbar({ displayMode = 'list', onDisplayModeChange, ...props }: MetricsTableToolbarProps) {
  const selectedOption = DISPLAY_MODE_OPTIONS.find(o => o.value === displayMode);

  const handleChange = React.useCallback((option: BaseSelectItem<DisplayMode> | null) => {
    if (option) {
      onDisplayModeChange?.(option.value);
    }
  }, []);

  return (
    <FilterableToolbar<EntityDetails> {...props}>
      <div className={styles.displaModeSelect}>
        <CopyText variant="copy-4" color="dark" additionalClass={'min-w-60'}>
          Display mode:
        </CopyText>
        <Select
          inPlace
          inputComponent={<TextControl variant="stealth" noInfo size="m" additionalClass={styles.input} />}
          filterable={false}
          clearable={false}
          options={DISPLAY_MODE_OPTIONS}
          value={selectedOption}
          onChange={handleChange}
        />
      </div>
    </FilterableToolbar>
  );
}
