import { CypressTestData, CypressTestType, CypressUtilityType } from './types';

/**
 * This array contains an entry with test data for each test type.
 */
export const CypressTestsData: CypressTestData = {
  [CypressTestType.SIDEBAR]: {
    cssClasses: {
      sideBarOpen: '.Sidebar_sideBarOpen',
    },
    testIds: {
      sidebarToggleButton: 'Sidebar-Toggle-Button',
    },
  },
  [CypressTestType.ISSUE_MANAGER]: {
    cssClasses: {},
    testIds: {
      topBarTotalIssuesCountLabel: 'Top-Bar-Total-Issues-Count-Label',
      clearAllFiltersButton: 'Clear-All-Filters-Button',
      filters: {
        newIssuesFilterButtonLabel: 'New-Issues-Filter-Button-Label',
        openIssuesFilterButtonLabel: 'Open-Issues-Filter-Button-Label',
        trackingIssuesFilterButtonLabel: 'Tracking-Issues-Filter-Button-Label',
        frozenIssuesFilterButtonLabel: 'Frozen-Issues-Filter-Button-Label',
        closedIssuesFilterButtonLabel: 'Closed-Issues-Filter-Button-Label',
        closingIssuesFilterButtonLabel: 'Closing-Issues-Filter-Button-Label',
      },
      downloadIssues: {
        downloadButton: 'Issues-Download-Button',
        formatSelectionButtonsSuffix: '-Format-Selection-Button',
        formatSelectionButtons: {
          xlsx: 'XLS-Format-Selection-Button',
          csv: 'CSV-Format-Selection-Button',
        },
      },
      views: {
        viewSelectInput: 'View-Select-Input',
        selectOptionsPrefix: 'Select-Options-Prefix',
        selectOptionsManageViews: 'Select-Options-Manage-Views',
      },
    },
  },
  [CypressUtilityType.UTILITY_TABLE]: {
    cssClasses: {},
    testIds: {
      tableWrapper: 'Table-Wrapper',
      totalItemsCountLabel: 'Total-Items-Count-Label',
      issuesPerPageInput: 'Issues-Per-Page-Input',
      searchInputField: 'Search-Input-Field',
      columns: {
        nameCellPrefix: 'Name-Cell',
        commentCellPrefix: 'Comment-Cell',
      },
    },
  },
};
