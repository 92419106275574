import React, { useState, useRef } from 'react';
// import React, { useState, useRef, useEffect } from 'react';
import CopyText from '@components/typography/CopyText';
import Popover from '@components/common/Popover';
import Gravatar from '@components/common/Gravatar';
import { useAuth } from '@redux/auth';
import UserMenu from '@components/layout/Header/components/User/UserMenu';
// import useBreakpoint from '@services/useBreakpoint';
import styles from './style.module.scss';

const UserButton: React.FC = () => {
  const { account } = useAuth();
  const userMenuRef = useRef<HTMLElement>(null);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  // const bp = useBreakpoint();

  // useEffect(() => {
  //   if (userMenuRef && userMenuRef.current) {
  // TODO figure out how to close this if resize happens and BP change takes place
  // not the highest priority right now
  // }
  // }, [bp]);

  return (
    <Popover
      position="bottom"
      content={<UserMenu helpOpen={helpOpen} setHelpOpen={setHelpOpen} />}
      additionalClass={styles.userPopover}
      noArrows
      popoverRef={userMenuRef}
      onClosed={() => setHelpOpen(false)}
    >
      <div className={styles.user}>
        <Gravatar username={account?.name ?? account?.username} email={account?.email} />
        <CopyText variant="copy-5" ellipsis additionalClass={styles.username}>
          {(account && (account.name || account.username)) || ''}
        </CopyText>
      </div>
    </Popover>
  );
};

export default UserButton;
