import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useLoadingContext } from 'react-router-loading';
import { EntitiesResponse, useEntitiesQuery } from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import {
  EntityDetails,
  selectItemsAsArray,
  selectPagination,
  selectQueryParams,
  selectSorting,
  updateItems,
  updatePagination,
  updateSorting,
  updateFilters,
  updateSearch,
  selectResolvedFilters,
  updateRowHeight,
  selectRowHeight,
} from '@redux/inventoryPage';
import { Sort, RemoteStoredTableProps, DEFAULT_FILTERS, RowHeight } from '@components/common/Table';
import { getFiltersConfig, getTableActions, getTableColumns } from './utils';
import useTextSnippets from '@services/useTextSnippets';
import { TablePageLayout } from '@components/layout/TablePageLayout/TablePageLayout';
import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import { ActiveItemDetails } from './components/ActiveItemDetails';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import { SortingParams } from '@infrastructure/redux/types';
import { FieldsConfig } from '@components/common/form/FormBuilder';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import { DefaultMotion } from '@services/theme';
import { routeToSiteUrl } from '@utils/url';
import { retrieveItem } from '@utils/storage';
import { MultiChoiceButtonItem } from '@components/common/MultiChoiceButton';
import { LinkScriptModal, LinkScriptModalProps } from './components/LinkScriptModal';
import { InventoryHeader } from './components/InventoryHeader';

import styles from './Inventory.module.scss';

export const InventoryPage = () => {
  const i18n = useTextSnippets('inventory');
  const loadingContext = useLoadingContext();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const items = useAppSelector(selectItemsAsArray);
  const allFilters = useAppSelector(selectResolvedFilters);
  const pagination = useAppSelector(selectPagination);
  const sorting = useAppSelector(selectSorting);
  const rowHeight = useAppSelector(selectRowHeight);
  const params = useAppSelector(selectQueryParams);
  const query = useEntitiesQuery<EntitiesResponse<EntityDetails>>({
    ...params,
    only_parent: true,
    details: true,
  });

  const [linkScriptModalProps, setLinkScriptModalProps] = React.useState<LinkScriptModalProps | null>(null);

  const [activeItem, setActiveItem] = useState<EntityDetails | null>(null);

  const activeItemQuery = useEntityQuery({ entityId: activeItem?.id }, { enabled: !!activeItem });

  useEffect(() => {
    document.title = 'Inventory - BaseN Platform';
  }, []);

  useEffect(() => {
    if (query.data?.entities) {
      dispatch(updateItems(query.data.entities));
      dispatch(updatePagination({ rowCount: query.data.total }));
      loadingContext.done();
    }
  }, [query.data, dispatch, loadingContext]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(updatePagination({ page: newPage }));
    },
    [dispatch]
  );

  const handleSortChange = useCallback(
    (sortBy: string | null, sort: Sort) => {
      dispatch(updateSorting({ sortBy: sortBy as SortingParams['sortBy'], sort }));
    },
    [dispatch]
  );

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      dispatch(updatePagination({ rowsPerPage: newRowsPerPage }));
    },
    [dispatch]
  );

  const handleRowHeightChange = useCallback(
    (newRowHeight: RowHeight) => {
      dispatch(updateRowHeight(newRowHeight));
    },
    [dispatch]
  );

  const handleNavigate = useCallback(() => {
    if (activeItem?.id) {
      navigate(ROUTES.entityById.replace(':entityId', activeItem?.id));
    }
  }, [activeItem?.id, navigate]);

  const handleClosePanel = useCallback(() => {
    setActiveItem(null);
  }, []);

  const handleSearch = useCallback(
    (keyword?: string) => {
      dispatch(updateSearch(keyword));
      dispatch(updatePagination({ page: 1 }));
    },

    [dispatch]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Dictionary<any>) => {
      dispatch(updateFilters(newFilters));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch]
  );

  const filterConfig: FieldsConfig = useMemo(() => getFiltersConfig(allFilters), [allFilters]);

  const actionHandlers: Record<string, any> = useMemo(
    () => ({
      runScript: (selectedItems: EntityDetails[]) =>
        navigate(ROUTES.runScript, { state: { entities: selectedItems.map(({ id, name }) => ({ id, name })) } }),
      openTerminal: (selectedItems: EntityDetails[]) => {
        const option = retrieveItem<MultiChoiceButtonItem>('terminal-button-option');
        const entity = selectedItems[0];
        if (option?.value === 'open-terminal-window') {
          window.open(
            routeToSiteUrl(ROUTES.standaloneTerminal.replace(':entityId', entity.id)),
            `Terminal ${Date.now()}`,
            'width=800,height=600,resizable=yes,location=no'
          );
        } else {
          window.open(routeToSiteUrl(ROUTES.terminal.replace(':entityId', entity.id)), '_blank');
        }
      },
      linkToScript: (selectedItems: EntityDetails[]) =>
        setLinkScriptModalProps({ selectedItems, isOpen: true, onClose: () => setLinkScriptModalProps(null) }),
    }),
    [navigate]
  );

  const itemActions = useMemo(() => getTableActions(actionHandlers), [actionHandlers]);

  const tableProps: RemoteStoredTableProps<EntityDetails> = useMemo(
    () => ({
      name: 'inventory',
      items,
      itemActions,
      columns: getTableColumns(allFilters ?? DEFAULT_FILTERS, i18n),
      onRowsPerPageChange: handleRowsPerPageChange,
      onRowHeightChange: handleRowHeightChange,
      onPageChange: handlePageChange,
      onSortChange: handleSortChange,
      onSearch: handleSearch,
      onFiltersChange: handleFiltersChange,
      onCellClick(e, item) {
        return setActiveItem(currentItem => (currentItem?.id === item.id ? null : item));
      },
      filterConfig,
      keyword: allFilters.generalFilter,
      isLoading: query.isLoading || query.isFetching,
      config: {
        ...pagination,
        sort: sorting?.sort,
        sortBy: sorting?.sortBy,
        selectable: true,
        bordered: true,
        filtersInPopup: false,
        rowHeight,
      },
      sidebarFilters: true,
    }),
    [
      items,
      itemActions,
      i18n,
      handleRowsPerPageChange,
      handleRowHeightChange,
      handlePageChange,
      handleSortChange,
      handleSearch,
      handleFiltersChange,
      filterConfig,
      allFilters,
      pagination,
      sorting?.sort,
      sorting?.sortBy,
      rowHeight,
      query.isLoading,
      query.isFetching,
    ]
  );

  const detailsPanelProps: DetailsPanelProps = useMemo(
    () => ({
      header: activeItem?.name,
      isOpen: !!activeItem,
      isLoading: activeItemQuery.isLoading || activeItemQuery.isFetching,
      onNavigate: handleNavigate,
      onClose: handleClosePanel,
      additionalClass: styles.entityDetailsPanel,
      children: [<ActiveItemDetails key="inventory-contents" item={activeItemQuery?.data} />],
    }),
    [
      activeItem,
      handleClosePanel,
      handleNavigate,
      activeItemQuery.isFetching,
      activeItemQuery.isLoading,
      activeItemQuery?.data,
    ]
  );

  return (
    <div className="page-container">
      <InventoryHeader />
      <div className="page-body">
        <DefaultMotion key="inventory" className="min-h-0">
          <TablePageLayout tableProps={tableProps} detailsPanelProps={detailsPanelProps} />
        </DefaultMotion>
      </div>
      {linkScriptModalProps && <LinkScriptModal {...linkScriptModalProps} />}
    </div>
  );
};
