import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';
import { GenericOkApiResponse } from '@infrastructure/api/types';

export const useDeleteDashboardMutation = <
  T extends GenericOkApiResponse = GenericOkApiResponse,
  P extends string = string
>(
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> =>
  useMutation<T, Error, P>(
    (dashboardId): Promise<T> => apiClient.delete<T, any>(ENDPOINTS.deleteDashboard, { dashboardId }),
    {
      ...mutationOptions,
    }
  );
