import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { DashboardsQueryKey, RawDashboard } from '@redux/dashboards';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '../query-configs';
import { GenericPaginatedResponse } from '../types';
import { PaginatedQueryRequest } from './usePaginatedQuery';

export type DashboardsQueryRequest = PaginatedQueryRequest & {
  tags?: string;
  includetags?: string;
  maximum?: number;
  category_types?: string;
  general?: string;
  my_dashboards?: boolean;
  favorites_only?: boolean;
  from_date?: string;
  to_date?: string;
};

export type DashboardsQueryResponse = GenericPaginatedResponse<RawDashboard> & {
  data: RawDashboard[];
};

export const useDashboardsQuery = <T extends DashboardsQueryResponse = DashboardsQueryResponse>(
  searchParams: DashboardsQueryRequest = {},
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useQuery<T, Error>(
    [DashboardsQueryKey, searchParams],
    (): Promise<T> => apiClient.get<T>(ENDPOINTS.dashboards, searchParams),
    {
      ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
      ...queryOptions,
    }
  );
};
