import React, { FC, useCallback } from 'react';
import c from 'classnames';
import { noop } from '@utils/util';
import { Tree as BPTree, TreeProps as BPTreeProps } from './BPTree';
import { TreeNodeInfo } from './BPTreeNode';
import styles from './style.module.scss';

export type TreeProps = Omit<BPTreeProps, 'onNodeCollapse' | 'onNodeExpand'> & {
  onNodeToggle?: (node: TreeNodeInfo, nodePath: number[], isExpanded: boolean, e: React.MouseEvent) => void;
  additionalClass?: string;
};

export const Tree: FC<TreeProps> = ({ additionalClass, onNodeToggle = noop, ...props }) => {
  const handleNodeCollapse = useCallback(
    (node: TreeNodeInfo, nodePath: number[], e: React.MouseEvent) => {
      onNodeToggle(node, nodePath, false, e);
    },
    [onNodeToggle]
  );

  const handleNodeExpand = useCallback(
    (node: TreeNodeInfo, nodePath: number[], e: React.MouseEvent) => {
      onNodeToggle(node, nodePath, true, e);
    },
    [onNodeToggle]
  );

  return (
    <BPTree
      className={c(styles.tree, additionalClass)}
      {...props}
      onNodeCollapse={handleNodeCollapse}
      onNodeExpand={handleNodeExpand}
    />
  );
};

export const { nodeFromPath } = BPTree;
export type { TreeNodeInfo } from './BPTreeNode';
