import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import {
  selectItemsAsArray,
  selectPagination,
  selectQueryParams,
  selectSorting,
  selectResolvedFilters,
  selectAreLoaded,
} from '@redux/scriptsPage';
import { Sort, RemoteStoredTableProps, RowHeight } from '@components/common/Table';
import { getTableActions, getTableColumns } from './utils';
import { SortingParams } from '@infrastructure/redux/types';
import Headline from '@components/typography/Headline';
import { TablePageLayout } from '@components/layout/TablePageLayout/TablePageLayout';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import { Script, useScriptsQuery } from '@infrastructure/api/BaseNClient/useScriptsQuery';
import Button from '@components/common/Button';
import Icon from '@components/common/Icon';
import useTextSnippets from '@services/useTextSnippets';
import CopyText from '@components/typography/CopyText';
import { ScriptDownloadPopupProps, ScriptDownloadPopup } from './ScriptDownloadPopup';

export type ScriptsPageTableProps = {
  actions: Dictionary<any>;
  showDetailsInSidePanel?: boolean;
  onInitialLoadComplete?: () => void;
};

export const ScriptsPageTable: React.FC<ScriptsPageTableProps> = ({
  actions,
  showDetailsInSidePanel = true,
  onInitialLoadComplete,
}) => {
  const { updatePagination, updateSorting, updateFilters, updateSearch, updateItems, updateRowHeight } = actions;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const areLoaded = useAppSelector(selectAreLoaded);
  const items = useAppSelector(selectItemsAsArray);
  const allFilters = useAppSelector(selectResolvedFilters);
  const pagination = useAppSelector(selectPagination);
  const sorting = useAppSelector(selectSorting);
  const params = useAppSelector(selectQueryParams);
  const commonI18n = useTextSnippets('common');
  const [downloadPopupProps, setDownloadPopupProps] = useState<ScriptDownloadPopupProps | null>(null);
  const [activeItem, setActiveItem] = useState<Script | null>(null);

  const queryParams = useAppSelector(selectQueryParams);

  const query = useScriptsQuery({
    ...params,
  });

  const handleDownloadClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDownloadPopupProps({
      params: queryParams,
      target: e.currentTarget,
    });
  };

  useEffect(() => {
    if (query.data?.data) {
      dispatch(updatePagination({ rowCount: query.data.total ?? query.data.data.length }));
      dispatch(updateItems(query.data?.data));
      onInitialLoadComplete?.();
    }
  }, [dispatch, onInitialLoadComplete, query.data, updateItems, updatePagination]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(updatePagination({ page: newPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSortChange = useCallback(
    (sortBy: string | null, sort: Sort) => {
      dispatch(updateSorting({ sortBy: sortBy as SortingParams['sortBy'], sort }));
    },
    [dispatch, updateSorting]
  );

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      dispatch(updatePagination({ rowsPerPage: newRowsPerPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSearch = useCallback(
    (keyword?: string) => {
      dispatch(updateSearch(keyword));
      dispatch(updatePagination({ page: 1 }));
    },

    [dispatch, updatePagination, updateSearch]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Dictionary<any>) => {
      dispatch(updateFilters(newFilters));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch, updateFilters, updatePagination]
  );

  const handleRowHeightChange = useCallback(
    (newRowHeight: RowHeight) => {
      dispatch(updateRowHeight(newRowHeight));
    },
    [dispatch, updateRowHeight]
  );

  const handleCellClick = useCallback((e, item) => {
    setActiveItem(item);
  }, []);

  const actionHandlers: Record<string, any> = useMemo(
    () => ({
      edit: (selectedItems: Script[]) => navigate(ROUTES.editScript.replace(':scriptId', selectedItems[0].id)),
      runScript: (selectedItems: Script[]) => navigate(ROUTES.runScript, { state: { script: selectedItems[0] } }),
    }),
    [navigate]
  );

  const itemActions = useMemo(() => getTableActions(actionHandlers), [actionHandlers]);

  const tableProps: RemoteStoredTableProps<Script> = useMemo(
    () => ({
      name: 'configsSearch',
      highlightedId: activeItem?.id,
      items,
      itemActions,
      columns: getTableColumns(allFilters),
      onRowsPerPageChange: handleRowsPerPageChange,
      onPageChange: handlePageChange,
      onSortChange: handleSortChange,
      onSearch: handleSearch,
      onFiltersChange: handleFiltersChange,
      onRowHeightChange: handleRowHeightChange,
      keyword: allFilters.generalFilter,
      onCellClick: showDetailsInSidePanel ? handleCellClick : undefined,
      isLoading: query.isLoading || query.isFetching,
      config: {
        ...pagination,
        sort: sorting?.sort,
        sortBy: sorting?.sortBy,
        sortable: true,
        bordered: false,
        selectable: true,
        resizable: true,
      },
    }),
    [
      activeItem?.id,
      items,
      itemActions,
      allFilters,
      handleRowsPerPageChange,
      handlePageChange,
      handleSortChange,
      handleSearch,
      handleFiltersChange,
      handleRowHeightChange,
      showDetailsInSidePanel,
      handleCellClick,
      query.isLoading,
      query.isFetching,
      pagination,
      sorting?.sort,
      sorting?.sortBy,
    ]
  );

  return (
    <>
      <div className="h-full vbox">
        <div className="w-full mb-16 hbox">
          <Headline variant="headline-6">Scripts</Headline>
          <Button
            onClick={() => navigate(ROUTES.addScript)}
            ariaLabel="Add Script"
            additionalClass="ml-auto"
            variant="fillBlueDark"
            size="s"
          >
            <Icon name="Add" additionalClass="mr-8" />
            Add Script
          </Button>
          <div className="ml-12">
            <Button variant="outline" onClick={handleDownloadClick} ariaLabel={commonI18n.download} size="s">
              <Icon name="Download" size="s" additionalClass="mobileLandscape:mr-4" />
              <CopyText variant="copy-5" additionalClass="hidden mobileLandscape:inline-block">
                {commonI18n.download}
              </CopyText>
            </Button>
          </div>
          {downloadPopupProps && (
            <ScriptDownloadPopup isOpen {...downloadPopupProps} onClose={() => setDownloadPopupProps(null)} />
          )}
        </div>
        {!areLoaded ? null : <TablePageLayout tableProps={tableProps} />}
      </div>
    </>
  );
};
