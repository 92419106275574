import { createSelector } from 'reselect';
import { AppState } from '@infrastructure/redux/store';
import { AuthState } from './types';
import { getUserToken } from '@utils/token';

export const selectAuthRoot = (state: AppState): AuthState => state.auth;

export const selectAccount = createSelector(selectAuthRoot, authRoot => authRoot.account);

export const selectToken = createSelector(selectAccount, account => account?.token.data || (getUserToken() ?? ''));
