import { Badge } from '@components/common/Badge';
import { useTableConfig } from '@components/common/Table/TableConfigContext';
import { Issue } from '@infrastructure/redux/issues';
import { getStatusBadgeVariant } from '@utils/issue';
import { capitalize } from '@utils/string';

export const IssueStateBadge = ({ issue }: { issue: Issue }) => {
  const { rowHeight } = useTableConfig();

  return (
    <Badge variant={getStatusBadgeVariant(issue.state)} size={rowHeight === 'small' ? 's' : 'm'}>
      {capitalize(issue.state)}
    </Badge>
  );
};
