import { createCustomSlice, createDataTableReducers, createInitialDataTableState } from '@utils/redux';
import { AlertsPageState } from './types';
import { ALERTS_TABLE_ID } from './selectors';
import { Alert } from '@infrastructure/api/BaseNClient/useAlertsQuery';

const initialState: AlertsPageState = createInitialDataTableState(ALERTS_TABLE_ID);

export const createAlertsSlice = (actionPrefix?: string) =>
  createCustomSlice({
    name: 'alertsPage',
    initialState,
    reducers: createDataTableReducers<Alert>(initialState),
    actionPrefix,
  });

const alertsSlice = createAlertsSlice();

export const { updateItems, updatePagination, updateSearch, updateSorting, updateFilters, updateRowHeight, reset } =
  alertsSlice.actions;

export default alertsSlice;
