import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Error404Page from '../Error404Page';
import { Terminal } from './components/Terminal';
import { EntityIcon } from '../EntityPage/components/EntityIcon';
import { EntityStatusDot } from '../EntityPage/components/EntityStatusDot';
import CopyText from '@components/typography/CopyText';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import IconButton from '@components/common/IconButton';
import ROUTES from '@infrastructure/routes';
import { setDocumentTitle } from '@utils/dom';

import styles from './TerminaPage.module.scss';

export type TerminalPageProps = {
  isStandalone?: boolean;
};

export const TerminalPage = ({ isStandalone }: TerminalPageProps) => {
  const { entityId } = useParams();
  const navigate = useNavigate();
  const entity = useEntityQuery({ entityId, alerts: true, include_maintenance: false });

  React.useEffect(() => {
    setDocumentTitle(`>_ ${entity?.data?.name ? `${entity?.data?.name}` : ''}`);
  }, [entity.data?.name]);

  if (!entity.isLoading && !entity.data) {
    return <Error404Page text="Entity not found" />;
  }

  if (entity.isLoading) {
    return null;
  }

  return isStandalone ? (
    <Terminal entityId={entity.data.id} isAuthRequired />
  ) : (
    <div className="page-container">
      <div className="page-header">
        <div className={styles.headerSection}>
          <IconButton
            icon="Back"
            onClick={() => navigate(ROUTES.entityById.replace(':entityId', entity.data.id))}
            additionalClass="mr-8"
          />
        </div>

        <CopyText variant="copy-2" additionalClass="hbox gap-8">
          <EntityIcon entity={entity.data} />
          {entity.data?.name}
        </CopyText>
        <EntityStatusDot entity={entity.data} additionalClass="ml-8" />
      </div>

      <div className="page-body">
        <Terminal entityId={entity.data.id} isAuthRequired />
      </div>
    </div>
  );
};
