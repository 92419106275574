import { FavoritesMenu } from '@components/layout/FavoritesMenu';
import React, { useRef, useState } from 'react';

type HandleOpenFavoritesProps = {
  dashboardId: string;
  targetEl: HTMLElement;
};

export type WithFavoritesMenu = {
  openFavoritesMenuCommand: (props: HandleOpenFavoritesProps) => void;
};

function withFavoritesMenu<P>(WrappedComponent: React.ComponentType<P & WithFavoritesMenu>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentFavoritesMenuProps = (props: P) => {
    const [isOpen, setIsOpen] = useState(false);
    const openFavoritesPropsRef = useRef<HandleOpenFavoritesProps | null>(null);

    const handleOpenFavoritesMenu = (openFavoritesProps: HandleOpenFavoritesProps) => {
      openFavoritesPropsRef.current = openFavoritesProps;
      setIsOpen(true);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    return (
      <>
        <WrappedComponent {...props} openFavoritesMenuCommand={handleOpenFavoritesMenu} />
        {openFavoritesPropsRef.current && (
          <FavoritesMenu isOpen={isOpen} {...openFavoritesPropsRef.current} onClose={handleClose} />
        )}
      </>
    );
  };

  ComponentFavoritesMenuProps.displayName = `withFavoritesMenu(${displayName})`;

  return ComponentFavoritesMenuProps;
}

export default withFavoritesMenu;
