import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import c from 'classnames';

import styles from './Timer.module.scss';

type TimerProps = {
  endTime: number;
  checkTimes?: number[];
  onCheckTime?: (timeLeft: number) => void;
  onTimeout?: () => void;
  autoFormat?: boolean;
  additionalClass?: string;
};

export const Timer: React.FC<TimerProps> = ({
  endTime,
  checkTimes,
  onCheckTime,
  onTimeout,
  autoFormat = true,
  additionalClass,
}) => {
  const [timeLeft, setTimeLeft] = useState(endTime - Date.now());
  const sortedCheckTimes = useRef(
    (checkTimes || []).filter(time => time > Date.now() && time <= endTime).sort((a, b) => a - b)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const newTimeLeft = endTime - now;
      setTimeLeft(newTimeLeft);

      if (sortedCheckTimes.current.length > 0 && onCheckTime) {
        while (sortedCheckTimes.current.length && now >= sortedCheckTimes.current[0]) {
          onCheckTime(sortedCheckTimes.current[0] - now);
          sortedCheckTimes.current.shift();
        }
      }

      if (newTimeLeft <= 0) {
        clearInterval(interval);
        setTimeLeft(0);
        onTimeout?.();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime, onTimeout, onCheckTime]);

  const color = timeLeft < 1000 * 60 ? 'warn' : timeLeft < 1000 * 60 * 3 ? 'notice' : 'default';

  return timeLeft ? (
    <div className={c(styles.timer, styles[color], additionalClass)}>
      {autoFormat ? moment.duration(timeLeft).humanize() : `${Math.max(0, Math.floor(timeLeft / 1000))}s`}
    </div>
  ) : null;
};
