import { UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '../query-configs';
import { useValidatedQuery } from '@services/useValidatedQuery';
import z from 'zod';
import ENDPOINTS from '../endpoints';
import { PaginatedQueryRequest } from './usePaginatedQuery';

const scheduleSchema = z.object({
  startTime: z.number().or(z.null()),
  endTime: z.number().or(z.null()),
});

const recurringScheduleSchema = z.object({
  recurrences: z.array(z.string()), // array of cron expressions, e.g. "* 30-59 9 * 5-7 * * *"
});

const ruleSchema = z.object({
  alert: z.string().optional(),
  page: z.string().optional(),
  entityid: z.string().optional(),
});

export const mwSchema = z.object({
  id: z.string(),
  reason: z.string(),
  times: z.array(scheduleSchema.or(recurringScheduleSchema)).optional(),
  name: z.string(),
  description: z.string().optional(),
  entityIds: z.array(z.string()).optional(),
  entityNames: z.array(z.string().nullable()).optional(),
  global: z.boolean().optional().default(false),
  routing: z.boolean().optional().default(false),
  last_modified: z.number().optional(),
  user: z.string().optional(),
  modifications: z.array(z.string()).optional(),
  // NOTE: this prop is weird: ¯\_(ツ)_/¯
  // 1. according to initial opinion it should be deprecated, so that if it's missing that would mean that MW is not a
  // legacy one: https://basen.slack.com/archives/C01RK33JYUR/p1679583628503129
  // 2. on the other hand it has to be sent to BE as received, 'cause "I don't know why", the problem might arise with
  // global MWs (see 3.), but an advice is to omit rules array altogether on submission in this case:
  // https://basen.slack.com/archives/D0219P9LWS1/p1679581325002509
  // 3. for every global MW there might be tens of thousands of rules - one per every existing entity (I say might be
  // because they might or might not be presented as such and nobody seems to know why)
  // 4. BE requires rules array to be present, because it generates entityIds array out of rules (for whatever reason),
  // advice is to go over rules array and leave items without entityId intact and updates those with defined entityId
  // (apparently will simply strip off all the existing entityId containing items and insert new ones):
  // https://basen.slack.com/archives/D0219P9LWS1/p1679580593036949
  rules: z.array(ruleSchema).optional(),
});

const mwQueryResponseSchema = z.object({
  maintenancewindows: z.array(mwSchema),
  total: z.number(),
});

export type Schedule = z.infer<typeof scheduleSchema>;
export type RecurringSchedule = z.infer<typeof recurringScheduleSchema>;
export type MaintenanceWindow = z.infer<typeof mwSchema>;
export type MaintenanceWindowsQueryRequest = PaginatedQueryRequest & {
  general?: string;
  future?: boolean;
  past?: boolean;
  global?: boolean;
  entityIds?: string; // comma-separated list of entity IDs
};
type MaintenanceWindowsQueryResponse = z.infer<typeof mwQueryResponseSchema>;

export const useMaintenanceWindowsQuery = <T extends MaintenanceWindowsQueryResponse = MaintenanceWindowsQueryResponse>(
  params: MaintenanceWindowsQueryRequest = {},
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.maintenanceWindows, params, mwQueryResponseSchema, {
    ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
    ...queryOptions,
  });
};
