import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '../query-configs';
import { IssueWithDetails } from '@redux/issues';

type IssueByIdRequest = {
  issueId?: string;
  issueFields?: string;
};

export const useIssueByIdQuery = <T extends IssueWithDetails = IssueWithDetails>(
  searchParams: IssueByIdRequest,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useQuery<T, Error>(
    ['issue', searchParams],
    (): Promise<T> =>
      apiClient.get<T>(ENDPOINTS.issueById, {
        issueFields: 'entity.id,entity.name', // without these entity data (name specifically) won't be retrieved at all
        ...searchParams,
      }),
    {
      ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
      ...queryOptions,
    }
  );
};
