import React, { useCallback } from 'react';
import useTextSnippets from '@services/useTextSnippets';
import c from 'classnames';
import Icon from '@components/common/Icon';
import { Menu, MenuItem, MenuDivider, MenuItemWithTitle } from '@components/common/Menu';
import Panel from '@components/common/Panel/Panel';
import Accordion from '@components/common/Accordion/Accordion';
import HelpMenu from '@components/layout/Header/components/HelpMenu';
import CopyText from '@components/typography/CopyText';
import { useAccount, useAuth } from '@redux/auth';
import Clock from '@components/layout/Header/components/Clock';
import ROUTES from '@infrastructure/routes';
import { useNavigate } from 'react-router-dom';
import { IS_PROD, VITE_BUILD_TIME, VITE_COMMIT_HASH } from '@config';
import { selectAppSettings } from '@infrastructure/redux/settings';
import { useAppSelector } from '@infrastructure/redux/store';

import styles from './style.module.scss';

type Props = {
  helpOpen?: boolean;
  setHelpOpen: (open: boolean) => void;
};

const UserMenu: React.FC<Props> = ({ helpOpen, setHelpOpen }) => {
  const { logout } = useAuth();
  const account = useAccount();
  const { settings, tz, version, uiversion, backToV1, signOut } = useTextSnippets('userMenu');
  const { help } = useTextSnippets('help');
  const navigate = useNavigate();
  const appSettings = useAppSelector(selectAppSettings);

  const goToV1 = useCallback(() => {
    // take the user to the current hostname with no path parameters with protocol
    window.location.href = `${window.location.protocol}//${window.location.hostname}`;
  }, []);

  const goToUserSettings = useCallback(() => {
    navigate(ROUTES.userSettings);
  }, [navigate]);

  return account ? (
    <Menu key="menu" className={styles.userMenu}>
      <MenuItemWithTitle
        disabledMenu
        shouldDismissPopover={false}
        title={account.username || account.name}
        subTitle={account.email}
      />
      <MenuDivider className={styles.helpMenuItem} />
      {/* <MenuItem className={styles.helpMenuItem} text={recentChanges} icon={<Icon name="Recent" />} />
      <MenuItem className={styles.helpMenuItem} text={notifications} icon={<Icon name="Notifications" />} /> */}
      <MenuItem
        shouldDismissPopover={false}
        className={c(styles.helpMenuItem, helpOpen && styles.activeHelpMenu)}
        text={
          <Accordion
            exclusive
            variant="menu"
            onChange={() => {
              setHelpOpen(!helpOpen);
            }}
          >
            <Panel title={help} active={helpOpen}>
              <HelpMenu />
            </Panel>
          </Accordion>
        }
        icon={<Icon name="Help" size="s" additionalClass={styles.helpIcon} />}
      />
      <MenuDivider />
      <MenuItem text={settings} onClick={goToUserSettings} />
      <MenuDivider />
      <MenuItem text={signOut} onClick={logout} />
      <MenuItem text={backToV1} onClick={goToV1} disabled={!IS_PROD} />
      {account && account.timezone && (
        <MenuItem
          shouldDismissPopover={false}
          disabledMenu
          text={
            <div>
              <CopyText variant="copy-8" additionalClass={styles.disabledTitle}>
                {tz.toUpperCase()}
              </CopyText>
              <Clock timezone={account.timezone} locale={account.locale} />
            </div>
          }
        />
      )}
      <MenuItem
        shouldDismissPopover={false}
        disabledMenu
        text={
          <div>
            <CopyText variant="copy-8" additionalClass={styles.disabledTitle}>
              {version.toUpperCase()}
            </CopyText>
            <CopyText variant="copy-6" additionalClass={styles.disabledLink}>
              {appSettings?.service.platformVersion || 'N/A'}
            </CopyText>
          </div>
        }
      />
      <MenuItem
        shouldDismissPopover={false}
        disabledMenu
        text={
          <div>
            <CopyText variant="copy-8" additionalClass={styles.disabledTitle}>
              {uiversion.toUpperCase()}
            </CopyText>
            <CopyText variant="copy-6" additionalClass={styles.disabledLink}>
              {VITE_BUILD_TIME || 'N/A'} ({VITE_COMMIT_HASH || 'N/A'})
            </CopyText>
          </div>
        }
      />
    </Menu >
  ) : null;
};

export default UserMenu;
