import getLineChartConfig from './line';
import getPieChartConfig from './pie';
import getBarChartConfig from './bar';
import getPolarChartConfig from './polar';

const chartConfigs = {
  line: getLineChartConfig,
  pie: getPieChartConfig,
  bar: getBarChartConfig,
  polar: getPolarChartConfig,
};

export default chartConfigs;
