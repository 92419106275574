import React, { createContext, useContext } from 'react';

export type RadioGroupContextType = {
  name?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RadioGroupContext = createContext<RadioGroupContextType | null>(null);

export function useRadioGroup() {
  return useContext(RadioGroupContext);
}
