import Card from '@components/common/Card/Card';
import { StatusBar } from './components/StatusBar';
import { useLoadingContext } from 'react-router-loading';
import React from 'react';
import { DeviceConfig, useDeviceConfigHistoryQuery } from '@infrastructure/api/BaseNClient/useDeviceConfigHistoryQuery';
import { DeviceConfigPanel } from './components/DeviceConfigPanel';
import { toQueryStr } from '@utils/url';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import { DeviceDetails } from './components/DeviceDetails';
import { PortableChangesTable } from './components/PortableChangesTable';
import CopyText from '@components/typography/CopyText';
import { Note } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { TWO_MINUTES } from '@constants/date';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { floor } from 'lodash';
import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import Headline from '@components/typography/Headline';

export type ConfigManagerProps = {
  entityId: string;
  onInitialLoadComplete?: () => void;
  onTerminalOpen?: () => void;
};

export const ConfigManager = ({ entityId, onInitialLoadComplete, onTerminalOpen }: ConfigManagerProps) => {
  const loadingContext = useLoadingContext();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = React.useState<Note | null>(null);

  const params = React.useMemo(
    () =>
      activeItem
        ? {
          entityId,
          start: (+activeItem.timestamp - TWO_MINUTES).toString(),
          end: (+activeItem.timestamp + TWO_MINUTES).toString(),
        }
        : { entityId, latest: true },
    [activeItem, entityId]
  );

  const configQuery = useDeviceConfigHistoryQuery(params);

  React.useEffect(() => {
    if (configQuery.data?.result) {
      onInitialLoadComplete?.();
    }
  }, [configQuery.data, loadingContext, onInitialLoadComplete]);

  const handleDiffClick = React.useCallback(
    (config: DeviceConfig) => {
      navigate(
        `${ROUTES.configDiff}?${toQueryStr({
          entityId1: entityId,
          version1: floor(config.time / 1000),
          fromPage: 'entity'
        })}`
      );
    },
    [entityId, navigate]
  );

  if (configQuery.isError) {
    return (
      <NoElementsContainer
        icon="EmptyConfig"
        title={
          <Headline centered variant="headline-4">
            There’s no config available for this entity
          </Headline>
        }
        description={
          <CopyText variant="copy-2">
            This entity is not parametrized for configuration snapshots. If you think it should be available
            please&nbsp;
            <a href={ROUTES.support}>contact support</a>.
          </CopyText>
        }
      />
    );
  }

  return (
    <div className="items-stretch h-full gap-16 hbox">
      <div className="w-full gap-8 vbox">
        <DeviceDetails entityId={entityId} onTerminalOpen={onTerminalOpen} />

        <Card header={<CopyText variant="copy-4">All Changes</CopyText>}>
          <PortableChangesTable entityId={entityId} onItemChange={setActiveItem} activeItem={activeItem} />
        </Card>
      </div>

      <div className="w-full gap-8 vbox">
        <StatusBar entityId={entityId} />
        {configQuery.isLoading ? (
          <LoadingSpinner centered additionalClass="p-20" />
        ) : (
          <DeviceConfigPanel config={configQuery.data?.result?.[0]} onDiffClick={handleDiffClick} />
        )}
      </div>
    </div>
  );
};
