import React from 'react';
import c from 'classnames';
import { Classes, Drawer as BPDrawer, DrawerProps as BPDrawerProps, Position } from '@blueprintjs/core';
import { useTheme } from '@services';

import styles from './Drawer.module.scss';

export type DrawerProps = MarkOptional<BPDrawerProps, 'position'> & {
  additionalClasses?: {
    root?: string;
    header?: string;
    body?: string;
    dialogBody?: string;
    footer?: string;
  };
  footer?: React.ReactNode;
  customHeader?: React.ReactNode;
  position?: Position;
};

/**
 * https://blueprintjs.com/docs/versions/4/#core/components/drawer
 * Relevant props:
 * autoFocus - bool = true
 * backdropProps - React.HTMLProps<HTMLDivElement>
 * canEscapeKeyClose - bool = true
 * canOutsideClickClose - bool = true
 * enforceFocus - bool = true
 * hasBackdrop - bool = true
 * isOpen - bool - required
 * onClose - evt => void = noop
 * onClosed - evt => void = noop
 * onOpened - evt => void = noop
 * position - Position - required
 * usePortal - bool = true
 */

export const Drawer: React.FC<DrawerProps> = ({
  children,
  additionalClasses = {},
  customHeader,
  footer,
  onClose,
  title,
  isCloseButtonShown,
  position = 'bottom',
  ...rest
}) => {
  const defaultDuration = useTheme('transitionDuration.DEFAULT');

  return (
    <BPDrawer
      className={c(styles.drawer, additionalClasses.root)}
      title={customHeader ? undefined : title}
      onClose={onClose}
      isCloseButtonShown={isCloseButtonShown}
      transitionDuration={parseInt(defaultDuration, 10)}
      position={position}
      {...rest}
      backdropClassName={styles.backdrop}
    >
      {customHeader && <div className={c(Classes.DIALOG_HEADER, additionalClasses.header)}>{customHeader}</div>}
      <div className={c(Classes.DRAWER_BODY, additionalClasses.body)}>
        <div className={c(Classes.DIALOG_BODY, additionalClasses.dialogBody)}>{children}</div>
      </div>
      {footer && <div className={c(Classes.DRAWER_FOOTER, additionalClasses.footer)}>{footer}</div>}
    </BPDrawer>
  );
};
