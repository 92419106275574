import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const useScrollAware = (className: string = 'isScrolling') => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const handleScrollStart = () => {
      if (!element.classList.contains(className)) {
        element.classList.add(className);
      }
    };

    const handleScrollEnd = debounce(() => {
      if (element.classList.contains(className)) {
        element.classList.remove(className);
      }
    }, 500);

    element.addEventListener('scroll', handleScrollStart);
    element.addEventListener('scroll', handleScrollEnd);

    return () => {
      element.removeEventListener('scroll', handleScrollStart);
      element.removeEventListener('scroll', handleScrollEnd);
      handleScrollEnd.cancel();
    };
  }, [className]);

  return ref;
};

export default useScrollAware;
