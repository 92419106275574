import React, { useCallback, useMemo } from 'react';
import useTextSnippets from '@services/useTextSnippets';
import c from 'classnames';
import { v4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import {
  IssueView,
  deleteView,
  selectGroups,
  CreateIssueView,
  selectIssueViewsLength,
  IssueGroup,
  IssueViewField,
  selectCustomFields,
  EMPTY_ISSUE_VIEW,
  IssueViewAction,
  selectIssueScriptOptions,
} from '@redux/issues';
import Headline from '@components/typography/Headline';
import getSchema, { IssueViewSchema } from './IssueView.schema';
import CopyText from '@components/typography/CopyText';
import ROUTES from '@infrastructure/routes';
import withConfirmation from '@services/withConfirmation';
import { useMutation, useQueryClient } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { setNotification } from '@redux/notifications';
import styles from './IssueViewManager.module.scss';
import { useApiClient } from '@infrastructure/api/useApiClient';
import { GenericOkApiResponse } from '@infrastructure/api/types';
import BasePage from '../../components/BasePage';
import useForm, { FormProvider } from '@components/common/form/useForm';
import { RowHeightPickerField, SelectField, TagInputField, TextField } from '@components/common/form';
import HorizontalSeparator from '@components/separators/HorizontalSeparator';
import Button from '@components/common/Button';
import { RadioGroupField } from '@components/common/form/Radio/RadioGroupField';
import Radio from '@components/common/form/Radio/Radio';
import { DropResult } from 'react-beautiful-dnd';
import ItemGridDND from '@components/common/ItemGridDND/ItemGridDND';
import { reorder } from '@utils/dndUtils';
import Icon from '@components/common/Icon';
import Card from '@components/common/Card/Card';
import ItemGridAdder from '@components/common/ItemGridAdder/ItemGridAdder';
import { TagType } from '@components/common/form/TagInput/TagInput';
import UnsupportedWrapper from '@components/common/UnsupportedWrapper/UnsupportedWrapper';
import { useNavigate } from 'react-router-dom';
import {
  getIssueViewFieldLabel,
  getAvailableFieldOptions,
  getAvailableCustomFieldOptions,
  getAvailableIssueGroupOptions,
} from '@components/connected/IssueManager/utils';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS, DEFAULT_TABLE_CONFIG } from '@components/common/Table';

export type IssueViewEditProps = {
  issueView?: IssueView;
};

const IssueViewEdit: React.FC<IssueViewEditProps> = withConfirmation(({ issueView, confirmOperation }) => {
  const dispatch = useAppDispatch();
  const commonI18n = useTextSnippets('common');
  const issueViewManagerI18n = useTextSnippets('issueViewManager');
  const issueViewKeysI18n = useTextSnippets('issueViewKeys');
  const groups = useAppSelector(selectGroups);
  const customFields = useAppSelector(selectCustomFields);
  const issueScriptOptions = useAppSelector(selectIssueScriptOptions);
  const viewsLength = useAppSelector(selectIssueViewsLength);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createViewMutation = useApiClient<IssueView, CreateIssueView>('createIssueView');

  const updateViewMutation = useApiClient<GenericOkApiResponse, IssueView>('updateIssueView');

  const handleViewDeleted = useCallback(() => {
    if (issueView) {
      dispatch(setNotification({ type: 'success', description: issueViewManagerI18n.viewDeleted }));
      dispatch(deleteView(issueView.id));
    }
    navigate(ROUTES.issueViewManager);
  }, [dispatch, issueView, issueViewManagerI18n.viewDeleted, navigate]);

  const deleteViewMutation = useMutation(() => apiClient.delete(ENDPOINTS.deleteIssueView, { id: issueView?.id }), {
    onSuccess: handleViewDeleted,
    onError: () => {
      dispatch(setNotification({ type: 'error', description: issueViewManagerI18n.deleteError }));
    },
  });

  const issueViewForm = useForm<IssueViewSchema>({
    validationSchema: getSchema(issueViewManagerI18n),
    // @ts-ignore TODO: revisit, once schemas are converted to zod
    initialValues: !issueView
      ? {
          ...EMPTY_ISSUE_VIEW,
          order: viewsLength,
        }
      : { ...issueView, visibility: 'shared' },
    onSubmit: (values: IssueViewSchema) => {
      const config = {
        onSuccess: () => {
          dispatch(setNotification({ type: 'success', description: issueViewManagerI18n.viewUpdatedSuccess }));
          navigate(ROUTES.issueViewManager);

          queryClient.invalidateQueries([ENDPOINTS.issueViews, {}]);
        },
        onError: () => {
          dispatch(setNotification({ type: 'error', title: issueViewManagerI18n.viewUpdateFail }));
        },
      };

      const mutation = !issueView ? createViewMutation : updateViewMutation;

      mutation.mutate(values as IssueView, config);
    },
  });

  const availableIssueGroupOptions = useMemo(
    () => getAvailableIssueGroupOptions(groups, issueViewForm.values.filters.groups as IssueGroup[]),
    [groups, issueViewForm.values.filters.groups]
  );

  const getValidFieldOrder = useCallback(
    (newItems?: string[], type?: 'fields' | 'customFields') => {
      const newFields = newItems && type === 'fields' ? [...newItems] : issueViewForm.values.filters.fields || [];
      const newCustomFields =
        newItems && type === 'customFields' ? [...newItems] : issueViewForm.values.filters.custom_fields || [];
      const activeFields = [...newFields, ...newCustomFields];

      const newFinalFields = activeFields.filter(
        activeField => !(issueViewForm.values.field_order || []).includes(activeField)
      );

      return (issueViewForm.values.field_order || [])
        .concat(...newFinalFields)
        .filter(field => activeFields.includes(field));
    },
    [issueViewForm.values.field_order, issueViewForm.values.filters.custom_fields, issueViewForm.values.filters.fields]
  );

  const availableFieldOptions = useMemo(() => getAvailableFieldOptions(issueViewKeysI18n), [issueViewKeysI18n]);
  const availableCustomFieldOptions = useMemo(() => getAvailableCustomFieldOptions(customFields), [customFields]);
  const availableCombinedFieldOptions = useMemo(() => {
    return getValidFieldOrder().map((fieldId, idx) => ({
      id: fieldId as string,
      order: idx,
    }));
  }, [getValidFieldOrder]);

  const handleDelete = useCallback(() => {
    void confirmOperation({
      title: issueViewManagerI18n.deleteConfirmTitle,
      contents: issueViewManagerI18n.deleteConfirmMessage,
      modalProps: { danger: true },
    }).then(result => {
      if (result) {
        if (issueView) {
          deleteViewMutation.mutate();
        } else {
          handleViewDeleted();
        }
      }
    });
  }, [
    handleViewDeleted,
    confirmOperation,
    deleteViewMutation,
    issueViewManagerI18n.deleteConfirmMessage,
    issueViewManagerI18n.deleteConfirmTitle,
    issueView,
  ]);

  const handleCancelConfirm = useCallback(() => {
    if (issueView) {
      handleViewDeleted();
    } else {
      navigate(ROUTES.issueViewManager);
    }
  }, [issueView, handleViewDeleted, navigate]);

  const handleCancel = useCallback(() => {
    if (issueViewForm.dirty) {
      void confirmOperation({
        title: issueViewManagerI18n.cancel,
        contents: issueViewManagerI18n.cancelConfirm,
        modalProps: { danger: true },
      }).then(result => {
        if (result) {
          handleCancelConfirm();
        }
      });
    } else {
      handleCancelConfirm();
    }
  }, [
    confirmOperation,
    issueViewForm.dirty,
    issueViewManagerI18n.cancel,
    issueViewManagerI18n.cancelConfirm,
    handleCancelConfirm,
  ]);

  const issueFieldRenderer = useCallback(
    (item: IssueViewField) => getIssueViewFieldLabel(item.id, issueViewKeysI18n),
    [issueViewKeysI18n]
  );

  const customActionRenderer = useCallback(
    (item: IssueViewAction, index: number) => (
      <>
        <TextField
          additionalClass="w-full"
          size="l"
          type="text"
          name={`actions[${index}].label`}
          noInfo
          placeholder={issueViewManagerI18n.name}
          autoComplete="off"
          additionalClasses={{ textControl: 'w-full' }}
        />
        <span className="mx-4" />
        <SelectField
          popoverProps={{
            additionalClass: styles.actionDropdownPopover,
          }}
          clearable
          filterable
          noInfo
          valueAsSelectedLabel
          variant="outlined"
          fullWidth
          additionalClasses={{ input: 'min-w-4xs tablet:min-w-7xs' }}
          options={issueScriptOptions}
          name={`actions[${index}].script`}
          placeholder={issueViewManagerI18n.script}
          size="l"
        />
      </>
    ),
    [issueScriptOptions, issueViewManagerI18n.name, issueViewManagerI18n.script]
  );

  const handleAddCustomAction = useCallback((index: number) => ({ label: '', script: '', order: index, id: v4() }), []);

  const handleReorderCustomActions = useCallback(
    (actions: IssueViewAction[]) => {
      issueViewForm.setFieldValue('actions', [...actions]);
    },
    [issueViewForm]
  );

  const handleFieldsChange = useCallback(
    (items: TagType[], type: 'fields' | 'customFields') => {
      issueViewForm.setFieldValue(
        'field_order',
        getValidFieldOrder(
          items.map(i => i.value),
          type
        )
      );
    },
    [issueViewForm, getValidFieldOrder]
  );

  return (
    <BasePage
      level={2}
      subPages={[
        { title: issueViewManagerI18n.issueViewManager, path: ROUTES.issueViewManager },
        { title: !issueView ? issueViewManagerI18n.createNew : issueViewManagerI18n.editView, path: '' },
      ]}
      pageControls={[
        {
          id: 'cancel',
          onClick: handleCancel,
          ariaLabel: issueViewManagerI18n.discard,
          icon: 'Close',
          label: issueViewManagerI18n.discard,
          hideIconOnDesktop: true,
        },
        {
          id: 'save',
          variant: 'fillBlueDark',
          onClick: issueViewForm.submitForm,
          loading: issueViewForm.isSubmitting,
          ariaLabel: issueViewManagerI18n.save,
          label: commonI18n.save,
          icon: 'Save',
          hideIconOnDesktop: true,
          disabled:
            !issueViewForm.dirty || (issueViewForm.submitCount > 0 && Object.keys(issueViewForm.errors).length > 0),
        },
      ]}
    >
      <FormProvider<IssueViewSchema> form={issueViewForm} additionalClass={styles.issueViewForm}>
        <Headline variant="headline-5" additionalClass="mb-16" color="dark">
          {issueView ? issueViewManagerI18n.editView : issueViewManagerI18n.createNew}
        </Headline>
        <Headline variant="headline-6" additionalClass="mb-8" color="dark">
          {issueViewManagerI18n.viewConfiguration}
        </Headline>

        <div className="flex flex-col align-start">
          <TextField
            size="l"
            label={issueViewManagerI18n.titleField}
            type="text"
            name="name"
            placeholder={issueViewManagerI18n.titleFieldPlaceholder}
            autoComplete="off"
          />
          <TextField
            multiline
            hideCounter={false}
            maxLength={200}
            size="l"
            label={issueViewManagerI18n.descriptionField}
            type="text"
            name="description"
            placeholder={issueViewManagerI18n.descriptionFieldPlaceholder}
            autoComplete="off"
          />

          <CopyText variant="copy-1" additionalClass="mt-12">
            {issueViewManagerI18n.issueGroups}
          </CopyText>
          <CopyText variant="copy-4" additionalClass="mb-4">
            {issueViewManagerI18n.issueGroupsDescriptionOne}
          </CopyText>
          <CopyText variant="copy-4" additionalClass="mb-16">
            {issueViewManagerI18n.issueGroupsDescriptionTwo}
          </CopyText>

          <TagInputField
            options={availableIssueGroupOptions}
            name="filters.groups"
            placeholder={issueViewManagerI18n.issueGroupsFieldDescription}
          />
          <HorizontalSeparator gutter={0} additionalClass="mb-24 mt-8" />

          <Headline variant="headline-6" additionalClass="mb-8" color="dark">
            {issueViewManagerI18n.issueActions}
          </Headline>

          <CopyText variant="copy-4" additionalClass="mb-16">
            {issueViewManagerI18n.issueActionsDescription}
          </CopyText>
          <Card row additionalClass={styles.actionToolbar}>
            <CopyText variant="copy-4" additionalClass="text-blue-gray-base mr-16">
              {issueViewManagerI18n.toolbar}
            </CopyText>
            <Icon name="Acknowledge" size="s" additionalClass="mr-16 text-blue-gray-base" />
            <Icon name="ChangePriority" size="s" additionalClass="mr-16 text-blue-gray-base" />
            <Icon name="ChangeStatus" size="s" additionalClass="mr-16 text-blue-gray-base" />
            <Icon name="Comment" size="s" additionalClass="mr-16 text-blue-gray-base" />
            <Icon name="MoreVertical" size="s" additionalClass="text-blue-gray-base" />
          </Card>

          <ItemGridAdder<IssueViewAction>
            showIndex
            formContext="actions"
            title={issueViewManagerI18n.customActions}
            itemRenderer={customActionRenderer}
            addLabel={issueViewManagerI18n.addCustomAction}
            // @ts-ignore stupid yup defaults to string | undefined and you can't override only cast
            items={issueViewForm.values.actions || []}
            onReorder={handleReorderCustomActions}
            onAdd={handleAddCustomAction}
            description={
              <div className="flex items-center mb-16">
                <CopyText variant="copy-4" additionalClass="inline-flex mr-4">
                  {issueViewManagerI18n.customActionsLocation}
                </CopyText>
                <Icon name="MoreVertical" size="s" additionalClass="text-dark" />
              </div>
            }
          />

          <HorizontalSeparator gutter={0} additionalClass="mb-16 mt-8" />
          <Headline variant="headline-6" additionalClass="mb-8" color="dark">
            {issueViewManagerI18n.tableFields}
          </Headline>
          <CopyText variant="copy-4" additionalClass="mb-16">
            {issueViewManagerI18n.issueFieldsDescription}
          </CopyText>
          <CopyText variant="copy-3" color="dark" additionalClass="mb-4">
            {issueViewManagerI18n.issueFieldsField}
          </CopyText>
          <TagInputField
            options={availableFieldOptions}
            name="filters.fields"
            onChange={items => handleFieldsChange(items, 'fields')}
          />

          <CopyText variant="copy-3" color="dark" additionalClass="mb-4">
            {issueViewManagerI18n.issueCustomFieldsField}
          </CopyText>
          <TagInputField
            onChange={items => handleFieldsChange(items, 'customFields')}
            options={availableCustomFieldOptions}
            name="filters.custom_fields"
            placeholder={issueViewManagerI18n.issueCustomFieldsFieldDescription}
          />

          <ItemGridDND<IssueViewField>
            title={issueViewManagerI18n.organizeFields}
            minimal
            id="issue-view-field-order-grid"
            onDragEnd={(result: DropResult) => {
              const { destination, source } = result;

              if (!destination || !source || source.index === undefined || destination.index === source.index) {
                return;
              }

              const newFieldOrder = reorder(getValidFieldOrder(), source.index, destination.index);

              issueViewForm.setFieldValue('field_order', [...newFieldOrder]);
            }}
            additionalClass={styles.fieldGrid}
            items={availableCombinedFieldOptions}
            itemContentRenderer={issueFieldRenderer}
          />

          <CopyText variant="copy-3" color="dark" additionalClass="mb-4">
            {issueViewManagerI18n.tableRows}
          </CopyText>

          <div className="flex flex-row items-center gap-8 my-8">
            <CopyText variant="copy-4">{issueViewManagerI18n.defaulttRowsPerPage}</CopyText>

            <SelectField
              name="rows_per_page"
              inline
              noInfo
              filterable={false}
              clearable={false}
              options={DEFAULT_ROWS_PER_PAGE_OPTIONS}
              defaultValue={DEFAULT_TABLE_CONFIG.rowsPerPage}
              additionalClasses={{ control: 'max-w-64 min-w-64' }}
            />
          </div>

          <RowHeightPickerField
            name="row_height"
            label={issueViewManagerI18n.defaultRowHeight}
            additionalClass="my-8"
            inline
          />

          <HorizontalSeparator gutter={0} additionalClass="mb-16 mt-8" />
          <Headline variant="headline-6" additionalClass="mb-8" color="dark">
            {issueViewManagerI18n.permissions}
          </Headline>
          <CopyText variant="copy-4">{issueViewManagerI18n.permissionsDescription}</CopyText>
          <RadioGroupField
            noInfo
            variant="outlined"
            name="visibility"
            defaultValue="private"
            additionalClasses={{ control: 'overflow-visible', body: 'overflow-visible', child: 'overflow-visible' }}
          >
            <UnsupportedWrapper>
              <Radio
                disabled
                color="dark"
                value="private"
                label={commonI18n.private}
                additionalClass={styles.permissionRadio}
              />
            </UnsupportedWrapper>
            <Radio
              color="dark"
              value="shared"
              label={commonI18n.sharedWithEveryone}
              additionalClass={c('mt-8', styles.permissionRadio)}
            />
          </RadioGroupField>
          <HorizontalSeparator gutter={0} additionalClass="mb-16 mt-16" />
          <Button onClick={handleDelete} variant="link" additionalClass="mt-16 mb-24 max-w-fit-content">
            <CopyText variant="copy-4" additionalClass="text-red-error">
              {issueViewManagerI18n.deleteView}
            </CopyText>
          </Button>
        </div>
      </FormProvider>
    </BasePage>
  );
});

export default IssueViewEdit;
