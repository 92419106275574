import { selectReleaseEmbedLoaded } from '@infrastructure/redux/settings';
import { useAppSelector } from '@infrastructure/redux/store';
import React from 'react';
import Error404Page from '../Error404Page';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'released-page': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'released-badge': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export const ReleaseNotes: React.FC = () => {
  const isReleaseEmbedLoaded = useAppSelector(selectReleaseEmbedLoaded);

  return isReleaseEmbedLoaded ? (
    <div className="page-container">
      <div className="page-body">
        <released-page channel-id="b67d60b5-9058-4291-a022-f4b6c9db395c"></released-page>
      </div>
    </div>
  ) : (
    <Error404Page />
  );
};
