import { useWizardContext } from '@components/common/form/Wizard/WizardContext';
import { Box } from '@components/common/Box';
import CopyText from '@components/typography/CopyText';
import Button from '@components/common/Button';
import React from 'react';
import { WizardStepProps, useWizardHandle } from '@components/common/form/Wizard';
import { AddEidtScriptWizardValues } from '../../AddEditScriptWizard.schema';

import styles from './ReviewStep.module.scss';

export const ReviewStep = ({ innerRef, onSubmit }: WizardStepProps) => {
  useWizardHandle(innerRef, onSubmit);
  const { valuesRef } = useWizardContext();
  const { targets, script } = valuesRef.current as AddEidtScriptWizardValues;

  const [showTargets, setShowTargets] = React.useState(false);

  const handleTargetsToggle = React.useCallback(() => {
    setShowTargets(state => !state);
  }, []);

  return (
    <div className={styles.reviewStep}>
      <Box header="Script" additionalClass={styles.scriptBox}>
        <div className={styles.general}>
          <CopyText variant="copy-4" additionalClass="text-blue-gray-base">
            Name
          </CopyText>
          <CopyText variant="copy-4">{script.name}</CopyText>

          {script.description && (
            <>
              <CopyText variant="copy-4" additionalClass="text-blue-gray-base">
                Description
              </CopyText>
              <CopyText variant="copy-4">{script.description}</CopyText>
            </>
          )}
        </div>
        <pre className={styles.script}>{script.script}</pre>
      </Box>

      {!!targets.entities?.length && (
        <Box header="Targets" additionalClass="mb-16">
          <CopyText variant="copy-4">
            <span className="mr-8">{`${targets.entities?.length} ${
              targets.entities?.length === 1 ? 'entity' : 'entities'
            } selected`}</span>
            <Button variant="link" size="s" onClick={handleTargetsToggle}>{`${
              showTargets ? 'Hide all' : 'Show all'
            }`}</Button>
          </CopyText>

          {showTargets && (
            <div className={styles.targets}>
              {targets.entities?.map(entity => (
                <CopyText variant="copy-6" key={entity?.name}>
                  {entity?.name}
                </CopyText>
              ))}
            </div>
          )}
        </Box>
      )}
    </div>
  );
};
