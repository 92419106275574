import { object, string, TypeOf } from 'yup';

import { ITextSnippets } from '@services/useTextSnippets';
import { useMemo } from 'react';

const useSchema = (i18nKeys: ITextSnippets['account']) => {
  const schema = object({
    id: string().required(),
    firstname: string().required(i18nKeys.firstnameRequired),
    lastname: string().required(i18nKeys.lastnameRequired),
    email: string().email(i18nKeys.invalidEmail).required(i18nKeys.emailRequired),
    timezone: string().required(i18nKeys.timezoneRequired),
    phone: string(),
    locale: string(),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, [i18nKeys]);
};

export interface AccountSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
