import React from 'react';
import c from 'classnames';

import styles from './Badge.module.scss';

export type BadgeVariant =
  | 'default'
  | 'info'
  | 'maintenance' // alias for info
  | 'success'
  | 'green' // alias for success
  | 'error'
  | 'red' // alias for error
  | 'warning'
  | 'yellow' // alias for warning
  | 'light'
  | 'pink'
  | 'orange'
  | 'gray'
  | 'lime'
  | 'black';

export type BadgeProps = {
  disabled?: boolean;
  outlined?: boolean;
  size?: 'm' | 'l' | 's' | 'auto';
  variant?: BadgeVariant;
  additionalClass?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  testid?: string;
};

const Badge: React.FC<BadgeProps> = ({
  disabled,
  outlined,
  size = 'm',
  variant = 'default',
  onClick,
  additionalClass,
  children,
  testid,
}) => {
  const isClickable = !!onClick;
  const Component = isClickable ? 'button' : 'div';

  return (
    <Component
      disabled={disabled}
      className={c(
        styles.badge,
        styles[variant],
        {
          [styles.large]: size === 'l',
          [styles.medium]: size === 'm',
          [styles.small]: size === 's',
          [styles.auto]: size === 'auto',
          [styles.outlined]: outlined,
          [styles.disabled]: disabled,
          [styles.clickable]: isClickable,
        },
        additionalClass
      )}
      data-testid={testid ? `Badge::${testid}` : undefined}
    >
      {children}
    </Component>
  );
};

export default Badge;
