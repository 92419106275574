import { MVTLayer, MVTLayerProps } from '@components/common/Map';
import React, { FC, useMemo } from 'react';
import * as x from '@utils/map';
import { LayerSpecification } from 'maplibre-gl';
import { range } from 'lodash';

export type RouteDirLayerProps = Omit<MVTLayerProps, 'type'>;

export const RouteDirLayer: FC<RouteDirLayerProps> = props => {
  const layout = useMemo(
    () =>
      ({
        'symbol-placement': 'line-center',
        'symbol-spacing': 500,
        'symbol-avoid-edges': true,
        'icon-image': x.ternary(
          x.ifProp('alert_color'),
          x.concat(`arrow-right-`, x.prop('alert_color')),
          `arrow-right-gray`
        ),
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'icon-rotation-alignment': 'map',
        'icon-keep-upright': false,
        'text-allow-overlap': false,
        'icon-anchor': 'center',
        // Mapbox doesn't support expressions in arrays, so we have to hardcode all "interpolations" of the 'index' prop
        'icon-offset': [
          'case',
          ...range(2, 10).reduce(
            (arr: any[], idx: number) => [...arr, x.ifEq('index', idx), ['literal', [idx * 6, idx * 1.5]]],
            []
          ),
          ['literal', [0, 1.5]],
        ],
      } as LayerSpecification['layout']),
    []
  );

  return <MVTLayer {...props} type="symbol" layout={layout} />;
};
