import React from 'react';
import c from 'classnames';
import SwitchBase, { SwitchBaseProps } from '../../SwitchBase/SwitchBase';
import { ToggleButtonProps } from './ToggleButton';

import styles from './ToggleButton.module.scss';

type PassThroughProps = Pick<ToggleButtonProps, 'disabled' | 'size' | 'round' | 'variant' | 'additionalClass'>;

export type ToggleButtonGroupProps = SwitchBaseProps<ToggleButtonProps, PassThroughProps> & {
  additionalClass?: string;
};

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({ exclusive = true, additionalClass, ...props }) => (
  <SwitchBase<ToggleButtonProps, PassThroughProps>
    exclusive={exclusive}
    {...props}
    additionalClass={c(styles.toggleButtonGroup, additionalClass)}
  />
);

export default ToggleButtonGroup;
