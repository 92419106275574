export default {
  color: ['#2182c3', '#8fc5ea', '#f3c44a', '#a23182', '#bcde85', '#f68230', '#c6b3d3', '#fcac8f', '#8b572a'],
  backgroundColor: 'rgba(0,0,0,0)',
  textStyle: {},
  title: {
    textStyle: {
      color: '#464646',
    },
    subtextStyle: {
      color: '#6e7079',
    },
  },
  line: {
    itemStyle: {
      borderWidth: '0',
    },
    lineStyle: {
      width: '2',
    },
    symbolSize: '2',
    symbol: 'emptyCircle',
    smooth: false,
  },
  radar: {
    itemStyle: {
      borderWidth: '0',
    },
    lineStyle: {
      width: '2',
    },
    symbolSize: '2',
    symbol: 'emptyCircle',
    smooth: false,
  },
  bar: {
    itemStyle: {
      barBorderWidth: 0,
      barBorderColor: '#ededed',
    },
  },
  pie: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ededed',
    },
  },
  scatter: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ededed',
    },
  },
  boxplot: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ededed',
    },
  },
  parallel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ededed',
    },
  },
  sankey: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ededed',
    },
  },
  funnel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ededed',
    },
  },
  gauge: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ededed',
    },
  },
  candlestick: {
    itemStyle: {
      color: '#f03738',
      color0: '#29b028',
      borderColor: '#f03738',
      borderColor0: '#29b028',
      borderWidth: 1,
    },
  },
  graph: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ededed',
    },
    lineStyle: {
      width: 1,
      color: '#c3ced9',
    },
    symbolSize: '2',
    symbol: 'emptyCircle',
    smooth: false,
    color: ['#2182c3', '#8fc5ea', '#f3c44a', '#a23182', '#bcde85', '#f68230', '#c6b3d3', '#fcac8f', '#8b572a'],
    label: {
      color: '#ffffff',
    },
  },
  map: {
    itemStyle: {
      areaColor: '#eee',
      borderColor: '#444',
      borderWidth: 0.5,
    },
    label: {
      color: '#000',
    },
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(255,215,0,0.8)',
        borderColor: '#444',
        borderWidth: 1,
      },
      label: {
        color: 'rgb(100,0,0)',
      },
    },
  },
  geo: {
    itemStyle: {
      areaColor: '#eee',
      borderColor: '#444',
      borderWidth: 0.5,
    },
    label: {
      color: '#000',
    },
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(255,215,0,0.8)',
        borderColor: '#444',
        borderWidth: 1,
      },
      label: {
        color: 'rgb(100,0,0)',
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#6E7079',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#6E7079',
      },
    },
    axisLabel: {
      show: true,
      color: '#6E7079',
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ['#E0E6F1'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: '#6E7079',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#6E7079',
      },
    },
    axisLabel: {
      show: true,
      color: '#6E7079',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#E0E6F1'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: '#6E7079',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#6E7079',
      },
    },
    axisLabel: {
      show: true,
      color: '#6E7079',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#E0E6F1'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#6E7079',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#6E7079',
      },
    },
    axisLabel: {
      show: true,
      color: '#6E7079',
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ['#E0E6F1'],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
      },
    },
  },
  toolbox: {
    iconStyle: {
      borderColor: '#bdbdbd',
    },
    emphasis: {
      iconStyle: {
        borderColor: '#4f4f4f',
      },
    },
  },
  legend: {
    textStyle: {
      color: '#1a1a1a',
    },
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: '#bdbdbd',
        width: 1,
      },
      crossStyle: {
        color: '#bdbdbd',
        width: 1,
      },
    },
  },
  timeline: {
    lineStyle: {
      color: '#e0e0e0',
      width: '1',
    },
    itemStyle: {
      color: '#e0e0e0',
      borderWidth: '0',
    },
    controlStyle: {
      color: '#333333',
      borderColor: 'rgba(255,255,255,0)',
      borderWidth: '0',
    },
    checkpointStyle: {
      color: '#2182c3',
      borderColor: 'rgba(33,130,195,0)',
    },
    label: {
      color: '#333333',
    },
    emphasis: {
      itemStyle: {
        color: '#2182c3',
      },
      controlStyle: {
        color: '#333333',
        borderColor: 'rgba(255,255,255,0)',
        borderWidth: '0',
      },
      label: {
        color: '#333333',
      },
    },
  },
  visualMap: {
    color: ['#3d0a51', '#433279', '#3f5688', '#40778c', '#49968b', '#5eb47e', '#8fce63', '#d5e04e', '#fae655'],
  },
  dataZoom: {
    handleSize: 'undefined%',
    textStyle: {},
  },
  markPoint: {
    label: {
      color: '#ffffff',
    },
    emphasis: {
      label: {
        color: '#ffffff',
      },
    },
  },
};
