import { Note } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { createCustomSlice, createDataTableReducers, createInitialDataTableState } from '@utils/redux';
import { LogbookState } from './types';

const initialState: LogbookState = createInitialDataTableState();
const reducers = createDataTableReducers<Note>(initialState);

export const createLogbookSlice = (actionPrefix?: string) =>
  createCustomSlice({
    name: 'logbook',
    initialState,
    reducers,
    actionPrefix,
  });

const logbookSlice = createLogbookSlice();

export const { updateItems, updatePagination, updateSearch, updateSorting, updateFilters, updateRowHeight, reset } =
  logbookSlice.actions;

export default logbookSlice;
