import { tokenToAccount } from '@utils/token';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountUpdateData, AuthState } from './types';

const initialState: AuthState = {
  account: tokenToAccount() ?? null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateToken(state, { payload }: PayloadAction<{ token?: string | null; isTemporary?: boolean }>) {
      if (payload.token) {
        const accountInfo = tokenToAccount(payload.token, payload.isTemporary);
        if (!state.account) {
          state.account = accountInfo;
        } else if (accountInfo) {
          state.account.token = accountInfo.token;
        } else {
          state.account = null;
        }
      } else {
        state.account = null;
      }
    },

    updateAccount(state, { payload }: PayloadAction<AccountUpdateData>) {
      if (state.account) {
        state.account = {
          ...state.account,
          ...payload,
        };
      }
    },
  },
});

export const { updateAccount, updateToken } = authSlice.actions;

export default authSlice;
