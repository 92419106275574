import useTextSnippets from '@services/useTextSnippets';
import Icon from '@components/common/Icon';
import { TabsPanel, TabsPanelProps } from '@components/common/Tabs';
import CopyText from '@components/typography/CopyText';
import * as widgetSetups from '@components/widgets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { selectWidgetPage, setActiveTab, WidgetConfig, WidgetWithSource } from '@redux/widgetPage';
import React, { FC, useMemo } from 'react';
import { WidgetSetup } from '../../types';
import { ConfigEditorTab } from '../Tabs/ConfigEditorTab';
import { ConfigurationTab } from '../Tabs/ConfigurationTab';
import { get } from 'lodash';

import styles from './WidgetSidebar.module.scss';

export type WidgetSidebarProps = {
  widgetConfig: WidgetConfig;
};

export const WidgetSidebar: FC<WidgetSidebarProps> = ({ widgetConfig }) => {
  const i18n = useTextSnippets();
  const { activeTab } = useAppSelector(selectWidgetPage);
  const dispatch = useAppDispatch();

  const setup: WidgetSetup = widgetSetups[widgetConfig?.type];

  const isOptionsTabDisabled = !!setup.DataSourcePicker && !(widgetConfig?.config as WidgetWithSource).source;

  const tabs = useMemo(
    () =>
      [
        {
          tabProps: {
            title: 'Configuration',
          },
          panel: <ConfigurationTab widgetConfig={widgetConfig} />,
        },
        setup.OptionsTab && {
          tabProps: {
            title: 'Options',
            disabled: isOptionsTabDisabled,
          },
          panel: <setup.OptionsTab />,
        },
        {
          tabProps: {
            title: 'JSON model',
          },
          panel: <ConfigEditorTab widgetConfig={widgetConfig} />,
        },
      ].filter(Boolean) as TabsPanelProps['tabs'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOptionsTabDisabled, widgetConfig]
  );

  const handleTabChange = (idx: number) => {
    dispatch(setActiveTab(idx));
  };

  return (
    <div className={styles.widgetSidebar}>
      <div className={styles.header}>
        <CopyText variant="copy-2" additionalClass={styles.title}>
          {widgetConfig?.name ?? i18n.widgetPage.newWidgetName}
        </CopyText>

        <div className={styles.banner}>
          <Icon name={setup.icon ?? 'Settings'} additionalClass="mr-8" />
          <span className="copy-3">
            {setup.i18nTitle ? get(i18n, setup.i18nTitle, get(i18n, `widgetPage.${setup.i18nTitle}`)) : 'Widget'}
          </span>
        </div>
      </div>

      <TabsPanel additionalClass={styles.tabsPanel} onChange={handleTabChange} activeIdx={activeTab} tabs={tabs} />
    </div>
  );
};
