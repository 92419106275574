import React from 'react';
import c from 'classnames';
import styles from './style.module.scss';

type Props = { additionalClass?: string; small?: boolean };

const VerticalSeparator: React.FC<Props> = ({ additionalClass, small }) => (
  <div className={c(styles.verticalSeparator, additionalClass, small && styles.small)} />
);

export default VerticalSeparator;
