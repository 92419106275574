import { SelectField, SelectFieldProps } from '@components/common/form';
import React from 'react';

export const REASONS: [string, ...string[]] = [
  'Configuration change',
  'Customer request',
  'Force majeure',
  'HW change',
  'Human error',
  'Power change',
  'Power outage',
  'Remote Session',
  'Transmission change',
  'SW failure',
  'SW upgrade',
  'Other',
];

export type ReasonPickerFieldProps = Omit<SelectFieldProps, 'options'>;

export const ReasonPickerField: React.FC<ReasonPickerFieldProps> = props => {
  return (
    <SelectField
      label="Reason"
      placeholder="Pick one..."
      clearable={false}
      {...props}
      options={REASONS.map(reason => ({ label: reason, value: reason }))}
    />
  );
};
