import LoadingSpinner from '@components/layout/LoadingSpinner';
import React, { useCallback, useState } from 'react';
import { Input } from '@components/common/form/Input';
import useDebouncedValue from '@services/useDebouncedValue';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { Script, ScriptsRequest, ScriptsResponse } from '@infrastructure/api/BaseNClient/useScriptsQuery';
import { UseQueryOptions, useQueryClient } from 'react-query';
import { apiClient } from '@infrastructure/api';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import { InfiniteList, InfiniteListItem } from '@components/connected/InfiniteSelect/InfiniteList';
import { GenericPaginatedResponse } from '@infrastructure/api/types';
import c from 'classnames';
import CopyText from '@components/typography/CopyText';
import { ScriptDetails } from '@infrastructure/api/BaseNClient/useScriptByIdQuery';

import styles from './ScriptPicker.module.scss';

const itemToLabel = (item: Script | ScriptDetails | null) => item?.name ?? '';

const toInfiniteListItem = (script: Script | ScriptDetails, idx = 0): InfiniteListItem<Script | ScriptDetails> => ({
  idx,
  label: itemToLabel(script),
  value: script,
});

export type ScriptPickerProps = {
  filterable?: boolean;
  loading?: boolean;
  value?: Script | ScriptDetails | string | null;
  onChange?: (item: Script) => void;
  additionalClass?: string;
};

export function ScriptPicker({ value, filterable, loading, onChange, additionalClass }: ScriptPickerProps) {
  const [filterStr, setFilterStr] = useState<string>('');
  const debouncedFilterStr = useDebouncedValue<string>(filterStr, 300);
  const queryClient = useQueryClient();
  const [resolvedValue, setResolvedValue] = useState<Script | ScriptDetails | null>(null);

  React.useEffect(() => {
    if (typeof value === 'string') {
      const queryParams = { id: value };

      queryClient
        .fetchQuery([ENDPOINTS.scriptById, queryParams], () =>
          apiClient.get<ScriptDetails>(ENDPOINTS.scriptById, queryParams)
        )
        .then((script: ScriptDetails) => {
          setResolvedValue(script);
        });
    } else {
      setResolvedValue(value as Script | ScriptDetails);
    }
  }, [value, onChange, queryClient]);

  const getItemsQuery = useCallback(
    (start_index: number, end_index: number, filterStr?: string) => {
      const queryParams: ScriptsRequest = {
        start_index,
        end_index,
      };

      if (filterStr) {
        queryParams.general = filterStr;
      }

      return queryClient.fetchQuery(
        [ENDPOINTS.scripts, queryParams],
        () =>
          apiClient
            .get<ScriptsResponse>(ENDPOINTS.scripts, queryParams)
            .then(({ data, total, result }) => ({ data, total, result })),
        {
          ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<GenericPaginatedResponse<Script>, Error>),
        }
      );
    },
    [queryClient]
  );

  const handleFilterChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStr(e.currentTarget?.value);
  }, []);

  const rowRenderer = useCallback(
    ({ item, key, style }) => {
      return (
        <div className="hbox" style={style} key={key}>
          {!item || loading ? (
            <LoadingSpinner centered size="s" />
          ) : (
            <div
              className={c(styles.row, resolvedValue?.id === item.id && styles.selected)}
              onClick={() => onChange?.(item)}
            >
              <CopyText variant="copy-6">{item.name}</CopyText>
            </div>
          )}
        </div>
      );
    },
    [loading, onChange, resolvedValue]
  );

  return (
    <div className={c(styles.scriptPicker, additionalClass)}>
      {filterable && (
        <Input
          icon="Search"
          type="text"
          size="m"
          placeholder="Filter..."
          value={filterStr}
          onChange={handleFilterChange}
          additionalClasses={{ root: styles.input }}
        />
      )}
      <InfiniteList<Script | ScriptDetails>
        key={debouncedFilterStr ?? '__all'}
        getItemsQuery={getItemsQuery}
        rowRenderer={rowRenderer}
        filterStr={filterStr ?? undefined}
        selectedItems={resolvedValue ? [toInfiniteListItem(resolvedValue)] : []}
      />
    </div>
  );
}
