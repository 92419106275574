import getI18N from '@utils/i18n';

const i18n = getI18N('keyboardShortcuts');

const shortcuts: Record<string, Record<string, string>> = {
  [i18n.global]: {
    [i18n.toggleSidebar]: 'shift-1',
    [i18n.quickSearch]: '/',
    // [i18n.openShortcutHelp]: 'shift-?',
    [i18n.closeInfoPanel]: 'x',
  },
};

export default shortcuts;
