import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import React, { FC, useCallback } from 'react';
import { useQueryClient, UseQueryOptions } from 'react-query';
import {
  InfiniteSelectControl,
  InfiniteSelectControlProps,
} from '@components/connected/InfiniteSelect/InfiniteSelectControl';
import {
  ConfigParams,
  ConfigParamsRequest,
  ConfigParamsResponse,
} from '@infrastructure/api/BaseNClient/useDeviceConfigParams';

const itemToLabel = (item: ConfigParams | null) => item?.host ?? item?.id ?? '';

export type DevicePickerProps = Omit<InfiniteSelectControlProps<ConfigParams>, 'getItemsQuery' | 'itemToLabel'>;

export const DevicePicker: FC<DevicePickerProps> = ({ value, ...props }) => {
  const queryClient = useQueryClient();
  const isQueryEnabled = typeof value === 'string';
  const [isValueLoading, setIsValueLoading] = React.useState(false);

  React.useEffect(() => {
    if (isQueryEnabled) {
      setIsValueLoading(true);
      const queryParams: any = { entityId: value };

      queryClient
        .fetchQuery(
          [ENDPOINTS.deviceConfigParamsById, queryParams],
          () => apiClient.get<ConfigParams>(ENDPOINTS.deviceConfigParamsById, queryParams),
          {
            ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<ConfigParams, Error>),
          }
        )
        .then(result => {
          setIsValueLoading(false);
          props.onChange?.({ idx: 0, label: itemToLabel(result), value: result });
        });
    } else if (value) {
      props.onChange?.({ idx: 0, label: itemToLabel(value.value), value: value.value });
    }
  }, []);

  const getItemsQuery = useCallback(
    (start_index: number, end_index: number, filterStr?: string) => {
      const queryParams: ConfigParamsRequest = {
        start_index,
        end_index,
        host: filterStr
      };

      return queryClient
        .fetchQuery(
          ['deviceConfigParams', queryParams],
          () => apiClient.get<ConfigParamsResponse>(ENDPOINTS.deviceConfigParams, queryParams),
          {
            ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<ConfigParamsResponse, Error>),
          }
        )
        .then(({ result, total }) => {
          return { data: result, total };
        });
    },
    [queryClient]
  );

  return (
    <InfiniteSelectControl<ConfigParams>
      label="Device"
      {...props}
      value={typeof value === 'string' ? null : value}
      getItemsQuery={getItemsQuery}
      itemToLabel={itemToLabel}
      searchable={true}
      disabled={isQueryEnabled && isValueLoading}
    />
  );
};
