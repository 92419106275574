import LoadingSpinner from '@components/layout/LoadingSpinner';
import CopyText from '@components/typography/CopyText';
import { useEntitiesAndAlertsQuery } from '@infrastructure/api/BaseNClient/useEntitiesAndAlertsQuery';
import React, { FC, useCallback } from 'react';
import { Cluster } from '../../ClusterCache';
import { EntityStatusDot } from '@components/pages/EntityPage/components/EntityStatusDot';
import { getEntityProperty } from '@utils/entity';
import Link from '@components/common/Link';
import ROUTES from '@infrastructure/routes';

import styles from './ClusterPopupContent.module.scss';

export type ClusterPopupContentProps = {
  cluster: Cluster;
  onTopologyMapRequest?: (cluster: Cluster) => void;
};

export const ClusterPopupContent: FC<ClusterPopupContentProps> = ({ cluster, onTopologyMapRequest }) => {
  const { name, entityIds, alerting, entities } = cluster;
  const query = useEntitiesAndAlertsQuery(
    {
      ids: entityIds?.join(','),
    },
    {
      enabled: !!entityIds?.length,
      useAbortSignal: false,
    }
  );

  const handleTopologyMapRequest = useCallback(() => {
    onTopologyMapRequest?.(cluster);
  }, [cluster, onTopologyMapRequest]);

  return (
    <div className={styles.popupContents}>
      <div className={styles.header}>
        <div className={styles.title}>
          {!!name && <CopyText variant="copy-6">{name}</CopyText>}
          {!!onTopologyMapRequest && !!entityIds?.length && (
            <span className={styles.link} onClick={handleTopologyMapRequest}>
              Open Topology map
            </span>
          )}
        </div>

        <CopyText variant="copy-5" additionalClass={styles.totalNodes}>
          {entities}
        </CopyText>
        {!!alerting && (
          <CopyText variant="copy-5" additionalClass={styles.alertingNodes}>
            {alerting}
          </CopyText>
        )}
      </div>

      {query.isLoading ? (
        <LoadingSpinner additionalClass={styles.spinner} centered />
      ) : (
        <div className={styles.list}>
          {query.data?.map(entity => {
            const description = getEntityProperty(entity, 'description');

            return (
              <div key={entity.id} className={styles.item}>
                <EntityStatusDot entity={entity} size="xs" additionalClass="mr-8" />

                <CopyText variant="copy-8" additionalClass="text-dark">
                  <Link href={ROUTES.entityById.replace(':entityId', entity.id)}>{entity.name}</Link>
                </CopyText>
                {description && (
                  <CopyText variant="copy-8" additionalClass="text-blue-gray-base">
                    {description}
                  </CopyText>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
