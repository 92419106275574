import { object, string, TypeOf } from 'yup';
import { ITextSnippets } from '@services/useTextSnippets';
import { useMemo } from 'react';

const useSchema = (i18nKeys: ITextSnippets['dashboards']) => {
  const schema = object({
    name: string().required(i18nKeys.dashboardNameRequired),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, [i18nKeys]);
};

export interface DashboardSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
