import React, { FC } from 'react';
import { useField } from 'formik';
import { TagInputControl, TagInputControlProps } from './TagInputControl';
import { TagType } from './TagInput';

export type TagInputFieldProps = Omit<TagInputControlProps, 'value' | 'defaultValue'> & {
  name: string;
  defaultValue?: string[];
};

export const TagInputField: FC<TagInputFieldProps> = ({ defaultValue, options, size, onChange, ...props }) => {
  const [field, { error, touched }, helpers] = useField<string[]>({ ...props, defaultValue });

  const handleChange = (items: TagType[]) => {
    helpers.setValue(items?.map(({ value }) => value));

    if (onChange) {
      onChange(items);
    }
  };

  const selectedOption = field.value?.length ? options.filter(option => field.value.includes(option.value)) : undefined;

  return (
    <TagInputControl
      options={options}
      size={size}
      {...props}
      {...field}
      errorText={touched ? error : undefined}
      onChange={handleChange}
      value={selectedOption}
    />
  );
};
