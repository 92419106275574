import React from 'react';
import Icon, { IconNames } from '@components/common/Icon';
import styles from './IconCard.module.scss';
import CopyText from '@components/typography/CopyText';
import Card from '@components/common/Card/Card';
import c from 'classnames';

type Props = {
  icon: IconNames;
  title: string;
  description: string;
  invertedColorScheme?: boolean;
  onClick?: () => void;
};

const IconCard: React.FC<Props> = ({ icon, title, description, invertedColorScheme, onClick }) => {
  return (
    <Card additionalClass={c(styles.iconCard, invertedColorScheme && styles.invertedColors)} onClick={onClick}>
      <div className="items-center flex-full">
        <div className={styles.iconContainer}>
          <Icon name={icon} additionalClass={styles.icon} />
        </div>
        <div className={styles.infoContainer}>
          <CopyText variant="copy-4" color={invertedColorScheme ? 'white' : 'dark'}>
            {title}
          </CopyText>
          <CopyText variant="copy-6" color={invertedColorScheme ? 'white' : 'dark'}>
            {description}
          </CopyText>
        </div>
      </div>
    </Card>
  );
};

export default IconCard;
