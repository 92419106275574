export default {
  mobileMini: '320px',
  mobile: '375px',
  mobileLandscape: '480px',
  smallTablet: '620px',
  tablet: '768px',
  tabletLandscape: '1024px',
  desktop: '1024px',
  large: '1260px',
  xl: '1440px',
  xxl: '1720px',
  xxs: '0px', // a way to describe in code any screen size between 0 px and actual smallest breakpoint that we have
};
