import { GenericOptionsResponse } from '@infrastructure/api/types';
import { useField } from 'formik';
import { InfiniteListItem } from '../InfiniteSelect/InfiniteList';
import { OptionsControl, OptionsControlProps } from './OptionsControl';

const defaultItemToId = (item: any): string => item?.name ?? '';

export type OptionsControlFieldProps<T extends GenericOptionsResponse = GenericOptionsResponse> = Omit<
  OptionsControlProps,
  'value' | 'onChange'
> & {
  name?: string;
  itemToId?: (item?: T) => string;
};

export function OptionsControlField<T extends GenericOptionsResponse = GenericOptionsResponse>({
  name,
  size,
  placeholder,
  itemToId,
  ...props
}: OptionsControlFieldProps) {
  const [field, { error, touched }, helpers] = useField({ ...props, name: name ?? props.property });

  const handleChange = (item: InfiniteListItem<T> | null) => {
    helpers.setValue((itemToId ?? defaultItemToId)(item?.value));
  };

  return <OptionsControl<T> {...props} {...field} onChange={handleChange} errorText={touched ? error : undefined} />;
}
