import React, { useState, useCallback, useMemo, ReactElement, useRef, useEffect } from 'react';
import c from 'classnames';
import {
  TableColumns,
  TableConfig,
  TableItem,
  Sort,
  RowHeight,
  TableColumn,
  TableMouseEventHandler,
  TableImperativeHandle,
  InternalTableConfig,
  ColumnWidths,
  TableItemAction,
  TableComponents,
  Overrides,
  ColumnVisibilities,
  ColumnsOrder,
} from './types';
import { DEFAULT_TABLE_CONFIG, rowHeightMap } from './config';
import { defaultComponents, TableCell, TableCheckboxCell } from './components';
import { getOrderedColumns } from '@components/common/Table/utils';
import { LoadingOverlay } from '@components/common/Overlay';
import useResizableColumns from './useResizableColumns';
import useTextSnippets from '@services/useTextSnippets';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
import { BottomToolbar } from './components/BottomToolbar';
import { TableContextMenuProps } from './components/TableContextMenu';
import { TableConfigContext } from './TableConfigContext';
import { CypressTestsData, CypressUtilityType } from '@cypress';
import { differenceBy, isUndefined, unionBy } from 'lodash';

import styles from './Table.module.scss';

const SORT_STATES = ['asc', 'desc', false] as const;

export type TableProps<T extends object> = {
  isLoading?: boolean;
  items: TableItem<T>[];
  columns: TableColumns<T>;
  itemActions?: TableItemAction<T>[];
  components?: Overrides<TableComponents>;
  minimalMode?: boolean;
  className?: string;
  idProperty?: keyof T | ((item: T) => keyof T);
  highlightedId?: T[keyof T];
  config?: Partial<TableConfig>;
  selectedItems?: TableItem<T>[];
  onCellClick?: TableMouseEventHandler<T>;
  onContextMenu?: (e: React.MouseEvent<HTMLElement>, item: T, selectedItems: T[]) => void;
  onPageChange?: (page: number) => void;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  onSortChange?: (property: string | null, order: Sort) => void;
  onRowHeightChange?: (rowHeight: RowHeight) => void;
  onColumnResize?: (colWidths: ColumnWidths) => void;
  onColumnOrderChange?: (colOrder: ColumnsOrder) => void;
  onColumnVisibilityToggle?: (colVisibility: ColumnVisibilities) => void;
  onSelect?: (items: T[]) => void;
  additionalClasses?: {
    toolbar?: string;
    tableContainer?: string;
    tableWrapper?: string;
  };
  imperativeHandle?: TableImperativeHandle;
};

function CustomTable<T extends object>(props: TableProps<T>): ReactElement | null {
  const i18n = useTextSnippets('table');
  const components = { ...defaultComponents, ...props.components };
  const utilityTableTestDataConfig = CypressTestsData[CypressUtilityType.UTILITY_TABLE];

  const {
    minimalMode,
    items,
    columns,
    highlightedId,
    additionalClasses = {},
    config = {},
    itemActions,
    selectedItems: preSelectedItems,
    onContextMenu,
    onPageChange,
    onRowsPerPageChange,
    onSortChange,
    onRowHeightChange,
    onColumnResize,
    onColumnOrderChange,
    onColumnVisibilityToggle,
    onCellClick,
    onSelect,
    isLoading,
    idProperty = 'id' as keyof T,
  } = props;

  const tableConfig = useMemo(() => ({ ...DEFAULT_TABLE_CONFIG, ...config } as InternalTableConfig), [config]);
  const {
    page,
    rowsPerPage,
    sortable,
    sort,
    sortBy,
    hasToolbar,
    rowHeight,
    rowsPerPageOptions,
    rowCount = items.length,
    selectable,
    paginated,
    resizable,
    bordered,
  } = tableConfig;

  const tableRef = useRef<HTMLTableElement>(null);

  const [previousSelectionIndex, setPreviousSelectionIndex] = useState(-1);
  const [selectedItems, setSelectedItems] = useState<T[]>(preSelectedItems ?? []);
  const hasContextMenu = !!(itemActions?.length || components.ContextMenu);
  const [contextMenuProps, setContextMenuProps] = useState<Pick<
    TableContextMenuProps<T>,
    'target' | 'item' | 'selectedItems'
  > | null>(null);

  const [someSelected, allSelected] = useMemo(() => {
    const selectedLength = differenceBy(items, selectedItems, idProperty).length;
    return [selectedLength > 0 && selectedLength < items.length, items.length > 0 && selectedLength === 0];
  }, [items, selectedItems, idProperty]);

  // TODO: potentially selectedItems can become a controlled prop, however currently it's too intertwined with everything else
  useEffect(() => {
    setSelectedItems(preSelectedItems ?? []);
  }, [preSelectedItems]);

  const visibleColumns: TableColumn<T>[] = useMemo(() => {
    return getOrderedColumns(Object.values(columns)).filter(col => isUndefined(col.visible) || col.visible);
  }, [columns]);

  const handleToggleRowHeight = useCallback(() => {
    const currentRowHeightIndex = rowHeightMap[rowHeight];
    const nextRowHeightIndex =
      currentRowHeightIndex + 1 < Object.keys(rowHeightMap).length ? currentRowHeightIndex + 1 : 0;
    const nextRowHeight = Object.keys(rowHeightMap).find(
      key => rowHeightMap[key as RowHeight] === nextRowHeightIndex
    ) as RowHeight;

    onRowHeightChange?.(nextRowHeight || 'small');
  }, [onRowHeightChange, rowHeight]);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: string) => {
      const idx = sort ? SORT_STATES.indexOf(sort) : -1;
      const nextIdx = (idx + 1) % SORT_STATES.length;

      onSortChange?.(property, SORT_STATES[nextIdx]);
    },
    [sort, onSortChange]
  );

  const handleChangePage = useCallback(
    (newPage: number) => {
      onPageChange?.(newPage);
    },
    [onPageChange]
  );

  const handleChangeRowsPerPage = useCallback(
    (newRowsPerPage: number) => {
      onRowsPerPageChange?.(newRowsPerPage);
      onPageChange?.(1);
    },
    [onPageChange, onRowsPerPageChange]
  );

  const handleContextMenu = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, item: T) => {
      if (itemActions?.length) {
        e.preventDefault();
        setContextMenuProps({ target: e.currentTarget.getBoundingClientRect(), item, selectedItems });
      }
      onContextMenu?.(e, item, selectedItems);
    },
    [onContextMenu, itemActions, selectedItems]
  );

  const getId = useCallback(
    (item: T) => (typeof idProperty === 'function' ? idProperty(item) : (item[idProperty] as unknown as string)),
    [idProperty]
  );

  const handleSelect = useCallback(
    (item: T) => {
      const itemAlreadySelected = selectedItems.find(si => getId(si) === getId(item));
      const newSelectedItems = itemAlreadySelected
        ? selectedItems.filter(si => getId(si) !== getId(item))
        : selectedItems.concat(item);

      setPreviousSelectionIndex(items.findIndex(i => getId(i) === getId(item)));
      onSelect?.(newSelectedItems);
      setSelectedItems(newSelectedItems);
    },
    [selectedItems, items, onSelect, getId]
  );

  const handleToggleSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => {
      const newSelectedItems = checked
        ? unionBy(items, selectedItems, idProperty)
        : differenceBy(selectedItems, items, idProperty);
      setSelectedItems(newSelectedItems);
      onSelect?.(newSelectedItems);
    },
    [idProperty, items, onSelect, selectedItems]
  );

  const handleCellClick: TableMouseEventHandler<T> = useCallback(
    (e, item, optionalParams = {}) => {
      // in case the table has select enabled, we should toggle select for the row on cell click
      // if modifiers are pressed
      if (tableConfig.selectable) {
        e.preventDefault();
        e.stopPropagation();

        if (e.ctrlKey || e.metaKey) {
          handleSelect(item);
          setPreviousSelectionIndex(items.findIndex(i => getId(i) === getId(item)));
        } else if (e.shiftKey && previousSelectionIndex > -1) {
          // anything in-between previous selection index and current index (either forwards or backwards direction)
          // needs to be toggled either on or off (on if previous selection was a select, off if previous was a de-select)
          const currentSelectionIndex = items.findIndex(i => getId(i) === getId(item));
          const newItemIsBeingSelected = !selectedItems.find(selectedItem => getId(selectedItem) === getId(item));
          let newSelectedItems = [...selectedItems];

          if (currentSelectionIndex === previousSelectionIndex) {
            handleSelect(item);
          } else if (previousSelectionIndex < currentSelectionIndex) {
            for (let i = previousSelectionIndex + 1; i <= currentSelectionIndex; i++) {
              newSelectedItems = newSelectedItems.filter(selectedItem => getId(selectedItem) !== getId(item));
              if (newItemIsBeingSelected) {
                newSelectedItems.push(items[i]);
              }
            }
          } else if (previousSelectionIndex > currentSelectionIndex) {
            for (let i = previousSelectionIndex; i >= currentSelectionIndex; i--) {
              newSelectedItems = newSelectedItems.filter(selectedItem => getId(selectedItem) !== getId(items[i]));

              if (newItemIsBeingSelected) {
                newSelectedItems.push(items[i]);
              }
            }
          }

          setSelectedItems(newSelectedItems);
          setPreviousSelectionIndex(currentSelectionIndex);
        }
      }

      if (onCellClick && !optionalParams.skipOnCellClick) {
        // it's a selection related click, don't do anything
        if (tableConfig.selectable && (e.ctrlKey || e.metaKey || e.shiftKey)) {
          // do nothing
        } else {
          onCellClick(e, item);
        }
      }
    },
    [tableConfig, previousSelectionIndex, handleSelect, onCellClick, items, selectedItems, getId]
  );

  useResizableColumns({
    $table: tableRef.current,
    columns,
    selectable,
    enabled: resizable && !!items?.length,
    onColumnResize,
  });

  const bottomToolbar = useMemo(
    () =>
      paginated && items.length ? (
        <BottomToolbar
          minimalMode={minimalMode}
          rowCount={rowCount}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={handleChangePage}
          paginated={paginated}
        />
      ) : null,
    [
      handleChangePage,
      handleChangeRowsPerPage,
      minimalMode,
      page,
      paginated,
      rowCount,
      rowsPerPage,
      rowsPerPageOptions,
      items.length,
    ]
  );

  const header = useMemo(
    () => (
      <thead>
        <tr>
          {!!(selectable && items.length) && (
            <TableCheckboxCell<T>
              onToggle={handleToggleSelectAll}
              checked={allSelected}
              indeterminate={someSelected}
              header
            />
          )}
          {visibleColumns.map((col, colIdx) => (
            <TableCell<T>
              key={col.id ?? colIdx}
              rowHeight={rowHeight}
              header
              column={col}
              colKey={col.id}
              bordered={bordered}
              sortable={sortable && col.sortable}
              resizable={resizable && col.resizable !== false}
              sort={sort}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
            />
          ))}
        </tr>
      </thead>
    ),
    [
      selectable,
      items.length,
      handleToggleSelectAll,
      allSelected,
      someSelected,
      visibleColumns,
      rowHeight,
      bordered,
      sortable,
      resizable,
      sort,
      sortBy,
      handleRequestSort,
    ]
  );

  const body = useMemo(
    () => {
      return (
        <tbody className={styles.tableBody}>
          {items.map((item, idx) => {
            const id = getId(item) as unknown as string;
            const rowSelected = !!selectedItems.find(si => getId(si) === getId(item));
            const isItemHighlighted = highlightedId && highlightedId === getId(item);

            return !!components.TableRow?.component ? (
              <components.TableRow.component
                key={`${id}-${idx}`}
                id={id}
                idx={idx}
                item={item}
                columns={visibleColumns}
                isSelected={rowSelected}
                isHighlighted={isItemHighlighted}
                onSelect={handleSelect}
                onContextMenu={hasContextMenu ? handleContextMenu : undefined}
                onCellClick={tableConfig.selectable || !!onCellClick ? handleCellClick : undefined}
                {...components.TableRow?.props}
              />
            ) : null;
          })}
        </tbody>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // components,
      items,
      idProperty,
      selectedItems,
      highlightedId,
      visibleColumns,
      handleSelect,
      hasContextMenu,
      handleContextMenu,
      handleCellClick,
      getId,
    ]
  );

  return (
    <TableConfigContext.Provider value={tableConfig}>
      <div
        className={c(styles.table, additionalClasses.tableWrapper)}
        data-testid={utilityTableTestDataConfig.testIds.tableWrapper}
      >
        {hasToolbar && !!components.TableToolbar?.component && (
          <components.TableToolbar.component
            itemActions={itemActions}
            selectedItems={selectedItems}
            columns={columns}
            onColumnVisibilityToggle={onColumnVisibilityToggle}
            onToggleRowHeight={handleToggleRowHeight}
            onColumnOrderChange={onColumnOrderChange}
            onColumnResize={onColumnResize}
            {...components.TableToolbar?.props}
            additionalClass={c(additionalClasses.toolbar, components.TableToolbar?.props?.additionalClass)}
          />
        )}

        <div className="relative flex flex-col min-w-0 min-h-0">
          <div className={c(styles.tableContainer, additionalClasses.tableContainer)}>
            <table ref={tableRef} className={styles.tableRoot}>
              {header}
              {!!items.length && body}
            </table>

            {!items.length && (
              <div className={styles.noElementsContainer}>
                <Headline variant="headline-6" centered additionalClass="mb-8 block">
                  {i18n.noDataTitle}
                </Headline>
                <CopyText variant="copy-4" centered>
                  {i18n.noDataDescription}
                </CopyText>
              </div>
            )}
          </div>
          {isLoading && <LoadingOverlay size="l" />}
        </div>

        {bottomToolbar}

        {hasContextMenu && itemActions?.length && !!components.ContextMenu?.component && (
          <components.ContextMenu.component
            {...contextMenuProps}
            actions={itemActions}
            onClose={() => setContextMenuProps(null)}
            {...components.ContextMenu?.props}
          />
        )}
      </div>
    </TableConfigContext.Provider>
  );
}

export default CustomTable;
