import React from 'react';
import c from 'classnames';

import styles from './Box.module.scss';

export type BoxProps = {
  additionalClass?: string;
  header?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  noPadding?: boolean;
};

export const Box: React.FC<BoxProps> = ({ additionalClass, header, footer, noPadding, children }) => {
  return (
    <div className={c(styles.box, noPadding && styles.noPadding, additionalClass)}>
      {header && <div className={c(styles.header, 'box-header')}>{header}</div>}
      <div className={c(styles.body, 'box-body')}>{children}</div>
      {footer && <div className={c(styles.footer, 'box-footer')}>{footer}</div>}
    </div>
  );
};
