import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import { AppDispatch, useAppDispatch } from '@infrastructure/redux/store';
import { IssueView, updateView } from '@redux/issues';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';
import { GenericOkApiResponse } from '@infrastructure/api/types';

export function onUpdateIssueViewSuccess(
  dispatch: AppDispatch,
  response: GenericOkApiResponse,
  data: IssueView,
  context?: any
): void;
export function onUpdateIssueViewSuccess(dispatch: AppDispatch, response: GenericOkApiResponse, data: IssueView) {
  dispatch(updateView(data));
}

export type UpdateIssueViewMutationSettings = { noDefaultSuccess?: boolean };

export const useUpdateIssueViewMutation = <
  T extends GenericOkApiResponse = GenericOkApiResponse,
  P extends IssueView = IssueView
>(
  { noDefaultSuccess }: UpdateIssueViewMutationSettings = { noDefaultSuccess: false },
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> => {
  const dispatch = useAppDispatch();

  return useMutation<T, Error, P>(
    (params): Promise<T> => apiClient.put<T, IssueView>(ENDPOINTS.updateIssueView, params),
    {
      ...mutationOptions,
      onSuccess: (...successParams) => {
        if (!noDefaultSuccess) {
          onUpdateIssueViewSuccess(dispatch, ...successParams);
        }

        void mutationOptions.onSuccess?.(...successParams);
      },
    }
  );
};
