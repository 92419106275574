import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import { AppDispatch, useAppDispatch } from '@infrastructure/redux/store';
import { UpdateIssueSuccessResponse, UpdateIssueParams, updateIssueFields } from '@redux/issues';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';

export function onUpdateIssueSuccess(
  dispatch: AppDispatch,
  response: UpdateIssueSuccessResponse,
  data: UpdateIssueParams,
  context?: any
): void;
export function onUpdateIssueSuccess(
  dispatch: AppDispatch,
  response: UpdateIssueSuccessResponse,
  data: UpdateIssueParams
) {
  dispatch(updateIssueFields(data));
}

export type UpdateIssueMutationSettings = { noDefaultSuccess?: boolean };

export const useUpdateIssueMutation = <
  T extends UpdateIssueSuccessResponse = UpdateIssueSuccessResponse,
  P extends UpdateIssueParams = UpdateIssueParams
>(
  { noDefaultSuccess }: UpdateIssueMutationSettings = { noDefaultSuccess: false },
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> => {
  const dispatch = useAppDispatch();

  return useMutation<T, Error, P>(
    (params): Promise<T> => apiClient.put<T, UpdateIssueParams>(ENDPOINTS.updateIssue, params),
    {
      ...mutationOptions,
      onSuccess: (...successParams) => {
        if (!noDefaultSuccess) {
          onUpdateIssueSuccess(dispatch, ...successParams);
        }

        void mutationOptions.onSuccess?.(...successParams);
      },
    }
  );
};
