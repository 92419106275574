import LoadingSpinner from '@components/layout/LoadingSpinner';
import { EntityInfoGrid } from '@components/pages/Inventory/components/EntityInfoGrid';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import React, { FC } from 'react';

import styles from './EntityPopupContent.module.scss';

export type EntityPopupContentProps = {
  name: string;
  entityId?: string;
};

export const EntityPopupContent: FC<EntityPopupContentProps> = ({ name, entityId }) => {
  const entity = useEntityQuery(
    {
      entityId: entityId,
      alerts: true,
    },
    {
      enabled: !!entityId,
    }
  );

  return (
    <div className={styles.entityPopup}>
      {!entityId || entity.isLoading ? (
        <LoadingSpinner centered additionalClass="p-20" />
      ) : (
        <EntityInfoGrid entity={entity.data} nameAsHeader size="xs" />
      )}
    </div>
  );
};
