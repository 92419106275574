import { InfoGrid, InfoGridProps } from '@components/layout/InfoGrid/InfoGrid';
import React, { useMemo } from 'react';
import { filterTruthy } from '@utils/misc';
import { NoteWithEntity } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import c from 'classnames';
import { formatEpochDate } from '@utils/date';
import { Gravatar } from '@components/common/Gravatar';
import CopyText from '@components/typography/CopyText';
import Link from '@components/common/Link';
import ROUTES from '@infrastructure/routes';

import styles from './MWInfoGrid.module.scss';

type MWInfoGridProps = Omit<InfoGridProps, 'data'> & { note?: NoteWithEntity | null };

export const MWInfoGrid: React.FC<MWInfoGridProps> = ({ note, size, additionalClass, ...props }) => {
  const data = useMemo(
    () =>
      note
        ? filterTruthy([
            {
              label: 'Time',
              value: formatEpochDate(note.timestamp),
            },
            {
              label: 'Type',
              value: 'Maintenance Window',
            },
            {
              label: 'Reason',
              value: note.reason,
            },
            !!note.entityid && {
              label: 'Entity',
              value: (
                <div className="w-full hbox">
                  <Link
                    href={ROUTES.entityById.replace(':entityId', note.entityid)}
                    additionalClass="flex-grow min-w-0 truncate"
                  >
                    {note.entity?.name ?? note.entityid}
                  </Link>
                </div>
              ),
            },
            {
              label: 'User',
              value: (
                <div className="w-full hbox">
                  <Gravatar size={40} username={note.author} email={note.author} additionalClass="mr-8" />
                  <CopyText variant="copy-4" additionalClass="truncate">
                    {note.author}
                  </CopyText>
                </div>
              ),
            },
          ])
        : [],
    [note]
  );

  return note ? (
    <div
      className={c(
        styles.infoGrid,
        {
          [styles.medium]: size === 'm',
          [styles.small]: size === 's',
          [styles.extraSmall]: size === 'xs',
        },
        additionalClass
      )}
    >
      {data.length > 0 && <InfoGrid data={data} {...props} size={size} additionalClass={c(styles.grid)} />}
    </div>
  ) : null;
};
