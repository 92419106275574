import { MaintenanceWindow } from '@infrastructure/api/BaseNClient/useMaintenanceWindowsQuery';
import { createCustomSlice, createDataTableReducers, createInitialDataTableState } from '@utils/redux';
import { MWListPageState } from './types';

const initialState: MWListPageState = createInitialDataTableState();

const reducers = createDataTableReducers<MaintenanceWindow>(initialState);

export const createMWSlice = (actionPrefix?: string) =>
  createCustomSlice({
    name: 'mw',
    initialState,
    reducers,
    actionPrefix,
  });

const mwListPageSlice = createMWSlice();

export const { updateItems, updatePagination, updateSorting, updateSearch, updateFilters, updateRowHeight, reset } =
  mwListPageSlice.actions;

export default mwListPageSlice;
