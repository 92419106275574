import useTextSnippets from '@services/useTextSnippets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { ChartWidgetSubConfig, selectWidgetSubConfig, updateSubConfig } from '@redux/widgetPage';
import { noop } from 'lodash';
import React, { useMemo } from 'react';
import palette from '@components/common/form/PalettePicker/paletteUtils';
import { DEFAULT_CHART_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { FieldsConfig, FormBuilder } from '@components/common/form/FormBuilder';
import useSchema from './OptionsTab.schema';

export const OptionsTab = () => {
  const i18n = useTextSnippets('widgetPage');
  const dispatch = useAppDispatch();
  const options = (useAppSelector(selectWidgetSubConfig) as ChartWidgetSubConfig) ?? DEFAULT_CHART_WIDGET_CONFIG;

  const schema = useSchema();

  const handleChange = (newOptions: ChartWidgetSubConfig) => {
    dispatch(updateSubConfig(newOptions));
  };

  const fields: FieldsConfig = useMemo(
    () => [
      {
        type: 'group',
        title: i18n.components,
        fields: [
          {
            type: 'toggle',
            props: {
              label: i18n.showZoomSlider,
              name: 'showZoomSlider',
            },
          },
          {
            type: 'toggle',
            props: {
              label: 'Horizontal legend',
              name: 'horizontalLegend',
            },
          },
          {
            type: 'toggle',
            props: {
              label: 'Show summary',
              name: 'showSummary',
            },
          },
        ],
      },
      {
        type: 'group',
        title: i18n.palette,
        fields: [
          {
            type: 'palette',
            props: {
              name: 'palette',
              placeholder: i18n.chooseYourPalette,
              options: Object.keys(palette.categorical).map(preset => ({ label: preset, value: preset })),
            },
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  return (
    <FormBuilder<ChartWidgetSubConfig>
      fields={fields}
      initialValues={options}
      onSubmit={noop}
      onChange={handleChange}
      validationSchema={schema}
      validateOnChange
      enableReinitialize
      additionalClass="m-10"
    />
  );
};
