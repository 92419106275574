import { Box } from '@components/common/Box';
import { TextField, Form } from '@components/common/form';
import { WizardStepProps } from '@components/common/form/Wizard/Wizard';
import z from 'zod';
import { ReasonPickerField, REASONS } from './ReasonPickerField';

export const GeneralStep: React.FC<WizardStepProps> = ({ name, title, validationSchema, initialValues, ...props }) => {
  return (
    <Form {...props} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize>
      <Box header="Maintenance Window Information">
        <div className="flex flex-row w-full gap-16">
          <TextField
            name="name"
            label="Name"
            placeholder="Make it descriptive..."
            additionalClasses={{ textControl: 'flex-grow w-1/2' }}
            required
          />
          <ReasonPickerField
            name="reason"
            label="Reason"
            placeholder="Pick one..."
            fullWidth
            additionalClasses={{ control: 'flex-grow w-1/2' }}
            required
          />
        </div>

        <TextField multiline name="description" label="Description" additionalClasses={{ textControl: 'w-full' }} />
      </Box>
    </Form>
  );
};

GeneralStep.defaultProps = {
  validationSchema: z.object({
    name: z.string().min(1, 'Name is required'),
    reason: z.enum(REASONS),
    description: z.string().optional(),
  }),
  initialValues: {
    name: '',
    reason: 'Configuration change',
    description: '',
  },
};
