const strings = {
  account: {
    account: 'Account',
    admin: 'Admin',
    cancel: 'Cancel',
    discard: 'Discard',
    email: 'Email',
    emailPlaceholder: 'Type your email',
    emailRequired: 'Email is required.',
    groups: 'Groups',
    invalidEmail: 'Invalid email format.',
    lastUpdateDayAgo: 'Last Update 1 day ago',
    lastUpdateDaysAgo: 'Last Update #{{days}} days ago',
    lastUpdateLessThanADayAgo: 'Last Update less than a day ago',
    firstname: 'Firstname',
    lastname: 'Lastname',
    locale: 'Locale',
    firstnamePlaceholder: 'Type your firstname',
    lastnamePlaceholder: 'Type your lastname',
    firstnameRequired: 'Firstname is required.',
    lastnameRequired: 'Lastname is required.',
    password: 'Password',
    passwordUpdateFail: 'Password update was not successful.',
    passwordUpdateSuccess: 'Password updated successfully.',
    realm: 'Realm',
    save: 'Save',
    timezone: 'Timezone',
    timePreferences: 'Time Preferences',
    timezoneRequired: 'Timezone is required.',
    updateFail: 'Account update was not successful.',
    updatePassword: 'Update Password',
    updateSuccess: 'Account updated successfully.',
    userSettings: 'User Settings',
    userSettingsDescription: 'This section contains your basic profile information.',
  },
  accountSidebar: {
    hello: 'Hello',
    profile: 'Account',
  },
  breadcrumbs: {
    rootLabel: 'Home',
  },
  close: {
    closeButtonLabel: 'Back & Close',
  },
  comment: {
    comment: 'Comment',
    commentIsRequired: 'Comment is required.',
    commentPlaceholder: 'Write a comment...',
    commentSaved: 'Comment successfully added!',
    commentSaveFail: 'Adding comment was unsuccessful.',
    commentTitle: 'Title',
    commentTitlePlaceholder: 'Comment Title',
    optionalComment: 'Comment (Optional)',
    optionalCommentPlaceholder: 'Add a comment if needed',
    publishComment: 'Add Comment',
  },
  common: {
    actions: 'Actions',
    addItem: 'Add Item',
    addToFavorites: 'Add To Favorites',
    any: 'Any',
    appliedFilters: 'Filter by:',
    areYouSure: 'Are you sure?',
    cannotBeUndoneWarning: 'Warning: This action cannot be undone.',
    clearAllFilters: 'Clear All Filters',
    closed: 'Closed',
    closing: 'Closing',
    comments: 'Comments',
    copy: 'Copy',
    critical: 'Critical',
    date: 'Date',
    delete: 'Delete',
    download: 'Download',
    duplicate: 'Duplicate',
    edit: 'Edit',
    failure: 'Failure',
    favorite: 'Favorite',
    filter: 'Filter',
    filterBy: 'Filter By',
    frozen: 'Frozen',
    hideAll: 'Hide All',
    high: 'High',
    items: 'Items',
    lockView: 'Lock View',
    low: 'Low',
    medium: 'Medium',
    name: 'Name',
    nameRequired: 'Name is required.',
    new: 'New',
    noData: 'No Data',
    none: 'None',
    normal: 'Normal',
    notYetSupported: 'Unsupported feature - Coming soon!',
    now: 'Now',
    open: 'Open',
    operationFailed: 'Operation failed:',
    operationSuccessful: 'Operation successfully completed!',
    pastDay: 'Past day',
    pastWeek: 'Past week',
    pastMonth: 'Past month',
    pastQuarter: 'Past quarter',
    pastYear: 'Past year',
    private: 'Private',
    sharedWithEveryone: 'Shared With Everyone',
    removeFromFavorites: 'Remove From Favorites',
    rename: 'Rename',
    reset: 'Reset',
    resetSystemDefault: 'Reset to Defaults',
    save: 'Save',
    saveChanges: 'Save Changes',
    search: 'Search',
    settings: 'Settings',
    share: 'Share',
    showAll: 'Show All',
    showing: 'Showing',
    success: 'Success',
    timeControl: 'Time Control',
    tracking: 'Tracking',
    unknownError: 'Unknown error',
    view: 'View',
  },
  copyRight: {
    trademarks:
      'BaseN® and SPIME® are registered trademarks of BaseN Corporation within the United States and European Union',
  },
  dashboards: {
    access: 'Access',
    applyFilter: 'Apply Filter',
    createDashboard: 'Create Dashboard',
    dashboardDeleted: 'Successfully deleted Dashboard [#{{name}}]',
    dashboardFailDelete: 'Could not delete Dashboard [#{{name}}]',
    dashboardFailDeleteMultiple: 'Could not delete Dashboards',
    dashboardFailUpdate: 'Could not update Dashboard',
    dashboardManager: 'This is the dashboard manager where you can find all your dashboards or public dashboards.',
    dashboardName: 'Dashboard Name',
    dashboardNameRequired: 'Name is required.',
    dashboardNotFound: 'Could not find Dashboard',
    dashboardsDeleted: 'Successfully deleted all Dashboards',
    dashboardsTitle: 'Dashboard Manager',
    dashboardUpdated: 'Dashboard successfully updated.',
    deleteDashboard: 'Delete Dashboard',
    deleteDashboards: 'Delete Dashboards',
    deleteDashboardMessage: 'All shared links will be inaccessible and all widgets and configurations will be lost.',
    deleteDashboardTitle: 'Delete [#{{name}}] Dashboard',
    deleteMultipleDashboardsTitle: 'Delete Multiple Dashboards',
    enterFullscreen: 'Enter Fullscreen',
    exitFullscreen: 'Exit Fullscreen',
    favoritesOnly: 'Favorites Only',
    filterByName: 'Filter By Name',
    filterByNamePlaceholder: 'Find your dashboard',
    manageFavorites: 'Manage favorites',
    modified: 'Last Modified',
    myDashboards: 'My Dashboards',
    name: 'Name',
    newDashboard: 'Add New',
    newFolder: 'New Folder',
    newWidget: 'New Widget',
    noDashboards: 'Start building your Dashboard',
    noFilterMatch: 'No Dashboards matching filter',
    owner: 'Owner',
    renameDashboardTitle: 'Rename Dashboard',
    selectedDashboards: '#{{number}} selected Dashboards',
    startAddingWidgets: 'Start adding widgets',
    timeControl: 'Time Control',
    widgetsDescription:
      'This is a dashboard, a new way to visualize your data on BaseN Platform. Start by adding a widget.',
  },
  error: {
    backToHome: 'Back to Home',
    defaultErrorMessage: 'Something went wrong',
    defaultErrorTitle: 'There was a problem',
    forbiddenErrorMessage: 'Forbidden Error Message',
    forbiddenErrorTitle: 'Forbidden',
    internalErrorMessage: 'Internal Error Message',
    notFoundErrorMessage: 'The page you were looking for could not be found',
    notFoundErrorTitle: 'Not Found',
    reloadMessage: 'Reload Page Message',
    reloadPage: 'Reload Page',
    oops: 'Ooops! Something went wrong',
    contactUs: 'contact us',
    contactUsOnError:
      'We track these errors automatically, but if the problem persists feel free to #{{contactUs}}. In the meantime try refreshing.',
    takeABreak: 'Still not working? Maybe is time for a break. Go grab a coffee or drink some water.',
  },
  favorites: {
    addSingleItem: 'Add as single item',
    addToFolders: 'Add to your folders',
    addedSingleItem: 'Added as single item',
    createNewFolder: 'Create new folder',
    noFoldersYet: "You don't have any folders yet",
    folderName: 'Folder name...',
  },
  forgotPassword: {
    codeFailed: 'Wrong verification code. Please try again.',
    codeRequired: 'Code is required.',
    codeSubtitle:
      'We’ve sent you the verification code#{{forAddress}}. If it takes a while you might need to check your spam folder.',
    codeTitle: 'Verification code',
    codeVerificationDone: 'Verification code approved! You can create your new password now.',
    confirmPasswordRequired: 'Confirm Password is required.',
    currentPasswordRequired: 'Current Password is required.',
    currentPasswordLabel: 'Current Password',
    emailFailed: 'Could not send email. Please try again.',
    emailFormatRequired: 'Email is invalid.',
    fieldRequired: 'Email is required.',
    fieldStrength: 'Password is not complex enough.',
    forgotPassword: 'Forgot your password?',
    forWho: 'for',
    goBack: 'Go back to Sign in',
    goodPassword: 'Good password.',
    greatPassword: 'Great password!',
    inputLabel: 'Email',
    newPasswordLabel: 'New Password',
    newPasswordRequired: 'New Password is required.',
    newPasswordTitle: 'Create your new password',
    noMail: "Didn't receive code?",
    noMatch: 'New password and confirmation password do not match.',
    passwordFailed: 'Could not save new password. Please try again.',
    passwordHint:
      'Hint: Use at least #{{minLength}} characters, one lowercase, one uppercase and one special character.',
    passwordRequired: 'Password is required.',
    passwordUpdatedMessage: 'To see or change your password go to User Menu > Settings.',
    passwordUpdatedTitle: 'Your password has been successfully updated',
    repeatNewPasswordLabel: 'Confirm Password',
    resend: 'Resend it',
    resetPasswordAndLogin: 'Reset Password and Login',
    second: 'second',
    seconds: 'seconds',
    sendEmail: 'Send email',
    subTitleOne:
      'Please enter the email address you used when you joined and we’ll send you instructions to reset your password.',
    subTitleTwo: 'For security reasons we will never send your password via email.',
    title: 'Forgot Password?',
    tooManyCodeFailures: 'Too many failures. Please try again later or request another code.',
    tryAgainIn: 'You can try to send the e-mail again in',
    validateCode: 'Validate Verification Code',
    weakPassword: 'Weak password.',
  },
  header: {
    alert: 'Alerts',
    entity: 'Entities',
    dashboard: 'Dashboards',
    issue: 'Issues',
    noResults: 'No results found',
    searchPlaceholder: 'Global Search',
  },
  help: {
    help: 'Help',
    licenses: 'Licenses and Credits',
    patents: 'Patents',
    releaseNotes: 'Release Notes',
    shortcuts: 'Keyboard Shortcuts',
    support: 'Contact Support',
  },
  inventory: {
    description:
      'Manage all your entities in one place! With this feature you can drill down into every entity, filter them, grouping them and much more.',
    glimpse: 'A glimpse of this new feature',
    inventory: 'Inventory',
    parentEntity: 'Parent Entity',
    name: 'Name',
    customer: 'Customer',
    ip: 'IP',
    location: 'Location',
  },
  issues: {
    acknowledge: 'Acknowledge',
    addComment: 'Comment',
    alerts: 'Alert Status',
    allIssuesUpdatedPartialSuccess: 'Not all issues were updated. Only #{{updated}} / #{{number}} were successful.',
    allIssuesUpdatedSuccess: 'All Issues updated successfully (#{{number}}).',
    allIssuesUpdatedError: 'Could not update issues.',
    alreadyAcknowledgedIssues:
      '#{{number}} issue(s) of the selected #{{selected}} have already been acknowledged and are not new. No further updates will happen to these issues.',
    changeStatus: 'Change Status',
    changeStatusLabel: 'New Status',
    changePriority: 'Change Priority',
    changePriorityLabel: 'New Priority',
    comment: 'Comment',
    created: 'Created',
    createNewView: 'Create New View',
    createTicket: 'Create Ticket',
    customActionAcceptLabel: 'Ok',
    defaultIssueManager: 'Default Issue Manager',
    download: 'Download',
    entity: 'Entity',
    externalReference: 'External Reference',
    firstEventTime: 'Time',
    group: 'Group',
    issueManager: 'Issue Manager',
    issueNotFound: 'Issue Not Found',
    issues: 'Issues',
    issueHistoryLog: 'Issue History Log',
    issueView: 'Issue View',
    pickIssueViewOrCreate: 'Pick an Issue View',
    lastUpdate: 'Last Update',
    lastValue: 'Last Value',
    maintenanceWindow: 'Maintenance Window',
    manage: 'Manage',
    manageViews: 'Manage Views',
    name: 'Name',
    noComments: 'No comments on the issue yet.',
    noData: 'No Data',
    noIssues: 'No Issues to show',
    noIssuesSelected: 'No issues selected',
    priority: 'Priority',
    relatedIssues: 'Related Issues',
    retry: 'Refresh',
    rootCauseOnly: 'Only root cause issues',
    selectedIssues: '#{{number}} selected issues',
    socketDisconnected: 'Problems retrieving issue information.',
    state: 'Status',
    status: 'Status',
    statusChangeFromNewToOpen: 'Status will change from New to Open',
    take: 'Take',
    tryNumber: 'Recovery try #{{tryNumber}}. Refresh manually or reload page to restart.',
  },
  issueViewKeys: {
    'alert.id': 'Alert ID',
    'alert.name': 'Alert Name',
    'entity.id': 'Entity ID',
    'entity.name': 'Entity Name',
    alerts: 'Alerts',
    comment: 'Comment',
    created: 'Created',
    description: 'Description',
    entity: 'Entity',
    extref: 'External Reference',
    group: 'Group',
    last_update: 'Last Update',
    last_value: 'Last Value',
    name: 'Name',
    priority: 'Priority',
    state: 'Status',
    status: 'Status',
  },
  issueViewManager: {
    addCustomAction: 'Add Custom Action',
    allFields: 'All Fields',
    allGroups: 'All groups',
    availableViews: 'Available Views',
    cancel: 'Cancel Changes',
    cancelConfirm: 'Are you sure you want to cancel your changes?',
    createNew: 'Create View',
    customActions: 'Custom Actions',
    customActionsLocation: "You can find your custom actions on the Issue Manager's toolbar under ",
    customFields: 'Custom Fields',
    defaultIssueManager: 'Default Issue Manager',
    defaulttRowsPerPage: 'Default rows per page',
    defaultRowHeight: 'Default row height',
    defaultSortColumn: 'Default',
    deleteConfirmMessage: 'Are you sure you want to delete this View?',
    deleteConfirmTitle: 'Delete Issue Manager View',
    deleteError: 'Could not delete View.',
    deleteView: 'Delete View',
    description: 'Description',
    descriptionField: 'Description',
    descriptionFieldPlaceholder: 'Short description of the Issue View',
    discard: 'Discard',
    discardWarning: 'Your current changes will be lost!',
    editView: 'Edit View',
    featureDescription:
      'Views are the new mode to visualize what you need in the issue manager without having to filter it every time.',
    fields: 'Fields',
    groups: 'Groups',
    issueActions: 'Actions',
    issueActionsDescription:
      'Issue Manager has 4 default actions available: Acknowledge, Comment, changePriority and changeStatus. You can add actions with pre-configured scripts below.',
    issueCustomFieldsDescription: 'Select the Custom Fields that you want to visualize in the table within this view.',
    issueCustomFieldsField: 'Custom Fields',
    issueCustomFieldsFieldDescription: 'No Custom Fields Selected',
    issueFieldsDescription: 'Select and organize the columns that you want to see by default in this view.',
    issueFields: 'Table Fields',
    issueFieldsField: 'Default Fields',
    issueGroups: 'Issue Groups',
    issueGroupsDescriptionOne: 'Select the Issue groups that you want to visualize in this view.',
    issueGroupsDescriptionTwo: 'Hint: use exclamation mark (!) to exclude groups.',
    issueGroupsField: 'Groups',
    issueGroupsFieldDescription: 'All Groups',
    issueViewManager: 'Issue View Manager',
    name: 'Name',
    noData: 'None',
    organizeFields: 'Organize Table Columns',
    permissions: 'Permissions',
    permissionsDescription: 'Admin users have the ability to share views with others.',
    required: 'Required',
    save: 'Save',
    script: 'Script',
    sortColumn: 'Sort Column',
    tableFields: 'Table Fields',
    tableRows: 'Table Rows',
    title: 'Issue Manager',
    titleField: 'Title',
    titleFieldPlaceholder: 'Enter a title',
    titleIsRequired: 'Title is required.',
    toolbar: 'Toolbar',
    view: 'View',
    viewConfiguration: 'View Configuration',
    viewDeleted: 'View Successfully Deleted.',
    viewUpdateFail: 'View save unsuccessful.',
    viewUpdatedSuccess: 'View Successfully Saved',
    viewExplanation:
      'The first item will be the view that will appear when you select “Issues” on the left sidebar. If you want other than the Default Issue Manager, just drag and reorganize the views below.',
    viewExplanationTemporary:
      "The first item will always be the default view for Issue Manager. It's the view that will appear when you select “Issues” on the left sidebar. You can re-order the rest below for easier access on the Issue Manager page.",
  },
  keyboardShortcuts: {
    title: 'Keyboard Shortcuts',
    description: 'List of shorcuts to speed up your work',
    intro:
      'Our daily work is easier with keyboard shortcuts, our team knows that, that’s why we are implementing this really nice feature!',
    testOne: 'You can test it out using',
    testTwo: 'to open/close sidebar',
    toggleSidebar: 'Open/close sidebar',
    quickSearch: 'Quick Search',
    openShortcutHelp: 'Open shortcut help',
    closeInfoPanel: 'Close info panel',
    global: 'Global',
  },
  login: {
    aboutBaseN: 'About BaseN Corporation',
    forgotPassword: 'Forgot your password?',
    loginFail: 'Login failed. Please try again.',
    passwordRequired: 'Password is required.',
    rememberMe: 'Remember Me',
    usernameRequired: 'Username is required.',
    welcomeBack: 'Hi, Welcome back!',
  },
  management: {
    account: 'Account',
    accountDescription: 'Manage your account preferences. Most of these options are available on the user menu.',
    issueManager: 'Issue Manager',
    issueManagerDescription: 'Manage Issue Manager’s settings. Create and manage views and choose your preferences.',
    title: 'Administration',
  },
  metrics: {
    glimpse: 'A glimpse of this new feature',
    metrics: 'Metrics',
    description:
      'Are you only interested in the data? So this will be the right place for you to get everything about your data in a faster, organised and upgraded way.',
  },
  modal: {
    acceptLabel: 'Ok',
    apply: 'Apply',
    cancelLabel: 'Cancel',
    closeLabel: 'Close',
    closeButtonLabel: 'Back & Close',
  },
  overview: {
    alerts: 'Alerts',
    alertsDescription: 'Get real-time insights with automatic alerts and notifications. Same foundation, better ux.',
    alreadyAvailable: 'Other features available to you',
    betaVersion: 'Beta Version',
    comingSoon: 'What’s coming soon(ish): A glimpse of the new features',
    dashboards: 'Dashboards',
    dashboardsDescription: 'A new way to display your data',
    favorites: 'Favorites',
    favoritesDescription: 'Manage and organize your favorites into folders.',
    inventory: 'Inventory',
    inventoryDescription:
      'Manage all your entities in one place. Drill down into every entity to manage your services.',
    issueManager: 'Issue Manager',
    issueManagerDescription: 'Better performance and a fresher look',
    logbook: 'Logbook and Maintenance Window',
    logbookDescription: 'Maintenance Window Manager and graphs, enable logbook in the new UI',
    metrics: 'Metrics',
    metricsDescription: 'Get everything about your data in one place. A faster, organised and upgraded way to work.',
    newWidgets: 'New Widgets',
    newWidgetsDescription: 'Improve your dashboards with new widgets. Add images, text, lists, alerts and much more!',
    responsive: 'Fully Responsive Platform',
    responsiveDescription: 'Work in every device you want!',
    shared: 'Shared',
    sharedDescription: 'Admins will be able to share important information for the entire company.',
    trademark:
      'BaseN® and SPIME® are registered trademarks of BaseN Corporation within the United States and European Union',
    userSettings: 'User Settings',
    userSettingsDescription: 'Manage all your settings in one place: change password, update your timezone, etc.',
    welcome: 'Welcome to the new BaseN Platform',
    welcomeDescription:
      'Better performance and a modern well-tried design experience on the same solid BaseN Platform ground.',
  },
  select: {
    clear: 'Clear',
    defaultAddNewLabel: 'Add New Item',
    noResults: 'No results.',
  },
  sidebar: {
    dashboards: 'Dashboards',
    favorites: 'Favorites',
    geo: 'Geo Demo',
    help: 'Help',
    inventory: 'Inventory',
    issueManager: 'Issue Manager',
    keyboardShortcuts: 'Keyboard Shortcuts',
    manage: 'Administration',
    metrics: 'Metrics',
    overview: 'Overview',
    shared: 'Shared',
    storybook: 'Storybook',
    tools: 'Tools',
    alerts: 'Alerts',
  },
  support: {
    emergencySupport: 'Emergency Support',
    emergencySupportCall: '24/7 Emergency Calls',
    emergencySupportOne:
      'Emergency support is needed to handle platform faults, security issues or other critical issues.',
    emergencySupportThree: '24/7 emergency phone calls can be done to #{{supportPhone}}.',
    emergencySupportTwo:
      'It is always recommended that an email to #{{supportMail}} is sent about the situation even if a phone call is placed immediately afterwards. This helps to identify the problem and to establish a more structured communication path.',
    normalRequests: 'Normal support requests should be done through email to #{{supportMail}}.',
    supportEmail: '#{{supportMail}}',
    supportPhone: '#{{supportPhone}}',
    supportRequests: 'Support Requests',
    title: 'BaseN Platform Support',
  },
  table: {
    activeFilters: 'Active filters',
    add: 'Add',
    ascending: 'Ascending',
    defaultNoItemsMessage: 'Nothing To See Here! No data to show.',
    defaultNoMatchingItemsMessage: 'Nothing To See Here! No matches found.',
    descending: 'Descending',
    edit: 'Edit',
    filterColumns: 'Filter By Columns',
    filters: 'Filters',
    itemsSelected: '#{{number}} items selected',
    noItemsSelected: 'No items selected',
    oneItemSelected: '1 item selected',
    rowsPerPageLabel: 'per page:',
    sorted: 'Sorted',
    toggleColumns: 'Show / Hide Columns',
    toggleRowHeight: 'Switch Row Height',
    visibleColumns: 'Visible Columns',
    noDataTitle: 'No records to display',
    noDataDescription: "There's no data to display in this table",
    itemsTotal: '#{{rowCount}} items',
    filtersInSidebar: 'Use Filters on Sidebar',
    filtersInPopup: 'Use Filters on Search Bar',
  },
  userMenu: {
    backToV1: 'Switch to old UI',
    clock: "o'clock",
    help: 'Help',
    notifications: 'Notifications',
    recentChanges: 'Recent Changes / History',
    settings: 'User Settings',
    signOut: 'Sign Out',
    tz: 'Timezone',
    username: 'Username',
    version: 'Platform Version',
    uiversion: 'UI Version',
  },
  widgetPage: {
    add: 'Add',
    addCustomFilter: 'Add custom filter',
    filterByEntityProp: 'Filter by a particular entity property(-ies)',
    chooseEntityProp: 'Choose a property...',
    value: 'value',
    is: 'is',
    alertInfo: 'Alert Information',
    addSuccessful: 'Widget added successfully!',
    addWarning: 'Your changes will be saved as a new Widget.',
    adding: 'Adding Widget',
    areYouSure: 'Are you sure?',
    barChart: 'Bar Chart',
    cannotRetrieveEntities: 'Cannot retrieve entities',
    cannotRetrieveRouteAlerts: 'Cannot retrieve route alerts',
    cannotRetrieveEntityCustomers: 'Cannot retrieve customers',
    chooseType: 'Choose Widget Type',
    chooseYourPalette: 'Choose your Palette...',
    components: 'Components',
    configEditorTitle: 'Code Editor',
    configOverwriteWarning: 'Your current configuration will be overwritten!',
    customer: 'Customer',
    dashboardNotFound: 'Dashboard Not Found',
    dataSource: 'Data source',
    deleteWarning: 'This operation is irreversible! Widget and its associated data will be deleted.',
    deviceType: 'Device Type',
    discard: 'Discard',
    discardWarning: 'Your current changes will be lost!',
    duplicateWarning: "This widget and it's configuration will be cloned as a new Widget on the Dashboard.",
    editWidget: 'Edit Widget',
    entity: 'Entity',
    exportJSON: 'Export to JSON file',
    group: 'Metricset',
    importJSON: 'Import from JSON file',
    jsonView: 'JSON',
    latitude: 'Latitude',
    longitude: 'Longitude',
    lineChart: 'Line Chart',
    lineChartDescription: 'Time based line chart',
    map: 'Map',
    metric: 'Metric',
    newWidgetName: 'New Widget',
    noDataSource: 'Data Source is empty or not valid - nothing to show',
    noLocationData: 'Entities with location data not found - nothing to map',
    page: 'Page',
    palette: 'Palette',
    pieChart: 'Pie Chart',
    plotname: 'Plot name',
    polarChart: 'Polar Chart',
    routeAlert: 'Route Alert',
    routeChannel: 'Route Channel',
    saveCode: 'Save code',
    selectCustomers: 'Select customer(s)',
    selectDataSource: 'Select your Data Source',
    selectEntity: 'Select an entity...',
    selectGroup: 'Select a metricset...',
    selectMetric: 'Select a metric...',
    selectPage: 'Select a page...',
    selectPlotname: 'Select a plot name...',
    selectSubEntity: 'Maybe select a sub-entity...',
    selectRouteAlert: 'Select route alert...',
    selectRouteChannel: 'Select route channel...',
    showZoomSlider: 'Zoom slider',
    showEntityLabels: 'Entity labels',
    showMaintenanceWindows: 'Show Maintenance Windows',
    subEntity: 'Sub entity',
    timeSeries: 'Time series',
    topologyMap: 'Topology',
    update: 'Update',
    updateWarning: 'Your changes will overwrite existing Widget configuration.',
    widgetList: 'Widget List',
    widgetNotFound: 'Widget Not Found',
    widgetType: 'Widget type',
    zoom: 'Zoom',
    switchToTable: 'Switch to statistics',
    switchToTimeSeries: 'Switch to Timeseries view',
    resetToDefaultDateRange: 'Reset to default date range',
    downloadAsImage: 'Download as image',
  },
};

export default strings;
