import { DEFAULT_TABLE_CONFIG, Sort, Table } from '@components/common/Table';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { WidgetComponentProps } from '@components/pages/WidgetPage/types';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import { ChildEntity } from '@redux/inventoryPage';
import ROUTES from '@infrastructure/routes';
import { EntityDataSource, WidgetHandle } from '@redux/widgetPage';
import React, { useMemo, useState, useCallback, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTableColumns } from './getTableColumns';

import styles from './SubEntitiesWidget.module.scss';

export type SubEntitiesWidgetProps = WidgetComponentProps;

export const SubEntitiesWidget = forwardRef<WidgetHandle, SubEntitiesWidgetProps>(({ config }, ref) => {
  const { source } = config ?? {};
  const entityDataSource = source as EntityDataSource;
  const navigate = useNavigate();

  const {
    page: initialPage,
    rowsPerPage: initialRowsPerPage,
    sortable,
    paginated,
    ...otherConfig
  } = DEFAULT_TABLE_CONFIG;

  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [sort, setSort] = useState<Sort>('asc' as Sort);
  const [sortBy, setSortBy] = useState<string | null>(null);

  const query = useEntityQuery({
    entityId: entityDataSource.entityId,
    include_children: true,
    include_child_metricsets: true,
    include_child_alerts: true,
    start_index: (page - 1) * rowsPerPage,
    end_index: (page - 1) * rowsPerPage + rowsPerPage,
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSortChange = (property: string | null, order: Sort) => {
    setSort(order);
    setSortBy(property);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  };

  const handleClick = useCallback(
    (e, item) => {
      navigate(`${ROUTES.entityById.replace(':entityId', item.id)}`);
    },
    [navigate]
  );

  const columns = useMemo(() => getTableColumns(), []);

  const tableConfig = useMemo(
    () => ({
      ...otherConfig,
      sortable: true,
      columnsToggleable: true,
      rowHeightToggleable: false,
      hasToolbar: true,
      paginated: true,
      selectable: false,
      page,
      rowCount: query.data?.totalchildren ?? query.data?.children?.length ?? 0,
      sort,
      sortBy,
      rowsPerPage,
    }),
    [page, rowsPerPage, sort, sortBy, otherConfig, query.data]
  );

  return (
    <div className={styles.subEntitiesWidget}>
      <Table<ChildEntity>
        columns={columns}
        items={query.data?.children ?? []}
        config={tableConfig}
        onRowsPerPageChange={handleRowsPerPageChange}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
        onCellClick={handleClick}
      />
      {query.isLoading && (
        <div className={styles.overlay}>
          <LoadingSpinner centered />
        </div>
      )}
    </div>
  );
});
