import { COMMON_DATE_INTERVALS, MYSQL_DATETIME_FORMAT } from '@constants/date';
import { DateRange } from '@blueprintjs/datetime';
import moment from 'moment';
import 'moment-timezone';

let timeFormatter: Intl.DateTimeFormat;
let usedTz: string;

const createTimeFormatter = (
  options: Intl.DateTimeFormatOptions,
  timeZone: string = 'Europe/Helsinki'
): Intl.DateTimeFormat => {
  const finalOptions: Intl.DateTimeFormatOptions = {
    timeZone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    ...options,
  };

  usedTz = timeZone;
  timeFormatter = new Intl.DateTimeFormat([], finalOptions);

  return timeFormatter;
};

const getTimeFormatter = (tz?: string, options: Intl.DateTimeFormatOptions = {}): Intl.DateTimeFormat => {
  if (!tz || (tz && tz !== usedTz) || !timeFormatter) {
    return createTimeFormatter(options, tz || undefined);
  }

  return timeFormatter;
};
//
// const getTimeZoneUTCOffset = (locale: string = 'en-FI', timeZone: string = 'Europe/Helsinki') => {
//   const date = new Date();
//   // Abuse the Intl API to get a local ISO 8601 string for a given time zone.
//   let iso = date.toLocaleString(locale, { timeZone, hour12: false }).replace(', ', 'T');
//
//   // Include the milliseconds from the original timestamp
//   iso += `.${date.getMilliseconds().toString().padStart(3, '0')}`;
//
//   // Lie to the Date object constructor that it's a UTC time.
//   const lie = new Date(`${iso}Z`);
//
//   // Return the difference in timestamps, as minutes
//   // Positive values are West of GMT, opposite of ISO 8601
//   // this matches the output of `Date.getTimeZoneOffset`
//   return -(lie - date) / 60 / 1000;
// };
//
// const getTimezoneUTCOffset = (locale: string, timeZone: string, date = new Date()): string | null => {
//   try {
//     const formatter = getTimeFormatter(timeZone);
//     const tz = date.toLocaleString(locale, { timeZone, timeStyle: 'long' }).split(' ').slice(-1)[0];
//
//     const dateString = date.toString();
//
//     const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);
//     const prefix = offset >= 0 ? '+' : '-';
//
//     return `${prefix}${offset / 60 / 60 / 1000}`;
//   } catch (e) {
//     return null;
//   }
// };

export const parseDate = (str: string) => new Date(str);

export function formatDate(date: Date, locale?: string): string;
export function formatDate(date: Date, locale?: string, withTime?: boolean): string;
export function formatDate(...args: any[]): string {
  return moment(args[0]).format(`YYYY-MM-DD${args[2] ? ' HH:mm:ss' : ''}`);
}

export function formatEpochDate(date?: string | number | null, format?: string) {
  return date ? moment(date).format(format || MYSQL_DATETIME_FORMAT) : 'Invalid date';
}

// export { getTimeZoneUTCOffset, getTimeFormatter };
export { getTimeFormatter };

export const getMatchingInterval = (dateRange: DateRange) => {
  return COMMON_DATE_INTERVALS.find(
    ({ value }) => Math.abs(Math.ceil(moment(dateRange[1]).diff(dateRange[0], value[1], true))) === value[0]
  );
};

export function toFakeTimezonedJSDate(date: Date, timezone: string) {
  const m = moment(date).tz(timezone);

  // ignoring timezone offset
  return new Date(m.year(), m.month(), m.date(), m.hours(), m.minutes(), m.seconds(), m.milliseconds());
}

export function fromFakeTimezonedJSDate(date: Date, timezone: string) {
  const m = moment(date).tz(timezone, true);

  return m.utc().toDate();
}

export function inDateRange(date: number | Date, dateRange: DateRange) {
  const [fromDate, toDate] = dateRange;
  return moment(date).isBetween(fromDate, toDate ?? undefined, 'ms', '[]');
}

export const TIMEZONE_OPTIONS = [
  { label: 'Africa/Abidjan', value: 'Africa/Abidjan' },
  { label: 'Africa/Accra', value: 'Africa/Accra' },
  { label: 'Africa/Addis_Ababa', value: 'Africa/Addis_Ababa' },
  { label: 'Africa/Algiers', value: 'Africa/Algiers' },
  { label: 'Africa/Asmara', value: 'Africa/Asmara' },
  { label: 'Africa/Asmera', value: 'Africa/Asmera' },
  { label: 'Africa/Bamako', value: 'Africa/Bamako' },
  { label: 'Africa/Bangui', value: 'Africa/Bangui' },
  { label: 'Africa/Banjul', value: 'Africa/Banjul' },
  { label: 'Africa/Bissau', value: 'Africa/Bissau' },
  { label: 'Africa/Blantyre', value: 'Africa/Blantyre' },
  { label: 'Africa/Brazzaville', value: 'Africa/Brazzaville' },
  { label: 'Africa/Bujumbura', value: 'Africa/Bujumbura' },
  { label: 'Africa/Cairo', value: 'Africa/Cairo' },
  { label: 'Africa/Casablanca', value: 'Africa/Casablanca' },
  { label: 'Africa/Ceuta', value: 'Africa/Ceuta' },
  { label: 'Africa/Conakry', value: 'Africa/Conakry' },
  { label: 'Africa/Dakar', value: 'Africa/Dakar' },
  { label: 'Africa/Dar_es_Salaam', value: 'Africa/Dar_es_Salaam' },
  { label: 'Africa/Djibouti', value: 'Africa/Djibouti' },
  { label: 'Africa/Douala', value: 'Africa/Douala' },
  { label: 'Africa/El_Aaiun', value: 'Africa/El_Aaiun' },
  { label: 'Africa/Freetown', value: 'Africa/Freetown' },
  { label: 'Africa/Gaborone', value: 'Africa/Gaborone' },
  { label: 'Africa/Harare', value: 'Africa/Harare' },
  { label: 'Africa/Johannesburg', value: 'Africa/Johannesburg' },
  { label: 'Africa/Juba', value: 'Africa/Juba' },
  { label: 'Africa/Kampala', value: 'Africa/Kampala' },
  { label: 'Africa/Khartoum', value: 'Africa/Khartoum' },
  { label: 'Africa/Kigali', value: 'Africa/Kigali' },
  { label: 'Africa/Kinshasa', value: 'Africa/Kinshasa' },
  { label: 'Africa/Lagos', value: 'Africa/Lagos' },
  { label: 'Africa/Libreville', value: 'Africa/Libreville' },
  { label: 'Africa/Lome', value: 'Africa/Lome' },
  { label: 'Africa/Luanda', value: 'Africa/Luanda' },
  { label: 'Africa/Lubumbashi', value: 'Africa/Lubumbashi' },
  { label: 'Africa/Lusaka', value: 'Africa/Lusaka' },
  { label: 'Africa/Malabo', value: 'Africa/Malabo' },
  { label: 'Africa/Maputo', value: 'Africa/Maputo' },
  { label: 'Africa/Maseru', value: 'Africa/Maseru' },
  { label: 'Africa/Mbabane', value: 'Africa/Mbabane' },
  { label: 'Africa/Mogadishu', value: 'Africa/Mogadishu' },
  { label: 'Africa/Monrovia', value: 'Africa/Monrovia' },
  { label: 'Africa/Nairobi', value: 'Africa/Nairobi' },
  { label: 'Africa/Ndjamena', value: 'Africa/Ndjamena' },
  { label: 'Africa/Niamey', value: 'Africa/Niamey' },
  { label: 'Africa/Nouakchott', value: 'Africa/Nouakchott' },
  { label: 'Africa/Ouagadougou', value: 'Africa/Ouagadougou' },
  { label: 'Africa/Porto-Novo', value: 'Africa/Porto-Novo' },
  { label: 'Africa/Sao_Tome', value: 'Africa/Sao_Tome' },
  { label: 'Africa/Timbuktu', value: 'Africa/Timbuktu' },
  { label: 'Africa/Tripoli', value: 'Africa/Tripoli' },
  { label: 'Africa/Tunis', value: 'Africa/Tunis' },
  { label: 'Africa/Windhoek', value: 'Africa/Windhoek' },
  { label: 'America/Adak', value: 'America/Adak' },
  { label: 'America/Anchorage', value: 'America/Anchorage' },
  { label: 'America/Anguilla', value: 'America/Anguilla' },
  { label: 'America/Antigua', value: 'America/Antigua' },
  { label: 'America/Araguaina', value: 'America/Araguaina' },
  { label: 'America/Argentina/Buenos_Aires', value: 'America/Argentina/Buenos_Aires' },
  { label: 'America/Argentina/Catamarca', value: 'America/Argentina/Catamarca' },
  { label: 'America/Argentina/ComodRivadavia', value: 'America/Argentina/ComodRivadavia' },
  { label: 'America/Argentina/Cordoba', value: 'America/Argentina/Cordoba' },
  { label: 'America/Argentina/Jujuy', value: 'America/Argentina/Jujuy' },
  { label: 'America/Argentina/La_Rioja', value: 'America/Argentina/La_Rioja' },
  { label: 'America/Argentina/Mendoza', value: 'America/Argentina/Mendoza' },
  { label: 'America/Argentina/Rio_Gallegos', value: 'America/Argentina/Rio_Gallegos' },
  { label: 'America/Argentina/Salta', value: 'America/Argentina/Salta' },
  { label: 'America/Argentina/San_Juan', value: 'America/Argentina/San_Juan' },
  { label: 'America/Argentina/San_Luis', value: 'America/Argentina/San_Luis' },
  { label: 'America/Argentina/Tucuman', value: 'America/Argentina/Tucuman' },
  { label: 'America/Argentina/Ushuaia', value: 'America/Argentina/Ushuaia' },
  { label: 'America/Aruba', value: 'America/Aruba' },
  { label: 'America/Asuncion', value: 'America/Asuncion' },
  { label: 'America/Atikokan', value: 'America/Atikokan' },
  { label: 'America/Atka', value: 'America/Atka' },
  { label: 'America/Bahia', value: 'America/Bahia' },
  { label: 'America/Bahia_Banderas', value: 'America/Bahia_Banderas' },
  { label: 'America/Barbados', value: 'America/Barbados' },
  { label: 'America/Belem', value: 'America/Belem' },
  { label: 'America/Belize', value: 'America/Belize' },
  { label: 'America/Blanc-Sablon', value: 'America/Blanc-Sablon' },
  { label: 'America/Boa_Vista', value: 'America/Boa_Vista' },
  { label: 'America/Bogota', value: 'America/Bogota' },
  { label: 'America/Boise', value: 'America/Boise' },
  { label: 'America/Buenos_Aires', value: 'America/Buenos_Aires' },
  { label: 'America/Cambridge_Bay', value: 'America/Cambridge_Bay' },
  { label: 'America/Campo_Grande', value: 'America/Campo_Grande' },
  { label: 'America/Cancun', value: 'America/Cancun' },
  { label: 'America/Caracas', value: 'America/Caracas' },
  { label: 'America/Catamarca', value: 'America/Catamarca' },
  { label: 'America/Cayenne', value: 'America/Cayenne' },
  { label: 'America/Cayman', value: 'America/Cayman' },
  { label: 'America/Chicago', value: 'America/Chicago' },
  { label: 'America/Chihuahua', value: 'America/Chihuahua' },
  { label: 'America/Coral_Harbour', value: 'America/Coral_Harbour' },
  { label: 'America/Cordoba', value: 'America/Cordoba' },
  { label: 'America/Costa_Rica', value: 'America/Costa_Rica' },
  { label: 'America/Creston', value: 'America/Creston' },
  { label: 'America/Cuiaba', value: 'America/Cuiaba' },
  { label: 'America/Curacao', value: 'America/Curacao' },
  { label: 'America/Danmarkshavn', value: 'America/Danmarkshavn' },
  { label: 'America/Dawson', value: 'America/Dawson' },
  { label: 'America/Dawson_Creek', value: 'America/Dawson_Creek' },
  { label: 'America/Denver', value: 'America/Denver' },
  { label: 'America/Detroit', value: 'America/Detroit' },
  { label: 'America/Dominica', value: 'America/Dominica' },
  { label: 'America/Edmonton', value: 'America/Edmonton' },
  { label: 'America/Eirunepe', value: 'America/Eirunepe' },
  { label: 'America/El_Salvador', value: 'America/El_Salvador' },
  { label: 'America/Ensenada', value: 'America/Ensenada' },
  { label: 'America/Fort_Nelson', value: 'America/Fort_Nelson' },
  { label: 'America/Fort_Wayne', value: 'America/Fort_Wayne' },
  { label: 'America/Fortaleza', value: 'America/Fortaleza' },
  { label: 'America/Glace_Bay', value: 'America/Glace_Bay' },
  { label: 'America/Godthab', value: 'America/Godthab' },
  { label: 'America/Goose_Bay', value: 'America/Goose_Bay' },
  { label: 'America/Grand_Turk', value: 'America/Grand_Turk' },
  { label: 'America/Grenada', value: 'America/Grenada' },
  { label: 'America/Guadeloupe', value: 'America/Guadeloupe' },
  { label: 'America/Guatemala', value: 'America/Guatemala' },
  { label: 'America/Guayaquil', value: 'America/Guayaquil' },
  { label: 'America/Guyana', value: 'America/Guyana' },
  { label: 'America/Halifax', value: 'America/Halifax' },
  { label: 'America/Havana', value: 'America/Havana' },
  { label: 'America/Hermosillo', value: 'America/Hermosillo' },
  { label: 'America/Indiana/Indianapolis', value: 'America/Indiana/Indianapolis' },
  { label: 'America/Indiana/Knox', value: 'America/Indiana/Knox' },
  { label: 'America/Indiana/Marengo', value: 'America/Indiana/Marengo' },
  { label: 'America/Indiana/Petersburg', value: 'America/Indiana/Petersburg' },
  { label: 'America/Indiana/Tell_City', value: 'America/Indiana/Tell_City' },
  { label: 'America/Indiana/Vevay', value: 'America/Indiana/Vevay' },
  { label: 'America/Indiana/Vincennes', value: 'America/Indiana/Vincennes' },
  { label: 'America/Indiana/Winamac', value: 'America/Indiana/Winamac' },
  { label: 'America/Indianapolis', value: 'America/Indianapolis' },
  { label: 'America/Inuvik', value: 'America/Inuvik' },
  { label: 'America/Iqaluit', value: 'America/Iqaluit' },
  { label: 'America/Jamaica', value: 'America/Jamaica' },
  { label: 'America/Jujuy', value: 'America/Jujuy' },
  { label: 'America/Juneau', value: 'America/Juneau' },
  { label: 'America/Kentucky/Louisville', value: 'America/Kentucky/Louisville' },
  { label: 'America/Kentucky/Monticello', value: 'America/Kentucky/Monticello' },
  { label: 'America/Knox_IN', value: 'America/Knox_IN' },
  { label: 'America/Kralendijk', value: 'America/Kralendijk' },
  { label: 'America/La_Paz', value: 'America/La_Paz' },
  { label: 'America/Lima', value: 'America/Lima' },
  { label: 'America/Los_Angeles', value: 'America/Los_Angeles' },
  { label: 'America/Louisville', value: 'America/Louisville' },
  { label: 'America/Lower_Princes', value: 'America/Lower_Princes' },
  { label: 'America/Maceio', value: 'America/Maceio' },
  { label: 'America/Managua', value: 'America/Managua' },
  { label: 'America/Manaus', value: 'America/Manaus' },
  { label: 'America/Marigot', value: 'America/Marigot' },
  { label: 'America/Martinique', value: 'America/Martinique' },
  { label: 'America/Matamoros', value: 'America/Matamoros' },
  { label: 'America/Mazatlan', value: 'America/Mazatlan' },
  { label: 'America/Mendoza', value: 'America/Mendoza' },
  { label: 'America/Menominee', value: 'America/Menominee' },
  { label: 'America/Merida', value: 'America/Merida' },
  { label: 'America/Metlakatla', value: 'America/Metlakatla' },
  { label: 'America/Mexico_City', value: 'America/Mexico_City' },
  { label: 'America/Miquelon', value: 'America/Miquelon' },
  { label: 'America/Moncton', value: 'America/Moncton' },
  { label: 'America/Monterrey', value: 'America/Monterrey' },
  { label: 'America/Montevideo', value: 'America/Montevideo' },
  { label: 'America/Montreal', value: 'America/Montreal' },
  { label: 'America/Montserrat', value: 'America/Montserrat' },
  { label: 'America/Nassau', value: 'America/Nassau' },
  { label: 'America/New_York', value: 'America/New_York' },
  { label: 'America/Nipigon', value: 'America/Nipigon' },
  { label: 'America/Nome', value: 'America/Nome' },
  { label: 'America/Noronha', value: 'America/Noronha' },
  { label: 'America/North_Dakota/Beulah', value: 'America/North_Dakota/Beulah' },
  { label: 'America/North_Dakota/Center', value: 'America/North_Dakota/Center' },
  { label: 'America/North_Dakota/New_Salem', value: 'America/North_Dakota/New_Salem' },
  { label: 'America/Nuuk', value: 'America/Nuuk' },
  { label: 'America/Ojinaga', value: 'America/Ojinaga' },
  { label: 'America/Panama', value: 'America/Panama' },
  { label: 'America/Pangnirtung', value: 'America/Pangnirtung' },
  { label: 'America/Paramaribo', value: 'America/Paramaribo' },
  { label: 'America/Phoenix', value: 'America/Phoenix' },
  { label: 'America/Port-au-Prince', value: 'America/Port-au-Prince' },
  { label: 'America/Port_of_Spain', value: 'America/Port_of_Spain' },
  { label: 'America/Porto_Acre', value: 'America/Porto_Acre' },
  { label: 'America/Porto_Velho', value: 'America/Porto_Velho' },
  { label: 'America/Puerto_Rico', value: 'America/Puerto_Rico' },
  { label: 'America/Punta_Arenas', value: 'America/Punta_Arenas' },
  { label: 'America/Rainy_River', value: 'America/Rainy_River' },
  { label: 'America/Rankin_Inlet', value: 'America/Rankin_Inlet' },
  { label: 'America/Recife', value: 'America/Recife' },
  { label: 'America/Regina', value: 'America/Regina' },
  { label: 'America/Resolute', value: 'America/Resolute' },
  { label: 'America/Rio_Branco', value: 'America/Rio_Branco' },
  { label: 'America/Rosario', value: 'America/Rosario' },
  { label: 'America/Santa_Isabel', value: 'America/Santa_Isabel' },
  { label: 'America/Santarem', value: 'America/Santarem' },
  { label: 'America/Santiago', value: 'America/Santiago' },
  { label: 'America/Santo_Domingo', value: 'America/Santo_Domingo' },
  { label: 'America/Sao_Paulo', value: 'America/Sao_Paulo' },
  { label: 'America/Scoresbysund', value: 'America/Scoresbysund' },
  { label: 'America/Shiprock', value: 'America/Shiprock' },
  { label: 'America/Sitka', value: 'America/Sitka' },
  { label: 'America/St_Barthelemy', value: 'America/St_Barthelemy' },
  { label: 'America/St_Johns', value: 'America/St_Johns' },
  { label: 'America/St_Kitts', value: 'America/St_Kitts' },
  { label: 'America/St_Lucia', value: 'America/St_Lucia' },
  { label: 'America/St_Thomas', value: 'America/St_Thomas' },
  { label: 'America/St_Vincent', value: 'America/St_Vincent' },
  { label: 'America/Swift_Current', value: 'America/Swift_Current' },
  { label: 'America/Tegucigalpa', value: 'America/Tegucigalpa' },
  { label: 'America/Thule', value: 'America/Thule' },
  { label: 'America/Thunder_Bay', value: 'America/Thunder_Bay' },
  { label: 'America/Tijuana', value: 'America/Tijuana' },
  { label: 'America/Toronto', value: 'America/Toronto' },
  { label: 'America/Tortola', value: 'America/Tortola' },
  { label: 'America/Vancouver', value: 'America/Vancouver' },
  { label: 'America/Virgin', value: 'America/Virgin' },
  { label: 'America/Whitehorse', value: 'America/Whitehorse' },
  { label: 'America/Winnipeg', value: 'America/Winnipeg' },
  { label: 'America/Yakutat', value: 'America/Yakutat' },
  { label: 'America/Yellowknife', value: 'America/Yellowknife' },
  { label: 'Antarctica/Casey', value: 'Antarctica/Casey' },
  { label: 'Antarctica/Davis', value: 'Antarctica/Davis' },
  { label: 'Antarctica/DumontDUrville', value: 'Antarctica/DumontDUrville' },
  { label: 'Antarctica/Macquarie', value: 'Antarctica/Macquarie' },
  { label: 'Antarctica/Mawson', value: 'Antarctica/Mawson' },
  { label: 'Antarctica/McMurdo', value: 'Antarctica/McMurdo' },
  { label: 'Antarctica/Palmer', value: 'Antarctica/Palmer' },
  { label: 'Antarctica/Rothera', value: 'Antarctica/Rothera' },
  { label: 'Antarctica/South_Pole', value: 'Antarctica/South_Pole' },
  { label: 'Antarctica/Syowa', value: 'Antarctica/Syowa' },
  { label: 'Antarctica/Troll', value: 'Antarctica/Troll' },
  { label: 'Antarctica/Vostok', value: 'Antarctica/Vostok' },
  { label: 'Arctic/Longyearbyen', value: 'Arctic/Longyearbyen' },
  { label: 'Asia/Aden', value: 'Asia/Aden' },
  { label: 'Asia/Almaty', value: 'Asia/Almaty' },
  { label: 'Asia/Amman', value: 'Asia/Amman' },
  { label: 'Asia/Anadyr', value: 'Asia/Anadyr' },
  { label: 'Asia/Aqtau', value: 'Asia/Aqtau' },
  { label: 'Asia/Aqtobe', value: 'Asia/Aqtobe' },
  { label: 'Asia/Ashgabat', value: 'Asia/Ashgabat' },
  { label: 'Asia/Ashkhabad', value: 'Asia/Ashkhabad' },
  { label: 'Asia/Atyrau', value: 'Asia/Atyrau' },
  { label: 'Asia/Baghdad', value: 'Asia/Baghdad' },
  { label: 'Asia/Bahrain', value: 'Asia/Bahrain' },
  { label: 'Asia/Baku', value: 'Asia/Baku' },
  { label: 'Asia/Bangkok', value: 'Asia/Bangkok' },
  { label: 'Asia/Barnaul', value: 'Asia/Barnaul' },
  { label: 'Asia/Beirut', value: 'Asia/Beirut' },
  { label: 'Asia/Bishkek', value: 'Asia/Bishkek' },
  { label: 'Asia/Brunei', value: 'Asia/Brunei' },
  { label: 'Asia/Calcutta', value: 'Asia/Calcutta' },
  { label: 'Asia/Chita', value: 'Asia/Chita' },
  { label: 'Asia/Choibalsan', value: 'Asia/Choibalsan' },
  { label: 'Asia/Chongqing', value: 'Asia/Chongqing' },
  { label: 'Asia/Chungking', value: 'Asia/Chungking' },
  { label: 'Asia/Colombo', value: 'Asia/Colombo' },
  { label: 'Asia/Dacca', value: 'Asia/Dacca' },
  { label: 'Asia/Damascus', value: 'Asia/Damascus' },
  { label: 'Asia/Dhaka', value: 'Asia/Dhaka' },
  { label: 'Asia/Dili', value: 'Asia/Dili' },
  { label: 'Asia/Dubai', value: 'Asia/Dubai' },
  { label: 'Asia/Dushanbe', value: 'Asia/Dushanbe' },
  { label: 'Asia/Famagusta', value: 'Asia/Famagusta' },
  { label: 'Asia/Gaza', value: 'Asia/Gaza' },
  { label: 'Asia/Harbin', value: 'Asia/Harbin' },
  { label: 'Asia/Hebron', value: 'Asia/Hebron' },
  { label: 'Asia/Ho_Chi_Minh', value: 'Asia/Ho_Chi_Minh' },
  { label: 'Asia/Hong_Kong', value: 'Asia/Hong_Kong' },
  { label: 'Asia/Hovd', value: 'Asia/Hovd' },
  { label: 'Asia/Irkutsk', value: 'Asia/Irkutsk' },
  { label: 'Asia/Istanbul', value: 'Asia/Istanbul' },
  { label: 'Asia/Jakarta', value: 'Asia/Jakarta' },
  { label: 'Asia/Jayapura', value: 'Asia/Jayapura' },
  { label: 'Asia/Jerusalem', value: 'Asia/Jerusalem' },
  { label: 'Asia/Kabul', value: 'Asia/Kabul' },
  { label: 'Asia/Kamchatka', value: 'Asia/Kamchatka' },
  { label: 'Asia/Karachi', value: 'Asia/Karachi' },
  { label: 'Asia/Kashgar', value: 'Asia/Kashgar' },
  { label: 'Asia/Kathmandu', value: 'Asia/Kathmandu' },
  { label: 'Asia/Katmandu', value: 'Asia/Katmandu' },
  { label: 'Asia/Khandyga', value: 'Asia/Khandyga' },
  { label: 'Asia/Kolkata', value: 'Asia/Kolkata' },
  { label: 'Asia/Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
  { label: 'Asia/Kuala_Lumpur', value: 'Asia/Kuala_Lumpur' },
  { label: 'Asia/Kuching', value: 'Asia/Kuching' },
  { label: 'Asia/Kuwait', value: 'Asia/Kuwait' },
  { label: 'Asia/Macao', value: 'Asia/Macao' },
  { label: 'Asia/Macau', value: 'Asia/Macau' },
  { label: 'Asia/Magadan', value: 'Asia/Magadan' },
  { label: 'Asia/Makassar', value: 'Asia/Makassar' },
  { label: 'Asia/Manila', value: 'Asia/Manila' },
  { label: 'Asia/Muscat', value: 'Asia/Muscat' },
  { label: 'Asia/Nicosia', value: 'Asia/Nicosia' },
  { label: 'Asia/Novokuznetsk', value: 'Asia/Novokuznetsk' },
  { label: 'Asia/Novosibirsk', value: 'Asia/Novosibirsk' },
  { label: 'Asia/Omsk', value: 'Asia/Omsk' },
  { label: 'Asia/Oral', value: 'Asia/Oral' },
  { label: 'Asia/Phnom_Penh', value: 'Asia/Phnom_Penh' },
  { label: 'Asia/Pontianak', value: 'Asia/Pontianak' },
  { label: 'Asia/Pyongyang', value: 'Asia/Pyongyang' },
  { label: 'Asia/Qatar', value: 'Asia/Qatar' },
  { label: 'Asia/Qostanay', value: 'Asia/Qostanay' },
  { label: 'Asia/Qyzylorda', value: 'Asia/Qyzylorda' },
  { label: 'Asia/Rangoon', value: 'Asia/Rangoon' },
  { label: 'Asia/Riyadh', value: 'Asia/Riyadh' },
  { label: 'Asia/Saigon', value: 'Asia/Saigon' },
  { label: 'Asia/Sakhalin', value: 'Asia/Sakhalin' },
  { label: 'Asia/Samarkand', value: 'Asia/Samarkand' },
  { label: 'Asia/Seoul', value: 'Asia/Seoul' },
  { label: 'Asia/Shanghai', value: 'Asia/Shanghai' },
  { label: 'Asia/Singapore', value: 'Asia/Singapore' },
  { label: 'Asia/Srednekolymsk', value: 'Asia/Srednekolymsk' },
  { label: 'Asia/Taipei', value: 'Asia/Taipei' },
  { label: 'Asia/Tashkent', value: 'Asia/Tashkent' },
  { label: 'Asia/Tbilisi', value: 'Asia/Tbilisi' },
  { label: 'Asia/Tehran', value: 'Asia/Tehran' },
  { label: 'Asia/Tel_Aviv', value: 'Asia/Tel_Aviv' },
  { label: 'Asia/Thimbu', value: 'Asia/Thimbu' },
  { label: 'Asia/Thimphu', value: 'Asia/Thimphu' },
  { label: 'Asia/Tokyo', value: 'Asia/Tokyo' },
  { label: 'Asia/Tomsk', value: 'Asia/Tomsk' },
  { label: 'Asia/Ujung_Pandang', value: 'Asia/Ujung_Pandang' },
  { label: 'Asia/Ulaanbaatar', value: 'Asia/Ulaanbaatar' },
  { label: 'Asia/Ulan_Bator', value: 'Asia/Ulan_Bator' },
  { label: 'Asia/Urumqi', value: 'Asia/Urumqi' },
  { label: 'Asia/Ust-Nera', value: 'Asia/Ust-Nera' },
  { label: 'Asia/Vientiane', value: 'Asia/Vientiane' },
  { label: 'Asia/Vladivostok', value: 'Asia/Vladivostok' },
  { label: 'Asia/Yakutsk', value: 'Asia/Yakutsk' },
  { label: 'Asia/Yangon', value: 'Asia/Yangon' },
  { label: 'Asia/Yekaterinburg', value: 'Asia/Yekaterinburg' },
  { label: 'Asia/Yerevan', value: 'Asia/Yerevan' },
  { label: 'Atlantic/Azores', value: 'Atlantic/Azores' },
  { label: 'Atlantic/Bermuda', value: 'Atlantic/Bermuda' },
  { label: 'Atlantic/Canary', value: 'Atlantic/Canary' },
  { label: 'Atlantic/Cape_Verde', value: 'Atlantic/Cape_Verde' },
  { label: 'Atlantic/Faeroe', value: 'Atlantic/Faeroe' },
  { label: 'Atlantic/Faroe', value: 'Atlantic/Faroe' },
  { label: 'Atlantic/Jan_Mayen', value: 'Atlantic/Jan_Mayen' },
  { label: 'Atlantic/Madeira', value: 'Atlantic/Madeira' },
  { label: 'Atlantic/Reykjavik', value: 'Atlantic/Reykjavik' },
  { label: 'Atlantic/South_Georgia', value: 'Atlantic/South_Georgia' },
  { label: 'Atlantic/St_Helena', value: 'Atlantic/St_Helena' },
  { label: 'Atlantic/Stanley', value: 'Atlantic/Stanley' },
  { label: 'Australia/ACT', value: 'Australia/ACT' },
  { label: 'Australia/Adelaide', value: 'Australia/Adelaide' },
  { label: 'Australia/Brisbane', value: 'Australia/Brisbane' },
  { label: 'Australia/Broken_Hill', value: 'Australia/Broken_Hill' },
  { label: 'Australia/Canberra', value: 'Australia/Canberra' },
  { label: 'Australia/Currie', value: 'Australia/Currie' },
  { label: 'Australia/Darwin', value: 'Australia/Darwin' },
  { label: 'Australia/Eucla', value: 'Australia/Eucla' },
  { label: 'Australia/Hobart', value: 'Australia/Hobart' },
  { label: 'Australia/LHI', value: 'Australia/LHI' },
  { label: 'Australia/Lindeman', value: 'Australia/Lindeman' },
  { label: 'Australia/Lord_Howe', value: 'Australia/Lord_Howe' },
  { label: 'Australia/Melbourne', value: 'Australia/Melbourne' },
  { label: 'Australia/North', value: 'Australia/North' },
  { label: 'Australia/NSW', value: 'Australia/NSW' },
  { label: 'Australia/Perth', value: 'Australia/Perth' },
  { label: 'Australia/Queensland', value: 'Australia/Queensland' },
  { label: 'Australia/South', value: 'Australia/South' },
  { label: 'Australia/Sydney', value: 'Australia/Sydney' },
  { label: 'Australia/Tasmania', value: 'Australia/Tasmania' },
  { label: 'Australia/Victoria', value: 'Australia/Victoria' },
  { label: 'Australia/West', value: 'Australia/West' },
  { label: 'Australia/Yancowinna', value: 'Australia/Yancowinna' },
  { label: 'Brazil/Acre', value: 'Brazil/Acre' },
  { label: 'Brazil/DeNoronha', value: 'Brazil/DeNoronha' },
  { label: 'Brazil/East', value: 'Brazil/East' },
  { label: 'Brazil/West', value: 'Brazil/West' },
  { label: 'Canada/Atlantic', value: 'Canada/Atlantic' },
  { label: 'Canada/Central', value: 'Canada/Central' },
  { label: 'Canada/Eastern', value: 'Canada/Eastern' },
  { label: 'Canada/Mountain', value: 'Canada/Mountain' },
  { label: 'Canada/Newfoundland', value: 'Canada/Newfoundland' },
  { label: 'Canada/Pacific', value: 'Canada/Pacific' },
  { label: 'Canada/Saskatchewan', value: 'Canada/Saskatchewan' },
  { label: 'Canada/Yukon', value: 'Canada/Yukon' },
  { label: 'CET', value: 'CET' },
  { label: 'Chile/Continental', value: 'Chile/Continental' },
  { label: 'Chile/EasterIsland', value: 'Chile/EasterIsland' },
  { label: 'CST6CDT', value: 'CST6CDT' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'EET', value: 'EET' },
  { label: 'Egypt', value: 'Egypt' },
  { label: 'Eire', value: 'Eire' },
  { label: 'EST', value: 'EST' },
  { label: 'EST5EDT', value: 'EST5EDT' },
  { label: 'Etc/GMT', value: 'Etc/GMT' },
  { label: 'Etc/GMT+0', value: 'Etc/GMT+0' },
  { label: 'Etc/GMT+1', value: 'Etc/GMT+1' },
  { label: 'Etc/GMT+10', value: 'Etc/GMT+10' },
  { label: 'Etc/GMT+11', value: 'Etc/GMT+11' },
  { label: 'Etc/GMT+12', value: 'Etc/GMT+12' },
  { label: 'Etc/GMT+2', value: 'Etc/GMT+2' },
  { label: 'Etc/GMT+3', value: 'Etc/GMT+3' },
  { label: 'Etc/GMT+4', value: 'Etc/GMT+4' },
  { label: 'Etc/GMT+5', value: 'Etc/GMT+5' },
  { label: 'Etc/GMT+6', value: 'Etc/GMT+6' },
  { label: 'Etc/GMT+7', value: 'Etc/GMT+7' },
  { label: 'Etc/GMT+8', value: 'Etc/GMT+8' },
  { label: 'Etc/GMT+9', value: 'Etc/GMT+9' },
  { label: 'Etc/GMT-0', value: 'Etc/GMT-0' },
  { label: 'Etc/GMT-1', value: 'Etc/GMT-1' },
  { label: 'Etc/GMT-10', value: 'Etc/GMT-10' },
  { label: 'Etc/GMT-11', value: 'Etc/GMT-11' },
  { label: 'Etc/GMT-12', value: 'Etc/GMT-12' },
  { label: 'Etc/GMT-13', value: 'Etc/GMT-13' },
  { label: 'Etc/GMT-14', value: 'Etc/GMT-14' },
  { label: 'Etc/GMT-2', value: 'Etc/GMT-2' },
  { label: 'Etc/GMT-3', value: 'Etc/GMT-3' },
  { label: 'Etc/GMT-4', value: 'Etc/GMT-4' },
  { label: 'Etc/GMT-5', value: 'Etc/GMT-5' },
  { label: 'Etc/GMT-6', value: 'Etc/GMT-6' },
  { label: 'Etc/GMT-7', value: 'Etc/GMT-7' },
  { label: 'Etc/GMT-8', value: 'Etc/GMT-8' },
  { label: 'Etc/GMT-9', value: 'Etc/GMT-9' },
  { label: 'Etc/GMT0', value: 'Etc/GMT0' },
  { label: 'Etc/Greenwich', value: 'Etc/Greenwich' },
  { label: 'Etc/UCT', value: 'Etc/UCT' },
  { label: 'Etc/Universal', value: 'Etc/Universal' },
  { label: 'Etc/UTC', value: 'Etc/UTC' },
  { label: 'Etc/Zulu', value: 'Etc/Zulu' },
  { label: 'Europe/Amsterdam', value: 'Europe/Amsterdam' },
  { label: 'Europe/Andorra', value: 'Europe/Andorra' },
  { label: 'Europe/Astrakhan', value: 'Europe/Astrakhan' },
  { label: 'Europe/Athens', value: 'Europe/Athens' },
  { label: 'Europe/Belfast', value: 'Europe/Belfast' },
  { label: 'Europe/Belgrade', value: 'Europe/Belgrade' },
  { label: 'Europe/Berlin', value: 'Europe/Berlin' },
  { label: 'Europe/Bratislava', value: 'Europe/Bratislava' },
  { label: 'Europe/Brussels', value: 'Europe/Brussels' },
  { label: 'Europe/Bucharest', value: 'Europe/Bucharest' },
  { label: 'Europe/Budapest', value: 'Europe/Budapest' },
  { label: 'Europe/Busingen', value: 'Europe/Busingen' },
  { label: 'Europe/Chisinau', value: 'Europe/Chisinau' },
  { label: 'Europe/Copenhagen', value: 'Europe/Copenhagen' },
  { label: 'Europe/Dublin', value: 'Europe/Dublin' },
  { label: 'Europe/Gibraltar', value: 'Europe/Gibraltar' },
  { label: 'Europe/Guernsey', value: 'Europe/Guernsey' },
  { label: 'Europe/Helsinki', value: 'Europe/Helsinki' },
  { label: 'Europe/Isle_of_Man', value: 'Europe/Isle_of_Man' },
  { label: 'Europe/Istanbul', value: 'Europe/Istanbul' },
  { label: 'Europe/Jersey', value: 'Europe/Jersey' },
  { label: 'Europe/Kaliningrad', value: 'Europe/Kaliningrad' },
  { label: 'Europe/Kiev', value: 'Europe/Kiev' },
  { label: 'Europe/Kirov', value: 'Europe/Kirov' },
  { label: 'Europe/Lisbon', value: 'Europe/Lisbon' },
  { label: 'Europe/Ljubljana', value: 'Europe/Ljubljana' },
  { label: 'Europe/London', value: 'Europe/London' },
  { label: 'Europe/Luxembourg', value: 'Europe/Luxembourg' },
  { label: 'Europe/Madrid', value: 'Europe/Madrid' },
  { label: 'Europe/Malta', value: 'Europe/Malta' },
  { label: 'Europe/Mariehamn', value: 'Europe/Mariehamn' },
  { label: 'Europe/Minsk', value: 'Europe/Minsk' },
  { label: 'Europe/Monaco', value: 'Europe/Monaco' },
  { label: 'Europe/Moscow', value: 'Europe/Moscow' },
  { label: 'Europe/Nicosia', value: 'Europe/Nicosia' },
  { label: 'Europe/Oslo', value: 'Europe/Oslo' },
  { label: 'Europe/Paris', value: 'Europe/Paris' },
  { label: 'Europe/Podgorica', value: 'Europe/Podgorica' },
  { label: 'Europe/Prague', value: 'Europe/Prague' },
  { label: 'Europe/Riga', value: 'Europe/Riga' },
  { label: 'Europe/Rome', value: 'Europe/Rome' },
  { label: 'Europe/Samara', value: 'Europe/Samara' },
  { label: 'Europe/San_Marino', value: 'Europe/San_Marino' },
  { label: 'Europe/Sarajevo', value: 'Europe/Sarajevo' },
  { label: 'Europe/Saratov', value: 'Europe/Saratov' },
  { label: 'Europe/Simferopol', value: 'Europe/Simferopol' },
  { label: 'Europe/Skopje', value: 'Europe/Skopje' },
  { label: 'Europe/Sofia', value: 'Europe/Sofia' },
  { label: 'Europe/Stockholm', value: 'Europe/Stockholm' },
  { label: 'Europe/Tallinn', value: 'Europe/Tallinn' },
  { label: 'Europe/Tirane', value: 'Europe/Tirane' },
  { label: 'Europe/Tiraspol', value: 'Europe/Tiraspol' },
  { label: 'Europe/Ulyanovsk', value: 'Europe/Ulyanovsk' },
  { label: 'Europe/Uzhgorod', value: 'Europe/Uzhgorod' },
  { label: 'Europe/Vaduz', value: 'Europe/Vaduz' },
  { label: 'Europe/Vatican', value: 'Europe/Vatican' },
  { label: 'Europe/Vienna', value: 'Europe/Vienna' },
  { label: 'Europe/Vilnius', value: 'Europe/Vilnius' },
  { label: 'Europe/Volgograd', value: 'Europe/Volgograd' },
  { label: 'Europe/Warsaw', value: 'Europe/Warsaw' },
  { label: 'Europe/Zagreb', value: 'Europe/Zagreb' },
  { label: 'Europe/Zaporozhye', value: 'Europe/Zaporozhye' },
  { label: 'Europe/Zurich', value: 'Europe/Zurich' },
  { label: 'Factory', value: 'Factory' },
  { label: 'GB', value: 'GB' },
  { label: 'GB-Eire', value: 'GB-Eire' },
  { label: 'GMT', value: 'GMT' },
  { label: 'GMT+0', value: 'GMT+0' },
  { label: 'GMT-0', value: 'GMT-0' },
  { label: 'GMT0', value: 'GMT0' },
  { label: 'Greenwich', value: 'Greenwich' },
  { label: 'Hongkong', value: 'Hongkong' },
  { label: 'HST', value: 'HST' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'Indian/Antananarivo', value: 'Indian/Antananarivo' },
  { label: 'Indian/Chagos', value: 'Indian/Chagos' },
  { label: 'Indian/Christmas', value: 'Indian/Christmas' },
  { label: 'Indian/Cocos', value: 'Indian/Cocos' },
  { label: 'Indian/Comoro', value: 'Indian/Comoro' },
  { label: 'Indian/Kerguelen', value: 'Indian/Kerguelen' },
  { label: 'Indian/Mahe', value: 'Indian/Mahe' },
  { label: 'Indian/Maldives', value: 'Indian/Maldives' },
  { label: 'Indian/Mauritius', value: 'Indian/Mauritius' },
  { label: 'Indian/Mayotte', value: 'Indian/Mayotte' },
  { label: 'Indian/Reunion', value: 'Indian/Reunion' },
  { label: 'Iran', value: 'Iran' },
  { label: 'Israel', value: 'Israel' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Japan', value: 'Japan' },
  { label: 'Kwajalein', value: 'Kwajalein' },
  { label: 'Libya', value: 'Libya' },
  { label: 'MET', value: 'MET' },
  { label: 'Mexico/BajaNorte', value: 'Mexico/BajaNorte' },
  { label: 'Mexico/BajaSur', value: 'Mexico/BajaSur' },
  { label: 'Mexico/General', value: 'Mexico/General' },
  { label: 'MST', value: 'MST' },
  { label: 'MST7MDT', value: 'MST7MDT' },
  { label: 'Navajo', value: 'Navajo' },
  { label: 'NZ', value: 'NZ' },
  { label: 'NZ-CHAT', value: 'NZ-CHAT' },
  { label: 'Pacific/Apia', value: 'Pacific/Apia' },
  { label: 'Pacific/Auckland', value: 'Pacific/Auckland' },
  { label: 'Pacific/Bougainville', value: 'Pacific/Bougainville' },
  { label: 'Pacific/Chatham', value: 'Pacific/Chatham' },
  { label: 'Pacific/Chuuk', value: 'Pacific/Chuuk' },
  { label: 'Pacific/Easter', value: 'Pacific/Easter' },
  { label: 'Pacific/Efate', value: 'Pacific/Efate' },
  { label: 'Pacific/Enderbury', value: 'Pacific/Enderbury' },
  { label: 'Pacific/Fakaofo', value: 'Pacific/Fakaofo' },
  { label: 'Pacific/Fiji', value: 'Pacific/Fiji' },
  { label: 'Pacific/Funafuti', value: 'Pacific/Funafuti' },
  { label: 'Pacific/Galapagos', value: 'Pacific/Galapagos' },
  { label: 'Pacific/Gambier', value: 'Pacific/Gambier' },
  { label: 'Pacific/Guadalcanal', value: 'Pacific/Guadalcanal' },
  { label: 'Pacific/Guam', value: 'Pacific/Guam' },
  { label: 'Pacific/Honolulu', value: 'Pacific/Honolulu' },
  { label: 'Pacific/Johnston', value: 'Pacific/Johnston' },
  { label: 'Pacific/Kanton', value: 'Pacific/Kanton' },
  { label: 'Pacific/Kiritimati', value: 'Pacific/Kiritimati' },
  { label: 'Pacific/Kosrae', value: 'Pacific/Kosrae' },
  { label: 'Pacific/Kwajalein', value: 'Pacific/Kwajalein' },
  { label: 'Pacific/Majuro', value: 'Pacific/Majuro' },
  { label: 'Pacific/Marquesas', value: 'Pacific/Marquesas' },
  { label: 'Pacific/Midway', value: 'Pacific/Midway' },
  { label: 'Pacific/Nauru', value: 'Pacific/Nauru' },
  { label: 'Pacific/Niue', value: 'Pacific/Niue' },
  { label: 'Pacific/Norfolk', value: 'Pacific/Norfolk' },
  { label: 'Pacific/Noumea', value: 'Pacific/Noumea' },
  { label: 'Pacific/Pago_Pago', value: 'Pacific/Pago_Pago' },
  { label: 'Pacific/Palau', value: 'Pacific/Palau' },
  { label: 'Pacific/Pitcairn', value: 'Pacific/Pitcairn' },
  { label: 'Pacific/Pohnpei', value: 'Pacific/Pohnpei' },
  { label: 'Pacific/Ponape', value: 'Pacific/Ponape' },
  { label: 'Pacific/Port_Moresby', value: 'Pacific/Port_Moresby' },
  { label: 'Pacific/Rarotonga', value: 'Pacific/Rarotonga' },
  { label: 'Pacific/Saipan', value: 'Pacific/Saipan' },
  { label: 'Pacific/Samoa', value: 'Pacific/Samoa' },
  { label: 'Pacific/Tahiti', value: 'Pacific/Tahiti' },
  { label: 'Pacific/Tarawa', value: 'Pacific/Tarawa' },
  { label: 'Pacific/Tongatapu', value: 'Pacific/Tongatapu' },
  { label: 'Pacific/Truk', value: 'Pacific/Truk' },
  { label: 'Pacific/Wake', value: 'Pacific/Wake' },
  { label: 'Pacific/Wallis', value: 'Pacific/Wallis' },
  { label: 'Pacific/Yap', value: 'Pacific/Yap' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'PRC', value: 'PRC' },
  { label: 'PST8PDT', value: 'PST8PDT' },
  { label: 'ROC', value: 'ROC' },
  { label: 'ROK', value: 'ROK' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'UCT', value: 'UCT' },
  { label: 'Universal', value: 'Universal' },
  { label: 'US/Alaska', value: 'US/Alaska' },
  { label: 'US/Aleutian', value: 'US/Aleutian' },
  { label: 'US/Arizona', value: 'US/Arizona' },
  { label: 'US/Central', value: 'US/Central' },
  { label: 'US/East-Indiana', value: 'US/East-Indiana' },
  { label: 'US/Eastern', value: 'US/Eastern' },
  { label: 'US/Hawaii', value: 'US/Hawaii' },
  { label: 'US/Indiana-Starke', value: 'US/Indiana-Starke' },
  { label: 'US/Michigan', value: 'US/Michigan' },
  { label: 'US/Mountain', value: 'US/Mountain' },
  { label: 'US/Pacific', value: 'US/Pacific' },
  { label: 'US/Samoa', value: 'US/Samoa' },
  { label: 'UTC', value: 'UTC' },
  { label: 'W-SU', value: 'W-SU' },
  { label: 'WET', value: 'WET' },
  { label: 'Zulu', value: 'Zulu' },
];

export const LOCALE_OPTIONS = [
  { value: 'af_NA', label: 'Afrikaans (Namibia)' },
  { value: 'af_ZA', label: 'Afrikaans (South Africa)' },
  { value: 'af', label: 'Afrikaans' },
  { value: 'ak_GH', label: 'Akan (Ghana)' },
  { value: 'ak', label: 'Akan' },
  { value: 'sq_AL', label: 'Albanian (Albania)' },
  { value: 'sq', label: 'Albanian' },
  { value: 'am_ET', label: 'Amharic (Ethiopia)' },
  { value: 'am', label: 'Amharic' },
  { value: 'ar_DZ', label: 'Arabic (Algeria)' },
  { value: 'ar_BH', label: 'Arabic (Bahrain)' },
  { value: 'ar_EG', label: 'Arabic (Egypt)' },
  { value: 'ar_IQ', label: 'Arabic (Iraq)' },
  { value: 'ar_JO', label: 'Arabic (Jordan)' },
  { value: 'ar_KW', label: 'Arabic (Kuwait)' },
  { value: 'ar_LB', label: 'Arabic (Lebanon)' },
  { value: 'ar_LY', label: 'Arabic (Libya)' },
  { value: 'ar_MA', label: 'Arabic (Morocco)' },
  { value: 'ar_OM', label: 'Arabic (Oman)' },
  { value: 'ar_QA', label: 'Arabic (Qatar)' },
  { value: 'ar_SA', label: 'Arabic (Saudi Arabia)' },
  { value: 'ar_SD', label: 'Arabic (Sudan)' },
  { value: 'ar_SY', label: 'Arabic (Syria)' },
  { value: 'ar_TN', label: 'Arabic (Tunisia)' },
  { value: 'ar_AE', label: 'Arabic (United Arab Emirates)' },
  { value: 'ar_YE', label: 'Arabic (Yemen)' },
  { value: 'ar', label: 'Arabic' },
  { value: 'hy_AM', label: 'Armenian (Armenia)' },
  { value: 'hy', label: 'Armenian' },
  { value: 'as_IN', label: 'Assamese (India)' },
  { value: 'as', label: 'Assamese' },
  { value: 'asa_TZ', label: 'Asu (Tanzania)' },
  { value: 'asa', label: 'Asu' },
  { value: 'az_Cyrl', label: 'Azerbaijani (Cyrillic)' },
  { value: 'az_Cyrl_AZ', label: 'Azerbaijani (Cyrillic, Azerbaijan)' },
  { value: 'az_Latn', label: 'Azerbaijani (Latin)' },
  { value: 'az_Latn_AZ', label: 'Azerbaijani (Latin, Azerbaijan)' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'bm_ML', label: 'Bambara (Mali)' },
  { value: 'bm', label: 'Bambara' },
  { value: 'eu_ES', label: 'Basque (Spain)' },
  { value: 'eu', label: 'Basque' },
  { value: 'be_BY', label: 'Belarusian (Belarus)' },
  { value: 'be', label: 'Belarusian' },
  { value: 'bem_ZM', label: 'Bemba (Zambia)' },
  { value: 'bem', label: 'Bemba' },
  { value: 'bez_TZ', label: 'Bena (Tanzania)' },
  { value: 'bez', label: 'Bena' },
  { value: 'bn_BD', label: 'Bengali (Bangladesh)' },
  { value: 'bn_IN', label: 'Bengali (India)' },
  { value: 'bn', label: 'Bengali' },
  { value: 'bs_BA', label: 'Bosnian (Bosnia and Herzegovina)' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'bg_BG', label: 'Bulgarian (Bulgaria)' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'my_MM', label: 'Burmese (Myanmar [Burma])' },
  { value: 'my', label: 'Burmese' },
  { value: 'yue_Hant_HK', label: 'Cantonese (Traditional, Hong Kong SAR China)' },
  { value: 'ca_ES', label: 'Catalan (Spain)' },
  { value: 'ca', label: 'Catalan' },
  { value: 'tzm_Latn', label: 'Central Morocco Tamazight (Latin)' },
  { value: 'tzm_Latn_MA', label: 'Central Morocco Tamazight (Latin, Morocco)' },
  { value: 'tzm', label: 'Central Morocco Tamazight' },
  { value: 'chr_US', label: 'Cherokee (United States)' },
  { value: 'chr', label: 'Cherokee' },
  { value: 'cgg_UG', label: 'Chiga (Uganda)' },
  { value: 'cgg', label: 'Chiga' },
  { value: 'zh_Hans', label: 'Chinese (Simplified Han)' },
  { value: 'zh_Hans_CN', label: 'Chinese (Simplified Han, China)' },
  { value: 'zh_Hans_HK', label: 'Chinese (Simplified Han, Hong Kong SAR China)' },
  { value: 'zh_Hans_MO', label: 'Chinese (Simplified Han, Macau SAR China)' },
  { value: 'zh_Hans_SG', label: 'Chinese (Simplified Han, Singapore)' },
  { value: 'zh_Hant', label: 'Chinese (Traditional Han)' },
  { value: 'zh_Hant_HK', label: 'Chinese (Traditional Han, Hong Kong SAR China)' },
  { value: 'zh_Hant_MO', label: 'Chinese (Traditional Han, Macau SAR China)' },
  { value: 'zh_Hant_TW', label: 'Chinese (Traditional Han, Taiwan)' },
  { value: 'zh', label: 'Chinese' },
  { value: 'kw_GB', label: 'Cornish (United Kingdom)' },
  { value: 'kw', label: 'Cornish' },
  { value: 'hr_HR', label: 'Croatian (Croatia)' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs_CZ', label: 'Czech (Czech Republic)' },
  { value: 'cs', label: 'Czech' },
  { value: 'da_DK', label: 'Danish (Denmark)' },
  { value: 'da', label: 'Danish' },
  { value: 'nl_BE', label: 'Dutch (Belgium)' },
  { value: 'nl_NL', label: 'Dutch (Netherlands)' },
  { value: 'nl', label: 'Dutch' },
  { value: 'ebu_KE', label: 'Embu (Kenya)' },
  { value: 'ebu', label: 'Embu' },
  { value: 'en_AS', label: 'English (American Samoa)' },
  { value: 'en_AU', label: 'English (Australia)' },
  { value: 'en_BE', label: 'English (Belgium)' },
  { value: 'en_BZ', label: 'English (Belize)' },
  { value: 'en_BW', label: 'English (Botswana)' },
  { value: 'en_CA', label: 'English (Canada)' },
  { value: 'en_GU', label: 'English (Guam)' },
  { value: 'en_HK', label: 'English (Hong Kong SAR China)' },
  { value: 'en_IN', label: 'English (India)' },
  { value: 'en_IE', label: 'English (Ireland)' },
  { value: 'en_IL', label: 'English (Israel)' },
  { value: 'en_JM', label: 'English (Jamaica)' },
  { value: 'en_MT', label: 'English (Malta)' },
  { value: 'en_MH', label: 'English (Marshall Islands)' },
  { value: 'en_MU', label: 'English (Mauritius)' },
  { value: 'en_NA', label: 'English (Namibia)' },
  { value: 'en_NZ', label: 'English (New Zealand)' },
  { value: 'en_MP', label: 'English (Northern Mariana Islands)' },
  { value: 'en_PK', label: 'English (Pakistan)' },
  { value: 'en_PH', label: 'English (Philippines)' },
  { value: 'en_SG', label: 'English (Singapore)' },
  { value: 'en_ZA', label: 'English (South Africa)' },
  { value: 'en_TT', label: 'English (Trinidad and Tobago)' },
  { value: 'en_UM', label: 'English (U.S. Minor Outlying Islands)' },
  { value: 'en_VI', label: 'English (U.S. Virgin Islands)' },
  { value: 'en_GB', label: 'English (United Kingdom)' },
  { value: 'en_US', label: 'English (United States)' },
  { value: 'en_ZW', label: 'English (Zimbabwe)' },
  { value: 'en', label: 'English' },
  { value: 'eo', label: 'Esperanto' },
  { value: 'et_EE', label: 'Estonian (Estonia)' },
  { value: 'et', label: 'Estonian' },
  { value: 'ee_GH', label: 'Ewe (Ghana)' },
  { value: 'ee_TG', label: 'Ewe (Togo)' },
  { value: 'ee', label: 'Ewe' },
  { value: 'fo_FO', label: 'Faroese (Faroe Islands)' },
  { value: 'fo', label: 'Faroese' },
  { value: 'fil_PH', label: 'Filipino (Philippines)' },
  { value: 'fil', label: 'Filipino' },
  { value: 'fi_FI', label: 'Finnish (Finland)' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr_BE', label: 'French (Belgium)' },
  { value: 'fr_BJ', label: 'French (Benin)' },
  { value: 'fr_BF', label: 'French (Burkina Faso)' },
  { value: 'fr_BI', label: 'French (Burundi)' },
  { value: 'fr_CM', label: 'French (Cameroon)' },
  { value: 'fr_CA', label: 'French (Canada)' },
  { value: 'fr_CF', label: 'French (Central African Republic)' },
  { value: 'fr_TD', label: 'French (Chad)' },
  { value: 'fr_KM', label: 'French (Comoros)' },
  { value: 'fr_CG', label: 'French (Congo - Brazzaville)' },
  { value: 'fr_CD', label: 'French (Congo - Kinshasa)' },
  { value: 'fr_CI', label: 'French (Côte d’Ivoire)' },
  { value: 'fr_DJ', label: 'French (Djibouti)' },
  { value: 'fr_GQ', label: 'French (Equatorial Guinea)' },
  { value: 'fr_FR', label: 'French (France)' },
  { value: 'fr_GA', label: 'French (Gabon)' },
  { value: 'fr_GP', label: 'French (Guadeloupe)' },
  { value: 'fr_GN', label: 'French (Guinea)' },
  { value: 'fr_LU', label: 'French (Luxembourg)' },
  { value: 'fr_MG', label: 'French (Madagascar)' },
  { value: 'fr_ML', label: 'French (Mali)' },
  { value: 'fr_MQ', label: 'French (Martinique)' },
  { value: 'fr_MC', label: 'French (Monaco)' },
  { value: 'fr_NE', label: 'French (Niger)' },
  { value: 'fr_RW', label: 'French (Rwanda)' },
  { value: 'fr_RE', label: 'French (Réunion)' },
  { value: 'fr_BL', label: 'French (Saint Barthélemy)' },
  { value: 'fr_MF', label: 'French (Saint Martin)' },
  { value: 'fr_SN', label: 'French (Senegal)' },
  { value: 'fr_CH', label: 'French (Switzerland)' },
  { value: 'fr_TG', label: 'French (Togo)' },
  { value: 'fr', label: 'French' },
  { value: 'ff_SN', label: 'Fulah (Senegal)' },
  { value: 'ff', label: 'Fulah' },
  { value: 'gl_ES', label: 'Galician (Spain)' },
  { value: 'gl', label: 'Galician' },
  { value: 'lg_UG', label: 'Ganda (Uganda)' },
  { value: 'lg', label: 'Ganda' },
  { value: 'ka_GE', label: 'Georgian (Georgia)' },
  { value: 'ka', label: 'Georgian' },
  { value: 'de_AT', label: 'German (Austria)' },
  { value: 'de_BE', label: 'German (Belgium)' },
  { value: 'de_DE', label: 'German (Germany)' },
  { value: 'de_LI', label: 'German (Liechtenstein)' },
  { value: 'de_LU', label: 'German (Luxembourg)' },
  { value: 'de_CH', label: 'German (Switzerland)' },
  { value: 'de', label: 'German' },
  { value: 'el_CY', label: 'Greek (Cyprus)' },
  { value: 'el_GR', label: 'Greek (Greece)' },
  { value: 'el', label: 'Greek' },
  { value: 'gu_IN', label: 'Gujarati (India)' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'guz_KE', label: 'Gusii (Kenya)' },
  { value: 'guz', label: 'Gusii' },
  { value: 'ha_Latn', label: 'Hausa (Latin)' },
  { value: 'ha_Latn_GH', label: 'Hausa (Latin, Ghana)' },
  { value: 'ha_Latn_NE', label: 'Hausa (Latin, Niger)' },
  { value: 'ha_Latn_NG', label: 'Hausa (Latin, Nigeria)' },
  { value: 'ha', label: 'Hausa' },
  { value: 'haw_US', label: 'Hawaiian (United States)' },
  { value: 'haw', label: 'Hawaiian' },
  { value: 'he_IL', label: 'Hebrew (Israel)' },
  { value: 'he', label: 'Hebrew' },
  { value: 'hi_IN', label: 'Hindi (India)' },
  { value: 'hi', label: 'Hindi' },
  { value: 'hu_HU', label: 'Hungarian (Hungary)' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'is_IS', label: 'Icelandic (Iceland)' },
  { value: 'is', label: 'Icelandic' },
  { value: 'ig_NG', label: 'Igbo (Nigeria)' },
  { value: 'ig', label: 'Igbo' },
  { value: 'id_ID', label: 'Indonesian (Indonesia)' },
  { value: 'id', label: 'Indonesian' },
  { value: 'ga_IE', label: 'Irish (Ireland)' },
  { value: 'ga', label: 'Irish' },
  { value: 'it_IT', label: 'Italian (Italy)' },
  { value: 'it_CH', label: 'Italian (Switzerland)' },
  { value: 'it', label: 'Italian' },
  { value: 'ja_JP', label: 'Japanese (Japan)' },
  { value: 'ja', label: 'Japanese' },
  { value: 'kea_CV', label: 'Kabuverdianu (Cape Verde)' },
  { value: 'kea', label: 'Kabuverdianu' },
  { value: 'kab_DZ', label: 'Kabyle (Algeria)' },
  { value: 'kab', label: 'Kabyle' },
  { value: 'kl_GL', label: 'Kalaallisut (Greenland)' },
  { value: 'kl', label: 'Kalaallisut' },
  { value: 'kln_KE', label: 'Kalenjin (Kenya)' },
  { value: 'kln', label: 'Kalenjin' },
  { value: 'kam_KE', label: 'Kamba (Kenya)' },
  { value: 'kam', label: 'Kamba' },
  { value: 'kn_IN', label: 'Kannada (India)' },
  { value: 'kn', label: 'Kannada' },
  { value: 'kk_Cyrl', label: 'Kazakh (Cyrillic)' },
  { value: 'kk_Cyrl_KZ', label: 'Kazakh (Cyrillic, Kazakhstan)' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'km_KH', label: 'Khmer (Cambodia)' },
  { value: 'km', label: 'Khmer' },
  { value: 'ki_KE', label: 'Kikuyu (Kenya)' },
  { value: 'ki', label: 'Kikuyu' },
  { value: 'rw_RW', label: 'Kinyarwanda (Rwanda)' },
  { value: 'rw', label: 'Kinyarwanda' },
  { value: 'kok_IN', label: 'Konkani (India)' },
  { value: 'kok', label: 'Konkani' },
  { value: 'ko_KR', label: 'Korean (South Korea)' },
  { value: 'ko', label: 'Korean' },
  { value: 'khq_ML', label: 'Koyra Chiini (Mali)' },
  { value: 'khq', label: 'Koyra Chiini' },
  { value: 'ses_ML', label: 'Koyraboro Senni (Mali)' },
  { value: 'ses', label: 'Koyraboro Senni' },
  { value: 'lag_TZ', label: 'Langi (Tanzania)' },
  { value: 'lag', label: 'Langi' },
  { value: 'lv_LV', label: 'Latvian (Latvia)' },
  { value: 'lv', label: 'Latvian' },
  { value: 'lt_LT', label: 'Lithuanian (Lithuania)' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'luo_KE', label: 'Luo (Kenya)' },
  { value: 'luo', label: 'Luo' },
  { value: 'luy_KE', label: 'Luyia (Kenya)' },
  { value: 'luy', label: 'Luyia' },
  { value: 'mk_MK', label: 'Macedonian (Macedonia)' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'jmc_TZ', label: 'Machame (Tanzania)' },
  { value: 'jmc', label: 'Machame' },
  { value: 'kde_TZ', label: 'Makonde (Tanzania)' },
  { value: 'kde', label: 'Makonde' },
  { value: 'mg_MG', label: 'Malagasy (Madagascar)' },
  { value: 'mg', label: 'Malagasy' },
  { value: 'ms_BN', label: 'Malay (Brunei)' },
  { value: 'ms_MY', label: 'Malay (Malaysia)' },
  { value: 'ms', label: 'Malay' },
  { value: 'ml_IN', label: 'Malayalam (India)' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mt_MT', label: 'Maltese (Malta)' },
  { value: 'mt', label: 'Maltese' },
  { value: 'gv_GB', label: 'Manx (United Kingdom)' },
  { value: 'gv', label: 'Manx' },
  { value: 'mr_IN', label: 'Marathi (India)' },
  { value: 'mr', label: 'Marathi' },
  { value: 'mas_KE', label: 'Masai (Kenya)' },
  { value: 'mas_TZ', label: 'Masai (Tanzania)' },
  { value: 'mas', label: 'Masai' },
  { value: 'mer_KE', label: 'Meru (Kenya)' },
  { value: 'mer', label: 'Meru' },
  { value: 'mfe_MU', label: 'Morisyen (Mauritius)' },
  { value: 'mfe', label: 'Morisyen' },
  { value: 'naq_NA', label: 'Nama (Namibia)' },
  { value: 'naq', label: 'Nama' },
  { value: 'ne_IN', label: 'Nepali (India)' },
  { value: 'ne_NP', label: 'Nepali (Nepal)' },
  { value: 'ne', label: 'Nepali' },
  { value: 'nd_ZW', label: 'North Ndebele (Zimbabwe)' },
  { value: 'nd', label: 'North Ndebele' },
  { value: 'nb_NO', label: 'Norwegian Bokmål (Norway)' },
  { value: 'nb', label: 'Norwegian Bokmål' },
  { value: 'nn_NO', label: 'Norwegian Nynorsk (Norway)' },
  { value: 'nn', label: 'Norwegian Nynorsk' },
  { value: 'nyn_UG', label: 'Nyankole (Uganda)' },
  { value: 'nyn', label: 'Nyankole' },
  { value: 'or_IN', label: 'Oriya (India)' },
  { value: 'or', label: 'Oriya' },
  { value: 'om_ET', label: 'Oromo (Ethiopia)' },
  { value: 'om_KE', label: 'Oromo (Kenya)' },
  { value: 'om', label: 'Oromo' },
  { value: 'ps_AF', label: 'Pashto (Afghanistan)' },
  { value: 'ps', label: 'Pashto' },
  { value: 'fa_AF', label: 'Persian (Afghanistan)' },
  { value: 'fa_IR', label: 'Persian (Iran)' },
  { value: 'fa', label: 'Persian' },
  { value: 'pl_PL', label: 'Polish (Poland)' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt_BR', label: 'Portuguese (Brazil)' },
  { value: 'pt_GW', label: 'Portuguese (Guinea-Bissau)' },
  { value: 'pt_MZ', label: 'Portuguese (Mozambique)' },
  { value: 'pt_PT', label: 'Portuguese (Portugal)' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'pa_Arab', label: 'Punjabi (Arabic)' },
  { value: 'pa_Arab_PK', label: 'Punjabi (Arabic, Pakistan)' },
  { value: 'pa_Guru', label: 'Punjabi (Gurmukhi)' },
  { value: 'pa_Guru_IN', label: 'Punjabi (Gurmukhi, India)' },
  { value: 'pa', label: 'Punjabi' },
  { value: 'ro_MD', label: 'Romanian (Moldova)' },
  { value: 'ro_RO', label: 'Romanian (Romania)' },
  { value: 'ro', label: 'Romanian' },
  { value: 'rm_CH', label: 'Romansh (Switzerland)' },
  { value: 'rm', label: 'Romansh' },
  { value: 'rof_TZ', label: 'Rombo (Tanzania)' },
  { value: 'rof', label: 'Rombo' },
  { value: 'ru_MD', label: 'Russian (Moldova)' },
  { value: 'ru_RU', label: 'Russian (Russia)' },
  { value: 'ru_UA', label: 'Russian (Ukraine)' },
  { value: 'ru', label: 'Russian' },
  { value: 'rwk_TZ', label: 'Rwa (Tanzania)' },
  { value: 'rwk', label: 'Rwa' },
  { value: 'saq_KE', label: 'Samburu (Kenya)' },
  { value: 'saq', label: 'Samburu' },
  { value: 'sg_CF', label: 'Sango (Central African Republic)' },
  { value: 'sg', label: 'Sango' },
  { value: 'seh_MZ', label: 'Sena (Mozambique)' },
  { value: 'seh', label: 'Sena' },
  { value: 'sr_Cyrl', label: 'Serbian (Cyrillic)' },
  { value: 'sr_Cyrl_BA', label: 'Serbian (Cyrillic, Bosnia and Herzegovina)' },
  { value: 'sr_Cyrl_ME', label: 'Serbian (Cyrillic, Montenegro)' },
  { value: 'sr_Cyrl_RS', label: 'Serbian (Cyrillic, Serbia)' },
  { value: 'sr_Latn', label: 'Serbian (Latin)' },
  { value: 'sr_Latn_BA', label: 'Serbian (Latin, Bosnia and Herzegovina)' },
  { value: 'sr_Latn_ME', label: 'Serbian (Latin, Montenegro)' },
  { value: 'sr_Latn_RS', label: 'Serbian (Latin, Serbia)' },
  { value: 'sr', label: 'Serbian' },
  { value: 'sn_ZW', label: 'Shona (Zimbabwe)' },
  { value: 'sn', label: 'Shona' },
  { value: 'ii_CN', label: 'Sichuan Yi (China)' },
  { value: 'ii', label: 'Sichuan Yi' },
  { value: 'si_LK', label: 'Sinhala (Sri Lanka)' },
  { value: 'si', label: 'Sinhala' },
  { value: 'sk_SK', label: 'Slovak (Slovakia)' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl_SI', label: 'Slovenian (Slovenia)' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'xog_UG', label: 'Soga (Uganda)' },
  { value: 'xog', label: 'Soga' },
  { value: 'so_DJ', label: 'Somali (Djibouti)' },
  { value: 'so_ET', label: 'Somali (Ethiopia)' },
  { value: 'so_KE', label: 'Somali (Kenya)' },
  { value: 'so_SO', label: 'Somali (Somalia)' },
  { value: 'so', label: 'Somali' },
  { value: 'es_AR', label: 'Spanish (Argentina)' },
  { value: 'es_BO', label: 'Spanish (Bolivia)' },
  { value: 'es_CL', label: 'Spanish (Chile)' },
  { value: 'es_CO', label: 'Spanish (Colombia)' },
  { value: 'es_CR', label: 'Spanish (Costa Rica)' },
  { value: 'es_DO', label: 'Spanish (Dominican Republic)' },
  { value: 'es_EC', label: 'Spanish (Ecuador)' },
  { value: 'es_SV', label: 'Spanish (El Salvador)' },
  { value: 'es_GQ', label: 'Spanish (Equatorial Guinea)' },
  { value: 'es_GT', label: 'Spanish (Guatemala)' },
  { value: 'es_HN', label: 'Spanish (Honduras)' },
  { value: 'es_419', label: 'Spanish (Latin America)' },
  { value: 'es_MX', label: 'Spanish (Mexico)' },
  { value: 'es_NI', label: 'Spanish (Nicaragua)' },
  { value: 'es_PA', label: 'Spanish (Panama)' },
  { value: 'es_PY', label: 'Spanish (Paraguay)' },
  { value: 'es_PE', label: 'Spanish (Peru)' },
  { value: 'es_PR', label: 'Spanish (Puerto Rico)' },
  { value: 'es_ES', label: 'Spanish (Spain)' },
  { value: 'es_US', label: 'Spanish (United States)' },
  { value: 'es_UY', label: 'Spanish (Uruguay)' },
  { value: 'es_VE', label: 'Spanish (Venezuela)' },
  { value: 'es', label: 'Spanish' },
  { value: 'sw_KE', label: 'Swahili (Kenya)' },
  { value: 'sw_TZ', label: 'Swahili (Tanzania)' },
  { value: 'sw', label: 'Swahili' },
  { value: 'sv_FI', label: 'Swedish (Finland)' },
  { value: 'sv_SE', label: 'Swedish (Sweden)' },
  { value: 'sv', label: 'Swedish' },
  { value: 'gsw_CH', label: 'Swiss German (Switzerland)' },
  { value: 'gsw', label: 'Swiss German' },
  { value: 'shi_Latn', label: 'Tachelhit (Latin)' },
  { value: 'shi_Latn_MA', label: 'Tachelhit (Latin, Morocco)' },
  { value: 'shi_Tfng', label: 'Tachelhit (Tifinagh)' },
  { value: 'shi_Tfng_MA', label: 'Tachelhit (Tifinagh, Morocco)' },
  { value: 'shi', label: 'Tachelhit' },
  { value: 'dav_KE', label: 'Taita (Kenya)' },
  { value: 'dav', label: 'Taita' },
  { value: 'ta_IN', label: 'Tamil (India)' },
  { value: 'ta_LK', label: 'Tamil (Sri Lanka)' },
  { value: 'ta', label: 'Tamil' },
  { value: 'te_IN', label: 'Telugu (India)' },
  { value: 'te', label: 'Telugu' },
  { value: 'teo_KE', label: 'Teso (Kenya)' },
  { value: 'teo_UG', label: 'Teso (Uganda)' },
  { value: 'teo', label: 'Teso' },
  { value: 'th_TH', label: 'Thai (Thailand)' },
  { value: 'th', label: 'Thai' },
  { value: 'bo_CN', label: 'Tibetan (China)' },
  { value: 'bo_IN', label: 'Tibetan (India)' },
  { value: 'bo', label: 'Tibetan' },
  { value: 'ti_ER', label: 'Tigrinya (Eritrea)' },
  { value: 'ti_ET', label: 'Tigrinya (Ethiopia)' },
  { value: 'ti', label: 'Tigrinya' },
  { value: 'to_TO', label: 'Tonga (Tonga)' },
  { value: 'to', label: 'Tonga' },
  { value: 'tr_TR', label: 'Turkish (Turkey)' },
  { value: 'tr', label: 'Turkish' },
  { value: 'uk_UA', label: 'Ukrainian (Ukraine)' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur_IN', label: 'Urdu (India)' },
  { value: 'ur_PK', label: 'Urdu (Pakistan)' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz_Arab', label: 'Uzbek (Arabic)' },
  { value: 'uz_Arab_AF', label: 'Uzbek (Arabic, Afghanistan)' },
  { value: 'uz_Cyrl', label: 'Uzbek (Cyrillic)' },
  { value: 'uz_Cyrl_UZ', label: 'Uzbek (Cyrillic, Uzbekistan)' },
  { value: 'uz_Latn', label: 'Uzbek (Latin)' },
  { value: 'uz_Latn_UZ', label: 'Uzbek (Latin, Uzbekistan)' },
  { value: 'uz', label: 'Uzbek' },
  { value: 'vi_VN', label: 'Vietnamese (Vietnam)' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'vun_TZ', label: 'Vunjo (Tanzania)' },
  { value: 'vun', label: 'Vunjo' },
  { value: 'cy_GB', label: 'Welsh (United Kingdom)' },
  { value: 'cy', label: 'Welsh' },
  { value: 'yo_NG', label: 'Yoruba (Nigeria)' },
  { value: 'yo', label: 'Yoruba' },
  { value: 'zu_ZA', label: 'Zulu (South Africa)' },
  { value: 'zu', label: 'Zulu' },
];
