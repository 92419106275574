import { MVTLayer } from '@components/common/Map';
import React, { FC, useMemo } from 'react';
import * as x from '@utils/map';
import { LayerSpecification } from 'maplibre-gl';
import { MVTLayerProps } from '@components/common/Map';

export type RouteLinesLayerProps = Omit<MVTLayerProps, 'type'>;

export const RouteLinesLayer: FC<RouteLinesLayerProps> = props => {
  const layout = useMemo(
    () =>
      ({
        'line-cap': 'round',
      } as LayerSpecification['layout']),
    []
  );

  const paint = useMemo(
    () => ({
      'line-width': x.width(),
      'line-offset': x.mul(x.ternary(x.ifProp('index'), x.prop('index'), 0), 1.5),
      'line-color': x.color(x.prop('alert_color')),
      'line-blur': 1.2,
    }),
    []
  );

  return <MVTLayer {...props} type="line" paint={paint} layout={layout} />;
};
