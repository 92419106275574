import React, { useCallback, useEffect, useRef, useState } from 'react';
import c from 'classnames';
import Icon from '@components/common/Icon';
import { NetworkStatusIndicator } from '@components/common/NetworkStatusIndicator';
import useTextSnippets from '@services/useTextSnippets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import {
  IssueStatusFilter,
  IssueWithDetails,
  selectTotalIssueCount,
  selectSummary,
  selectResolvedFilters,
  IssueState,
} from '@redux/issues';
import CopyText from '@components/typography/CopyText';
import useSocket, { Message } from '@infrastructure/push-api/useSocket';
import fillTemplate from '@utils/fillTemplate';
import { CypressTestsData, CypressTestType } from '@cypress';
import { FilterableToolbar, FilterableToolbarProps } from '@components/common/Table/components/FilterableToolbar';
import { clear as clearOnElementResize, bind as onElementResize } from 'size-sensor';
import { FilterableToolbarSearch } from '@components/common/Table/components/FilterableToolbarSearch';
import { selectToken } from '@infrastructure/redux/auth';

import styles from './IssuesToolbar.module.scss';

const MINIMAL_WIDTH = 800;
const STATUSES: IssueState[] = ['new', 'open', 'tracking', 'frozen', 'closing', 'closed'];

type IssueFilterButtonProps = {
  count: number | string;
  label: number | string;
  active?: boolean;
  showBadge?: boolean;
  status: IssueStatusFilter;
  onToggle: (status: IssueStatusFilter) => void;
  testid?: string;
};

const IssueFilterButton: React.FC<IssueFilterButtonProps> = ({
  count,
  label,
  active,
  onToggle,
  showBadge,
  status,
  testid,
}) => (
  <div
    className={c(styles.toolbarCell, styles.stackedCell, styles.filterCell, active && styles.activeFilterCell)}
    onClick={() => onToggle(active ? '' : status)}
  >
    <CopyText variant="copy-3" additionalClass="relative leading-none mb-4">
      <span data-testid={testid}>{count || '0'}</span>
      {showBadge && <span className={styles.filterBadge} />}
    </CopyText>
    <CopyText variant="copy-8" additionalClass="leading-none">
      {label}
    </CopyText>
  </div>
);

const SocketDisconnected: React.FC<{ onRetry: () => void; tryNumber: number }> = ({ onRetry, tryNumber }) => {
  const issuesI18n = useTextSnippets('issues');

  return (
    <>
      <div className={c(styles.toolbarCell, styles.stackedCell, styles.disconnectedCell)}>
        <CopyText variant="copy-5" additionalClass="relative">
          {issuesI18n.socketDisconnected}
        </CopyText>
        <CopyText variant="copy-6">{fillTemplate(issuesI18n.tryNumber, { tryNumber })}</CopyText>
      </div>
      <div className={c(styles.toolbarCell, styles.stackedCell, styles.refreshCell)} onClick={onRetry}>
        <Icon name="Refresh" additionalClass="block" />
        <CopyText variant="copy-6">{issuesI18n.retry}</CopyText>
      </div>
    </>
  );
};

export type IssuesToolbarProps = Omit<FilterableToolbarProps, 'onFiltersChange'> & {
  actions: Dictionary<any>;
  showExtendedToolbar?: boolean;
  onFiltersChange: (values: Dictionary<any>) => void;
};

export const IssuesToolbar: React.FC<IssuesToolbarProps> = ({
  actions,
  showExtendedToolbar = true,
  isLoading,
  showFiltersToggle,
  keyword,
  filters: filterConfig,
  onSearch,
  onFiltersChange,
  ...props
}) => {
  const { updateIssue, updatePagination, updateStatusFilter } = actions;

  const dispatch = useAppDispatch();
  const i18n = useTextSnippets('issues');
  const commonI18n = useTextSnippets('common');

  const totalIssueCount = useAppSelector(selectTotalIssueCount);
  const issuesSummary = useAppSelector(selectSummary);
  const filters = useAppSelector(selectResolvedFilters);

  const issueManagerTestDataConfig = CypressTestsData[CypressTestType.ISSUE_MANAGER];

  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const [inMinimalMode, setIsMinimalMode] = useState(false);

  useEffect(() => {
    setIsMinimalMode((toolbarRef.current?.getBoundingClientRect().width ?? 0) < MINIMAL_WIDTH);

    onElementResize(toolbarRef.current, () => {
      setIsMinimalMode((toolbarRef.current?.getBoundingClientRect().width ?? 0) < MINIMAL_WIDTH);
    });

    return () => {
      if (toolbarRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearOnElementResize(toolbarRef.current);
      }
    };
  }, []);

  const handleMessage = ({ data: updatedIssue }: Message<IssueWithDetails>) => {
    dispatch(updateIssue(updatedIssue));
  };

  const token = useAppSelector(selectToken);

  const { isSocketActive, reconnectionAttempt, connectSocket } = useSocket({
    token,
    service: 'issues',
    channels: ['issue'],
    onMessage: handleMessage,
  });

  const handleStatusChange = useCallback(
    (status: IssueStatusFilter) => {
      dispatch(updateStatusFilter(status));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch, updatePagination, updateStatusFilter]
  );

  return (
    <>
      <div className={c(styles.issuesToolbar, inMinimalMode && styles.minimalHeader)} ref={toolbarRef}>
        <div className={styles.toolbarCell}>
          <CopyText variant="copy-4" additionalClass="flex items-center">
            <NetworkStatusIndicator size="xs" variant={isSocketActive ? 'ok' : 'error'} />
            {`${i18n.issues} `}
            <span className="ml-4 copy-3" data-testid={issueManagerTestDataConfig.testIds.topBarTotalIssuesCountLabel}>
              {totalIssueCount}
            </span>
          </CopyText>
        </div>

        {isSocketActive && (
          <>
            {STATUSES.map(status => (
              <IssueFilterButton
                key={status}
                count={issuesSummary.status_counts[status]}
                label={commonI18n[status]}
                active={filters.discreteFilters.state === status}
                onToggle={handleStatusChange}
                status={status}
                showBadge={issuesSummary.status_counts[status] > 0}
                testid={issueManagerTestDataConfig.testIds.filters[`${status}IssuesFilterButtonLabel`]}
              />
            ))}

            <FilterableToolbarSearch
              isLoading={isLoading}
              additionalClass="toolbarSearch"
              showFiltersToggle={showFiltersToggle}
              filters={filterConfig}
              keyword={keyword}
              onSearch={onSearch}
              onFiltersChange={onFiltersChange}
            />
          </>
        )}

        {!isSocketActive && <SocketDisconnected tryNumber={reconnectionAttempt} onRetry={connectSocket} />}
      </div>

      <FilterableToolbar
        filters={filterConfig}
        onFiltersChange={onFiltersChange}
        {...props}
        additionalClass={styles.hasToolbarExtension}
      />
    </>
  );
};
