import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BASE_NAME, IS_CF_PAGES, IS_DEV, IS_LOCAL, SENTRY_DSN } from '@config';

if (!IS_DEV && !IS_LOCAL) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration({
        blockAllMedia: true,
      }),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  Sentry.setContext('env', {
    baseName: IS_CF_PAGES ? 'cloud' : BASE_NAME,
  });
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
