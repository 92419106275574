import { InfoGrid, InfoGridItem } from '@components/layout/InfoGrid/InfoGrid';
import React from 'react';
import { formatEpochDate } from '@utils/date';
import { Gravatar } from '@components/common/Gravatar';
import CopyText from '@components/typography/CopyText';
import { CopyToClipboardButton } from '@components/connected/CopyToClipboardButton/CopyToClipboardButton';
import { Position } from '@blueprintjs/core';
import { SessionDetailsEntry } from '@infrastructure/api/BaseNClient/useSessionByIdQuery';
import ROUTES from '@infrastructure/routes';
import Link from '@components/common/Link';

type SessionLogDetailsProps = {
  entry: SessionDetailsEntry;
};

export const SessionLogDetails = ({ entry }: SessionLogDetailsProps) => {
  const data = React.useMemo(
    () =>
      [
        {
          label: 'Started',
          value: formatEpochDate(entry?.timestamp),
        },
        !!entry?.endtimestamp && {
          label: 'Ended',
          value: formatEpochDate(entry.endtimestamp),
        },
        !!entry?.sessionId && {
          label: 'ID',
          value: (
            <div className="w-full hbox">
              <div className="truncate">{entry?.sessionId}</div>
              <CopyToClipboardButton
                size="xs"
                textToCopy={entry?.sessionId}
                tooltipProps={{ targetProps: { className: 'min-w-24' }, position: Position.TOP }}
              />
            </div>
          ),
        },
        !!entry?.entityId && {
          label: 'Entity ID',
          value: (
            <div className="w-full hbox">
              <Link
                href={ROUTES.entityById.replace(':entityId', entry?.entityId)}
                additionalClass="flex-grow min-w-0 truncate"
              >
                {entry?.entityId}
              </Link>
              <CopyToClipboardButton
                size="xs"
                textToCopy={entry?.entityId}
                tooltipProps={{ targetProps: { className: 'min-w-24' }, position: Position.TOP }}
              />
            </div>
          ),
        },
        !!entry?.user && {
          label: 'User',
          value: (
            <div className="w-full hbox">
              <Gravatar size={48} username={entry.user} email={entry.user} additionalClass="mr-8" />
              <CopyText variant="copy-4" additionalClass="truncate">
                {entry.user}
              </CopyText>
            </div>
          ),
        },
      ].filter(Boolean) as InfoGridItem[],
    [entry]
  );

  return (
    <div className="w-full pt-8 pb-20">
      <InfoGrid data={data} size="m" variant="description" />
    </div>
  );
};
