import { ReviewStep, TargetsStep, SchedulesStep, GeneralStep } from './components';
import { Wizard } from '@components/common/form/Wizard';
import { useCallback } from 'react';
import { UseMWSubmitMutationResponse, useMWSubmitMutation } from '@infrastructure/api/BaseNClient/useMWSubmitMutation';
import { formValuesToMW } from '@utils/mw';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import ROUTES from '@infrastructure/routes';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import ENDPOINTS from '@infrastructure/api/endpoints';

import styles from './MWCreatePage.module.scss';

export const MWCreatePage = () => {
  const navigate = useNavigate();
  const submitMWMutation = useMWSubmitMutation();
  const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    (values: any, { setIsSubmitting }) => {
      submitMWMutation.mutate(formValuesToMW(values), {
        onSuccess: getSuccessHandler((res: UseMWSubmitMutationResponse) => {
          setIsSubmitting(false);
          navigate(ROUTES.singleMaintenanceWindow.replace(':id', res.id));

          queryClient.invalidateQueries(ENDPOINTS.maintenanceWindows);
        }, 'Maintenance Window added successfully'),
        onError: getFailureHandler(undefined, 'Failed to add Maintenance Window'),
      });
    },
    [getFailureHandler, getSuccessHandler, navigate, submitMWMutation, queryClient]
  );

  return (
    <div className={styles.mwCreatePage}>
      <Wizard onSubmit={handleSubmit}>
        <GeneralStep name="general" title="General" />
        <SchedulesStep name="schedules" title="Schedules" />
        <TargetsStep name="targets" title="Targets" />
        <ReviewStep name="review" title="Review" />
      </Wizard>
    </div>
  );
};
