import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import { useAppDispatch } from '@infrastructure/redux/store';
import {
  DashboardCreateSuccessResponse,
  NewDashboard,
  NewRawDashboard,
  RawDashboard,
  createReset,
} from '@redux/dashboards';
import { mapDashboardToRaw } from '@redux/dashboards/data-mapper';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';

export type CreateDashboardParams = NewDashboard;

export const useCreateDashboardMutation = <
  T extends DashboardCreateSuccessResponse = DashboardCreateSuccessResponse,
  P extends CreateDashboardParams = CreateDashboardParams
>(
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> => {
  const dispatch = useAppDispatch();

  return useMutation<T, Error, P>(
    (dashboard): Promise<T> =>
      apiClient.post<T, RawDashboard | NewRawDashboard>(ENDPOINTS.createDashboard, mapDashboardToRaw(dashboard)),
    {
      onSuccess: () => {
        dispatch(createReset());
      },
      ...mutationOptions,
    }
  );
};
