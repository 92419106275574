export const maybePrettify = (json: string): string => {
  try {
    return JSON.stringify(JSON.parse(json), undefined, 2);
  } catch (ex) {
    return json;
  }
};

export const maybeMinify = (json: string): string => {
  try {
    return JSON.stringify(JSON.parse(json));
  } catch (ex) {
    return json;
  }
};
