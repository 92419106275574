import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Source, Layer, LayerProps, MapboxMap, MapLayerMouseEvent } from 'react-map-gl';
import { ExpressionSpecificationArray, LayerSpecification } from 'maplibre-gl';
import { MapContext } from 'react-map-gl/dist/esm/components/map';
import { FQDNId } from './types';

export type MVTLayerProps = {
  id: string;
  url?: string;
  type: LayerProps['type'];
  minZoom?: number;
  maxZoom?: number;
  layout?: LayerSpecification['layout'];
  paint?: LayerSpecification['paint'];
  filter?: ExpressionSpecificationArray;
  sourceId?: string; // defaults to id
  sourceLayerId?: string;
  beforeId?: string;
  onClick?: (e: MapLayerMouseEvent) => void;
  onHover?: (e: MapLayerMouseEvent) => void;
};

const defaultLayout: Record<string /* potentially LayerProps['type'] */, LayerSpecification['layout']> = {
  symbol: {
    'icon-allow-overlap': true,
    'icon-anchor': 'center',
  },
  line: {
    visibility: 'visible',
  },
};

const defaultPaint: Record<string /* potentially LayerProps['type'] */, LayerSpecification['paint']> = {
  line: {
    'line-width': 1,
    // 'line-blur': 0.2,
  },
};

export const MVTLayer: FC<MVTLayerProps> = ({
  id,
  type,
  layout,
  paint,
  url,
  minZoom = 0,
  maxZoom = 24,
  onHover,
  onClick,
  ...props
}) => {
  const sourceId = props.sourceId ?? id;
  const sourceLayerId = props.sourceLayerId ?? id;

  const map: MapboxMap = useContext(MapContext).map.getMap();

  const mergedLayout = useMemo(
    () => ({ ...defaultLayout[type], ...layout } as LayerSpecification['layout']),
    [type, layout]
  );
  const mergedPaint = useMemo(
    () => ({ ...defaultPaint[type], ...paint } as LayerSpecification['paint']),
    [type, paint]
  );

  const handleHover = useCallback(
    (e: MapLayerMouseEvent) => {
      const feature = e.features?.[0];
      if (feature?.id) {
        const fqdnId: FQDNId = { source: sourceId, id: feature?.id.toString(), sourceLayer: sourceLayerId };
        const hoverState = map.getFeatureState(fqdnId)?.hover;
        map.setFeatureState(fqdnId, { hover: !hoverState });
        onHover?.(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map, onHover]
  );

  useEffect(
    () => {
      if (onHover) {
        map.on('mouseenter', id, handleHover);
        map.on('mouseleave', id, handleHover);
      }

      if (onClick) {
        map.on('click', id, onClick);
      }

      return () => {
        if (onHover) {
          map.off('mouseenter', id, handleHover);
          map.off('mouseleave', id, handleHover);
        }

        if (onClick) {
          map.off('click', id, onClick);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map, onClick, onHover]
  );

  // Layer cannot handle filter undefined, so we forcefully delete it from props
  if (props.filter === undefined) {
    // eslint-disable-next-line no-param-reassign
    delete props.filter;
  }

  return (
    <>
      {url && <Source id={sourceId} type="vector" tiles={[url]} maxzoom={maxZoom} minzoom={minZoom} promoteId="id" />}
      <Layer
        id={id}
        type={type}
        source={sourceId}
        source-layer={sourceLayerId}
        layout={mergedLayout}
        // @ts-ignore - some irrelevant typescript type mismatch
        paint={mergedPaint}
        {...props}
      />
    </>
  );
};
