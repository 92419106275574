import { DashboardConfigs, FullLayout, WidgetConfigs } from './types';

export const WIDGET_META_CONFIGS: WidgetConfigs = {
  defaultWidget: {
    w: 4,
    h: 15,
    minW: 4,
    minH: 15,
    maxW: 12,
    maxH: 100,
    resizeHandles: ['se'],
    // isBounded: true,
    // isResizable: true,
    // isDraggable: true,
    // static: false,
    // moved: false,
  },
  temporaryWidget: {
    // static: true,
    // isDraggable: false,
    // isResizable: false,
  },
  nonTemporaryWidget: {
    // static: false,
    // isDraggable: true,
    // isResizable: true,
  },
};

// see @theme/screens for actual widths for these
// too error prone to do it dynamically, highly unlikely we'll change breakpoints anyway
export function getColsForBP(screen: string) {
  switch (screen) {
    case 'xxs':
      return 2;
    case 'mobileMini':
      return 4;
    case 'mobile':
      return 4;
    case 'mobileLandscape':
      return 4;
    case 'smallTablet':
      return 4;
    case 'tablet':
      return 12;
    case 'tabletLandscape':
      return 12;
    case 'desktop':
      return 12;
    case 'large':
      return 12;
    case 'xl':
      return 12;
    default:
      return 12;
  }
}

const defaultLayout = { xxs: [], tablet: [] };
export const defaultBreakpoints = { xxs: 0, tablet: 768 };
const defaultCols = { xxs: getColsForBP('xxs'), tablet: getColsForBP('tablet') };

export const DASHBOARD_CONFIGS: DashboardConfigs = {
  defaultGridConfig: {
    compactType: 'vertical',
    isBounded: true,
    preventCollision: false,
    rowHeight: 20,
    breakpoints: defaultBreakpoints,
    cols: defaultCols,
    resizeHandles: ['se'],
    // positioning grid elements via translate is 6x faster, but it doesn't allow us to lazy load filterdata
    // using useInView hook of react-intersection-observer - rendering speed is not crucial in our context
    useCSSTransforms: false,
  },
  defaultLayout,
  defaultBreakpoints,
  defaultCols,
};

export const TEMP_WIDGET_ID = 'temporary-widget';
export const widgetDragHandleClass = 'widget-drag-handle';

export const TEMP_WIDGET_LAYOUT = {
  x: 0,
  y: 0,
  i: TEMP_WIDGET_ID,
  ...WIDGET_META_CONFIGS.defaultWidget,
  ...WIDGET_META_CONFIGS.temporaryWidget,
} as FullLayout;
