import { round } from 'lodash';
import invariant from './invariant';
import { Issue, IssueState } from '@redux/issues';
import { ALERT_COLORS } from '@constants/issue';
import { BadgeVariant } from '@components/common/Badge';

export type StripeColor = typeof ALERT_COLORS[number];

export type Stripe = {
  value: number | null;
  duration: number;
};

const msToMinutes = (ms: number) => Math.floor(ms / 1000 / 60);

const timesToDurations = (times: number[], lastTimestamp = Date.now()) => {
  const adjustedTimes = [...times, lastTimestamp];
  const widths = [];
  for (let i = 1; i < adjustedTimes.length; i++) {
    widths.push(msToMinutes(adjustedTimes[i] - adjustedTimes[i - 1]) * 4);
  }

  return widths;
};

export const valueToColor = (value?: number | null): StripeColor => {
  if (value === null || value === undefined) {
    return 'gray';
  }

  switch (true) {
    case value < 0.0000001:
      return 'green';
    case value >= 0.0000001 && value < 0.33:
      return 'lime';
    case value >= 0.33 && value < 0.67:
      return 'yellow';
    case value >= 0.67 && value < 0.9999999:
      return 'orange';
    case value >= 0.9999999:
      return 'red';
    default:
      return 'gray';
  }
};

export const valuesToColors = (values: (number | null)[]) => values.map(valueToColor);

export const timesAndValuesToStripes = (
  times: number[],
  values: (number | null)[],
  lastTimestamp = Date.now()
): Stripe[] => {
  invariant(
    times.length === values.length,
    `timesAndValuesToStripes: 'times' and 'values' arrays should have same lengths`
  );

  return timesToDurations(times, lastTimestamp).map((duration, idx) => ({
    duration,
    value: values[idx],
  }));
};

const reduce = (values: (number | null)[]) => {
  if (!values?.length || values[0] === null) {
    return null;
  }

  if (values.length === 1) {
    return values[0];
  }

  return round((values as number[]).reduce((sum: number, curr: number) => sum + curr, 0) / values.length, 4);
};

const isClampable = (value: number | null, otherValue: number | null, clampBy: 'value' | 'color') => {
  if (clampBy === 'value') {
    return value === otherValue;
  }

  return valueToColor(value) === valueToColor(otherValue);
};

export const clampStripes = (stripes: Stripe[], clampBy: 'value' | 'color' = 'color'): Stripe[] => {
  if (stripes.length === 1) {
    return stripes;
  }

  const clamped = [];

  // behold! an actual algo here...
  for (let i = 0, ii; i < stripes.length; i++) {
    const { value } = stripes[i];
    const values = [value];
    let duration = stripes[i].duration;

    for (ii = i + 1; ii < stripes.length; ii++) {
      const { value: nextValue, duration: nextDuration } = stripes[ii];
      if (isClampable(value, nextValue, clampBy)) {
        duration += nextDuration;
        values.push(nextValue);
      } else {
        i = ii - 1;
        break;
      }
    }
    clamped.push({
      duration,
      value: reduce(values),
    });
  }

  return clamped;
};

export const colorToRange = (color: StripeColor): string =>
  ({
    green: '< 0.00001%',
    lime: '0.00001% - 33%',
    yellow: '33% - 67%',
    orange: '67% - 99.99999%',
    red: '> 99.99999%',
    gray: '-',
    maintenance: '-',
    black: '-',
  }[color]);

export const valueToRange = (value: number): string => colorToRange(valueToColor(value));

export function getPriorityColor(priority: Issue['priority']) {
  switch (priority) {
    case 'critical':
      return 'text-alert-red';
    case 'high':
      return 'text-alert-orange';
    case 'normal':
      return 'text-blue-gray-1';
    case 'low':
      return 'text-blue-gray-2';
    case 'none':
    default:
      return 'text-alert-gray';
  }
}

export function getPriorityIcon(priority: Issue['priority']) {
  switch (priority) {
    case 'critical':
      return 'IssuesCriticalHigh';
    case 'high':
      return 'IssuesCriticalHigh';
    case 'medium':
    case 'normal':
      return 'IssuesMediumNormal';
    case 'low':
      return 'IssuesLow';
    case 'none':
      return 'IssuesNone';
    default:
      return 'IssuesNone';
  }
}

export function getStatusBadgeVariant(status: IssueState): BadgeVariant {
  switch (status) {
    case 'open':
      return 'orange';

    case 'tracking':
      return 'warning';

    case 'new':
      return 'error';

    case 'frozen':
      return 'info';

    case 'closed':
    default:
      return 'default';
  }
}

export const isAlertColor = (colorName: any) => !!colorName && ALERT_COLORS.includes(colorName);
