import palette from './palette';

export default {
  initial: 'initial',
  inherit: 'inherit',
  'current-color': 'currentColor',
  transparent: 'transparent',
  backdrop: 'rgba(1,30,66,0.3)',
  black: '#000000',
  dark: '#1A1A1A',
  gray: {
    1: '#333333',
    2: '#4F4F4F',
    3: '#828282',
    disabled: '#DADADA',
    4: '#BDBDBD',
    5: '#E0E0E0',
    light: '#E0E0E0',
    6: '#F2F2F2',
  },
  // bran blue -> pink
  'bn-gradient': 'linear-gradient(180deg, #A23182 0%, #47A5E5 100%);',
  white: '#FFFFFF',
  'white-light': 'rgba(255, 255, 255, 0.7)',
  'white-disabled': 'rgba(255, 255, 255, 0.5)',
  background: {
    white: '#F9FBFD',
  },
  blue: {
    gray: {
      base: '#8497AB',
      DEFAULT: '#8497AB',
      1: '#AFBECC',
      2: '#C3CED9',
      3: '#D5DDE5',
      4: '#DFE5EB',
      5: '#E6EBF0',
      6: '#EDF1F5',
      background: '#F7F9FB',
    },
    dark: '#011E42',
    n: '#012A5C',
    base: '#265B99',
    DEFAULT: '#265B99',
    ocean: '#2182C3',
    brand: '#47A5E5',
    light: '#8FC5EA',
    info: '#2C8CDF',
    infoLight: '#E8F4FD',
  },
  alert: {
    gray: '#BDBDBD',
    red: '#F03738',
    orange: '#FE9663',
    yellow: '#FFCC00',
    lime: '#AAD459',
    green: '#29B028',
    maintenance: '#2C8CDF',
    pink: '#A23182',
    black: '#000000',
  },
  pink: {
    base: '#A23182',
    light: '#FFECF9',
  },
  red: {
    base: '#F03738',
    error: '#F03738',
    active: '#C50F10',
    dark: '#A20202',
    light: '#FEEBEB',
    secondary: '#FAA2A2',
  },
  green: {
    base: '#29B028',
    success: '#29B028',
    light: '#ECF9EB',
    secondary: '#ABE4A7',
  },
  lime: {
    base: '#AAD459',
    light: '#F2FFDA',
  },
  yellow: {
    base: '#F3BB1C',
    warning: '#F3BB1C',
    light: '#FEF8E8',
    secondary: '#F3C44A',
  },
  orange: {
    base: '#FE9663',
    light: '#FEF0E8',
  },
  palette,
};
