import { AppState } from '@infrastructure/redux/store';
import { createSelector } from 'reselect';

export const selectWidgetPage = (state: AppState) => state.widgetPage;
export const selectIsWidgetConfigEmpty = createSelector(
  selectWidgetPage,
  widgetPageRoot => !widgetPageRoot.widgetConfig
);

export const selectWidgetConfig = createSelector(selectWidgetPage, widgetPageRoot => widgetPageRoot.widgetConfig);

export const selectWidgetId = createSelector(selectWidgetConfig, config => config?.id);
export const selectWidgetName = createSelector(selectWidgetConfig, config => config?.name);
export const selectWidgetType = createSelector(selectWidgetConfig, config => config?.type);
export const selectWidgetSubConfig = createSelector(selectWidgetConfig, config => config?.config);
