import { z } from 'zod';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import ENDPOINTS from '../endpoints';
import { useValidatedQuery } from '@services/useValidatedQuery';
import QUERY_CONFIGS from '../query-configs';
import { scriptSchema } from './useScriptsQuery';

const entitySchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const scriptDetailsSchema = z
  .object({
    script: z.string(),
    entities: z.array(entitySchema).optional(),
    variables: z.array(z.string()).optional(),
  })
  .merge(scriptSchema);

export type ScriptDetails = z.infer<typeof scriptDetailsSchema>;

export const useScriptByIdQuery = <T extends ScriptDetails = ScriptDetails>(
  id: string,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.scriptById, { id }, scriptDetailsSchema, {
    ...(QUERY_CONFIGS.cacheDisabled as UseQueryOptions<T, Error>),
    ...queryOptions,
  });
};
