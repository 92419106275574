import useTextSnippets from '@services/useTextSnippets';
import { Checkbox } from '@components/common/form/Checkbox';
import Icon from '@components/common/Icon';
import { Menu, MenuItem } from '@components/common/Menu';
import React, { FC, useMemo } from 'react';
import HorizontalTitleSeparator from '@components/separators/HorizontalTitleSeparator/HorizontalTitleSeparator';
import LoadingSpinner from '../LoadingSpinner';
import CopyText from '@components/typography/CopyText';
import { FavoritesFolder } from './types';
import { IRef } from '@blueprintjs/core';

import styles from './FavoritesList.module.scss';
import { ROOT_FOLDER_NAME } from './constants';

type CheckboxItemProps = FavoritesFolder & {
  onChange?: (id: number, checked: boolean) => void;
};

const CheckboxItem: FC<CheckboxItemProps> = ({ onChange, name, id, checked = false, loading = false }) => {
  const handleClick = () => {
    onChange?.(+id, !checked);
  };

  return (
    <MenuItem
      additionalClass={styles.checkboxItem}
      onClick={handleClick}
      text={
        <>
          {!loading ? (
            <Checkbox id={`folder-${id}`} size="s" additionalClass="mr-8" checked={!!checked} />
          ) : (
            <LoadingSpinner centered additionalClass={styles.spinner} size="s" />
          )}
          <Icon name="FolderClose" size="s" additionalClass="mr-4" />
          <span className={styles.title}>{name}</span>
        </>
      }
    />
  );
};

export type FavoritesListProps = {
  folders?: FavoritesFolder[];
  onChange?: (id: number, checked: boolean) => void;
  contentAreaRef?: IRef<HTMLUListElement>;
};

export const FavoritesList: FC<FavoritesListProps> = ({ folders = [], onChange, contentAreaRef }) => {
  const i18n = useTextSnippets('favorites');

  const { rootFolder, otherFolders } = useMemo(() => {
    return folders.reduce(
      ({ rootFolder: root, otherFolders: others }, curr: FavoritesFolder) =>
        curr.name === ROOT_FOLDER_NAME
          ? { rootFolder: curr, otherFolders: others }
          : { rootFolder: root, otherFolders: [...others, curr] },
      { rootFolder: null, otherFolders: [] } as { rootFolder: FavoritesFolder | null; otherFolders: FavoritesFolder[] }
    );
  }, [folders]);

  return (
    <div className={styles.favoritesList}>
      <Menu className="p-0">
        {!rootFolder ? (
          <CheckboxItem id={0} name={i18n.addSingleItem} onChange={onChange} />
        ) : (
          <CheckboxItem
            id={rootFolder.id}
            name={rootFolder.checked ? i18n.addedSingleItem : i18n.addSingleItem}
            onChange={onChange}
            checked={rootFolder.checked}
            loading={rootFolder.loading}
          />
        )}
      </Menu>

      <HorizontalTitleSeparator title={i18n.addToFolders} variant="copy-6" additionalClass={styles.separator} />

      <Menu className={styles.existingFavoritesList} ulRef={contentAreaRef}>
        {otherFolders.length ? (
          otherFolders.map((props: FavoritesFolder) => <CheckboxItem key={props.id} {...props} onChange={onChange} />)
        ) : (
          <CopyText variant="copy-6" additionalClass={styles.empty}>
            {i18n.noFoldersYet}
          </CopyText>
        )}
      </Menu>
    </div>
  );
};
