import Drawer from '@components/common/Drawer';
import Icon from '@components/common/Icon';
import { Menu, MenuItem } from '@components/common/Menu';
import { DetachedPopover, DetachedPopoverProps } from '@components/common/Popover/DetachedPopover';
import useBreakpoint from '@services/useBreakpoint';
import { TableItemAction } from '../types';
import { isActionDisabled } from '../utils';

export type TableContextMenuProps<T extends object = object> = {
  item?: T;
  selectedItems?: T[];
  target?: DetachedPopoverProps['target'];
  actions: TableItemAction<T>[];
  onClose?: () => void;
};

export function TableContextMenu<T extends object = object>({
  item,
  selectedItems,
  target,
  actions,
  onClose,
}: TableContextMenuProps<T>) {
  const isOpen = !!target && !!item;

  const {
    breakpoints: { isDesktop },
  } = useBreakpoint();

  const menu = (
    <Menu>
      {actions
        .filter(({ multiAction }) => typeof multiAction !== 'number' || multiAction === 1)
        .map(action => {
          const items = selectedItems?.length ? selectedItems : item ? [item] : ([] as T[]);
          const isDisabled = isActionDisabled(action, items);

          return (
            <MenuItem
              key={action.id}
              icon={action.icon ? <Icon name={action.icon} /> : null}
              onClick={event => {
                if (item) {
                  onClose?.();
                  action.handler(items, event.currentTarget);
                }
              }}
              text={action.label}
              disabled={isDisabled}
            />
          );
        })}
    </Menu>
  );

  return isDesktop ? (
    <DetachedPopover isOpen={isOpen} target={target} onClose={onClose}>
      {menu}
    </DetachedPopover>
  ) : (
    <Drawer isOpen={isOpen} onClose={onClose} size="auto">
      {menu}
    </Drawer>
  );
}
