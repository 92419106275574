import { ENTITY_INFO_ITEMS, CHILD_ENTITY_INFO_ITEMS } from '@constants/entity';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import { EntityDataSource, EntityWidgetSubConfig } from '@redux/widgetPage';
import React, { FC, useCallback } from 'react';
import c from 'classnames';
import useBreakpoint, { Screen } from '@services/useBreakpoint';
import { WidgetComponentProps } from '@components/pages/WidgetPage/types';
import { getEntityProperty, getEntityTitle } from '../../../utils/entity';

import styles from './EntityWidget.module.scss';

const bpToCols = (bp: Screen) => {
  switch (bp) {
    case 'xxs':
    case 'mobileMini':
    case 'mobile':
    case 'mobileLandscape':
      return 2; // NOTE: doesn't seem to work
    case 'smallTablet':
    case 'tablet':
      return 3;
    default:
      return 6;
  }
};

export type EntityWidgetProps = Omit<WidgetComponentProps, 'config'> & {
  config?: EntityWidgetSubConfig | null;
};

export const EntityWidget: FC<EntityWidgetProps> = ({ config }) => {
  const { source } = config ?? {};
  const entityDataSource = source as EntityDataSource;
  const entity = useEntityQuery({ entityId: entityDataSource.entityId });
  const {
    getCurrentBreakpoint,
    breakpoints: { isTablet },
  } = useBreakpoint();

  const renderItems = useCallback(() => {
    const items = Object.keys(entity.data?.parent ? CHILD_ENTITY_INFO_ITEMS : ENTITY_INFO_ITEMS)
      .map(key => {
        if (!entity.data) {
          // TODO: can have some message here instead of null
          return null;
        }

        const value = getEntityProperty(entity.data, key);

        return value ? (
          <div
            key={key}
            // special care for a huge 'SYSDESCR'
            className={c(styles.infoItem, key === 'SYSDESCR' && [styles.long, styles[`span-${!isTablet ? 2 : 3}`]])}
          >
            <div className={styles.title}>{getEntityTitle(entity.data, key)}</div>
            <div className={styles.value} title={value}>
              {value}
            </div>
          </div>
        ) : null;
      })
      .filter(Boolean);

    return items.length ? items : null;
  }, [entity.data, isTablet]);

  return (
    <div className={styles.entityWidget}>
      <div className={c(styles.infoGrid, styles[`cols-${bpToCols(getCurrentBreakpoint())}`])}>
        {entity.isLoading ? <LoadingSpinner additionalClass="m-20" centered /> : renderItems()}
      </div>
    </div>
  );
};
