import { EntityDetails } from '@redux/inventoryPage';

export const COMMON_DATA_KEYS = [
  'agent',
  'alertgroup',
  'bgp_enabled',
  'bgp_id',
  'bgp_local_asn',
  'cdp_local_sys_id',
  'chassisentphysicalalias',
  'chassisentphysicalassetid',
  'chassisentphysicalchassiscount',
  'chassisentphysicalclass',
  'chassisentphysicalcontainedin',
  'chassisentphysicaldescr',
  'chassisentphysicalfancount',
  'chassisentphysicalmfgdate',
  'chassisentphysicalmodulecount',
  'chassisentphysicalportcount',
  'chassisentphysicalpsucount',
  'customer_name',
  'customerid',
  'customernick',
  'entity_descr',
  'entity_type',
  'hostname',
  'if_port_ethernet_count',
  'ip_route_magnitude',
  'ip_routing_enabled',
  'ipaddress',
  'ipv4_route_default_gateway',
  'ipv6_route_magnitude',
  'ipv6_routing_enabled',
  'lldp_local_sys_id',
  'lldp_local_sys_name',
  'mapcity',
  'mapcountry',
  'mpls_enabled',
  'ospf_enabled',
  'ospf_router_abr_status',
  'ospf_router_asbr_status',
  'ospf_router_id',
  'sys_cpu_architecture',
  'sys_cpu_name',
  'sys_cpu_thread_count',
  'sys_fw_version',
  'sys_hw_version',
  'sys_memory_total_gb',
  'sys_model_family',
  'sys_model_name',
  'sys_model_series',
  'sys_os_build_date',
  'sys_os_kernel_version',
  'sys_os_name',
  'sys_product_series',
  'sys_serial',
  'sys_storage_total_gb',
  'sys_sw_build_date',
  'sys_sw_image',
  'sys_sw_version',
  'sys_sw_version_major',
  'sys_vendor_name',
  'syscontact',
  'sysdescr',
  'syslocation',
  'sysname',
  'sysoid',
  'sysservices',
] as const;

export const COMMON_CHILD_DATA_KEYS = [
  'ifadmin',
  'ifalias',
  'ifaliasoriginal',
  'ifconnectorpresent',
  'ifdescr',
  'ifdescroriginal',
  'ifindex',
  'ifname',
  'ifnameoriginal',
  'ifoperdiscoverystatus',
  'ifphysaddress',
  'ifspeed',
  'iftype',
  'iftype_descr',
  'if_ipv4_address_list',
  'if_ipv6_address_list',
  'disksize_gb',
  'ospf_area_id',
  'bgp_peer_asn',
] as const;

// populated from: https://jira.basen.net/browse/UX-364?focusedCommentId=86739#comment-86739
export const ENTITY_INFO_ITEMS: Record<keyof EntityDetails['data']['original'], string> = {
  ipaddress: 'IP Address',
  customer_name: 'Customer',
  sys_vendor_name: 'Vendor',
  sys_model_name: 'Model',
  sys_serial: 'Serial',
  sys_sw_version: 'SW Version',
  sys_sw_build_date: 'SW Build Date',
  sys_os_name: 'OS',
  sys_os_kernel_version: 'OS Kernel Version',
  sys_os_build_date: 'OS Build Date',
  sys_memory_total_gb: 'Memory (GB)',
  sys_storage_total_gb: 'Storage (GB)',
  sys_cpu_name: 'CPU Name',
  sys_cpu_thread_count: 'CPU Threads',
  if_port_ethernet_count: 'Ethernet Count',
  chassisentphysicalchassiscount: 'Chassis Count',
  chassisentphysicalmodulecount: 'Module Count',
  chassisentphysicalpsucount: 'PSU Count',
  chassisentphysicalfancount: 'Fan Count',
  entity_type: 'Entity Type',
  entity_descr: 'Entity Description',
  sys_model_family: 'Model Family',
  sys_model_series: 'Model Series',
  sys_hw_version: 'HW Version',
  sys_fw_version: 'FW Version',
  sys_sw_version_major: 'SW Version Major',
  bgp_local_asn: 'BGP ASN',
  hostname: 'Hostname',
  sysname: 'SNMP sysName',
  syslocation: 'SNMP sysLocation',
  syscontact: 'SNMP sysContact',
  sysoid: 'SNMP sysOID',
  sysdescr: 'SNMP sysDescr', // this one has to be long
  agent: 'Agent',
};

// populated from: https://jira.basen.net/browse/UX-458
export const CHILD_ENTITY_INFO_ITEMS: Record<keyof EntityDetails['data']['original'], string> = {
  if_ipv4_address_list: 'IP4 Addresses',
  if_ipv6_address_list: 'IP6 Addresses',
  ifindex: 'SNMP ifIndex',
  ifdescroriginal: 'SNMP ifDescr',
  ifnameoriginal: 'SNMP ifName',
  ifaliasoriginal: 'SNMP ifAlias',
  iftype_descr: 'Interface type', // ? Interface type (IANA) IFTYPE_DESCR (IFTYPE)
  ifcustom: 'Interface other info',
  ifphysaddress: 'Interface MAC',
  dot1d_port_learned_mac_list: 'Learned MACs',
  dot1q_vlan_name: 'VLAN name',
  dot1q_port_vlan_list: 'VLAN list',
  dot1q_port_trunk_admin_state: 'Port trunk admin state',
  dot1q_port_trunk_oper_state: 'Port trunk operative state',
  eth_poe_port_status: 'Port PoE status',
  egressratelimit: 'Egress rate limit', // bit/s
  ingressratelimit: 'Ingress rate limit', // bit/s
  if_lag_member_ifname_list: 'Link aggregate Members',
  if_lag_aggregate_ifname_list: 'Link aggregate member Aggregate',
  entphysicalmfgname: 'Port manufacturer',
  entphysicalname: 'Port name',
  entphysicaldescr: 'Port description',
  entphysicalmodelname: 'Port hardware model',
  entphysicalhardwarerev: 'Port hardware revision',
  entphysicalserialnum: 'Port serial number',
  entphysicalmfgdate: 'Port manufacturing date',
  entphysicalfirmwarerev: 'Port firmware version',
  entphysicalsoftwarerev: 'Port software version',
  entphysicaluris: 'Port URI',
  ciscointerfacefexname: 'Cisco FEX name',
  ciscointerfacefexstatus: 'Cisco FEX status',

  /* TODO: special care for the following ones
  Neighbors (basically these fields should be split by comma and be arranged as a table)
cdpneighbors (list of devices) - cdpneighborsremoteport (list of ports, the port corresponds to the device when you split both to arrays )
  lldp_remote_name_list - lldp_remote_port_list
  */
};

export const ENABLE_PAGE_PLOTNAME_CHARTS = false;
