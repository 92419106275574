import { set } from 'lodash';

export type ClusterType = 'cluster' | 'site';

type Registry = Record<Cluster['id'], Cluster>;

export type Cluster = {
  id: string;
  name?: string;
  alerting: number;
  maintenance: number;
  entities: number;
  entityIds?: string[];
  lng: number;
  lat: number;
  zoom: number;
  type: ClusterType;
};

export class ClusterCache {
  private idxByZoom: Record<number, Registry> = {};
  private idxById: Registry = {};
  private idxByType: Partial<Record<ClusterType, Registry>> = {};

  add(cluster: Cluster | Cluster[]): boolean {
    let wasUpdated = false;

    if (Array.isArray(cluster)) {
      cluster.forEach(item => {
        if (this.add(item)) {
          wasUpdated = true;
        }
      });
      return wasUpdated;
    }

    if (this.idxById[cluster.id]) {
      return false;
    } else {
      wasUpdated = true;
    }

    this.idxById[cluster.id] = cluster;
    set(this.idxByZoom, `["${cluster.zoom}"]["${cluster.id}"]`, cluster);
    set(this.idxByType, `["${cluster.type}"]["${cluster.id}"]`, cluster);

    return wasUpdated;
  }

  getById(id: string): Cluster | null {
    return this.idxById[id] ?? null;
  }

  getByZoom(zoom: number): Cluster[] {
    return (this.idxByZoom[zoom] && Object.values(this.idxByZoom[zoom])) ?? [];
  }

  getByType(type: ClusterType): Cluster[] {
    return Object.values(this.idxByType[type] ?? {});
  }

  clear() {
    this.idxByZoom = {};
    this.idxById = {};
    this.idxByType = {};
  }
}
