import React from 'react';
import c from 'classnames';
import { MenuDivider as BPMenuDivider, MenuDividerProps } from '@blueprintjs/core';
import styles from './style.module.scss';

export type Props = MenuDividerProps;

const MenuDivider: React.FC<Props> = ({ className, ...rest }) => (
  <BPMenuDivider className={c(styles.menuDivider, className)} {...rest} />
);

export default MenuDivider;
