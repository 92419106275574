import { DownloadPopup, DownloadPopupProps } from '@components/connected/DownloadButton';
import { API_URL } from '@config';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { toQueryStr } from '@utils/url';
import React from 'react';

export type ScriptDownloadPopupProps = Omit<DownloadPopupProps, 'url'> & {
    params: Record<string, any>;
};

export const ScriptDownloadPopup: React.FC<ScriptDownloadPopupProps> = ({ params, ...props }) => {
    const url = `${API_URL}${ENDPOINTS.scripts}/?${toQueryStr(params)}`;

    return <DownloadPopup {...props} url={url} fileName="script" />;
};
