import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { selectSettings } from './selectors';
import { useMeQuery } from '@infrastructure/api/BaseNClient/useMeQuery';
import React from 'react';
import { updateSettings } from './slice';
import { updateAccount } from '../auth';

export function useSettings() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectSettings);

  const meQuery = useMeQuery();

  React.useEffect(() => {
    if (!meQuery.isLoading && meQuery.data) {
      const {
        info,
        user: { user },
      } = meQuery.data;

      dispatch(updateSettings(info));

      dispatch(
        updateAccount({
          id: user.id,
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          timezone: user.timezone,
          phone: user.phone,
          locale: user.locale,
        })
      );
    }
  }, [meQuery.data, meQuery.isLoading, dispatch]);

  return {
    settings,
    q: meQuery,
  };
}
