import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { EntityDetails } from '@redux/inventoryPage';

type EntityRequest = {
  entityId?: string | null;
  include_children?: boolean;
  include_child_metricsets?: boolean;
  include_child_alerts?: boolean;
  include_maintenance?: boolean;
  alerts?: boolean;
  start_index?: number;
  end_index?: number;
};

export const useEntityQuery = <T extends EntityDetails = EntityDetails>(
  params: EntityRequest,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> =>
  useQuery<T, Error>(
    ['entity', params],
    (): Promise<T> =>
      apiClient.get<T>(ENDPOINTS.getEntity, {
        include_children: false,
        include_child_metricsets: false,
        include_child_alerts: false,
        ...params,
      }),
    {
      ...(QUERY_CONFIGS.stale as UseQueryOptions<T, Error>),
      enabled: !!params.entityId,
      ...queryOptions,
    }
  );
