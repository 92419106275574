import { ItemWithId, withoutUndefined } from './misc';
import { getURLQueryParams } from './url';
import { history } from '@services/useHistory';
import { PaginatedSliceState, PaginationParams, SortingParams } from '@infrastructure/redux/types';
import { RowHeight } from '@components/common/Table/types';
import { DEFAULT_FILTERS, DEFAULT_PAGINATION, DEFAULT_TABLE_CONFIG } from '@components/common/Table/config';
import {
  SliceCaseReducers,
  Slice,
  configureStore,
  createAction,
  createReducer,
  CreateSliceOptions,
  PayloadAction,
} from '@reduxjs/toolkit';
import locationSlice from '@infrastructure/redux/location/slice';
import authSlice from '@infrastructure/redux/auth';
import { DEVTOOLS_OPTIONS } from '@constants/redux';
import { createSelector } from 'reselect';
import { AppState } from '@infrastructure/redux/store';
import { identity, isUndefined } from 'lodash';
import { IS_DEV } from '@config';
import { DEFAULT_ISSUE_VIEW } from '@infrastructure/redux/issues/config';

export function toItemsAndOrder<T extends ItemWithId = ItemWithId>(data: T[]) {
  return data.reduce(
    (acc, c) => ({
      items: {
        ...acc.items,
        [c.id]: c,
      },
      order: [...acc.order, c.id],
    }),
    {
      items: {},
      order: [],
    } as { items: Record<string, T>; order: (number | string)[] }
  );
}

export function selectInitialFilters(dataTableId?: string) {
  const { discreteFilters, generalFilter } = getURLQueryParams(history.location);
  if (discreteFilters === undefined && generalFilter === undefined) {
    if (dataTableId === 'table-settings-metrics') {
      return { ...DEFAULT_FILTERS, discreteFilters: { metricId: 'icmp.ping.packet_loss' } }
    } else {
      return DEFAULT_FILTERS
    }
  }
  return { ...DEFAULT_FILTERS, discreteFilters, generalFilter };
}

export function selectInitialPagination() {
  const { rowsPerPage, page } = getURLQueryParams(history.location);

  return {
    rowsPerPage: !isUndefined(rowsPerPage) ? +rowsPerPage : DEFAULT_TABLE_CONFIG.rowsPerPage,
    page: !isUndefined(page) ? +page : DEFAULT_TABLE_CONFIG.page,
    rowCount: DEFAULT_TABLE_CONFIG.rowCount,
  } as PaginationParams;
}

export function selectInitialSorting() {
  const { sortBy, sort } = getURLQueryParams(history.location);

  return {
    sortBy,
    sort,
  } as SortingParams;
}

export function getConfigFromURL() {
  return {
    ...selectInitialPagination(),
    ...selectInitialSorting(),
  };
}

export function selectInitialView() {
  const { view } = getURLQueryParams(history.location);
  return view ?? DEFAULT_ISSUE_VIEW.id;
}

// createSlice doesn't allow to separate slice name from prefix for actions, hence this custom slice generator
type SliceOptions = CreateSliceOptions & {
  actionPrefix?: string;
};

export const createCustomSlice = (options: SliceOptions) => {
  const { name, initialState, reducers, actionPrefix } = options;

  const generatedReducers: any = {};
  const generatedActions: any = {};

  for (let key in reducers) {
    const type = `${actionPrefix ?? name}/${key}`;
    generatedActions[key] = createAction(type);
    generatedReducers[type] = reducers[key];
  }

  return {
    name,
    actions: generatedActions,
    reducer: createReducer(initialState, generatedReducers),
    caseReducers: reducers,
    getInitialState: () => initialState,
  };
};

export const createPortableStore = (slice: Slice<any, SliceCaseReducers<any>, string>) => {
  return {
    store: configureStore({
      reducer: {
        [slice.name]: slice.reducer,
        // TODO: remove when selectors are detached from locationSlice
        [locationSlice.name]: locationSlice.reducer,
        [authSlice.name]: authSlice.reducer,
      },
      devTools: IS_DEV ? DEVTOOLS_OPTIONS : undefined,
    }),
    actions: slice.actions,
  };
};

export const createDataTableReducers = <T extends ItemWithId = ItemWithId>(initialState: PaginatedSliceState<T>) => {
  return {
    updateItems(state: PaginatedSliceState<T>, { payload }: PayloadAction<T[]>) {
      const { items, order } = toItemsAndOrder<T>(payload);

      state.items = items;
      state.itemsOrder = order as string[];
      state.areLoaded = true;
    },

    updateItem(state: PaginatedSliceState<T>, { payload }: PayloadAction<T>) {
      state.items[payload.id] = payload;
    },

    updatePagination(state: PaginatedSliceState<T>, { payload }: PayloadAction<Partial<PaginationParams>>) {
      state.pagination = {
        ...DEFAULT_PAGINATION,
        ...state.pagination,
        ...payload,
      };
      state.areLoaded = true;
    },

    updateSorting(state: PaginatedSliceState<T>, { payload }: PayloadAction<PaginatedSliceState<T>['sort']>) {
      state.sort = payload;
    },

    updateRowHeight(state: PaginatedSliceState<T>, { payload }: PayloadAction<RowHeight>) {
      state.rowHeight = payload;
    },

    updateSearch(state: PaginatedSliceState<T>, { payload }: PayloadAction<string | undefined>) {
      state.filters.generalFilter = payload ?? '';
    },

    updateFilters(state: PaginatedSliceState<T>, { payload }: PayloadAction<Dictionary<any>>) {
      state.filters.discreteFilters = payload;
    },

    mergeFilters(state: PaginatedSliceState<T>, { payload }: PayloadAction<Dictionary<any>>) {
      state.filters.discreteFilters = {
        ...state.filters.discreteFilters,
        ...payload,
      };
    },

    reset() {
      return initialState;
    },
  };
};

export const createInitialDataTableState = (dataTableId?: string) => {
  return {
    areLoaded: false,
    items: {},
    itemsOrder: [],
    filters: selectInitialFilters(dataTableId),
    pagination: selectInitialPagination(),
    sort: { sortBy: 'name', sort: 'asc', ...withoutUndefined(selectInitialSorting(), true) },
    rowHeight: 'medium' as RowHeight,
  };
};

export const createDataTableSelectors = <T, S extends PaginatedSliceState<T> = PaginatedSliceState<T>>(
  sliceName: keyof AppState
) => {
  const selectSliceRoot = (state: AppState): S => state[sliceName];

  const selectItems = createSelector(selectSliceRoot, _ => _.items);
  const selectItemsOrder = createSelector(selectSliceRoot, _ => _.itemsOrder);
  const selectAreLoaded = createSelector(selectSliceRoot, _ => _.areLoaded);

  const selectItemsAsArray = createSelector(selectItems, selectItemsOrder, (items, order) =>
    order.map(id => items[id])
  );

  // the following might seem exessive, but in reality it's the only way to memoize ¯\_(ツ)_/¯
  const selectFilters = createSelector(selectSliceRoot, _ => _.filters);
  const selectPagination = createSelector(selectSliceRoot, _ => _.pagination);
  const selectSorting = createSelector(selectSliceRoot, _ => _.sort);

  const selectResolvedFilters = createSelector(selectFilters, identity);
  const selectResolvedPagination = createSelector(selectPagination, identity);
  const selectResolvedSorting = createSelector(selectSorting, identity);

  return {
    selectSliceRoot,
    selectItems,
    selectItemsOrder,
    selectAreLoaded,
    selectItemsAsArray,
    selectFilters: selectResolvedFilters,
    selectPagination: selectResolvedPagination,
    selectSorting: selectResolvedSorting,
  };
};
