import React from 'react';
import c from 'classnames';
import { Card as BPCard, CardProps as BPCardProps } from '@blueprintjs/core';

import styles from './Card.module.scss';

export type CardProps = BPCardProps & {
  header?: React.ReactNode;
  additionalClass?: string;
  centered?: boolean;
  row?: boolean;
  noPadding?: boolean;
};

const Card: React.FC<CardProps> = ({
  onClick,
  centered,
  additionalClass,
  row,
  children,
  header,
  noPadding,
  ...props
}) => {
  const hasHeader = !!header;
  const hasContent = !!children;

  return (
    <BPCard
      onClick={onClick}
      interactive={!!onClick}
      {...props}
      className={c(
        styles.card,
        centered && styles.centered,
        row && styles.row,
        additionalClass,
        hasHeader && styles.hasHeader,
        noPadding && styles.noPadding
      )}
    >
      {hasHeader && <div className={styles.header}>{header}</div>}
      {hasContent && <div className={styles.content}>{children}</div>}
    </BPCard>
  );
};

export default Card;
