// import React, { useCallback, useEffect } from 'react';
import React, { useState } from 'react';
// import TextControl from '@components/common/form/Input/TextControl';
// import Icon from '@components/common/Icon';
// import { ToggleButton } from '@components/common/ToggleButton';
import PageHeader from '@components/layout/PageHeader';
import useTextSnippets from '@services/useTextSnippets';
// import { useAppDispatch, useAppSelector } from '@infrastructure/store';
// import { filterByName, selectFilters, filterByUser } from '@infrastructure/dashboards';
// import { useAccount } from '@infrastructure/auth';
// import { useLocalStorage } from '@services';
import withCommands, { WithCommands } from '@services/withCommands';

import styles from './DashboardManagerHeader.module.scss';

const DashboardManagerHeader: React.FC<WithCommands> = ({ dashboardCreateCommand }) => {
  // const account = useAccount();
  // const dispatch = useAppDispatch();
  // const filters = useAppSelector(selectFilters);
  // const { dashboardsTitle, filterByNamePlaceholder, myDashboards, newDashboard } = useTextSnippets('dashboards');
  const i18n = useTextSnippets('dashboards');
  // const { value: myDashboardsFilter, set: setMyDashboardsFilter } = useLocalStorage<{ active: boolean }>(
  //   'myDashboardsFilterState',
  //   { active: false }
  // );

  // const onToggleMyDashboardsFilter = () => {
  //   setMyDashboardsFilter({ active: !myDashboardsFilter.active });
  // };

  // TODO filter config from the above and below

  // const updateFilterByUser = useCallback(
  //   (active: boolean) => {
  //     dispatch(filterByUser(active ? account?.sub : ''));
  //   },
  //   [dispatch, account]
  // );

  // useEffect(() => {
  //   // toggle filter based on a value saved in local storage (upon toggle local storage is updated too)
  //   updateFilterByUser(myDashboardsFilter.active);
  // }, [updateFilterByUser, myDashboardsFilter]);

  const [isLoading, setIsloading] = useState(false);

  const handleCreateNewDashboard = async () => {
    setIsloading(true);
    await dashboardCreateCommand();
    setIsloading(false);
  };

  return (
    <PageHeader
      level={1}
      additionalClass={styles.detailHeader}
      title={i18n.dashboardsTitle}
      pageControls={[
        {
          id: 'addNew',
          label: i18n.newDashboard,
          ariaLabel: i18n.newDashboard,
          loading: isLoading,
          onClick: handleCreateNewDashboard,
          icon: 'Add',
        },
      ]}
    />
  );
};

export default withCommands(DashboardManagerHeader);
