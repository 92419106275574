import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { RawDashboard, Dashboard } from '@redux/dashboards';
import { mapRawToDashboard } from '@redux/dashboards/data-mapper';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '../query-configs';

type DashboardbyIdRequest = {
  dashboardId: string;
  widget_data?: boolean;
};

export const useDashboardByIdQuery = <T extends Dashboard = Dashboard>(
  searchParams: DashboardbyIdRequest,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useQuery<T, Error>(
    ['dashboard', searchParams],
    (): Promise<T> =>
      apiClient
        .get<RawDashboard>(ENDPOINTS.dashboardById, {
          widget_data: true, // we never require dashboard without widget_data
          ...searchParams,
        })
        .then((dashboard: RawDashboard): Dashboard => mapRawToDashboard(dashboard)) as Promise<T>,
    {
      ...(QUERY_CONFIGS.cacheDisabled as UseQueryOptions<T, Error>),
      ...queryOptions,
    }
  );
};
