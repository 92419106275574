import { Toast as BNToast } from '@blueprintjs/core';
import { NotificationType, NotificationVariant } from '@infrastructure/redux/notifications';
import Icon, { IconNames } from '@components/common/Icon';
import c from 'classnames';

import styles from './Toast.module.scss';

const type2icon: Record<NotificationVariant, IconNames> = {
  success: 'SuccessMessage',
  error: 'ErrorMessage',
  warning: 'WarningMessage',
  info: 'InfoMessage',
};

export type ToastProps = NotificationType & {
  onClose?: () => void;
};

export const Toast = ({ type, title, description, delay = 0, onClose }: ToastProps) => {
  return (
    <BNToast
      icon={<Icon name={type2icon[type]} size="m" additionalClass={styles.icon} />}
      isCloseButtonShown={!!onClose}
      className={c(styles.toast, styles[type])}
      message={
        <>
          <span className={styles.title}>{title}</span>
          {description && <span className={styles.description}>{description}</span>}
        </>
      }
      onDismiss={onClose}
      timeout={delay ? delay : 0}
    />
  );
};
