import { TimeField, SelectField } from '@components/common/form';
import CopyText from '@components/typography/CopyText';
import { range } from 'lodash';

export const DailyMode = () => {
  return (
    <>
      <div className="flex flex-row gap-8 items-center mb-16">
        <TimeField label="From" name="startTime" useAmPm={false} />
        <TimeField label="To" name="endTime" useAmPm={false} />
      </div>

      <div className="flex flex-row gap-4 items-center">
        <SelectField
          name="dayRepeatInterval"
          label="Repeat every"
          options={range(1, 31).map(day => ({ label: day, value: day }))}
          inline
          noInfo
          clearable={false}
          additionalClasses={{ control: 'w-180' }}
        />
        <CopyText variant="copy-4">day(s)</CopyText>
      </div>
    </>
  );
};
