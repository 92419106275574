import React, { FC } from 'react';
import Control, { ControlProps } from '../Control/Control';
import { DateInput, DateInputProps } from './DateInput';

export type DateControlProps = ControlProps<Date | null, (selectedDate: Date | null) => void> &
  DateInputProps & {
    onError?: () => void;
  };

export const DateControl: FC<DateControlProps> = ({
  label,
  errorText,
  inline,
  additionalClass,
  disabled = false,
  ...childProps
}) => {
  return (
    <Control
      label={label}
      errorText={errorText}
      additionalClasses={{ control: additionalClass }}
      inline={inline}
      disabled={disabled}
    >
      <DateInput {...childProps} />
    </Control>
  );
};
