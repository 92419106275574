import React from 'react';

const getPathFromUrl = (url: string): string => {
  return url.split(/[?#]/)[0];
};

export const convertBreadcrumb = (
  title: string,
  toUpperCase: boolean | undefined,
  transformLabel?: ((title: string) => React.ReactNode) | undefined
): React.ReactNode => {
  let transformedTitle = getPathFromUrl(title);

  transformedTitle = transformedTitle.replace(/\?.*/, '');

  if (transformLabel) {
    return transformLabel(transformedTitle);
  }

  return toUpperCase ? decodeURI(transformedTitle.toUpperCase()) : decodeURI(transformedTitle);
};
