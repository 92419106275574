import { useDashboardsQuery } from '@infrastructure/api/BaseNClient';
import { useFavoritesQuery } from '@infrastructure/api/BaseNClient/useFavoritesQuery';
import { RawDashboard } from '@redux/dashboards';
import { populateFavoritesPanel, Favorite } from '@redux/sidebar';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { useRef, useState, useEffect } from 'react';
import { useAppDispatch } from '@infrastructure/redux/store';
import { noop } from 'lodash';

type UseSidebarFavorites = {
  isLoading: boolean;
  data: Favorite[] | null;
};

export const useSidebarFavorites = () => {
  const returnData = useRef<UseSidebarFavorites>({
    isLoading: false,
    data: null,
  });
  const { getFailureHandler } = useGenericMutationHandlers();
  const dispatch = useAppDispatch();
  const [, setUpdate] = useState(Date.now());

  // stub default onSuccess handlers
  const favoritesQuery = useFavoritesQuery({ onSuccess: noop, onError: getFailureHandler(undefined, undefined, true) });
  const dashboardsQuery = useDashboardsQuery(
    { category_types: 'favorite' },
    {
      onSuccess: noop, // do nothing on success
      onError: getFailureHandler(),
    }
  );

  useEffect(() => {
    const isLoading = favoritesQuery.isLoading || dashboardsQuery.isLoading;

    if (dashboardsQuery.data && favoritesQuery.data) {
      const dashboardsById = dashboardsQuery.data?.data.reduce(
        (map: Record<string, RawDashboard>, dashboard: RawDashboard) => ({ ...map, [dashboard.id]: dashboard }),
        {}
      );

      const favorites = favoritesQuery.data?.data?.map((favorite: any) => ({
        ...favorite,
        members: favorite.members?.reduce(
          (arr: Favorite[], dashboardId: string) =>
            dashboardsById[dashboardId] ? [...arr, dashboardsById[dashboardId]] : arr,
          []
        ),
      }));

      returnData.current = {
        isLoading,
        data: favorites ?? null,
      };

      // TODO: make this optional maybe
      dispatch(populateFavoritesPanel(favorites));
    }
    setUpdate(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoritesQuery.isLoading, dashboardsQuery.isLoading, favoritesQuery.data, dashboardsQuery.data]);

  return returnData.current;
};
