import React, { FC } from 'react';
import { IconNames } from '@components/common/Icon';
import Link, { LinkProps } from '@components/common/Link';

import styles from './Sidebar.module.scss';

export type SidebarLink = {
  icon?: IconNames;
  href: string;
  label: string;
  onClick?: (e: React.MouseEvent) => void;
  target?: LinkProps['target'];
};

export type SidebarMenuProps = {
  links?: SidebarLink[];
  onClick?: () => void;
};

export const SidebarMenu: FC<SidebarMenuProps> = ({ links = [], onClick }) => {
  return (
    <>
      {links.map(({ label, ...props }, idx) => (
        <Link
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          {...props}
          onClick={onClick}
          additionalClass={styles.link}
          testid={label}
        >
          {label}
        </Link>
      ))}
    </>
  );
};
