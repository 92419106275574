import { createContext, useContext } from 'react';
import { DashboardWithDetails } from '@redux/dashboards/types';
import { DateRange } from '@blueprintjs/datetime';

export type DashboardContextType = {
  dashboard: DashboardWithDetails;
  onEdit?: (widgetId: string) => void;
  onRemove?: (widgetId: string) => void;
  onDuplicate?: (widgetId: string) => void;
  dateRange?: DateRange;
  dashboardRef?: { current: null | HTMLDivElement };
};

export const DashboardContext = createContext<DashboardContextType | null>(null);

export function useDashboardContext(): DashboardContextType | null {
  return useContext(DashboardContext);
}
