import useTextSnippets from '@services/useTextSnippets';
import { useUpdateDashboardMutation } from '@infrastructure/api/BaseNClient';
import { selectDashboards, TEMP_WIDGET_ID, setDashboard } from '@redux/dashboards';
import { duplicateWidgetWithId } from '@redux/dashboards/dashboard-utils';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { ConfirmOperation } from '@services/withConfirmation';
import { CommandOptions } from './types';

export const useWidgetDuplicateCommand = (confirmOperation: ConfirmOperation) => {
  const i18nCommon = useTextSnippets('common');
  const i18n = useTextSnippets('widgetPage');
  const updateDashboardMutation = useUpdateDashboardMutation();
  const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();
  const dispatch = useAppDispatch();
  const dashboards = useAppSelector(selectDashboards);

  return (
    dashboardId: string,
    widgetId: string,
    newWidgetId = TEMP_WIDGET_ID,
    { successMessage, failureMessage }: CommandOptions = {}
  ) => {
    const dashboard = dashboards[dashboardId];

    return confirmOperation({
      title: i18nCommon.areYouSure,
      contents: i18n.duplicateWarning,
      modalProps: { danger: true },
    }).then(result => {
      if (result) {
        const updatedDashboard = duplicateWidgetWithId(dashboard, widgetId, newWidgetId);

        return updateDashboardMutation.mutateAsync(updatedDashboard, {
          onSuccess: getSuccessHandler(() => {
            dispatch(setDashboard(updatedDashboard));
          }, successMessage),
          onError: getFailureHandler(undefined, failureMessage),
        });
      }

      return null;
    });
  };
};
