import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import {
  IssueView,
  IssueViewRelatedData,
  IssueCustomField,
  IssueAction,
  IssueWithDetails,
  IssueGroup,
} from '@redux/issues/types';
import { QueryClient } from 'react-query';
import { issueActionsLoaded, issueFieldsLoaded, issueGroupsLoaded, issueViewsLoaded } from '@redux/issues/index';
import { AppDispatch } from '@infrastructure/redux/store';
import { IssueViewsQueryResponse } from '@infrastructure/api/BaseNClient/useIssueViewsQuery';

export type IssueActionMutationParams = {
  issue_ids: string[];
  script: string;
};

export function getIssueById(issueId: string): Promise<IssueWithDetails> {
  return apiClient.get<IssueWithDetails>(ENDPOINTS.issueById, { issueId });
}

export async function getIssueViews<T extends IssueViewsQueryResponse = IssueViewsQueryResponse>(): Promise<T> {
  return apiClient.get<T>(ENDPOINTS.issueViews);
}

export async function getIssueActions(): Promise<{ data: IssueAction[] }> {
  return apiClient.get<{ data: IssueAction[] }>(ENDPOINTS.issueActions);
}

export async function getIssueFields(): Promise<{ data: IssueCustomField[] }> {
  return apiClient.get<{ data: IssueCustomField[] }>(ENDPOINTS.issueFields);
}

export async function getIssueGroups(): Promise<{ data: IssueGroup[] }> {
  return apiClient.get<{ data: IssueGroup[] }>(ENDPOINTS.issueGroups);
}

export async function getIssueViewsRelatedData(queryClient: QueryClient): Promise<IssueViewRelatedData> {
  return Promise.all([
    queryClient.fetchQuery<{ data: IssueAction[] }>(IssueActionsKey, getIssueActions),
    queryClient.fetchQuery(IssueFieldsKey, getIssueFields),
    queryClient.fetchQuery(IssueGroupsKey, getIssueGroups),
    // TODO later on we will also pull user preferences for active issue manager view
    queryClient.fetchQuery<{ data: IssueView[] }>(IssueViewsKey, getIssueViews),
  ]).then(([{ data: actionsData }, { data: fieldsData }, { data: groupData }, { data: viewData }]) => ({
    actions: actionsData,
    fields: fieldsData,
    groups: groupData,
    views: viewData,
  }));
}

export function handleIssueViewsRelatedData(dispatch: AppDispatch, results: IssueViewRelatedData) {
  const { actions, fields, groups, views } = results;

  if (actions && actions.length > 0) {
    dispatch(issueActionsLoaded(actions));
  }

  if (fields && fields.length > 0) {
    dispatch(issueFieldsLoaded(fields));
  }

  if (groups && groups.length > 0) {
    dispatch(issueGroupsLoaded(groups));
  }

  if (views && views.length > 0) {
    dispatch(issueViewsLoaded(views));
  }

  return results;
}

export const IssuesQueryKey = 'issues';
export const IssueQueryKey = 'issue';
export const IssueViewsKey = 'issue-views';
export const IssueFieldsKey = 'issue-fields';
export const IssueActionsKey = 'issue-actions';
export const IssueGroupsKey = 'issue-groups';
export const IssueSummaryKey = 'issue-summary';
