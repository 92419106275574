import Layout, { StandaloneLayout, UnauthenticatedLayout } from '@components/layout/Layout';
import LoginPage from '@components/pages/LoginPage/LoginPage';
import React from 'react';
import ForgotPassword from '@components/pages/ForgotPassword/ForgotPassword';
import { EntityPage } from '@components/pages/EntityPage/EntityPage';
import Overview from '@components/pages/Overview/Overview';
import Support from '@components/pages/Support/Support';
import KeyboardShortcuts from '@components/pages/KeyboardShortcuts/KeyboardShortcuts';
import { DashboardManagement } from '@components/pages/Dashboards/DashboardManagement';
import { DashboardPage } from '@components/pages/Dashboards/DashboardDetail/DashboardPage';
import { Route, Routes, topbar } from 'react-router-loading';
import { WidgetPage } from '@components/pages/WidgetPage/WidgetPage';
import Management from '@components/pages/Management/Management';
import { IssuePage } from '@components/pages/IssuePage';
import colors from '@theme/colors';
import { Router as ReactRouter } from 'react-router';
import { BASE_NAME } from '@config';
import { useAppSelector } from '@infrastructure/redux/store';
import { selectSliceRoot } from '@infrastructure/redux/location';
import { history } from '@services/useHistory';
import Tools from '@components/pages/Tools/Tools';
import ROUTES from '@infrastructure/routes';
import { ChartPage } from '@components/pages/ChartPage/ChartPage';
import Error404Page from './pages/Error404Page';
import {
  AlertPage,
  ScriptRunPage,
  ConfigSearchPage,
  SessionLogPage,
  TerminalPage,
  ConfigDiffPage,
  ConfigManagerPage,
  AlertsPage,
  MetricsPage,
  LogbookPage,
  IssueViewEditPage,
  IssueManagementPage,
  AccountManagement,
  IssueViewManagerPage,
  InventoryPage,
  MWSinglePage,
  MWListPage,
  MWEditPage,
  MWCreatePage,
  ReleaseNotes,
} from './pages';
import { ScriptsPage } from './pages/ScriptsPage/ScriptsPage';
import { AddEditScriptsPage } from './pages/ScriptsPage/AddEditScriptPage';
import { ScriptBatchPage } from './pages/ScriptBatchPage/ScriptBatchPage';

topbar.config({
  barThickness: 4,
  barColors: {
    0: colors.blue.ocean,
    1: colors.blue.ocean,
  },
  shadowBlur: 0,
});

const Router = () => {
  const { action, location } = useAppSelector(selectSliceRoot);

  return (
    <ReactRouter basename={BASE_NAME} location={location} navigationType={action} navigator={history}>
      <Routes maxLoadingTime={5000}>
        <Route
          path="/login/"
          element={
            <UnauthenticatedLayout>
              <LoginPage />
            </UnauthenticatedLayout>
          }
        />
        <Route
          path="/forgot-password/"
          element={
            <UnauthenticatedLayout>
              <ForgotPassword />
            </UnauthenticatedLayout>
          }
        />

        <Route
          path={ROUTES.standaloneTerminal}
          element={
            <StandaloneLayout>
              <TerminalPage isStandalone />
            </StandaloneLayout>
          }
        />

        <Route path="/" element={<Layout />}>
          <Route index element={<Overview />} />
          <Route path={ROUTES.dashboardById} element={<DashboardPage />} loading />
          <Route path={ROUTES.dashboards} element={<DashboardManagement />} loading />
          <Route path={ROUTES.widget} element={<WidgetPage />} loading />
          <Route path="entities/:entityId/" element={<EntityPage />} loading />
          <Route path="inventory/" element={<InventoryPage />} loading />
          <Route path="overview/" element={<Overview />} />
          <Route path="support/" element={<Support />} />
          <Route path="metrics/" element={<MetricsPage />} loading />
          <Route path={ROUTES.alerts} element={<AlertsPage />} loading />
          <Route path="keyboard-shortcuts/" element={<KeyboardShortcuts />} />
          <Route path="issue-manager/" element={<IssueManagementPage />} loading />
          <Route path="issue-manager/:issueId/" element={<IssuePage />} loading />
          <Route path="management/" element={<Management />} />
          <Route path="management/account/" element={<AccountManagement />} />
          <Route path="management/issue-views/" element={<IssueViewManagerPage />} loading />
          <Route path={ROUTES.issueViewEdit} element={<IssueViewEditPage />} loading />
          <Route path={ROUTES.tools} element={<Tools />} />
          <Route path={ROUTES.maintenanceWindows} element={<MWListPage />} loading />
          <Route path={ROUTES.createMaintenanceWindow} element={<MWCreatePage />} loading />
          <Route path={ROUTES.singleMaintenanceWindow} element={<MWSinglePage />} loading />
          <Route path={ROUTES.editMaintenanceWindow} element={<MWEditPage />} loading />
          <Route path={ROUTES.logbook} element={<LogbookPage />} loading />
          <Route path={ROUTES.chart} element={<ChartPage />} loading />
          <Route path={ROUTES.alertById} element={<AlertPage />} loading />
          <Route path={ROUTES.configManager} element={<ConfigManagerPage />} loading />
          <Route path={ROUTES.configDiff} element={<ConfigDiffPage />} loading />
          <Route path={ROUTES.terminal} element={<TerminalPage />} />
          <Route path={ROUTES.sessionLogs} element={<SessionLogPage />} loading />
          <Route path={ROUTES.configSearch} element={<ConfigSearchPage />} loading />
          <Route path={ROUTES.runScript} element={<ScriptRunPage />} />
          <Route path={ROUTES.trackScript} element={<ScriptBatchPage />} />
          <Route path={ROUTES.releaseNotes} element={<ReleaseNotes />} />
          <Route path={ROUTES.scripts} element={<ScriptsPage />} loading />
          <Route path={ROUTES.addScript} element={<AddEditScriptsPage />} />
          <Route path={ROUTES.editScript} element={<AddEditScriptsPage />} loading />
          <Route path="*" element={<Error404Page />} />
        </Route>
      </Routes>
    </ReactRouter>
  );
};

export default Router;
