import { typify } from '@utils/type';
import { ViewState } from 'react-map-gl';

export const LEGACY_VECTOR_TILES = typify({
  STANDARD: 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
  LIGHT: 'https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
  DARK: 'https://cartodb-basemaps-a.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
  // WATERCOLOR: 'http://c.tile.stamen.com/watercolor/{z}/{x}/{y}.jpg',
  MONOCHROME: 'http://a.tile.stamen.com/toner/{z}/{x}/{y}.png',
  HUMANITARIAN: 'http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
  MAPTILER: `https://api.maptiler.com/maps/7b52e352-b631-4278-a535-3c50b97bbd41/{z}/{x}/{y}.png?key=:mapTilerKey`,
  MAPTILER_HIDPI: `https://api.maptiler.com/maps/7b52e352-b631-4278-a535-3c50b97bbd41/{z}/{x}/{y}@2x.png?key=:mapTilerKey`,
});

export const VECTOR_TILES = {
  LIGHT: 'https://api.maptiler.com/maps/41711ff7-a4fb-4fbb-ad57-25fcbca7dde6/{z}/{x}/{y}.png?key=:mapTilerKey',
  DARK: 'https://api.maptiler.com/maps/56c5c66a-25cb-4a24-bcc1-d10c7891676a/{z}/{x}/{y}.png?key=:mapTilerKey',
} as const;

export type MapMode = keyof typeof VECTOR_TILES;

export type MapboxRef = mapboxgl.Map | maplibregl.Map | null;

export type Viewport = Pick<ViewState, 'zoom' | 'latitude' | 'longitude'>;

export type FQDNId = {
  id: string;
  source: string;
  sourceLayer: string;
};
