import useTextSnippets from '@services/useTextSnippets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import {
  DataSource,
  setDefaultConfig,
  updateSubConfig,
  WidgetConfig,
  WidgetType,
  WidgetWithSubtypesConfig,
  ChartWidgetSubConfig,
  selectWidgetPage,
  WidgetWithSource,
  updateName,
} from '@redux/widgetPage';
import React, { FC } from 'react';
import WidgetList from '@components/pages/WidgetPage/components/WidgetList';
import withConfirmation from '@services/withConfirmation';
import HorizontalTitleSeparator from '@components/separators/HorizontalTitleSeparator/HorizontalTitleSeparator';
import { SubTypesSection } from './SubTypesSection';
import * as widgetSetups from '@components/widgets';
import { WidgetSetup } from '@components/pages/WidgetPage/types';
import { normalizeLegacySource } from '@utils/chart';

import styles from './ConfigurationTab.module.scss';

const getFullType = (config: WidgetConfig): string => {
  switch (config.type) {
    case 'timeseries':
      return `${config.type}:${(config.config as ChartWidgetSubConfig).type ?? 'line'}`;

    default:
      return config.type ?? 'timeseries';
  }
};

export type ConfigurationTabProps = {
  widgetConfig: WidgetConfig;
};

export const ConfigurationTab: FC<ConfigurationTabProps> = withConfirmation(({ widgetConfig, confirmOperation }) => {
  const i18n = useTextSnippets('widgetPage');
  const { isDirty } = useAppSelector(selectWidgetPage);
  const dispatch = useAppDispatch();

  const isEditing = !!widgetConfig?.id;

  const setup: WidgetSetup = widgetSetups[widgetConfig?.type];

  const handleWidgetTypeClick = (id: string) => {
    const [widgetType, extension] = id.split(':');

    if (isDirty) {
      void confirmOperation({
        title: i18n.areYouSure,
        contents: i18n.configOverwriteWarning,
      }).then((result: boolean) => {
        if (result) {
          dispatch(
            setDefaultConfig({
              type: widgetType as WidgetType,
              subType: extension,
            })
          );
        }
      });
    } else {
      dispatch(
        setDefaultConfig({
          type: widgetType as WidgetType,
          subType: extension,
        })
      );
    }
  };

  const handleWidgetSubTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [, extension] = e.target.value.split(':');
    dispatch(updateSubConfig({ type: extension } as any));
  };

  const handleDataSourceChange = (newSource?: DataSource | null) => {
    dispatch(updateSubConfig({ source: newSource }));
  };

  const handleNameChange = (newName: string | null) => {
    if (!isEditing) {
      dispatch(updateName(newName ?? ''));
    }
  };

  return (
    <div className={styles.configurationTab}>
      <HorizontalTitleSeparator title={i18n.widgetType} variant="copy-4" additionalClass={styles.sidebarSeparator} />

      <WidgetList
        onItemClick={handleWidgetTypeClick}
        shortcutListProps={{ variant: 'buttonLike' }}
        activeShortcut={getFullType(widgetConfig)}
      />

      {!!setup.subTypes?.length && (
        <SubTypesSection
          type={widgetConfig?.type}
          subTypes={setup.subTypes}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          value={(widgetConfig?.config as WidgetWithSubtypesConfig)?.type ?? ''}
          onChange={handleWidgetSubTypeChange}
        />
      )}

      {setup.DataSourcePicker && (
        <>
          <HorizontalTitleSeparator
            title={i18n.dataSource}
            variant="copy-4"
            additionalClass={styles.sidebarSeparator}
          />
          <setup.DataSourcePicker
            onChange={handleDataSourceChange}
            source={normalizeLegacySource((widgetConfig?.config as WidgetWithSource).source)}
            onNameChange={handleNameChange}
          />
        </>
      )}
    </div>
  );
});
