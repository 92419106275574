import React, { useEffect, useState } from 'react';
import c from 'classnames';
import Icon from '@components/common/Icon';
import useTextSnippets from '@services/useTextSnippets';
import { useAppSelector } from '@infrastructure/redux/store';
import { selectQueryParams, selectActiveIssueKey } from '@redux/issues';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
import { selectSidebarOpen } from '@redux/layout';
import { useBreakpoint } from '@services';
import Button from '@components/common/Button';
import {
  InventoryDownloadPopup,
  InventoryDownloadPopupProps,
} from '@components/pages/Inventory/components/InventoryDownloadPopup';

import styles from './InventoryHeader.module.scss';

export const InventoryHeader = () => {
  const [minimalMode, setMinimalMode] = useState(false);
  const [downloadPopupProps, setDownloadPopupProps] = useState<InventoryDownloadPopupProps | null>(null);

  const {
    breakpoints: { isXl, isXxl },
  } = useBreakpoint();

  const i18n = useTextSnippets('inventory');
  const commonI18n = useTextSnippets('common');

  const sidebarOpen = useAppSelector(selectSidebarOpen);
  const activeIssueKey = useAppSelector(selectActiveIssueKey);
  const queryParams = useAppSelector(selectQueryParams);

  const handleDownloadClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDownloadPopupProps({
      params: queryParams,
      target: e.currentTarget,
    });
  };

  useEffect(() => {
    setMinimalMode(Boolean(!isXl || (!isXxl && sidebarOpen && activeIssueKey)));
  }, [isXl, isXxl, sidebarOpen, activeIssueKey]);

  return (
    <div className={c(styles.inventoryHeader, minimalMode && styles.minimalHeader)}>
      <div className="flex items-center mb-16">
        <Headline variant="headline-6" additionalClass={styles.pageTitle}>
          {i18n.inventory}
        </Headline>

        <div className="ml-auto">
          <Button variant="outline" onClick={handleDownloadClick} ariaLabel={commonI18n.download} size="s">
            <div className="hbox">
              <Icon name="Download" size="s" additionalClass="mobileLandscape:mr-4" />
              <CopyText variant="copy-5" additionalClass="hidden mobileLandscape:inline-block">
                {commonI18n.download}
              </CopyText>
            </div>
          </Button>
        </div>
      </div>

      {downloadPopupProps && (
        <InventoryDownloadPopup isOpen {...downloadPopupProps} onClose={() => setDownloadPopupProps(null)} />
      )}
    </div>
  );
};
