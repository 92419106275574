import { array, object, string, TypeOf } from 'yup';

import { ITextSnippets } from '@services/useTextSnippets';
import { useMemo } from 'react';

const useSchema = (commentRequired: boolean, i18nKeys: ITextSnippets['comment']) => {
  const schema = object({
    comment: commentRequired ? string().required(i18nKeys.commentIsRequired) : string(),
    issueIds: array().of(string()),
    relevantData: string(),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, [commentRequired, i18nKeys]);
};

export interface ActionSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
