import { IItemRendererProps } from '@blueprintjs/select';
import React, { FC } from 'react';
import { MenuItem } from '@components/common/Menu';
import {
  BaseSelect,
  BaseSelectInputProps,
  BaseSelectItem,
  BaseSelectProps,
} from '@components/common/form/Select/BaseSelect';
import c from 'classnames';
import { Palette } from './Palette';
import { PalettePreset } from './paletteUtils';

import styles from './Palette.module.scss';
import inputStyles from '@components/common/form/Input/Input.module.scss';

const PalettePickerInput = ({
  value,
  disabled,
  actionIcon,
  additionalClasses,
  defaultInputComponent,
}: BaseSelectInputProps): React.ReactElement | null => {
  return value ? (
    <div
      className={c(
        styles.pickerInput,
        inputStyles.input,
        disabled && styles.disabled,
        disabled && inputStyles.disabled,
        additionalClasses?.input
      )}
    >
      <Palette preset={value as PalettePreset} showTitle={false} additionalClass={styles.pickerValue} />
      <span className={inputStyles.actionIcon}>{actionIcon}</span>
    </div>
  ) : (
    defaultInputComponent ?? null
  );
};

export type PalettePickerProps = Omit<BaseSelectProps, 'optionRenderer'>;

export const PalettePicker: FC<PalettePickerProps> = ({ options = [], ...props }) => {
  const optionRenderer = (option: BaseSelectItem, { handleClick, modifiers }: IItemRendererProps) => {
    return (
      <MenuItem
        key={option.value}
        active={modifiers.active}
        disabled={modifiers.disabled}
        onClick={handleClick}
        text={<Palette preset={option.value as PalettePreset} showTitle />}
        additionalClass={styles.menuItem}
      />
    );
  };

  return (
    <div className={styles.pickerContainer}>
      <BaseSelect
        options={options}
        {...props}
        optionRenderer={optionRenderer}
        inputComponent={<PalettePickerInput />}
        fullWidth
      />
    </div>
  );
};
