import { Box } from '@components/common/Box';
import CopyText from '@components/typography/CopyText';
import { MaintenanceWindow } from '@infrastructure/api/BaseNClient/useMaintenanceWindowsQuery';
import { trimStart } from 'lodash';
import React, { useCallback } from 'react';
import c from 'classnames';
import Headline from '@components/typography/Headline';
import Button from '@components/common/Button';
import { ScheduleItem } from '@components/pages/MWCreatePage/components/ScheduleItem';
import { fromBaseNFormat } from '@utils/mw';

import styles from './MWSummary.module.scss';

type MWSummaryProps = {
  mw: Omit<MaintenanceWindow, 'id'>;
  additionalClass?: string;
  onScheduleEdit?: (idx: number) => void;
  onScheduleRemove?: (idx: number) => void;
};

export const MWSummary: React.FC<MWSummaryProps> = ({ mw, additionalClass, onScheduleEdit, onScheduleRemove }) => {
  const [showTargets, setShowTargets] = React.useState(false);

  const handleTargetsToggle = useCallback(() => {
    setShowTargets(state => !state);
  }, []);

  return (
    <div className={additionalClass}>
      <Box header="General" additionalClass="mb-16">
        <div className={styles.general}>
          <CopyText variant="copy-4" additionalClass="text-blue-gray-base">
            Name
          </CopyText>
          <CopyText variant="copy-4">{mw.name}</CopyText>

          <CopyText variant="copy-4" additionalClass="text-blue-gray-base">
            Reason
          </CopyText>
          <CopyText variant="copy-4">{mw.reason}</CopyText>

          {mw.description && (
            <>
              <CopyText variant="copy-4" additionalClass="text-blue-gray-base">
                Description
              </CopyText>
              <CopyText variant="copy-4">{mw.description}</CopyText>
            </>
          )}
        </div>
      </Box>

      {!!mw.times?.length && (
        <Box header="Schedules" additionalClass={c(styles.schedules, 'mb-16')}>
          {mw.times?.map((schedule: any, idx: number) => (
            <ScheduleItem
              schedule={fromBaseNFormat(schedule)}
              key={idx}
              onEdit={() => onScheduleEdit?.(idx)}
              onRemove={() => onScheduleRemove?.(idx)}
              isEditable={!!onScheduleEdit}
              isRemovable={!!onScheduleRemove}
            />
          ))}
        </Box>
      )}

      {(!!mw.entityIds?.length || mw.global) && (
        <Box header="Targets" additionalClass="mb-16">
          {mw.global ? (
            <CopyText variant="copy-4">All entities selected</CopyText>
          ) : (
            <>
              <CopyText variant="copy-4">
                <span className="mr-8">{`${mw.entityIds?.length} ${
                  mw.entityIds?.length === 1 ? 'entity' : 'entities'
                } selected`}</span>
                <Button variant="link" size="s" onClick={handleTargetsToggle}>{`${
                  showTargets ? 'Hide all' : 'Show all'
                }`}</Button>
              </CopyText>

              {showTargets && (
                <div className={styles.targets}>
                  {mw.entityNames?.map(name => (
                    <CopyText variant="copy-6" key={name}>
                      {name}
                    </CopyText>
                  ))}
                </div>
              )}
            </>
          )}
        </Box>
      )}

      {!!mw.modifications?.length && (
        <>
          <Headline variant="headline-6" additionalClass="mb-16">
            Last modifications
          </Headline>
          <Box additionalClass={c(styles.modifications, 'mb-16')}>
            <div className={styles.general}>
              {mw.modifications.map((modification: any, index: number) => {
                const [timestamp, text] = modification.split(/\]\s+/);
                return (
                  <React.Fragment key={index}>
                    <CopyText variant="copy-4">{trimStart(timestamp, '[')}</CopyText>
                    <CopyText variant="copy-4">{text}</CopyText>
                  </React.Fragment>
                );
              })}
            </div>
          </Box>
        </>
      )}
    </div>
  );
};
