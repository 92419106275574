import {
  DEFAULT_TABLE_CONFIG,
  Sort,
  RemoteStoredTable,
  TableColumn,
  TableColumns,
  RowHeight,
} from '@components/common/Table';
import { AlertsIndicator } from '@components/pages/ChartPage/components/AlertsIndicator';
import { EntityLink } from '@components/pages/EntityPage/components/EntityLink';
import {
  EntitiesResponse,
  EntityWithAlerts,
  useEntitiesWithAlertsQuery,
} from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import { EntityDetails } from '@redux/inventoryPage';
import { getEntityProperty } from '@utils/entity';
import { toIdMap } from '@utils/misc';
import React, { useMemo, useState } from 'react';

const getTableColumns = (): TableColumns<EntityWithAlerts> => {
  return toIdMap<TableColumn<EntityWithAlerts>>([
    {
      id: 'type',
      label: 'Type',
      sortable: false,
      valueRenderer: (item: EntityWithAlerts) => getEntityProperty(item, 'entity_type'),
    },
    {
      id: 'name',
      label: 'Entity',
      sortable: true,
      valueRenderer: (item: EntityWithAlerts) => <EntityLink entity={item} />,
    },
    {
      id: 'description',
      label: 'Description',
      sortable: false,
      valueRenderer: (item: EntityWithAlerts) => getEntityProperty(item, 'entity_descr'),
    },
    {
      id: 'serial',
      label: 'Serial',
      sortable: false,
      valueRenderer: (item: EntityWithAlerts) => getEntityProperty(item, 'sys_serial'),
    },
    {
      id: 'health',
      label: 'Health',
      sortable: false,
      valueRenderer: (item: EntityWithAlerts) => (item.alerts ? <AlertsIndicator alerts={item.alerts} /> : null),
    },
  ]);
};

export const DevicesTable: React.FC<{ entity?: EntityDetails }> = ({ entity }) => {
  const {
    page: initialPage,
    rowsPerPage: initialRowsPerPage,
    sortable,
    paginated,
    ...otherConfig
  } = DEFAULT_TABLE_CONFIG;

  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [sort, setSort] = useState<Sort>('asc' as Sort);
  const [sortBy, setSortBy] = useState<string | null>('name');
  const [rowHeight, setRowHeight] = useState<RowHeight>('medium');

  const entitiesQuery = useEntitiesWithAlertsQuery<EntitiesResponse<EntityWithAlerts>>(
    {
      parent: entity?.id,
      'data.type': '^(^!net.if)$',
      details: true,
      start_index: (page - 1) * rowsPerPage,
      end_index: (page - 1) * rowsPerPage + rowsPerPage,
      order: sort && sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : undefined,
    },
    {
      keepPreviousData: true,
      enabled: !!entity?.id,
    }
  );

  const handlePageChange = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleSortChange = React.useCallback((property: string | null, order: Sort) => {
    setSort(order);
    setSortBy(property);
  }, []);

  const handleRowsPerPageChange = React.useCallback((newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  }, []);

  const handleRowHeightChange = React.useCallback((newRowHeight: RowHeight) => {
    setRowHeight(newRowHeight);
  }, []);

  const columns = useMemo(() => getTableColumns(), []);

  const tableConfig = useMemo(
    () => ({
      ...otherConfig,
      sortable: true,
      columnsToggleable: true,
      rowHeightToggleable: true,
      hasToolbar: true,
      paginated: true,
      selectable: false,
      page,
      rowCount: entitiesQuery.data?.total ?? 0,
      sort,
      sortBy,
      rowsPerPage,
      rowHeight,
    }),
    [otherConfig, page, entitiesQuery.data?.total, sort, sortBy, rowsPerPage, rowHeight]
  );

  return (
    <RemoteStoredTable<EntityWithAlerts>
      name="devices-tables"
      columns={columns}
      items={entitiesQuery.data?.entities ?? []}
      config={tableConfig}
      onRowsPerPageChange={handleRowsPerPageChange}
      onPageChange={handlePageChange}
      onSortChange={handleSortChange}
      onRowHeightChange={handleRowHeightChange}
      isLoading={entitiesQuery.isLoading}
    />
  );
};
