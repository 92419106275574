import React from 'react';
import { MobileDrawerMenu, Menu, MenuItem } from '@components/common/Menu';
import { IssueViewAction } from '@infrastructure/redux/issues';

import styles from './MoreMenu.module.scss';

type Props = {
  actions: IssueViewAction[];
  onAction: (action: IssueViewAction) => void;
};

const MoreMenu: React.FC<Props> = ({ actions, onAction }) => {
  return (
    <MobileDrawerMenu>
      <Menu key="menu" className={styles.moreMenu}>
        {actions.map(action => (
          <MenuItem text={action.label} onClick={() => onAction(action)} />
        ))}
      </Menu>
    </MobileDrawerMenu>
  );
};

export default MoreMenu;
