import { DateRange } from '@blueprintjs/datetime';
import { WizardStepProps, useWizardHandle } from '@components/common/form/Wizard';
import SwitchBase from '@components/common/SwitchBase/SwitchBase';
import { useCallback, useRef, useState } from 'react';
import z from 'zod';
import { AddScheduleButton } from './AddScheduleButton';
import { ScheduleItem } from './ScheduleItem';

export const SchedulesStep: React.FC<WizardStepProps> = ({
  initialValues,
  validationSchema,
  onSubmit,
  onChange,
  innerRef,
}) => {
  const timesRef = useRef(initialValues.times ?? []);

  const [activeItemIdx, setActiveItemIdx] = useState(-1);

  useWizardHandle(innerRef, onSubmit);

  const handleAdd = useCallback(
    (schedule: string | DateRange) => {
      timesRef.current.push(schedule);
      onChange?.({ times: timesRef.current });
      setActiveItemIdx(-1);
    },
    [onChange]
  );

  const handleEdit = useCallback(
    (idx: number, schedule: string | DateRange) => {
      timesRef.current[idx] = schedule;
      onChange?.({ times: timesRef.current });
      setActiveItemIdx(-1);
    },
    [onChange]
  );

  const handleRemove = useCallback(
    (idx: number) => {
      timesRef.current.splice(idx, 1);
      onChange?.({ times: timesRef.current });
      setActiveItemIdx(-1);
    },
    [onChange]
  );

  const handleToggle = useCallback((e: any, idx: number) => {
    setActiveItemIdx(oldIdx => (oldIdx === idx ? -1 : idx));
  }, []);

  return (
    <>
      {!!timesRef.current?.length ? (
        <SwitchBase<any, any> exclusive onChange={handleToggle} additionalClass="flex flex-col gap-8 mb-16">
          {timesRef.current.map((schedule: string | DateRange, idx: number) => (
            <ScheduleItem
              key={idx}
              schedule={schedule}
              onEdit={(schedule: string | DateRange) => handleEdit(idx, schedule)}
              onRemove={() => handleRemove(idx)}
              active={idx === activeItemIdx}
              // recurring is not supported yet TODO: remove once it is
              isEditable={typeof schedule !== 'string'}
            />
          ))}
          <AddScheduleButton onAdd={handleAdd} active={activeItemIdx === timesRef.current.length} />
        </SwitchBase>
      ) : (
        <AddScheduleButton onAdd={handleAdd} active />
      )}
    </>
  );
};

SchedulesStep.defaultProps = {
  validationSchema: z.object({
    times: z.array(z.array(z.date()).or(z.string())),
  }),
  initialValues: {
    times: [],
  },
};
