import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import Router from './Router';
import { dismissNotification, selectNotifications } from '@infrastructure/redux/notifications';
import { Toast, Toaster } from './common/Toast';

export const Main = () => {
  const notifications = useAppSelector(selectNotifications);
  const dispatch = useAppDispatch();

  return (
    <>
      <Router />

      <Toaster>
        {notifications.map(n => (
          <Toast key={n.id} {...n} onClose={() => dispatch(dismissNotification(n))} />
        ))}
      </Toaster>
    </>
  );
};
