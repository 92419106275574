/* eslint-disable no-console */
import { IS_DEV, IS_LOCAL } from '@config';
import * as Sentry from '@sentry/react';

const log = (severity: 'info' | 'warn' | 'error', message: string | Error, extra?: Dictionary<any>) => {
  if (IS_DEV || IS_LOCAL) {
    console[severity](message, extra);
  } else if (severity === 'error') {
    Sentry.captureException(message, { extra });
  } else {
    Sentry.captureMessage(message as string, { extra });
  }
};

const info = (message: string, extra?: Dictionary<any>) => {
  log('info', message, extra);
};

const warn = (message: string, extra?: Dictionary<any>) => {
  log('warn', message, extra);
};

const error = (error: Error | string, extra?: Dictionary<any>) => {
  log('error', typeof error === 'string' ? new Error(error) : error, extra);
};

export default { info, warn, error };
