import { useMutation, UseMutationResult } from 'react-query';
import { WidgetConfig } from '@redux/widgetPage';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';

export type WidgetMutationSuccess = {
  result: 'ok';
  id: string;
};

export type WidgetMutationError = {
  result: 'error';
  code: number;
  message: string;
};

export type WidgetCreateRequest = WidgetConfig;

export const useCreateWidgetMutation = <
  S extends WidgetMutationSuccess = WidgetMutationSuccess,
  F extends WidgetMutationError = WidgetMutationError,
  V extends WidgetCreateRequest = WidgetCreateRequest
>(
  mutationOptions = {}
): UseMutationResult<S, F, V> =>
  useMutation<S, F, V>(
    (body: V): Promise<S> =>
      apiClient.post(ENDPOINTS.postWidget, {
        name: body.name,
        config: body,
      }),
    mutationOptions
  );
