import LoadingSpinner from '@components/layout/LoadingSpinner';
import { EntityInfoGrid, EntityInfoGridProps } from '@components/pages/Inventory/components/EntityInfoGrid';
import CopyText from '@components/typography/CopyText';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';

type EntityInfoWrapperProps = EntityInfoGridProps & {
  entityId: string;
};

export const EntityInfoWrapper = ({ entityId }: EntityInfoWrapperProps) => {
  const entity = useEntityQuery({ entityId, alerts: true, include_maintenance: true });
  const parentEntity = useEntityQuery({ entityId: entity.data?.parent }, { enabled: !!entity.data?.parent });

  if (entity.isLoading) {
    return <LoadingSpinner centered />;
  }

  if (!entity.data) {
    return null;
  }

  return (
    <>
      <CopyText variant="copy-3" additionalClass="mb-8 ml-16">
        Entity Details
      </CopyText>
      <EntityInfoGrid entity={entity.data} parentEntity={parentEntity.data} />
    </>
  );
};
