import { apiClient } from '@infrastructure/api';
import logger from '@infrastructure/logging/logger';
import { isZodError, isZodSchema, zodErrorToString } from '@utils/type';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

export type UseValidatedQueryOptions<S = unknown, F = unknown> = Omit<
  UseQueryOptions<any, F, S>,
  'queryKey' | 'queryFn'
> & {
  httpMethod?: 'get' | 'post' | 'put' | 'delete';
};

export function useValidatedQuery<S = unknown, F = unknown>(
  endpoint: string,
  params: Record<string, any> = {},
  schema?: any,
  { httpMethod = 'get', ...options }: UseValidatedQueryOptions<S, F> = {}
): UseQueryResult<S, F> {
  return useQuery<S, F>({
    queryKey: [endpoint, params],
    queryFn: () =>
      apiClient[httpMethod]<S>(endpoint, params).then(response => {
        if (!schema) {
          return response;
        }

        try {
          return isZodSchema(schema)
            ? schema.parse(response)
            : schema.validateSync(schema.cast(response), {
                abortEarly: true,
                stripUnknown: true,
                strict: false,
              });
        } catch (err: any) {
          const message = `GET ${endpoint}: ${isZodError(err) ? zodErrorToString(err) : err.message}`;
          logger.error(message);
          throw new Error(message);
        }
      }),
    ...options,
  });
}
