import React, { ReactNode } from 'react';
import c from 'classnames';
import { Classes } from '@blueprintjs/core';
import Link, { LinkProps } from '@components/common/Link';
import Icon, { IconNames } from '@components/common/Icon';

import styles from './Breadcrumbs.module.scss';

export interface BreadcrumbProps extends Omit<LinkProps, 'href'> {
  /** Whether this breadcrumb is the current breadcrumb. */
  current?: boolean;
  icon?: IconNames;
  label?: string | ReactNode;
  href?: string;
}

export const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = ({
  current,
  disabled,
  href,
  label,
  icon,
  additionalClass,
  onClick,
}) => {
  const classes = c(
    styles.breadcrumb,
    Classes.BREADCRUMB,
    current && Classes.BREADCRUMB_CURRENT,
    disabled && Classes.DISABLED,
    additionalClass
  );

  const labelIcon = icon != null ? <Icon name={icon} /> : undefined;

  const innerContent = (
    <span className={styles.label}>
      {labelIcon}
      {label}
    </span>
  );

  if (!href) {
    return innerContent;
  }

  return (
    <>
      <Link
        additionalClass={classes}
        href={href}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        tabIndex={disabled ? undefined : 0}
      >
        {innerContent}
      </Link>
      {!current && <Icon name="ArrowRight" size="s" additionalClass={styles.separator} />}
    </>
  );
};
