import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import { AppDispatch, useAppDispatch } from '@infrastructure/redux/store';
import { IssueView, CreateIssueView, addView } from '@redux/issues';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';

export function onCreateIssueViewSuccess(
  dispatch: AppDispatch,
  response: IssueView,
  data: CreateIssueView,
  context?: any
): void;
export function onCreateIssueViewSuccess(dispatch: AppDispatch, response: IssueView, data: CreateIssueView) {
  dispatch(addView({ ...data, id: response.id }));
}

export type CreateIssueViewMutationSettings = { noDefaultSuccess?: boolean };

export const useCreateIssueViewMutation = <
  T extends IssueView = IssueView,
  P extends CreateIssueView = CreateIssueView
>(
  { noDefaultSuccess }: CreateIssueViewMutationSettings = { noDefaultSuccess: false },
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> => {
  const dispatch = useAppDispatch();

  return useMutation<T, Error, P>(
    (params): Promise<T> => apiClient.post<T, CreateIssueView>(ENDPOINTS.createIssueView, params),
    {
      ...mutationOptions,
      onSuccess: (...successParams) => {
        if (!noDefaultSuccess) {
          onCreateIssueViewSuccess(dispatch, ...successParams);
        }

        void mutationOptions.onSuccess?.(...successParams);
      },
    }
  );
};
