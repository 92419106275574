import { useAppDispatch } from '@infrastructure/redux/store';
import { updateSubConfig, WidgetConfig, WidgetSubConfig } from '@redux/widgetPage';
import React from 'react';
import { ConfigEditor } from '../ConfigEditor/ConfigEditor';

export const ConfigEditorTab = ({ widgetConfig }: { widgetConfig: WidgetConfig }) => {
  const dispatch = useAppDispatch();

  const handleCodeChange = (newConfig: WidgetSubConfig) => {
    dispatch(updateSubConfig(newConfig));
  };

  return widgetConfig ? (
    <ConfigEditor key={widgetConfig.id} defaultConfig={widgetConfig.config} onChange={handleCodeChange} />
  ) : null;
};
