import englishTextSnippets from '@infrastructure/translations/languages/english';
import { useMemo } from 'react'; // for now use this as reference
// import { useLocale } from '../locale';

export type ITextSnippets = typeof englishTextSnippets;

function useTextSnippets<T extends keyof ITextSnippets>(key: T): ITextSnippets[T];
function useTextSnippets(): ITextSnippets;

function useTextSnippets<T extends keyof ITextSnippets>(key: T | undefined = undefined) {
  const data = useMemo(() => englishTextSnippets, []);

  const returnValue = useMemo(() => (key ? data[key] : data), [data, key]);

  return returnValue;
}

export default useTextSnippets;
