import { UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '../query-configs';
import { useValidatedQuery } from '@services/useValidatedQuery';
import ENDPOINTS from '../endpoints';
import { MaintenanceWindow, mwSchema } from './useMaintenanceWindowsQuery';

export const useMaintenanceWindowsQueryById = <T extends MaintenanceWindow = MaintenanceWindow>(
  id: string,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.maintenanceWindowById.replace(':id', id), {}, mwSchema, {
    ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
    ...queryOptions,
  });
};
