import { Box } from '@components/common/Box';
import c from 'classnames';
import { DEFAULT_FILTERS, RemoteStoredTable, Sort } from '@components/common/Table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getFiltersConfig, getTableColumns } from '@components/pages/Inventory/utils';
import { Filters, PaginationParams, SortingParams } from '@infrastructure/redux/types';
import { EntitiesRequest, EntitiesResponse, useEntitiesQuery } from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { generateParams } from '@infrastructure/redux/inventoryPage/utils';
import Icon from '@components/common/Icon';
import Button from '@components/common/Button';
import CopyText from '@components/typography/CopyText';
import { getEntityProperty } from '@utils/entity';
import IconButton from '@components/common/IconButton';
import useTextSnippets from '@services/useTextSnippets';
import { ToggleSwitch } from '@components/common/form/ToggleSwitch';
import { toIdMap } from '@utils/misc';

import styles from './TargetsTable.module.scss';

type TargetsTableProps = {
  routingEnabled?: boolean;
  selectedTargets?: EntityDetails[];
  onSelectTargets?: (newSelection: EntityDetails[]) => void;
  onRoutingChange?: (newState: boolean) => void;
  queryParams?: Partial<EntitiesRequest>;
};

export const TargetsTable: React.FC<TargetsTableProps> = ({
  routingEnabled = false,
  selectedTargets: selectedItems = [],
  onSelectTargets,
  onRoutingChange,
  queryParams,
}) => {
  const i18n = useTextSnippets('inventory');

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [filters, setFilters] = useState<Filters | undefined>();
  const [sorting, setSorting] = useState<SortingParams>();
  const [pagination, setPagination] = useState<PaginationParams>({ page: 1, rowsPerPage: 10 });

  const query = useEntitiesQuery<EntitiesResponse<EntityDetails>>(
    {
      ...generateParams(filters ?? DEFAULT_FILTERS, pagination, sorting),
      only_parent: true,
      details: true,
      ...queryParams,
    },
    {
      keepPreviousData: true,
    }
  );

  const itemsMap = useMemo(() => toIdMap<EntityDetails>(query.data?.entities ?? []), [query.data?.entities]);

  useEffect(() => {
    if (query.data?.entities) {
      setPagination(oldPagination => ({ ...oldPagination, rowCount: query.data.total }));
    }
  }, [query.data]);

  const handleDetailsToggle = useCallback(() => {
    setIsDetailsOpen(oldIsDetailsOpen => !oldIsDetailsOpen);
  }, []);

  const handlePageChange = useCallback((newPage: number) => {
    setPagination(oldPagination => ({ ...oldPagination, page: newPage }));
  }, []);

  const handleSortChange = useCallback((sortBy: string | null, sort: Sort) => {
    setSorting({ sortBy: sortBy as SortingParams['sortBy'], sort });
  }, []);

  const handleRowsPerPageChange = useCallback((newRowsPerPage: number) => {
    setPagination(oldPagination => ({ ...oldPagination, rowsPerPage: newRowsPerPage }));
  }, []);

  const handleFiltersChange = useCallback((newFilters: Dictionary<any>) => {
    setFilters((oldFilters?: Filters) => ({ generalFilter: '', ...oldFilters, discreteFilters: newFilters }));
    setPagination(oldPagination => ({ ...oldPagination, page: 1 }));
  }, []);

  const handleSearch = useCallback((keyword: string = '') => {
    setFilters((oldFilters?: Filters) => ({ discreteFilters: {}, ...oldFilters, generalFilter: keyword }));
    setPagination(oldPagination => ({ ...oldPagination, page: 1 }));
  }, []);

  const columns = useMemo(() => getTableColumns(filters ?? DEFAULT_FILTERS, i18n), [i18n, filters]);

  const filterConfig = useMemo(() => getFiltersConfig(filters), [filters]);

  const tableConfig = useMemo(
    () => ({
      ...pagination,
      sort: sorting?.sort,
      sortBy: sorting?.sortBy,
      sortable: true,
    }),
    [pagination, sorting?.sort, sorting?.sortBy]
  );

  return (
    <>
      <div className="w-full mb-12 hbox">
        <CopyText variant="copy-2">Select your targets</CopyText>
        {!!onRoutingChange && (
          <ToggleSwitch
            label="Routing information"
            helperText="Routing maintenance windows include devices based on routing information."
            onChange={(e, checked) => onRoutingChange?.(!!checked)}
            checked={!!routingEnabled}
            size="s"
            additionalClass="ml-auto"
          />
        )}
      </div>
      <div className={styles.targetsTable}>
        <Box header="All entities" additionalClass={c(styles.table, isDetailsOpen && styles.isDetailsOpen)}>
          <RemoteStoredTable
            name="mw-entities"
            items={query.data?.entities ?? []}
            selectedItems={selectedItems}
            columns={columns}
            onRowsPerPageChange={handleRowsPerPageChange}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            onSearch={handleSearch}
            onFiltersChange={handleFiltersChange}
            onSelect={onSelectTargets}
            filterConfig={filterConfig}
            keyword={filters?.generalFilter}
            config={tableConfig}
            isLoading={query.isLoading || query.isFetching}
          />

          <Button variant="fillBlueDark" additionalClass={styles.detailsToggler} size="s" onClick={handleDetailsToggle}>
            <Icon name={isDetailsOpen ? 'ArrowRightDouble' : 'ArrowLeftDouble'} />
          </Button>
        </Box>

        {isDetailsOpen && (
          <Box
            header={
              <div className="flex flex-row items-center w-full">
                <CopyText variant="copy-2">Added entities</CopyText>
                <span className={c(styles.link, 'ml-auto')} onClick={() => onSelectTargets?.([])}>
                  Remove all
                </span>
              </div>
            }
            additionalClass={styles.details}
          >
            {selectedItems.length > 0 ? (
              selectedItems
                // API responds with ids only, so if possible we attempt to convert them to full entities
                .map((item: { id: string; name: string }) => itemsMap[item.id] ?? item)
                .map((item: EntityDetails) => {
                  const description =
                    getEntityProperty(item, 'description') ??
                    getEntityProperty(item, 'if_ipv4_address_list') ??
                    getEntityProperty(item, 'if_ipv6_address_list');

                  return (
                    <div key={item.id} className={styles.selectedItem}>
                      <CopyText variant="copy-3" additionalClass="truncate">
                        {item.name}
                      </CopyText>
                      {description && (
                        <CopyText variant="copy-8" additionalClass="text-blue-gray">
                          {description}
                        </CopyText>
                      )}

                      <IconButton
                        icon="Close"
                        onClick={() =>
                          onSelectTargets?.(
                            selectedItems.filter((selectedItem: EntityDetails) => selectedItem.id !== item.id)
                          )
                        }
                        additionalClass={styles.closeButton}
                        size="xs"
                      />
                    </div>
                  );
                })
            ) : (
              <div>No items selected</div>
            )}
          </Box>
        )}
      </div>
    </>
  );
};
