import { DEFAULT_TABLE_CONFIG, RemoteStoredTable, RowHeight, Sort } from '@components/common/Table';
import { EntitiesResponse, useEntitiesQuery } from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import { Alert, useAlertsQuery } from '@infrastructure/api/BaseNClient/useAlertsQuery';
import { EntityDetails } from '@redux/inventoryPage';
import React, { useMemo, useState } from 'react';
import { formatValue } from '@utils/chart';
import { StripeColor, valueToColor, colorToRange } from '@utils/issue';
import { toIdMap } from '@utils/misc';
import { StatusDot } from '@components/common/StatusDot/StatusDot';
import { TableColumns, TableColumn } from '@components/common/Table/types';
import CopyText from '@components/typography/CopyText';
import ROUTES from '@infrastructure/routes';
import { Interface, InterfaceAlertName } from './types';
import Link from '@components/common/Link';
import Tooltip from '@components/common/Tooltip';
import { maxBy } from 'lodash';
import { Badge } from '@components/common/Badge';
import Icon from '@components/common/Icon';
import c from 'classnames';
import useDebouncedValue from '@services/useDebouncedValue';
import { getEntityProperty } from '@utils/entity';

const getTableColumns = (isNetSwitch: boolean = false): TableColumns<Interface> => {
  return toIdMap<TableColumn<Interface>>(
    [
      {
        id: 'status',
        label: 'Status',
        width: 100,
        align: 'center',
        sortable: false,
        valueRenderer: (item: Interface) => {
          const status = item.alerts['if oper:status']?.info.status as StripeColor;

          return status ? (
            <Badge variant={status}>{status === 'red' ? 'Down' : 'Up'}</Badge>
          ) : (
            <Badge variant="gray">No data</Badge>
          );
        },
      },
      {
        id: 'type',
        label: 'Type',
        width: 100,
        sortable: false,
        valueRenderer: (item: Interface) => getEntityProperty(item, 'iftype_descr') ?? '-',
      },
      {
        id: 'name',
        label: 'Interface',
        width: 150,
        sortable: true,
        valueRenderer: (item: Interface) => (
          <Link href={ROUTES.entityById.replace(':entityId', item.id)}>{item.name}</Link>
        ),
      },
      {
        id: 'description',
        label: 'Description',
        sortable: false,

        valueRenderer: (item: Interface) => getEntityProperty(item, 'ifalias') ?? '-',
      },
      {
        id: 'speed',
        label: 'Speed',
        sortable: false,
        valueRenderer: (item: Interface) => formatValue(getEntityProperty(item, 'ifspeed'), 'bit/s', 'unit'),
      },
      isNetSwitch && {
        id: 'if_port_dot1q_trunk_oper_state',
        label: 'Trunk mode',
        sortable: false,
        valueRenderer: (item: Interface) => getEntityProperty(item, 'if_port_dot1q_trunk_oper_state') ?? '-',
      },
      isNetSwitch && {
        id: 'if_port_dot1q_vlan_default',
        label: 'VLAN',
        sortable: false,
        valueRenderer: (item: Interface) => getEntityProperty(item, 'if_port_dot1q_vlan_default') ?? '-',
      },
      {
        id: 'ips',
        label: 'IP Addresses',
        width: 100,
        valueRenderer: (item: Interface) =>
          getEntityProperty(item, 'if_ipv4_address_list') ?? getEntityProperty(item, 'if_ipv6_address_list') ?? '-',
      },
      {
        id: 'traffic-in',
        label: 'Traffic In',
        width: 100,
        sortable: false,
        valueRenderer: (item: Interface) => {
          const { value, info } = item.alerts['if traffic:in'] ?? {};
          return (
            <CopyText variant="copy-6" additionalClass={c(!value ? 'text-blue-gray-1' : 'text-pink-base', 'hbox')}>
              {!value ? <span className="mr-4">-</span> : <Icon name="ArrowDown" size="xs" additionalClass="mr-4" />}
              {value ? formatValue(value, info?.unit ?? 'bit/s', 'unit') : `0 bit/s`}
            </CopyText>
          );
        },
      },
      {
        id: 'traffic-out',
        label: 'Traffic Out',
        width: 80,
        sortable: false,
        valueRenderer: (item: Interface) => {
          const { value, info } = item.alerts['if traffic:out'] ?? {};
          return (
            <CopyText variant="copy-6" additionalClass={c(!value ? 'text-blue-gray-1' : 'text-blue-ocean', 'hbox')}>
              {!value ? <span className="mr-4">-</span> : <Icon name="ArrowUp" size="xs" additionalClass="mr-4" />}
              {value ? formatValue(value, info?.unit ?? 'bit/s', 'unit') : `0 bit/s`}
            </CopyText>
          );
        },
      },
      {
        id: 'alerts-health',
        label: 'Health / Errors',
        width: 100,
        align: 'center',
        sortable: false,
        valueRenderer: (item: Interface) => {
          const health = [
            item.alerts['if errors:in'],
            item.alerts['if errors:out'],
            item.alerts['if discards:in'],
            item.alerts['if discards:out'],
            item.alerts['if utilization:in'],
            item.alerts['if utilization:out'],
          ].filter(Boolean);

          const alert = health.length ? maxBy(health, 'greenred') : null;
          const color = valueToColor(alert?.greenred ?? 0);

          return (
            <Tooltip content={colorToRange(color)}>
              <StatusDot size="s" color={color} additionalClass="mx-auto block" />
            </Tooltip>
          );
        },
      },
    ].filter(Boolean) as TableColumn<Interface>[]
  );
};

export const InterfacesTable: React.FC<{ entity?: EntityDetails }> = ({ entity }) => {
  const {
    page: initialPage,
    rowsPerPage: initialRowsPerPage,
    sortable,
    paginated,
    ...otherConfig
  } = DEFAULT_TABLE_CONFIG;

  const isNetSwitch = entity ? !!getEntityProperty(entity, 'entity_type')?.startsWith('net.switch') : false;

  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [sort, setSort] = useState<Sort>('asc' as Sort);
  const [sortBy, setSortBy] = useState<string | null>('name');
  const [rowHeight, setRowHeight] = useState<RowHeight>('medium');
  const [keyword, setKeyword] = useState<string>();

  const debouncedSearchTerm = useDebouncedValue<string>(keyword ?? '', 500);

  const entities = useEntitiesQuery<EntitiesResponse<EntityDetails>>(
    {
      parent: entity?.id,
      'data.IFDESCR': '.*',
      details: true,
      alerts: true,
      start_index: (page - 1) * rowsPerPage,
      end_index: (page - 1) * rowsPerPage + rowsPerPage,
      order: sort && sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : undefined,
      general: debouncedSearchTerm,
    },
    {
      keepPreviousData: true,
      enabled: !!entity?.id,
    }
  );

  const alertIds = entities.data?.entities
    ?.reduce((ids, item) => ids.concat(item.alert_ids ?? []), [] as string[])
    .join(',');

  const alerts = useAlertsQuery(
    {
      ids: alertIds,
    },
    {
      enabled: !!alertIds,
    }
  );

  const ifs = useMemo(() => {
    // building a map of alerts by id for later reference
    const alertsById: Record<string, Alert[]> =
      alerts.data?.alerts?.reduce((obj: any, item: Alert) => {
        if (!obj[item.id]) {
          obj[item.id] = [];
        }
        // there might be multiple channels per single alertId (typical for 'if traffic' alert)
        obj[item.id].push(item);

        return obj;
      }, {}) ?? {};

    return (
      // resolving alertIds to actual alert objects: { [name:channel]: alert } and storing under 'alerts' property
      entities.data?.entities.map(item => {
        const interfaceAlerts =
          item.alert_ids?.reduce((obj, alertId) => {
            const alerts = alertsById[alertId];

            return alerts?.length
              ? {
                  ...obj,
                  ...alerts.reduce(
                    (obj: any, alert: Alert) => ({
                      ...obj,
                      // technically there might be multiple alerts with the same name and channel, but we simply
                      // take the last one
                      [`${alert.info.name}${alert.info.channel ? `:${alert.info.channel}` : ''}`]: alert,
                    }),
                    {}
                  ),
                }
              : obj;
          }, {} as Record<InterfaceAlertName, Alert>) ?? {};

        return {
          ...item,
          alerts: interfaceAlerts,
        };
      }) ?? []
    );
  }, [alerts.data, entities.data]);

  const handlePageChange = React.useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handleSortChange = React.useCallback((property: string | null, order: Sort) => {
    setSort(order);
    setSortBy(property);
  }, []);

  const handleRowsPerPageChange = React.useCallback((newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  }, []);

  const handleRowHeightChange = React.useCallback((newRowHeight: RowHeight) => {
    setRowHeight(newRowHeight);
  }, []);

  const columns = useMemo(() => getTableColumns(isNetSwitch), [isNetSwitch]);

  const tableConfig = useMemo(
    () => ({
      ...otherConfig,
      sortable: true,
      columnsToggleable: true,
      rowHeightToggleable: true,
      hasToolbar: true,
      paginated: true,
      selectable: false,
      page,
      rowCount: entities.data?.total ?? 0,
      sort,
      sortBy,
      rowsPerPage,
      bordered: true,
      rowHeight,
    }),
    [otherConfig, page, entities.data?.total, sort, sortBy, rowsPerPage, rowHeight]
  );

  return (
    <RemoteStoredTable<Interface>
      name={`interfaces-tables${isNetSwitch ? '-switch' : ''}`}
      columns={columns}
      items={ifs ?? []}
      config={tableConfig}
      onRowsPerPageChange={handleRowsPerPageChange}
      onPageChange={handlePageChange}
      onSortChange={handleSortChange}
      onRowHeightChange={handleRowHeightChange}
      keyword={keyword}
      onSearch={setKeyword}
      isLoading={entities.isLoading || entities.isFetching || alerts.isLoading}
    />
  );
};
