import { ReviewStep, TargetsStep, SchedulesStep, GeneralStep } from './components';
import { useMaintenanceWindowsQueryById } from '@infrastructure/api/BaseNClient/useMaintenenceWindowByIdQuery';
import { useLoadingContext } from 'react-router-loading';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Error404Page from '../Error404Page';
import { Wizard } from '@components/common/form/Wizard';
import { formValuesToMW, fromBaseNFormat } from '@utils/mw';
import { useMWSubmitMutation } from '@infrastructure/api/BaseNClient/useMWSubmitMutation';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import ROUTES from '@infrastructure/routes';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { useQueryClient } from 'react-query';

import styles from './MWCreatePage.module.scss';

export const MWEditPage = () => {
  const loadingContext = useLoadingContext();

  const { id } = useParams<{ id: string }>();
  const query = useMaintenanceWindowsQueryById(id!, { enabled: !!id });
  const submitMWMutation = useMWSubmitMutation();
  const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (query.data) {
      loadingContext.done();
    }
  }, [query.data, loadingContext]);

  const handleSubmit = useCallback(
    (values: any, { setIsSubmitting }) => {
      submitMWMutation.mutate(
        {
          ...formValuesToMW(values),
          id: id!,
        },
        {
          onSuccess: getSuccessHandler(() => {
            setIsSubmitting(false);
            navigate(ROUTES.singleMaintenanceWindow.replace(':id', id!));

            queryClient.invalidateQueries(ENDPOINTS.maintenanceWindows);
          }, 'Maintenance Window updated successfully'),
          onError: getFailureHandler(undefined, 'Failed to update Maintenance Window'),
        }
      );
    },
    [getFailureHandler, getSuccessHandler, id, navigate, submitMWMutation, queryClient]
  );

  if (!query.isLoading && (query.isError || !query.data)) {
    return <Error404Page />;
  }

  return query.isLoading ? null : (
    <div className={styles.mwCreatePage}>
      <Wizard onSubmit={handleSubmit} allEnabled>
        <GeneralStep
          name="general"
          title="General"
          initialValues={{ name: query.data.name, reason: query.data.reason, description: query.data.description }}
        />
        <SchedulesStep
          name="schedules"
          title="Schedules"
          initialValues={{ times: query.data.times?.map(fromBaseNFormat) }}
        />
        <TargetsStep
          name="targets"
          title="Targets"
          initialValues={{
            global: !!query.data.global,
            routing: !!query.data.routing,
            // we are using an id as a name here, because name not available from API response
            entities: query.data.entityIds?.map((id, index) => ({ id, name: query.data.entityNames ? query.data.entityNames[index] : id })),
          }}
        />
        <ReviewStep name="review" title="Review" />
      </Wizard>
    </div>
  );
};
