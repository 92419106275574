type ImageOptions = {
  stretchX?: Array<[number, number]>;
  stretchY?: Array<[number, number]>;
  content?: [number, number, number, number];
};

export type ImageSource = {
  width?: number;
  height?: number;
  src: string;
  options?: ImageOptions;
};

export type ImageLoaderOptions = {
  map: maplibregl.Map | mapboxgl.Map;
  imgSources: Record<string, ImageSource>;
  onMissingImage?: (imgId: string) => void;
};

export class ImageLoader {
  private opts: ImageLoaderOptions;

  private cache: Record<string, HTMLImageElement> = {};

  constructor(opts: ImageLoaderOptions) {
    this.opts = opts;
    this.opts.map.on('styleimagemissing', this.addImage);
  }

  addImage = ({ id }: { id: string }) => {
    if (this.opts.map.hasImage(id)) {
      return;
    }

    if (this.cache[id]) {
      this.opts.map.addImage(id, this.cache[id], this.opts.imgSources[id].options ?? {});
    } else {
      this.loadImage(id)
        .then((image: HTMLImageElement) => {
          this.cache[id] = image;
          this.addImage({ id });
        })
        .catch((error: Error) => console.error(error.message));
    }
  };

  hasSource(id: string) {
    return !!this.opts.imgSources[id];
  }

  private async loadImage(id: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      if (this.hasSource(id)) {
        const { width, height, src } = this.opts.imgSources[id];
        const img = new Image();
        img.onload = function onload() {
          resolve(this as HTMLImageElement);
        };
        img.onerror = function onerror() {
          reject(new Error(`Image with id of ${id} cannot be loaded`));
        };
        Object.assign(img, { id, width, height, src });
      } else {
        this.opts.onMissingImage?.(id);

        reject(new Error(`No source for Image with id of ${id}`));
      }
    });
  }

  destroy() {
    if (this.opts.map) {
      this.opts.map.off('styleimagemissing', this.addImage);
    }
    this.cache = {};
  }
}
