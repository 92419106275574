import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import CopyText from '@components/typography/CopyText';
import { getTimeFormatter } from '@utils/date';

type Props = {
  utcOffsetMinutes?: number;
  locale?: string;
  timezone?: string;
};

// TODO tackle locale
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Clock: React.FC<Props> = ({ utcOffsetMinutes, locale, timezone = '' }) => {
  const [clockTickingInterval, setClockTickingInterval] = useState<NodeJS.Timeout | null>(null);
  const clockRef = useRef<HTMLDivElement>(null);
  const timeFormatter = useMemo(
    () => getTimeFormatter(timezone, { year: undefined, month: undefined, day: undefined }),
    [timezone]
  );

  const [currentTime, setCurrentTime] = useState<string>(timeFormatter.format(new Date()) || '');

  const updateClock = useCallback(() => {
    if (clockRef && clockRef.current) {
      setCurrentTime(timeFormatter.format(new Date()));
    }
  }, [timeFormatter, setCurrentTime]);

  useEffect(() => {
    if (!clockTickingInterval) {
      setClockTickingInterval(setInterval(updateClock, 1000));
    }

    return () => (clockTickingInterval ? clearInterval(clockTickingInterval) : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={clockRef}>
      <CopyText variant="copy-6" additionalClass="cursor-default text-gray-4">
        {utcOffsetMinutes ? `UTC ${utcOffsetMinutes > 0 ? '+' : ''}${utcOffsetMinutes / 60} | ` : ''}
        {timezone}
        {` | ${currentTime}`}
      </CopyText>
    </div>
  );
};

export default Clock;
