import { createSelector } from 'reselect';
import { AppState } from '@infrastructure/redux/store';
import { identity, withoutUndefined } from '@utils/misc';
import { ConfigsPageState } from './types';
import { Filters, PaginationParams, SortingParams } from '../types';
import { isEmpty } from 'lodash';
import { SearchConfigsRequest } from '@infrastructure/api/BaseNClient/useSearchConfigsQuery';

export const CONFIGS_TABLE_ID = `table-settings-configs`;

export const selectSliceRoot = (state: AppState): ConfigsPageState => state.configsPage;

export const selectItems = createSelector(selectSliceRoot, _ => _.items);
export const selectItemsOrder = createSelector(selectSliceRoot, _ => _.itemsOrder);
export const selectAreLoaded = createSelector(selectSliceRoot, _ => _.areLoaded);

export const selectItemsAsArray = createSelector(selectItems, selectItemsOrder, (items, order) =>
  order.map(id => items[id])
);

// the following might seem exessive, but in reality it's the only way to memoize ¯\_(ツ)_/¯
export const selectFilters = createSelector(selectSliceRoot, _ => _.filters);
export const selectPagination = createSelector(selectSliceRoot, _ => _.pagination);
export const selectSorting = createSelector(selectSliceRoot, _ => _.sort);

export const selectResolvedFilters = createSelector(selectFilters, identity);
export const selectResolvedPagination = createSelector(selectPagination, identity);
export const selectResolvedSorting = createSelector(selectSorting, identity);

export const selectQueryParams = createSelector(
  selectResolvedFilters,
  selectResolvedPagination,
  selectResolvedSorting,
  (filters: Filters, pagination: PaginationParams, sorting?: SortingParams) => {
    const params: SearchConfigsRequest = {};

    params.start_index = (pagination.page - 1) * pagination.rowsPerPage;
    params.end_index = (pagination.page - 1) * pagination.rowsPerPage + pagination.rowsPerPage;

    if (filters.generalFilter) {
      params.searchterm = filters.generalFilter;
    }

    if (!isEmpty(filters.discreteFilters)) {
      Object.keys(filters.discreteFilters).forEach(filterKey => {
        // @ts-ignore
        params[filterKey] = filters.discreteFilters[filterKey];
      });
    }

    // TODO: uncomment when sorting is supported
    // if (sorting?.sort && sorting?.sortBy) {
    //   params.order = `${sorting.sort === 'desc' ? '-' : ''}${sorting.sortBy}`;
    // }

    return withoutUndefined(params, true);
  }
);
