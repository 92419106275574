import { TableColumn, TableColumns, TableItemAction } from '@components/common/Table';
import { toIdMap } from '@utils/misc';
import { SearchConfigItem } from '@infrastructure/api/BaseNClient/useSearchConfigsQuery';
import Link from '@components/common/Link';
import ROUTES from '@infrastructure/routes';
import CopyText from '@components/typography/CopyText';
import { formatEpochDate } from '@utils/date';
import { Filters } from '@infrastructure/redux/types';
import { CodeEditor } from '@components/common/CodeEditor/CodeEditor';
import { floor } from 'lodash';

import styles from './ConfigSearchTable.module.scss';

export const getTableColumns = (allFilters: Filters): TableColumns<SearchConfigItem> => {
  return toIdMap(
    [
      {
        id: 'entity',
        label: 'Entity',
        width: 250,
        sortable: false,
        valueRenderer: (d: SearchConfigItem) => (
          <div className="flex flex-row items-center flex-1 truncate text-dark">
            <Link
              href={`${ROUTES.entityById.replace(':entityId', d.id)}`}
              testid={`Entity::${d.name}`}
              additionalClass={styles.link}
            >
              <CopyText variant="copy-4" additionalClass="truncate">
                {d.name}
              </CopyText>
            </Link>
          </div>
        ),
      },
      {
        id: 'time',
        label: 'Changed on',
        width: 180,
        valueRenderer: (d: SearchConfigItem) => {
          return formatEpochDate(d.configuration_time);
        },
      },
      {
        id: 'version',
        label: 'Version',
        width: 150,
        valueRenderer: (d: SearchConfigItem) => {
          // Convert timestamp to seconds since epoch
          return floor(d.time / 1000)
        },
      },
      {
        id: 'user',
        label: 'User',
        width: 150,
        valueRenderer: (d: SearchConfigItem) => {
          return d.config_user;
        },
      },
      {
        id: 'content',
        label: 'Content',
        valueRenderer: (d: SearchConfigItem) => {
          return allFilters.generalFilter ? (
            <div className={styles.codeContainer}>
              <CodeEditor
                key={d.line}
                value={d.config}
                readOnly
                additionalClass={styles.codeEditor}
                scrollToLine={d.line}
                highlightMatches={[allFilters.generalFilter]}
              />
            </div>
          ) : null;
        },
      },
    ].filter(Boolean) as TableColumn<SearchConfigItem>[]
  );
};

export const getTableActions = (
  handlers: Record<string, (selectedItems: SearchConfigItem[]) => void>
): TableItemAction<SearchConfigItem>[] => [
    {
      multiAction: true,
      collapse: false,
      label: 'Run Script',
      hint: '',
      id: 'runScript',
      icon: 'RunScript',
      handler: handlers.runScript,
    },

    {
      multiAction: true,
      hint: 'Select 2 items to compare',
      disabled(selectedItems: SearchConfigItem[]) {
        return selectedItems.length !== 2;
      },
      collapse: false,
      label: 'Compare Selected Items(Diff)',
      id: 'diff',
      icon: 'Grid2Columns',
      handler: handlers.diff,
    },
  ];
