import { useMutation, UseMutationResult } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericNotOkApiResponse } from '../types';
import { z } from 'zod';

export type ConnectionCheckRequest = {
  username: string;
  password: string;
  entityId: string;
};

const connectionCheckResponseSchema = z.discriminatedUnion('result', [
  z.object({
    result: z.literal('ok'),
    host: z.string(),
    user: z.string(),
  }),
  z.object({
    result: z.literal('error'),
    message: z.string(),
    user: z.string(),
  }),
]);

export type ConnectionCheckResponse = z.infer<typeof connectionCheckResponseSchema>;

export const useConnectionCheckMutation = <
  S extends ConnectionCheckResponse = ConnectionCheckResponse,
  F extends GenericNotOkApiResponse = GenericNotOkApiResponse,
  V extends ConnectionCheckRequest = ConnectionCheckRequest
>(
  mutationOptions = {}
): UseMutationResult<S, F, V> =>
  useMutation<S, F, V>((body: V): Promise<S> => apiClient.post(ENDPOINTS.connectionCheck, body), mutationOptions);
