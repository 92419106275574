import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LayoutState } from './types';

const initialState: LayoutState = {
  isHeaderOpen: true,
  sidebarOpen: true,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },

    setSidebarOpen(state, { payload }: PayloadAction<boolean>) {
      state.sidebarOpen = payload;
    },

    setHeaderOpen(state, { payload }: PayloadAction<boolean>) {
      state.isHeaderOpen = payload;
    },
  },
});

export const { toggleSidebar, setSidebarOpen, setHeaderOpen } = layoutSlice.actions;

export default layoutSlice;

export * from './selectors';
export * from './types';
