import { useCallback, useEffect, useRef } from 'react';
import { ColumnWidths, TableColumns } from './types';
import {
  createResizeHandle,
  getColumnsVisibility,
  getColumnsWidths,
  setResizeListeners,
} from '@components/common/Table/utils';
import { MIN_CELL_WIDTH, defaultCheckboxCellWidth } from './config';

type UseResizableColumns<T> = {
  $table: HTMLTableElement | null;
  columns: TableColumns<T>;
  selectable?: boolean;
  enabled?: boolean;
  onColumnResize?: (colWidths: ColumnWidths) => void;
};

const useResizableColumns = <T>({
  $table,
  columns,
  selectable = false,
  enabled = true,
  onColumnResize,
}: UseResizableColumns<T>) => {
  const columnsRef = useRef<TableColumns<T>>(columns);

  const handleColumnResize = useCallback(
    (colId: string, newWidth: number) => {
      const colWidths = getColumnsWidths(columnsRef.current);
      colWidths[colId] = newWidth < MIN_CELL_WIDTH ? MIN_CELL_WIDTH : newWidth;
      onColumnResize?.(colWidths);
    },
    [onColumnResize]
  );

  useEffect(() => {
    columnsRef.current = columns;
  }, [columns]);

  useEffect(() => {
    let clearListeners: Function;
    const startIdx = selectable ? 1 : 0;

    if ($table) {
      const $row = $table.getElementsByTagName('tr')[0];
      // hidden columns are not present in the DOM
      const $cols = $row ? $row.children : undefined;

      if ($cols) {
        const tableWidth = $table.offsetWidth;
        const colVisibility = getColumnsVisibility(columns, true);
        const colKeys = Object.keys(colVisibility);
        // calculate collective width of all visible columns with a defined width, plus the width of the checkbox column (if present)
        const colsWithWidth = Object.keys(getColumnsWidths(columns)).filter(key => colVisibility[key]);
        const colsWidth =
          colsWithWidth.reduce((result: number, key: string) => {
            return result + (columns[key]?.width ? parseInt(columns[key].width as string, 10) : 0);
          }, 0) +
          startIdx * defaultCheckboxCellWidth;
        const colsWithoutWidth = colKeys.length - colsWithWidth.length;
        // now calculate default width for columns without a defined width
        const defaultColWidth = colsWithoutWidth > 0 ? (tableWidth - colsWidth) / colsWithoutWidth : 0;

        // set initial table width (without explicit table width, column widths will be ignored and random)
        $table.style.width = `${colsWithoutWidth > 0 ? tableWidth : colsWidth}px`;

        for (let i = startIdx; i < $cols.length; i++) {
          const colKey = colKeys[i - startIdx];

          // set initial column width
          ($cols[i] as HTMLElement).style.width = `${columns[colKey]?.width ?? defaultColWidth}px`;

          // TODO: this is quite flaky, maybe revise
          if (enabled && columns[colKey].resizable !== false) {
            const $div = createResizeHandle();
            $cols[i].appendChild($div);
            clearListeners = setResizeListeners($div, $table, newWidth => handleColumnResize(colKey, newWidth));
          }
        }
      }
    }

    return () => {
      if (clearListeners) {
        clearListeners();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$table, handleColumnResize, enabled, columns]);
};

export default useResizableColumns;
