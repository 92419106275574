import React, { ChangeEvent, forwardRef, RefObject, useCallback, useState } from 'react';
import c from 'classnames';
import Control, { ControlProps } from '../Control/Control';
import { Input, InputProps, Password, TextArea, NumberInput, NumberInputProps } from './index';

import styles from './Input.module.scss';

export type TextControlProps = Omit<ControlProps, 'additionalClasses'> &
  (({ type?: 'number' } & NumberInputProps) | ({ type?: 'text' | 'email' | 'password' } & InputProps)) & {
    maxLength?: number;
    multiline?: boolean;
    autoComplete?: string;
    hideCounter?: boolean;
    additionalClass?: string;
    additionalClasses?: {
      textControl?: string;
      control?: string;
      child?: string;
    };
  };

const TextControl = forwardRef<HTMLInputElement, TextControlProps>(
  (
    {
      onChange,
      type = 'text',
      multiline = false,
      additionalClass,
      additionalClasses: { textControl: wrapperClass, ...controlClasses } = {},
      autoComplete,
      placeholder,
      disabled,
      icon,
      errorText,
      actionIcon,
      maxLength,
      hideCounter,
      noInfo = false,
      // comes from <Select> .. just discard here since we won't need it, and it doesn't belong on html dom
      // @ts-ignore
      defaultInputComponent,
      testid,
      ...props
    },
    ref
  ) => {
    const [length, setLength] = useState<number>(0);

    const showCounter = !hideCounter && typeof maxLength === 'number' && maxLength >= 0;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLength(e.target.value.length);
      onChange?.(e);
    };

    const renderChild = () => {
      switch (type) {
        case 'password':
          return <Password placeholder={placeholder} autoComplete={autoComplete} />;

        case 'number':
          return <NumberInput placeholder={placeholder} />;

        default:
          return multiline ? (
            <TextArea ref={ref as RefObject<HTMLTextAreaElement>} placeholder={placeholder} maxLength={maxLength} />
          ) : (
            <Input
              ref={ref}
              type={type}
              placeholder={placeholder}
              autoComplete={autoComplete}
              maxLength={maxLength}
              icon={icon}
              actionIcon={actionIcon}
              testid={testid}
            />
          );
      }
    };

    return (
      <div
        className={c(
          styles.textControl,
          wrapperClass,
          disabled && styles.disabled,
          showCounter && styles.showCounter,
          additionalClass
        )}
      >
        <Control
          {...props}
          additionalClasses={{ info: styles.info, ...controlClasses }}
          errorText={errorText}
          onChange={handleChange}
          noInfo={noInfo}
          disabled={disabled}
        >
          {renderChild()}
        </Control>

        {!noInfo && showCounter && (
          <div className={styles.charCounter}>
            {length}/{maxLength}
          </div>
        )}
      </div>
    );
  }
);

TextControl.displayName = 'TextControl';

export const TextControlUncontrolled: React.FC<Omit<TextControlProps, 'value'>> = ({
  defaultValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState<string>(defaultValue ?? '');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
      onChange?.(e);
    },
    [onChange]
  );

  return <TextControl {...props} onChange={handleChange} value={value} />;
};

export default TextControl;
