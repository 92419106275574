import React, { useCallback, useRef, useState, useEffect } from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List, { ListRowProps } from 'react-virtualized/dist/commonjs/List';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { BaseSelectItem } from '@components/common/form/Select';
import c from 'classnames';
import { identity } from 'lodash';
import logger from '@infrastructure/logging/logger';

import styles from './InfiniteList.module.scss';

export type GetItemQuery<TValue extends any = string> = (
  startIndex: number,
  endIndex: number,
  searchTerm?: string
) => Promise<{ data: TValue[]; total: number }>;

export type InfiniteListItem<TValue extends any = string> = BaseSelectItem<TValue> & {
  idx?: number;
};
export type InfiniteListItemProps<TValue extends any = string> = ListRowProps & {
  item: TValue;
};
export type InfiniteListRowRenderer<TValue extends any = string> = (
  props: InfiniteListItemProps<TValue>
) => React.ReactNode;

export type InfiniteListProps<TValue extends any = string> = {
  selectedItems?: Array<InfiniteListItem<TValue> | string>;
  onSelect?: (item: InfiniteListItem<TValue>) => void;
  onLoadMore?: (items: TValue[], startIndex: number, stopIndex: number) => void;
  /** TValue to item, label transformer (defaults to identity) */
  itemToLabel?: (item: TValue) => string;
  /** TValue to item identifier transformer (defaults to itemToLabel) */
  itemToId?: (item: TValue) => string;
  getItemsQuery: GetItemQuery<TValue>;
  rowRenderer?: InfiniteListRowRenderer<TValue>;
  minimumBatchSize?: number;
  threshold?: number;
  rowHeight?: number;
  filterStr?: string;
};

export function InfiniteList<TValue extends any = string>({
  onSelect,
  onLoadMore,
  getItemsQuery,
  selectedItems = [],
  itemToLabel = identity,
  rowRenderer,
  filterStr,
  minimumBatchSize = 20,
  threshold = 5,
  rowHeight = 32,
  ...props
}: InfiniteListProps<TValue>) {
  const listRef = useRef<InfiniteLoader | null>(null);
  const itemsRef = useRef<Record<number, TValue>>({});
  const isLoadingRef = useRef(false);
  const [totalItems, setTotalItems] = useState(-1);
  const itemToId = props.itemToId ?? itemToLabel;

  useEffect(() => {
    listRef.current?.resetLoadMoreRowsCache(true);
  }, [filterStr]);

  const loadMoreQuery = useCallback(
    ({ startIndex, stopIndex }) => {
      return isLoadingRef.current
        ? Promise.resolve(true)
        : new Promise(resolve => {
            isLoadingRef.current = true;
            // BaseN API's endIndex is exclusive, so we give it a bigger by one
            void getItemsQuery(startIndex, stopIndex + 1, filterStr)
              .then(({ data, total }) => {
                setTotalItems(total ?? 0);

                for (let i = 0; i < data.length; i++) {
                  itemsRef.current[i + Number(startIndex)] = data[i];
                }

                onLoadMore?.(data, startIndex, stopIndex);

                resolve(true);
              })
              .catch(ex => {
                logger.error(`InfiniteList: failed to retrieve items for ${startIndex}-${stopIndex} range.`);
              })
              .finally(() => {
                isLoadingRef.current = false;
              });
          });
    },
    [getItemsQuery, filterStr, onLoadMore]
  );

  const isRowLoaded = useCallback(({ index }) => {
    return !!itemsRef.current[index];
  }, []);

  const defaultRowRenderer = useCallback(
    ({ index, key, style, item }: InfiniteListItemProps<TValue>) => {
      const isLoaded = !!item;

      return (
        <div
          style={style}
          className={c(
            styles.item,
            selectedItems.find(
              selectedItem =>
                (typeof selectedItem === 'string' ? selectedItem : itemToId(selectedItem.value)) === itemToId(item)
            ) && styles.selected
          )}
          key={key}
          onClick={isLoaded ? () => onSelect?.({ label: itemToLabel(item), value: item, idx: index }) : undefined}
        >
          {isLoaded ? itemToLabel(item) : <LoadingSpinner size="s" />}
        </div>
      );
    },
    [onSelect, selectedItems, itemToLabel, itemToId]
  );

  const handleRowRender = useCallback(
    // ListRowProps contain only index, which is inconvenient, so we are augumenting them with an actual item
    (props: ListRowProps) => (rowRenderer ?? defaultRowRenderer)({ ...props, item: itemsRef.current[props.index] }),
    [defaultRowRenderer, rowRenderer]
  );

  // kick off initial load on mount
  useEffect(() => {
    void loadMoreQuery({ startIndex: 0, stopIndex: 20 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.infiniteList}>
      {totalItems < 0 ? (
        <LoadingSpinner size="m" additionalClass="m-20" centered />
      ) : (
        <InfiniteLoader
          ref={listRef}
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreQuery}
          rowCount={totalItems}
          minimumBatchSize={minimumBatchSize}
          threshold={threshold}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  ref={registerChild}
                  width={width}
                  height={height}
                  onRowsRendered={onRowsRendered}
                  rowCount={totalItems}
                  rowRenderer={handleRowRender}
                  rowHeight={rowHeight}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      )}
    </div>
  );
}
