import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { Field } from './Field';
import { FieldGroup } from './FieldGroup';
import { FieldConfig, FieldGroupConfig, FieldsConfig } from './types';
import { isUndefined } from 'lodash';

export type FormBodyProps = {
  fields: FieldsConfig;
  isSubmitting?: boolean;
};

export function FormBody<Schema>({ fields, isSubmitting: isSubmittingOverride }: FormBodyProps) {
  const { setSubmitting, isSubmitting } = useFormikContext<Schema>();

  useEffect(() => {
    setSubmitting(!isUndefined(isSubmittingOverride) ? isSubmittingOverride : isSubmitting);
  }, [isSubmitting, isSubmittingOverride, setSubmitting]);

  return (
    <div className="formBody">
      {fields.map((fieldProps: FieldConfig | FieldGroupConfig) =>
        fieldProps.type === 'group' ? (
          <FieldGroup key={fieldProps.title} {...fieldProps} />
        ) : (
          <Field key={fieldProps.props.name} {...fieldProps} />
        )
      )}
    </div>
  );
}
