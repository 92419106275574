import defaultDeviceIcon from '@components/common/Icon/icons/entities/png/16x16/default-device.png';
import switchIcon from '@components/common/Icon/icons/entities/png/16x16/switch.png';
import routerIcon from '@components/common/Icon/icons/entities/png/16x16/router.png';

const entities = {
  'default-device': {
    width: 16,
    height: 16,
    src: defaultDeviceIcon as string,
  },
  switch: {
    width: 16,
    height: 16,
    src: switchIcon as string,
  },
  router: {
    width: 16,
    height: 16,
    src: routerIcon as string,
  },
};

export type EntityIcons = keyof typeof entities;

export default entities;
