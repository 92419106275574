import Icon from '@components/common/Icon';
import { StripeColor } from '@utils/issue';
import React from 'react';
import c from 'classnames';
import CopyText from '@components/typography/CopyText';

import styles from './Arrow.module.scss';
import alertStyles from '@components/connected/IssueManager/components/AlertBar/Alert.module.scss';

export type ArrowProps = {
  color?: StripeColor;
  dir?: 'left' | 'right';
  width?: 1 | 2 | 3 | 4;
  title?: string;
  additionalClass?: string;
};

export const Arrow: React.FC<ArrowProps> = ({ color = 'gray', dir = 'right', width = 1, title, additionalClass }) => {
  return (
    <div className={c(styles.arrow, styles[`width-${width}`], additionalClass)}>
      <div className={c(styles.line, alertStyles[color], alertStyles[`${color}-bg`])}></div>
      {title && (
        <CopyText variant="copy-8" additionalClass={c(styles.title, styles[color])}>
          {title}
        </CopyText>
      )}
      <Icon
        name={dir === 'right' ? 'ArrowRight' : 'ArrowLeft'}
        additionalClass={c(styles.icon, styles[dir], styles[color])}
      />
    </div>
  );
};
