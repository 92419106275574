import React, { useMemo } from 'react';
import c from 'classnames';
import {
  DateRangePicker as BPDateRangePicker,
  DateRangePickerProps as BPDateRangePickerProps,
  DateRangeShortcut,
  TimePrecision,
} from '@blueprintjs/datetime';
import dayPickerProps from './dayPickerProps';
import useTextSnippets from '@services/useTextSnippets';
import { COMMON_DATE_INTERVALS } from '@constants/date';
import moment from 'moment';
import Button from '@components/common/Button';

import styles from './style.module.scss';
import timePickerStyles from '../TimeInput/TimeInput.module.scss';

export type DateRangePickerProps = Omit<BPDateRangePickerProps, 'timePrecision' | 'timePickerProps'> & {
  showTimePicker?: boolean;
  showActionsBar?: boolean;
  additionalClass?: string;
  onReset?: () => void;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  showTimePicker = true,
  showActionsBar = false,
  additionalClass,
  onReset,
  ...props
}) => {
  const i18n = useTextSnippets('common');
  const now = moment();

  const shortcutMap: Record<string, string> = useMemo(
    () => ({
      '1d': i18n.pastDay,
      '1w': i18n.pastWeek,
      '1m': i18n.pastMonth,
      '3m': i18n.pastQuarter,
      '1y': i18n.pastYear,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const shortcuts = COMMON_DATE_INTERVALS.map(interval => {
    const dateRange = [
      now
        .clone()
        .subtract(...interval.value)
        .toDate(),
      now.clone().toDate(),
    ];

    return {
      dateRange,
      label: shortcutMap[interval.label],
    };
  }) as DateRangeShortcut[];

  let timePickerProps;
  if (showTimePicker) {
    timePickerProps = {
      timePrecision: TimePrecision.MINUTE,
      timePickerProps: {
        showArrowButtons: true,
        selectAllOnFocus: true,
        useAmPm: true,
        className: timePickerStyles.timePicker,
      },
    };
  }

  return (
    <div className={c(styles.dateRangePicker, showActionsBar && styles.showActionsBar, additionalClass)}>
      <BPDateRangePicker
        highlightCurrentDay
        allowSingleDayRange={false} // TODO: investigate why it errors out currently
        maxDate={new Date()}
        dayPickerProps={dayPickerProps}
        shortcuts={shortcuts}
        className={styles.bpDateRangePicker}
        {...props}
        {...timePickerProps}
      />
      {showActionsBar && (
        <div className={styles.actions}>
          <Button variant="stealth" size="m" onClick={onReset}>
            {i18n.reset}
          </Button>
        </div>
      )}
    </div>
  );
};
