import React from 'react';
import CopyText from '@components/typography/CopyText';
import { PopoverPosition } from '@blueprintjs/core';
import c from 'classnames';
import useTextSnippets from '@services/useTextSnippets';
import Tooltip from '@components/common/Tooltip';

type Props = {
  position?: PopoverPosition;
  additionalClass?: string;
};

const UnsupportedWrapper: React.FC<Props> = ({ additionalClass, position, children }) => {
  const commonI18n = useTextSnippets('common');

  return (
    <Tooltip
      content={<CopyText variant="copy-4">{commonI18n.notYetSupported}</CopyText>}
      position={position || PopoverPosition.TOP}
      usePortal
      noIndicatorClass
      renderTarget={({ isOpen, ...props }) => (
        <div {...props} className={c(additionalClass, props.className, 'max-w-fit-content cursor-not-allowed')}>
          {children}
        </div>
      )}
    />
  );
};

export default UnsupportedWrapper;
