const QUERY_CONFIGS = {
  static: { staleTime: Infinity, cacheTime: Infinity, enabled: false, retry: 1 },
  staticEnabled: {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
    retry: 1,
  },
  stale: { staleTime: 60 * 1000, cacheTime: Infinity, enabled: true, retry: 1 },
  live: {
    staleTime: 60 * 1000,
    cacheTime: Infinity,
    enabled: true,
    retry: 1,
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
    refetchOnReconnect: 'always',
  },
  default: { retry: 1 },
  defaultEnabled: { enabled: true, retry: 1 },
  cacheDisabled: {
    cacheTime: 0,
    staleTime: 0,
    retry: 1,
  },
};

export default QUERY_CONFIGS;
