import { ChartConfigGenerator } from '../types';

const getConfig: ChartConfigGenerator = ({ channels = [] } = {}) => ({
  echartsConfig: {
    animation: false,
    legend: {
      type: 'scroll',
      orient: 'vertical',
      right: 0,
      top: 0,
      bottom: 20,
    },
    polar: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    angleAxis: {
      type: 'value',
      startAngle: 0,
    },
    radiusAxis: {},
    series:
      channels?.map(({ name, values: data }) => ({
        type: 'line',
        name,
        data,
        coordinateSystem: 'polar',
      })) ?? [],
  },
});

export default getConfig;
