import React, { FC } from 'react';
import useTextSnippets from '@services/useTextSnippets';
import ErrorPageContainer from '@components/error/ErrorPageContainer';

type Error404PageProps = {
  headline?: string;
  text?: string;
  action?: 'reload' | 'redirect';
};

const Error404Page: FC<Error404PageProps> = ({ headline, text }) => {
  const { notFoundErrorTitle, notFoundErrorMessage } = useTextSnippets('error');

  return <ErrorPageContainer headline={headline || notFoundErrorTitle} text={text || notFoundErrorMessage} />;
};

export default Error404Page;
