import { useCallback, useRef } from 'react';
import { WizardStepProps, useWizardHandle } from '@components/common/form/Wizard';
import { TargetsTable } from './TargetsTable';
import RadioGroupControl from '@components/common/form/Radio/RadioGroupControl';
import { Radio } from '@components/common/form/Radio';
import Icon from '@components/common/Icon';
import CopyText from '@components/typography/CopyText';
import TargetStepSchema from './TargetStep.schema';
import { EntitiesRequest } from '@infrastructure/api/BaseNClient/useEntitiesQuery';

type TargetsStepProps = WizardStepProps & {
  showRouting?: boolean;
  showGlobal?: boolean;
  queryParams?: Partial<EntitiesRequest>;
};

export const TargetsStep: React.FC<TargetsStepProps> = ({
  validationSchema,
  initialValues,
  onSubmit,
  onChange,
  innerRef,
  showRouting = true,
  showGlobal = true,
  queryParams,
}) => {
  const targetsRef = useRef(initialValues);

  useWizardHandle(innerRef, onSubmit);

  const handleTargetsChange = useCallback(
    selectedItems => {
      targetsRef.current.entities = selectedItems;
      onChange?.(targetsRef.current);
    },
    [onChange]
  );

  const handleGlobalChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      targetsRef.current = { global: e.target.value === 'yes', entities: [], routing: false };
      onChange?.(targetsRef.current);
    },
    [onChange]
  );

  const handleRoutingChange = useCallback(
    (newState: boolean) => {
      targetsRef.current = { ...targetsRef.current, routing: newState, global: false };
      onChange?.(targetsRef.current);
    },
    [onChange]
  );

  return (
    <>
      {showGlobal && (
        <RadioGroupControl
          label="Global Maintenance Window?"
          helperText="A Global maintenance window will affect all entities of this realm."
          inline
          onChange={handleGlobalChange}
        >
          <Radio value="yes" label="Yes" size="m" checked={targetsRef.current.global} />
          <Radio value="no" label="No" size="m" checked={!targetsRef.current.global} />
        </RadioGroupControl>
      )}

      {targetsRef.current.global ? (
        <div className="flex flex-col items-center justify-center p-32 border-solid rounded-sm border-1 border-blue-gray-3 bg-blue-gray-background">
          <div className="text-center">
            <Icon name="SwitchRouter" size="l" additionalClass="mb-16" />
            <CopyText variant="copy-1" additionalClass="mb-4">
              All entities selected
            </CopyText>
            <CopyText variant="copy-4">All entities of this realm are inclluded in this Maintenance Window.</CopyText>
          </div>
        </div>
      ) : (
        <TargetsTable
          queryParams={queryParams}
          routingEnabled={targetsRef.current.routing}
          selectedTargets={targetsRef.current.entities}
          onSelectTargets={handleTargetsChange}
          onRoutingChange={showRouting ? handleRoutingChange : undefined}
        />
      )}
    </>
  );
};

TargetsStep.defaultProps = {
  validationSchema: TargetStepSchema,
  initialValues: {
    global: false,
    entities: [],
    routing: false,
  },
};
