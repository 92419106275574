import { DEFAULT_TOPOLOGY_MAP_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { WidgetSetup } from '@components/pages/WidgetPage/types';
import { OptionsTab } from './components/OptionsTab';
import { DataSourcePicker } from './components/DataSourcePicker';
import { TopologyMapWidget } from './TopologyMapWidget';

export default {
  type: 'topology_map',
  icon: 'Tree',
  i18nTitle: 'topologyMap',
  OptionsTab,
  DataSourcePicker,
  Widget: TopologyMapWidget,
  defaultSubConfig: DEFAULT_TOPOLOGY_MAP_WIDGET_CONFIG,
  editSubConfig: {
    areNodesDraggable: true,
  },
} as WidgetSetup;
