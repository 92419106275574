import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import { NoteWithEntity } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import React, { FC } from 'react';
import { ScheduleItem } from '@components/pages/MWCreatePage/components/ScheduleItem';
import { fromBaseNFormat } from '@utils/mw';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import Card from '@components/common/Card/Card';
import { CopyToClipboardButton } from '@components/connected/CopyToClipboardButton/CopyToClipboardButton';
import { Position } from '@blueprintjs/core';
import c from 'classnames';
import { MWInfoGrid } from './MWInfoGrid';
import { useMaintenanceWindowsQueryById } from '@infrastructure/api/BaseNClient/useMaintenenceWindowByIdQuery';
import CopyText from '@components/typography/CopyText';
import Button from '@components/common/Button';
import HorizontalTitleSeparator from '@components/separators/HorizontalTitleSeparator/HorizontalTitleSeparator';

import styles from '@components/pages/ConfigDiffPage/components/DiffPanel.module.scss';

export type MWDetailsProps = DetailsPanelProps & {
  item?: NoteWithEntity | null;
};

export const MWDetails: FC<MWDetailsProps> = ({ item }) => {
  const [showTargets, setShowTargets] = React.useState(false);

  const query = useMaintenanceWindowsQueryById(item?.link!, {
    enabled: !!item?.link,
  });

  const handleTargetsToggle = React.useCallback(() => {
    setShowTargets(state => !state);
  }, []);

  return (
    <>
      <MWInfoGrid note={item} additionalClass="mb-16" />

      {item && (
        <Card
          header={
            <div className="w-full hbox">
              <div className="copy-4">Description</div>

              <CopyToClipboardButton
                size="s"
                textToCopy={item.text}
                tooltipProps={{ targetProps: { className: 'ml-auto -mr-6' }, position: Position.TOP }}
              />
            </div>
          }
          additionalClass={c(styles.diffPanel, 'mb-32')}
          noPadding
        >
          <pre className="h-full p-16 whitespace-pre-wrap bg-blue-gray-background">{item.text}</pre>
        </Card>
      )}

      {query.isLoading ? (
        <LoadingSpinner centered />
      ) : query.data ? (
        <>
          <CopyText variant="copy-2" additionalClass="mb-12">
            Maintenance Window Info
          </CopyText>

          <div className="mb-16">
            <HorizontalTitleSeparator title="Schedules" variant="copy-4" additionalClass="mb-8" />
            {query.data.times?.map((schedule: any, idx: number) => (
              <div key={idx} className="mb-8">
                <ScheduleItem schedule={fromBaseNFormat(schedule)} key={idx} isEditable={false} isRemovable={false} />
              </div>
            ))}
          </div>

          {(!!query.data.entityIds?.length || query.data.global) && (
            <div className="mb-16">
              <HorizontalTitleSeparator title="Targets" variant="copy-4" additionalClass="mb-8" />
              {query.data.global ? (
                <CopyText variant="copy-4">All entities selected</CopyText>
              ) : (
                <>
                  <CopyText variant="copy-4">
                    <span className="mr-8">{`${query.data.entityIds?.length} ${
                      query.data.entityIds?.length === 1 ? 'entity' : 'entities'
                    } selected`}</span>
                    <Button variant="link" size="s" onClick={handleTargetsToggle}>{`${
                      showTargets ? 'Hide all' : 'Show all'
                    }`}</Button>
                  </CopyText>

                  {showTargets && (
                    <div className={styles.targets}>
                      {query.data.entityIds?.map(name => (
                        <CopyText variant="copy-6" key={name}>
                          {name}
                        </CopyText>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      ) : null}
    </>
  );
};
