import Icon from '@components/common/Icon';
import Popover from '@components/common/Popover';
import React, { forwardRef, useMemo, useState } from 'react';
import { Menu, MenuItem, MobileDrawerMenu } from '@components/common/Menu';
import CopyText from '@components/typography/CopyText';
import { WidgetHandle } from '@redux/widgetPage';
import c from 'classnames';
import { useDashboardContext } from '@redux/dashboards/context';
import { widgetDragHandleClass } from '@redux/dashboards';
import useTextSnippets from '@services/useTextSnippets';
import IconButton from '@components/common/IconButton';
import * as widgetSetups from '@components/widgets';
import invariant from '@utils/invariant';
import { useInView } from 'react-intersection-observer';
import { WidgetContext } from './WidgetContext';
import { WidgetProps } from './types';

import styles from './Widget.module.scss';

export const Widget = forwardRef<WidgetHandle, WidgetProps>((props, ref) => {
  const {
    id,
    name,
    type,
    config,
    additionalClass,
    hideMenu = false,
    isLocked = false,
    onExpand,
    ...otherProps
  } = props;

  const { Widget: WidgetComponent } = widgetSetups[type];

  const { onEdit, onRemove, onDuplicate, dashboard } = useDashboardContext() ?? {};
  const i18n = useTextSnippets('widgetPage');
  const commonI18n = useTextSnippets('common');

  const { inView, ref: viewRef } = useInView({ triggerOnce: true });
  const [currentTitle, setCurrentTitle] = useState<React.ReactNode | string>(name ?? '');

  const canShowMenu = !hideMenu && id && dashboard;
  const showExpandButton = !!onExpand;

  const context = useMemo(
    () => ({
      props,
      title: currentTitle,
      setTitle: (newTitle?: React.ReactNode | string | null) => setCurrentTitle(newTitle ?? name ?? ''),
    }),
    [currentTitle, name]
  );

  invariant(widgetSetups[type], `Widget: setup for '${type}' is not defined.`);

  return (
    <div
      id={id ? `widget-${id}` : undefined}
      className={c(styles.widget, type && styles[type], additionalClass)}
      ref={viewRef}
    >
      <div
        className={c(styles.header, !isLocked && !hideMenu && c(widgetDragHandleClass, styles.dragHandle), 'header')}
      >
        <CopyText variant="copy-6" additionalClass={styles.name}>
          {currentTitle}
        </CopyText>

        {(canShowMenu || showExpandButton) && (
          <div className={styles.actions}>
            {canShowMenu && (
              <Popover
                minimal
                additionalClass={styles.popover}
                content={
                  <MobileDrawerMenu>
                    <Menu>
                      <MenuItem icon={<Icon name="Edit" />} text={i18n.editWidget} onClick={() => onEdit?.(id)} />
                      <MenuItem
                        disabled={!onDuplicate}
                        icon={<Icon name="Duplicate" />}
                        text={commonI18n.duplicate}
                        onClick={() => onDuplicate?.(id)}
                      />
                      <MenuItem icon={<Icon name="Delete" />} text={commonI18n.delete} onClick={() => onRemove?.(id)} />
                    </Menu>
                  </MobileDrawerMenu>
                }
              >
                <div>
                  <IconButton icon="MoreVertical" size="r" />
                </div>
              </Popover>
            )}

            {showExpandButton && (
              <IconButton icon="NewTab" size="r" onClick={onExpand} additionalClass="ml-4" title="Open in a new tab" />
            )}
          </div>
        )}
      </div>

      <div className={c(styles.body, 'body')}>
        <WidgetContext.Provider value={context}>
          <WidgetComponent ref={ref} config={config} inView={inView} {...otherProps} />
        </WidgetContext.Provider>
      </div>
    </div>
  );
});
