import { object, string, ref, TypeOf } from 'yup';

import { ITextSnippets } from '@services/useTextSnippets';
import { PasswordPolicy } from '@redux/auth';
import { useMemo } from 'react';
import { forgotPasswordConfig } from '@redux/auth/config';
import fillTemplate from '@utils/fillTemplate';

// in general password requirements are: mandatory min length check, uppercase letter, lowercase letter, digit, special character
// medium password means that it is at least {minLength} characters long and meets all the other requirements, or has no digit but meets the rest of the requirements.
export const mediumPasswordRegexTemplate =
  '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{#{{minLength}},}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{#{{minLength}},}))';
// strong password has to meet all the requirements
// also, technically the difference between a good and strong password is the presence of a digit
export const strongPasswordRegexTemplate = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{#{{minLength}},})';

const useSchema = (i18nKeys: ITextSnippets['forgotPassword'], passwordPolicy?: PasswordPolicy) => {
  let passwordRegex = new RegExp(
    fillTemplate(mediumPasswordRegexTemplate, {
      minLength: passwordPolicy?.minPasswordLength || forgotPasswordConfig.DEFAULT_MIN_PASSWORD_LENGTH,
    })
  );

  if (passwordPolicy?.high) {
    passwordRegex = new RegExp(
      fillTemplate(strongPasswordRegexTemplate, {
        minLength: passwordPolicy?.minPasswordLength || forgotPasswordConfig.DEFAULT_MIN_PASSWORD_LENGTH,
      })
    );
  }

  const schema = object({
    currentPassword: string().required(i18nKeys.currentPasswordRequired),
    newPassword: string().matches(passwordRegex, i18nKeys.fieldStrength).required(i18nKeys.newPasswordRequired),
    repeatNewPassword: string()
      .oneOf([ref('newPassword')], i18nKeys.noMatch)
      .required(i18nKeys.confirmPasswordRequired),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, [i18nKeys, passwordPolicy]);
};

export interface NewPasswordSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
