import { range } from 'lodash';
import cronParser, { DayOfTheMonthRange, DayOfTheWeekRange, HourRange, MonthRange, SixtyRange } from 'cron-parser';
import z from 'zod';

export const DEFAULT_CRON_FIELDS = {
  second: range(0, 60) as SixtyRange[],
  minute: range(0, 60) as SixtyRange[],
  hour: range(0, 24) as HourRange[],
  month: range(1, 13) as MonthRange[],
  dayOfWeek: range(0, 7) as DayOfTheWeekRange[],
  dayOfMonth: range(1, 32) as DayOfTheMonthRange[],
};

export const DEFAULT_CRON_STRING = '* * * * *';

export const validationSchema = z.object({
  modeIdx: z.number(),
  startTime: z.date().or(z.null()),
  endTime: z.date().or(z.null()),
  dayRepeatInterval: z.number().optional(),
  monthRepeatInterval: z.number().optional(),
  weekdays: z.array(z.boolean()).optional(),
  custom: z.string().default(DEFAULT_CRON_STRING),
});

export type Schema = z.infer<typeof validationSchema>;

export const INITIAL_VALUES: Schema = {
  modeIdx: 0,
  startTime: null,
  endTime: null,
  dayRepeatInterval: 1,
  monthRepeatInterval: 1,
  weekdays: new Array(7).fill(false),
  custom: DEFAULT_CRON_STRING,
};

export const MODES = {
  Once: 0,
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  Yearly: 4,
  Custom: 5,
};

export function valuesToCronExpression<T extends Schema = Schema>(values: T): string {
  const startHour = values.startTime ? new Date(values.startTime).getHours() : 0;
  const endHour = values.endTime ? new Date(values.endTime).getHours() : 23;

  const startMinute = values.startTime ? new Date(values.startTime).getMinutes() : 0;
  const endMinute = values.endTime ? new Date(values.endTime).getMinutes() : 59;

  switch (values.modeIdx) {
    case MODES.Daily:
      return cronParser
        .fieldsToExpression({
          ...DEFAULT_CRON_FIELDS,
          hour: range(startHour, endHour + 1) as HourRange[],
          minute: range(startMinute, endMinute + 1) as SixtyRange[],
          dayOfMonth: range(1, 32, values.dayRepeatInterval) as DayOfTheMonthRange[],
        })
        .stringify();

    case MODES.Weekly:
      return cronParser
        .fieldsToExpression({
          ...DEFAULT_CRON_FIELDS,
          hour: range(startHour, endHour + 1) as HourRange[],
          minute: range(startMinute, endMinute + 1) as SixtyRange[],
          dayOfWeek: values.weekdays?.reduce(
            (acc, curr, idx) => (curr ? [...acc, idx] : acc),
            [] as number[]
          ) as DayOfTheWeekRange[],
        })
        .stringify();

    case MODES.Monthly:
    case MODES.Yearly:
      return cronParser
        .fieldsToExpression({
          ...DEFAULT_CRON_FIELDS,
          hour: range(startHour, endHour + 1) as HourRange[],
          minute: range(startMinute, endMinute + 1) as SixtyRange[],
          month: range(1, 13, values.monthRepeatInterval) as MonthRange[],
          dayOfMonth: range(1, 32, values.dayRepeatInterval) as DayOfTheMonthRange[],
        })
        .stringify();

    case MODES.Custom:
      return values.custom ?? DEFAULT_CRON_STRING;

    default:
      return DEFAULT_CRON_STRING;
  }
}

// assumes that values are are at regular intervals
const guessInterval = (values: readonly any[]) => {
  if (!values.length) {
    return 1;
  }

  if (values.length === 1) {
    return +values[0];
  } else {
    return +values[1] - +values[0];
  }
};

const guessMode = (values: Omit<Schema, 'modeIdx' | 'custom'>) => {
  if (values.weekdays?.some(day => day)) {
    return MODES.Weekly;
  }

  if (values.monthRepeatInterval && values.monthRepeatInterval > 1) {
    return MODES.Yearly;
  }

  return MODES.Daily;
};

// not every cron expression can be converted to values with these, but rather only those that were generated
// by the valuesToCronExpression function
export function cronExpressionToValues<T extends Schema = Schema>(schedule: string): T {
  const { fields } = cronParser.parseExpression(schedule, { currentDate: new Date() });

  const { minute, hour, dayOfMonth, month, dayOfWeek } = fields;

  const weekdays = new Array(7).fill(false);
  dayOfWeek.forEach(day => (weekdays[day] = true));

  let startTime = null;
  let endTime = null;

  // here we assume that a range was specified and take the first and last values for an hour and minute
  if (hour.length !== DEFAULT_CRON_FIELDS.hour.length) {
    startTime = new Date();
    startTime.setHours(hour[0]);
    endTime = new Date();
    endTime.setHours(hour[hour.length - 1]);

    if (minute.length !== DEFAULT_CRON_FIELDS.minute.length) {
      startTime.setMinutes(minute[0]);
      endTime.setMinutes(minute[minute.length - 1]);
    }
  }

  // TODO: handle 'L' case
  const dayRepeatInterval = guessInterval(dayOfMonth);
  const monthRepeatInterval = guessInterval(month);

  const modeIdx = guessMode({
    startTime,
    endTime,
    dayRepeatInterval,
    monthRepeatInterval,
    weekdays,
  });

  return {
    modeIdx,
    startTime: startTime,
    endTime: endTime,
    dayRepeatInterval,
    monthRepeatInterval,
    weekdays,
    custom: schedule,
  } as T;
}
