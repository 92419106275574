import { z } from 'zod';
import { PaginatedQueryRequest } from './usePaginatedQuery';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import ENDPOINTS from '../endpoints';
import { useValidatedQuery } from '@services/useValidatedQuery';
import QUERY_CONFIGS from '../query-configs';

export const scriptSchema = z.object({
  id: z.string(),
  author: z.string(),
  modified_by: z.string(),
  name: z.string(),
  description: z.string().optional(),
  last_modified: z.number(),
  size: z.number(),
  page: z.string(),
});

const scriptsResponseSchema = z.object({
  result: z.literal('ok'),
  total: z.number(),
  data: z.array(scriptSchema),
});

export type Script = z.infer<typeof scriptSchema>;
export type ScriptsRequest = PaginatedQueryRequest & {
  general?: string;
};
export type ScriptsResponse = z.infer<typeof scriptsResponseSchema>;

export const useScriptsQuery = <T extends ScriptsResponse = ScriptsResponse>(
  searchParams: ScriptsRequest,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.scripts, searchParams, scriptsResponseSchema, {
    ...(QUERY_CONFIGS.cacheDisabled as UseQueryOptions<T, Error>),
    ...queryOptions,
  });
};
