import React from 'react';
import c from 'classnames';
import { Popover2 as BNPopover, Popover2InteractionKind, Popover2Props } from '@blueprintjs/popover2';

import styles from './Popover.module.scss';

export type PopoverProps = {
  additionalClass?: string;
  preserveTarget?: boolean;
  noArrows?: boolean;
  small?: boolean;
  full?: boolean;
} & Popover2Props;

/**
 * https://blueprintjs.com/docs/versions/4/#core/components/popover.props
 * Relevant props:
 * * content - string or JSX, what goes into the popover?
 * autoFocus - bool = true
 * enforceFocus - bool = true
 * disabled - bool = false
 * fill - bool force to take up entire container space = false
 * defaultIsOpen - bool = false
 * canEscapeKeyClose - bool = true
 * minimal - bool, toggle arrow for tooltip
 * onClose - evt => void = noop
 * onClosed - evt => void = noop
 * onOpened - evt => void = noop
 * usePortal - bool, use for complex positioned or overflow disabled elements, performance tradeoff
 */

const Popover: React.FC<PopoverProps> = ({
  children,
  noArrows = false,
  small,
  full,
  additionalClass = '',
  preserveTarget = false,
  modifiers,
  ...rest
}) => (
  <BNPopover
    modifiers={{
      preventOverflow: { enabled: true },
      flip: { enabled: true },
      arrow: { enabled: !noArrows },
      ...modifiers,
    }}
    boundary="clippingParents"
    rootBoundary="viewport"
    {...rest}
    popoverClassName={c(styles.basePopover, full && styles.full, small && styles.small, additionalClass)}
    // we will use modals for this behavior instead
    hasBackdrop={false}
    usePortal={rest.usePortal !== false}
    interactionKind={rest.interactionKind || Popover2InteractionKind.CLICK}
  >
    {preserveTarget ? <div>{children}</div> : children}
  </BNPopover>
);

export default Popover;
