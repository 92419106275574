import logger from '@infrastructure/logging/logger';
import { selectReleaseEmbedLoaded, updateReleaseEmbedLoaded } from '@infrastructure/redux/settings';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { loadScript } from '@utils/misc';
import React from 'react';

export const ReleaseBadge = () => {
  const dispatch = useAppDispatch();
  const isReleaseEmbedLoaded = useAppSelector(selectReleaseEmbedLoaded);

  React.useEffect(() => {
    if (!isReleaseEmbedLoaded) {
      loadScript('https://embed.released.so/1/embed.js')
        .then(() => {
          dispatch(updateReleaseEmbedLoaded(true));
        })
        .catch(() => {
          logger.error('Failed to load Release embed');
        });
    }
  }, [isReleaseEmbedLoaded, dispatch]);

  return isReleaseEmbedLoaded ? (
    <released-badge channel-id="b67d60b5-9058-4291-a022-f4b6c9db395c"></released-badge>
  ) : null;
};
