import { noop } from 'lodash';
import { useState, useEffect, useRef, useCallback } from 'react';

// decided to try a hook-fu madness (ง`_´)ง

type UseFlagsResponse = {
  dataTrue: string[];
  dataFalse: string[];
  setLoading: (flagId?: string, loading?: boolean) => void;
};

type UseFlagsState = Record<string, boolean>;

/**
 * By encapsulating UseFlagsState allows to control a state of many items at once. Items identify
 * by id. Will trigger components re-render every time the state of any flag changes.
 * @param initialState
 * @returns UseFlagsResponse
 */
const useFlags = (initialState: UseFlagsState = {}): UseFlagsResponse => {
  const data = useRef<UseFlagsState>(initialState);
  const returnData = useRef<UseFlagsResponse>({
    dataTrue: [],
    dataFalse: [],
    // this has to be noop, 'cause actual setLoading has not been defined yet
    setLoading: noop,
  });
  const [, setUpdate] = useState(Date.now());

  const setLoading = useCallback(
    (flagId?: string, loading: boolean = false) => {
      if (!flagId) {
        data.current = initialState;
      } else {
        data.current[flagId] = loading;
      }
      returnData.current = {
        dataTrue: Object.keys(data.current).filter(id => data.current[id]),
        dataFalse: Object.keys(data.current).filter(id => !data.current[id]),
        setLoading,
      };
      // force update
      setUpdate(Date.now());
    },
    [setUpdate, initialState]
  );

  // TODO: this better be useLayoutEffect() to reliably avoid possibility of invoking noop setLoading
  useEffect(() => {
    setLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return returnData.current;
};

export default useFlags;
