import React, { ReactElement } from 'react';
import c from 'classnames';
import { Checkbox } from '@components/common/form/Checkbox';
import { defaultCheckboxCellWidth } from '@components/common/Table/config';
import { TableMouseEventHandler } from '@components/common/Table/types';
import styles from './TableCell.module.scss';

type TableCheckboxCellProps<T> = {
  header?: boolean;
  onToggle: (e: React.ChangeEvent<HTMLInputElement>, checked?: boolean, indeterminate?: boolean) => void;
  active?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  onCellClick?: TableMouseEventHandler<T>;
  item?: T;
};

export default function TableCheckboxCell<T extends object>({
  header,
  checked,
  active,
  onToggle,
  indeterminate,
  onCellClick,
  item,
}: TableCheckboxCellProps<T>): ReactElement | null {
  return (
    <td
      className={c(
        styles.tableCell,
        header && styles.headerCell,
        styles.fixedCell,
        styles.checkboxCell,
        checked && styles.selectedCell,
        active && styles.activeCell
      )}
      onClick={e => onCellClick && item && onCellClick(e, item)}
      style={{ width: defaultCheckboxCellWidth, minWidth: defaultCheckboxCellWidth }}
    >
      <Checkbox
        checked={checked}
        additionalClass={styles.checkbox}
        onChange={onToggle}
        size="s"
        indeterminate={indeterminate}
      />
    </td>
  );
}
