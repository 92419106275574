import { MapboxEvent, Marker } from 'react-map-gl';
import React, { FC, useCallback, useMemo } from 'react';
import { ClusterPie, Stats } from './ClusterPie';
import { Cluster } from '../ClusterCache';
import { SitePie } from './SitePie';

export type ClusterMarkerProps = Cluster & {
  onClick?: (e: MapboxEvent<MouseEvent>, cluster: Cluster) => void;
};

export const ClusterMarker: FC<ClusterMarkerProps> = ({ onClick, ...cluster }) => {
  const { lat, lng, entities = 0, maintenance = 0, alerting = 0, type = 'cluster' } = cluster;
  const stats = useMemo(
    () =>
      ({
        green: entities - maintenance - alerting,
        blue: maintenance,
        red: alerting,
      } as Stats),
    [entities, maintenance, alerting]
  );

  const handleClick = useCallback(
    (e: MapboxEvent<MouseEvent>) => {
      onClick?.(e, cluster);
    },
    [cluster, onClick]
  );

  const PieComponent = type === 'cluster' ? ClusterPie : SitePie;

  return (
    <Marker latitude={lat} longitude={lng} onClick={handleClick}>
      <PieComponent stats={stats} />
    </Marker>
  );
};
