import { EditorView, Decoration, MatchDecorator, ViewPlugin, ViewUpdate } from '@codemirror/view';
import { escapeRegExp } from 'lodash';

class MatchDecoratorPlugin {
  decorations: any;
  constructor(view: EditorView, private decorator: MatchDecorator) {
    this.decorations = this.decorator.createDeco(view);
  }

  update(update: ViewUpdate): void {
    if (update.docChanged || update.viewportChanged) {
      this.decorations = this.decorator.createDeco(update.view);
    }
  }
}

export const keywordDecorator = (highlightMatches: string[] = []) => {
  const escapedMatches = highlightMatches.map(escapeRegExp).filter(match => match.trim() !== '');

  // handle empty array case
  if (escapedMatches.length === 0) {
    return ViewPlugin.define(() => ({}), { decorations: () => Decoration.none });
  }

  const decorator = new MatchDecorator({
    regexp: new RegExp(`(${escapedMatches.join('|')})`, 'g'),
    decoration: Decoration.mark({ class: 'bn-highlight' }),
  });

  return ViewPlugin.define(view => new MatchDecoratorPlugin(view, decorator), {
    decorations: v => v.decorations,
  });
};
