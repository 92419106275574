import { InterfaceAlertName } from '@components/connected/InterfacesTable/types';
import { CHILD_ENTITY_INFO_ITEMS, ENTITY_INFO_ITEMS } from '@constants/entity';
import { Alert } from '@infrastructure/api/BaseNClient/useAlertsQuery';
import { EntityDetails } from '@redux/inventoryPage';

export const getEntityProperty = (entity: EntityDetails, propName: string) =>
  entity.data?.override?.[propName] ?? entity?.data?.original?.[propName];

export const getEntityTitle = (entity: EntityDetails, propName: string) =>
  (entity.parent ? CHILD_ENTITY_INFO_ITEMS : ENTITY_INFO_ITEMS)[propName];

export const hasAtLeastOneProperty = (entity: EntityDetails) =>
  Object.keys(entity.parent ? CHILD_ENTITY_INFO_ITEMS : ENTITY_INFO_ITEMS).find(
    key => !!getEntityProperty(entity, key)
  );

export const getAlertFQID = (alert: Alert) => `${alert.info.name}:${alert.info.channel}`;

export const toAlertMap = (alerts: Alert[]) =>
  alerts.reduce((obj, alert) => {
    return alert ? { ...obj, [getAlertFQID(alert)]: alert } : obj;
  }, {} as Record<InterfaceAlertName, Alert>) ?? {};

// NOTE: basically Entity is a subset of EntityDetails except parentid prop
export const entityDetailsToEntity = ({ id, name, customerid, customer, parent: parentid }: EntityDetails) => ({
  id,
  name,
  customer,
  customerid,
  parentid,
});
