import { rem } from './utils';

export default {
  theme: {
    fontFamily: {
      sans: ['Open Sans', 'Arial', 'monospace'],
      code: ['Monaco'],
    },
    fontSize: {
      ...rem(10),
      ...rem(12),
      ...rem(14),
      ...rem(16),
      ...rem(18),
      ...rem(20),
      ...rem(22),
      ...rem(24),
      ...rem(28),
      ...rem(30),
      ...rem(40),
      ...rem(44),
    },
    lineHeight: {
      1: 1,
      ...rem(12),
      ...rem(16),
      ...rem(18),
      ...rem(20),
      ...rem(22),
      ...rem(24),
      ...rem(28),
      ...rem(30),
      ...rem(32),
      ...rem(38),
      ...rem(40),
      ...rem(46),
      ...rem(48),
      initial: 'initial',
      inherit: 'inherit',
      normal: 'normal',
    },
    letterSpacing: {
      normal: `${0.3 / 16}rem`,
    },
    fontWeight: {
      normal: '400',
      medium: '600',
      bold: '700',
    },
  },

  plugins: theme => ({
    '.copy-1': {
      fontSize: theme('fontSize.16'),
      lineHeight: theme('lineHeight.24'),
      fontWeight: theme('fontWeight.medium'),
    },
    '.copy-2': {
      fontSize: theme('fontSize.16'),
      lineHeight: theme('lineHeight.24'),
      fontWeight: theme('fontWeight.normal'),
    },
    '.copy-3': {
      fontSize: theme('fontSize.14'),
      lineHeight: theme('lineHeight.20'),
      fontWeight: theme('fontWeight.medium'),
    },
    '.copy-4': {
      fontSize: theme('fontSize.14'),
      lineHeight: theme('lineHeight.20'),
      fontWeight: theme('fontWeight.normal'),
    },
    '.copy-5': {
      fontSize: theme('fontSize.12'),
      lineHeight: theme('lineHeight.16'),
      fontWeight: theme('fontWeight.medium'),
    },
    '.copy-6': {
      fontSize: theme('fontSize.12'),
      lineHeight: theme('lineHeight.16'),
      fontWeight: theme('fontWeight.normal'),
    },
    '.copy-7': {
      fontSize: theme('fontSize.10'),
      lineHeight: theme('lineHeight.14'),
      fontWeight: theme('fontWeight.medium'),
    },
    '.copy-8': {
      fontSize: theme('fontSize.10'),
      lineHeight: theme('lineHeight.14'),
      fontWeight: theme('fontWeight.normal'),
    },
    '.copy-9': {
      fontSize: theme('fontSize.8'),
      lineHeight: theme('lineHeight.12'),
      fontWeight: theme('fontWeight.normal'),
    },
    '.headline-1': {
      fontSize: theme('fontSize.24'),
      lineHeight: theme('lineHeight.32'),
      fontWeight: theme('fontWeight.bold'),
      '@screen desktop': {
        fontSize: theme('fontSize.28'),
        lineHeight: theme('lineHeight.40'),
      },
    },
    '.headline-2': {
      fontSize: theme('fontSize.24'),
      lineHeight: theme('lineHeight.32'),
      fontWeight: theme('fontWeight.normal'),
      '@screen desktop': {
        fontSize: theme('fontSize.28'),
        lineHeight: theme('lineHeight.40'),
      },
    },
    '.headline-3': {
      fontSize: theme('fontSize.22'),
      lineHeight: theme('lineHeight.30'),
      fontWeight: theme('fontWeight.medium'),
      '@screen desktop': {
        fontSize: theme('fontSize.24'),
        lineHeight: theme('lineHeight.32'),
      },
    },
    '.headline-4': {
      fontSize: theme('fontSize.22'),
      lineHeight: theme('lineHeight.30'),
      fontWeight: theme('fontWeight.normal'),
      '@screen desktop': {
        fontSize: theme('fontSize.24'),
        lineHeight: theme('lineHeight.32'),
      },
    },
    '.headline-5': {
      fontSize: theme('fontSize.18'),
      lineHeight: theme('lineHeight.24'),
      fontWeight: theme('fontWeight.medium'),
      '@screen desktop': {
        fontSize: theme('fontSize.20'),
        lineHeight: theme('lineHeight.28'),
      },
    },
    '.headline-6': {
      fontSize: theme('fontSize.18'),
      lineHeight: theme('lineHeight.24'),
      fontWeight: theme('fontWeight.normal'),
      '@screen desktop': {
        fontSize: theme('fontSize.20'),
        lineHeight: theme('lineHeight.28'),
      },
    },
  }),
};
