import { Table } from '@components/common/Table';
import { Channel } from '@infrastructure/api/BaseNClient/useFilterDataQuery';
import React, { useMemo, FC } from 'react';
import { AggregateMethod } from '@components/widgets/PieWidget/types';
import { capitalize, round } from 'lodash';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import useTextSnippets from '@services/useTextSnippets';
import { BaseChartWidgetSubConfig } from '@redux/widgetPage';
import { presetToPalette } from '@components/common/form/PalettePicker/paletteUtils';
import c from 'classnames';
import colors from '@theme/colors';

import styles from './SummaryView.module.scss';

export type SummaryRow = {
  name: string;
  avg: number;
  max: number;
  min: number;
  last: number;
};

export type SummaryViewProps = {
  channels?: Channel[];
  unit?: string;
  isLoading?: boolean;
  options?: Omit<BaseChartWidgetSubConfig, 'echartsConfig' | 'source' | 'type'>;
  additionalClass?: string;
  onChannelSelected?: (item: SummaryRow) => void;
  channelsSelected?: Record<string, boolean> | null;
};

export const SummaryView: FC<SummaryViewProps> = ({
  channels = [],
  unit,
  isLoading = false,
  options,
  additionalClass,
  onChannelSelected,
  channelsSelected,
}) => {
  const i18n = useTextSnippets('common');
  const { colors: palette } = presetToPalette(options?.palette ?? 'BaseN');

  const isLegendClickable = !!onChannelSelected;

  const columns = useMemo(
    () =>
      [
        {
          id: 'name',
          label: i18n.name,
          valueRenderer(item: SummaryRow, colIdx: number, rowIdx: number) {
            const isUnselected = isLegendClickable && !channelsSelected?.[item.name];
            return (
              <div
                className={c(styles.name, isLegendClickable && styles.clickable, isUnselected && styles.unselected)}
                onClick={onChannelSelected ? () => onChannelSelected(item) : undefined}
              >
                <span
                  className={styles.bullet}
                  style={{
                    backgroundColor: isUnselected
                      ? colors.gray[5]
                      : // amount of colors in palette is limited, so we need to cycle through them
                        ((palette[rowIdx % palette.length] ?? 'transparent') as string),
                  }}
                />
                <span title={item.name}>{item.name}</span>
              </div>
            );
          },
        },
        ...Object.values(AggregateMethod).map(key => ({
          id: key,
          label: `${capitalize(key)}${unit ? ` (${unit})` : ''}`,
          width: 80,
          valueRenderer(item: SummaryRow) {
            return !isNaN(item[key]) ? round(item[key], 2) : '-';
          },
        })),
      ].reduce((config, column) => ({ ...config, [column.id]: column }), {}),
    [colors, i18n.name, unit, onChannelSelected, channelsSelected, isLegendClickable]
  );

  const items = useMemo(
    () =>
      channels.map(({ name, summaries }) => ({
        id: name,
        name,
        ...summaries,
      })),
    [channels]
  );

  const config = useMemo(
    () => ({
      sortable: false,
      hasToolbar: false,
      paginated: false,
      selectable: false,
    }),
    []
  );

  return (
    <div className={c(styles.summaryView, additionalClass)}>
      <Table<SummaryRow> columns={columns} items={items} config={config} />
      {isLoading && <LoadingSpinner centered additionalClass="my-20" />}
    </div>
  );
};
