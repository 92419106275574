import React, { FC, useEffect, useCallback, useMemo, useRef, useLayoutEffect, useState } from 'react';
import MapGL, { NavigationControl, ViewStateChangeEvent, MapRef, MapProps as ReactMapProps } from 'react-map-gl';
import c from 'classnames';
import { API_URL, MAPTILER_KEY } from '@config';
import { getUserToken } from '@utils/token';
import { ImageLoader, ImageSource } from './ImageLoader';
import { isEmpty } from 'lodash';
import maplibregl, { RequestParameters, StyleSpecification } from 'maplibre-gl';
import { clear as clearOnElementResize, bind as onElementResize } from 'size-sensor';
import { MAX_ZOOM, MIN_ZOOM } from '@constants/map';
import { Viewport, VECTOR_TILES, MapMode } from './types';

import styles from './Map.module.scss';

export type MapProps = ReactMapProps & {
  viewport: Partial<Viewport>;
  mode?: MapMode;
  showControls?: boolean;
  icons?: Record<string, ImageSource>;
  onViewportChange?: (e: ViewStateChangeEvent) => void;
  onMissingImage?: (imgId: string) => void;
  additionalClass?: string;
  interactiveLayerIds?: string[];
};

const Map: FC<MapProps> = ({
  viewport,
  mode = 'LIGHT',
  showControls = true,
  additionalClass,
  icons = {},
  onViewportChange,
  onMissingImage,
  // If specified, pointer event (mousemove, click etc.) listeners will be triggered only if its location is within
  // a visible feature in these layers: https://visgl.github.io/react-map-gl/docs/api-reference/map#interactivelayerids
  interactiveLayerIds,
  children,
  ...props
}) => {
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const token = useMemo(() => getUserToken() ?? '', []);

  const config = useMemo(
    () =>
      ({
        version: 8,
        sources: {
          osm: {
            type: 'raster',
            tiles: [VECTOR_TILES[mode]?.replace(':mapTilerKey', MAPTILER_KEY)],
            tileSize: 512,
            maxzoom: MAX_ZOOM,
            minzoom: MIN_ZOOM,
          },
        },
        layers: [
          {
            id: 'osm',
            type: 'raster',
            source: 'osm',
          },
        ],
        // TODO research if using this open source service has any negative consequences
        // font distributing service, without this textual layers cannot be added (apparently there can be other
        // or self-hosted services too, but the following one works)
        // https://maplibre.org/maplibre-gl-js-docs/style-spec/glyphs/
        glyphs: 'https://fonts.openmaptiles.org/{fontstack}/{range}.pbf',
      } as StyleSpecification),
    [mode]
  );

  // injecting Authentication header for local requests
  const transformRequest = useCallback(
    (url: string = '/'): RequestParameters => {
      const baseUrl = API_URL ?? '/';

      return url?.startsWith(baseUrl)
        ? {
            url,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            credentials: 'same-origin',
          }
        : { url };
    },
    [token]
  );

  // Maplibre (and neither Mapbox) doesn't support SVG icons, only PNG sprites, so SVGs and single PNGs we have to load ourselves
  useLayoutEffect(
    () => {
      const map = mapRef?.getMap();

      let imgLoader: ImageLoader;
      if (map && !isEmpty(icons)) {
        imgLoader = new ImageLoader({ map, imgSources: icons, onMissingImage });
      }

      return () => {
        imgLoader?.destroy();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapRef]
  );

  // monitor size change of the container and resize Map accordingly
  useEffect(() => {
    onElementResize(mapContainerRef.current, () => {
      const mapInstance = mapRef?.getMap();

      // @ts-ignore addressing: https://basencorp.atlassian.net/browse/UX-579
      if (mapInstance && !mapInstance?._removed) {
        mapInstance.resize();
      }
    });

    return () => {
      if (mapContainerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearOnElementResize(mapContainerRef.current);
      }
    };
  }, [mapRef]);

  const handleError = useCallback((ex: ErrorEvent) => {
    console.error(ex.error?.message);
  }, []);

  return (
    <div className={c(styles.map, additionalClass)} ref={mapContainerRef}>
      <MapGL
        mapLib={maplibregl}
        {...viewport}
        // @ts-ignore - Type 'maplibregl.StyleSpecification' is not assignable to type 'mapboxgl.Style'
        mapStyle={config}
        minZoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        // @ts-ignore - Type 'maplibregl.ErrorEvent' is not assignable to type 'mapboxgl.ErrorEvent'
        onError={handleError}
        onMove={onViewportChange}
        transformRequest={transformRequest}
        interactiveLayerIds={interactiveLayerIds}
        ref={setMapRef}
        {...props}
      >
        {showControls && <NavigationControl showCompass />}

        {children}
      </MapGL>
    </div>
  );
};

export default Map;
