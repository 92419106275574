import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardWithDetails, NewDashboard, DashboardsState, Dashboard } from './types';
import { DASHBOARD_CONFIGS, TEMP_WIDGET_ID } from './configs';
import { PaginationParams } from '@infrastructure/redux/types';
import { DEFAULT_PAGINATION } from '@components/common/Table/config';
import { RowHeight } from '@components/common/Table';

export const EMPTY_DASHBOARD: NewDashboard = {
  name: 'New Dashboard Name',
  meta: DASHBOARD_CONFIGS.defaultLayout,
  widgets: [],
  config: {
    draft: true,
  },
};

const initialState: DashboardsState = {
  areLoaded: false,
  items: {},
  currentState: {
    isLocked: true,
    isTimeControlled: false,
    dirty: false,
  },
  dialogs: {
    renameActive: false,
    deleteActive: false,
  },
  currentlyActiveItem: null,
  currentlyActiveItems: [], // used for multi select
  filters: {
    generalFilter: '',
    discreteFilters: {},
  },
  pagination: null,
  sort: null,
  rowHeight: 'medium' as RowHeight,
};

const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    toggleLock(state) {
      state.currentState.isLocked = !state.currentState.isLocked;
    },

    toggleTimeControl(state) {
      state.currentState.isTimeControlled = !state.currentState.isTimeControlled;
    },

    dashboardsLoaded(state, { payload }: PayloadAction<DashboardWithDetails[]>) {
      state.items = payload.reduce(
        (acc, c) => ({ ...acc, [c.id]: state.items[c.id]?.detailsLoaded ? state.items[c.id] : c }),
        {}
      );
    },

    addDashboard(state, { payload }: PayloadAction<DashboardWithDetails>) {
      state.items[payload.id] = payload;
    },

    removeDashboard(state, { payload }: PayloadAction<string>) {
      delete state.items[payload];
    },

    setDashboard(state, { payload }: PayloadAction<DashboardWithDetails>) {
      state.items[payload.id] = payload;
      state.currentState.dirty = false;
    },

    // is meant for changes that do not automatically sync with BE (like in the case of setDashboard)
    updateDashboard(state, { payload }: PayloadAction<DashboardWithDetails>) {
      state.items[payload.id] = payload;
      state.currentState.dirty = true;
    },

    resetDirty(state) {
      state.currentState.dirty = false;
    },

    updateDashboardField(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        field: keyof DashboardWithDetails;
        value: DashboardWithDetails[keyof DashboardWithDetails];
      }>
    ) {
      state.items[payload.id] = { ...state.items[payload.id], [payload.field]: payload.value };
    },

    createStart(state) {
      state.currentState.isLocked = false;
      state.currentState.dirty = false;
    },

    cancelTemporaryWidget(state, { payload }: PayloadAction<string>) {
      const { meta } = state.items[payload];

      Object.keys(meta).forEach(bpKey => {
        meta[bpKey] = meta[bpKey].filter(item => item.i !== TEMP_WIDGET_ID);
      });
    },

    createReset(state) {
      state.currentState.isLocked = true;
      state.currentState.dirty = false;
    },

    updateSearch(state, { payload }: PayloadAction<string | undefined>) {
      state.filters.generalFilter = payload ?? '';
    },

    updateFilters(state, { payload }: PayloadAction<Dictionary<any>>) {
      state.filters.discreteFilters = payload;
    },

    renameDashboardStart(state, { payload: dashboard }: PayloadAction<Dashboard>) {
      state.dialogs.renameActive = true;
      state.currentlyActiveItem = dashboard;
    },

    renameDashboardEnd(state) {
      state.dialogs.renameActive = false;
      state.currentlyActiveItem = null;
    },

    deleteDashboardStart(state, { payload: dashboards }: PayloadAction<Dashboard[]>) {
      state.dialogs.deleteActive = true;
      state.currentlyActiveItems = dashboards;
    },

    deleteDashboardEnd(state) {
      state.dialogs.deleteActive = false;
      state.currentlyActiveItems = [];
    },

    setDashboardFavorite(
      state,
      { payload: { dashboardId, favorite = false } }: PayloadAction<{ dashboardId: string; favorite?: boolean }>
    ) {
      if (state.items[dashboardId]) {
        const newTags = state.items[dashboardId]?.tags?.filter(tag => tag !== 'favorite') ?? [];
        state.items[dashboardId].tags = favorite ? [...newTags, 'favorite'] : newTags;
      }
    },

    updateDashboards(state, { payload }: PayloadAction<DashboardWithDetails[]>) {
      state.items = payload.reduce((acc, c) => ({ ...acc, [c.id]: c }), {});
      state.areLoaded = true;
    },

    updatePagination(state, { payload }: PayloadAction<Partial<PaginationParams>>) {
      state.pagination = {
        ...DEFAULT_PAGINATION,
        ...state.pagination,
        ...payload,
      };
    },

    updateSorting(state, { payload }: PayloadAction<DashboardsState['sort']>) {
      state.sort = payload;
    },

    updateRowHeight(state: DashboardsState, { payload }: PayloadAction<RowHeight>) {
      state.rowHeight = payload;
    },
  },
});

export const {
  resetDirty,
  toggleLock,
  toggleTimeControl,
  createStart,
  updateSearch,
  updateFilters,
  removeDashboard,
  addDashboard,
  setDashboard,
  updateDashboard,
  updateDashboardField,
  dashboardsLoaded,
  createReset,
  cancelTemporaryWidget,
  renameDashboardStart,
  renameDashboardEnd,
  deleteDashboardStart,
  deleteDashboardEnd,
  setDashboardFavorite,
  updateDashboards,
  updatePagination,
  updateSorting,
  updateRowHeight,
} = dashboardsSlice.actions;

export default dashboardsSlice;
