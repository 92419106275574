import { useEffect, DependencyList } from 'react';
import throttle from 'lodash/throttle';

const useResizeEffect = (callback: () => void, dependencyList: DependencyList = []) => {
  useEffect(() => {
    callback();
    const resizeHandler = throttle(callback, 200);
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList);
};

export default useResizeEffect;
