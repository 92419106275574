import React, { useRef } from 'react';
import { Form, TextField, SubmitButton, FormSubmitOptions, FormikProps } from '@components/common/form';
import Link from '@components/common/Link';
import CopyText from '@components/typography/CopyText';
import Headline from '@components/typography/Headline';
import BaseNLogo from '@components/common/BaseNLogo';
import useTextSnippets from '@services/useTextSnippets';
import ROUTES from '@infrastructure/routes';
import Notification from '@components/common/Notification';
import noop from '@utils/noop';

import getSchema, { ForgotPasswordSchema } from './ForgotPasswordForm.schema';

import styles from './style.module.scss';

export type ForgotPasswordFormProps = {
  submitError?: boolean;
  clearError?: () => void;
  initialValues?: ForgotPasswordSchema;
  onSubmit: (values: ForgotPasswordSchema, helpers: FormSubmitOptions<ForgotPasswordSchema>) => void | Promise<any>;
};

function ForgotPasswordForm({
  clearError = noop,
  submitError,
  initialValues = { email: '' },
  onSubmit,
}: ForgotPasswordFormProps) {
  const forgotPasswordSnippets = useTextSnippets('forgotPassword');
  const validationSchema = getSchema(forgotPasswordSnippets);
  const { title, subTitleOne, subTitleTwo, goBack, inputLabel, sendEmail, emailFailed } = forgotPasswordSnippets;
  const { trademarks } = useTextSnippets('copyRight');
  const forgotPasswordFormRef = useRef<FormikProps<ForgotPasswordSchema>>(null);

  return (
    <div className={styles.forgotPasswordForm}>
      <div className="vbox">
        <BaseNLogo additionalClass={styles.logo} />
      </div>
      {submitError && (
        <div className={styles.errorContainer}>
          <Notification type="error" title={emailFailed} onClose={clearError} />
        </div>
      )}
      <Form
        innerRef={forgotPasswordFormRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        additionalClass={styles.form}
      >
        <Headline additionalClass="mb-16 text-black" variant="headline-5">
          {title}
        </Headline>
        <CopyText additionalClass="mb-16 text-black block" variant="copy-4">
          {subTitleOne}
        </CopyText>
        <CopyText additionalClass="mb-16 text-black block" variant="copy-4">
          {subTitleTwo}
        </CopyText>

        <TextField size="l" label={inputLabel} name="email" autoComplete="username" />

        <SubmitButton size="l" variant="fillBlueDark" fullWidth>
          {sendEmail}
        </SubmitButton>

        <Link centered href={ROUTES.login} additionalClass="mt-24 mb-56 text-center copy-4" variant="classic">
          {goBack}
        </Link>

        <CopyText variant="copy-6" additionalClass={styles.trademarks}>
          {trademarks}
        </CopyText>
      </Form>
    </div>
  );
}

export default ForgotPasswordForm;
