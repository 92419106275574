import React from 'react';
import c from 'classnames';
import { RadioGroupContext, RadioGroupContextType } from './RadioGroupContext';

import styles from './Radio.module.scss';

export type RadioGroupProps = RadioGroupContextType & {
  inline?: boolean;
  additionalClass?: string;
};

const RadioGroup: React.FC<RadioGroupProps> = ({ inline = false, additionalClass, children, ...otherProps }) => {
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <RadioGroupContext.Provider value={{ ...otherProps }}>
      <div className={c(styles.radioGroup, inline && styles.inline, additionalClass)} role="radiogroup">
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
};

export default RadioGroup;
