import Modal from '@components/common/Modal';
import { ScriptPicker } from '@components/pages/ScriptRunPage/components/steps/ScriptPicker';
import { Script } from '@infrastructure/api/BaseNClient/useScriptsQuery';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import ROUTES from '@infrastructure/routes';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export type LinkScriptModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  selectedItems: EntityDetails[];
};

export const LinkScriptModal = ({ isOpen = false, selectedItems, onClose }: LinkScriptModalProps) => {
  const navigate = useNavigate();

  const handleSelectScript = React.useCallback(
    (script: Script | null) => {
      if (script) {
        navigate(ROUTES.editScript.replace(':scriptId', script.id), {
          state: { entities: selectedItems.map(({ id, name }) => ({ id, name })) },
        });
      }
    },
    [navigate, selectedItems]
  );

  return (
    <Modal isOpen={isOpen} usePortal title="Pick a Script" onClose={onClose} customFooter={null}>
      <ScriptPicker onChange={handleSelectScript} />
    </Modal>
  );
};
