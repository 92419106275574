import { TableColumns } from '@components/common/Table';
import { NoteWithEntity } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { toIdMap } from '@utils/misc';
import { formatEpochDate } from '@utils/date';
import { capitalize, floor } from 'lodash';
import { BackupStatus } from '@components/pages/ConfigDiffPage/components/BackupStatus';

export const getTableColumns = (): TableColumns<NoteWithEntity> => {
  return toIdMap([
    {
      id: 'timestamp',
      label: 'Version',
      valueRenderer({ timestamp }: NoteWithEntity) {
        return floor(timestamp / 1000);
      },
    },
    {
      id: 'changedOn',
      label: 'Changed on',
      valueRenderer({ timestamp }: NoteWithEntity) {
        return formatEpochDate(timestamp);
      },
    },
    {
      id: 'author',
      label: 'Changed by',
      width: 150,
    },
    {
      id: 'reason',
      label: 'Backed up',
      align: 'center',
      width: 100,
      valueRenderer(note: NoteWithEntity) {
        return (
          <div className="w-full text-center">
            <BackupStatus note={note} />
          </div>
        );
      },
    },
  ]);
};
