import { useDashboardByIdQuery } from '@infrastructure/api/BaseNClient';
import { useFavoritesQuery } from '@infrastructure/api/BaseNClient/useFavoritesQuery';
import { useState, useEffect } from 'react';

const useFavoritesFor = (dashboardId: string) => {
  const [returnData, setReturnData] = useState<{ isLoading: boolean; data?: any[] }>({
    isLoading: false,
    data: undefined,
  });
  const favoritesQuery = useFavoritesQuery();
  const dashboardQuery = useDashboardByIdQuery({ dashboardId });

  useEffect(() => {
    const isLoading = favoritesQuery.isLoading || dashboardQuery.isLoading;

    let data;

    if (!isLoading) {
      const selectedIds = dashboardQuery.data?.categories
        ?.filter(({ category_type }) => category_type === 'favorite')
        .map(({ id }) => id);

      data = favoritesQuery.data?.data.map(item => ({
        id: item.id,
        name: item.name,
        checked: selectedIds?.includes(item.id),
      }));
    }
    setReturnData({ isLoading, data });
  }, [favoritesQuery.isLoading, dashboardQuery.isLoading, favoritesQuery.data, dashboardQuery.data]);

  return returnData;
};

export default useFavoritesFor;
