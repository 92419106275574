/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
import resolveConfig from 'tailwindcss/resolveConfig';
import get from 'lodash/get';
import tailwindConfig from '../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export default function useTheme<T = string>(path: string = '') {
  return get(fullConfig.theme, path) as unknown as T;
}
