import IconButton, { IconButtonProps } from '@components/common/IconButton';
import { setNotification } from '@infrastructure/redux/notifications';
import { useAppDispatch } from '@infrastructure/redux/store';
import React, { useCallback } from 'react';

interface CopyToClipboardButtonProps extends Omit<IconButtonProps, 'icon' | 'onClick'> {
  textToCopy: string;
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ textToCopy, ...props }) => {
  const dispatch = useAppDispatch();

  const handleCopyClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      dispatch(
        setNotification({
          type: 'success',
          title: 'Copied to clipboard',
        })
      );
    } catch (err) {
      console.error('Failed to copy text:', err);
      dispatch(
        setNotification({
          type: 'error',
          title: 'Failed to copy to clipboard',
        })
      );
    }
  }, [dispatch, textToCopy]);

  return <IconButton title={'Copy to clipboard'} onClick={handleCopyClick} {...props} icon="Duplicate" />;
};
