import React, { useState, useCallback } from 'react';
import useTextSnippets from '@services/useTextSnippets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { IssueView, deleteView, updateView, selectIssueViews } from '@redux/issues';
import Headline from '@components/typography/Headline';
import ItemGridDND from '@components/common/ItemGridDND/ItemGridDND';
import { IssueViewGroups, IssueViewDescription } from './components';
import CopyText from '@components/typography/CopyText';
import Icon from '@components/common/Icon';
import IconButton from '@components/common/IconButton';
import ROUTES from '@infrastructure/routes';
import withConfirmation from '@services/withConfirmation';
import { useMutation } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { setNotification } from '@redux/notifications';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { DropResult } from 'react-beautiful-dnd';
import { useApiClient } from '@infrastructure/api/useApiClient';
import { GenericOkApiResponse } from '@infrastructure/api/types';
import BasePage from '../../components/BasePage';
import Error500Page from '@components/pages/Error500Page';
import { useNavigate } from 'react-router-dom';
import HorizontalSeparator from '@components/separators/HorizontalSeparator';

import styles from './IssueViewManager.module.scss';

type Props = {
  error?: boolean;
};

const IssueViewManager: React.FC<Props> = withConfirmation(({ confirmOperation, error }) => {
  if (error) {
    return <Error500Page />;
  }

  const dispatch = useAppDispatch();
  const [deleteViewId, setDeleteViewId] = useState<string>('');
  const issueViewManagerI18n = useTextSnippets('issueViewManager');
  const views = useAppSelector(selectIssueViews);
  const updateViewMutation = useApiClient<GenericOkApiResponse, IssueView>('updateIssueView', {
    noDefaultSuccess: true,
  });
  const navigate = useNavigate();

  const deleteViewMutation = useMutation((id: string) => apiClient.delete(ENDPOINTS.deleteIssueView, { id }), {
    onSuccess: (data, variables) => {
      dispatch(setNotification({ type: 'success', description: issueViewManagerI18n.viewDeleted }));
      dispatch(deleteView(variables));
      setDeleteViewId('');
    },
    onError: () => {
      dispatch(setNotification({ type: 'error', description: issueViewManagerI18n.deleteError }));
      setDeleteViewId('');
    },
  });

  const handleCreateNew = useCallback(() => {
    navigate(ROUTES.issueViewCreate);
  }, [navigate]);

  const handleEdit = useCallback(
    (item: IssueView) => {
      navigate(ROUTES.issueViewEdit.replace(':viewId', item.id));
    },
    [navigate]
  );

  const handleDelete = useCallback(
    (item: IssueView) => {
      void confirmOperation({
        title: issueViewManagerI18n.deleteConfirmTitle,
        contents: issueViewManagerI18n.deleteConfirmMessage,
        modalProps: { danger: true },
      }).then(result => {
        if (result) {
          setDeleteViewId(item.id);
          deleteViewMutation.mutate(item.id);
        }
      });
    },
    [
      confirmOperation,
      deleteViewMutation,
      issueViewManagerI18n.deleteConfirmMessage,
      issueViewManagerI18n.deleteConfirmTitle,
    ]
  );

  const handleDragEnd = (result: DropResult) => {
    if (result.destination) {
      const destinationOrder = result.destination.index;
      const destination = Object.values(views).find(view => view.order === destinationOrder);
      const source = views[result.draggableId];

      if (source && destination) {
        const sourceIndex = source.order;
        const destinationIndex = destination.order;

        if (sourceIndex !== destinationIndex) {
          const newSourceView = {
            ...views[destination.id],
            order: sourceIndex,
          };

          const newDestinationView = {
            ...views[source.id],
            order: destinationIndex,
          };

          dispatch(updateView(newSourceView));
          dispatch(updateView(newDestinationView));
          updateViewMutation.mutate(newSourceView);
          updateViewMutation.mutate(newDestinationView);
        }
      }
    }
  };

  const itemContentRenderer = useCallback(
    (item: IssueView) => (
      <div className="flex items-center w-full">
        <div className="flex items-center flex-1">
          <div className="flex flex-col w-full">
            <CopyText variant="copy-1" additionalClass="mb-8">
              {item.id === 'default' ? issueViewManagerI18n.defaultIssueManager : item.name}
            </CopyText>
            <IssueViewDescription view={item} />
            {/* <IssueViewFields view={item} customFields /> */}
            {/* <IssueViewSortColumn view={item} /> */}
            {/* <IssueViewFields view={item} /> */}
            <IssueViewGroups view={item} />
          </div>
        </div>
        <div className="flex items-center justify-center">
          {deleteViewId === item.id && <LoadingSpinner />}
          {item.id === 'default' && <Icon name="Lock" size="r" additionalClass="cursor-not-allowed text-gray-4 mr-4" />}
          {deleteViewId !== item.id && item.id !== 'default' && (
            <IconButton icon="Edit" size="r" additionalClass="text-dark" onClick={() => handleEdit(item)} />
          )}
          {deleteViewId !== item.id && item.id !== 'default' && (
            <IconButton icon="Delete" size="r" additionalClass="text-dark" onClick={() => handleDelete(item)} />
          )}
        </div>
      </div>
    ),
    [deleteViewId, issueViewManagerI18n.defaultIssueManager, handleDelete, handleEdit]
  );

  return (
    <BasePage
      level={2}
      subPages={[{ title: issueViewManagerI18n.issueViewManager, path: ROUTES.issueViewManager }]}
      pageControls={[
        {
          id: 'createView',
          onClick: handleCreateNew,
          ariaLabel: issueViewManagerI18n.createNew,
          icon: 'Add',
          showLabelOnMobile: false,
          hideIconOnDesktop: false,
          label: issueViewManagerI18n.createNew,
        },
      ]}
    >
      <div className={styles.viewManagerContainer}>
        <Headline variant="headline-5" color="dark">
          {issueViewManagerI18n.title}
        </Headline>
        <CopyText variant="copy-4" color="dark" additionalClass="mb-16">
          {issueViewManagerI18n.featureDescription}
        </CopyText>
        <CopyText variant="copy-1" color="dark">
          {issueViewManagerI18n.availableViews}
        </CopyText>
        <CopyText variant="copy-4" color="dark">
          {issueViewManagerI18n.viewExplanationTemporary}
        </CopyText>

        <HorizontalSeparator noSpacing additionalClass="my-16" />

        <ItemGridDND<IssueView>
          id="issue-view-manager"
          freezeFirstItem
          onDragEnd={handleDragEnd}
          additionalClass="my-24"
          items={views}
          itemContentRenderer={itemContentRenderer}
        />
      </div>
    </BasePage>
  );
});

export default IssueViewManager;
