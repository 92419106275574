import React, { MouseEventHandler } from 'react';
import c from 'classnames';
import styles from './style.module.scss';
import Icon, { IconNames } from '../Icon';
import { NavLink } from 'react-router-dom';

export interface LinkProps {
  disabled?: boolean;
  variant?: 'classic' | 'black';
  href: string;
  centered?: boolean;
  block?: boolean;
  icon?: IconNames;
  additionalClass?: string;
  tabIndex?: number;
  onClick?: MouseEventHandler;
  testid?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
}

const Link: React.FC<LinkProps> = ({
  additionalClass,
  centered = false,
  disabled = false,
  variant,
  tabIndex,
  icon,
  onClick,
  block = false,
  href,
  children,
  testid,
  target,
  ...rest
}) => {
  const handleClick = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    onClick?.(e);
  }, []);

  const content = (
    <>
      {icon && <Icon name={icon} additionalClass="mr-8" />}
      {children}
    </>
  );

  return (
    <div
      className={c(
        styles.linkContainer,
        block && styles.isBlock,
        centered && styles.centered,
        variant && styles[variant],
        disabled && styles.disabled,
        additionalClass
      )}
      tabIndex={tabIndex}
      onClick={handleClick}
    >
      {target ? (
        <a href={href} target={target}>
          {content}
        </a>
      ) : (
        <NavLink to={href} {...rest} data-testid={testid ? `NavLink::${testid}` : undefined}>
          {content}
        </NavLink>
      )}
    </div>
  );
};

export default Link;
