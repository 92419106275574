import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import { NoteWithEntity } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import React, { FC } from 'react';
import { SessionInfoGrid } from './SessionInfoGrid';
import { SessionEntry, useSessionByIdQuery } from '@infrastructure/api/BaseNClient/useSessionByIdQuery';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import Card from '@components/common/Card/Card';
import { CodeEditor } from '@components/common/CodeEditor/CodeEditor';
import { CopyToClipboardButton } from '@components/connected/CopyToClipboardButton/CopyToClipboardButton';
import { Position } from '@blueprintjs/core';
import c from 'classnames';
import { useAppDispatch } from '@infrastructure/redux/store';
import { setNotification } from '@infrastructure/redux/notifications';

import styles from '@components/pages/ConfigDiffPage/components/DiffPanel.module.scss';

const getRawSessionLog = (data: SessionEntry[]): string => {
  return data.map(entry => entry.content).join('');
};

export type SessionDetailsProps = DetailsPanelProps & {
  item?: NoteWithEntity | null;
};

export const SessionDetails: FC<SessionDetailsProps> = ({ item }) => {
  const dispatch = useAppDispatch();

  const query = useSessionByIdQuery(
    {
      sessionId: item?.link!,
      entityId: item?.entityid!,
      user: item?.author!,
      rawdata: true,
    },
    {
      enabled: !!(item?.link && item.entityid && item.author),
    }
  );

  const recording = getRawSessionLog(query.data?.data ?? []);

  if (query.isError || !(item?.link && item.entityid && item.author)) {
    dispatch(
      setNotification({
        type: 'error',
        title: 'Error',
        description: `Invalid ${!item?.link ? 'sessionId (link)' : !item.entityid ? 'entityId' : 'user (author)'}`,
      })
    );
  }

  return (
    <>
      <SessionInfoGrid note={item} additionalClass="mb-16" />

      {query.isLoading ? (
        <LoadingSpinner centered />
      ) : query.data ? (
        <Card
          header={
            <div className="w-full hbox">
              <div className="copy-4">Session record</div>

              <CopyToClipboardButton
                size="s"
                textToCopy={recording}
                tooltipProps={{ targetProps: { className: 'ml-auto -mr-6' }, position: Position.TOP }}
              />
            </div>
          }
          additionalClass={c(styles.diffPanel, 'h-full')}
          noPadding
        >
          <CodeEditor value={recording} readOnly additionalClass={styles.codeEditor} />
        </Card>
      ) : null}
    </>
  );
};
