import { noop } from 'lodash';
import { createContext, useContext } from 'react';
import { WidgetProps } from './types';

export type WidgetContextType = {
  props: WidgetProps | null;
  title: React.ReactNode | string;
  setTitle: (title?: React.ReactNode | string | null) => void;
};

export const WidgetContext = createContext<WidgetContextType>({
  props: null,
  title: '',
  setTitle: noop,
});

export function useWidgetContext(): WidgetContextType | null {
  return useContext(WidgetContext);
}
