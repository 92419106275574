import Card from '@components/common/Card/Card';
import { NetworkStatusIndicator } from '@components/common/NetworkStatusIndicator';
import { Overlay } from '@components/common/Overlay';
import { useTerminal } from '@infrastructure/push-api/useTerminal';
import Icon from '@components/common/Icon';
import Button from '@components/common/Button';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { selectToken } from '@infrastructure/redux/auth';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { LoginForm } from './LoginForm';
import React from 'react';
import { AuthFormSchema } from '@components/pages/ScriptRunPage/components/steps/ReviewStep.schema';
import { setNotification } from '@infrastructure/redux/notifications';
import { Timer } from '@components/common/Timer';
import CopyText from '@components/typography/CopyText';

import styles from './Terminal.module.scss';

type TerminalProps = {
  entityId: string;
  isAuthRequired?: boolean;
};

export const Terminal = ({ entityId, isAuthRequired }: TerminalProps) => {
  const dispatch = useAppDispatch();
  const [error, setError] = React.useState<Error | null>(null);
  const { getFailureHandler } = useGenericMutationHandlers();
  const token = useAppSelector(selectToken);
  const [now, forceUpdate] = React.useState(Date.now());

  const handleCopy = React.useCallback(
    (text?: string) => {
      if (text) {
        dispatch(
          setNotification({
            type: 'success',
            title: 'Copied to clipboard',
          })
        );
      } else {
        dispatch(
          setNotification({
            type: 'error',
            title: 'Copying to clipboard is denied',
          })
        );
      }
    },
    [dispatch]
  );

  const handlePaste = React.useCallback(
    (text?: string) => {
      if (!text) {
        dispatch(
          setNotification({
            type: 'error',
            title: 'Pasting from clipboard is denied',
          })
        );
      }
    },
    [dispatch]
  );

  const handleOutput = React.useCallback((text: string) => {
    // reset the timer
    forceUpdate(Date.now());
  }, []);

  const { ref, isReady, connectTerminal, disconnectTerminal, isConnecting } = useTerminal({
    token,
    entity: entityId,
    onError: getFailureHandler(setError),
    onConnect: () => setError(null),
    onCopy: handleCopy,
    onPaste: handlePaste,
    onOutput: handleOutput,
  });

  const handleLogin = React.useCallback(
    (values: AuthFormSchema, { setSubmitting }) => {
      connectTerminal({
        params: {
          credentials: values,
        },
      }).catch(() => {
        setSubmitting(false);
      });
    },
    [connectTerminal]
  );

  return (
    <Card
      header={
        <div className="w-full gap-8 hbox">
          <Icon name="Terminal" />
          <div>Terminal</div>

          <div className="gap-4 hbox">
            <NetworkStatusIndicator size="xs" variant={isReady ? 'ok' : 'error'} />
            <CopyText variant="copy-5" color={isReady ? 'green' : 'red'} additionalClass="uppercase">
              {isReady ? 'Connected' : 'Disconnected'}
            </CopyText>

            <div className="gap-4 uppercase copy-7 text-blue-gray hbox">
              {isReady && (
                <>
                  <CopyText variant="copy-7">Timeout in:</CopyText>
                  <Timer endTime={now + 1000 * 60 * 15} />
                </>
              )}
              {!!error && <CopyText variant="copy-7">Error: {error.message}</CopyText>}
            </div>
          </div>

          {isReady && (
            <Button additionalClass="ml-auto" size="s" variant="fillOutline" onClick={disconnectTerminal}>
              End Session
            </Button>
          )}
        </div>
      }
      noPadding
      additionalClass="h-full"
    >
      <div className={styles.tty}>
        <div ref={ref} />
        {!isReady && (
          <Overlay>
            {isAuthRequired ? (
              <LoginForm onSubmit={handleLogin} />
            ) : (
              <Button onClick={() => connectTerminal()} disabled={isConnecting}>
                {isConnecting && <LoadingSpinner centered additionalClass="mr-8" />}
                Start Session
              </Button>
            )}
          </Overlay>
        )}
      </div>
    </Card>
  );
};
