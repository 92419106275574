import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { IconNames } from '@components/common/Icon';
import React from 'react';
import { retrieveItem, storeItem } from '@utils/storage';
import { TagInputControl } from '@components/common/form/TagInput/TagInputControl';
import { TimeControl } from '@components/common/form/TimeControl';
import { DateRange } from '@blueprintjs/datetime';
import moment from 'moment';
import { BaseSelectItem } from '@components/common/form/Select';
import { ItemRendererProps } from '@blueprintjs/select';
import { MenuItem } from '@components/common/Menu';
import CopyText from '@components/typography/CopyText';
import useBreakpoint from '@services/useBreakpoint';
import IconButton from '@components/common/IconButton';
import { Widget } from '@components/layout/Widget';
import Headline from '@components/typography/Headline';
import { ChartWidgetSubConfig, DashboardWidget, DataSource } from '@infrastructure/redux/widgetPage';
import { DEFAULT_TIMESERIES_WIDGET_CONFIG } from '@infrastructure/redux/widgetPage/constants';
import { routeToSiteUrl, toQueryStr } from '@utils/url';
import ROUTES from '@infrastructure/routes';
import { getOptionsFromWidgets, getWidgetsByGroup, getWidgetsCombined } from './getWidgets';
import c from 'classnames';
import Accordion from '@components/common/Accordion/Accordion';
import { Panel } from '@components/common/Panel';
import { isEmpty, sortBy } from 'lodash';

import styles from './MetricsTab.module.scss';

enum ColumnView {
  SINGLE = 'single',
  DOUBLE = 'double',
  TRIPLE = 'triple',
}

const COLUMN_VIEW_ICONS: Record<ColumnView, IconNames> = {
  [ColumnView.SINGLE]: 'CheckboxUnselected',
  [ColumnView.DOUBLE]: 'Grid2Columns',
  [ColumnView.TRIPLE]: 'FilterColumns',
};

const MULTI_COLUMN_VIEW_MODE_ID = 'single-entity-column-view-mode-id';

type MetricsetSelectItem = BaseSelectItem<string> & {
  metrics?: number;
};

export const MetricsTab: React.FC<{ entity: EntityDetails }> = ({ entity }) => {
  const { widgets: widgetsByGroup, options } = React.useMemo(() => {
    const widgets = getWidgetsByGroup(entity);
    const options = getOptionsFromWidgets(getWidgetsCombined(entity).widgets);
    return {
      widgets,
      options,
    };
  }, []);

  const {
    breakpoints: { isDesktop },
  } = useBreakpoint();

  const [visibleMetricsets, setVisibleMetricsets] = React.useState<MetricsetSelectItem[]>();
  const [activeMetricsets, setActiveMetricsets] = React.useState<boolean[]>(new Array(options?.length).fill(false));
  const [areAllMetricsetsActive, setAreAllMetricsetsActive] = React.useState(false);

  const now = moment();
  // styling for default range vs. user selected differs
  const [isDateRangeUserSelected, setIsDateRangeUserSelected] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>([
    now.clone().subtract(1, 'day').toDate(),
    now.clone().toDate(),
  ]);

  const [columnView, setColumnView] = React.useState<ColumnView>(
    retrieveItem<ColumnView>(MULTI_COLUMN_VIEW_MODE_ID) ?? ColumnView.SINGLE
  );

  const handleToggleColumnView = React.useCallback(() => {
    setColumnView(prev => {
      const modes = Object.values(ColumnView);
      const nextIndex = (modes.indexOf(prev) + 1) % modes.length;
      const newMode = modes[nextIndex];
      storeItem<ColumnView>(MULTI_COLUMN_VIEW_MODE_ID, newMode);
      return newMode;
    });
  }, []);

  const handleDateRangeChange = React.useCallback((newDateRange: DateRange) => {
    setDateRange(newDateRange);
    setIsDateRangeUserSelected(true);
  }, []);

  const handleDateRangeReset = React.useCallback(() => {
    const newNow = moment();
    setDateRange([newNow.clone().subtract(1, 'day').toDate(), newNow.clone().toDate()]);
    setIsDateRangeUserSelected(false);
  }, []);

  const handleExpand = React.useCallback((source: DataSource) => {
    const url = `${ROUTES.chart}?${toQueryStr(source)}`;
    window.open(routeToSiteUrl(url), '_blank');
  }, []);

  const handleToggleAll = React.useCallback(() => {
    setAreAllMetricsetsActive(prev => {
      const newState = !prev;
      setActiveMetricsets(new Array(activeMetricsets?.length).fill(newState));
      return newState;
    });
  }, [activeMetricsets]);

  const handleVisibleMetricsChange = React.useCallback(
    (newVisibleMetricsets: MetricsetSelectItem[]) => {
      setVisibleMetricsets(newVisibleMetricsets);
      setAreAllMetricsetsActive(false);
      setActiveMetricsets(
        new Array(newVisibleMetricsets?.length ? newVisibleMetricsets.length : options.length).fill(false)
      );
    },
    [options]
  );

  const renderMetricsetOption = React.useCallback(
    (option: MetricsetSelectItem, { handleClick, modifiers }: ItemRendererProps) => {
      if (visibleMetricsets?.map(({ value }) => value).includes(option.value)) {
        return null;
      }

      return (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={option.value}
          onClick={handleClick}
          text={
            <div className="hbox">
              <div>{option.label}</div>
              <CopyText variant="copy-6" additionalClass="ml-auto text-blue-gray-base pl-16">
                {option.metrics}
              </CopyText>
            </div>
          }
        />
      );
    },
    [visibleMetricsets]
  );

  return (
    <div className="pb-16">
      <div className="items-stretch vbox">
        <div className={styles.filterBar}>
          <Headline variant="headline-6">Metrics</Headline>

          <TimeControl
            active={isDateRangeUserSelected}
            dateRange={dateRange}
            onChange={handleDateRangeChange}
            onReset={handleDateRangeReset}
          />

          <div className="gap-8 hbox">
            <div className="hbox">
              <CopyText variant="copy-7">Rows:</CopyText>
              <IconButton
                additionalClass="inline-flex"
                title={areAllMetricsetsActive ? 'Collapse all' : 'Expand all'}
                icon={areAllMetricsetsActive ? 'Collapse' : 'Expand'}
                onClick={handleToggleAll}
                size="xs"
                tooltipProps={{ position: 'left' }}
              />
            </div>

            {isDesktop && (
              <div className="hbox">
                <CopyText variant="copy-7">Grid:</CopyText>
                <IconButton
                  additionalClass="inline-flex"
                  title="Toggle column view"
                  icon={COLUMN_VIEW_ICONS[columnView]}
                  onClick={handleToggleColumnView}
                  size="xs"
                  tooltipProps={{ position: 'left' }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full my-8">
          <TagInputControl<MetricsetSelectItem>
            placeholder="All metricsets"
            inline
            options={options}
            optionRenderer={renderMetricsetOption}
            noInfo
            onChange={handleVisibleMetricsChange}
            value={visibleMetricsets}
            matchTargetWidth={false}
            fullWidth
          />
        </div>
      </div>

      {!isEmpty(widgetsByGroup) ? (
        <Accordion onStateChange={setActiveMetricsets} variant="group" additionalClass={styles.accordion}>
          {sortBy(
            Object.keys(widgetsByGroup).filter(
              metricset => !visibleMetricsets?.length || visibleMetricsets.map(({ value }) => value).includes(metricset)
            )
          ).map((metricset: string, idx: number) => (
            <Panel
              key={metricset}
              title={
                <div className="w-full hbox">
                  <CopyText variant="copy-2">{metricset}</CopyText>
                  <CopyText variant="copy-6" additionalClass="ml-auto text-blue-gray-base">
                    {`${widgetsByGroup[metricset].length} metric${widgetsByGroup[metricset].length > 1 ? 's' : ''}`}
                  </CopyText>
                </div>
              }
              active={!!activeMetricsets?.[idx]}
              additionalClasses={{ content: c(styles.charts, styles[columnView]) }}
              showBottomToggler
            >
              {widgetsByGroup[metricset].map(({ id, config: { config, ...otherProps } }: DashboardWidget) => (
                <Widget
                  key={id}
                  {...otherProps}
                  config={{ ...DEFAULT_TIMESERIES_WIDGET_CONFIG, ...config, dateRange } as ChartWidgetSubConfig}
                  isLocked
                  additionalClass={c(styles.widget, styles[otherProps.type])}
                  onExpand={() => (config?.source ? handleExpand(config.source) : undefined)}
                />
              ))}
            </Panel>
          ))}
        </Accordion>
      ) : (
        <Headline variant="headline-6" additionalClass="py-40 text-center">
          No widgets
        </Headline>
      )}
    </div>
  );
};
