const ROUTES = {
  account: '/management/account',
  alertById: '/alerts/:alertId',
  alerts: '/alerts',
  dashboardById: '/dashboards/:dashboardId',
  dashboards: '/dashboards',
  entities: '/entities',
  entityById: '/entities/:entityId',
  forgotPassword: '/forgot-password',
  home: '/overview',
  inventory: '/inventory',
  issueById: '/issue-manager/:issueId',
  issueManager: '/issue-manager',
  issueViewCreate: '/management/issue-views/create',
  issueViewEdit: '/management/issue-views/:viewId',
  issueViewManager: '/management/issue-views',
  keyboardShortcuts: '/keyboard-shortcuts',
  login: '/login',
  management: '/management',
  mapEntities: '/map/entities',
  support: '/support',
  widget: '/dashboards/:dashboardId/:widgetId',
  userSettings: '/management/account',
  maintenanceWindows: '/tools/maintenance-windows',
  createMaintenanceWindow: '/tools/maintenance-windows/create',
  singleMaintenanceWindow: '/tools/maintenance-windows/:id',
  editMaintenanceWindow: '/tools/maintenance-windows/:id/edit',
  logbook: '/tools/logbook',
  chart: '/chart',
  configManager: '/config-manager/:entityId',
  configDiff: '/config-manager/diff',
  configSearch: '/tools/configuration-manager',
  terminal: '/terminal/:entityId',
  standaloneTerminal: '/standalone-terminal/:entityId',
  sessionLogs: '/session-logs/:entityId',
  scripts: '/tools/scripts',
  runScript: '/tools/scripts/run',
  trackScript: '/jumpstation/batch/log/:batchId',
  addScript: '/tools/scripts/add',
  editScript: '/tools/scripts/:scriptId',
  releaseNotes: '/release-notes',
  tools: '/tools',
};

export type Routes = typeof ROUTES;

export default ROUTES;
