import React, { useState, useRef, useEffect, useMemo } from 'react';
import c from 'classnames';
import { Input } from '@components/common/form/Input';
import Icon from '@components/common/Icon';
import { Position } from '@blueprintjs/core';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { GetItemQuery, InfiniteList, InfiniteListItem, InfiniteListItemProps } from './InfiniteList';
import useDebouncedValue from '@services/useDebouncedValue';
import { identity } from 'lodash';
import Popover from '@components/common/Popover';

import selectStyles from '@components/common/form/Select/Select.module.scss';
import styles from './InfiniteSelect.module.scss';

export type InfiniteSelectProps<TValue = any> = {
  disabled?: boolean;
  filterable?: boolean;
  searchable?: boolean;
  clearable?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  matchTargetWidth?: boolean;
  getItemsQuery: GetItemQuery<TValue>;
  itemToLabel?: (item: TValue) => string;
  /** TValue to item identifier transformer (defaults to itemToLabel) */
  itemToId?: (item: TValue) => string;
  onChange?: (item: InfiniteListItem<TValue> | null) => void;
  onQueryChange?: (query: string | null) => void;
  rowContentsRenderer?: (item: TValue) => JSX.Element;
  value?: InfiniteListItem<TValue> | string | null;
  rowHeight?: number;
  labelProp?: string;
  additionalClass?: string; // @deprecated - use additionalClasses.root
  additionalClasses?: {
    root?: string;
    input?: string;
  };
};

export function InfiniteSelect<TValue = any>({
  disabled,
  filterable,
  searchable,
  clearable = true,
  fullWidth = true,
  loading,
  readOnly = false,
  matchTargetWidth = true,
  onChange,
  onQueryChange,
  value,
  rowHeight,
  getItemsQuery,
  itemToLabel = identity,
  rowContentsRenderer,
  additionalClass,
  additionalClasses = {},
  ...props
}: InfiniteSelectProps<TValue>) {
  const [query, setQuery] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isDisabled = disabled || loading;
  const hasClear = clearable && (query || value);
  const debouncedFilterStr = useDebouncedValue<string | null>(query, 300);
  const itemToId = props.itemToId ?? itemToLabel;

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(false);
    onChange?.(null);
    setQuery(null);
  };

  const handleSelect = React.useCallback(
    (item: InfiniteListItem<TValue>) => {
      setIsOpen(false);
      setQuery(null);
      onChange?.(item);
    },
    [onChange]
  );

  const handleLoadMore = (items: TValue[]) => {
    // auto-select first item if there's no value and Select cannot be empty
    if (!clearable && !value && items.length) {
      onChange?.({ idx: 0, label: itemToLabel(items[0]), value: items[0] });
    }
  };

  const handleKeyDown = () => {
    setIsOpen(true);
    inputRef.current?.focus();
  };

  const handlePopoverInteraction = (newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
  };

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery ?? null);
    onQueryChange?.(newQuery);
  };

  const handleOpened = () => {
    if (filterable) {
      inputRef.current?.focus();
    }
  };

  const selectedItems = useMemo(() => (value ? [value] : undefined), [value]);

  const defaultRowContentsRenderer = React.useCallback(
    (item: TValue) => <span>{itemToLabel(item)}</span>,
    [itemToLabel]
  );

  const rowRenderer = React.useCallback(
    ({ index, key, style, item }: InfiniteListItemProps<TValue>) => {
      const isLoaded = !!item;
      const isString = typeof value === 'string';
      const isSelected =
        (isString && value === itemToId(item)) ||
        (!isString && !!value?.value && itemToId(value.value) === itemToId(item));

      return (
        <div
          style={style}
          className={c(styles.item, isSelected && styles.selected)}
          key={key}
          onClick={isLoaded ? () => handleSelect?.({ label: itemToLabel(item), value: item, idx: index }) : undefined}
        >
          {isLoaded ? (rowContentsRenderer ?? defaultRowContentsRenderer)(item) : <LoadingSpinner size="s" />}
        </div>
      );
    },
    [value, itemToId, rowContentsRenderer, defaultRowContentsRenderer, handleSelect, itemToLabel]
  );

  const getActionIcon = () => {
    if (readOnly) {
      return undefined;
    }

    if (loading) {
      return <LoadingSpinner centered />;
    }

    return (
      <>
        {hasClear && (
          <Icon name="Close" additionalClass="mr-8" onClick={!isDisabled ? handleClear : undefined} size="xs" />
        )}
        <Icon
          name="ChevronOpen"
          additionalClass={selectStyles.toggler}
          size="s"
          onClick={!isDisabled ? handleToggle : undefined}
        />
      </>
    );
  };

  let resolvedValue = null;
  if (filterable && query !== null) {
    resolvedValue = query;
  } else if (typeof value === 'string') {
    resolvedValue = value;
  } else if (value?.value) {
    resolvedValue = itemToLabel(value.value);
  }

  const inputProps = {
    ...props,
    ref: inputRef,
    onChange: handleQueryChange,
    onKeyDown: filterable && !readOnly ? handleKeyDown : undefined,
    value: resolvedValue,
    additionalClasses: {
      root: c(selectStyles.baseSelectInput, isOpen && selectStyles.isOpen),
      input: c(additionalClasses.input, hasClear && selectStyles.hasClear),
    },
    actionIcon: getActionIcon(),
    readOnly: !filterable || readOnly,
    disabled: isDisabled,
  };
  return (
    <Popover
      minimal
      fill={fullWidth}
      enforceFocus={!filterable}
      matchTargetWidth={matchTargetWidth}
      isOpen={isOpen}
      position={Position.BOTTOM_LEFT}
      popoverClassName={c(styles.popover)}
      content={
        // NOTE: react-virtualized is not well suited for dynamic query updates, so we force re-create the list for each new query
        <>
          {searchable && <Input
            icon="Search"
            type="text"
            size="m"
            placeholder="Filter..."
            value={query ?? undefined}
            onChange={handleQueryChange}
            additionalClasses={{ root: styles.input }}
          />}
          <InfiniteList<TValue>
            key={debouncedFilterStr ?? '__all'}
            selectedItems={selectedItems}
            onSelect={handleSelect}
            onLoadMore={handleLoadMore}
            getItemsQuery={getItemsQuery}
            itemToLabel={itemToLabel}
            filterStr={debouncedFilterStr ?? undefined}
            rowRenderer={rowRenderer}
            rowHeight={rowHeight}
          /></>
      }
      onOpened={handleOpened}
      onInteraction={!isDisabled && !readOnly ? handlePopoverInteraction : undefined}
    >
      <Input {...inputProps} value={inputProps.value ?? ''} type="text" />
    </Popover>
  );
}
