import React, { FC, useCallback, useMemo } from 'react';
import { Chart } from '@components/common/Chart';
import { StripeColor } from '@utils/issue';
import colors from '@theme/colors';
import { Stats } from './ClusterPie';
import c from 'classnames';
import Icon from '@components/common/Icon';

import styles from './SitePie.module.scss';

export type SitePieProps = {
  stats: Stats;
  hideGreen?: boolean;
  showTooltip?: boolean;
  renderer?: 'svg' | 'canvas';
  additionalClass?: string;
};

const getConfig = ({
  stats,
  hideGreen = false,
  showTooltip = true,
}: Omit<SitePieProps, 'stats'> & { stats: Required<Record<StripeColor, number>> }) => {
  return {
    tooltip: {
      show: showTooltip,
      trigger: 'item',
      formatter: '{c}',
      padding: [2, 5],
      className: styles.popup,
      appendToBody: true,
      // position: ['50%', '50%'],
    },
    series: [
      {
        type: 'pie',
        radius: [0, '76%'],
        label: {
          show: false,
        },
        tooltip: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        animation: false,
        emphasis: {
          scale: false,
          silent: true,
          itemStyle: {
            opacity: 1,
            color: colors.blue.n,
          },
        },
        data: [{ value: 1, itemStyle: { color: colors.blue.n } }],
      },
      // styles for outer rim
      {
        type: 'pie',
        radius: ['76%', '90%'],
        emphasis: {
          scaleSize: 2,
          scale: showTooltip,
        },
        animation: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: Object.keys(stats).map(color => ({
          value: stats[color as StripeColor],
          itemStyle: {
            // @ts-ignore
            color: colors.alert[color],
            opacity: color === 'green' && hideGreen ? 0 : 1,
          },
        })),
      },
    ],
  };
};

export const SitePie: FC<SitePieProps> = ({ renderer = 'svg', additionalClass, ...props }) => {
  const config = useMemo(() => {
    const { stats, ...otherProps } = props;

    return getConfig({
      stats: { green: 0, lime: 0, yellow: 0, orange: 0, red: 0, gray: 0, maintenance: 0, black: 0, ...stats },
      ...otherProps,
    });
  }, [props]);

  const initProps = useMemo(() => ({ width: 24, height: 24, renderer }), [renderer]);

  const captureMouseMove = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div
      className={c(styles.sitePie, additionalClass)}
      // without this, hover event leaks through and might trigger a tooltip for some other feature
      onMouseMove={captureMouseMove}
    >
      <Chart echartsConfig={config} initProps={initProps} />
      <Icon name="BuildingSite" size="xs" additionalClass={styles.icon} />
    </div>
  );
};
