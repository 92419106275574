import { Checkbox } from '@components/common/form/Checkbox';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GetItemQuery, InfiniteList } from './InfiniteList';
import { identity } from 'lodash';
import { Input } from '@components/common/form/Input';
import useDebouncedValue from '@services/useDebouncedValue';

import styles from './InfiniteChecklist.module.scss';

export type InfiniteChecklistProps<TValue = any> = {
  filterable?: boolean;
  loading?: boolean;
  value?: TValue[];
  getItemsQuery: GetItemQuery<TValue>;
  /** TValue to item, label transformer (defaults to identity) */
  itemToLabel?: (item: TValue) => string;
  /** TValue to item identifier transformer (defaults to itemToLabel) */
  itemToId?: (item: TValue) => string;
  rowContentsRenderer?: (item: TValue) => JSX.Element;
  onChange?: (items: TValue[]) => void;
};

export function InfiniteChecklist<TValue = any>({
  filterable,
  loading,
  value,
  getItemsQuery,
  itemToLabel = identity,
  rowContentsRenderer,
  onChange,
  ...props
}: InfiniteChecklistProps) {
  const [filterStr, setFilterStr] = useState<string>('');
  const debouncedFilterStr = useDebouncedValue<string>(filterStr, 300);

  const itemToId = props.itemToId ?? itemToLabel;
  const selectedItems = useRef<Dictionary<TValue>>({});

  useEffect(() => {
    selectedItems.current = value
      ? value.reduce((obj, curr) => ({ ...obj, [typeof curr === 'string' ? curr : itemToId(curr)]: curr }), {})
      : {};
  }, [value, itemToId]);

  const handleItemSelect = useCallback(
    (item: TValue, checked?: boolean) => {
      if (checked) {
        selectedItems.current[itemToId(item)] = item;
      } else {
        delete selectedItems.current[itemToId(item)];
      }

      onChange?.(Object.values(selectedItems.current));
    },
    [onChange, itemToId]
  );

  const handleFilterChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStr(e.currentTarget?.value);
  }, []);

  const defaultRowContentsRenderer = useCallback((item: TValue) => <span>{itemToLabel(item)}</span>, [itemToLabel]);

  const rowRenderer = useCallback(
    ({ item, key, style }) => {
      const name = itemToId(item);

      return (
        <div className="flex items-center px-8" style={style} key={key}>
          {!!item ? (
            <Checkbox
              name={name}
              size="s"
              checked={!!selectedItems.current[name]}
              additionalClass="mr-8"
              onChange={(_, checked?: boolean) => handleItemSelect(item, checked)}
            />
          ) : (
            <LoadingSpinner centered size="s" />
          )}
          <div className="flex items-center flex-grow w-full min-w-0">
            {(rowContentsRenderer ?? defaultRowContentsRenderer)(item)}
          </div>
        </div>
      );
    },
    [handleItemSelect, loading, itemToId, defaultRowContentsRenderer, rowContentsRenderer]
  );

  return (
    <div className={styles.infiniteChecklist}>
      {filterable && (
        <Input
          icon="Search"
          type="text"
          size="m"
          placeholder="Filter..."
          value={filterStr}
          onChange={handleFilterChange}
          additionalClasses={{ root: styles.input }}
        />
      )}
      <InfiniteList<TValue>
        key={debouncedFilterStr ?? '__all'}
        getItemsQuery={getItemsQuery}
        rowRenderer={rowRenderer}
        filterStr={filterStr ?? undefined}
      />
    </div>
  );
}
