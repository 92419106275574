import React, { useCallback } from 'react';
import PageHeader from '@components/layout/PageHeader';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import useTextSnippets from '@services/useTextSnippets';
import withConfirmation from '@services/withConfirmation';
import { WidgetConfig, reset, resetDirty, selectWidgetPage, updateName } from '@redux/widgetPage';
import ROUTES from '@infrastructure/routes';
import {
  useCreateWidgetMutation,
  WidgetMutationSuccess,
} from '@infrastructure/api/BaseNClient/useCreateWidgetMutation';
import { DashboardWithDetails, setDashboard } from '@redux/dashboards';
import { useUpdateDashboardMutation } from '@infrastructure/api/BaseNClient';
import { replaceIdWithName, replaceDashboardWidget, addWidgetToDashboard } from '@redux/dashboards/dashboard-utils';
import { useUpdateWidgetMutation } from '@infrastructure/api/BaseNClient/useUpdateWidgetMutation';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import useForm, { FormProvider } from '@components/common/form/useForm';
import useSchema, { WidgetNameSchema } from '../WidgetNameForm.schema';
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

import styles from '../WidgetPage.module.scss';

type WidgetPageHeaderProps = {
  dashboard: DashboardWithDetails;
  widgetConfig: WidgetConfig;
  loading?: boolean;
};

export const WidgetPageHeader: React.FC<WidgetPageHeaderProps> = withConfirmation(
  ({ dashboard, widgetConfig, confirmOperation }) => {
    const dispatch = useAppDispatch();
    const i18n = useTextSnippets('widgetPage');
    const { isDirty } = useAppSelector(selectWidgetPage);
    const createWidgetMutation = useCreateWidgetMutation();
    const updateDashboardMutation = useUpdateDashboardMutation();
    const updateWidgetMutation = useUpdateWidgetMutation();
    const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();
    const widgetFormSchema = useSchema();
    const navigate = useNavigate();

    const widgetForm = useForm<WidgetNameSchema>({
      validationSchema: widgetFormSchema,
      initialValues: {
        name: widgetConfig?.name ?? i18n.newWidgetName,
      },
      enableReinitialize: true,
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      onSubmit: values => {
        const newWidget = { ...widgetConfig, name: values.name! };

        // only update name in a persisted way if it's an already saved widget
        if (widgetConfig?.id) {
          updateWidgetMutation.mutate(newWidget, {
            onSuccess: () => {
              dispatch(
                setDashboard(
                  replaceDashboardWidget(dashboard, { id: widgetConfig.id!, config: newWidget }, widgetConfig.id)
                )
              );
            },
            onError: getFailureHandler(),
          });
        }
      },
    });

    const redirectToDashboard = useCallback(() => {
      navigate(ROUTES.dashboardById.replace(':dashboardId', dashboard.id));
    }, [dashboard, navigate]);

    const handleDiscard = () => {
      if (isDirty) {
        void confirmOperation({
          title: i18n.areYouSure,
          contents: i18n.discardWarning,
        }).then(result => {
          if (result) {
            dispatch(reset());
            redirectToDashboard();
          }
        });
      } else {
        redirectToDashboard();
      }
    };

    const handleAdd = () => {
      if (!widgetConfig) {
        return;
      }

      void confirmOperation({
        title: i18n.areYouSure,
        contents: i18n.addWarning,
      }).then(result => {
        if (result) {
          const newDashboard = addWidgetToDashboard(dashboard, {
            id: v4(),
            config: { name: widgetConfig?.name || i18n.newWidgetName, ...widgetConfig },
          });

          updateDashboardMutation.mutate(newDashboard, {
            onSuccess: getSuccessHandler(() => {
              dispatch(setDashboard(newDashboard));
              redirectToDashboard();
            }),
            onError: getFailureHandler(),
          });
        }
      });
    };

    const handleUpdate = () => {
      if (!widgetConfig) {
        return;
      }

      void confirmOperation({
        title: i18n.areYouSure,
        contents: i18n.updateWarning,
      }).then(result => {
        if (result) {
          updateWidgetMutation.mutate(widgetConfig, {
            onSuccess: getSuccessHandler((response: WidgetMutationSuccess) => {
              dispatch(
                setDashboard(
                  replaceDashboardWidget(dashboard, { id: widgetConfig.id!, config: widgetConfig }, widgetConfig.id)
                )
              );
              dispatch(resetDirty());
            }),
            onError: getFailureHandler(),
          });
        }
      });
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(updateName(e.target?.value));
    };

    return (
      <FormProvider form={widgetForm}>
        <PageHeader
          level={2}
          additionalClass={styles.header}
          breadcrumbs
          breadcrumbsProps={{
            inputError: widgetForm.errors.name,
            renderLastAsInput: true,
            breadcrumbInputValue: widgetConfig?.name ?? i18n.newWidgetName,
            onLastBreadcrumbBlur: widgetForm.submitForm,
            onLastBreadcrumbChange: handleNameChange,
            transformLabel: label => replaceIdWithName(label, dashboard),
          }}
          pageControls={[
            {
              id: 'discard',
              onClick: handleDiscard,
              ariaLabel: 'Discard changes',
              hideIconOnDesktop: true,
              icon: 'Close',
              label: i18n.discard,
            },
            !widgetConfig?.id
              ? {
                  id: 'add',
                  variant: 'fillBlueDark',
                  onClick: handleAdd,
                  ariaLabel: 'Add Widget',
                  label: i18n.add,
                  hideIconOnDesktop: true,
                  icon: 'Add',
                  loading: createWidgetMutation.isLoading,
                  disabled: !isDirty || createWidgetMutation.isLoading || widgetForm.isSubmitting,
                }
              : {
                  icon: 'Save',
                  id: 'update',
                  variant: 'fillBlueDark',
                  onClick: handleUpdate,
                  ariaLabel: 'Update Widget',
                  label: i18n.update,
                  hideIconOnDesktop: true,
                  loading: updateWidgetMutation.isLoading,
                  disabled: !isDirty || updateWidgetMutation.isLoading || widgetForm.isSubmitting,
                },
          ]}
        />
      </FormProvider>
    );
  }
);
