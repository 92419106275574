import c from 'classnames';

import styles from './Stepper.module.scss';

type StepProps = {
  title: string;
  enabled?: boolean;
};

export type StepperProps = {
  steps: (StepProps | string)[];
  activeStep?: number;
  onSelectStep?: (step: number) => void;
  additionalClass?: string;
  allEnabled?: boolean;
};

export const Stepper: React.FC<StepperProps> = ({
  steps,
  activeStep = 0,
  onSelectStep,
  additionalClass,
  allEnabled,
}) => {
  return (
    <div className={c(styles.stepper, additionalClass)}>
      {steps.map((step, idx) => {
        const { title, enabled } = typeof step === 'string' ? { title: step, enabled: undefined } : step;

        const isActive = activeStep === idx;
        const isDisabled = idx > activeStep && !enabled && !allEnabled;

        return (
          <div
            key={idx}
            className={c(styles.step, {
              [styles.active]: isActive,
              [styles.disabled]: isDisabled,
            })}
            onClick={!isActive && !isDisabled ? () => onSelectStep?.(idx) : undefined}
          >
            <div className={styles.stepNumber}>
              <span>{idx + 1}</span>
            </div>
            <div className={styles.stepTitle}>{title}</div>
            {idx < steps.length - 1 && <div className={styles.stepLine} />}
          </div>
        );
      })}
    </div>
  );
};
