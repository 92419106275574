import React, { FC } from 'react';
import { useField } from 'formik';
import { PalettePickerControl, PalettePickerControlProps } from './PalettePickerControl';
import { BaseSelectItem } from '../Select/BaseSelect';

export type PalettePickerFieldProps = Omit<PalettePickerControlProps, 'value' | 'defaultValue' | 'onChange'> & {
  name: string;
  defaultValue?: string;
};

export const PalettePickerField: FC<PalettePickerFieldProps> = ({ defaultValue, options, size, ...props }) => {
  const [field, { error, touched }, helpers] = useField({ ...props, defaultValue });

  const handleChange = (item: BaseSelectItem | null) => {
    helpers.setValue(item?.value);
  };

  const selectedOption = options.find(option => option.value === field.value);

  return (
    <PalettePickerControl
      options={options}
      size={size}
      {...props}
      {...field}
      value={selectedOption}
      errorText={touched ? error : undefined}
      onChange={handleChange}
    />
  );
};
