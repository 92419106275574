import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { createLogbookSlice } from '@infrastructure/redux/logbook';
import { Logbook, LogbookProps } from './Logbook';
import { createPortableStore } from '@utils/redux';

let componentCounter = 0;

export type PortableLogbookProps = Omit<LogbookProps, 'actions'> & {
  actionsPrefix?: string;
  entityId?: string;
};

export const PortableLogbook: React.FC<PortableLogbookProps> = ({ actionsPrefix = 'portableLogbook', ...props }) => {
  const { store, actions } = useMemo(
    () => createPortableStore(createLogbookSlice(`${actionsPrefix}-${++componentCounter}`)),
    [actionsPrefix]
  );

  return (
    // NOTE: overriding global store with local one to redirect all selectors and dispatcher
    <Provider store={store}>
      <Logbook actions={actions} {...props} />
    </Provider>
  );
};
