import React, { useCallback, useMemo, useEffect } from 'react';
import {
  Dashboard,
  DashboardWithDetails,
  deleteDashboardStart,
  renameDashboardStart,
  updatePagination,
  selectDashboardsAsArray,
  updateDashboards,
  selectAreDashboardsLoaded,
  updateSorting,
  selectQueryParams,
  DashboardSortingParams,
  updateSearch,
  updateFilters,
  selectResolvedFilters,
  selectResolvedPagination,
  selectResolvedSorting,
  updateRowHeight,
} from '@redux/dashboards';
import useTextSnippets from '@services/useTextSnippets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import Error500Page from '@components/pages/Error500Page';
import { DashboardDialogManager } from '@components/pages/Dashboards/CommonComponents/DashboardDialogManager';
import { DefaultMotion } from '@services';
import { DashboardManagerHeader, SingleDashboardMenu } from './components';
import { RemoteStoredTable, RowHeight, Sort, TableConfig, TableItemAction } from '@components/common/Table';
import { getTableColumns } from '@components/pages/Dashboards/DashboardManagement/utils';
import IconButton from '@components/common/IconButton';
import withCommands, { WithCommands } from '@services/withCommands';
import NoDashboardElements from './components/DashboardManagerHeader/NoDashboardElements';
import { useDashboardsQuery } from '@infrastructure/api/BaseNClient';
import { mapRawToDashboard } from '@redux/dashboards/data-mapper';
import { useLoadingContext } from 'react-router-loading';
import { FieldsConfig } from '@components/common/form/FormBuilder';

import styles from './DashboardManagement.module.scss';

type Props = WithCommands & { error?: boolean };

const DashboardManagement: React.FC<Props> = ({ error, openFavoritesMenuCommand }) => {
  const dispatch = useAppDispatch();
  const loadingContext = useLoadingContext();

  const dashboards = useAppSelector(selectDashboardsAsArray);
  const areLoaded = useAppSelector(selectAreDashboardsLoaded);
  const noDashboardsAvailable = !dashboards?.length && !areLoaded;
  const i18n = useTextSnippets('dashboards');
  const commonI18n = useTextSnippets('common');
  const pagination = useAppSelector(selectResolvedPagination);
  const filters = useAppSelector(selectResolvedFilters);
  const sorting = useAppSelector(selectResolvedSorting);
  const queryParams = useAppSelector(selectQueryParams);

  useEffect(() => {
    document.title = 'Dashboards - BaseN Platform';
  }, []);

  const query = useDashboardsQuery(queryParams, {
    keepPreviousData: true,
  });

  // react-query doesn't invoke onSuccess when data is retrieved from cache... WTF? :'/ so we have to useEffect
  useEffect(() => {
    const { data, total } = query.data ? query.data : { data: [], total: 0 };
    dispatch(updatePagination({ rowCount: total }));
    dispatch(updateDashboards(data.map(mapRawToDashboard)));
    loadingContext.done();
  }, [query.data, dispatch, loadingContext]);

  const handleFavoriteClick = useCallback(
    (e, item) => {
      e.stopPropagation();
      openFavoritesMenuCommand({
        dashboardId: item.id,
        targetEl: e.target as HTMLElement,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePageChange = (newPage: number) => {
    dispatch(updatePagination({ page: newPage }));
  };

  const handleSortChange = (sortBy: string | null, sort: Sort) => {
    dispatch(updateSorting({ sortBy: sortBy as DashboardSortingParams['sortBy'], sort }));
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    dispatch(updatePagination({ rowsPerPage: newRowsPerPage }));
  };

  const handleRename = useCallback(
    (item: DashboardWithDetails) => {
      dispatch(renameDashboardStart(item));
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    (items: DashboardWithDetails[]) => {
      dispatch(deleteDashboardStart(items));
    },
    [dispatch]
  );

  const handleSearch = useCallback(
    (keyword?: string) => {
      dispatch(updateSearch(keyword));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Dictionary<any>) => {
      dispatch(updateFilters(newFilters));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch]
  );

  const handleRowHeightChange = useCallback(
    (newRowHeight: RowHeight) => {
      dispatch(updateRowHeight(newRowHeight));
    },
    [dispatch]
  );

  const dashboardColumns = useMemo(
    () => {
      const columns = getTableColumns(i18n);

      columns.actions = {
        type: 'custom',
        id: 'actions',
        label: commonI18n.actions,
        width: 80,
        align: 'center',
        // eslint-disable-next-line react/no-unstable-nested-components
        valueRenderer(item: Dashboard) {
          const isFavorite = item.categories?.find(({ category_type }) => category_type === 'favorite');

          return (
            <IconButton
              size="s"
              title={i18n.manageFavorites}
              icon={isFavorite ? 'StarFill' : 'StarOutline'}
              onClick={e => handleFavoriteClick(e, item)}
            />
          );
        },
      };

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getTableColumns, handleFavoriteClick]
  );

  // const onDuplicate = useCallback(
  //   (item: DashboardWithDetails) => {
  //     dispatch(duplicateDashboardStart(item));
  //   },
  //   [dispatch]
  // );

  const actions: TableItemAction<Dashboard>[] = useMemo(
    () => [
      // TODO implement duplicate - take dashboard with widgets, clear out all ids, take the user into create mode
      // {
      //   multiAction: false,
      //   disabled: true,
      //   label: commonI18n.duplicate,
      //   icon: 'Duplicate',
      //   handler: (selectedItemIds: string[]) => {
      //     const dashboardToDuplicate = filteredDashboards.find(dashboard => dashboard.id === selectedItemIds[0]);
      //
      //     if (dashboardToDuplicate) {
      //       onDuplicate(dashboardToDuplicate);
      //     }
      //   },
      //   id: 'duplicate',
      // },
      {
        multiAction: false,
        label: commonI18n.rename,
        icon: 'Edit',
        handler: (selectedItems: Dashboard[]) => {
          if (selectedItems.length) {
            handleRename(selectedItems[0]);
          }
        },
        id: 'rename',
      },
      {
        label: commonI18n.delete,
        icon: 'Delete',
        handler: (selectedItems: Dashboard[]) => {
          if (selectedItems.length) {
            handleDelete(selectedItems);
          }
        },
        id: 'delete',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filterConfig: FieldsConfig = useMemo(
    () => [
      {
        type: 'toggle',
        props: {
          name: 'my_dashboards',
          label: i18n.myDashboards,
          type: 'switch',
          defaultValue: filters.discreteFilters.my_dashboards || undefined,
        },
      },
      {
        type: 'toggle',
        props: {
          name: 'favorites_only',
          label: i18n.favoritesOnly,
          type: 'switch',
          defaultValue: filters.discreteFilters.favorites_only || undefined,
        },
      },
    ],
    [i18n, filters.discreteFilters.my_dashboards, filters.discreteFilters.favorites_only]
  );

  const tableConfig: Partial<TableConfig> = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      selectable: true,
      columnsToggleable: false,
      rowHeight: 'large',
      rowHeightToggleable: false,
      hasToolbar: true,
      paginated: true,
      bordered: false,
      ...pagination,
      ...sorting,
    }),
    [pagination, sorting]
  );

  const components = useMemo(
    () => ({
      ContextMenu: SingleDashboardMenu,
    }),
    []
  );

  const contentMotionKey = useMemo(() => {
    if (noDashboardsAvailable) return 'noDashboards';

    return 'dashboardsGrid';
  }, [noDashboardsAvailable]);

  if (error) {
    return <Error500Page />;
  }

  return (
    <>
      <DashboardManagerHeader />
      <div className="min-h-0 flex-container">
        <DefaultMotion key={contentMotionKey} full>
          {noDashboardsAvailable && !areLoaded ? (
            <NoDashboardElements />
          ) : (
            <RemoteStoredTable<Dashboard>
              name="dashboards"
              columns={dashboardColumns}
              items={dashboards ?? []}
              config={tableConfig}
              itemActions={actions}
              filterConfig={filterConfig}
              keyword={filters.generalFilter}
              additionalClasses={{ tableContainer: styles.dashboardTableContainer }}
              onRowsPerPageChange={handleRowsPerPageChange}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              onSearch={handleSearch}
              onFiltersChange={handleFiltersChange}
              onRowHeightChange={handleRowHeightChange}
              components={components}
              isLoading={query.isLoading || query.isFetching}
            />
          )}
        </DefaultMotion>
      </div>
      <DashboardDialogManager />
    </>
  );
};

export default withCommands(DashboardManagement);
