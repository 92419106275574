import { TimeField, WeekdayPickerField } from '@components/common/form';

export const WeeklyMode = () => {
  return (
    <>
      <div className="flex flex-row items-center gap-8 mb-16">
        <TimeField label="From" name="startTime" useAmPm={false} />
        <TimeField label="To" name="endTime" useAmPm={false} />
      </div>

      <WeekdayPickerField name="weekdays" label="On" />
    </>
  );
};
