import colors from './theme/colors';
import typography from './theme/typography';
import spacing from './theme/spacing';
import screens from './theme/screens';
import plugins from './theme/plugins';
import {
  HEADER_HEIGHT,
  PAGE_PLUS_HEADER_HEIGHT,
  HEADER_SEARCH_HEIGHT,
  MOBILE_PAGE_HEADER_HEIGHT,
  TABLET_PAGE_HEADER_HEIGHT,
  PANEL_MAX_HEIGHT,
  CHART_MIN_HEIGHT,
} from './theme/constants';

const fill = {
  white: colors.white,
  green: colors.green,
  red: colors.red,
  dark: colors.dark,
  'blue-light': colors.blue.light,
};

export default {
  content: ['./src/**/*.{tsx,scss}'],
  safelist: [
    'text-alert-red',
    'text-alert-orange',
    'text-alert-yellow',
    'text-alert-green',
    'text-alert-lime',
    'text-alert-gray',
    'text-blue-gray-1',
  ],
  theme: {
    ...typography.theme,
    colors,
    fill,
    screens,
    spacing: {
      header: HEADER_HEIGHT,
      headerSearch: HEADER_SEARCH_HEIGHT,
      tabletPageHeaderHeight: TABLET_PAGE_HEADER_HEIGHT,
      mobilePageHeaderHeight: MOBILE_PAGE_HEADER_HEIGHT,
      ...spacing,
    },
    borderRadius: {
      none: '0',
      sm: '4px',
      lg: '8px',
      xl: '16px',
      full: '9999px',
    },
    boxShadow: {
      none: 'none',
      1: '0px 1px 4px rgba(0, 0, 0, 0.1)',
      2: '0px 2px 8px rgba(0, 0, 0, 0.1)',
      3: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      4: '0px 8px 16px rgba(0, 0, 0, 0.1)',
      5: '0px 16px 32px rgba(0, 0, 0, 0.1)',
      right: '2px 0 7px rgba(0, 0, 0, 0.15)',
      top: '0 0 10px 2px rgba(0, 0, 0, 0.08)',
      bottom: '0 2px 8px rgba(0, 0, 0, 0.1)',
      insetOne: `0px -1px 0px 0px ${colors.blue.gray[6]} inset`,
      modal: '0px 0px 12px rgba(0, 0, 0, 0.1)',
      sm: '0 2px 4px 0 rgba(0, 0, 0, 0.07)',
      md: '0 6px 20px 2px rgba(0, 0, 0, 0.07)',
      lg: '0 10px 25px 2px rgba(0, 0, 0, 0.2)',
    },
    borderWidth: {
      DEFAULT: '1px',
      0: '0',
      1: '1px',
      2: '2px',
      4: '4px',
    },
    opacity: {
      0: '0',
      10: '0.1',
      20: '0.2',
      30: '0.3',
      40: '0.4',
      50: '0.5',
      60: '0.6',
      70: '0.7',
      80: '0.8',
      90: '0.9',
      100: '1',
    },
    rotate: {
      '-180': '-180deg',
      '-90': '-90deg',
      '-45': '-45deg',
      0: '0',
      45: '45deg',
      90: '90deg',
      180: '180deg',
    },
    extend: {
      width: {
        180: '11.25rem', // 180px
        'fit-content': 'fit-content',
        '1xs': '1rem',
        '2xs': '2rem',
        '3xs': '4rem',
        '4xs': '6rem',
        '5xs': '8rem',
        '6xs': '12rem',
        '7xs': '16rem',
        '8xs': '20rem',
        '9xs': '24rem',
        '1xl': '30rem',
        '2xl': '36rem',
        '3xl': '42rem',
        '4xl': '50rem',
        ...spacing,
      },
      inset: {
        '1/5': '20%',
        '2/5': '40%',
        '3/5': '60%',
        '4/5': '80%',
        '1/6': '16.666667%',
        '2/6': '33.333333%',
        '3/6': '50%',
        '4/6': '66.666667%',
        '5/6': '83.333333%',
        '1/12': '8.333333%',
        '2/12': '16.666667%',
        '3/12': '25%',
        '4/12': '33.333333%',
        '5/12': '41.666667%',
        '6/12': '50%',
        '7/12': '58.333333%',
        '8/12': '66.666667%',
        '9/12': '75%',
        '10/12': '83.333333%',
        '11/12': '91.666667%',
      },
      height: {
        header: HEADER_HEIGHT,
        pagePlusHeader: PAGE_PLUS_HEADER_HEIGHT,
        '10/12': '83.333333%',
      },
      minHeight: {
        header: HEADER_HEIGHT,
        headerSearch: HEADER_SEARCH_HEIGHT,
        pagePlusHeader: PAGE_PLUS_HEADER_HEIGHT,
        unset: 'unset',
        ...spacing,
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        chart: CHART_MIN_HEIGHT,
      },
      maxHeight: {
        unset: 'unset',
        0: '0',
        200: '200px',
        header: HEADER_HEIGHT,
        panel: PANEL_MAX_HEIGHT,
        pagePlusHeader: PAGE_PLUS_HEADER_HEIGHT,
      },
      minWidth: {
        ...spacing,
        '1xs': '1rem',
        '2xs': '2rem',
        '3xs': '4rem',
        '4xs': '6rem',
        '5xs': '8rem',
        '6xs': '12rem',
        '7xs': '16rem',
        '8xs': '20rem',
        '9xs': '24rem',
        '1xl': '30rem',
        '2xl': '36rem',
        '3xl': '42rem',
        '4xl': '50rem',
        unset: 'unset',
        'fit-content': 'fit-content',
      },
      maxWidth: {
        ...spacing,
        '1xs': '1rem',
        '2xs': '2rem',
        '3xs': '4rem',
        '4xs': '6rem',
        '5xs': '8rem',
        '6xs': '12rem',
        '7xs': '16rem',
        '8xs': '20rem',
        '9xs': '24rem',
        '1xl': '30rem',
        '2xl': '36rem',
        '3xl': '42rem',
        '4xl': '50rem',
        'fit-content': 'fit-content',
        unset: 'unset',
        screen: '100vw',
        full: '100%',
      },
      zIndex: {
        '-1': -1,
        'table-resize': 20,
        'page-header': 30,
        // 30+1 so box shadow goes over page header
        sidebar: 31,
        header: 40,
        notification: 100,
        overlay: 250,
        modal: 300,
      },
      animation: {
        slideIn: 'slideIn 300ms ease',
        fadeOut: 'fadeOut 500ms ease forwards',
      },
      transitionProperty: {
        height: 'height',
        width: 'width',
        spacing: 'margin, padding',
        margin: 'margin',
        padding: 'padding',
        maxHeight: 'max-height',
      },
      keyframes: {
        slideIn: {
          '0%': { transform: 'translateX(100vw)' },
          '100%': { transform: 'translateX(0)' },
        },
        fadeOut: {
          '0%': { opacity: 100 },
          '100%': { opacity: 0 },
        },
      },
    },
  },
  variants: {
    extend: {
      fill: ['hover'],
    },
  },
  plugins,
};
