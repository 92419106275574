import React, { useCallback, useEffect, useMemo, useState } from 'react';
import c from 'classnames';
import { DEFAULT_TABLE_CONFIG } from './config';
import { InternalTableConfig, TableColumns } from './types';
import { TableConfigContext } from './TableConfigContext';
import { FilterableToolbar } from './components/FilterableToolbar';
import { FiltersDrawer } from './components/TableFilters/FiltersDrawer';
import { FieldsConfig, extractValues } from '../form/FormBuilder';
import Table, { TableProps } from './Table';

import styles from './FilterableTable.module.scss';

export type FilterableTableProps<T extends object = object> = TableProps<T> & {
  filterConfig?: FieldsConfig;
  sidebarFilters?: boolean;
  keyword?: string;
  onSearch?: (keyword?: string) => void;
  onFiltersChange?: (values: Dictionary<any>) => void;
  onFiltersViewToggle?: (filtersInPopup?: boolean) => void;
};

export function FilterableTable<T extends object = object>({
  filterConfig,
  columns,
  sidebarFilters = false,
  keyword,
  onSearch,
  onFiltersChange,
  onFiltersViewToggle,
  config,
  ...props
}: FilterableTableProps<T>) {
  const initialTableConfig = useMemo(() => {
    return {
      ...DEFAULT_TABLE_CONFIG,
      ...config,
    };
  }, [config]);

  const [tableConfig, setTableConfig] = useState<InternalTableConfig>(initialTableConfig);

  useEffect(() => {
    setTableConfig(initialTableConfig);
  }, [initialTableConfig]);

  const handleSingleFiltersChange = useCallback(
    (singleFilters: Dictionary<any>) => {
      onFiltersChange?.({
        ...(filterConfig ? extractValues(filterConfig) : null),
        ...singleFilters,
      });
    },
    [filterConfig, onFiltersChange]
  );

  const filterableColumns = React.useMemo(() => {
    const filters = filterConfig ? extractValues(filterConfig) : {};

    return Object.keys(columns).reduce((acc: TableColumns<T>, key: string) => {
      acc[key] = {
        ...columns[key],
      };
      if (columns[key].filter) {
        acc[key].filter = {
          ...columns[key].filter!,
          onChange: handleSingleFiltersChange,
        };
        acc[key].filtering = acc[key].filtering ?? !!filters[key] ?? false;
      }
      return acc;
    }, {} as TableColumns<T>);
  }, [filterConfig, columns, handleSingleFiltersChange]);

  const components = React.useMemo(() => {
    return {
      TableToolbar: {
        component: FilterableToolbar,
        props: {
          isLoading: tableConfig.loading,
          additionalClass: c(styles.toolbar, !tableConfig.selectable && styles.leftAlign),
          showFiltersToggle: tableConfig.filtersInPopup,
          filters: filterConfig,
          keyword,
          onSearch,
          onFiltersChange,
          onFiltersViewToggle: sidebarFilters ? onFiltersViewToggle : undefined,
        },
      },
      ...props.components,
    };
  }, [
    tableConfig.loading,
    tableConfig.selectable,
    tableConfig.filtersInPopup,
    filterConfig,
    keyword,
    onSearch,
    onFiltersChange,
    sidebarFilters,
    onFiltersViewToggle,
    props.components,
  ]);

  return (
    <div className={styles.filterableTable}>
      {sidebarFilters && !tableConfig.filtersInPopup && !!onFiltersChange && !!filterConfig && (
        <TableConfigContext.Provider value={tableConfig}>
          <FiltersDrawer
            isOpen
            filters={filterConfig}
            onSubmit={onFiltersChange}
            onFiltersViewToggle={sidebarFilters ? onFiltersViewToggle : undefined}
          />
        </TableConfigContext.Provider>
      )}
      <Table<T> {...props} columns={filterableColumns} components={components} config={tableConfig} />
    </div>
  );
}
