import React from 'react';
import Control, { ControlProps } from '../Control/Control';
import { BaseSelectItem } from './BaseSelect';

import { Select, SelectProps } from './Select';

export type SelectControlProps<ItemType extends BaseSelectItem = BaseSelectItem> = ControlProps<
  ItemType | null,
  (item: ItemType) => void
> &
  SelectProps<ItemType>;

export function SelectControl<ItemType extends BaseSelectItem = BaseSelectItem>({
  options,
  placeholder,
  ...props
}: SelectControlProps<ItemType>) {
  return (
    <Control {...props}>
      <Select<ItemType> options={options} placeholder={placeholder} />
    </Control>
  );
}
