import React, { Children, cloneElement, isValidElement, ReactElement, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { getChildIndex } from '@utils/dom';

export type SwitchBaseChildProps<CP extends any = any> = CP & {
  active?: boolean;
  onChange?: (e: React.MouseEvent<HTMLElement>, active?: boolean, idx?: number) => void;
};

type SwitchBaseChild<CP> = ReactElement<SwitchBaseChildProps<CP>>;

export type SwitchBaseProps<CP extends any = any, PP extends any = any> = PP & {
  exclusive?: boolean;
  onStateChange?: (active: boolean[]) => void;
  onChange?: (e: React.MouseEvent<HTMLElement>, idx: number) => void;
  additionalClass?: string;
  children?: SwitchBaseChild<SwitchBaseChildProps<CP>> | SwitchBaseChild<SwitchBaseChildProps<CP>>[];
};

// internal types from react omitted
type SwitchCloneChild<CP> = Omit<SwitchBaseChild<CP>, 'props' | 'type' | 'key'>;

function SwitchBase<ChildProps, PassThroughProps = any>({
  exclusive = false,
  onStateChange,
  onChange,
  children,
  additionalClass,
  ...passThroughProps
}: SwitchBaseProps<ChildProps, PassThroughProps>) {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const childrenArray = Children.toArray(children).filter(isValidElement) as SwitchBaseChild<ChildProps>[];
  const childrenStates = childrenArray.map(child => !!child.props.active);

  const [states, setStates] = useState<boolean[]>(childrenStates);

  useEffect(() => {
    setStates(childrenStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEqual(states, childrenStates)]);

  const handleChange = (e: React.MouseEvent<HTMLElement>, state: boolean) => {
    const idx = getChildIndex(e.currentTarget, wrapperRef.current);
    if (states[idx] === state) {
      return;
    }
    const newStates = [...states];
    if (exclusive) {
      newStates.fill(false);
    }
    newStates[idx] = state;
    setStates(newStates);
    onStateChange?.(newStates);
    onChange?.(e, idx);
  };

  return (
    <div ref={wrapperRef} className={additionalClass}>
      {childrenArray.map((child, idx) =>
        cloneElement<SwitchCloneChild<ChildProps>>(child, {
          ...(passThroughProps as PassThroughProps),
          key: idx,
          active: states[idx],
          onChange: handleChange,
        })
      )}
    </div>
  );
}

export default SwitchBase;
