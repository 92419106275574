import Button from '@components/common/Button';
import { Form, SubmitButton, TextField } from '@components/common/form';
import Modal, { ModalProps } from '@components/common/Modal';
import { EntitiesField } from '@components/connected/EntitiesControl/EntitiesField';
import { ReasonPickerField, REASONS } from '@components/pages/MWCreatePage/components/ReasonPickerField';
import React, { useCallback } from 'react';
import z from 'zod';
import { Classes } from '@blueprintjs/core';
import c from 'classnames';
import Icon from '@components/common/Icon';
import { useLogbookAddEditMutation } from '@infrastructure/api/BaseNClient/useLogbookAddEditMutation';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { useQueryClient } from 'react-query';
import ENDPOINTS from '@infrastructure/api/endpoints';

import styles from './AddEditNotePopup.module.scss';

const validationSchema = z.object({
  entityId: z.string(),
  reason: z.enum(REASONS),
  description: z.string(),
});

type AddEditNotePopupProps = ModalProps & {
  id?: string;
  entityId?: string;
};

export const AddEditNotePopup: React.FC<AddEditNotePopupProps> = ({ id, entityId, onClose, ...props }) => {
  const addEditMutation = useLogbookAddEditMutation();
  const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();
  const queryClient = useQueryClient();

  const initialValues = {
    entityId,
    reason: 'Configuration change',
    description: undefined,
  };

  const handleSubmit = useCallback(
    ({ entityId, reason, description }, { setSubmitting }) => {
      setSubmitting(true);

      const params: any = {
        entityid: entityId,
        reason,
        text: description,
        priority: 0, // TODO: find out what the possible values are and maybe add the field to the form,
        // weird but `timestamp` is a required field and has to be passed along from UI
        timestamp: Date.now(),
        // currently we only allow creation of MW notes
        type: 'maintenance',
      };

      if (id) {
        params.id = id;
      }

      addEditMutation.mutate(params, {
        onSuccess: getSuccessHandler(() => {
          setSubmitting(false);
          onClose?.();

          // give it some time - for some reason changes are not reflected immediately
          setTimeout(() => {
            queryClient.invalidateQueries(ENDPOINTS.logbook);
          }, 500);
        }),
        onError: getFailureHandler(() => {
          setSubmitting(false);
        }),
      });
    },
    [addEditMutation, getFailureHandler, getSuccessHandler, id, onClose]
  );

  return (
    <Modal
      usePortal
      customFooter={null}
      title={
        <div className={styles.header}>
          <Icon name="Ticket" additionalClass="mr-8" />
          Add Note
        </div>
      }
      {...props}
      additionalClasses={{ root: styles.addEditNotePopup }}
      onClose={onClose}
    >
      <Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {!entityId && <EntitiesField label="Entity" name="entityId" filterable required />}

        <ReasonPickerField label="Reason" name="reason" fullWidth required />

        <TextField
          multiline
          name="description"
          label="Description"
          additionalClasses={{ textControl: 'w-full' }}
          required
        />

        <div className={c(styles.footer, Classes.DIALOG_FOOTER)}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button size="s" variant="fillOutline" onClick={onClose} ariaLabel="Close Button" additionalClass="mr-16">
              Cancel
            </Button>
            <SubmitButton size="s" variant="fillBlueDark" ariaLabel="Add Note">
              Add Note
            </SubmitButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
