import React, { FC } from 'react';
import useTextSnippets from '@services/useTextSnippets';
import ErrorPageContainer from '@components/error/ErrorPageContainer';

import styles from './style.module.scss';

type Error500PageProps = {
  headline?: string;
  text?: string;
  action?: 'reload' | 'redirect';
};

const Error500Page: FC<Error500PageProps> = ({ headline, text }) => {
  const { internalErrorMessage } = useTextSnippets('error');

  return (
    <ErrorPageContainer
      headline={headline}
      text={text ?? internalErrorMessage}
      withLogo
      additionalClass={styles.page}
    />
  );
};

export default Error500Page;
