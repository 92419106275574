export type Interval = {
  label: string;
  value: [number, moment.unitOfTime.DurationConstructor];
};

export const COMMON_DATE_INTERVALS: Interval[] = [
  { label: '1d', value: [1, 'days'] },
  { label: '1w', value: [1, 'weeks'] },
  { label: '1m', value: [1, 'months'] },
  { label: '3m', value: [3, 'months'] },
  { label: '1y', value: [1, 'years'] },
];

export const DEFAULT_TIMEZONE = 'Europe/Helsinki';

export const DEFAULT_DATETIME_FORMAT = 'HH:mm, DD-MMM-YYYY';
export const MYSQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const TWO_MINUTES = 2 * 60 * 1000;
export const FIVE_MINUTES = 5 * 60 * 1000;
