import { AlertConfig } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';
import {
  getAlertStats,
  getIconColor,
  getIconName,
  getOtherColor,
  getExplanation,
  getTitle,
  getBadgeVariant,
} from '@utils/alert';
import React from 'react';
import Icon from '@components/common/Icon';
import c from 'classnames';
import Tooltip from '@components/common/Tooltip';
import { Badge } from '@components/common/Badge';
import { AlertsPopup } from './AlertsPopup';

import styles from './AlertsIndicator.module.scss';
import alertStyles from '@components/connected/IssueManager/components/AlertBar/Alert.module.scss';

export type AlertsIndicatorProps = {
  alerts: AlertConfig[];
  showBadge?: boolean;
  variant?: 'default' | 'tooltip' | 'popup';
};

export const AlertsIndicator: React.FC<AlertsIndicatorProps> = ({ alerts = [], variant = 'default', showBadge }) => {
  const stats = React.useMemo(() => getAlertStats(alerts), [alerts]);

  const otherColor = getOtherColor(stats);
  const otherTextColor = otherColor === 'gray' ? 'light' : 'dark';

  const indicator = (
    <div className={styles.alertIndicator}>
      <Icon name={getIconName(stats)} size="s" additionalClass={alertStyles[getIconColor(stats)]} />
      {stats.total > 1 && (
        <div className={c(styles.moreCircle, alertStyles[`${otherColor}-bg`], styles[otherTextColor])}>
          <span>{`+${stats.total - 1}`}</span>
        </div>
      )}
      {showBadge && (
        <Badge variant={getBadgeVariant(stats)} additionalClass="ml-8">
          {getTitle(stats)}
        </Badge>
      )}
    </div>
  );

  switch (variant) {
    case 'tooltip':
      return <Tooltip content={getExplanation(stats)}>{indicator}</Tooltip>;
    case 'popup':
      return <AlertsPopup alerts={alerts}>{indicator}</AlertsPopup>;
    default:
      return indicator;
  }
};
