import React from 'react';
import useTextSnippets from '@services/useTextSnippets';
import { ErrorPage } from '@components/layout/Layout/components/PageErrorBoundary/ErrorPage';

interface ErrorPageContainerProps {
  headline?: string;
  text?: string;
  withLogo?: boolean;
  onReset?: () => void;
  additionalClass?: string;
}

const ErrorPageContainer: React.FC<ErrorPageContainerProps> = ({ headline, text, onReset }) => {
  const { defaultErrorTitle } = useTextSnippets('error');

  const handleReset = () => {
    if (onReset) {
      onReset();
    } else {
      window.location.reload();
    }
  };

  return <ErrorPage headline={headline ?? defaultErrorTitle} description={text} onReset={handleReset} />;
};

export default ErrorPageContainer;
