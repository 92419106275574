import React, { FC, useCallback, useMemo } from 'react';
import { Chart } from '@components/common/Chart';
import { StripeColor } from '@utils/issue';
import colors from '@theme/colors';

import styles from './ClusterPie.module.scss';

export type Stats = Partial<Record<StripeColor, number>>;

export type ClusterPieProps = {
  stats: Stats;
  hideGreen?: boolean;
  showTooltip?: boolean;
  renderer?: 'svg' | 'canvas';
  additionalClass?: string;
};

const getConfig = ({
  stats,
  hideGreen = false,
  showTooltip = true,
}: Omit<ClusterPieProps, 'stats'> & { stats: Required<Record<StripeColor, number>> }) => {
  const total = Object.values(stats).reduce((sum, value) => sum + value, 0);

  let bgColor;
  let textColor;
  if (stats.maintenance > total / 2) {
    bgColor = colors.blue.infoLight;
    textColor = colors.alert.maintenance;
  } else if (stats.red > total / 2) {
    bgColor = colors.red.light;
    textColor = colors.alert.red;
  } else {
    bgColor = colors.green.light;
    textColor = colors.alert.green;
  }

  return {
    title: {
      text: total,
      left: 'center',
      top: 'center',
      textStyle: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 16,
        color: textColor,
      },
    },
    tooltip: {
      show: showTooltip,
      trigger: 'item',
      formatter: '{c}',
      padding: [2, 5],
      className: styles.popup,
      appendToBody: true,
      // position: ['50%', '50%'],
    },
    series: [
      {
        type: 'pie',
        radius: [0, '76%'],
        label: {
          show: false,
        },
        tooltip: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        animation: false,
        emphasis: {
          scale: false,
          silent: true,
          itemStyle: {
            opacity: 1,
            color: bgColor,
          },
        },
        data: [{ value: 1, itemStyle: { color: bgColor } }],
      },
      // styles for outer rim
      {
        type: 'pie',
        radius: ['76%', '90%'],
        emphasis: {
          scaleSize: 2,
          scale: showTooltip,
        },
        animation: false,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data: Object.keys(stats).map(color => ({
          value: stats[color as StripeColor],
          itemStyle: {
            // @ts-ignore
            color: colors.alert[color],
            opacity: color === 'green' && hideGreen ? 0 : 1,
          },
        })),
      },
    ],
  };
};

export const ClusterPie: FC<ClusterPieProps> = ({ renderer = 'svg', additionalClass, ...props }) => {
  const config = useMemo(() => {
    const { stats, ...otherProps } = props;

    return getConfig({
      stats: { green: 0, lime: 0, yellow: 0, orange: 0, red: 0, gray: 0, maintenance: 0, black: 0, ...stats },
      ...otherProps,
    });
  }, [props]);

  const initProps = useMemo(() => {
    const size = config.title.text.toString().length * 10 + 24;

    return { width: size, height: size, renderer };
  }, [config.title.text, renderer]);

  const captureMouseMove = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div
      className="inline-block"
      // without this, hover event leaks through and might trigger a tooltip for some other feature
      onMouseMove={captureMouseMove}
    >
      <Chart echartsConfig={config} initProps={initProps} additionalClass={additionalClass} />
    </div>
  );
};
