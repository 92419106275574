import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import React, { useEffect, useMemo, useCallback } from 'react';
import {
  selectItemsAsArray,
  selectPagination,
  selectQueryParams,
  selectSorting,
  selectResolvedFilters,
  selectAreLoaded,
} from '@redux/logbook';
import { Sort, RemoteStoredTableProps, RemoteStoredTable } from '@components/common/Table';
import { SortingParams } from '@infrastructure/redux/types';
import { DefaultMotion } from '@services/theme';
import { Note, useLogbookQuery } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { NoNotes } from '@components/pages/Logbook/components/NoNotes';
import { getTableColumns } from './utils';

export type ChangesTableProps = {
  entityId?: string;
  actions: Dictionary<any>;
  activeItem?: Note | null;
  onItemChange?: (item: Note) => void;
  onInitialLoadComplete?: () => void;
};

export const ChangesTable: React.FC<ChangesTableProps> = ({
  entityId,
  actions,
  activeItem,
  onItemChange,
  onInitialLoadComplete,
}) => {
  const { updatePagination, updateSorting, updateFilters, updateSearch, updateItems } = actions;

  const dispatch = useAppDispatch();

  const areLoaded = useAppSelector(selectAreLoaded);
  const items = useAppSelector(selectItemsAsArray);
  const allFilters = useAppSelector(selectResolvedFilters);
  const pagination = useAppSelector(selectPagination);
  const sorting = useAppSelector(selectSorting);
  const params = useAppSelector(selectQueryParams);

  const notesQuery = useLogbookQuery({
    ...params,
    entity_ids: entityId,
    reasons: 'Configuration change',
    types: 'config',
  });

  useEffect(() => {
    if (notesQuery.data?.notes) {
      dispatch(updatePagination({ rowCount: notesQuery.data.total }));
      dispatch(updateItems(notesQuery.data.notes));
      onInitialLoadComplete?.();
    }
  }, [notesQuery.data, dispatch, onInitialLoadComplete, updatePagination, updateItems]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(updatePagination({ page: newPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSortChange = useCallback(
    (sortBy: string | null, sort: Sort) => {
      dispatch(updateSorting({ sortBy: sortBy as SortingParams['sortBy'], sort }));
    },
    [dispatch, updateSorting]
  );

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      dispatch(updatePagination({ rowsPerPage: newRowsPerPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSearch = useCallback(
    (keyword?: string) => {
      dispatch(updateSearch(keyword));
      dispatch(updatePagination({ page: 1 }));
    },

    [dispatch, updatePagination, updateSearch]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Dictionary<any>) => {
      dispatch(updateFilters(newFilters));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch, updateFilters, updatePagination]
  );

  const tableProps: RemoteStoredTableProps<Note> = useMemo(
    () => ({
      name: 'changesTable',
      items,
      columns: getTableColumns(),
      onRowsPerPageChange: handleRowsPerPageChange,
      onPageChange: handlePageChange,
      onSortChange: handleSortChange,
      onSearch: handleSearch,
      onFiltersChange: handleFiltersChange,
      selectedItems: activeItem ? [activeItem] : [],
      onCellClick(e, item) {
        return onItemChange?.(item);
      },
      keyword: allFilters.generalFilter,
      isLoading: notesQuery.isLoading || notesQuery.isFetching,
      config: {
        ...pagination,
        sort: sorting?.sort,
        sortBy: sorting?.sortBy,
        sortable: true,
        bordered: false,
        selectable: false,
        hasToolbar: false,
        resizable: true,
      },
    }),
    [
      items,
      handleRowsPerPageChange,
      handlePageChange,
      handleSortChange,
      handleSearch,
      handleFiltersChange,
      activeItem,
      allFilters.generalFilter,
      pagination,
      sorting?.sort,
      sorting?.sortBy,
      notesQuery.isLoading,
      notesQuery.isFetching,
      onItemChange,
    ]
  );

  return (
    <div className="flex flex-col h-full min-h-0">
      {!areLoaded ? null : !items.length ? (
        <NoNotes />
      ) : (
        <>
          <DefaultMotion key="logbook" className="min-h-0">
            <RemoteStoredTable<Note> {...tableProps} />
          </DefaultMotion>
        </>
      )}
    </div>
  );
};
