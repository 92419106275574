import { useField } from 'formik';
import React from 'react';
import { WeekdayPicker, WeekdayPickerProps } from './WeekdayPicker';

export type WeekdayPickerFieldProps = Omit<WeekdayPickerProps, 'onChange'> & {
  name: string;
};

export const WeekdayPickerField: React.FC<WeekdayPickerFieldProps> = ({ name, ...props }) => {
  const [field, , helpers] = useField<boolean[]>(name);

  const handleChange = (days: boolean[]) => {
    helpers.setValue(days);
  };

  return <WeekdayPicker {...props} {...field} onChange={handleChange} />;
};
