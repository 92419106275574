import LoadingSpinner, { LoadingSpinnerProps } from '@components/layout/LoadingSpinner';
import React, { FC } from 'react';

import styles from './Overlay.module.scss';

export const Overlay: FC = ({ children }) => {
  return <div className={styles.overlay}>{children}</div>;
};

export type LoadingOverlayProps = {
  size?: LoadingSpinnerProps['size'];
};

export const LoadingOverlay: FC<LoadingOverlayProps> = ({ size }) => (
  <Overlay>
    <LoadingSpinner centered additionalClass="p-20 h-full text-gray-3" size={size} />
  </Overlay>
);
