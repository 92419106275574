import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import { AppDispatch } from '@infrastructure/redux/store';
import { AccountData, updateToken } from '@redux/auth';
import { LOCAL_STORAGE } from '@constants/storage';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { Buffer } from 'buffer';
import { setUserToken } from '@utils/token';

export function onLoginSuccess(dispatch: AppDispatch, token: string, variables?: AccountData) {
  dispatch(updateToken({ token, isTemporary: false }));
  apiClient.setToken(token);
  setUserToken(token);

  if (variables && variables.remember) {
    localStorage.setItem(LOCAL_STORAGE.rememberMe, JSON.stringify({ username: variables.username }));
  } else {
    localStorage.removeItem(LOCAL_STORAGE.rememberMe);
  }
}

type LoginResponse = {
  token: string;
  temporary: boolean;
};

export const useLoginMutation = <T extends LoginResponse = LoginResponse, P extends AccountData = AccountData>(
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> => {
  return useMutation<T, Error, P>(
    (accountData): Promise<T> =>
      apiClient.post<T, P>(ENDPOINTS.login, undefined, {
        headers: {
          Authorization: `Basic ${Buffer.from(`${accountData.username}:${accountData.password}`).toString('base64')}`,
        },
      }),
    {
      ...mutationOptions,
    }
  );
};
