import { mergeWithUndefined } from '@utils/misc';

import { cloneDeep, isEmpty, isEqual, merge, omit, pick } from 'lodash';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { DEFAULT_TABLE_CONFIG } from './config';
import { ColumnVisibilities, ColumnWidths, ColumnsOrder, RowHeight, Sort } from './types';
import {
  getStorableColumnProps,
  getColumnsOrder,
  getColumnsWidths,
  getColumnsVisibility,
  mergeWithPropMap,
} from './utils';
import { StoredTableLayout } from '@infrastructure/api/BaseNClient/useTableViewQuery';
import { FilterableTable, FilterableTableProps } from './FilterableTable';
import useBreakpoint from '@services/useBreakpoint';
import { extractValues } from '../form/FormBuilder';

// IMPORTANT: every config prop that is controlled by StoredTable should be listed here
// for it to be ignored when passed from outside
const CONTROLLED_CONFIG_PROPS = ['sortBy', 'sort', 'rowsPerPage', 'page', 'rowHeight', 'filtersInPopup'] as const;

export type StoredTableProps<T extends object> = FilterableTableProps<T> & {
  storeLayout: (layout: StoredTableLayout) => Promise<void>;
  retrieveLayout: (layout: StoredTableLayout) => Promise<StoredTableLayout>;
};

export function StoredTable<T extends object>({
  storeLayout,
  retrieveLayout,
  config,
  filterConfig,
  onPageChange,
  onRowsPerPageChange,
  onSortChange,
  onRowHeightChange,
  onColumnResize,
  onColumnOrderChange,
  onColumnVisibilityToggle,
  onFiltersChange,
  onFiltersViewToggle,
  columns,
  ...props
}: StoredTableProps<T>): ReactElement | null {
  const [isInitialLayoutLoading, setIsInitialLayoutLoading] = React.useState(true);

  const [, forceUpdate] = React.useState(0);

  const {
    breakpoints: { isDesktop },
  } = useBreakpoint();

  // stored config is null, until it is loaded from BE storage
  const storedLayoutRef = React.useRef<StoredTableLayout | null>(null);

  useEffect(
    () => {
      if (!storedLayoutRef.current) {
        setIsInitialLayoutLoading(true);
        retrieveLayout({
          $version: 0,
          columns: getStorableColumnProps(columns),
          config: {
            ...DEFAULT_TABLE_CONFIG,
            ...config,
          },
        })
          .then(layout => {
            storedLayoutRef.current = layout;
          })
          .finally(() => setIsInitialLayoutLoading(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSortChange = useCallback(
    (sortBy: string | null, sort: Sort) => {
      const next = mergeWithUndefined({}, storedLayoutRef.current, { $version: Date.now(), config: { sortBy, sort } });
      storeLayout(next);
      onSortChange?.(sortBy, sort);
      storedLayoutRef.current = next;
    },
    [onSortChange, storeLayout]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      const next = mergeWithUndefined({}, storedLayoutRef.current, { $version: Date.now(), config: { page } });
      storeLayout(next);
      onPageChange?.(page);
      storedLayoutRef.current = next;
    },
    [onPageChange, storeLayout]
  );

  const handleRowsPerPageChange = useCallback(
    (rowsPerPage: number) => {
      const next = mergeWithUndefined({}, storedLayoutRef.current, { $version: Date.now(), config: { rowsPerPage } });
      storeLayout(next);
      onRowsPerPageChange?.(rowsPerPage);
      storedLayoutRef.current = next;
    },
    [onRowsPerPageChange, storeLayout]
  );

  const handleRowHeightChange = useCallback(
    (newRowHeight: RowHeight) => {
      const next = mergeWithUndefined({}, storedLayoutRef.current, {
        $version: Date.now(),
        config: { rowHeight: newRowHeight },
      });
      storeLayout(next);
      onRowHeightChange?.(newRowHeight);
      storedLayoutRef.current = next;
    },
    [onRowHeightChange, storeLayout]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleColumnResize = useCallback(
    (newColWidths: ColumnWidths) => {
      if (isEmpty(newColWidths)) {
        newColWidths = getColumnsWidths<T>(columns, false);
      }

      const newProps = mergeWithPropMap(storedLayoutRef.current?.columns ?? {}, newColWidths, 'width');
      const next = mergeWithUndefined({}, storedLayoutRef.current, { $version: Date.now(), columns: newProps });
      storeLayout(next);
      onColumnResize?.(newColWidths);
      storedLayoutRef.current = next;
      forceUpdate(Date.now());
    },
    [storeLayout, onColumnResize, columns]
  );

  const handleColumnOrderChange = useCallback(
    (newColOrder: ColumnsOrder) => {
      if (isEmpty(newColOrder)) {
        newColOrder = getColumnsOrder(columns);
      }
      const newProps = mergeWithPropMap(storedLayoutRef.current?.columns ?? {}, newColOrder, 'order');
      const next = mergeWithUndefined({}, storedLayoutRef.current, { $version: Date.now(), columns: newProps });
      storeLayout(next);
      onColumnOrderChange?.(newColOrder);
      storedLayoutRef.current = next;
      forceUpdate(Date.now());
    },
    [storeLayout, onColumnOrderChange, columns]
  );

  const handleColumnVisibilityToggle = useCallback(
    (newColVisibility: ColumnVisibilities) => {
      if (isEmpty(newColVisibility)) {
        newColVisibility = getColumnsVisibility(columns);
      }
      const newProps = mergeWithPropMap(storedLayoutRef.current?.columns ?? {}, newColVisibility, 'visible');
      const next = mergeWithUndefined({}, storedLayoutRef.current, { $version: Date.now(), columns: newProps });
      storeLayout(next);
      onColumnVisibilityToggle?.(newColVisibility);
      storedLayoutRef.current = next;
      forceUpdate(Date.now());
    },
    [storeLayout, onColumnVisibilityToggle, columns]
  );

  const handleFiltersChange = useCallback(
    (values: Dictionary<any>) => {
      const next = mergeWithUndefined({}, storedLayoutRef.current, { $version: Date.now(), filters: values });
      storeLayout(next);
      onFiltersChange?.(values);
      storedLayoutRef.current = next;
    },
    [onFiltersChange, storeLayout]
  );

  const handleFiltersViewToggle = useCallback(() => {
    if (props.sidebarFilters) {
      const newState = isDesktop && storedLayoutRef.current?.config?.filtersInPopup ? false : true;
      const next = mergeWithUndefined({}, storedLayoutRef.current, {
        $version: Date.now(),
        config: { filtersInPopup: newState },
      });
      storeLayout(next);
      onFiltersViewToggle?.(newState);
      storedLayoutRef.current = next;
      forceUpdate(Date.now());
    }
  }, [props.sidebarFilters, isDesktop, storeLayout, onFiltersViewToggle]);

  React.useEffect(() => {
    const next = mergeWithUndefined({}, {
      $version: Date.now(),
      columns: getStorableColumnProps(columns),
    }, storedLayoutRef.current);
    storedLayoutRef.current = next;
  }, [columns]);

  // simply updating filters locally won't work, since there are epics subscribed onto redux changes, which listen to
  // onFiltersChange and alter the query string accodingly
  React.useEffect(() => {
    if (storedLayoutRef.current) {
      if (
        storedLayoutRef.current?.filters &&
        (!filterConfig || !isEqual(extractValues(filterConfig), storedLayoutRef.current?.filters))
      ) {
        // onFiltersChange?.(storedLayoutRef.current?.filters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConfig, onFiltersChange, storedLayoutRef.current]);

  return isInitialLayoutLoading ? null : (
    <FilterableTable<T>
      {...props}
      columns={
        !isEmpty(columns) && !isEmpty(storedLayoutRef.current?.columns)
          ? merge(cloneDeep(columns), pick(storedLayoutRef.current?.columns, Object.keys(columns)))
          : columns
      }
      config={mergeWithUndefined({}, storedLayoutRef.current?.config, omit(config, CONTROLLED_CONFIG_PROPS))}
      filterConfig={filterConfig}
      onPageChange={handlePageChange}
      onSortChange={handleSortChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      onRowHeightChange={handleRowHeightChange}
      onColumnResize={handleColumnResize}
      onColumnOrderChange={handleColumnOrderChange}
      onColumnVisibilityToggle={handleColumnVisibilityToggle}
      onFiltersViewToggle={handleFiltersViewToggle}
      onFiltersChange={handleFiltersChange}
    />
  );
}
