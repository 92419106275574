import React, { ReactNode } from 'react';
import c from 'classnames';
import styles from './style.module.scss';

type ToolbarProps = {
  additionalClass?: string;
  children: ReactNode;
};

const Toolbar: React.FC<ToolbarProps> = ({ additionalClass, children }) => {
  return <div className={c(styles.toolbar, additionalClass)}>{children}</div>;
};

export default Toolbar;
