import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import ROUTES from '@infrastructure/routes';
import { MapboxEvent, MapLayerMouseEvent, MapSourceDataEvent, ViewStateChangeEvent } from 'react-map-gl';
import { RoutesLayer } from './layers/RoutesLayer';
import { flattenPaths, prepareMVTUrl } from '@utils/url';
import { MapDataSource, MapWidgetSubConfig, WidgetHandle } from '@redux/widgetPage';
import { EntitiesLayer } from './layers/EntitiesLayer';
import { Viewport } from '@components/common/Map/types';
import { DEFAULT_MAP_WIDGET_CONFIG } from '@redux/widgetPage/constants';
import { ClusterMarker } from './components/ClusterMarker';
import { VectorTile } from '@mapbox/vector-tile';
import Protobuf from 'pbf';
import { Cluster, ClusterCache } from './ClusterCache';
import c from 'classnames';
import { WidgetComponentProps } from '@components/pages/WidgetPage/types';
import Map from '@components/common/Map';
import { layersToClusters } from '@utils/map';
import { MapWidgetPopup, MapWidgetPopupProps } from './components/MapWidgetPopup';
import icons from './icons/';
import { TopologyMapWidget } from '../TopologyMapWidget';
import IconButton from '@components/common/IconButton';
import Tooltip from '@components/common/Tooltip';
import { useWidgetContext } from '@components/layout/Widget/WidgetContext';
import Icon from '@components/common/Icon';
import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
import { Connection } from './types';
import { API_URL } from '@config';

import styles from './MapWidget.module.scss';

export type MapWidgetProps = Omit<WidgetComponentProps, 'config'> & {
  config?: MapWidgetSubConfig | null;
};

export const MapWidget = React.forwardRef<WidgetHandle, MapWidgetProps>(({ config }, ref) => {
  const { source, zoom, latitude, longitude, mode, showZoomControl, showEntityTooltip } = {
    ...DEFAULT_MAP_WIDGET_CONFIG,
    ...config,
  };
  const mapDataSource = source as MapDataSource;
  const isValidSource = !!mapDataSource?.customerIds?.length;

  const context = useWidgetContext();

  const [popup, setPopup] = useState<MapWidgetPopupProps | null>(null);
  const [clusterAsTopology, setClusterAsTopology] = useState<Cluster | null>(null);
  const [, forceUpdate] = useState(Date.now());

  // here we store previous viewport props
  const propsViewportRef = useRef<Viewport>({
    zoom,
    latitude,
    longitude,
  });

  // Map is controlled component
  const [viewport, setViewport] = useState<Viewport>({
    ...propsViewportRef.current,
  });

  // reset the viewport if any of corresponding props change, and close popup
  useEffect(() => {
    const prevProps = propsViewportRef.current;
    setViewport({
      ...viewport,
      zoom: prevProps.zoom !== zoom ? zoom : prevProps.zoom,
      latitude: prevProps.latitude !== latitude ? latitude : prevProps.latitude,
      longitude: prevProps.longitude !== longitude ? longitude : prevProps.longitude,
    });
    propsViewportRef.current = {
      ...prevProps,
      zoom,
      latitude,
      longitude,
    };
    setPopup(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom, latitude, longitude]);

  const clusterCache = useRef<ClusterCache>(new ClusterCache());

  // when a data source changes, cluster cache becomes invalid
  useEffect(() => {
    clusterCache.current?.clear();
    forceUpdate(Date.now());
  }, [mapDataSource]);

  const handleClick = useCallback(({ originalEvent, features }: MapLayerMouseEvent) => {
    const { sourceLayer, properties } = features?.[0] ?? {};

    if (sourceLayer === 'entities' && properties?.id) {
      setPopup({
        x: originalEvent.clientX,
        y: originalEvent.clientY,
        type: 'entity',
        childProps: {
          name: properties.name,
          entityId: properties.id,
        },
      });
    }
  }, []);

  const handleShowTopologyView = useCallback(
    (cluster: Cluster | null) => {
      setClusterAsTopology(cluster);
      context?.setTitle(
        cluster?.name ? (
          <span className={styles.widgetTitle}>
            {context.title}
            <Icon name="ChevronRight" size="xs" additionalClass="mx-4" />
            {cluster.name}
          </span>
        ) : null
      );
    },
    [context]
  );

  const handleSiteClick = useCallback(
    ({ originalEvent }: MapboxEvent<MouseEvent>, cluster: Cluster) => {
      originalEvent.stopPropagation();

      setPopup({
        x: originalEvent.clientX,
        y: originalEvent.clientY,
        type: 'cluster',
        childProps: {
          cluster,
          onTopologyMapRequest: handleShowTopologyView,
        },
      });
    },
    [handleShowTopologyView]
  );

  const handleRouteHover = useCallback(({ originalEvent, features }: MapLayerMouseEvent) => {
    const { sourceLayer, properties } = features?.[0] ?? {};

    if (sourceLayer === 'routes' || sourceLayer === 'routes_return') {
      setPopup({
        x: originalEvent.clientX,
        y: originalEvent.clientY,
        type: 'route',
        childProps: {
          route: properties as Connection,
          rtl: sourceLayer === 'routes',
        },
      });
    }
  }, []);

  const handleClosePopup = useCallback(() => {
    setPopup(null);
  }, []);

  const handleViewportChange = useCallback((e: ViewStateChangeEvent) => {
    setViewport({ ...e.viewState });
    setPopup(null);
  }, []);

  const handleSourceData = useCallback((e: MapSourceDataEvent) => {
    if (e.source.type !== 'vector' || e.sourceId !== 'data' || !e.tile?.latestRawTileData) {
      return;
    }

    // Mapbox doesn't allow us to have interactive symbols nor has html layers, so we grab data directly from
    // vector tiles and manually construct custom markers for all the clusters
    const { layers } = new VectorTile(new Protobuf(e.tile.latestRawTileData as ArrayBuffer));

    let wasUpdated = false;

    if (layers.entity_clusters) {
      if (clusterCache.current.add(layersToClusters(layers.entity_clusters, 'cluster', e.coord.canonical.z))) {
        wasUpdated = true;
      }
    }

    if (layers.entity_sites) {
      if (clusterCache.current.add(layersToClusters(layers.entity_sites, 'site'))) {
        wasUpdated = true;
      }
    }

    // force update the Map to reflect the clusterCache updates
    if (wasUpdated) {
      forceUpdate(Date.now());
    }
  }, []);

  const url = useMemo(
    () =>
      prepareMVTUrl(
        ROUTES.mapEntities,
        {
          ...(mapDataSource?.dataFilter ? flattenPaths(mapDataSource.dataFilter, ['data']) : undefined),
          customer_ids: mapDataSource?.customerIds?.join(','),
          route_alert: mapDataSource?.routeAlert,
          route_channel: mapDataSource?.routeChannel,
          cluster_minpoints: 2,
          cluster_distance: 'auto',
          cluster_sites: true,
          cluster_id_limit: 50, // default
        },
        API_URL
      ),
    [mapDataSource]
  );

  return clusterAsTopology ? (
    <>
      <Tooltip content="Back to Geo Map" additionalClass={styles.backToGeoMap}>
        <IconButton icon="Back" onClick={() => handleShowTopologyView(null)} />
      </Tooltip>
      <TopologyMapWidget config={{ source: { entityIds: clusterAsTopology.entityIds } }} />
    </>
  ) : (
    <>
      <Map
        // NOTE: Map cannot survive dynamic mode change, so we force re-create it if mode changes
        key={mode}
        viewport={viewport}
        mode={mode}
        icons={icons}
        showControls={showZoomControl}
        onClick={handleClick}
        onViewportChange={handleViewportChange}
        onSourceData={handleSourceData}
        additionalClass={c('rounded-b-sm overflow-hidden')}
        interactiveLayerIds={['entities']}
      >
        {isValidSource && (
          <>
            <EntitiesLayer id="entities" sourceId="data" url={url} />
            <RoutesLayer
              id="routes"
              sourceId="data"
              sourceLayerId="routes"
              beforeId="entities"
              onHover={handleRouteHover}
            />
            <RoutesLayer
              id="routes-return"
              sourceId="data"
              sourceLayerId="routes_return"
              beforeId="entities"
              onHover={handleRouteHover}
            />

            {showEntityTooltip && popup && <MapWidgetPopup key={popup.type} {...popup} onClose={handleClosePopup} />}

            {clusterCache.current.getByZoom(Math.floor(viewport.zoom)).map(cluster => (
              <ClusterMarker key={cluster.id} {...cluster} onClick={handleSiteClick} />
            ))}

            {clusterCache.current.getByType('site').map(site => (
              <ClusterMarker key={site.id} {...site} onClick={handleSiteClick} />
            ))}
          </>
        )}
      </Map>

      {!isValidSource && (
        <div className={styles.noDataSource}>
          <div className={styles.noDataSourceWrapper}>
            <NoElementsContainer
              icon="EmptyMapSource"
              title={
                <Headline centered variant="headline-6">
                  Please select a <span className="text-blue-ocean">Data Source</span>
                </Headline>
              }
              description={<CopyText variant="copy-4">Data Source &gt; Customer</CopyText>}
            />
          </div>
        </div>
      )}
    </>
  );
});
