import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import React, { useEffect, useMemo, useCallback } from 'react';
import {
  selectItemsAsArray,
  selectPagination,
  selectQueryParams,
  selectSorting,
  selectResolvedFilters,
  selectAreLoaded,
  selectDisplayMode,
  DisplayMode,
  METRICS_TABLE_ID,
  MetricsStoredLayout,
} from '@redux/metricsPage';
import {
  Sort,
  RemoteStoredTableProps,
  RemoteStoredTable,
  DEFAULT_TABLE_CONFIG,
  RowHeight,
} from '@components/common/Table';
import { getFiltersConfig, getTableGridColumns, getTableListColumns } from './utils';
import { Filters, SortingParams } from '@infrastructure/redux/types';
import { DefaultMotion } from '@services/theme';
import Headline from '@components/typography/Headline';
import { FieldsConfig } from '@components/common/form/FormBuilder';
import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import { FiltersPopup } from '@components/common/Table/components/TableFilters/FiltersPopup';
import { EntitiesResponse, useEntitiesQuery } from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { MetricsTableToolbar } from './MetricsTableToolbar';
import { updateQueryString } from '@services/useHistory';
import { storeItem } from '@utils/storage';
import { TimeControl } from '@components/common/form/TimeControl';
import { DateRange } from '@blueprintjs/datetime';
import moment from 'moment';

import styles from './MetricsTable.module.scss';

export type MetricsTableProps = {
  actions: Dictionary<any>;
  onInitialLoadComplete?: () => void;
};

export const MetricsTable: React.FC<MetricsTableProps> = ({ actions, onInitialLoadComplete }) => {
  const {
    updatePagination,
    updateSorting,
    updateFilters,
    mergeFilters,
    updateSearch,
    updateItems,
    updateDisplayMode,
    updateRowHeight,
  } = actions;

  const dispatch = useAppDispatch();

  const now = moment();
  const [dateRange, setDateRange] = React.useState<DateRange>([
    now.clone().subtract(1, 'day').toDate(),
    now.clone().toDate(),
  ]);
  // styling for default range vs. user selected differs
  const [isDateRangeUserSelected, setIsDateRangeUserSelected] = React.useState(false);

  const displayMode = useAppSelector(selectDisplayMode);

  const areLoaded = useAppSelector(selectAreLoaded);
  const items = useAppSelector(selectItemsAsArray);
  const allFilters: Filters = useAppSelector(selectResolvedFilters);
  const pagination = useAppSelector(selectPagination);
  const sorting = useAppSelector(selectSorting);
  const { metricId, metricset, ...params } = useAppSelector(selectQueryParams);

  // instead of denoting array of strings with [], BaseN uses comma separated string, so when parsed from URL param
  // script cannot tell whether it is an array or a single string
  const metricParam = Array.isArray(metricId) ? metricId.join(',') : metricId;

  // Table component currently does not update if columns change (TODO: fix this), so we force redraw  the table
  // on every change to metric filter
  const tableKey = `${displayMode}-${metricParam}`;

  const query = useEntitiesQuery<EntitiesResponse<EntityDetails>>(
    {
      ...params,

      metricId: metricParam,
      details: true,
    },
    {
      enabled: !!allFilters.discreteFilters?.metricId,
    }
  );

  useEffect(() => {
    if (query.data?.entities) {
      dispatch(updatePagination({ rowCount: query.data.total }));
      dispatch(updateItems(query.data?.entities));
      onInitialLoadComplete?.();
    } else if (!allFilters.discreteFilters?.metricId) {
      onInitialLoadComplete?.();
    }
  }, [
    query.data,
    dispatch,
    onInitialLoadComplete,
    updatePagination,
    updateItems,
    allFilters.discreteFilters?.metricId,
  ]);

  const handleDateRangeChange = (newDateRange: DateRange) => {
    setDateRange(newDateRange);
    setIsDateRangeUserSelected(true);
  };

  const handleDateRangeReset = () => {
    const newNow = moment();
    setDateRange([newNow.clone().subtract(1, 'day').toDate(), newNow.clone().toDate()]);
    setIsDateRangeUserSelected(false);
  };

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(updatePagination({ page: newPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSortChange = useCallback(
    (sortBy: string | null, sort: Sort) => {
      dispatch(updateSorting({ sortBy: sortBy as SortingParams['sortBy'], sort }));
    },
    [dispatch, updateSorting]
  );

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      dispatch(updatePagination({ rowsPerPage: newRowsPerPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSearch = useCallback(
    (keyword?: string) => {
      dispatch(updateSearch(keyword));
      dispatch(updatePagination({ page: 1 }));
    },

    [dispatch, updatePagination, updateSearch]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Dictionary<any>) => {
      dispatch(updateFilters(newFilters));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch, updateFilters, updatePagination]
  );

  const handleRowHeightChange = useCallback(
    (newRowHeight: RowHeight) => {
      dispatch(updateRowHeight(newRowHeight));
    },
    [dispatch, updateRowHeight]
  );

  const handleMetricsetChange = useCallback(
    ({ metricset }: Dictionary<any>) => {
      dispatch(mergeFilters({ metricset }));
    },
    [dispatch, mergeFilters]
  );

  const handleDisplayModeChange = useCallback(
    (newMode: DisplayMode) => {
      dispatch(updateDisplayMode(newMode));
      storeItem<MetricsStoredLayout>(
        METRICS_TABLE_ID,
        { $version: Date.now(), config: { displayMode: newMode } },
        true
      );
      updateQueryString({ displayMode: newMode });
      // more than 10 rows for grid display mode takes too long to load
      dispatch(updatePagination({ rowsPerPage: newMode === 'grid' ? 10 : DEFAULT_TABLE_CONFIG.rowsPerPage }));
    },
    [dispatch, updateDisplayMode, updatePagination]
  );

  const filterConfig: FieldsConfig = useMemo(() => getFiltersConfig(allFilters), [allFilters]);

  const columns = useMemo(
    () =>
      displayMode === 'grid' ? getTableGridColumns(allFilters, dateRange) : getTableListColumns(allFilters, dateRange),
    [allFilters, dateRange, displayMode]
  );

  const tableProps: RemoteStoredTableProps<EntityDetails> = useMemo(
    () => ({
      name: `metricsTable-${displayMode}`,
      items,
      columns,
      onRowsPerPageChange: handleRowsPerPageChange,
      onPageChange: handlePageChange,
      onSortChange: handleSortChange,
      onSearch: handleSearch,
      onFiltersChange: handleFiltersChange,
      onRowHeightChange: handleRowHeightChange,
      filterConfig,
      keyword: allFilters.generalFilter,
      isLoading: query.isLoading || query.isFetching,
      config: {
        ...pagination,
        sort: sorting?.sort,
        sortBy: sorting?.sortBy,
        sortable: true,
        bordered: false,
        selectable: false,
        stretchable: displayMode !== 'grid',
      },
      components: {
        TableToolbar: {
          component: MetricsTableToolbar,
          props: {
            isLoading: query.isLoading || query.isFetching,
            showFiltersToggle: true,
            filters: filterConfig,
            keyword: allFilters.generalFilter,
            onSearch: handleSearch,
            onFiltersChange: handleFiltersChange,
            displayMode,
            onDisplayModeChange: handleDisplayModeChange,
          },
        },
      },
    }),
    [
      displayMode,
      items,
      columns,
      handleRowsPerPageChange,
      handlePageChange,
      handleSortChange,
      handleSearch,
      handleFiltersChange,
      handleRowHeightChange,
      filterConfig,
      allFilters.generalFilter,
      query.isLoading,
      query.isFetching,
      pagination,
      sorting?.sort,
      sorting?.sortBy,
      handleDisplayModeChange,
    ]
  );

  const renderContent = () => {
    if (!allFilters.discreteFilters?.metricId?.length) {
      return (
        <FiltersPopup isOpen filters={filterConfig} onChange={handleMetricsetChange} onSubmit={handleFiltersChange} />
      );
    }

    return !areLoaded ? (
      <NoElementsContainer icon="Metrics" title="Metrics" />
    ) : (
      <DefaultMotion key="metrics" className="min-h-0">
        <RemoteStoredTable<EntityDetails> key={tableKey} {...tableProps} />
      </DefaultMotion>
    );
  };

  return (
    <div className="flex flex-col h-full min-h-0">
      <div className={styles.header}>
        <TimeControl
          active={isDateRangeUserSelected}
          dateRange={dateRange}
          onChange={handleDateRangeChange}
          onReset={handleDateRangeReset}
        />
      </div>
      {renderContent()}
    </div>
  );
};
