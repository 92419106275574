import { IItemRendererProps, ItemRenderer, MultiSelect2 as MultiSelect } from '@blueprintjs/select';
import React, { useState, useMemo, useCallback } from 'react';
import { BaseSelectItem } from '../Select/BaseSelect';
import c from 'classnames';
import { MenuItem } from '@components/common/Menu';
import { difference, escapeRegExp, union } from 'lodash';
import useTextSnippets from '@services/useTextSnippets';
import { Tag } from '@components/common/Tag';
import { BadgeVariant } from '@components/common/Badge';
import { PopoverPosition } from '@blueprintjs/core';

import styles from './TagInput.module.scss';

export type TagType = Omit<BaseSelectItem, 'value'> & {
  mandatory?: boolean;
  variant?: BadgeVariant;
  outlined?: boolean;
  value: string;
};

export type TagInputProps<ItemType extends TagType = TagType> = {
  placeholder?: string;
  options: ItemType[];
  value?: ItemType[];
  onChange?: (value: ItemType[]) => void;
  fullWidth?: boolean;
  matchTargetWidth?: boolean;
  size?: 'm' | 'l';
  disabled?: boolean;
  optionRenderer?: ItemRenderer<ItemType>;
  additionalClasses?: {
    root?: string;
    input?: string;
  };
};

export function TagInput<ItemType extends TagType = TagType>({
  onChange,
  value = [],
  options = [],
  fullWidth,
  size,
  matchTargetWidth = true,
  disabled,
  additionalClasses = {},
  ...props
}: TagInputProps<ItemType>) {
  const [isOpen, setIsOpen] = useState(false);
  const i18n = useTextSnippets('select');

  const handleItemSelect = (item: ItemType) => {
    onChange?.([...value, item]);
    setIsOpen(false);
  };

  const handleItemsPaste = (items: ItemType[]) => {
    onChange?.(union(value, items));
    setIsOpen(false);
  };

  const handleRemove = useCallback(
    (item: TagType) => {
      onChange?.(value.filter(selectedItem => selectedItem.value !== item.value));
    },
    [onChange, value]
  );

  const handlePopoverInteraction = useCallback((newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
  }, []);

  const handleQueryChange = () => {
    setIsOpen(true);
  };

  const filterOptions = (q: string, items: ItemType[]) => {
    const re = new RegExp(escapeRegExp(q), 'i');

    return difference(items, value).filter(item => re.test(item.label));
  };

  const tagRenderer = useCallback(
    (item: ItemType) => (
      <Tag
        variant={item.variant as BadgeVariant}
        outlined={item.outlined}
        onRemove={disabled || item.mandatory ? undefined : () => handleRemove(item)}
        size={size}
      >
        {item.label}
      </Tag>
    ),
    [handleRemove, size, disabled]
  );

  const optionRenderer = useCallback((item: ItemType, { modifiers, handleClick }: IItemRendererProps) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={item.value}
        text={item.label}
        onClick={handleClick}
        // doesn't work for some reason, had to control popover manually
        // shouldDismissPopover
      />
    );
  }, []);

  const popoverProps = useMemo(() => {
    return {
      isOpen,
      minimal: true,
      fill: fullWidth,
      usePortal: false,
      position: PopoverPosition.BOTTOM_RIGHT,
      onInteraction: handlePopoverInteraction,
      popoverClassName: styles.popover,
      matchTargetWidth,
    };
  }, [isOpen, handlePopoverInteraction, matchTargetWidth, fullWidth]);

  const tagInputProps = useMemo(
    () => ({
      tagProps: {
        onRemove: undefined, // this will remove default close button
      },
      className: c(additionalClasses.root, additionalClasses.input),
    }),
    [additionalClasses.input, additionalClasses.root]
  );

  return (
    <MultiSelect<ItemType>
      {...props}
      disabled={disabled}
      fill={fullWidth}
      selectedItems={value}
      popoverProps={popoverProps}
      tagInputProps={tagInputProps}
      items={options}
      tagRenderer={tagRenderer}
      itemRenderer={props.optionRenderer ?? optionRenderer}
      onItemSelect={handleItemSelect}
      onItemsPaste={handleItemsPaste}
      onRemove={handleRemove}
      onQueryChange={handleQueryChange}
      itemListPredicate={filterOptions}
      className={c(styles.tagInput, {
        [styles.disabled]: disabled,
        [styles.large]: size === 'l',
        [styles.medium]: size === 'm',
      })}
      noResults={<MenuItem disabled text={i18n.noResults} />}
      itemsEqual="value"
      openOnKeyDown
      resetOnSelect
    />
  );
}
