import React, { ReactElement, MouseEvent } from 'react';
import CopyText from '@components/typography/CopyText';
import { Sort, TableCellProps } from '../types';
import { formatEpochDate } from '@utils/date';
import c from 'classnames';
import { get, isEmpty } from 'lodash';
import { DEFAULT_CELL_WIDTH } from '../config';
import IconButton from '@components/common/IconButton';
import { Position } from '@blueprintjs/core';
import { DetachedPopover } from '@components/common/Popover/DetachedPopover';
import { FormBuilder } from '@components/common/form/FormBuilder';

import styles from './TableCell.module.scss';

const getSortIcon = (sort?: Sort | 'false') => {
  switch (sort) {
    case 'desc':
      return <IconButton size="xs" icon="ArrowDown" title="Descending" />;

    case false:
    case 'false':
      return <IconButton size="xs" icon="Expand" title="In response order" />;

    case 'asc':
    default:
      return <IconButton size="xs" icon="ArrowUp" title="Ascending" />;
  }
};

export default function TableCell<T extends object>(props: TableCellProps<T>): ReactElement | null {
  const {
    children,
    selected,
    active,
    header,
    footer,
    bordered,
    column,
    rowHeight,
    colKey,
    colIdx,
    rowIdx,
    item,
    sortable,
    sort,
    sortBy,
    width,
    onRequestSort,
    onClick,
    onContextMenu,
  } = props;

  const { label, truncated = true } = column;
  const filterable = !!column.filter;

  const [filterPopupTarget, setFilterPopupTarget] = React.useState<DOMRect | null>(null);

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort?.(event, property);
  };

  const handleCellClick = React.useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (item && onClick) {
        onClick(e, item);
      }
    },
    [onClick, item]
  );

  const handleFilterChange = React.useCallback(
    values => {
      setFilterPopupTarget(null);
      column.filter?.onChange?.(!isEmpty(values) ? values : { [column.id]: undefined });
    },
    [column]
  );

  const handleContextMenu = React.useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (item) {
        onContextMenu?.(e, item);
      }
    },
    [onContextMenu, item]
  );

  const getCellValue = () => {
    // this method is only used for body cells, so item will definitely be there
    const columnValueForCell = get(item, colKey, null);

    switch (column.type) {
      case 'date':
        return formatEpochDate(columnValueForCell as unknown as string);
      // TODO
      case 'toggle':
        return columnValueForCell;
      case 'custom':
        return column.valueRenderer?.(item!, colIdx, rowIdx) || '';
      case 'string':
      case 'number':
      default:
        return columnValueForCell ?? null;
    }
  };

  const finalCellProps = {
    className: c(
      {
        [styles.tableCell]: true,
        [styles.bordered]: bordered,
        [styles.headerCell]: header,
        // [styles.fixedCell]: column.sticky || header,
        [styles.selectedCell]: selected,
        [styles.activeCell]: active,
        [styles.filterable]: filterable,
        [styles[rowHeight || 'default']]: true,
      },
      styles[`align-${column.align || 'left'}`]
    ),
    style: {
      width: width || DEFAULT_CELL_WIDTH,
    },
    onContextMenu: handleContextMenu,
  };

  if (!header && !footer) {
    return (
      <td {...finalCellProps} onClick={handleCellClick}>
        {item ? (
          <div className={c({ [styles.truncated]: truncated })}>
            {column.valueRenderer ? column.valueRenderer(item, colIdx, rowIdx) : getCellValue()}
          </div>
        ) : (
          children
        )}
      </td>
    );
  } else if (header) {
    const isActive = sortBy === column.id;

    return (
      <td {...finalCellProps}>
        <div className={c(styles.wrapper, styles[`align-${column.align || 'left'}`])}>
          {sortable ? (
            <div className={styles.sortLabel} onClick={createSortHandler(column.id)}>
              <CopyText variant="copy-6" additionalClass="truncate">
                {label || '-'}
              </CopyText>
              <span className={c(styles.sortIcon, isActive && styles.isActive)}>{getSortIcon(sort)}</span>
            </div>
          ) : (
            <CopyText variant="copy-6">{label || '-'}</CopyText>
          )}
          {filterable && (
            <>
              <IconButton
                size="xs"
                icon="Filter"
                title={column.filtering ? `Filtering` : 'Filter'}
                variant={column.filtering ? 'fillOutline' : 'icon'}
                tooltipProps={{ targetProps: { className: c(styles.filterIcon) }, position: Position.TOP }}
                onClick={e => setFilterPopupTarget(new DOMRect(e.pageX - 12, e.pageY, 24, 24))}
              />
              {!!filterPopupTarget && (
                <DetachedPopover isOpen target={filterPopupTarget} onClose={() => setFilterPopupTarget(null)} hasArrow>
                  <FormBuilder
                    {...column.filter!}
                    onChange={handleFilterChange}
                    additionalClass={styles.filterContainer}
                  />
                </DetachedPopover>
              )}
            </>
          )}
        </div>
      </td>
    );
  } else {
    return (
      <td {...finalCellProps}>
        <CopyText variant="copy-6" additionalClass="truncate">
          {label || '-'}
        </CopyText>
        {children}
      </td>
    );
  }
}
