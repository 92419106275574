import React, { RefObject, useMemo, useCallback } from 'react';
import { Menu, MenuDivider, MenuItem, MenuItemWithTitle } from '@components/common/Menu';
import Icon from '@components/common/Icon';
import Drawer from '@components/common/Drawer';
import { Dashboard, renameDashboardStart, deleteDashboardStart } from '@redux/dashboards';
import { useAppDispatch } from '@infrastructure/redux/store';
import useTextSnippets from '@services/useTextSnippets';
import ROUTES from '@infrastructure/routes';
import { useBreakpoint } from '@services';
import styles from './SingleDashboardMenu.module.scss';
import withCommands, { WithCommands } from '@services/withCommands';
import { useNavigate } from 'react-router-dom';
import { DetachedPopover } from '@components/common/Popover/DetachedPopover';
import { TableContextMenuProps } from '@components/common/Table';

type SingleDashboardMenuProps = TableContextMenuProps<Dashboard> & {
  containerRef?: RefObject<HTMLDivElement>;
} & WithCommands;

const SingleDashboardMenu: React.FC<SingleDashboardMenuProps> = ({ target, item, onClose }) => {
  const isOpen = !!target && !!item;
  const dispatch = useAppDispatch();
  const commonI18n = useTextSnippets('common');
  const {
    breakpoints: { isDesktop },
  } = useBreakpoint();
  const navigate = useNavigate();
  // const isFavorite = useAppSelector(createSelectIsFavoriteDashboard(item.id));

  // const onToggleFavorite = useCallback(
  //   (e: React.MouseEvent<HTMLElement>) => {
  //     openFavoritesMenu({
  //       dashboardId: item.id,
  //       targetEl: e.target as HTMLElement,
  //     });
  //   },
  //   [item, openFavoritesMenu]
  // );

  const onRename = useCallback(() => {
    if (item) {
      dispatch(renameDashboardStart(item));
    }
  }, [dispatch, item]);

  const onDelete = useCallback(() => {
    if (item) {
      dispatch(deleteDashboardStart([item]));
    }
  }, [dispatch, item]);

  // const onDuplicate = () => {
  //   console.log('command');
  // TODO commands!!
  // };

  const baseMenu = useMemo(
    () =>
      item ? (
        <Menu key="menu" className={styles.dashboardMenu}>
          <MenuItemWithTitle
            additionalClasses={{ root: 'mobile-only', text: 'copy-4' }}
            disabledMenu
            shouldDismissPopover={false}
            title={item.name}
          />
          <MenuDivider className="mobile-only" />
          <MenuItem
            text={commonI18n.view}
            icon={<Icon name="PasswordHidden" />}
            onClick={() => navigate(ROUTES.dashboardById.replace(':dashboardId', item.id))}
          />
          {/* <MenuItem text={commonTexts.duplicate} icon={<Icon name="Duplicate" />} onClick={onDuplicate} /> */}
          <MenuItem text={commonI18n.rename} icon={<Icon name="Edit" />} onClick={onRename} />
          {/* <MenuItem
          text={isFavorite ? commonTexts.removeFromFavorites : commonTexts.addToFavorites}
          icon={<Icon name={isFavorite ? 'StarFill' : 'StarOutline'} />}
          onClick={onToggleFavorite}
        /> */}
          {/* <MenuItem text={commonTexts.share} icon={<Icon name="Share" />} /> */}
          <MenuDivider />
          <MenuItem text={commonI18n.delete} icon={<Icon name="Delete" />} onClick={onDelete} />
        </Menu>
      ) : null,
    [commonI18n, item, navigate, onDelete, onRename]
  );

  return isDesktop ? (
    <DetachedPopover target={target} isOpen={isOpen} onClose={onClose}>
      {baseMenu}
    </DetachedPopover>
  ) : (
    <Drawer isOpen={isOpen} size="auto" onClose={onClose}>
      {baseMenu}
    </Drawer>
  );
};

export default withCommands(SingleDashboardMenu);
