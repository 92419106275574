import React from 'react';
import c from 'classnames';
import useTextSnippets from '@services/useTextSnippets';
import { DefaultMotion } from '@services';
import Icon from '@components/common/Icon';
import Headline from '@components/typography/Headline';
import PageHeader, { PageHeaderProps } from '@components/layout/PageHeader';
import ROUTES from '@infrastructure/routes';
import Breadcrumbs from '@components/common/Breadcrumbs';

type Props = {
  subPages?: {
    title: string;
    path: string;
  }[];
  level: number;
  pageControls?: PageHeaderProps['pageControls'];
};

const BasePage: React.FC<Props> = ({ children, subPages, pageControls, level }) => {
  const managementI18n = useTextSnippets('management');

  return (
    <div className="page-container">
      <PageHeader
        level={level}
        customTitle
        pageControls={pageControls}
        title={
          subPages ? (
            <Headline variant="headline-6" color="dark" additionalClass="flex items-center leading-20">
              <Breadcrumbs
                omitRootLabel
                items={[
                  {
                    current: false,
                    href: ROUTES.management,
                    label: managementI18n.title,
                  },
                ].concat(
                  subPages.map((page, idx) => ({
                    current: idx === subPages.length - 1,
                    href: page.path,
                    label: page.title,
                  }))
                )}
              />
            </Headline>
          ) : (
            <Headline variant="headline-5" color="dark" additionalClass="flex items-center">
              <Icon name="Management" size="s" additionalClass="mr-8" />
              <Breadcrumbs
                omitRootLabel
                items={[{ current: true, href: ROUTES.management, label: managementI18n.title }]}
              />
            </Headline>
          )
        }
      />
      <div className={c('page-body', level === 1 ? 'bg-white' : 'bg-blue-gray-background')}>
        <DefaultMotion key="issue-view-manager" full>
          {children}
        </DefaultMotion>
      </div>
    </div>
  );
};

export default BasePage;
