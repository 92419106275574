import React from 'react';
import c from 'classnames';
import { IssueWithDetails } from '@redux/issues';
import styles from './IssueDetailsDrawer.module.scss';
import useTextSnippets from '@services/useTextSnippets';
import HorizontalTitleSeparator from '@components/separators/HorizontalTitleSeparator/HorizontalTitleSeparator';
import IssueComments from './IssueComments';
import { Widget } from '@components/layout/Widget';
import { IssueInfo } from '@components/pages/IssuePage/components/IssueInfo';
import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import { EntityInfoWrapper } from './EntityInfoWrapper';

type IssueDetailsDrawerProps = DetailsPanelProps & {
  item?: IssueWithDetails;
};

const IssueDetailsDrawer: React.FC<IssueDetailsDrawerProps> = ({ item }) => {
  const commonI18n = useTextSnippets('common');

  return item ? (
    <>
      <IssueInfo hideHeader issue={item} />

      {item.entityid && <EntityInfoWrapper entityId={item.entityid} />}

      <HorizontalTitleSeparator title="Measurements" additionalClass={c(styles.drawerSeparator, 'mt-20')} />

      <div className="flex items-center justify-center w-full pt-20 pl-16">
        {!!item.alertid && (
          <Widget
            type="timeseries"
            hideMenu
            config={{
              type: 'line',
              source: { alertId: item.alertid },
              horizontalLegend: true,
            }}
            additionalClass={styles.chart}
          />
        )}
      </div>

      <HorizontalTitleSeparator title={commonI18n.comments} additionalClass={c(styles.drawerSeparator, 'mt-20')} />

      <IssueComments issue={item} />

      {/* <HorizontalTitleSeparator
        title={issuesI18n.relatedIssues}
        additionalClass={c(styles.drawerSeparator, 'mt-20')}
      />
      <div className="flex items-center justify-center w-full h-200">
        <UnsupportedWrapper>Related Issues - Coming soon</UnsupportedWrapper>
      </div> */}
    </>
  ) : null;
};

export default IssueDetailsDrawer;
