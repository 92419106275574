import React, { useCallback } from 'react';
import { MobileDrawerMenu, Menu, MenuDivider, MenuItem, MenuItemWithTitle } from '@components/common/Menu';
import Icon from '@components/common/Icon';
import CopyText from '@components/typography/CopyText';
import { ToggleSwitch } from '@components/common/form/ToggleSwitch';
import {
  renameDashboardStart,
  deleteDashboardStart,
  Dashboard,
  selectCurrentDashboardState,
  toggleLock,
  // toggleTimeControl,
  createSelectIsFavoriteDashboard,
} from '@redux/dashboards';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import useTextSnippets from '@services/useTextSnippets';
import { DefaultMotion } from '@services';
import withCommands, { WithCommands } from '@services/withCommands';

import styles from './DashboardsMenu.module.scss';

type Props = {
  item: Dashboard;
} & WithCommands;

const DashboardsMenu: React.FC<Props> = ({ item, openFavoritesMenuCommand }) => {
  const dispatch = useAppDispatch();
  const dashboardState = useAppSelector(selectCurrentDashboardState);
  const commonTexts = useTextSnippets('common');
  const dashboardTexts = useTextSnippets('dashboards');
  const isFavorite = useAppSelector(createSelectIsFavoriteDashboard(item.id));

  const onToggleFavorite = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      openFavoritesMenuCommand({
        dashboardId: item.id,
        targetEl: e.target as HTMLElement,
      });
    },
    [item, openFavoritesMenuCommand]
  );

  const onRename = useCallback(() => {
    dispatch(renameDashboardStart(item));
  }, [dispatch, item]);

  const onDelete = useCallback(() => {
    dispatch(deleteDashboardStart([item]));
  }, [dispatch, item]);

  // const onDuplicate = () => {
  // TODO - commands!!
  // };

  return (
    <MobileDrawerMenu>
      <Menu key="menu" className={styles.dashboardMenu}>
        <MenuItemWithTitle additionalClasses={{ root: 'mobile-only' }} disabledMenu title={item.name} />
        <MenuDivider className="mobile-only" />
        <MenuItem
          className="mobile-only"
          text={isFavorite ? commonTexts.removeFromFavorites : commonTexts.addToFavorites}
          icon={<Icon name={isFavorite ? 'StarFill' : 'StarOutline'} />}
          onClick={onToggleFavorite}
        />
        <MenuItem className="mobile-only" text={dashboardTexts.enterFullscreen} icon={<Icon name="Fullscreen" />} />
        <MenuDivider className="mobile-only" />
        <MenuItem
          shouldDismissPopover={false}
          text={
            <div className={styles.toggleMenuItemContainer}>
              <div className="flex">
                <DefaultMotion
                  key={dashboardState.isLocked ? 'Lock' : 'LockOpen'}
                  initial={{ y: 0, opacity: 0 }}
                  transition={{ delay: 0 }}
                >
                  {dashboardState.isLocked && <Icon name="Lock" additionalClass="mr-8" />}
                  {!dashboardState.isLocked && <Icon name="LockOpen" additionalClass="mr-8" />}
                </DefaultMotion>
                <CopyText variant="copy-4">{commonTexts.lockView}</CopyText>
              </div>
              <div className="flex">
                <ToggleSwitch
                  id="toggle-lock"
                  checked={dashboardState.isLocked}
                  onChange={() => dispatch(toggleLock())}
                  size="s"
                />
              </div>
            </div>
          }
        />
        {/* <MenuItem */}
        {/*  shouldDismissPopover={false} */}
        {/*  text={ */}
        {/*    <div className={styles.toggleMenuItemContainer}> */}
        {/*      <div className="flex"> */}
        {/*        <DefaultMotion */}
        {/*          key={dashboardState.isTimeControlled ? 'ClockFilled' : 'Clock'} */}
        {/*          initial={{ y: 0, opacity: 0 }} */}
        {/*          transition={{ delay: 0 }} */}
        {/*        > */}
        {/*          {dashboardState.isTimeControlled && <Icon name="ClockFilled" additionalClass="mr-8" />} */}
        {/*          {!dashboardState.isTimeControlled && <Icon name="Clock" additionalClass="mr-8" />} */}
        {/*        </DefaultMotion> */}
        {/*        <CopyText variant="copy-4">{commonTexts.timeControl}</CopyText> */}
        {/*      </div> */}
        {/*      <div className="flex"> */}
        {/*        <ToggleSwitch */}
        {/*          id="toggle-time-control" */}
        {/*          checked={dashboardState.isTimeControlled} */}
        {/*          onChange={() => dispatch(toggleTimeControl())} */}
        {/*          size="s" */}
        {/*        /> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*  } */}
        {/* /> */}
        <MenuDivider />
        {/* <MenuItem additionalClass="mobile-only" text={commonTexts.settings} icon={<Icon name="Settings" />} /> */}
        {/* <MenuItem additionalClass="no-mobile" text={commonTexts.settings} icon={<Icon name="Settings" />} label="⌘S" /> */}
        {/* <MenuItem */}
        {/*  additionalClass="mobile-only" */}
        {/*  text={commonTexts.duplicate} */}
        {/*  icon={<Icon name="Duplicate" />} */}
        {/*  onClick={onDuplicate} */}
        {/* /> */}
        {/* <MenuItem */}
        {/*  onClick={onDuplicate} */}
        {/*  additionalClass="no-mobile" */}
        {/*  text={commonTexts.duplicate} */}
        {/*  icon={<Icon name="Duplicate" />} */}
        {/*  label="⌘D" */}
        {/* /> */}
        <MenuItem
          additionalClass="mobile-only"
          text={commonTexts.rename}
          icon={<Icon name="Edit" />}
          onClick={onRename}
        />
        <MenuItem
          additionalClass="no-mobile"
          text={commonTexts.rename}
          icon={<Icon name="Edit" />}
          label="R"
          onClick={onRename}
        />
        {/* <MenuItem additionalClass="mobile-only" text={commonTexts.share} icon={<Icon name="Share" />} /> */}
        {/* <MenuItem additionalClass="no-mobile" text={commonTexts.share} icon={<Icon name="Share" />} label="⇧S" /> */}
        <MenuDivider />
        <MenuItem
          additionalClass="mobile-only"
          text={commonTexts.delete}
          icon={<Icon name="Delete" />}
          onClick={onDelete}
        />
        <MenuItem
          additionalClass="no-mobile"
          text={commonTexts.delete}
          icon={<Icon name="Delete" />}
          label="⇧D"
          onClick={onDelete}
        />
      </Menu>
    </MobileDrawerMenu>
  );
};

export default withCommands(DashboardsMenu);
