import Button from '@components/common/Button';
import PageHeader from '@components/layout/PageHeader';
import HorizontalSeparator from '@components/separators/HorizontalSeparator';
import Headline from '@components/typography/Headline';
import { useMaintenanceWindowsQueryById } from '@infrastructure/api/BaseNClient/useMaintenenceWindowByIdQuery';
import { replaceIdWithName } from '@infrastructure/redux/dashboards/dashboard-utils';
import ROUTES from '@infrastructure/routes';
import { useLoadingContext } from 'react-router-loading';
import withConfirmation from '@services/withConfirmation';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Error404Page from '../Error404Page';
import { MWSummary } from './components/MWSummary';
import { useMWDeleteMutation } from '@infrastructure/api/BaseNClient/useMWDeleteMutation';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { isLegacyMW } from '@utils/mw';

import styles from './MWSinglePage.module.scss';

export const MWSinglePage = withConfirmation(({ confirmOperation }) => {
  const loadingContext = useLoadingContext();
  const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();
  const { id } = useParams<{ id: string }>();
  const query = useMaintenanceWindowsQueryById(id!, { enabled: !!id });
  const navigate = useNavigate();
  const deleteMWMutation = useMWDeleteMutation();

  const isLegacy = isLegacyMW(query.data);

  useEffect(() => {
    document.title = `Maintenance Window ${query.data?.name ? `"${query.data?.name}"` : ''} - BaseN Platform`;
  }, [query.data?.name]);

  useEffect(() => {
    if (query.data) {
      loadingContext.done();
    }
  }, [query.data, loadingContext]);

  const handleEdit = useCallback(() => {
    if (query.data) {
      navigate(ROUTES.editMaintenanceWindow.replace(':id', query.data.id));
    }
  }, [navigate, query.data]);

  const handleDelete = useCallback(() => {
    void confirmOperation({
      title: 'Delete Maintenance Window',
      contents: 'Are you sure you want to delete this Maintenance Window?',
      modalProps: { danger: true },
    }).then(result => {
      if (result) {
        deleteMWMutation.mutate(id!, {
          onSuccess: getSuccessHandler(() => {
            navigate(ROUTES.maintenanceWindows);
          }, 'Maintenance Window removed successfully'),
          onError: getFailureHandler(undefined, 'Failed to remove Maintenance Window'),
        });
      }
    });
  }, [confirmOperation, deleteMWMutation, getFailureHandler, getSuccessHandler, id, navigate]);

  // const handleScheduleRemove = useCallback(
  //   (idx: number) => {
  //     void confirmOperation({
  //       title: 'Delete a Schedule',
  //       contents: 'Are you sure you want to delete this Schedule?',
  //       modalProps: { danger: true },
  //     }).then(result => {
  //       if (result) {
  //         // ... delete code here
  //       }
  //     });
  //   },
  //   [confirmOperation]
  // );

  if (!query.isLoading && (query.isError || !query.data)) {
    return <Error404Page />;
  }

  return query.isLoading ? null : (
    <div className="page-container">
      <PageHeader
        level={2}
        breadcrumbs
        breadcrumbsProps={{
          omitRootLabel: true,
          transformLabel: label => replaceIdWithName(label, query.data),
        }}
        pageControls={[
          {
            id: 'editMW',
            onClick: handleEdit,
            ariaLabel: 'Edit',
            icon: 'Add',
            label: 'Edit',
            tooltip: isLegacy ? 'Use legacy portal to modify Maintenance Windows in legacy format.' : undefined,
            disabled:
              !query.data ||
              // we do not support modification of legacy MWs
              isLegacy,
          },
        ]}
      />
      <div className="bg-white page-body">
        <div className={styles.summary}>
          <Headline variant="headline-6" additionalClass="mb-24">
            {query.data.name}
          </Headline>
          <MWSummary
            mw={query.data}
            // enable when it is supported
            //onScheduleRemove={handleScheduleRemove}
          />

          <HorizontalSeparator noSpacing additionalClass="mb-16" />

          <Button variant="stealthRed" onClick={handleDelete} additionalClass="mb-16">
            Delete Maintenance Window
          </Button>
        </div>
      </div>
    </div>
  );
});
