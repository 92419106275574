import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import useBreakpoint from './useBreakpoint';
import screenfull from 'screenfull';

// this is quite flakey, but that's everything we got
const isF11Mode = () => window.innerWidth === window.screen.width && window.innerHeight === window.screen.height;

type UseFullscreen = {
  enterFullscreen: (target?: Element) => Promise<void>;
  exitFullscreen: () => Promise<void>;
  isFullscreenEnabled: boolean;
  isF11: boolean;
  fullscreenTarget?: Element;
};

export const useFullscreen = (): UseFullscreen => {
  const {
    breakpoints: { isDesktop },
  } = useBreakpoint();
  const [isF11, setIsF11] = useState(screenfull.element || !isDesktop ? false : isF11Mode());
  const [fullscreenTarget, setFullscreenTarget] = useState(screenfull.element);

  const handleFullscreenChange = useCallback(() => {
    setFullscreenTarget(screenfull.element);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleWindowResize = useCallback(
    debounce(() => {
      // isF11Mode will return true even for non F11 fullscreen, so we additionally test for
      // fullscreenElement, which should be null for F11 mode
      setIsF11(screenfull.element || !isDesktop ? false : isF11Mode());
    }, 100),
    []
  );

  useEffect(() => {
    // handle in-browser switch to fullscreen and back
    screenfull.on('change', handleFullscreenChange);
    // hacky way to handle F11, which is basically entirely separate feature from in-browser fullscreen and doesn't
    // trigger fullscreenchange
    // NOTE: initial idea was to intercept F11 keypress, however event is swallowed for exit fullscreen event
    window.addEventListener('resize', handleWindowResize);

    return () => {
      screenfull.off('change', handleFullscreenChange);
      window.addEventListener('resize', handleWindowResize);
    };
  }, [handleFullscreenChange, handleWindowResize]);

  return {
    exitFullscreen: screenfull.exit,
    enterFullscreen: screenfull.request,
    isFullscreenEnabled: !!fullscreenTarget || isF11,
    isF11,
    fullscreenTarget,
  };
};
