import { TableColumn, TableColumns, TableItemAction } from '@components/common/Table/types';
import { isFalsy, toArray, toIdMap } from '@utils/misc';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { ITextSnippets } from '@services/useTextSnippets';
import { getEntityProperty } from '@utils/entity';
import { ENTITY_INFO_ITEMS } from '@constants/entity';
import { OptionsFilterField, OptionsFilterFieldProps } from './components/OptionsFilterField';
import { FieldConfig, FieldsConfig } from '@components/common/form/FormBuilder';
import { Filters } from '@infrastructure/redux/types';
import { EntityLink } from '../EntityPage/components/EntityLink';

export const getTableColumns = (filters: Filters, i18n: ITextSnippets['inventory']): TableColumns<EntityDetails> => {
  const optionsRenderer = (config: FieldConfig) => (
    <OptionsFilterField {...(config.props as OptionsFilterFieldProps)} />
  );

  return toIdMap([
    {
      id: 'name',
      label: i18n.name,
      width: 250,
      sortable: true,
      resizable: true,
      valueRenderer: (d: EntityDetails) => (
        <div className="w-full truncate hbox text-dark">
          <EntityLink entity={d} />
        </div>
      ),
    },
    {
      id: 'location',
      label: i18n.location,
      valueRenderer: ({ location }: EntityDetails) => {
        return location?.longitude && location?.latitude ? [location?.latitude, location?.longitude].join(', ') : '';
      },
    },
    ...Object.keys(ENTITY_INFO_ITEMS).reduce(
      (cols, key) => [
        ...cols,
        {
          id: key,
          label: ENTITY_INFO_ITEMS[key],
          sortable: true,
          valueRenderer: (entity: EntityDetails) => getEntityProperty(entity, key),
          filter: {
            fields: [
              {
                type: 'custom',
                props: {
                  name: key,
                  label: ENTITY_INFO_ITEMS[key],
                  property: key,
                  placeholder: `Select...`,
                  fullWidth: true,
                  defaultValue: toArray(filters?.discreteFilters?.[key]),
                  collapsible: false,
                  collapsed: false,
                  keepState: false,
                },
                renderer: optionsRenderer,
              } as FieldConfig,
            ],
          },
          filtering: !isFalsy(filters?.discreteFilters?.[key], false),
        },
      ],
      [] as TableColumn<EntityDetails>[]
    ),
  ]);
};

export const getFiltersConfig = (allFilters?: Filters): FieldsConfig => {
  const optionsRenderer = (config: FieldConfig) => (
    <OptionsFilterField {...(config.props as OptionsFilterFieldProps)} />
  );

  return [
    {
      type: 'toggle',
      props: {
        name: 'terminal_allowed',
        label: 'With terminal only',
        // TODO: need to cast to boolean somewhere prior to this, perhaps in vquery string validation method
        checked: [true, 'true'].includes(allFilters?.discreteFilters?.terminal_allowed),
        additionalClass: 'mx-8 mb-16',
      },
    },
    ...Object.keys(ENTITY_INFO_ITEMS).reduce(
      (items, key) => [
        ...items,
        {
          type: 'custom',
          props: {
            name: key,
            label: ENTITY_INFO_ITEMS[key],
            property: key,
            placeholder: `Select...`,
            fullWidth: true,
            collapsed: true,
            defaultValue: toArray(allFilters?.discreteFilters?.[key]),
          },
          renderer: optionsRenderer,
        } as FieldConfig,
      ],
      [] as FieldsConfig
    ),
  ];
};

export const getTableActions = (
  handlers: Record<string, (selectedItems: EntityDetails[]) => void>
): TableItemAction<EntityDetails>[] => [
  {
    disabled(selectedItems: EntityDetails[]) {
      return !selectedItems.length || !!selectedItems.find(entity => !entity?.terminal_allowed);
    },
    collapse: false,
    label: 'Run Script',
    id: 'runScript',
    icon: 'RunScript',
    handler: handlers.runScript,
  },
  {
    collapse: false,
    label: 'Open Terminal',
    id: 'openTerminal',
    icon: 'Terminal',
    handler: handlers.openTerminal,
    disabled(selectedItems: EntityDetails[]) {
      return selectedItems.filter(entity => entity.terminal_allowed).length !== 1;
    },
  },
  {
    label: 'Link to Script',
    id: 'linkToScript',
    icon: 'Link',
    handler: handlers.linkToScript,
    disabled(selectedItems: EntityDetails[]) {
      return !!selectedItems.find(entity => !entity.terminal_allowed);
    },
  },
];
