import {
  createSelectIssueViewById,
  getIssueViewsRelatedData,
  handleIssueViewsRelatedData,
} from '@infrastructure/redux/issues';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { useLoadingContext } from 'react-router-loading';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import IssueViewEdit from './IssueViewEdit';

export const IssueViewEditPage = () => {
  const { viewId } = useParams();
  const loadingContext = useLoadingContext();

  const issueView = useAppSelector(createSelectIssueViewById(viewId ?? ''));
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // this is quite a clunky way to retrieve all the data, but currently it's the only way
    // for details on why this is here refer to IssueManagementPage component, in brief issue view
    // depends on groups, actions, fields, etc
    // TODO refactor when possible
    setIsLoading(true);
    void getIssueViewsRelatedData(queryClient).then(results => {
      handleIssueViewsRelatedData(dispatch, results);
      loadingContext.done();
      setIsLoading(false);
    });
  }, [dispatch, loadingContext, queryClient]);

  if (isLoading) {
    return null;
  }

  return <IssueViewEdit issueView={issueView} />;
};
