import { useValidatedQuery } from '@services/useValidatedQuery';
import { UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult, useMutation } from 'react-query';
import { z } from 'zod';
import ENDPOINTS from '../endpoints';
import { apiClient } from '../ApiClient';

const resourceDetailsSchema = z.object({
  id: z.string(),
  type: z.string(), // e.g. PAGE, MQTT_TOPIC, PATH, ENTITYGROUP
});

const resourceSchema = z.object({
  role: z.string(), // e.g. WRITE, ADMIN
  resource: resourceDetailsSchema,
  groupid: z.number(),
});

export const groupSchema = z.object({
  name: z.string(),
  resources: z.array(resourceSchema),
  realm: z.string(),
  id: z.number(),
});

const updateableUserSchema = z.object({
  firstname: z.string().optional(),
  timezone: z.string().optional(),
  locale: z.string().optional(),
  lastname: z.string().optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
});

export const userSchema = z
  .object({
    authSource: z.string().or(z.null()).optional(),
    groups: z.array(z.number()), // array of group ids
    login: z.string(),
    locale: z.string().optional(),
    generated: z.boolean(),
    realm: z.string(),
    id: z.number(),
    username: z.string().optional(),
  })
  .merge(updateableUserSchema);

export const tokenSchema = z.object({
  token: z.string(),
  group: z.string(),
});

const userByIdResponseSchema = z.object({
  result: z.string(),
  groups: z.array(groupSchema),
  user: userSchema,
  tokens: z.array(tokenSchema).optional(),
});

export type Group = z.infer<typeof groupSchema>;
export type User = z.infer<typeof userSchema>;
export type UserByIdResponse = z.infer<typeof userByIdResponseSchema>;

export const useUserByIdQuery = <T extends UserByIdResponse = UserByIdResponse>(
  userId: string | number,
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.userById, { userId }, userByIdResponseSchema, queryOptions);
};

export const useUserUpdateMutation = <T extends UserByIdResponse = UserByIdResponse, P extends User = User>(
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> => {
  return useMutation<T, Error, P>(
    (params: User) => apiClient.put(ENDPOINTS.userById.replace(':userId', params.id.toString()), params),
    mutationOptions
  );
};
