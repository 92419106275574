import React, { useEffect, useMemo, useState } from 'react';
import c from 'classnames';
import TextControl from '@components/common/form/Input/TextControl';
import Icon from '@components/common/Icon';
import useTextSnippets from '@services/useTextSnippets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import {
  changeView,
  selectIssueViewOptions,
  selectActiveView,
  DEFAULT_ISSUE_VIEW,
  selectQueryParams,
  selectActiveIssueKey,
} from '@redux/issues';
import { useNavigate } from 'react-router-dom';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
import { selectSidebarOpen } from '@redux/layout';
import { useBreakpoint } from '@services';
import { Select } from '@components/common/form/Select/Select';
import { BaseSelectItem } from '@components/common/form/Select/BaseSelect';
import ROUTES from '@infrastructure/routes';
import Button from '@components/common/Button';
import {
  IssueDownloadPopup,
  IssueDownloadPopupProps,
} from '@components/connected/IssueManager/components/IssueDownloadPopup';
import { CypressTestsData, CypressTestType } from '@cypress';

import styles from './IssueManagerHeader.module.scss';

const IssueManagerHeader: React.FC = () => {
  const [minimalMode, setMinimalMode] = useState(false);
  const [downloadPopupProps, setDownloadPopupProps] = useState<IssueDownloadPopupProps | null>(null);

  const {
    breakpoints: { isXl, isXxl },
  } = useBreakpoint();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const i18n = useTextSnippets('issues');
  const commonI18n = useTextSnippets('common');

  const activeView = useAppSelector(selectActiveView);
  const issueViewOptions = useAppSelector(selectIssueViewOptions);
  const sidebarOpen = useAppSelector(selectSidebarOpen);
  const activeIssueKey = useAppSelector(selectActiveIssueKey);
  const queryParams = useAppSelector(selectQueryParams);

  const issueManagerTestDataConfig = CypressTestsData[CypressTestType.ISSUE_MANAGER];

  const handleDownloadClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDownloadPopupProps({
      params: queryParams,
      target: e.currentTarget,
    });
  };

  const handleChangeIssueView = (newIssueView: BaseSelectItem | null) => {
    if (newIssueView && newIssueView.id !== activeView.id) {
      dispatch(changeView(newIssueView.value as string));
    }
  };

  useEffect(() => {
    setMinimalMode(Boolean(!isXl || (!isXxl && sidebarOpen && activeIssueKey)));
  }, [isXl, isXxl, sidebarOpen, activeIssueKey]);

  const issueViewOptionsWithDefault = useMemo(() => {
    issueViewOptions.unshift({ label: i18n.defaultIssueManager, value: DEFAULT_ISSUE_VIEW.id });
    return issueViewOptions;
  }, [issueViewOptions, i18n]);

  const selectInput = useMemo(
    () => (
      <TextControl
        variant="outlined"
        noInfo
        size="m"
        testid={issueManagerTestDataConfig.testIds.views.viewSelectInput}
      />
    ),
    [issueManagerTestDataConfig.testIds.views.viewSelectInput]
  );

  return (
    <div className={c(styles.issueManagerHeader, minimalMode && styles.minimalHeader)}>
      <div className="flex items-center mb-16">
        <Headline variant="headline-6" additionalClass={styles.pageTitle}>
          {i18n.issueManager}
        </Headline>
        <Select
          addCreateNew
          createNewLabel={i18n.manageViews}
          createNewIcon={false}
          onCreateNew={() => navigate(ROUTES.issueViewManager)}
          additionalClass="ml-16 mr-8"
          inputComponent={selectInput}
          filterable
          clearable={false}
          options={issueViewOptionsWithDefault}
          value={{
            label: activeView.id === 'default' ? i18n.defaultIssueManager : activeView.name,
            value: activeView.id,
          }}
          onChange={handleChangeIssueView}
          optionsTestIdPrefix={issueManagerTestDataConfig.testIds.views.selectOptionsPrefix}
          optionsCreateNewTestId={issueManagerTestDataConfig.testIds.views.selectOptionsManageViews}
        />
        <div className="ml-auto">
          <Button
            variant="outline"
            onClick={handleDownloadClick}
            ariaLabel={commonI18n.download}
            size="s"
            testid={issueManagerTestDataConfig.testIds.downloadIssues.downloadButton}
          >
            <div className="hbox">
              <Icon name="Download" size="s" additionalClass="mobileLandscape:mr-4" />
              <CopyText variant="copy-5" additionalClass="hidden mobileLandscape:inline-block">
                {commonI18n.download}
              </CopyText>
            </div>
          </Button>
        </div>
      </div>

      {downloadPopupProps && (
        <IssueDownloadPopup isOpen {...downloadPopupProps} onClose={() => setDownloadPopupProps(null)} />
      )}
    </div>
  );
};

export default IssueManagerHeader;
