import useTextSnippets from '@services/useTextSnippets';
import { GenericNotOkApiResponse } from '@infrastructure/api/types';
import { setNotification } from '@redux/notifications';
import { useAppDispatch } from '@infrastructure/redux/store';
import { useCallback } from 'react';

export type Failure = Error | GenericNotOkApiResponse | { message: string };

export const useGenericMutationHandlers = () => {
  const i18n = useTextSnippets('common');
  const dispatch = useAppDispatch();

  const getSuccessHandler = useCallback(
    <S extends unknown = unknown>(cb?: Function, customMessage?: string) =>
      (data?: S) => {
        cb?.(data);
        dispatch(
          setNotification({
            type: 'success',
            title: i18n.success,
            description: customMessage ?? i18n.operationSuccessful,
          })
        );
      },
    [dispatch, i18n.operationSuccessful, i18n.success]
  );

  const getFailureHandler = useCallback(
    (cb?: Function, customMessage?: string, noError?: boolean) => (error: Failure) => {
      cb?.(error);
      if (!noError) {
        dispatch(
          setNotification({
            type: 'error',
            title: i18n.failure,
            description: `${i18n.operationFailed} ${customMessage ?? error.message ?? i18n.unknownError}`,
          })
        );
      }
    },
    [dispatch, i18n]
  );

  return { getSuccessHandler, getFailureHandler };
};
