import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import { ForgotPasswordSchema } from '@components/pages/ForgotPassword/ForgotPasswordForm/ForgotPasswordForm.schema';
import { GenericOkApiResponse } from '@infrastructure/api/types';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';

export const useForgotPasswordHandler = <
  T extends GenericOkApiResponse = GenericOkApiResponse,
  P extends ForgotPasswordSchema = ForgotPasswordSchema
>(
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> =>
  useMutation<T, Error, P>(
    (forgotPasswordParams): Promise<T> => apiClient.post<T, P>(ENDPOINTS.forgotPassword, forgotPasswordParams),
    mutationOptions
  );
