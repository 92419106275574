import { useMutation, UseMutationResult } from 'react-query';
import { WidgetConfig } from '@redux/widgetPage';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { WidgetMutationError, WidgetMutationSuccess } from './useCreateWidgetMutation';

export type WidgetUpdateRequest = WidgetConfig;

export const useUpdateWidgetMutation = <
  S extends WidgetMutationSuccess = WidgetMutationSuccess,
  F extends WidgetMutationError = WidgetMutationError,
  V extends WidgetUpdateRequest = WidgetUpdateRequest
>(
  mutationOptions = {}
): UseMutationResult<S, F, V> =>
  useMutation<S, F, V>(
    (body: V): Promise<S> =>
      apiClient.put(ENDPOINTS.putWidget, {
        widgetId: body.id,
        name: body.name,
        config: body,
      }),
    mutationOptions
  );
