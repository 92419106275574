import React from 'react';

export type ModalType = React.FunctionComponent<any>;

export interface ModalContextType {
  showModal(key: string, component: ModalType): void;
  hideModal(key: string): void;
}

const invariantViolation = () => {
  throw new Error('ModalProvider missing. Add to app root!');
};

export const ModalContext = React.createContext<ModalContextType>({
  showModal: invariantViolation,
  hideModal: invariantViolation,
});

ModalContext.displayName = 'ModalContext';
