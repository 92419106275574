import React, { useCallback, useState, useMemo } from 'react';
import { ModalType, ModalContext } from './modalContext';
import { ModalRoot } from './modalRoot';

export interface ModalProviderProps {
  container?: Element;
  rootComponent?: React.ComponentType<any>;
  children: React.ReactNode;
}

export const ModalProvider = ({ container, rootComponent, children }: ModalProviderProps) => {
  const [modals, setModals] = useState<Record<string, ModalType>>({});

  if (container && !(container instanceof HTMLElement)) {
    throw new Error('Container Missing!');
  }

  const showModal = useCallback(
    (key: string, modal: ModalType) =>
      setModals(prevModals => ({
        ...prevModals,
        [key]: modal,
      })),
    []
  );

  const hideModal = useCallback(
    (key: string) =>
      setModals(prevModals => {
        if (!prevModals[key]) {
          return modals;
        }

        const newModals = { ...prevModals };

        delete newModals[key];

        return newModals;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => ({ showModal, hideModal }), []);

  return (
    <ModalContext.Provider value={contextValue}>
      <>
        {children}
        <ModalRoot modals={modals} component={rootComponent} container={container} />
      </>
    </ModalContext.Provider>
  );
};
