import Link from '@components/common/Link';
import { TableColumns } from '@components/common/Table/types';
import { ChildEntity } from '@redux/inventoryPage/types';
import ROUTES from '@infrastructure/routes';
import React from 'react';

export const getTableColumns = (): TableColumns<ChildEntity> => {
  return [
    {
      id: 'id',
      label: 'ID',
      width: 150,
      valueRenderer: (item: ChildEntity) => (
        <Link href={ROUTES.entityById.replace(':entityId', item.id)}>{item.id}</Link>
      ),
    },
    {
      id: 'textid',
      label: 'Name',
      width: 150,
    },
    {
      id: 'ip',
      label: 'IP',
      width: 50,
    },
  ].reduce((config, column) => ({ ...config, [column.id]: column }), {});
};
