import { getURLQueryParams } from '@utils/url';
import { DiffPanel } from './components/DiffPanel';
import React, { useMemo, useState } from 'react';
import { updateQueryString } from '@services/useHistory';
import Card from '@components/common/Card/Card';
import Error404Page from '../Error404Page';
import { DevicePicker } from './components/DevicePicker';
import { VersionPicker } from './components/VersionPicker';
import { InfiniteListItem } from '@components/connected/InfiniteSelect/InfiniteList';
import { ConfigParams } from '@infrastructure/api/BaseNClient/useDeviceConfigParams';
import { Note } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import { VersionDetails } from './components/VersionDetails';
import Button from '@components/common/Button';
import Icon from '@components/common/Icon';
import { floor } from 'lodash';
import CopyText from '@components/typography/CopyText';
import { EntityIcon } from '../EntityPage/components/EntityIcon';
import { EntityStatusDot } from '../EntityPage/components/EntityStatusDot';
import { useNavigate } from 'react-router-dom';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import ROUTES from '@infrastructure/routes';
import { useLoadingContext } from 'react-router-loading';

import styles from './ConfigDiffPage.module.scss';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { UseQueryResult } from 'react-query';
import Breadcrumbs from '@components/common/Breadcrumbs';
import { BreadcrumbProps } from '@components/common/Breadcrumbs/Breadcrumb';


const getEntityUrl = (id: string) => ROUTES.entityById.replace(':entityId', id).concat('?activeTabTitle=Config');

type ConfigDiffPageParams = {
  entityId1?: string;
  version1?: string;
  entityId2?: string;
  version2?: string;
  fromPage?: string;
};

export type ConfigDiffPageProps = {};

export const ConfigDiffPage = () => {
  const loadingContext = useLoadingContext();

  const params = getURLQueryParams() as ConfigDiffPageParams;
  const [isCompareView, setIsCompareView] = useState(!!params.entityId2);

  const navigate = useNavigate();

  const entity = useEntityQuery({ entityId: params.entityId1, alerts: true, include_maintenance: false });
  let entity2: UseQueryResult<EntityDetails, Error> | undefined = undefined
  if (params.entityId2) {
    entity2 = useEntityQuery({ entityId: params.entityId2, alerts: true, include_maintenance: false });
  }

  const [device1, setDevice1] = useState<InfiniteListItem<ConfigParams> | null>(null);
  const [config1, setConfig1] = useState<InfiniteListItem<Note> | null>(null);
  const [device2, setDevice2] = useState<InfiniteListItem<ConfigParams> | null>(null);
  const [config2, setConfig2] = useState<InfiniteListItem<Note> | null>(null);

  React.useEffect(() => {
    if (entity.data) {
      loadingContext.done();
    }
  }, [entity?.data, loadingContext]);

  React.useEffect(() => {
    if (device1?.value?.entityid && config1?.value?.timestamp) {
      updateQueryString({
        entityId1: device1.value.entityid,
        version1: `${floor(config1.value.timestamp / 1000)}`,
        entityId2: device2?.value.entityid,
        version2: config2?.value.timestamp ? floor(config2.value.timestamp / 1000) : undefined,
      } as ConfigDiffPageParams);
    }
  }, [device1, config1, device2, config2]);

  const breadcrumbsProps = useMemo(
    () => ({
      items: params.fromPage === 'entity' && entity.data
        ? ([
          { label: 'Inventory', href: '/inventory' },
          entity.data.parent ? { label: entity.data?.name, href: getEntityUrl(entity.data.parent) } : false,
          {
            label: entity.data.name,
            href: getEntityUrl(entity.data.id),
          }, { label: 'Config Diff View', current: true }
        ].filter(Boolean) as BreadcrumbProps[])
        : ([
          { label: 'Config', href: ROUTES.configSearch },
          { label: 'Config Diff View', current: true }
        ].filter(Boolean) as BreadcrumbProps[])
    }),
    [entity.data]
  );

  const handleToggleCompareView = React.useCallback(() => {
    setIsCompareView(prevState => {
      const nextState = !prevState;
      if (!nextState) {
        setDevice2(null);
        setConfig2(null);
      }
      return nextState;
    });
  }, []);

  if (params.entityId1) {
    if (!entity.isLoading && !entity.data) {
      return <Error404Page />;
    }
  }

  return !entity.isLoading ? (
    <div className="page-container">
      <div className="page-header">
        <div className={styles.headerSection}>
          <Breadcrumbs {...breadcrumbsProps} additionalClasses={{ root: styles.breadcrumbs }} />
        </div>

        <div className="flex justify-center gap-8">
          {entity?.data &&
            <CopyText variant="copy-2" additionalClass="flex items-center gap-8">
              <EntityIcon entity={entity.data} />
              {entity.data?.name}
              <EntityStatusDot entity={entity.data} />
            </CopyText>
          }
          {entity2?.data &&
            <CopyText variant="copy-2" additionalClass="flex items-center gap-8">
              <EntityIcon entity={entity2.data} />
              {entity2.data?.name}
              <EntityStatusDot entity={entity2.data} />
            </CopyText>
          }
        </div>
      </div>

      <div className="page-body">
        <div className="items-stretch h-full gap-16 hbox">
          <Card header="Config Diff View" additionalClass={styles.sidebar}>
            <div className="pb-20 vbox">
              <DevicePicker
                placeholder="Select a device..."
                readOnly={false}
                clearable={false}
                value={device1 ?? params.entityId1}
                onChange={option => {
                  setDevice1(option);
                  setConfig1(null);
                }}
              />
              <VersionPicker
                key={device1?.value.entityid ?? params.entityId1}
                placeholder="Select a version..."
                clearable={false}
                entityId={device1?.value.entityid ?? params.entityId1 ?? ''}
                value={config1 ?? (params.version1 ? `${+params.version1 * 1000}` : undefined)}
                onChange={setConfig1}
                disabled={!device1?.value.entityid}
                noInfo
              />

              {config1?.value && <VersionDetails note={config1.value} />}
            </div>

            {isCompareView && (
              <div className="pb-20 vbox">
                <DevicePicker
                  placeholder="Select a device..."
                  clearable={false}
                  value={device2 ?? device1 ?? params.entityId2}
                  onChange={option => {
                    setDevice2(option);
                    setConfig2(null);
                  }}
                />
                <VersionPicker
                  key={device2?.value.entityid ?? params.entityId2}
                  placeholder="Select a version..."
                  clearable={false}
                  entityId={device2?.value.entityid ?? params.entityId2 ?? ''}
                  value={config2 ?? (params.version2 ? `${+params.version2 * 1000}` : undefined)}
                  onChange={setConfig2}
                  disabled={!device2?.value.entityid}
                  noInfo
                />

                {config2?.value && <VersionDetails note={config2.value} />}
              </div>
            )}

            <div className="mt-20">
              {isCompareView ? (
                <Button variant="fillOutline" size="s" onClick={handleToggleCompareView}>
                  <Icon name="Collapse" additionalClass="mr-4" size="s" />
                  Back to Single view
                </Button>
              ) : (
                <Button variant="fillOutline" size="s" onClick={handleToggleCompareView}>
                  <Icon name="Expand" additionalClass="mr-4" size="s" />
                  Compare with...
                </Button>
              )}
            </div>
          </Card>

          <DiffPanel
            entityId1={device1?.value.entityid ?? undefined}
            entityId2={isCompareView ? device2?.value.entityid : undefined}
            version1={config1?.value?.timestamp?.toString() ?? undefined}
            version2={isCompareView ? config2?.value?.timestamp?.toString() : undefined}
          />
        </div>
      </div>
    </div>
  ) : null;
};
