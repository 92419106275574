import { ITextSnippets } from '@services/useTextSnippets';
import { CreateIssueView, IssueView, IssueViewField } from '@redux/issues/types';
import { DEFAULT_TABLE_CONFIG } from '@components/common/Table/config';
import { BaseSelectItem } from '@components/common/form/Select/types';

export const STATUS_OPTIONS = {
  NEW: 'new',
  OPEN: 'open',
  TRACKING: 'tracking',
  FROZEN: 'frozen',
  CLOSING: 'closing',
  CLOSED: 'closed',
};

export const getStatusOptions = (i18nKeys: ITextSnippets['common']): BaseSelectItem<string>[] => [
  { label: i18nKeys.new, value: STATUS_OPTIONS.NEW },
  { label: i18nKeys.open, value: STATUS_OPTIONS.OPEN },
  { label: i18nKeys.tracking, value: STATUS_OPTIONS.TRACKING },
  { label: i18nKeys.frozen, value: STATUS_OPTIONS.FROZEN },
  { label: i18nKeys.closing, value: STATUS_OPTIONS.CLOSING },
  { label: i18nKeys.closed, value: STATUS_OPTIONS.CLOSED },
];

export const PRIORITY_OPTIONS = {
  CRITICAL: 'critical',
  HIGH: 'high',
  NORMAL: 'normal',
  LOW: 'low',
  NONE: 'none',
};

export const getPriorityOptions = (i18nKeys: ITextSnippets['common']): BaseSelectItem<string>[] => [
  { label: i18nKeys.critical, value: PRIORITY_OPTIONS.CRITICAL },
  { label: i18nKeys.high, value: PRIORITY_OPTIONS.HIGH },
  { label: i18nKeys.normal, value: PRIORITY_OPTIONS.NORMAL },
  { label: i18nKeys.low, value: PRIORITY_OPTIONS.LOW },
  { label: i18nKeys.none, value: PRIORITY_OPTIONS.NONE },
];

export const DEFAULT_FIELDS: IssueViewField[] = [
  'entity',
  'state',
  'priority',
  'name',
  'group',
  'alerts',
  'created',
  'last_update',
  'comment',
  'last_value',
  'extref',
].map((field, idx) => ({
  id: field,
  order: idx,
}));

export const DEFAULT_CUSTOM_FIELDS = ['alert.id', 'alert.name', 'entity.id', 'entity.name'].map((field, idx) => ({
  id: field,
  order: DEFAULT_FIELDS.length + idx,
}));

export const DEFAULT_FIELDS_ORDER = DEFAULT_FIELDS.map(({ id }) => id);

export const DEFAULT_CUSTOM_FIELDS_ORDER = DEFAULT_CUSTOM_FIELDS.map(({ id }) => id);

export const DEFAULT_ISSUE_VIEW: IssueView = {
  id: 'default',
  name: '---',
  description: '',
  visibility: 'shared',
  filters: {
    groups: [],
    custom_fields: [...DEFAULT_CUSTOM_FIELDS_ORDER],
    fields: [...DEFAULT_FIELDS_ORDER],
  },
  field_order: [...DEFAULT_FIELDS_ORDER, ...DEFAULT_CUSTOM_FIELDS_ORDER],
  sort_column: '',
  order: 0,
  actions: [],
  row_height: 'small',
  rows_per_page: DEFAULT_TABLE_CONFIG.rowsPerPage,
};

export const EMPTY_ISSUE_VIEW: CreateIssueView = {
  name: '',
  description: '',
  filters: {
    groups: [],
    custom_fields: [...DEFAULT_CUSTOM_FIELDS_ORDER],
    fields: [...DEFAULT_FIELDS_ORDER],
  },
  visibility: 'shared',
  order: 0,
  sort_column: '',
  field_order: [...DEFAULT_FIELDS_ORDER, ...DEFAULT_CUSTOM_FIELDS_ORDER],
  actions: [],
  row_height: 'small',
  rows_per_page: DEFAULT_TABLE_CONFIG.rowsPerPage,
};
