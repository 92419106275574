import React, { MouseEventHandler, useRef, useState } from 'react';
import Button from '@components/common/Button';
import CopyText from '@components/typography/CopyText';
import useTextSnippets from '@services/useTextSnippets';
import useInterval from '@services/useInterval';
import styles from './style.module.scss';

type Props = {
  handleResend: MouseEventHandler;
  count?: number;
};

type StartedClock = 'STARTED';
type StoppedClock = 'STOPPED';

type ClockStatus = StoppedClock | StartedClock;

const ResendLink: React.FC<Props> = ({ handleResend, count = 60 }) => {
  const linkRef = useRef<HTMLDivElement>(null);
  const { noMail, resend, tryAgainIn, second, seconds } = useTextSnippets('forgotPassword');
  const [secondsRemaining, setSecondsRemaining] = useState(count);
  const [status, setStatus] = useState<ClockStatus>('STOPPED');

  const handleStart = () => {
    setSecondsRemaining(count);
    setStatus('STARTED');
  };

  const handleStop = () => {
    setStatus('STOPPED');
  };

  useInterval(
    () => {
      if (linkRef && linkRef.current) {
        if (secondsRemaining > 1) {
          setSecondsRemaining(secondsRemaining - 1);
        } else {
          handleStop();
        }
      }
    },
    1000,
    status === 'STOPPED'
  );

  const handleClick: MouseEventHandler = e => {
    if (status === 'STOPPED') {
      handleStart();
      handleResend(e);
    }
  };

  return (
    <div ref={linkRef}>
      {status === 'STOPPED' && (
        <CopyText additionalClass={styles.resendLink} variant="copy-4">
          {noMail}
          <Button variant="link" onClick={handleClick}>
            {resend}
          </Button>
        </CopyText>
      )}
      {status === 'STARTED' && (
        <CopyText additionalClass={styles.resendLink} variant="copy-4">{`${tryAgainIn} ${secondsRemaining} ${
          secondsRemaining > 1 ? seconds : second
        }`}</CopyText>
      )}
    </div>
  );
};

export default ResendLink;
