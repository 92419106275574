import { StatusDot, StatusDotProps } from '@components/common/StatusDot/StatusDot';
import { useAlertsQuery } from '@infrastructure/api/BaseNClient/useAlertsQuery';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { isNowInMaintenanceWindow } from '@utils/chart';
import { isAlertColor, StripeColor } from '@utils/issue';
import { useMemo } from 'react';

type EntityStatusDotProps = StatusDotProps & { entity: EntityDetails; additionalClass?: string };

export const EntityStatusDot: React.FC<EntityStatusDotProps> = ({ entity, size = 'm', additionalClass }) => {
  const alerts = useAlertsQuery(
    {
      ids: entity.alert_ids?.join(','),
    },
    {
      enabled: !!entity.alert_ids?.length,
    }
  );

  const color = useMemo(() => {
    if (entity.maintenancewindows?.length && isNowInMaintenanceWindow(entity.maintenancewindows)) {
      return 'maintenance';
    } else if (entity.parent) {
      return (alerts.data?.alert_map['if oper:status']?.info?.status as StripeColor) ?? null;
    } else {
      return (alerts.data?.alert_map['ping:loss']?.info?.status as StripeColor) ?? null;
    }
  }, [entity, alerts.data]);

  return alerts.isLoading || !isAlertColor(color) ? null : (
    <StatusDot size={size} color={color} additionalClass={additionalClass} />
  );
};
