import { useMutation, UseMutationResult } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericNotOkApiResponse, GenericOkApiResponse } from '../types';
import { z } from 'zod';
import { alertOverridesSchema } from './useAlertOverrideQuery';

export const alertOverrideRequestSchema = z.object({
  alerts: z.array(alertOverridesSchema),
});

type AlertOverrideMutationRequest = z.infer<typeof alertOverrideRequestSchema>;
export type AlertOverrideMutationResponse = GenericOkApiResponse & {
  id: string;
};

export const useAlertOverrideMutation = <
  S extends AlertOverrideMutationResponse = AlertOverrideMutationResponse,
  F extends GenericNotOkApiResponse = GenericNotOkApiResponse,
  V extends AlertOverrideMutationRequest = AlertOverrideMutationRequest
>(
  mutationOptions = {}
): UseMutationResult<S, F, V> =>
  useMutation<S, F, V>((body: V): Promise<S> => apiClient.put(ENDPOINTS.alertOverride, body), mutationOptions);
