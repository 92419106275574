import React, { FC } from 'react';
import { useField } from 'formik';
import CodeControl, { CodeControlProps } from './CodeControl';

export type CodeFieldProps = CodeControlProps & {
  name: string;
};

export const CodeField: FC<CodeFieldProps> = ({ size, ...props }) => {
  const [field, { error, touched }, helpers] = useField(props);

  return (
    <CodeControl
      size={size}
      {...props}
      {...field}
      formHelpers={helpers}
      errorText={touched ? error : undefined}
      noInfo
    />
  );
};
