import { z } from 'zod';
import { groupSchema, tokenSchema, userSchema } from './useUsersApi';
import { infoSchema } from './useSettingsData';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useValidatedQuery } from '@services/useValidatedQuery';
import ENDPOINTS from '../endpoints';
import QUERY_CONFIGS from '../query-configs';

const meSchema = z.object({
  result: z.string(),
  user: z.object({
    groups: z.array(groupSchema),
    user: userSchema,
    tokens: z.array(tokenSchema).optional(),
  }),
  info: infoSchema,
});

export type MeResponse = z.infer<typeof meSchema>;

export const useMeQuery = <T extends MeResponse = MeResponse>(
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useValidatedQuery<T, Error>(ENDPOINTS.me, {}, meSchema, { ...QUERY_CONFIGS.stale, ...queryOptions });
};
