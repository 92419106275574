import React from 'react';
import { BatchMessage, BatchRunDetails, BatchSummary } from './useBatchRun.schema';
import useSocket, { UseSocketResponse } from './useSocket';

type ScriptEntry = {
  command: string;
  expect?: string;
};

export type BatchParams = {
  entities: string[];
  password: string;
  username: string;
  script: Array<ScriptEntry>;
  variables?: Record<string, any>;
};

type UseBatchRun = {
  token?: string;
  onError?: (error: Error) => void;
  params: BatchParams;
  onStart?: (batchId: string) => void;
  onProgress?: (percent: number, details: BatchRunDetails) => void;
  onComplete?: (summary: BatchSummary) => void;
  onDisconnect?: (isCompleted?: boolean) => void;
  jobs?: number;
  timeout?: number;
  interval?: number;
};

type UseBatchRunResponse = UseSocketResponse &
  BatchRunDetails & {
    abort: () => void;
  };

export const useBatchRun = ({
  token,
  onError,
  onStart,
  onProgress,
  onComplete,
  onDisconnect,
  jobs = 25,
  timeout = 30,
  interval = 1,
  ...props
}: UseBatchRun) => {
  const [lastUpdate, forceUpdate] = React.useState(Date.now());

  const statusRef = React.useRef<BatchRunDetails>({
    status: 'idle',
    startTime: Date.now(),
    endTime: null,
    progress: props.params.entities.reduce(
      (acc, entity) => ({
        ...acc,
        [entity]: {
          state: 'running',
          start_time: Date.now(),
          entity,
        },
      }),
      {}
    ),
    summary: {
      running: 0,
      total: props.params.entities.length,
      waiting: props.params.entities.length,
      completed: 0,
      errors: 0,
      aborted: 0,
    },
    script: props.params.script.map(({ command }) => command).join('\n'),
  });

  const handleMessage = React.useCallback(
    (message: BatchMessage) => {
      switch (message.channel) {
        case 'started': {
          statusRef.current.status = 'running';
          onStart?.(message.data.id);
          onProgress?.(0, statusRef.current);
          forceUpdate(Date.now());
          break;
        }

        case 'status': {
          statusRef.current.summary = message.data;
          forceUpdate(Date.now());
          break;
        }

        case 'jobstate': {
          statusRef.current.progress = { ...statusRef.current.progress, [message.data.entity]: message.data };
          const total = Object.keys(statusRef.current.progress).length;
          const running = Object.values(statusRef.current.progress).filter(({ state }) => state === 'running').length;
          const percent = Math.round(((total - running) / total) * 100);
          onProgress?.(percent, statusRef.current);
          forceUpdate(Date.now());
          break;
        }

        case 'summary': {
          statusRef.current.status = 'completed';
          statusRef.current.endTime = Date.now();
          onProgress?.(100, statusRef.current);
          onComplete?.(message.data);
          forceUpdate(Date.now());
          break;
        }
      }
    },
    [onStart, onProgress, onComplete]
  );

  const handleConnect = React.useCallback(
    () => {
      if (statusRef.current.status === 'idle') {
        statusRef.current.status = 'running';
        socketState.sendMessage({ channel: 'batch', data: props.params });
        forceUpdate(Date.now());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleDisconnect = React.useCallback(() => {
    onDisconnect?.(statusRef.current.status === 'completed');
  }, [onDisconnect]);

  const handleError = React.useCallback(
    (error: any) => {
      statusRef.current.status = 'error';
      onError?.(error);
      forceUpdate(Date.now());
    },
    [onError]
  );

  const socketState = useSocket<BatchMessage>({
    token,
    service: 'jumpstation_batch',
    channels: ['started', 'status', 'jobstate', 'summary'],
    onMessage: handleMessage,
    onConnect: handleConnect,
    onDisconnect: handleDisconnect,
    onError: handleError,
    autoReconnect: false,
    autoConnect: true,
    params: {
      jobs,
      timeout,
      interval,
    },
  });

  const abort = React.useCallback(() => {
    statusRef.current.status = 'aborted';
    socketState.sendMessage({ channel: 'abort', data: {} });
    forceUpdate(Date.now());
  }, [socketState]);

  const responseRef = React.useRef<UseBatchRunResponse>({
    ...socketState,
    ...statusRef.current,
    abort,
  });

  React.useEffect(() => {
    responseRef.current = {
      ...socketState,
      ...statusRef.current,
      abort,
    };
  }, [socketState, abort, lastUpdate]);

  return responseRef.current;
};
