import { TabsPanel, TabsPanelProps } from '@components/common/Tabs';
import { useMemo, useState } from 'react';
import { PortableMaintenanceTable } from '@components/connected/MaintenanceTable/PortableMaintenanceTable';
import { PortableIssueManager } from '@components/connected/IssueManager/PortableIssueManager';
import { DetailsTab } from './DetailsTab';
import { AlertConfig } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';

import styles from './AlertTabs.module.scss';

type AlertTabsProps = {
  alert: AlertConfig;
  entityId?: string;
};

export const AlertTabs = ({ alert, entityId }: AlertTabsProps) => {
  const [activeTabIdx, setActiveTabIdx] = useState(0);

  const tabs = useMemo(() => {
    return [
      {
        tabProps: {
          title: 'Details',
        },
        panel: <DetailsTab alert={alert} />,
      },
      {
        tabProps: {
          title: 'Active Issues',
        },
        panel: (
          <div className="-m-16">
            <PortableIssueManager entityId={entityId} />
          </div>
        ),
      },
      {
        tabProps: {
          title: 'Maintenance',
        },
        panel: <PortableMaintenanceTable entityId={entityId} />,
      },
    ].filter(Boolean) as TabsPanelProps['tabs'];
  }, [alert, entityId]);

  return (
    <TabsPanel additionalClass={styles.tabsPanel} onChange={setActiveTabIdx} activeIdx={activeTabIdx} tabs={tabs} />
  );
};
