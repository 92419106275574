import React from 'react';
import c from 'classnames';
import CopyText from '@components/typography/CopyText';
import MenuItem, { Props as MenuItemProps } from '../MenuItem';

export type Props = { additionalClasses?: { root?: string; text?: string }; title: string; subTitle?: string } & Omit<
  MenuItemProps,
  'text'
>;

const MenuItemWithTitle: React.FC<Props> = ({ additionalClasses = {}, title, subTitle, ...rest }) => (
  <MenuItem
    text={
      <div>
        <CopyText variant="copy-3" additionalClass={additionalClasses.text}>
          {title}
        </CopyText>
        {subTitle && <CopyText variant="copy-6">{subTitle}</CopyText>}
      </div>
    }
    {...rest}
    className={c(rest.className, additionalClasses.root)}
  />
);

export default MenuItemWithTitle;
