import { getIssueViewsRelatedData, handleIssueViewsRelatedData } from '@redux/issues';
import { useAppDispatch } from '@infrastructure/redux/store';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import IssueManagement from './IssueManagement';
import { useLoadingContext } from 'react-router-loading';

export const IssueManagementPage = () => {
  const loadingContext = useLoadingContext();

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    document.title = `Issue Manager - BaseN Platform`;
  }, []);

  // TODO: split this up somehow
  // the way this seem to work at the moment is that it retrieves issue related data - actions, groups, views, fields
  // and then initial set of issues themselves and populates redux slice with all of this, so that children components
  // down the chain can feed of it
  useEffect(
    () => {
      setIsLoading(true);
      void getIssueViewsRelatedData(queryClient).then(results => {
        handleIssueViewsRelatedData(dispatch, results);
        loadingContext.done();
        setIsLoading(false);
      });
      // .then(results => {
      //   // let issueListQueryParams: Record<string, string> = {
      //   const issueListQueryParams: Record<string, string | number> = {
      //     issueFields: DEFAULT_CUSTOM_FIELDS.join(','),
      //     start_index: 0,
      //     end_index: 25,
      //   };

      //   // TODO tie in with user preference for default issue view
      //   if (results.views && results.views.length > 0) {
      //     // const userPreferredDefaultView = ...
      //     // issueListQueryParams = constructIssueListQueryParamsFromView(userPreferredDefaultView);
      //   }

      //   queryClient
      //     .fetchQuery<{ data: Issue[] }>(IssueQueryKey, async () =>
      //       apiClient.get<{ data: Issue[] }>(ENDPOINTS.issues, issueListQueryParams)
      //     )
      //     .then(({ data }) => {
      //       dispatch(issuesLoaded(data || []));
      //     });
      // });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return isLoading ? null : <IssueManagement />;
};
