import { DetachedPopover } from '@components/common/Popover/DetachedPopover';
import { useMemo } from 'react';
import { ClusterPopupContent, ClusterPopupContentProps } from './ClusterPopupContent';
import { EntityPopupContent, EntityPopupContentProps } from './EntityPopupContent';
import { RoutePopupContent, RoutePopupContentProps } from './RoutePopupContent';

export type MapWidgetPopupProps = { x: number; y: number; onClose?: () => void } & (
  | { type: 'entity'; childProps: EntityPopupContentProps }
  | { type: 'cluster'; childProps: ClusterPopupContentProps }
  | { type: 'route'; childProps: RoutePopupContentProps }
);

export const MapWidgetPopup: React.FC<MapWidgetPopupProps> = ({ x, y, onClose, type, childProps }) => {
  const renderChild = () => {
    switch (type) {
      case 'cluster':
        return <ClusterPopupContent {...childProps} />;

      case 'entity':
        return <EntityPopupContent {...childProps} />;

      case 'route':
        return <RoutePopupContent {...childProps} />;

      default:
        return null;
    }
  };

  const target = useMemo(() => new DOMRect(x, y, 10, 10), [x, y]);

  return (
    <DetachedPopover isOpen target={target} hasArrow usePortal={false} onClose={onClose}>
      {renderChild()}
    </DetachedPopover>
  );
};
