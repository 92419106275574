import { InfoGrid, InfoGridProps } from '@components/layout/InfoGrid/InfoGrid';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import React, { useMemo } from 'react';
import { ENTITY_INFO_ITEMS } from '@constants/entity';
import { filterTruthy } from '@utils/misc';
import { getEntityProperty } from '@utils/entity';
import Link from '@components/common/Link';
import ROUTES from '@infrastructure/routes';
import { EntityStatusDot } from '@components/pages/EntityPage/components/EntityStatusDot';
import c from 'classnames';
import CopyText from '@components/typography/CopyText';
import { EntityLink } from '@components/pages/EntityPage/components/EntityLink';

import styles from './EntityInfoGrid.module.scss';

export type EntityInfoGridProps = Omit<InfoGridProps, 'data'> & {
  entity?: EntityDetails;
  parentEntity?: EntityDetails;
  nameAsHeader?: boolean;
};

export const EntityInfoGrid: React.FC<EntityInfoGridProps> = ({
  entity,
  parentEntity,
  nameAsHeader = false,
  size,
  ...props
}) => {
  const data = useMemo(
    () =>
      entity
        ? filterTruthy([
            !nameAsHeader && {
              label: 'Name',
              value: <EntityLink entity={entity} parentEntity={parentEntity} variant="copy-6" additionalClass="" />,
            },
            entity.location?.longitude && entity.location?.latitude
              ? {
                  label: 'Location',
                  value: [entity.location?.latitude, entity.location?.longitude].join(', '),
                }
              : null,
            ...Object.keys(ENTITY_INFO_ITEMS).reduce((cols, key) => {
              const value = getEntityProperty(entity, key);
              return value === undefined
                ? cols
                : [
                    ...cols,
                    {
                      label: ENTITY_INFO_ITEMS[key],
                      value,
                    },
                  ];
            }, [] as InfoGridProps['data']),
          ])
        : [],
    [entity, nameAsHeader, parentEntity]
  );

  const description = entity ? getEntityProperty(entity, 'description') : '';

  return entity ? (
    <div
      className={c(styles.entityInfoGrid, {
        [styles.medium]: size === 'm',
        [styles.small]: size === 's',
        [styles.extraSmall]: size === 'xs',
      })}
    >
      {nameAsHeader && (
        <div className={styles.header}>
          <EntityStatusDot entity={entity} size={size} additionalClass="-ml-4 mr-4" />
          <Link additionalClass={styles.name} href={ROUTES.entityById.replace(':entityId', entity.id)}>
            {entity.name}
          </Link>
          {description && <CopyText variant="copy-8">{description}</CopyText>}
        </div>
      )}
      {data.length && (
        <InfoGrid
          data={data}
          {...props}
          size={size}
          additionalClass={c(styles.grid, nameAsHeader && styles.hasHeader, props.additionalClass)}
        />
      )}
    </div>
  ) : null;
};
