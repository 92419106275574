import { StripeColor } from '@utils/issue';
import React from 'react';
import { EntityIcons } from '../../icons/entities';
import c from 'classnames';
import Icon, { IconNames } from '@components/common/Icon';
import { titleCase } from '@utils/string';

import styles from './EntityMarker.module.scss';
import alertStyles from '@components/connected/IssueManager/components/AlertBar/Alert.module.scss';

export type EntityMarkerProps = {
  color?: StripeColor;
  type?: EntityIcons;
};

export const EntityMarker: React.FC<EntityMarkerProps> = ({ color = 'gray', type = 'default-device' }) => {
  return (
    <div className={c(styles.entityMarker, alertStyles[color], alertStyles[`${color}-bg`])}>
      <Icon name={titleCase(type) as IconNames} size="xs" additionalClass={styles.icon} />
    </div>
  );
};
