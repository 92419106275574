import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { SelectControl } from '@components/common/form/Select';
import { BaseSelectItem } from '@components/common/form/Select/BaseSelect';
import useTextSnippets from '@services/useTextSnippets';
import { DataSource, MapDataSource } from '@redux/widgetPage';
import { SubLevel } from '@components/layout/SubLevel';
import { DataSourcePickerProps } from '@components/pages/WidgetPage/types';
import { useRouteAlertsQuery } from '@infrastructure/api/BaseNClient/useAlertsQuery';
import { TagInputControl } from '@components/common/form/TagInput/TagInputControl';
import { TagType } from '@components/common/form/TagInput/TagInput';
import { useEntityCustomersQuery } from '@infrastructure/api/BaseNClient/useEntityCustomersQuery';
import { EntityCustomer } from '@redux/inventoryPage';
import { CustomFilters } from './CustomFilters';
import HorizontalTitleSeparator from '@components/separators/HorizontalTitleSeparator/HorizontalTitleSeparator';
import { debounce } from 'lodash';

const keyToOption = (key: string) => ({ label: key, value: key });
const customerToOption = ({ id, name }: EntityCustomer) => ({ label: name, value: `${id}` });

export type MapDataSourcePickerProps = DataSourcePickerProps & {
  requireCustomer?: boolean;
};

export const DataSourcePicker: FC<MapDataSourcePickerProps> = ({
  source,
  onChange,
  onNameChange,
  requireCustomer = true,
}) => {
  const i18n = useTextSnippets('widgetPage');
  const sourceRef = useRef<DataSource>(source ?? {});
  const routeAlerts = useRouteAlertsQuery();
  const customers = useEntityCustomersQuery();

  const mapDataSource = source as MapDataSource;

  const routeAlertOptions = useMemo(
    () => Object.keys(routeAlerts?.data?.alert_names ?? {}).map(keyToOption),
    [routeAlerts?.data?.alert_names]
  );
  const selectedRouteAlert = mapDataSource?.routeAlert
    ? { label: mapDataSource.routeAlert, value: mapDataSource.routeAlert }
    : undefined;

  const routeChannelOptions = useMemo(
    () =>
      mapDataSource?.routeAlert
        ? routeAlerts?.data?.alert_names?.[mapDataSource.routeAlert]?.map(keyToOption) ?? []
        : [],
    [mapDataSource?.routeAlert, routeAlerts?.data?.alert_names]
  );
  const selectedRouteChannel = mapDataSource?.routeChannel
    ? { label: mapDataSource.routeChannel, value: mapDataSource.routeChannel }
    : undefined;

  const customerOptions = useMemo(() => customers.data?.data?.map(customerToOption) ?? [], [customers.data]);
  const selectedCustomers = customerOptions.filter(({ value }) => mapDataSource?.customerIds?.includes(value));

  useEffect(() => {
    sourceRef.current = source ?? {};
  }, [source]);

  const handleRouteAlertChange = useCallback(
    (option?: BaseSelectItem | null) => {
      onChange?.(
        option
          ? {
              routeAlert: `${option.value}`,
              routeChannel: undefined,
            }
          : null
      );
    },
    [onChange]
  );

  const handleRouteChannelChange = useCallback(
    (option?: BaseSelectItem | null) => {
      onChange?.(
        option
          ? {
              ...sourceRef.current,
              routeChannel: `${option.value}`,
            }
          : null
      );
    },
    [onChange]
  );

  const handleCustomerChange = useCallback(
    (options?: TagType[] | null) => {
      onChange?.(
        options
          ? {
              ...sourceRef.current,
              customerIds: options.map(({ value }) => `${value}`),
            }
          : null
      );
      onNameChange?.(options ? options.map(({ label }) => label).join(', ') : null);
    },
    [onChange, onNameChange]
  );

  const handleDataFilterChange = useMemo(
    () =>
      debounce((dataFilter: Record<string, any>) => {
        onChange?.({
          ...sourceRef.current,
          dataFilter,
        });
      }, 500),
    [onChange]
  );

  return (
    <>
      <TagInputControl
        label={i18n.customer}
        placeholder={i18n.selectCustomers}
        onChange={handleCustomerChange}
        options={customerOptions}
        value={selectedCustomers}
        fullWidth
        required={requireCustomer}
      />

      <CustomFilters keyValues={mapDataSource?.dataFilter} onChange={handleDataFilterChange} />

      <HorizontalTitleSeparator title={i18n.alertInfo} variant="copy-4" additionalClass="mt-8 mb-16" />

      <SelectControl
        label={i18n.routeAlert}
        placeholder={i18n.selectRouteAlert}
        options={routeAlertOptions}
        loading={routeAlerts.isLoading}
        onChange={handleRouteAlertChange}
        value={selectedRouteAlert}
        filterable
        clearable
        fullWidth
        // TODO: enable when https://jira.basen.net/browse/BN-8897 is resolved
        disabled
      />

      {mapDataSource?.routeAlert && (
        <SubLevel additionalClass="-mt-16">
          <SelectControl
            label={i18n.routeChannel}
            placeholder={i18n.selectRouteChannel}
            options={routeChannelOptions}
            onChange={handleRouteChannelChange}
            value={selectedRouteChannel}
            filterable
            clearable
            fullWidth
          />
        </SubLevel>
      )}
    </>
  );
};
