import React from 'react';
import c from 'classnames';
import styles from './style.module.scss';

export type CopyTextType =
  | 'copy-1'
  | 'copy-2'
  | 'copy-3'
  | 'copy-4'
  | 'copy-5'
  | 'copy-6'
  | 'copy-7'
  | 'copy-8'
  | 'copy-9';

type CopyTextProps = {
  centered?: boolean;
  ellipsis?: boolean;
  onClick?: (...args: any) => any;
  color?: 'blue' | 'dark' | 'blue-gray' | 'ocean' | 'white' | 'green' | 'red' | 'inherit';
  variant?: CopyTextType;
  additionalClass?: string;
  testid?: string;
};

const CopyText: React.FC<CopyTextProps> = ({
  children,
  centered = false,
  ellipsis = false,
  color = 'inherit',
  variant = 'copy-2',
  onClick = () => {},
  additionalClass = undefined,
  testid,
}) => (
  <p
    data-testid={testid}
    aria-hidden="true"
    onClick={onClick}
    className={c(
      variant,
      { 'text-center block': centered },
      { 'text-dark': color === 'dark' },
      { 'text-blue-n': color === 'blue' },
      { 'text-blue-gray': color === 'blue-gray' },
      { 'text-blue-ocean': color === 'ocean' },
      { 'text-white': color === 'white' },
      { 'text-red-error': color === 'red' },
      { 'text-green-success': color === 'green' },
      { [styles.ellipsis]: ellipsis },
      additionalClass
    )}
  >
    {children}
  </p>
);

export default CopyText;
