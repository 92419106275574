import { ToggleButtonGroup, ToggleButton } from '@components/common/form/ToggleButton';
import React, { useState, useCallback, useRef, useMemo } from 'react';
import { Box } from '@components/common/Box';
import { Form, SubmitButton } from '@components/common/form';
import modeComponents from './components/modes';
import {
  DEFAULT_CRON_STRING,
  valuesToCronExpression,
  INITIAL_VALUES,
  MODES,
  Schema,
  validationSchema,
  cronExpressionToValues,
} from './utils';
import { DateRange } from '@blueprintjs/datetime';
import { FormikHelpers, FormikProps } from 'formik';
import c from 'classnames';
import { Button } from '@components/common/Button';
import { CronString } from './components/CronString';

import styles from './CronGenerator.module.scss';

export type CronGeneratorProps = {
  activeIdx?: number;
  onSubmit?: (schedule: string | DateRange) => void;
  // event is passed along for compatibility with SwitchBase only, which is wrapped around ScheduleItems
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  showCustomTab?: boolean;
  enableRecurring?: boolean;
  additionalClass?: string;
  value?: string | DateRange;
};

export const CronGenerator: React.FC<CronGeneratorProps> = ({
  activeIdx: defaultActiveIdx = 0,
  onSubmit,
  onCancel,
  showCustomTab = true,
  enableRecurring = true,
  additionalClass,
  value,
}) => {
  const [schedule, setSchedule] = useState<string | DateRange>(value ?? ([null, null] as DateRange));

  const isOnce = typeof schedule !== 'string' || !enableRecurring;
  const isEditing = !!value;

  const initialValues = useMemo(
    () =>
      isOnce
        ? { ...INITIAL_VALUES, modeIdx: 0, startTime: schedule[0] as Date, endTime: schedule[1] as Date }
        : cronExpressionToValues(schedule as string),
    [isOnce, schedule]
  );

  const [activeIdx, setActiveIdx] = useState(isOnce ? 0 : defaultActiveIdx);

  const formRef = useRef<FormikProps<Schema>>(null);

  const Component = modeComponents[activeIdx];

  const handleStateChange = useCallback(
    (newStates: boolean[]) => {
      const newActiveIdx = newStates.findIndex(state => state);
      const value = newActiveIdx > -1 ? newActiveIdx : defaultActiveIdx;
      formRef?.current?.resetForm({ values: initialValues });
      formRef?.current?.setFieldValue('modeIdx', value);
      setActiveIdx(value);
      setSchedule(DEFAULT_CRON_STRING);
    },
    [defaultActiveIdx, initialValues]
  );

  const handleChange = useCallback(
    (values: Schema) => {
      const newSchedule = isOnce
        ? ([values.startTime, values.endTime] as DateRange)
        : valuesToCronExpression<Schema>(values);
      setSchedule(newSchedule);
      return newSchedule;
    },
    [isOnce]
  );

  const handleSubmit = useCallback(
    (values: Schema, { setSubmitting }: FormikHelpers<Schema>) => {
      const newSchedule = handleChange(values);
      onSubmit?.(newSchedule);
      setSubmitting(false);
    },
    [handleChange, onSubmit]
  );

  return (
    <Form<Schema>
      innerRef={formRef}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onChange={handleChange}
      onSubmit={handleSubmit}
      additionalClass={additionalClass}
      enableReinitialize
    >
      <Box header={isEditing ? 'Edit Schedule' : 'Add schedule'} additionalClass={styles.cronGenerator}>
        {enableRecurring && (
          <div className={styles.header}>
            <ToggleButtonGroup onStateChange={handleStateChange} exclusive additionalClass={styles.toggleButtonGroup}>
              {Object.keys(MODES)
                .filter((mode: string) => showCustomTab || mode !== 'Custom')
                .map((mode: string, idx: number) => (
                  <ToggleButton key={idx} active={activeIdx === idx}>
                    {mode}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </div>
        )}

        <div className={styles.body}>
          <Component />
        </div>

        <div className={styles.footer}>
          {schedule && <CronString schedule={schedule} verbose additionalClass="pr-24" truncate={false} />}
          {onCancel && (
            <Button variant="stealth" additionalClass="ml-auto" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <SubmitButton
            variant="fillBlueDark"
            additionalClass={c(onCancel ? 'ml-8' : 'ml-auto')}
            // TODO: disableOnClean should be true, but dirty flag is not set correctly
            disableOnClean={false}
          >
            {isEditing ? 'Save' : 'Add'}
          </SubmitButton>
        </div>
      </Box>
    </Form>
  );
};
