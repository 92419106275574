import { createSelector } from 'reselect';
import { AppState } from '@infrastructure/redux/store';
import { identity, withoutUndefined } from '@utils/misc';
import { MWListPageState } from './types';
import { Filters, PaginationParams, SortingParams } from '../types';
import { MaintenanceWindowsQueryRequest } from '@infrastructure/api/BaseNClient/useMaintenanceWindowsQuery';

export const MW_TABLE_ID = `table-settings-mw`;

export const selectSliceRoot = (state: AppState): MWListPageState => state.mw;

export const selectItems = createSelector(selectSliceRoot, _ => _.items);
export const selectItemsOrder = createSelector(selectSliceRoot, _ => _.itemsOrder);
export const selectAreLoaded = createSelector(selectSliceRoot, _ => _.areLoaded);

export const selectItemsAsArray = createSelector(selectItems, selectItemsOrder, (items, order) =>
  order.map(id => items[id])
);

// the following might seem exessive, but in reality it's the way to memoize ¯\_(ツ)_/¯
export const selectFilters = createSelector(selectSliceRoot, _ => _.filters);
export const selectResolvedFilters = createSelector(selectFilters, identity);

export const selectPagination = createSelector(selectSliceRoot, _ => _.pagination);
export const selectResolvedPagination = createSelector(selectPagination, identity);

export const selectSorting = createSelector(selectSliceRoot, _ => _.sort);
export const selectResolvedSorting = createSelector(selectSorting, identity);

export const selectQueryParams = createSelector(
  selectResolvedFilters,
  selectResolvedPagination,
  selectResolvedSorting,
  (filters: Filters, pagination: PaginationParams, sorting?: SortingParams) => {
    const params: MaintenanceWindowsQueryRequest = {};

    if (filters.generalFilter) {
      params.general = filters.generalFilter;
    }

    // if (!isEmpty(filters.discreteFilters)) {
    //   Object.keys(filters.discreteFilters).forEach((filterKey: any) => {
    //     params[`data.${filterKey}`] = isArray(filters.discreteFilters[filterKey])
    //       ? filters.discreteFilters[filterKey].join(',')
    //       : filters.discreteFilters[filterKey];
    //   });
    // }

    params.start_index = (pagination.page - 1) * pagination.rowsPerPage;
    params.end_index = (pagination.page - 1) * pagination.rowsPerPage + pagination.rowsPerPage;

    if (sorting?.sort && sorting?.sortBy) {
      params.order = `${sorting.sort === 'desc' ? '-' : ''}${sorting.sortBy}`;
    }

    return withoutUndefined(params, true);
  }
);
