import { Entity } from '@infrastructure/api/BaseNClient/useEntitiesQuery';
import { useField } from 'formik';
import { EntitiesControl, EntitiesControlProps } from './EntitiesControl';
import React, { useRef } from 'react';
import { InfiniteListItem } from '../InfiniteSelect/InfiniteList';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';

const itemToId = (item?: Entity): string => item?.id ?? '';

export type EntitiesFieldProps = Omit<EntitiesControlProps, 'value' | 'onChange'> & {
  name: string;
};

export function EntitiesField({ name, size, ...props }: EntitiesFieldProps) {
  const [field, { error, touched }, helpers] = useField({ ...props, name });

  const currentValueRef = useRef<InfiniteListItem<Entity> | null | null>(null);

  const query = useEntityQuery(
    {
      entityId: field.value,
    },
    {
      enabled: !!field.value && typeof field.value === 'string' && !currentValueRef.current,
    }
  );

  const handleChange = (item: InfiniteListItem<Entity> | null | null) => {
    currentValueRef.current = item;
    helpers.setValue(itemToId(item?.value));
  };

  React.useEffect(() => {
    if (query.data) {
      currentValueRef.current = {
        label: query.data.name,
        value: query.data,
      };
    }
  }, [query.data]);

  return (
    <EntitiesControl
      {...props}
      {...field}
      value={currentValueRef.current ?? field.value}
      onChange={handleChange}
      errorText={touched ? error : undefined}
      loading={query.isLoading}
    />
  );
}
