import React from 'react';
import { Box } from '@components/common/Box';
import Icon from '@components/common/Icon';
import CopyText from '@components/typography/CopyText';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import Accordion from '@components/common/Accordion/Accordion';
import { AlertDetailsPanel } from './AlertDetailsPanel';
import { AlertChannel, AlertConfig, useAlertConfigsQuery } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';
import { ChartDataSource } from '@infrastructure/redux/widgetPage';
import { OverridePopup, OverridePopupProps } from './OverridePopups/OverridePopup';

import styles from './AlertsAccordion.module.scss';

type AlertsAccordionProps = {
  source: ChartDataSource;
};

export const AlertsAccordion: React.FC<AlertsAccordionProps> = ({ source }) => {
  const [overridePopupProps, setOverridePopupProps] = React.useState<OverridePopupProps | null>(null);

  const { entityId, metricId, page, alertId } = source;

  const alertsQuery = useAlertConfigsQuery({
    ids: alertId,
    entity_id: entityId,
    metric_id: metricId,
    pages: page,
    silent: true,
    events: true,
    details: true,
    overrides: true,
  });

  const [activeItemIdx, setActiveItemIdx] = React.useState(-1);

  const handleChange = React.useCallback((e: any, idx: number) => {
    setActiveItemIdx(oldIdx => (oldIdx === idx ? -1 : idx));
  }, []);

  const handleOverride = React.useCallback((alert: AlertConfig, channel?: AlertChannel) => {
    setOverridePopupProps({
      isOpen: true,
      alert,
      channel,
    });
  }, []);

  return !entityId && !alertId && !page ? null : (
    <>
      <Box
        header={
          <>
            <Icon name="AlertOn" size="s" additionalClass="mr-4" />
            <CopyText variant="copy-3">Alerts</CopyText>
          </>
        }
        additionalClass={styles.alertsAccordion}
      >
        {alertsQuery.isLoading ? (
          <LoadingSpinner />
        ) : !alertsQuery.data?.alerts.length ? (
          'No alerts'
        ) : (
          <Accordion onChange={handleChange} exclusive>
            {alertsQuery.data.alerts.map((alert, idx) => (
              <AlertDetailsPanel
                key={alert.id}
                alert={alert}
                entityId={entityId}
                active={idx === activeItemIdx}
                onOverride={handleOverride}
              />
            ))}
          </Accordion>
        )}
      </Box>
      {!!overridePopupProps && <OverridePopup {...overridePopupProps} onClose={() => setOverridePopupProps(null)} />}
    </>
  );
};
