import React from 'react';
import { IssueView } from '@redux/issues';
import CopyText from '@components/typography/CopyText';

type Props = {
  view: IssueView;
};

const IssueViewDescription: React.FC<Props> = ({ view }) => {
  if (!view.description) {
    return null;

    // return (
    // <CopyText variant="copy-3" additionalClass="mb-4">
    //   {issueViewManagerI18n.description}: <span className="text-gray-4 copy-4">{issueViewManagerI18n.noData}</span>
    // </CopyText>
    // );
  }

  return (
    <CopyText variant="copy-3" additionalClass="mb-4">
      <span className="copy-4">{view.description}</span>
    </CopyText>
  );
};

export default IssueViewDescription;
