import { Menu, MenuItem } from '@components/common/Menu';
import { DetachedPopover } from '@components/common/Popover/DetachedPopover';
import { CypressTestsData, CypressTestType } from '@cypress';
import { selectToken } from '@infrastructure/redux/auth';
import { useAppSelector } from '@infrastructure/redux/store';
import { downloadFile } from '@utils/file';
import { replaceQuery } from '@utils/url';
import React, { FC } from 'react';
import { DEFAULT_DOWNLOAD_FORMATS } from '../../layout/TablePageLayout/constants';

type Extension = string;
type Mime = string;

export type DownloadPopupProps = {
  url: string;
  target: HTMLElement;
  fileName?: string;
  formats?: Record<Extension, Mime>;
  isOpen?: boolean;
  onClose?: () => void;
};

export const DownloadPopup: FC<DownloadPopupProps> = ({
  target,
  url,
  fileName = 'download',
  formats = DEFAULT_DOWNLOAD_FORMATS,
  isOpen,
  onClose,
}) => {
  const token = useAppSelector(selectToken);
  const issueManagerTestDataConfig = CypressTestsData[CypressTestType.ISSUE_MANAGER];

  const handleDownload = (format: string) => {
    const fullUrl = replaceQuery({ format, access_token: token }, url);

    downloadFile(fullUrl.toString(), [fileName, format].join('.'));
  };

  return (
    <DetachedPopover onClose={onClose} target={target} isOpen={isOpen}>
      <Menu key="downloadMenu" className="p-0 min-w-90 shadow-1">
        {Object.keys(formats).map(format => (
          <MenuItem
            key={format}
            tagName="span"
            text={format.toUpperCase()}
            onClick={() => handleDownload(format)}
            testid={`${format.toUpperCase()}${
              issueManagerTestDataConfig.testIds.downloadIssues.formatSelectionButtonsSuffix
            }`}
          />
        ))}
      </Menu>
    </DetachedPopover>
  );
};
