import { Box } from '@components/common/Box';
import { CodeEditor } from '@components/common/CodeEditor/CodeEditor';
import { WizardStepProps } from '@components/common/form/Wizard/Wizard';
import z from 'zod';
import CopyText from '@components/typography/CopyText';
import React from 'react';
import { Text } from '@codemirror/state';
import { useWizardContext, useWizardHandle } from '@components/common/form/Wizard';
import { ScriptTemplates } from './ScriptTemplates';
import { ToggleInput } from '@components/common/form/ToggleInput/ToggleInput';
import { clone, zipObject } from 'lodash';
import { ScriptDetails } from '@infrastructure/api/BaseNClient/useScriptByIdQuery';
import { useQueryClient } from 'react-query';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';

import styles from './PickScriptStep.module.scss';

export const PickScriptStep: React.FC<WizardStepProps> = ({ onSubmit, onChange, initialValues, innerRef }) => {
  const valuesRef = React.useRef(initialValues);
  const { valuesRef: allValuesRef } = useWizardContext();
  const queryClient = useQueryClient();

  useWizardHandle(innerRef, onSubmit);

  const handleScriptChange = React.useCallback(
    (value: Text) => {
      if (value.toString() !== valuesRef.current.script) {
        valuesRef.current.script = value.toString();
        onChange?.(clone(valuesRef.current));
      }
    },
    [onChange]
  );

  const handleScriptSelect = React.useCallback(
    (item: ScriptDetails) => {
      valuesRef.current = item;
      allValuesRef.current.targets.entities = item.entities;
      allValuesRef.current.variables = item.variables?.length
        ? zipObject(
            item.variables,
            item.variables.map(() => '')
          )
        : {};
      onChange?.(clone(valuesRef.current));
    },
    [allValuesRef, onChange]
  );

  const handleNameChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      valuesRef.current.name = e.target.value;
      onChange?.(clone(valuesRef.current));
    },
    [onChange]
  );

  React.useEffect(() => {
    if (typeof valuesRef.current.id && !valuesRef.current.script) {
      const queryParams = { id: valuesRef.current.id };

      queryClient
        .fetchQuery([ENDPOINTS.scriptById, queryParams], () =>
          apiClient.get<ScriptDetails>(ENDPOINTS.scriptById, queryParams)
        )
        .then(handleScriptSelect);
    }
  }, [onChange, queryClient, handleScriptSelect]);

  return (
    <div className="w-full h-full gap-16 hbox">
      <ScriptTemplates onSelect={handleScriptSelect} value={valuesRef.current?.id} />

      <div className={styles.pickScriptStep}>
        <Box
          header={
            <div className="w-full hbox">
              <ToggleInput
                enforceFocusOnInvalid
                initialToggle={false}
                size="m"
                name="breadcrumb-input"
                value={initialValues.name || 'Script name'}
                additionalClass="w-full"
                onChange={handleNameChange}
                onKeyDown={e => e.key === 'Enter' && e.currentTarget.blur()}
              />
              <CopyText variant="copy-4" additionalClass="text-blue-gray ml-auto">
                Write a script or pick from templates
              </CopyText>
            </div>
          }
          additionalClass={styles.box}
          noPadding
        >
          <CodeEditor
            value={initialValues.script ?? ''}
            onChange={handleScriptChange}
            additionalClass={styles.codeEditor}
          />
        </Box>
      </div>
    </div>
  );
};

PickScriptStep.defaultProps = {
  initialValues: {
    id: '',
    name: '',
    script: '',
  },
  validationSchema: z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    script: z.string().min(1, 'Script is required'),
  }),
};
