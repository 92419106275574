import React, { useState, useEffect, useRef, MouseEvent } from 'react';
import { Form, TextField, ToggleField, SubmitButton, FormSubmitOptions, FormikProps } from '@components/common/form';
import Button from '@components/common/Button';
import CopyText from '@components/typography/CopyText';
import Headline from '@components/typography/Headline';
import BaseNLogo from '@components/common/BaseNLogo';
import useTextSnippets from '@services/useTextSnippets';
import ROUTES from '@infrastructure/routes';
import Notification from '@components/common/Notification';
import { getUserRememberMe } from '@utils/token';
import { useNavigate } from 'react-router-dom';
import getSchema, { LoginSchema } from './LoginForm.schema';
import noop from '@utils/noop';
import { toQueryStr } from '@utils/url';

import styles from './style.module.scss';

export type LoginFormProps = {
  submitError?: boolean;
  clearError?: () => void;
  showForgotPassword?: boolean;
  initialValues?: LoginSchema;
  onSubmit: (values: LoginSchema, helpers: FormSubmitOptions<LoginSchema>) => void | Promise<any>;
};

function LoginForm({
  showForgotPassword,
  clearError = noop,
  submitError,
  initialValues = { username: '', password: '' },
  onSubmit,
}: LoginFormProps) {
  const loginSnippets = useTextSnippets('login');
  const validationSchema = getSchema(loginSnippets);
  const { welcomeBack, forgotPassword, aboutBaseN, rememberMe, loginFail } = loginSnippets;
  const { trademarks } = useTextSnippets('copyRight');
  const loginFormRef = useRef<FormikProps<LoginSchema>>(null);
  const [usernameSet, setUsernameSet] = useState<boolean>(false);
  const navigate = useNavigate();

  const openAboutPage = () => {
    window.open('https://www.basen.net/about/', '_blank');
  };

  const getFormUserValue = (): string =>
    (loginFormRef && loginFormRef.current && loginFormRef.current.values.username) || '';

  useEffect(() => {
    const savedUsername = getUserRememberMe().username;

    if (savedUsername && !usernameSet && loginFormRef && loginFormRef.current) {
      setUsernameSet(true);
      loginFormRef.current.setFieldValue('username', savedUsername);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usernameSet, loginFormRef.current]);

  const onGoToForgotPassword = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    navigate({
      pathname: ROUTES.forgotPassword,
      search: `?${toQueryStr({ email: getFormUserValue() })}`,
    });
  };

  return (
    <div className={styles.loginForm}>
      <div className="vbox">
        <BaseNLogo additionalClass={styles.loginLogo} />
      </div>
      {submitError && (
        <div className={styles.errorContainer}>
          <Notification type="error" title={loginFail} onClose={clearError} />
        </div>
      )}
      <Form<LoginSchema>
        innerRef={loginFormRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        additionalClass={styles.form}
      >
        <Headline additionalClass="mb-16 text-black" variant="headline-6">
          {welcomeBack}
        </Headline>

        <TextField size="l" label="Username" name="username" autoComplete="username" />

        <TextField size="l" label="Password" type="password" name="password" autoComplete="current-password" animate />

        <ToggleField type="checkbox" label={rememberMe} name="remember" additionalClass="mb-20" />

        <SubmitButton
          size="l"
          variant="fillBlueDark"
          fullWidth
          additionalClass={!showForgotPassword ? 'mb-40' : undefined}
        >
          Login
        </SubmitButton>

        {showForgotPassword && (
          <Button
            ariaLabel="Forgot Password Link"
            variant="link"
            onClick={onGoToForgotPassword}
            additionalClass={styles.forgotPasswordLink}
          >
            {forgotPassword}
          </Button>
        )}
        <div className="vbox">
          <Button variant="fillOutline" additionalClass="mb-16" onClick={openAboutPage} size="s">
            {aboutBaseN}
          </Button>
          <CopyText variant="copy-6" additionalClass={styles.trademarks}>
            {trademarks}
          </CopyText>
        </div>
      </Form>
    </div>
  );
}

export default LoginForm;
