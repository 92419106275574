import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { RawDashboard } from '@redux/dashboards/types';

export function getDashboardById(dashboardId: string): Promise<RawDashboard> {
  return apiClient.get<RawDashboard>(`${ENDPOINTS.dashboardById}`, { dashboardId, widget_data: true });
}

export const DashboardsQueryKey = 'dashboards';
export const DashboardQueryKey = 'dashboard';
