import { createCustomSlice, createDataTableReducers, createInitialDataTableState } from '@utils/redux';
import { ScriptsPageState } from './types';
import { Script } from '@infrastructure/api/BaseNClient/useScriptsQuery';
import { SCRIPTS_TABLE_ID } from './selectors';

const initialState: ScriptsPageState = createInitialDataTableState(SCRIPTS_TABLE_ID);

export const createConfigsSlice = (actionPrefix?: string) =>
  createCustomSlice({
    name: 'scriptsPage',
    initialState,
    reducers: createDataTableReducers<Script>(initialState),
    actionPrefix,
  });

const configsSlice = createConfigsSlice();

export const {
  updateItems,
  updateItem,
  updatePagination,
  updateSearch,
  updateSorting,
  updateFilters,
  updateRowHeight,
  reset,
} = configsSlice.actions;

export default configsSlice;
