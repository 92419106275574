import React, { useCallback, useState, useRef } from 'react';
import c from 'classnames';
import { apiClient } from '@infrastructure/api';
import Icon from '@components/common/Icon';
import useTextSnippets from '@services/useTextSnippets';
import IconButton from '@components/common/IconButton';
import CopyText from '@components/typography/CopyText';
import { useQuery } from 'react-query';
import logger from '@infrastructure/logging/logger';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { useDebouncedValue } from '@services';
import ActiveSuggestions from './components/ActiveSuggestions';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { KEYCODES } from '@constants/keyboardShortcutsConfig';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { useHotkeys } from 'react-hotkeys-hook';
import { SearchHit } from '@infrastructure/api/BaseNClient/useGlobalSearch';

import styles from './style.module.scss';

type SearchBarProps = {
  disabled?: boolean;
  hideInteraction?: boolean;
  noIcon?: boolean;
  onResults?: (params: { data: SearchHit[]; searchTerm: string }) => void;
};

export const SearchBar: React.FC<SearchBarProps> = ({
  noIcon = false,
  hideInteraction = false,
  disabled = false,
  onResults,
}) => {
  const headerI18n = useTextSnippets('header');
  const [searchTerm, setSearchTerm] = useState('');
  const [isActive, setActive] = useState(false);
  const debouncedSearchTerm = useDebouncedValue<string>(searchTerm, 500);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { getFailureHandler } = useGenericMutationHandlers();

  useHotkeys(KEYCODES.FOCUS_SEARCH, (e: KeyboardEvent) => {
    if (inputRef.current) {
      inputRef.current.focus();
      e.stopPropagation();
      e.preventDefault();
    }
  });

  const onSuggestionClick = useCallback((suggestion: SearchHit) => {
    setSearchTerm(suggestion.name);
    setActive(false);
  }, []);

  const {
    data: suggestions,
    isFetching: isSearching,
    isFetched,
  } = useQuery<{ data: SearchHit[]; result?: 'ok' }, Error>(
    ['search', debouncedSearchTerm],
    () => apiClient.get<{ data: SearchHit[]; result?: 'ok' }>(ENDPOINTS.search, { searchTerm: debouncedSearchTerm }),
    {
      enabled: !!debouncedSearchTerm && debouncedSearchTerm.length > 2,
      onSuccess: ({ data }) => {
        onResults?.({ data, searchTerm: debouncedSearchTerm });
      },
      onError: getFailureHandler((error: Error) => {
        logger.error(error, { origin: 'searchSuggestQuery', debouncedSearchTerm });
      }),
    }
  );

  const noHitsFound = suggestions && suggestions.data.length === 0 && suggestions.result === 'ok' && isFetched;
  const hasSuggestions = suggestions && suggestions.data.length > 0;
  const activeSuggestions = hasSuggestions && isActive;

  return (
    <div className={c(styles.container, searchTerm && styles.hasValue, disabled && styles.disabled)}>
      <div className={styles.inputContainer}>
        {!noIcon && <IconButton ariaLabel="Search" icon="Search" additionalClass={styles.searchIcon} tabIndex={-1} />}
        <input
          ref={inputRef}
          disabled={disabled}
          className={c(styles.searchInput, noIcon && styles.noIconInput)}
          type="text"
          name="search"
          placeholder={headerI18n.searchPlaceholder}
          onChange={event => setSearchTerm(event.target.value)}
          value={searchTerm}
          onFocus={() => setActive(true)}
          onBlur={event => {
            if (event.relatedTarget?.className.includes('suggestion')) {
              return;
            }

            setActive(false);
          }}
        />
        {hideInteraction && isActive && isSearching && <LoadingSpinner size="m" additionalClass="mr-10" />}
      </div>
      {/* it's currently loading and the input is focused */}
      {isActive && isSearching && !hideInteraction ? (
        <div className={c(styles.suggestions, isSearching && styles.searching)}>
          <Icon name="Loading" additionalClass={styles.spinner} />
        </div>
      ) : null}
      {/* it's currently not loading and the input is focused and there's no suggestions to show */}
      {isActive && !isSearching && !hideInteraction && noHitsFound ? (
        <div className={styles.suggestions}>
          <div className={c(styles.suggestionsContainer, 'flex items-center justify-center min-h-150')}>
            <CopyText variant="copy-2" additionalClass="text-gray-4">
              {headerI18n.noResults}
            </CopyText>
          </div>
        </div>
      ) : null}
      {/* it's currently not loading and the input is focused and there are suggestions to show */}
      {activeSuggestions && !isSearching && !hideInteraction && (
        <ActiveSuggestions searchTerm={searchTerm} onSuggestionClick={onSuggestionClick} suggestions={suggestions} />
      )}
    </div>
  );
};
