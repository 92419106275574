import React from 'react';
import md5 from 'md5';
import c from 'classnames';
import chroma from 'chroma-js';

import styles from './Gravatar.module.scss';

type Rating = 'g' | 'pg';

/**
 * 404: do not load any image if none is associated with the email hash, instead return an HTTP 404 (File Not Found) response
 * mp: (mystery-person) a simple, cartoon-style silhouetted outline of a person (does not vary by email hash)
 * identicon: a geometric pattern based on an email hash
 * monsterid: a generated 'monster' with different colors, faces, etc
 * wavatar: generated faces with differing features and backgrounds
 * retro: awesome generated, 8-bit arcade-style pixelated faces
 * robohash: a generated robot with different colors, faces, etc
 * blank: a transparent PNG image
 * */
type DefaultStyle = '404' | 'mp' | 'identicon' | 'monsterid' | 'wavatar' | 'retro' | 'robohash' | 'blank';

export type Props = {
  additionalClass?: string;
  email?: string | null;
  username?: string | null;
  size?: number;
  rating?: Rating;
  defaultStyle?: DefaultStyle;
};

export const Gravatar: React.FC<Props> = ({ additionalClass, email, username, size = 64, rating = 'g', ...props }) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  const defaultStyle =
    props.defaultStyle ??
    `https%3A%2F%2Fui-avatars.com%2Fapi%2F${email ?? username ?? 'AN'}%2F${size * 2}%2F265B99%2FFFF%2F2`;
  const formattedEmail = email?.trim().toLowerCase() || '';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const hash = md5(formattedEmail, { encoding: 'binary' });
  const src = `//www.gravatar.com/avatar/${hash}?s=${size}&r=${rating}&d=${defaultStyle}`;
  const initials = (username || email || 'AN').substring(0, 2).toUpperCase();
  const halfSize = size / 2;
  const backgroundColor = chroma.random();
  const textColor = backgroundColor.luminance() > 0.5 ? 'black' : 'white';

  React.useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setIsImageLoaded(true);
    img.onerror = () => setIsImageLoaded(false);
  }, [src]);

  return (
    <div
      className={c(styles.icon, additionalClass)}
      style={{
        width: halfSize,
        height: halfSize,
        maxWidth: halfSize,
        minWidth: halfSize,
        backgroundColor: backgroundColor.hex(),
        color: textColor,
      }}
    >
      {isImageLoaded ? (
        <img alt="Author" src={src} className={styles.contents} />
      ) : (
        <div className={styles.contents}>{initials}</div>
      )}
    </div>
  );
};
