import { Form, SubmitButton, TextField } from '@components/common/form';
import {
  AuthFormSchema,
  authFormSchema,
  initialAuthValues,
} from '@components/pages/ScriptRunPage/components/steps/ReviewStep.schema';
import CopyText from '@components/typography/CopyText';
import { FormikHelpers } from 'formik';

import styles from './LoginForm.module.scss';

type LoginFormProps = {
  onSubmit?: (newValues: AuthFormSchema, formikHelpers: FormikHelpers<AuthFormSchema>) => void | Promise<any>;
};

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
  return (
    <Form
      validationSchema={authFormSchema}
      initialValues={initialAuthValues}
      enableReinitialize
      additionalClass={styles.loginForm}
      onSubmit={onSubmit}
    >
      <CopyText variant="copy-1" additionalClass="text-center">
        Login
      </CopyText>

      <div className={styles.fields}>
        <TextField size="l" label="Username" name="username" autoComplete="username" />

        <TextField size="l" label="Password" type="password" name="password" autoComplete="current-password" animate />

        <SubmitButton size="l" variant="fillBlueDark" fullWidth>
          Start session
        </SubmitButton>
      </div>
    </Form>
  );
};
