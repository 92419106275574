import { useCallback } from 'react';
import { FieldsConfig, extractValues } from '@components/common/form/FormBuilder';
import { TableToolbar, TableToolbarProps } from '.';
import { CurrentFiltersBar } from './TableFilters/CurrentFiltersBar';
import { FilterableToolbarSearch } from './FilterableToolbarSearch';
import c from 'classnames';

import styles from './FilterableToolbar.module.scss';

export type FilterableToolbarProps<T extends object = object> = TableToolbarProps<T> & {
  isLoading?: boolean;
  showFiltersToggle?: boolean;
  filters: FieldsConfig;
  keyword?: string;
  placeHolder?: string;
  leftAlign?: boolean;
  onSearch?: (keyword?: string) => void;
  onFiltersChange?: (values: Dictionary<any>) => void;
  onFiltersViewToggle?: () => void;
  additionalClass?: string;
};

export function FilterableToolbar<T extends object = object>({
  isLoading = false,
  showFiltersToggle = true,
  keyword,
  filters,
  placeHolder,
  leftAlign = true,
  onSearch,
  onFiltersChange,
  onFiltersViewToggle,
  children,
  ...toolbarProps
}: FilterableToolbarProps<T>) {
  const handleClearFilters = useCallback(
    (newFilterConfig: FieldsConfig) => {
      onFiltersChange?.(extractValues(newFilterConfig));
    },
    [onFiltersChange]
  );

  return (
    <>
      <TableToolbar<T>
        {...toolbarProps}
        additionalClass={c(styles.filterableToolbar, toolbarProps.additionalClass, leftAlign && styles.leftAlign)}
      >
        {!!onSearch && (
          <FilterableToolbarSearch
            isLoading={isLoading}
            additionalClass="toolbarSearch"
            showFiltersToggle={showFiltersToggle}
            filters={filters}
            placeHolder={placeHolder}
            keyword={keyword}
            onSearch={onSearch}
            onFiltersChange={onFiltersChange}
            onFiltersViewToggle={onFiltersViewToggle}
          />
        )}
        {children}
      </TableToolbar>
      {filters && <CurrentFiltersBar filters={filters} onClearFilters={handleClearFilters} />}
    </>
  );
}
