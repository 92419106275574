import CopyText from '@components/typography/CopyText';
import { DateRange } from '@blueprintjs/datetime';
import React from 'react';
import Icon from '@components/common/Icon';
import { toHumanReadable } from '@utils/mw';
import c from 'classnames';

export type CronStringProps = {
  schedule: string | DateRange;
  verbose?: boolean;
  additionalClass?: string;
  truncate?: boolean;
};

export const CronString: React.FC<CronStringProps> = ({
  schedule,
  verbose = false,
  truncate = true,
  additionalClass,
}) => {
  // @ts-ignore
  const isOnce: boolean = typeof schedule !== 'string';
  return (
    <div className={c(additionalClass, 'flex flex-row items-center min-w-0')}>
      <Icon name={`${isOnce ? 'SingleMw' : 'RecurrentMw'}`} size="s" additionalClass="mr-8 text-blue-ocean" />
      <CopyText variant="copy-4" additionalClass={c(truncate && 'truncate')}>
        {toHumanReadable(schedule, verbose)}
      </CopyText>
    </div>
  );
};
