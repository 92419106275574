/* eslint-disable */
import { GenericNotOkApiResponse } from '@infrastructure/api/types';
import { AxiosError } from 'axios';
import { isEmpty, isNull, isObject, isUndefined, mergeWith } from 'lodash';

const arrayConcatRule = (objValue: any, srcValue: any) => {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

const arrayReplaceRule = (objValue: any, srcValue: any) => {
  if (Array.isArray(objValue)) {
    return srcValue;
  }
};

const dontIgnoreUndefined = (objValue: any, srcValue: any) => {
  if (isUndefined(srcValue)) {
    return null;
  }
  return arrayReplaceRule(objValue, srcValue);
};

export const mergeAndConcatArrays = (obj: any, ...src: any[]): any => mergeWith(obj, ...src, arrayConcatRule);
export const mergeAndReplaceArrays = (obj: any, ...src: any[]): any => mergeWith(obj, ...src, arrayReplaceRule);
export const mergeWithUndefined = (obj: any, ...src: any[]): any => mergeWith(obj, ...src, dontIgnoreUndefined);

/**
 * Merging obj with src objects with the following condition: property of obj will be set to null if corresponding
 * property in src is either empty string, undefined, null, empty array or an empty object.
 *
 * @param obj
 * @param src
 * @returns A merged object
 */
export const mergeNoEmpty = (obj: any, ...src: any[]): any => {
  const noEmpty = (objValue: any, srcValue: any) => {
    return srcValue === '' || isUndefined(srcValue) || (isObject(srcValue) && isEmpty(srcValue)) ? null : srcValue;
  };

  return mergeWith(obj, ...src, noEmpty);
};

export const strToOption = (str: string): { label: string; value: string } => ({ label: str, value: str });

export const enumToOptions = (obj: Record<string, string>) =>
  Object.keys(obj).map((key: string) => ({ label: key, value: obj[key] }));

export const arrToOptions = (arr: string[] | readonly string[]) => arr.map(strToOption);

export const requestErrorToString = (error: AxiosError<GenericNotOkApiResponse> | null): string | null => {
  // currently this utility processes only AxiosErrors
  if (!error?.isAxiosError || !error?.response) {
    return null;
  }

  let message = '';
  if (error.response?.data?.code && error.response?.data?.message) {
    message += `${error.response?.data?.code}: ${error.response?.data?.detail ?? error.response?.data?.message}`;
  }

  return message || null;
};

export type ItemWithId = { id: string | number; [key: string]: any };

/**
 * Takes an array of ItemWithId-like items and converts it to a hashmap of id to item.
 *
 * @param arr
 * @returns Hashmap of items by id
 */
export const toIdMap = <T extends Record<string, any>>(arr?: T[], idProperty: string = 'id'): Record<string, T> =>
  arr?.reduce((obj, item) => ({ ...obj, [item[idProperty]]: item }), {}) ?? {};

export const isFalsy = (obj: any, undefinedOnly = true) =>
  undefinedOnly
    ? isUndefined(obj)
    : isUndefined(obj) ||
      isNull(obj) ||
      obj === '' ||
      (isObject(obj) && isEmpty(obj)) ||
      (Array.isArray(obj) && !obj.length);

export const withoutUndefined = (obj: any, withoutEmpty = false) => {
  if (!obj || Array.isArray(obj) || typeof obj !== 'object') {
    return obj;
  }

  return Object.keys(obj).reduce((cleanedObj, key: string) => {
    if (isFalsy(obj[key], !withoutEmpty)) {
      return cleanedObj;
    } else {
      cleanedObj[key] = obj[key];
      return cleanedObj;
    }
  }, {} as any);
};

export const filterTruthy = (arr: any[]) => arr?.filter(Boolean) ?? [];

export const toArray = (obj: any) => (obj && !Array.isArray(obj) ? [obj] : obj);

export const identity = (obj: any) => obj;

export const onOffEvents = (eventEmitter: any, events: Record<string, Function>, on: boolean = true) => {
  if (eventEmitter && isObject(events) && !isEmpty(events)) {
    Object.keys(events).forEach(eventName => {
      if (eventName.startsWith('on')) {
        const channel = eventName.slice(2).toLowerCase();
        eventEmitter[on ? 'on' : 'off'](channel, (events as any)[eventName]);
      }
    });
  }
};

type ScriptAttributes = {
  type?: string;
  async?: boolean;
  defer?: boolean;
  integrity?: string;
  crossorigin?: string;
  charset?: string;
  nonce?: string;
  [key: string]: any;
};

export async function loadScript(
  url: string,
  attributes: ScriptAttributes = {},
  callbackName?: string
): Promise<HTMLScriptElement> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;

    for (const [key, value] of Object.entries(attributes)) {
      script.setAttribute(key, value.toString());
    }

    script.async = true;

    if (callbackName) {
      (window as any)[callbackName] = resolve;
    } else {
      script.onload = () => {
        resolve(script);
      };
    }

    script.onerror = () => {
      reject(new Error(`Failed to load the script at: ${url}`));
    };

    document.head.appendChild(script);
  });
}
