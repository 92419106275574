import tagImage from '@assets/images/map/tag.png';
import { ImageSource } from '@components/common/Map/ImageLoader';

const misc: Record<string, ImageSource> = {
  tag: {
    width: 27,
    height: 16,
    src: tagImage as string,
    options: {
      content: [8, 2, 19, 10],
      stretchX: [[8, 19]],
      stretchY: [[2, 10]],
    },
  },
};

export default misc;
