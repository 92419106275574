import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { MaintenanceTable, MaintenanceTableProps } from './MaintenanceTable';
import { createMWSlice } from '@infrastructure/redux/mwListPage';
import { createPortableStore } from '@utils/redux';

let componentCounter = 0;

export type PortableMaintenanceTableProps = Omit<MaintenanceTableProps, 'actions'> & {
  actionsPrefix?: string;
  entityId?: string;
};

export const PortableMaintenanceTable: React.FC<PortableMaintenanceTableProps> = ({
  actionsPrefix = 'portableMaintenanceWindows',
  ...props
}) => {
  const { store, actions } = useMemo(
    () => createPortableStore(createMWSlice(`${actionsPrefix}-${++componentCounter}`)),
    [actionsPrefix]
  );

  return (
    // NOTE: overriding global store with local one to redirect all selectors and dispatcher
    <Provider store={store}>
      <MaintenanceTable actions={actions} {...props} />
    </Provider>
  );
};
