import React from 'react';
import c from 'classnames';
import styles from './style.module.scss';

type Props = {
  text: string;
  error?: boolean;
};

const FieldInfo: React.FC<Props> = ({ text, error }) => {
  return <div className={c(styles.info, error && styles.error)}>{text}</div>;
};

export default FieldInfo;
