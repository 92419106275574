import Link from '@components/common/Link';
import ROUTES from '@infrastructure/routes';
import CopyText, { CopyTextType } from '@components/typography/CopyText';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import Icon from '@components/common/Icon';
import c from 'classnames';

import styles from './EntityLink.module.scss';

type IdAndName = {
  id: string;
  name: string;
};

const SingleEntityLink = ({
  entity,
  variant = 'copy-4',
  additionalClass,
}: {
  entity: EntityDetails | IdAndName;
  variant?: CopyTextType;
  additionalClass?: string;
}) => {
  return (
    <Link
      href={`${ROUTES.entityById.replace(':entityId', entity.id)}`}
      testid={`Entity::${entity.name}`}
      additionalClass={c(styles.link, additionalClass)}
    >
      <CopyText variant={variant} additionalClass="truncate">
        {entity.name}
      </CopyText>
    </Link>
  );
};

export type EntityLinkProps = {
  entity: EntityDetails | IdAndName;
  parentEntity?: EntityDetails | IdAndName;
  variant?: CopyTextType;
  additionalClass?: string;
};

export const EntityLink = ({ entity, parentEntity, variant, additionalClass }: EntityLinkProps) => {
  const items = [
    <SingleEntityLink entity={entity} key={entity.id} variant={variant} additionalClass={additionalClass} />,
  ];
  if (parentEntity) {
    items.unshift(<Icon key="ChevronRight" name="ChevronRight" additionalClass="mx-4" size="xs" />);
    items.unshift(
      <SingleEntityLink
        entity={parentEntity}
        key={parentEntity.id}
        variant={variant}
        additionalClass={additionalClass}
      />
    );
  }
  return <div className={styles.linkWrapper}>{items}</div>;
};
