import React, { FC } from 'react';
import { startCase } from 'lodash';
import { PalettePreset, presetToPalette } from './paletteUtils';
import { ColorPalette, ColorPaletteProps } from './ColorPalette';

export type PaletteProps = Omit<ColorPaletteProps, 'stopColors' | 'title'> & {
  preset: PalettePreset;
  steps?: number;
};

export const Palette: FC<PaletteProps> = ({ preset = 'BaseN', steps, showTitle, ...props }) => {
  const { type, colors } = presetToPalette(preset);

  return (
    <ColorPalette
      {...props}
      stopColors={colors as string[]}
      steps={type === 'categorical' ? colors.length : steps}
      title={showTitle ? `${startCase(preset)} (${type})` : undefined}
    />
  );
};
