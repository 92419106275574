import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import { GenericOkApiResponse } from '@infrastructure/api/types';
import { AppDispatch, useAppDispatch } from '@infrastructure/redux/store';
import ROUTES from '@infrastructure/routes';
import { setNotification } from '@redux/notifications';
import useTextSnippets from '@services/useTextSnippets';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { onLoginSuccess } from './useLoginMutation';
import { useNavigate } from 'react-router-dom';

export function onNewPasswordSuccess(
  dispatch: AppDispatch,
  data: NewPasswordApiResponse,
  variables: ChangePasswordParams,
  context?: any
): void;
export function onNewPasswordSuccess(
  dispatch: AppDispatch,
  data: NewPasswordApiResponse,
  variables: ChangePasswordParams
) {
  onLoginSuccess(dispatch, data.token, { remember: true, username: variables.email, password: variables.password });
}

export type NewPasswordApiResponse = GenericOkApiResponse & { token: string };
export type NewPasswordHandlerSettings = { noNotification?: boolean; noRedirect?: boolean; noDefaultSuccess: boolean };
export type ChangePasswordParams = { password: string; email: string };

export const useNewPasswordHandler = <
  T extends NewPasswordApiResponse = NewPasswordApiResponse,
  P extends ChangePasswordParams = ChangePasswordParams
>(
  { noDefaultSuccess = false, noRedirect = false, noNotification = false }: NewPasswordHandlerSettings = {
    noRedirect: false,
    noDefaultSuccess: false,
    noNotification: false,
  },
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> => {
  const dispatch = useAppDispatch();
  const texts = useTextSnippets('forgotPassword');
  const navigate = useNavigate();

  return useMutation<T, Error, P>(
    (changePasswordParams): Promise<T> => apiClient.post<T, P>(ENDPOINTS.newPassword, changePasswordParams),
    {
      ...mutationOptions,
      onSuccess: (...successParams) => {
        if (!noDefaultSuccess) {
          onNewPasswordSuccess(dispatch, ...successParams);
        }

        void mutationOptions.onSuccess?.(...successParams);

        if (!noRedirect) {
          navigate(ROUTES.home);
        }

        if (!noNotification) {
          dispatch(
            setNotification({
              type: 'success',
              title: texts.passwordUpdatedTitle,
              description: texts.passwordUpdatedMessage,
            })
          );
        }
      },
    }
  );
};
