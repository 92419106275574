import { DateTimeField } from '@components/common/form/DatePicker/DateTimeField';
import { useFormikContext } from 'formik';
import { Schema } from '../../utils';
import moment from 'moment';

export const OnceMode = () => {
  const { values } = useFormikContext<Schema>();
  const minDate = moment().subtract(2, 'year').toDate();

  return (
    <div className="flex flex-row items-center gap-8 mb-16">
      <DateTimeField
        label="From"
        name="startTime"
        useAmPm={false}
        minDate={minDate}
        popoverProps={{ position: 'right' }}
      />
      <DateTimeField
        label="To"
        name="endTime"
        useAmPm={false}
        disabled={!values.startTime}
        minDate={values.startTime ? new Date(values.startTime) : undefined}
        popoverProps={{ position: 'left' }}
      />
    </div>
  );
};
