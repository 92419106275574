import Control, { ControlProps } from '@components/common/form/Control/Control';
import React from 'react';

import { InfiniteSelect, InfiniteSelectProps } from './InfiniteSelect';

export type InfiniteSelectControlProps<TValue = any> = Omit<ControlProps, 'onChange' | 'value' | 'defaultValue'> &
  InfiniteSelectProps<TValue>;

export function InfiniteSelectControl<TValue = any>({
  placeholder,
  getItemsQuery,
  itemToLabel,
  ...props
}: InfiniteSelectControlProps<TValue>) {
  return (
    <Control {...props}>
      <InfiniteSelect<TValue> placeholder={placeholder} getItemsQuery={getItemsQuery} itemToLabel={itemToLabel} />
    </Control>
  );
}
