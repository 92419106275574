import React, { useState, useMemo, useRef, MouseEvent, useCallback } from 'react';
import useTextSnippets from '@services/useTextSnippets';

import useForm, { FormProvider } from '@components/common/form/useForm';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import Headline from '@components/typography/Headline';
import CopyText from '@components/typography/CopyText';
import ROUTES from '@infrastructure/routes';
import getSchema, { AccountSchema } from './Account.schema';
import getNewPasswordSchema, { NewPasswordSchema } from './NewPassword.schema';
import { setNotification } from '@redux/notifications';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import styles from './AccountManagement.module.scss';
import { useApiClient } from '@infrastructure/api/useApiClient';
import { GenericOkApiResponse } from '@infrastructure/api/types';
import BasePage from '../BasePage';
import { AccountUpdateData, updateAccount, useAccount } from '@redux/auth';
import Gravatar from '@components/common/Gravatar';
import { Badge } from '@components/common/Badge';
import HorizontalSeparator from '@components/separators/HorizontalSeparator';
import VerticalSeparator from '@components/separators/VerticalSeparator';
import { Form, FormikProps, FormSubmitOptions, SelectField, TextField } from '@components/common/form';
import Button from '@components/common/Button';
import { DefaultMotion } from '@services';
import { ChangePasswordParams, NewPasswordApiResponse } from '@infrastructure/api/BaseNClient/useNewPasswordHandler';
import PasswordComplexityBar from '@components/pages/ForgotPassword/ForgotPasswordForm/PasswordComplexityBar';
import { selectFeatureFlags } from '@redux/settings';
import { LOCALE_OPTIONS, TIMEZONE_OPTIONS } from '@utils/date';
import { useNavigate } from 'react-router-dom';
import { toQueryStr } from '@utils/url';
import { useQueryClient } from 'react-query';
import ENDPOINTS from '@infrastructure/api/endpoints';

const AccountManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const forgotPasswordSnippets = useTextSnippets('forgotPassword');
  const { allowPasswordRecovery } = useAppSelector(selectFeatureFlags);
  const { currentPasswordLabel, newPasswordLabel, repeatNewPasswordLabel } = forgotPasswordSnippets;
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const accountI18n = useTextSnippets('account');
  const newPasswordValidationSchema = getNewPasswordSchema(forgotPasswordSnippets);
  const navigate = useNavigate();
  const account = useAccount();
  const queryClient = useQueryClient();

  const updateAccountMutation = useApiClient<GenericOkApiResponse, AccountUpdateData>('updateAccount');

  const newPasswordMutation = useApiClient<NewPasswordApiResponse, ChangePasswordParams>('newPassword', {
    noRedirect: true,
    noDefaultSuccess: false,
    noNotification: true,
  });

  const handleNewPasswordSubmit = (values: NewPasswordSchema, helpers: FormSubmitOptions<NewPasswordSchema>) => {
    newPasswordMutation.mutate(
      { password: values.newPassword!, email: account!.email! },
      {
        onError: () => {
          helpers.setSubmitting(false);
          dispatch(setNotification({ type: 'error', description: accountI18n.passwordUpdateFail }));
        },
        onSuccess: () => {
          helpers.setSubmitting(false);
          setShowForgotPassword(false);
          dispatch(setNotification({ type: 'success', description: accountI18n.passwordUpdateSuccess }));
        },
      }
    );
  };

  const newPasswordFormRef = useRef<FormikProps<NewPasswordSchema>>(null);

  const accountForm = useForm<AccountSchema>({
    validationSchema: getSchema(accountI18n),
    initialValues: {
      id: account?.id?.toString() || '',
      firstname: account?.firstname || '',
      lastname: account?.lastname || '',
      email: account?.email || '',
      timezone: account?.timezone || '',
      phone: account?.phone || '',
      locale:
        account?.locale && LOCALE_OPTIONS.find(locale => locale.value === account?.locale) ? account?.locale : 'en_GB',
    },
    enableReinitialize: true,
    onSubmit: (values: AccountSchema) => {
      const config = {
        onSuccess: (_: GenericOkApiResponse, data: AccountUpdateData) => {
          dispatch(setNotification({ type: 'success', description: accountI18n.updateSuccess }));
          dispatch(updateAccount(data));
          queryClient.invalidateQueries([ENDPOINTS.me]);
        },
        onError: () => {
          dispatch(setNotification({ type: 'error', title: accountI18n.updateFail }));
        },
      };

      updateAccountMutation.mutate(values as AccountUpdateData, config);
    },
  });

  const getFormEmailValue = useCallback((): string => account?.email || '', [account?.email]);

  const onGoToForgotPassword = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    navigate({
      pathname: ROUTES.forgotPassword,
      search: `?${toQueryStr({ email: getFormEmailValue() })}`,
    });
  };

  const lastUpdatedText = useMemo(() => {
    // lastUpdateLessThanADayAgo
    // lastUpdateDayAgo
    // lastUpdateDaysAgo

    return accountI18n.lastUpdateLessThanADayAgo;
  }, [accountI18n.lastUpdateLessThanADayAgo]);

  if (account) {
    return (
      <BasePage
        level={2}
        subPages={[{ title: accountI18n.account, path: ROUTES.account }]}
        pageControls={[
          {
            id: 'cancelChanges',
            onClick: () => accountForm.resetForm(),
            ariaLabel: accountI18n.discard,
            icon: 'Close',
            showLabelOnMobile: false,
            hideIconOnDesktop: true,
            label: accountI18n.discard,
            disabled: !accountForm.dirty,
          },
          {
            id: 'saveAccount',
            onClick: () => accountForm.submitForm(),
            ariaLabel: accountI18n.save,
            icon: 'Save',
            variant: 'fillBlueDark',
            loading: updateAccountMutation.isLoading,
            showLabelOnMobile: false,
            hideIconOnDesktop: true,
            label: accountI18n.save,
            disabled: !accountForm.dirty,
          },
        ]}
      >
        <FormProvider<AccountSchema> form={accountForm} additionalClass={styles.accountForm}>
          <Headline variant="headline-5" color="dark">
            {accountI18n.account}
          </Headline>
          <div className={styles.userCard}>
            <div className="flex items-center w-full">
              <Gravatar
                size={80}
                username={account?.name ?? account?.username}
                email={account?.email}
                additionalClass="mr-16"
              />
              <div className="flex flex-col">
                <CopyText variant="copy-1" color="dark">
                  {account?.username}
                </CopyText>
                {account?.email && (
                  <CopyText variant="copy-6" color="dark">
                    {account.email}
                  </CopyText>
                )}
              </div>
              {account?.isAdmin && (
                <div className="flex ml-auto">
                  <Badge outlined size="m" variant="info">
                    {accountI18n.admin}
                  </Badge>
                </div>
              )}
            </div>
            <HorizontalSeparator gutter={0} additionalClass={styles.accountSeparator} noSpacing />
            <div className="flex w-full">
              <div className="flex items-center h-32">
                <CopyText variant="copy-7" additionalClass="mr-4">
                  {accountI18n.realm}
                </CopyText>
                <CopyText variant="copy-8">{account?.realm}</CopyText>
                <VerticalSeparator additionalClass={styles.accountCardVerticalSeparator} />
                <CopyText variant="copy-7" additionalClass="mr-4">
                  {accountI18n.groups}
                </CopyText>
                <CopyText variant="copy-8">{account?.groups?.join(', ')}</CopyText>
              </div>
            </div>
          </div>
          <Headline variant="headline-6" additionalClass="mb-8" color="dark">
            {accountI18n.userSettings}
          </Headline>
          <CopyText variant="copy-4" additionalClass="mb-16">
            {accountI18n.userSettingsDescription}
          </CopyText>
          <TextField
            size="l"
            label={accountI18n.firstname}
            type="text"
            name="firstname"
            placeholder={accountI18n.firstnamePlaceholder}
            autoComplete="off"
          />
          <TextField
            size="l"
            label={accountI18n.lastname}
            type="text"
            name="lastname"
            placeholder={accountI18n.lastnamePlaceholder}
            autoComplete="off"
          />
          <TextField
            size="l"
            label={accountI18n.email}
            type="text"
            name="email"
            placeholder={accountI18n.emailPlaceholder}
            autoComplete="off"
          />
          <TextField
            size="l"
            label="Phone"
            type="text"
            name="phone"
            placeholder="Phone is optional"
            autoComplete="off"
          />
          <HorizontalSeparator gutter={0} additionalClass={styles.accountSectionSeparator} noSpacing />
          <Headline variant="headline-6" additionalClass="mb-16" color="dark">
            {accountI18n.timePreferences}
          </Headline>
          <SelectField
            popoverProps={{
              additionalClass: styles.timezoneSelect,
              usePortal: false,
              full: true,
              preserveTarget: true,
            }}
            filterable
            variant="outlined"
            fullWidth
            label={accountI18n.timezone}
            clearable={false}
            additionalClasses={{ body: styles.timezoneSelectBody }}
            options={TIMEZONE_OPTIONS}
            name="timezone"
            placeholder={accountI18n.timezone}
            size="l"
          />
          <SelectField
            popoverProps={{
              additionalClass: styles.timezoneSelect,
              usePortal: false,
              full: true,
              preserveTarget: true,
            }}
            label={accountI18n.locale}
            filterable
            variant="outlined"
            fullWidth
            clearable={false}
            additionalClasses={{ body: styles.timezoneSelectBody }}
            options={LOCALE_OPTIONS}
            name="locale"
            placeholder={accountI18n.locale}
            size="l"
          />
          <HorizontalSeparator gutter={0} additionalClass={styles.accountSectionSeparator} noSpacing />
          <div className="flex flex-col">
            <div className="flex items-center">
              <div className="flex flex-col">
                <Headline variant="headline-6" additionalClass="mb-16" color="dark">
                  {accountI18n.password}
                </Headline>
                {lastUpdatedText && (
                  <CopyText variant="copy-4" additionalClass="text-gray-3">
                    {lastUpdatedText}
                  </CopyText>
                )}
              </div>
              <Button
                onClick={() => {
                  if (showForgotPassword) {
                    setShowForgotPassword(false);
                  } else {
                    setShowForgotPassword(true);
                  }
                }}
                size="m"
                variant="outline"
                additionalClass="ml-auto"
              >
                {showForgotPassword ? accountI18n.cancel : accountI18n.updatePassword}
              </Button>
            </div>
            <DefaultMotion key={showForgotPassword ? 'show' : 'hide'} className="mt-16">
              {showForgotPassword && (
                <Form
                  innerRef={newPasswordFormRef}
                  initialValues={{
                    currentPassword: '',
                    newPassword: '',
                    repeatNewPassword: '',
                  }}
                  validationSchema={newPasswordValidationSchema}
                  onSubmit={handleNewPasswordSubmit}
                  additionalClass={styles.form}
                >
                  <TextField
                    animate
                    size="l"
                    label={currentPasswordLabel}
                    type="password"
                    name="currentPassword"
                    autoComplete="new-password"
                  />
                  {allowPasswordRecovery && (
                    <Button
                      ariaLabel={forgotPasswordSnippets.forgotPassword}
                      variant="link"
                      onClick={onGoToForgotPassword}
                      additionalClass="mb-24"
                    >
                      {forgotPasswordSnippets.forgotPassword}
                    </Button>
                  )}
                  <TextField
                    animate
                    size="l"
                    label={newPasswordLabel}
                    type="password"
                    noInfo
                    name="newPassword"
                    autoComplete="new-password"
                  />
                  <PasswordComplexityBar />
                  <TextField
                    animate
                    size="l"
                    label={repeatNewPasswordLabel}
                    type="password"
                    name="repeatNewPassword"
                    autoComplete="new-password"
                  />
                  <Button
                    size="l"
                    variant="fillBlueDark"
                    fullWidth
                    onClick={() => newPasswordFormRef.current?.submitForm()}
                  >
                    {accountI18n.updatePassword}
                  </Button>
                </Form>
              )}
              {!showForgotPassword && <div />}
            </DefaultMotion>
          </div>
          <HorizontalSeparator gutter={0} additionalClass={styles.accountSectionSeparator} noSpacing />
        </FormProvider>
      </BasePage>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center flex-1">
      <LoadingSpinner centered />
    </div>
  );
};

export default AccountManagement;
