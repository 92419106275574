import { useIssueViewsQuery } from '@infrastructure/api/BaseNClient/useIssueViewsQuery';
import { issueViewsLoaded } from '@infrastructure/redux/issues';
import { useAppDispatch } from '@infrastructure/redux/store';
import { useLoadingContext } from 'react-router-loading';
import React, { useEffect } from 'react';
import IssueViewManager from './IssueViewManager';

export const IssueViewManagerPage = () => {
  const loadingContext = useLoadingContext();

  const dispatch = useAppDispatch();
  const query = useIssueViewsQuery();

  useEffect(() => {
    if (query.data?.data?.length) {
      dispatch(issueViewsLoaded(query.data.data));
      loadingContext.done();
    }
  }, [query.data, dispatch, loadingContext]);

  return !query.isLoading ? <IssueViewManager /> : null;
};
