import useTextSnippets from '@services/useTextSnippets';
import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import withCommands, { WithCommands } from '@services/withCommands';
import React, { FC, useState } from 'react';

import styles from './DashboardManagerHeader.module.scss';

const NoDashboardElements: FC<WithCommands> = ({ dashboardCreateCommand }) => {
  const i18n = useTextSnippets('dashboards');

  const [isLoading, setIsloading] = useState(false);

  const handleCreateNewDashboard = async () => {
    setIsloading(true);
    await dashboardCreateCommand();
    setIsloading(false);
  };

  return (
    <NoElementsContainer
      icon="DashboardPc"
      iconClass={styles.dashboardPcIcon}
      title={i18n.noDashboards}
      description={i18n.dashboardManager}
      buttonProps={{
        loading: isLoading,
        label: i18n.newDashboard,
        onClick: handleCreateNewDashboard,
      }}
    />
  );
};

export default withCommands(NoDashboardElements);
