import Icon, { IconNames } from '@components/common/Icon';
import { ToggleButton, ToggleButtonGroup } from '@components/common/form/ToggleButton';
import CopyText, { CopyTextType } from '@components/typography/CopyText';
import React, { useMemo } from 'react';
import c from 'classnames';
import { RowHeight } from '@components/common/Table';
import { ButtonProps } from '@components/common/Button';

import styles from './RowHeightPicker.module.scss';

export type RowHeightPickerProps = {
  label?: string;
  inline?: boolean;
  disabled?: boolean;
  size?: ButtonProps['size'];
  onChange?: (rowHeight: RowHeight) => void;
  value?: RowHeight;
  labelVariant?: CopyTextType;
  additionalClass?: string;
};

type RowHeightVariant = {
  icon: IconNames;
  variant: RowHeight;
};

export const RowHeightPicker: React.FC<RowHeightPickerProps> = ({
  label,
  inline = false,
  disabled = false,
  labelVariant = 'copy-4',
  size = 'm',
  onChange,
  value = 'small',
  additionalClass,
}) => {
  const rowHeights = useMemo(
    () =>
      [
        {
          variant: 'small',
          icon: 'Row32Px',
        },
        {
          variant: 'medium',
          icon: 'Row40Px',
        },
        {
          variant: 'large',
          icon: 'Row48Px',
        },
      ] as RowHeightVariant[],
    []
  );

  const activeIdx = rowHeights.findIndex(({ variant }) => variant === value);

  const handleChange = (_: any, idx: number) => {
    onChange?.(rowHeights[idx].variant);
  };

  return (
    <div className={c(styles.rowHeightPicker, inline && styles.inline, additionalClass)}>
      {label && <CopyText variant={labelVariant}>{label}</CopyText>}

      <ToggleButtonGroup size={size} variant="icon" disabled={disabled} onChange={handleChange}>
        {rowHeights.map(({ icon }, idx) => (
          <ToggleButton key={`${idx}`} active={idx === activeIdx}>
            <Icon name={icon} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};
