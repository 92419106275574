import { useCreateDashboardMutation } from '@infrastructure/api/BaseNClient';
import {
  createReset,
  DashboardCreateSuccessResponse,
  DashboardsQueryKey,
  EMPTY_DASHBOARD,
  NewDashboard,
} from '@redux/dashboards';
import ROUTES from '@infrastructure/routes';
import { useAppDispatch } from '@infrastructure/redux/store';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

export const useDashboardCreateCommand = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();
  const createDashboardMutation = useCreateDashboardMutation();
  const navigate = useNavigate();

  return (dashboard: NewDashboard = EMPTY_DASHBOARD): Promise<DashboardCreateSuccessResponse> => {
    return createDashboardMutation.mutateAsync(dashboard, {
      onSuccess: getSuccessHandler((db: DashboardCreateSuccessResponse) => {
        navigate(`${ROUTES.dashboardById.replace(':dashboardId', db.id)}`);
        queryClient.removeQueries(DashboardsQueryKey);
        dispatch(createReset());
      }),
      onError: getFailureHandler(),
    });
  };
};
