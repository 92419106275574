import React, { FC } from 'react';
import { useField } from 'formik';
import Checkbox from '../Checkbox/Checkbox';
import { ToggleSwitch, ToggleSwitchProps } from '.';

export type ToggleFieldProps = ToggleSwitchProps & {
  name: string;
  type?: 'checkbox' | 'switch';
};

export const ToggleField: FC<ToggleFieldProps> = ({ type = 'switch', ...props }) => {
  // type should be explicitly passed into useField() for it to start controlling 'checked' prop
  const [field] = useField({ name: props.name, type: 'checkbox', checked: !!props.checked });
  const Component = type === 'switch' ? ToggleSwitch : Checkbox;

  return <Component {...props} {...field} />;
};
