import React, { useCallback } from 'react';
import { useLoadingContext } from 'react-router-loading';
import PageHeader from '@components/layout/PageHeader';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import { MaintenanceTable } from '@components/connected/MaintenanceTable/MaintenanceTable';
import mwListPageSlice from '@infrastructure/redux/mwListPage/slice';
import { lowerCase, startCase } from 'lodash';

export const MWListPage = () => {
  const loadingContext = useLoadingContext();

  const navigate = useNavigate();

  React.useEffect(() => {
    document.title = `Maintenance Windows - BaseN Platform`;
  }, []);

  const handleCreateNew = useCallback(() => {
    navigate(ROUTES.createMaintenanceWindow);
  }, [navigate]);

  // TODO add this back in some other form
  // if (query.isError) {
  //   return (
  //     <ErrorPageContainer
  //       headline="Error retrieving Maintenance Windows"
  //       text={query.error?.message}
  //       withLogo
  //       additionalClass={styles.page}
  //     />
  //   );
  // }

  return (
    <div className="page-container">
      <PageHeader
        level={2}
        breadcrumbs
        breadcrumbsProps={{
          omitRootLabel: true,
          transformLabel: (label) => {
            return startCase(lowerCase(label))
          }
        }}
        pageControls={[
          {
            id: 'newMW',
            onClick: handleCreateNew,
            ariaLabel: 'Create New',
            icon: 'Add',
            label: 'Create New',
          },
        ]}
      />

      <div className="page-body">
        <MaintenanceTable
          onInitialLoadComplete={() => loadingContext.done()}
          actions={mwListPageSlice.actions}
          showUpcoming
        />
      </div>
    </div>
  );
};
