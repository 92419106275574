import { WizardStepProps, useWizardHandle } from '@components/common/form/Wizard';
import { useWizardContext } from '@components/common/form/Wizard/WizardContext';
import { MWSummary } from '@components/pages/MWSinglePage/components/MWSummary';
import { formValuesToMW } from '@utils/mw';
import { useCallback } from 'react';

export const ReviewStep: React.FC<WizardStepProps> = ({ innerRef, onChange, onSubmit }) => {
  const { valuesRef } = useWizardContext();

  useWizardHandle(innerRef, onSubmit);

  const handleScheduleRemove = useCallback(
    (idx: number) => {
      valuesRef.current.schedules.times.splice(idx, 1);
      onChange?.({});
    },
    [onChange, valuesRef]
  );

  const { general, schedules, targets } = valuesRef.current;

  return !general || !schedules || !targets ? null : (
    <MWSummary mw={formValuesToMW(valuesRef.current)} onScheduleRemove={handleScheduleRemove} />
  );
};
