import { UseQueryOptions } from 'react-query';
import { useValidatedQuery } from '@services/useValidatedQuery';
import QUERY_CONFIGS from '../query-configs';
import ENDPOINTS from '../endpoints';
import { ConfigParams, configParamsSchema } from './useDeviceConfigParams';

export const useDeviceConfigParamsByIdQuery = <T extends ConfigParams = ConfigParams>(
  entityId: string,
  queryOptions: UseQueryOptions<T, Error> = {}
) => {
  return useValidatedQuery<T, Error>(ENDPOINTS.deviceConfigParamsById, { entityId }, configParamsSchema, {
    ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
    ...queryOptions,
  });
};
