import { AppState } from '@infrastructure/redux/store';
import { identity } from '@utils/misc';
import { createSelector } from 'reselect';
import { InventoryPageState } from './types';
import { generateParams } from './utils';

export const INVENTORY_TABLE_ID = `table-settings-inventory`;

export const selectSliceRoot = (state: AppState): InventoryPageState => state.inventoryPage;

export const selectItems = createSelector(selectSliceRoot, _ => _.items);
export const selectItemsOrder = createSelector(selectSliceRoot, _ => _.itemsOrder);

export const selectItemsAsArray = createSelector(selectItems, selectItemsOrder, (items, order) =>
  order.map(id => items[id])
);

// the following might seem exessive, but in reality it's the way to memoize ¯\_(ツ)_/¯
export const selectFilters = createSelector(selectSliceRoot, _ => _.filters);
export const selectResolvedFilters = createSelector(selectFilters, identity);

export const selectPagination = createSelector(selectSliceRoot, _ => _.pagination);
export const selectResolvedPagination = createSelector(selectPagination, identity);

export const selectSorting = createSelector(selectSliceRoot, _ => _.sort);
export const selectResolvedSorting = createSelector(selectSorting, identity);

export const selectRowHeight = createSelector(selectSliceRoot, _ => _.rowHeight);

export const selectQueryParams = createSelector(
  selectResolvedFilters,
  selectResolvedPagination,
  selectResolvedSorting,
  generateParams
);
