import { object, number, string, array, TypeOf } from 'yup';

import { ITextSnippets } from '@services/useTextSnippets';
import { useMemo } from 'react';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from '@components/common/Table';

const useSchema = (i18nKeys: ITextSnippets['issueViewManager']) => {
  const schema = object({
    name: string().required(i18nKeys.titleIsRequired),
    order: number(),
    field_order: array().of(string()),
    description: string().optional(),
    sort_column: string().optional(),
    filters: object({
      groups: array().of(string()),
      custom_fields: array().of(string()),
      fields: array().of(string()),
    }),
    visibility: string().oneOf(['private', 'shared']),
    actions: array().of(
      object({
        label: string().required(),
        script: string().required(),
        id: string().required(),
        order: number().required(),
      })
    ),
    rows_per_page: number().oneOf(DEFAULT_ROWS_PER_PAGE_OPTIONS.map(({ value }) => value)),
    row_height: string().oneOf(['small', 'medium', 'large']),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<typeof schema>(() => schema, [i18nKeys]);
};

export interface IssueViewSchema extends TypeOf<ReturnType<typeof useSchema>> {}
export default useSchema;
