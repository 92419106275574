import { Overrides, TableComponents } from '../types';
import { TableContextMenu } from './TableContextMenu';
import { TableRow } from './TableRow';
import { TableToolbar } from './TableToolbar/TableToolbar';

export { default as TablePagination } from './TablePagination/TablePagination';
export { default as TableCell } from './TableCell';
export { default as TableCheckboxCell } from './TableCheckboxCell';
export * from './TableToolbar/TableToolbar';
export * from './TableContextMenu';
export * from './TableRow';

export const defaultComponents: Overrides<TableComponents> = {
  ContextMenu: { component: TableContextMenu },
  TableRow: { component: TableRow },
  TableToolbar: { component: TableToolbar },
};
