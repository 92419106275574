import { MVTLayer } from '@components/common/Map';
import React, { FC, useMemo } from 'react';
import colors from '@theme/colors';
import * as x from '@utils/map';
import { EntityIconsLayer } from './EntityIconsLayer';
import { EntityLabelsLayer } from './EntityLabelsLayer';

export type EntitiesLayerProps = {
  id: string;
  sourceId: string;
  url: string;
};

export const EntitiesLayer: FC<EntitiesLayerProps> = ({ id, url, ...props }) => {
  const layout = useMemo(() => ({}), []);

  const paint = useMemo(
    () => ({
      'circle-color': x.colorFromProp('alert_color'),
      'circle-radius': ['interpolate', ['linear'], ['zoom'], 10, 10, 20, 20],
      'circle-stroke-color': colors.white,
      'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 10, 1, 20, 2],
      'circle-blur': 0.1,
    }),
    []
  );

  const shadowPaint = useMemo(
    () => ({
      'circle-color': colors.gray[4],
      'circle-radius': ['interpolate', ['linear'], ['zoom'], 10, 9, 20, 18],
      'circle-opacity': 0.4,
    }),
    []
  );

  return (
    <>
      <MVTLayer id={id} url={url} {...props} type="circle" layout={layout} paint={paint} />
      <MVTLayer
        id={`${id}-shadow`}
        {...props}
        type="circle"
        sourceLayerId={id}
        layout={layout}
        paint={shadowPaint}
        beforeId={id}
      />
      <EntityIconsLayer id={`${id}-icons`} {...props} sourceLayerId={id} />
      <EntityLabelsLayer id={`${id}-labels`} {...props} sourceLayerId={id} />
    </>
  );
};
