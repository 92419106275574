import React, { ReactElement, useCallback } from 'react';
import Button from '@components/common/Button';
import useTextSnippets from '@services/useTextSnippets';
import CopyText from '@components/typography/CopyText';
import Icon from '@components/common/Icon';
import * as PopperJS from '@popperjs/core';
import { DetachedPopover } from '@components/common/Popover/DetachedPopover';
import { FieldsConfig, FormBuilder } from '@components/common/form/FormBuilder';
import { SubmitButton } from '@components/common/form';
import useBreakpoint from '@services/useBreakpoint';
import Drawer, { DrawerSize } from '@components/common/Drawer';
import Modal from '@components/common/Modal';

import styles from './FiltersPopup.module.scss';

export type FiltersPopupProps = {
  isOpen: boolean;
  isLoading?: boolean;
  target?: Element | PopperJS.VirtualElement | DOMRect | null;
  filters: FieldsConfig;
  onChange?: (filters: Dictionary<any>) => void;
  onSubmit?: (filters: Dictionary<any>) => void;
  onClose?: () => void;
  onFiltersViewToggle?: () => void;
};

export function FiltersPopup({
  isOpen,
  isLoading = false,
  target,
  filters,
  onChange,
  onSubmit,
  onClose,
  onFiltersViewToggle,
}: FiltersPopupProps): ReactElement | null {
  const i18n = useTextSnippets();

  const {
    breakpoints: { isDesktop },
  } = useBreakpoint();

  const handleApply = useCallback(
    (values: Dictionary<any>) => {
      onSubmit?.(values);
      onClose?.();
    },
    [onClose, onSubmit]
  );

  const handleFiltersViewToggle = useCallback(() => {
    onFiltersViewToggle?.();
    onClose?.();
  }, [onFiltersViewToggle, onClose]);

  const contents = (
    <div className={styles.filterPopOverContent}>
      <div className="flex flex-col flex-1">
        <div className={styles.header}>
          <CopyText variant="copy-1" color="dark">
            {i18n.common.filterBy}
          </CopyText>

          {isDesktop && onFiltersViewToggle && (
            <Button variant="link" additionalClass="self-justify-end" onClick={handleFiltersViewToggle}>
              <Icon name="Sidebar" additionalClass="mr-4" size="s" />
              {i18n.table.filtersInSidebar}
            </Button>
          )}
        </div>

        <div className={styles.body}>
          <FormBuilder fields={filters} onSubmit={handleApply} onChange={onChange} isSubmitting={isLoading}>
            <div className={styles.actions}>
              {!!onClose && (
                <Button
                  size="s"
                  variant="fillOutline"
                  onClick={onClose}
                  ariaLabel="Close Button"
                  additionalClass="mr-16"
                >
                  {i18n.modal.cancelLabel}
                </Button>
              )}
              <SubmitButton size="s" variant="fillBlueDark" ariaLabel="Accept Button">
                {i18n.modal.apply}
              </SubmitButton>
            </div>
          </FormBuilder>
        </div>
      </div>
    </div>
  );

  if (!target) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        usePortal
        customFooter={null}
        additionalClasses={{ body: styles.modalBody }}
      >
        {contents}
      </Modal>
    );
  }

  if (isDesktop) {
    return (
      <DetachedPopover isOpen={isOpen} target={target} onClose={onClose} additionalClass={styles.filterPopOver}>
        {contents}
      </DetachedPopover>
    );
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={DrawerSize.STANDARD} usePortal position="bottom">
      {contents}
    </Drawer>
  );
}
