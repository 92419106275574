import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import { NoteWithEntity } from '@infrastructure/api/BaseNClient/useLogbookQuery';
import React, { FC } from 'react';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import Card from '@components/common/Card/Card';
import { CodeEditor } from '@components/common/CodeEditor/CodeEditor';
import { CopyToClipboardButton } from '@components/connected/CopyToClipboardButton/CopyToClipboardButton';
import { Position } from '@blueprintjs/core';
import c from 'classnames';
import { useDeviceConfigHistoryQuery } from '@infrastructure/api/BaseNClient/useDeviceConfigHistoryQuery';
import { TWO_MINUTES } from '@constants/date';
import { ConfigInfoGrid } from './ConfigInfoGrid';

import styles from '@components/pages/ConfigDiffPage/components/DiffPanel.module.scss';

export type ConfigDetailsProps = DetailsPanelProps & {
  item?: NoteWithEntity | null;
};

export const ConfigDetails: FC<ConfigDetailsProps> = ({ item }) => {
  const query = useDeviceConfigHistoryQuery(
    {
      entityId: item?.entityid!,
      start: (item?.timestamp! - TWO_MINUTES).toString(),
      end: (item?.timestamp! + TWO_MINUTES).toString(),
    },
    {
      enabled: !!(item?.entityid && item?.timestamp),
    }
  );

  return (
    <>
      <ConfigInfoGrid note={item} additionalClass="mb-16" />

      {query.isLoading ? (
        <LoadingSpinner centered />
      ) : query.data?.result?.[0].config ? (
        <Card
          header={
            <div className="w-full hbox">
              <div className="copy-4">Config Change</div>

              <CopyToClipboardButton
                size="s"
                textToCopy={query.data.result[0].config}
                tooltipProps={{ targetProps: { className: 'ml-auto -mr-6' }, position: Position.TOP }}
              />
            </div>
          }
          additionalClass={c(styles.diffPanel, 'h-full')}
          noPadding
        >
          <CodeEditor value={query.data.result[0].config} readOnly additionalClass={styles.codeEditor} />
        </Card>
      ) : null}
    </>
  );
};
