import React, { ReactNode } from 'react';
import c from 'classnames';
import Headline from '@components/typography/Headline';
import Icon, { IconNames } from '@components/common/Icon';
import CopyText from '@components/typography/CopyText';
import Breadcrumbs, { BreadcrumbsProps } from '@components/common/Breadcrumbs';
import Button, { ButtonProps } from '@components/common/Button';
import Tooltip from '@components/common/Tooltip';

import styles from './PageHeader.module.scss';

export type PageControlProps = Omit<ButtonProps, 'children'> & {
  icon?: IconNames;
  showLabelOnMobile?: boolean;
  hideIconOnDesktop?: boolean;
  label: string;
  tooltip?: string;
};

const PageControl = ({
  loading,
  variant,
  icon,
  hideIconOnDesktop,
  showLabelOnMobile,
  label,
  tooltip,
  ...props
}: PageControlProps) => {
  const button = (
    <Button
      additionalClass={c(styles.pageControl, loading && styles.pageControlLoading)}
      variant={variant || 'fillOutline'}
      size="s"
      {...props}
    >
      <div className="hbox">
        {icon && (
          <Icon
            name={icon}
            size="s"
            additionalClass={c(
              styles.pageControlIcon,
              loading && styles.pageControlIconLoading,
              hideIconOnDesktop && styles.desktopPageControlHiddenIcon
            )}
          />
        )}
        <CopyText
          variant="copy-5"
          additionalClass={c(styles.pageControlLabel, showLabelOnMobile && styles.showLabelOnMobile)}
        >
          {label}
        </CopyText>
      </div>
    </Button>
  );
  return tooltip ? <Tooltip content={tooltip}>{button}</Tooltip> : button;
};

export type PageHeaderProps = {
  level?: number;
  breadcrumbs?: boolean;
  customTitle?: boolean;
  title?: string | ReactNode;
  left?: string | ReactNode;
  middle?: string | ReactNode;
  right?: string | ReactNode;
  pageControls?: PageControlProps[];
  breadcrumbsProps?: Partial<BreadcrumbsProps>;
  additionalClass?: string;
};

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  level = 1,
  customTitle,
  breadcrumbs,
  left = null,
  middle = null,
  right = null,
  pageControls,
  breadcrumbsProps = {},
  additionalClass,
}) => {
  return (
    <div className={c(styles.header, additionalClass, level === 1 ? styles.levelOne : styles.levelTwo)}>
      <div className={c(styles.left, breadcrumbs && styles.breadcrums)}>
        {title && !customTitle && (
          <Headline variant="headline-6" additionalClass={styles.pageTitle}>
            {title}
          </Headline>
        )}
        {customTitle && title}
        {breadcrumbs && <Breadcrumbs {...breadcrumbsProps} />}
        {left}
      </div>

      {middle && <div className={styles.middle}>{middle}</div>}

      <div className={styles.right}>
        {pageControls && pageControls.map(pageControl => <PageControl key={pageControl.id} {...pageControl} />)}
        {right}
      </div>
    </div>
  );
};

export default PageHeader;
