import React, {
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import CopyText from '@components/typography/CopyText';
import c from 'classnames';
import { InputProps } from '@components/common/form/Input/Input';
import { DefaultMotion } from '@services';
import IconButton from '@components/common/IconButton';
import TextControl from '../Input/TextControl';

import styles from './ToggleInput.module.scss';

export type ToggleInputProps = Omit<InputProps, 'type'> & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  // toggle input is interested in having an error state for enforced focus
  errorText?: string;
  initialToggle?: boolean;
  enforceFocusOnInvalid?: boolean;
};

export const ToggleInput = forwardRef<HTMLInputElement, ToggleInputProps>(function ToggleInput(
  {
    animate,
    disabled,
    additionalClass,
    errorText,
    value = '',
    initialToggle = false,
    enforceFocusOnInvalid = true,
    onBlur,
    onKeyDown,
    ...otherProps
  },
  ref
) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [toggleOn, setToggleOn] = useState<boolean>(!!initialToggle);

  useEffect(() => {
    if (errorText) {
      setToggleOn(true);
    }
  }, [errorText]);

  const handleToggleTextClick = useCallback(() => {
    setToggleOn(true);
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();

        if (!errorText || !enforceFocusOnInvalid) {
          e.currentTarget.blur();
          setToggleOn(false);
          onKeyDown?.(e);
        }
      }
    },
    [errorText, enforceFocusOnInvalid, onKeyDown]
  );

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    e => {
      if (!errorText || !enforceFocusOnInvalid) {
        setToggleOn(false);
        onBlur?.(e);
      } else if (errorText) {
        e.preventDefault();
        e.stopPropagation();
        inputRef.current?.focus();
      }
    },
    [errorText, enforceFocusOnInvalid, onBlur]
  );

  const contents = !toggleOn ? (
    <CopyText additionalClass={styles.toggleText} onClick={!disabled ? handleToggleTextClick : undefined}>
      {value}
      <IconButton size="xs" icon="Edit" additionalClass="ml-4" disabled={disabled} />
    </CopyText>
  ) : (
    <TextControl
      type="text"
      ref={inputRef}
      placeholder=""
      value={value}
      {...otherProps}
      animate={animate}
      disabled={disabled}
      hideCounter
      errorText={errorText}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      autoFocus
      noInfo={!errorText}
    />
  );

  return (
    <div className={c(styles.toggleInput)}>
      {animate ? (
        <DefaultMotion key={`toggle${String(toggleOn)}`} full className="flex items-center">
          {contents}
        </DefaultMotion>
      ) : (
        contents
      )}
    </div>
  );
});
