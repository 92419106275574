import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { updateSettings } from '@redux/settings';
import { useAppDispatch } from '@infrastructure/redux/store';
import { useAccount } from '@redux/auth';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import QUERY_CONFIGS from '../query-configs';
import { z } from 'zod';

export const infoSchema = z.object({
  features: z.object({
    allowPasswordRecovery: z.boolean().optional(),
  }),
  service: z.object({
    timezone: z.string(),
    startuptime: z.number(),
    servicename: z.string(),
    locale: z.string().optional(),
    url: z.string(),
    platformVersion: z.string(),
  }),
  session: z
    .object({
      authenticated: z.boolean(),
      user: z.string(),
    })
    .optional(),
});

export type AppInfo = z.infer<typeof infoSchema>;

export type AppSettingsSearchParams = { loggedIn: boolean };

// we'll simply use logged in flag instead of token, really doubt more than one user would use the same machine.
export const useSettingsData = <T extends AppInfo = AppInfo>(
  searchParams: AppSettingsSearchParams = { loggedIn: false },
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  const dispatch = useAppDispatch();
  const account = useAccount();

  const settingsQueryVariable = { loggedIn: !!account?.token };

  const onLoadSettingsSuccess = (loadedSettings: AppInfo) => {
    dispatch(updateSettings(loadedSettings));
  };

  return useQuery<T, Error>(
    ['settings', { ...settingsQueryVariable, ...searchParams }],
    (): Promise<T> => apiClient.get<T, AppSettingsSearchParams>(ENDPOINTS.appSettings),
    {
      ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
      ...queryOptions,
      onSuccess: (appSettings: T) => {
        onLoadSettingsSuccess(appSettings);

        queryOptions.onSuccess?.(appSettings);
      },
    }
  );
};
