import z from 'zod';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Stepper } from '@components/common/Stepper';
import { titleCase } from '@utils/string';
import Headline from '@components/typography/Headline';
import Button from '@components/common/Button';
import { FormikHelpers, FormikProps } from 'formik';
import Icon from '@components/common/Icon';
import HorizontalSeparator from '@components/separators/HorizontalSeparator';
import { WizardContext } from './WizardContext';
import { FormProps } from '@components/common/form';
import CopyText from '@components/typography/CopyText';
import { clone, debounce, isEmpty } from 'lodash';

import styles from './Wizard.module.scss';

export type WizardStepProps = Omit<FormProps, 'validationSchema' | 'initialValues'> & {
  name: string;
  title?: string;
  description?: string;
  validationSchema?: z.ZodSchema<any>;
  initialValues?: any;
};

export type WizardProps = {
  onSubmit?: (values: any, options: { setIsSubmitting: (state: boolean) => void }) => void;
  submitButtonText?: string | React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  allEnabled?: boolean;
  initialValues?: any;
};

export const Wizard: React.FC<WizardProps> = ({
  children,
  onSubmit,
  submitButtonText,
  allEnabled,
  initialValues: globalInitialValues,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, forceUpdate] = useState(Date.now());
  const [activeStep, setActiveStep] = useState(0);

  const Steps = useMemo(() => React.Children.toArray(children) as React.ReactElement[], [children]);
  const CurrentStep = useMemo(() => Steps[activeStep], [activeStep, Steps]);

  // collect initial steps from children
  const initialSteps = useMemo(
    () =>
      globalInitialValues ??
      Steps.reduce((acc, step) => {
        const { name, initialValues } = step.props;
        return { ...acc, [name]: initialValues };
      }, {}),
    [Steps, globalInitialValues]
  );
  const valuesRef = useRef<any>(initialSteps);

  const { title, name, description, validationSchema, initialValues } = CurrentStep.props;

  type CurrentSchema = z.infer<typeof validationSchema>;
  const stepFormRef = useRef<FormikProps<CurrentSchema>>(null);

  const isStepValid = useCallback(
    (stepIdx: number) => {
      const stepProps = Steps[stepIdx].props;
      const isValid =
        !stepProps.validationSchema ||
        stepProps.validationSchema.safeParse(valuesRef.current[stepProps.name] ?? stepProps.initialValues).success;
      return isValid;
    },
    [Steps]
  );

  const areAllStepsValid = useCallback(() => {
    const isInvalid = Steps.some((step, idx) => !isStepValid(idx));
    return !isInvalid;
  }, [Steps, isStepValid]);

  const isSubmitDisabled = (stepIdx = activeStep) => stepFormRef.current?.isSubmitting || !isStepValid(stepIdx);

  const steps = Steps.map(({ props: { name } }, idx) => ({
    title: titleCase(name),
    enabled: !idx || isStepValid(idx - 1),
  }));

  const handleStepSubmit = useCallback(
    (values: CurrentSchema, { setSubmitting }: FormikHelpers<CurrentSchema>) => {
      // useWizardHandle will call this function with empty values - ignore it
      if (values && !isEmpty(values)) {
        valuesRef.current = { ...valuesRef.current, [name]: values };
      }
      setSubmitting(false);
      setActiveStep(step => step + 1);
    },
    [name]
  );

  const handleTriggerSubmit = useCallback(() => {
    stepFormRef.current?.submitForm();
  }, []);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    onSubmit?.(valuesRef.current, { setIsSubmitting });
  }, [onSubmit]);

  //! for whatever reason, this triggers twice for every change and debounce doesn't help
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((values: CurrentSchema) => {
      valuesRef.current = { ...valuesRef.current, [name]: values };
      // re-render to force recheck stepFormRef for validity
      forceUpdate(Date.now());
    }, 100),
    [name]
  );

  const handleError = useCallback(() => {
    // re-render to force recheck stepFormRef for validity
    forceUpdate(Date.now());
  }, []);

  return (
    <div className={styles.wizard}>
      <div className={styles.header}>
        <Stepper
          activeStep={activeStep}
          steps={steps}
          onSelectStep={setActiveStep}
          additionalClass="mx-auto my-32"
          allEnabled={allEnabled}
        />

        <Headline variant="headline-6" additionalClass={description ? 'mb-4' : 'mb-16'}>
          {title}
        </Headline>

        {description && (
          <CopyText variant="copy-4" additionalClass="mb-16">
            {description}
          </CopyText>
        )}
      </div>

      <div className={styles.body}>
        <WizardContext.Provider value={{ valuesRef }}>
          {React.cloneElement(CurrentStep, {
            innerRef: stepFormRef,
            initialValues: clone(valuesRef.current[name] ?? initialValues),
            validationSchema: validationSchema,
            enableReinitialize: true,
            onSubmit: handleStepSubmit,
            onChange: handleChange,
            onError: handleError,
            validateOnChange: true,
            validateOnBlur: true,
            additionalClass: 'w-full',
          })}
        </WizardContext.Provider>
      </div>

      <HorizontalSeparator additionalClass={styles.separator} noSpacing />

      <div className={styles.actions}>
        {activeStep > 0 && (
          <Button variant="fillOutline" onClick={() => setActiveStep(activeStep - 1)}>
            <Icon name="ChevronLeft" additionalClass="mr-8" />
            {Steps[activeStep - 1].props.title}
          </Button>
        )}

        {activeStep < Steps.length - 1 ? (
          <Button
            variant="fillBlueDark"
            onClick={handleTriggerSubmit}
            additionalClass="ml-auto"
            disabled={isSubmitDisabled()}
          >
            {Steps[activeStep + 1].props.title}
            <Icon name="ChevronRight" additionalClass="ml-8" />
          </Button>
        ) : (
          <Button
            variant="fillBlueDark"
            onClick={handleSubmit}
            additionalClass="ml-auto"
            disabled={!areAllStepsValid() || isSubmitting}
            loading={isSubmitting}
          >
            {submitButtonText ?? (
              <>
                <Icon name="Save" additionalClass="mr-8" />
                Submit
              </>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
