import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { apiClient } from '@infrastructure/api';
import { GenericOkApiResponse } from '@infrastructure/api/types';

export const useMWDeleteMutation = <T extends GenericOkApiResponse = GenericOkApiResponse, P extends string = string>(
  mutationOptions: UseMutationOptions<T, Error, P> = {}
): UseMutationResult<T, Error, P> =>
  useMutation<T, Error, P>(
    (id): Promise<T> =>
      apiClient.delete<T, any>(ENDPOINTS.maintenanceWindowById.replace(':id', id), {
        ...mutationOptions,
      })
  );
