import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import React, { useEffect, useMemo, useCallback } from 'react';
import {
  selectItemsAsArray,
  selectPagination,
  selectQueryParams,
  selectSorting,
  selectResolvedFilters,
  selectAreLoaded,
} from '@redux/mwListPage';
import { Sort, RemoteStoredTable, RemoteStoredTableProps, RowHeight } from '@components/common/Table';
import { SortingParams } from '@infrastructure/redux/types';
import {
  MaintenanceWindow,
  useMaintenanceWindowsQuery,
} from '@infrastructure/api/BaseNClient/useMaintenanceWindowsQuery';
import { getTableColumns } from './utils';
import { NoMWs, UpcomingMWs } from '@components/pages/MWListPage/components';
import { DefaultMotion } from '@services/theme';
import CopyText from '@components/typography/CopyText';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';

export type MaintenanceTableProps = {
  actions: Dictionary<any>;
  entityId?: string;
  onInitialLoadComplete?: () => void;
  showUpcoming?: boolean;
};

export const MaintenanceTable: React.FC<MaintenanceTableProps> = ({
  actions,
  entityId,
  onInitialLoadComplete,
  showUpcoming = false,
}) => {
  const { updatePagination, updateSorting, updateFilters, updateSearch, updateItems, updateRowHeight } = actions;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const areLoaded = useAppSelector(selectAreLoaded);
  const items = useAppSelector(selectItemsAsArray);
  const allFilters = useAppSelector(selectResolvedFilters);
  const pagination = useAppSelector(selectPagination);
  const sorting = useAppSelector(selectSorting);
  const params = useAppSelector(selectQueryParams);
  const query = useMaintenanceWindowsQuery({
    entityIds: entityId,
    ...params,
  });

  useEffect(() => {
    if (query.data?.maintenancewindows) {
      dispatch(updateItems(query.data.maintenancewindows));
      dispatch(updatePagination({ rowCount: query.data.total }));
      onInitialLoadComplete?.();
    }
  }, [query.data, dispatch, onInitialLoadComplete, updateItems, updatePagination]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(updatePagination({ page: newPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSortChange = useCallback(
    (sortBy: string | null, sort: Sort) => {
      dispatch(updateSorting({ sortBy: sortBy as SortingParams['sortBy'], sort }));
    },
    [dispatch, updateSorting]
  );

  const handleRowsPerPageChange = useCallback(
    (newRowsPerPage: number) => {
      dispatch(updatePagination({ rowsPerPage: newRowsPerPage }));
    },
    [dispatch, updatePagination]
  );

  const handleSearch = useCallback(
    (keyword?: string) => {
      dispatch(updateSearch(keyword));
      dispatch(updatePagination({ page: 1 }));
    },

    [dispatch, updatePagination, updateSearch]
  );

  const handleFiltersChange = useCallback(
    (newFilters: Dictionary<any>) => {
      dispatch(updateFilters(newFilters));
      dispatch(updatePagination({ page: 1 }));
    },
    [dispatch, updateFilters, updatePagination]
  );

  const handleRowHeightChange = useCallback(
    (newRowHeight: RowHeight) => {
      dispatch(updateRowHeight(newRowHeight));
    },
    [dispatch, updateRowHeight]
  );

  const handleCreateNew = useCallback(() => {
    navigate(ROUTES.createMaintenanceWindow);
  }, [navigate]);

  const tableProps: RemoteStoredTableProps<MaintenanceWindow> = useMemo(
    () => ({
      name: 'mwListPage',
      items,
      columns: getTableColumns(),
      onRowsPerPageChange: handleRowsPerPageChange,
      onPageChange: handlePageChange,
      onSortChange: handleSortChange,
      onSearch: handleSearch,
      onFiltersChange: handleFiltersChange,
      onRowHeightChange: handleRowHeightChange,
      keyword: allFilters.generalFilter,
      isLoading: query.isLoading || query.isFetching,
      config: {
        ...pagination,
        sort: sorting?.sort,
        sortBy: sorting?.sortBy,
        sortable: true,
        bordered: false,
        selectable: false,
      },
      sidebarFilters: true,
    }),
    [
      items,
      handleRowsPerPageChange,
      handlePageChange,
      handleSortChange,
      handleSearch,
      handleFiltersChange,
      handleRowHeightChange,
      allFilters.generalFilter,
      query.isLoading,
      query.isFetching,
      pagination,
      sorting?.sort,
      sorting?.sortBy,
    ]
  );

  return (
    <div className="flex flex-col h-full min-h-0">
      {!areLoaded ? null : !items.length ? (
        <NoMWs onCreateNew={handleCreateNew} />
      ) : (
        <>
          {showUpcoming && (
            <>
              <UpcomingMWs />
              <CopyText variant="copy-2" additionalClass="mb-16">
                List of Maintenance Windows
              </CopyText>
            </>
          )}

          <DefaultMotion key="inventory">
            <RemoteStoredTable<MaintenanceWindow> {...tableProps} />
          </DefaultMotion>
        </>
      )}
    </div>
  );
};
