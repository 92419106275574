import { WidgetSetup } from '@components/pages/WidgetPage/types';
import { IssueManagerWidget } from '@components/widgets/IssueManagerWidget/IssueManagerWidget';
import { DataSourcePicker } from './components/DataSourcePicker';
import { OptionsTab } from './components/OptionsTab';

export default {
  type: 'issue_manager',
  icon: 'Alerts',
  i18nTitle: 'issues.issues',
  Widget: IssueManagerWidget,
  DataSourcePicker,
  OptionsTab,
} as WidgetSetup;
