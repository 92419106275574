import useTextSnippets from '@services/useTextSnippets';
import { useDeleteWidgetMutation } from '@infrastructure/api/BaseNClient/useDeleteWidgetMutation';
import { selectDashboards, setDashboard } from '@redux/dashboards';
import { removeWidgetFromDashboard } from '@redux/dashboards/dashboard-utils';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { ConfirmOperation } from '@services/withConfirmation';
import { CommandOptions } from './types';

export const useWidgetRemoveCommand = (confirmOperation: ConfirmOperation) => {
  const i18nCommon = useTextSnippets('common');
  const i18n = useTextSnippets('widgetPage');
  const deleteWidgetMutation = useDeleteWidgetMutation();
  const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();
  const dispatch = useAppDispatch();
  const dashboards = useAppSelector(selectDashboards);

  return (dashboardId: string, widgetId: string, { successMessage, failureMessage }: CommandOptions = {}) => {
    const dashboard = dashboards[dashboardId];

    return confirmOperation({
      title: i18nCommon.cannotBeUndoneWarning,
      contents: i18n.deleteWarning,
      modalProps: { danger: true },
    }).then(result => {
      return result
        ? deleteWidgetMutation.mutateAsync(
            { id: widgetId },
            {
              onSuccess: getSuccessHandler(() => {
                const newDashboard = removeWidgetFromDashboard(dashboard, widgetId);
                dispatch(setDashboard(newDashboard));
              }, successMessage),
              onError: getFailureHandler(undefined, failureMessage),
            }
          )
        : null;
    });
  };
};
