import React from 'react';
import c from 'classnames';
import { CheckboxProps } from '../Checkbox';
import Tooltip from '@components/common/Tooltip';
import Icon from '@components/common/Icon';

import styles from './style.module.scss';

export type ToggleSwitchProps = Omit<CheckboxProps, 'indeterminate' | 'onChange'> & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked?: boolean) => void;
};

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  label,
  labelFirst = false,
  name,
  checked,
  disabled,
  size = 'm',
  additionalClass,
  required = false,
  helperText,
  onChange,
  ...otherProps
}) => {
  const className = checked ? 'checked' : 'unchecked';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e, e.target?.checked ?? false);
  };

  const labelComponent = label && (
    <span className={styles.title}>
      {label}
      {required ? <span className="text-red-base"> *</span> : null}
      {helperText && (
        <Tooltip content={helperText}>
          <Icon name="Help" additionalClass="ml-6" size="s" />
        </Tooltip>
      )}
    </span>
  );

  return (
    <label
      htmlFor={id}
      className={c(
        styles.toggleSwitch,
        styles[className],
        {
          [styles.disabled]: disabled,
          [styles.labelFirst]: labelFirst,
          [styles.small]: size === 's',
          [styles.medium]: size === 'm',
          [styles.large]: size === 'l',
        },
        additionalClass
      )}
    >
      {labelFirst && labelComponent}
      <span className={styles.container}>
        <input
          id={id ?? name}
          name={name ?? id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          {...otherProps}
        />
        <span className={styles.handle} />
      </span>
      {!labelFirst && labelComponent}
    </label>
  );
};

export default ToggleSwitch;
