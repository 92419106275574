import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericOkApiResponse } from '../types';
import { RouteAlerts } from '@redux/widgetPage';
import { PaginatedQueryRequest } from './usePaginatedQuery';
import { toAlertMap } from '@utils/entity';
import { InterfaceAlertName } from '@components/connected/InterfacesTable/types';
import { z } from 'zod';
import { ALERT_COLORS } from '@constants/issue';
import useTextSnippets from '@services/useTextSnippets';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { ALERT_PRIORITIES } from '@constants/alert';

export const silenceReasonSchema = z.enum([
  'maintenance silent',
  'agent silent',
  'root cause silent',
  'route silent',
  'parent silent',
  'user silent',
  'silent',
  'not silent',
]);

export type SilenceReason = z.infer<typeof silenceReasonSchema>;

export const SilenceReasonTitles: Record<SilenceReason, string> = {
  'maintenance silent': 'Silencing caused by maintenance windows or ATLs.',
  'agent silent': 'Silenced due to agents measuring the target are not sending any measurements.',
  'root cause silent': 'Silencing caused by root cause rules.',
  'route silent': 'Silencing caused by route root cause detection.',
  'parent silent': 'Silencing caused by parent alert.',
  'user silent': 'User has set the alert silent.',
  silent: 'Silent alert caused by the Alert plugin configuration  or manually using the alert popup settings.',
  'not silent': 'normal',
};

export const SilenceReasonColors: Record<SilenceReason, string> = {
  'maintenance silent': 'alert.maintenance',
  'agent silent': 'pink',
  'root cause silent': 'pink',
  'route silent': 'pink',
  'parent silent': 'pink',
  'user silent': 'alert.maintenance',
  silent: 'alert.gray',
  'not silent': 'alert.red',
};

export const prioritySchema = z.enum(ALERT_PRIORITIES);

export type GetAlertsRequest = PaginatedQueryRequest & {
  name?: string;
  ids?: string; // comma-separated list of alerts to retrieve
  silent?: boolean; // true
  green?: boolean; // false
  colors?: string; // comma-separated list of alert colors to include, available colors are: null,red,orange,yellow,lime,green
  details?: boolean; // false
  events?: boolean;
  coordinates?: boolean;
  start?: number; // timestamp in ms
  end?: number;
  entity_id?: string;
  metric_id?: string;
  pages?: string; // comma-separated list of pages to include
  overrides?: boolean;
};

export const alertInfoSchema = z.object({
  unit: z.string(),
  name: z.string(),
  channel: z.string(),
  comment: z.string().optional(),
  page: z.string().optional(),
  state: z.string(),
  priority: prioritySchema,
  status: z.string(),
  customer: z.string().optional(),
  parent: z.string().optional(),
  entity: z.string().optional(), // apparently a name of entity, but for some reason it's different from top-level entityname prop
});

export const alertEventSchema = z.object({
  author: z.string().optional(),
  description: z.string().optional(),
  time: z.number().optional(), // if it is grey ie - nodata - there will be no data to visualize
  type: z.union([z.literal('ok'), z.literal('error'), z.literal('discovered')]),
  queue: z.string().optional(),
});

// ¯\_(ツ)_/¯ value can be represented as number, or empty string or dash for missing values
export const valueSchema = z.number().or(z.literal('')).or(z.literal('-'));

export const colorSchema = z
  .enum(ALERT_COLORS)
  .or(z.literal('grey'))
  .optional()
  // TODO: remove this when BE starts returning 'gray' instead of 'grey'
  .transform((value: any) => (value === 'grey' ? 'gray' : value) as typeof ALERT_COLORS[number]);

const alertChannelSchema = z.object({
  silent: silenceReasonSchema.optional(),
  color: colorSchema,
  active: z.boolean().optional(), // whether alert is silenced or not
  times: z.array(z.number()).optional(),
  time: z.number().optional(),
  value: valueSchema.optional(),
  values: z.array(valueSchema).optional(),
  greenreds: z.array(z.number()).optional(),
  greenred: z.number().optional(),
  info: alertInfoSchema,
});

const alertSchema = z
  .object({
    id: z.string(),
    hasOverrides: z.boolean(),
    events: z.array(alertEventSchema).optional(),
    groups: z.array(z.string()).optional(),
    entityid: z.string().optional(),
    entityname: z.string().optional(),
    parent_entityid: z.string().optional(),
    parent_entityname: z.string().optional(),
  })
  .merge(alertChannelSchema);

export type AlertColor = typeof ALERT_COLORS[number];
export type AlertInfo = z.infer<typeof alertInfoSchema>;
export type AlertEvent = z.infer<typeof alertEventSchema>;
export type Alert = z.infer<typeof alertSchema>;

type GetAlertsResponse = GenericOkApiResponse & {
  alert_names?: RouteAlerts;
  alerts: Alert[];
  alert_map: Record<InterfaceAlertName, Alert>;
  version?: number;
  count?: number;
  total: number;
  errors?: number;
  colors?: string;
};

export const useAlertsQuery = <T extends GetAlertsResponse = GetAlertsResponse>(
  searchParams: GetAlertsRequest = {},
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  return useQuery<T, Error>(
    ['alerts', searchParams],
    (): Promise<T> =>
      apiClient.get<T>(ENDPOINTS.getAlerts, { silent: true, ...searchParams }).then(response => {
        response.alert_map = toAlertMap(response.alerts);
        return response;
      }),
    {
      ...(QUERY_CONFIGS.staticEnabled as UseQueryOptions<T, Error>),
      ...queryOptions,
    }
  );
};

export const useRouteAlertsQuery = () => {
  const i18n = useTextSnippets('widgetPage');
  const { getFailureHandler } = useGenericMutationHandlers();

  return useAlertsQuery(
    { start_index: 0, end_index: 1 },
    { onError: getFailureHandler(undefined, i18n.cannotRetrieveRouteAlerts) }
  );
};
