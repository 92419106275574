import { TextField } from '@components/common/form';
import { object, string } from 'yup';
import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';
import {
  FavoriteFolderCreateRequest,
  FavoriteFolderCreateResponse,
  useFavoriteFolderCreateMutation,
} from '@infrastructure/api/BaseNClient/useFavoriteFolderCreateMutation';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { FavoritesFolder } from './types';
import { Checkbox } from '@components/common/form/Checkbox';
import useTextSnippets from '@services/useTextSnippets';
import LoadingSpinner from '../LoadingSpinner';

import styles from './NewFavoriteForm.module.scss';

export type NewFavoriteFormProps = {
  onSuccess?: (folder: FavoritesFolder) => void;
  onCancel?: () => void;
};

export const NewFavoriteForm: FC<NewFavoriteFormProps> = ({ onSuccess, onCancel }) => {
  const i18n = useTextSnippets('favorites');

  const createMutation = useFavoriteFolderCreateMutation();
  const { getSuccessHandler, getFailureHandler } = useGenericMutationHandlers();

  const initialValues = {
    name: '',
  };

  const validationSchema = object({
    name: string().required(),
  });

  const handleSubmit = (
    { name }: FavoriteFolderCreateRequest,
    { setSubmitting }: FormikHelpers<FavoriteFolderCreateRequest>
  ) => {
    setSubmitting(true);
    void createMutation.mutate(
      { name },
      {
        onSuccess: getSuccessHandler(({ id }: FavoriteFolderCreateResponse) => {
          setSubmitting(false);
          onSuccess?.({
            id,
            name,
          });
        }),
        onError: getFailureHandler(() => {
          setSubmitting(false);
        }),
      }
    );
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ handleSubmit: triggerSubmit, isSubmitting }) => {
        const handleKeyDown = (e: React.KeyboardEvent) => {
          if (e.key === 'Enter') {
            triggerSubmit();
          } else if (e.key === 'Escape') {
            onCancel?.();
            e.stopPropagation();
          }
        };

        return (
          <div className={styles.newItem}>
            {isSubmitting ? (
              <LoadingSpinner centered additionalClass={styles.spinner} size="s" />
            ) : (
              <Checkbox size="s" additionalClass="mr-8" disabled />
            )}
            <TextField
              name="name"
              disabled={isSubmitting}
              onKeyDown={handleKeyDown}
              noInfo
              additionalClass={styles.input}
              autoFocus
              placeholder={i18n.folderName}
            />
          </div>
        );
      }}
    </Formik>
  );
};
