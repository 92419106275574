import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '.';
import { retrieveItem, storeItem } from '@utils/storage';

type StoredToggleButtonGroupProps = {
  name: string;
  options: string[];
  value?: string;
  onChange?: (item: string) => void;
};

export const StoredToggleButtonGroup = ({ name, value, options, onChange }: StoredToggleButtonGroupProps) => {
  const resolvedValue = value ?? retrieveItem(name) ?? options[0];

  const handleChange = React.useCallback(
    (_, idx) => {
      storeItem(name, options[idx]);
      onChange?.(options[idx]);
    },
    [onChange]
  );

  return (
    <ToggleButtonGroup onChange={handleChange}>
      {options.map((option, idx) => (
        <ToggleButton key={idx} active={resolvedValue === option}>
          {option}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
