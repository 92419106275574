import { formatValue, isValidChartDataSource } from '@utils/chart';
import { Chart } from '../../common/Chart';
import { ChartDataSource } from '@infrastructure/redux/widgetPage';
import { useFilterDataQuery } from '@infrastructure/api/BaseNClient';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import { useMemo, useState } from 'react';
import { FilterDataResponse } from '@infrastructure/api/BaseNClient/useFilterDataQuery';
import { DateRange } from '@blueprintjs/datetime';
import { zip } from 'lodash';
import { formatDate } from '@utils/date';
import colors from '@theme/colors';
import c from 'classnames';

import styles from './Sparkline.module.scss';

const getConfig = ({ result: { channels }, unit }: FilterDataResponse, color: string) => {
  const { times, values } = channels?.[0] ?? {};
  return {
    grid: {
      // remove any padding around the graph
      left: '0%',
      right: '0%',
      top: '0%',
      bottom: '0%',
      containLabel: false,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      show: false,
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
      show: false,
    },
    tooltip: {
      trigger: 'axis',
      // triggerOn: 'none',
      axisPointer: {
        type: 'none',
      },
      renderMode: 'html',
      className: styles.timeseriesTooltip,
      appendToBody: true, // escape boundaries of the Widget container
      enterable: true, // won't be able to scroll the overflowing content otherwise
      formatter: function (params: any) {
        return [formatDate(params[0].data[0], undefined, true), formatValue(params[0].data[1], unit, 'unit')].join(
          '<br/>'
        );
      },
      textStyle: {
        fontSize: 12,
      },
    },
    series: [
      {
        data: zip(times ?? [], values ?? []),
        type: 'line',
        symbol: 'none',
        lineStyle: {
          width: 1,
          color,
        },
        silent: true, // disable interactions
      },
    ],
    animation: false, // disable animation
  };
};

export type SparklineProps = {
  source: ChartDataSource;
  dateRange?: DateRange;
  color?: string;
  onLoadSuccess?: (data: FilterDataResponse, source: ChartDataSource | null) => void;
  onLoadError?: (error: Error, source?: ChartDataSource | null) => void;
  additionalClass?: string;
};

export const Sparkline = ({
  source,
  dateRange,
  color = colors.blue.ocean,
  onLoadSuccess,
  onLoadError,
  additionalClass,
}: SparklineProps) => {
  const isValidSource = isValidChartDataSource(source);
  const [isValidResponse, setIsValidResponse] = useState(true);

  const { getFailureHandler } = useGenericMutationHandlers();

  const q = useFilterDataQuery(
    {
      ...source,
    },
    {
      enabled: isValidSource,
      onError: getFailureHandler((error: any) => {
        setIsValidResponse(false);
        onLoadError?.(error, source);
      }),

      onSuccess: (data: FilterDataResponse) => {
        setIsValidResponse(true);
        onLoadSuccess?.(data, source);
      },
    }
  );

  const echartsConfig = useMemo(() => (q.data ? getConfig(q.data, color) : null), [q.data, dateRange, color]);

  if (q.isLoading) {
    return <div className={c(styles.sparkline, styles.loading)} />;
  }

  return q.data ? (
    <Chart additionalClass={c(styles.sparkline, additionalClass)} loading={q.isLoading} echartsConfig={echartsConfig} />
  ) : null;
};
