import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import QUERY_CONFIGS from '@infrastructure/api/query-configs';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { useGenericMutationHandlers } from '@services/useGenericMutationHandlers';
import useTextSnippets from '@services/useTextSnippets';
import { GenericOkApiResponse } from '../types';
import { StripeColor } from '@utils/issue';
import { ArrowProps } from '@components/widgets/MapWidget/components/MapWidgetPopup';
import { EntityIcons } from '@components/widgets/MapWidget/icons/entities';

export type EntitiesGeoJSONRequest = {
  ids?: string;
  customer_ids?: string;
  only_parent?: boolean;
  alerts?: boolean;
  data?: Record<string, any>;
};

export type TopologyRoute = {
  bidirectional: boolean;
  id: string;
  from: string;
  from_name: string;
  from_alert_color?: StripeColor;
  from_icon?: EntityIcons;
  to: string;
  to_name: string;
  to_alert_color?: StripeColor;
  to_icon?: EntityIcons;
  alert_color?: StripeColor;
  width: ArrowProps['width'];
  width_bps: number;
  alert_value?: string;
  alert_id?: string;
  status: 'up' | 'down' | 'unknown';
};

export type TopologyEntity = {
  id: string;
  name: string;
  customerid?: string; // numeric
  maintenance?: boolean;
  alerting?: boolean;
  alert_value?: string; // numeric
  alert_color?: StripeColor;
  topology_coordinates?: {
    [key: string]: [number, number];
  };
};

export type EntitiesGeoJSONResponse = GenericOkApiResponse & {
  entities: TopologyEntity[];
  routes: TopologyRoute[];
};

export const useEntitiesGeoJSONQuery = <T = EntitiesGeoJSONResponse>(
  searchParams: EntitiesGeoJSONRequest = {},
  queryOptions: UseQueryOptions<T, Error> = {}
): UseQueryResult<T, Error> => {
  const i18n = useTextSnippets('widgetPage');
  const { getFailureHandler } = useGenericMutationHandlers();

  return useQuery<T, Error>(
    ['entity_topology', searchParams],
    (): Promise<T> =>
      apiClient.get<T>(ENDPOINTS.getMapTopology, {
        alerts: true,
        ...searchParams,
        only_parent: true,
        // it is possible to request data as GeoJSON, however it doesn't include alerts, even with 'alerts'
        // param set to true ¯\_(ツ)_/¯, so we make sure that we never return it
        maximum: 1000, // NOTE: at 11K items echarts graph hangs and halts browser and even PC
        geojson: false,
      }),
    {
      ...(QUERY_CONFIGS.stale as UseQueryOptions<T, Error>),
      ...queryOptions,
      onError: getFailureHandler(undefined, i18n.cannotRetrieveEntities),
    }
  );
};
