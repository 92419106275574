import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import React, { FC } from 'react';
import Card from '@components/common/Card/Card';
import { CodeEditor } from '@components/common/CodeEditor/CodeEditor';
import { CopyToClipboardButton } from '@components/connected/CopyToClipboardButton/CopyToClipboardButton';
import { Position } from '@blueprintjs/core';
import c from 'classnames';
import { SearchConfigItem } from '@infrastructure/api/BaseNClient/useSearchConfigsQuery';
import { ConfigInfoGrid } from './ConfigInfoGrid';

import styles from '@components/pages/ConfigDiffPage/components/DiffPanel.module.scss';

export type ActiveItemDetailsProps = DetailsPanelProps & {
  item?: SearchConfigItem | null;
  highlightMatches?: string[];
};

export const ActiveItemDetails: FC<ActiveItemDetailsProps> = ({ item, highlightMatches }) => {
  return (
    <>
      <ConfigInfoGrid item={item} additionalClass="mb-16" />

      {item?.config && (
        <Card
          header={
            <div className="w-full hbox">
              <div className="copy-4">Config Change</div>

              <CopyToClipboardButton
                size="s"
                textToCopy={item.config}
                tooltipProps={{ targetProps: { className: 'ml-auto -mr-6' }, position: Position.TOP }}
              />
            </div>
          }
          additionalClass={c(styles.diffPanel, 'h-full')}
          noPadding
        >
          <CodeEditor
            value={item.config}
            readOnly
            additionalClass={styles.codeEditor}
            highlightMatches={highlightMatches}
            scrollToLine={item.line}
          />
        </Card>
      )}
    </>
  );
};
