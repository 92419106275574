import React, { useCallback, useEffect, useState } from 'react';
import c from 'classnames';
import IconButton from '@components/common/IconButton';
import BaseNLogo from '@components/common/BaseNLogo';
import VerticalSeparator from '@components/separators/VerticalSeparator';
import CopyText from '@components/typography/CopyText';
import Grid from '@components/layout/Grid';
import ROUTES from '@infrastructure/routes';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { selectIsHeaderOpen, selectSidebarOpen, setHeaderOpen, setSidebarOpen, toggleSidebar } from '@redux/layout';
import MobileSearchOverlay from './components/MobileSearchOverlay';
import HelpButton from './components/HelpButton';
import UserButton from './components/User';
import { useNavigate } from 'react-router-dom';
import { CypressTestsData, CypressTestType } from '@cypress';
import { useFullscreen } from '@services/useFullscreen';
import Tooltip from '@components/common/Tooltip';
import useTheme from '@services/useTheme';
import { GlobalSearch } from '@components/common/SearchBar/GlobalSearch';

import styles from './Header.module.scss';

export type Props = {
  searchDisabled?: boolean;
  additionalClass?: string;
};

const Header: React.FC<Props> = ({ searchDisabled, additionalClass }) => {
  const sidebarOpen = useAppSelector(selectSidebarOpen);
  const isHeaderOpen = useAppSelector(selectIsHeaderOpen);
  const dispatch = useAppDispatch();
  const [searchOverlayOpen, setSearchOverlayOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const sidebarTestDataConfig = CypressTestsData[CypressTestType.SIDEBAR];
  const headerHeight = parseInt(useTheme<string>('height.header'), 10);
  const { isFullscreenEnabled, isF11, enterFullscreen, exitFullscreen } = useFullscreen();

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isFullscreenEnabled) {
        if (!isHeaderOpen && e.clientY < 20) {
          dispatch(setHeaderOpen(true));
        } else if (isHeaderOpen && e.clientY > headerHeight) {
          dispatch(setHeaderOpen(false));
        }
      }
    },
    [dispatch, headerHeight, isFullscreenEnabled, isHeaderOpen]
  );

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove]);

  useEffect(() => {
    if (isFullscreenEnabled) {
      dispatch(setSidebarOpen(false));
      dispatch(setHeaderOpen(false));
    } else {
      dispatch(setSidebarOpen(true));
      dispatch(setHeaderOpen(true));
    }
  }, [isFullscreenEnabled, dispatch]);

  const openSearch = () => {
    setSearchOverlayOpen(true);
  };

  const closeSearch = () => {
    setSearchOverlayOpen(false);
  };

  const goToHome = () => {
    void navigate(ROUTES.home);
  };

  const handleFullscreenToggle = () => {
    if (isFullscreenEnabled) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  };

  return (
    <div
      className={c(
        styles.headerWrapper,
        !isHeaderOpen && styles.isCollapsed,
        isFullscreenEnabled && styles.isFullscreen,
        additionalClass
      )}
    >
      <Grid additionalClass={styles.headerContainer}>
        <div className={styles.logoAndControl}>
          <IconButton
            ariaLabel="Sidebar-Control"
            icon={sidebarOpen ? 'MenuOpen' : 'Menu'}
            onClick={() => dispatch(toggleSidebar())}
            size="s"
            additionalClass={styles.sidebarControlButton}
            testid={sidebarTestDataConfig.testIds.sidebarToggleButton}
          />
          <div
            role="button"
            aria-label="Logo And Home Button"
            tabIndex={0}
            className={styles.logoContainer}
            onClick={goToHome}
          >
            <BaseNLogo additionalClass={styles.logo} />
            <CopyText variant="copy-4" additionalClass={styles.platformLabel}>
              Platform
            </CopyText>
          </div>
        </div>
        <div className={styles.headerSearch}>
          <div className={styles.searchDesktop}>
            <GlobalSearch disabled={searchDisabled} />
          </div>
        </div>

        <MobileSearchOverlay onClose={closeSearch} open={searchOverlayOpen} />

        <div className={styles.userSectionDesktop}>
          {/* <div className={styles.headerIcon}> */}
          {/*  <IconButton ariaLabel="Recent" icon="Recent" /> */}
          {/* </div> */}
          {/* <div className={styles.headerIcon}> */}
          {/*  <IconButton ariaLabel="Alerts" icon="Notifications" /> */}
          {/* </div> */}
          <div className={styles.headerIcon}>
            <Tooltip
              content={
                isF11
                  ? "Press 'F11' to exit full screen"
                  : isFullscreenEnabled
                  ? 'Exit full screen'
                  : 'Enter full screen'
              }
              usePortal={false}
              placement="bottom"
              additionalClass={styles.tooltip}
            >
              <IconButton
                ariaLabel="Fullscreen"
                icon={isFullscreenEnabled ? 'FullscreenExit' : 'Fullscreen'}
                onClick={handleFullscreenToggle}
                disabled={isF11}
              />
            </Tooltip>
          </div>
          <div className={styles.headerIcon}>
            <HelpButton />
          </div>
          <VerticalSeparator />
          <UserButton />
        </div>
        <div className={styles.userSectionMobile}>
          <div className={styles.fakeSearchBar}>
            <IconButton
              ariaLabel="Search"
              icon="Search"
              onClick={openSearch}
              additionalClass={styles.searchIcon}
              tabIndex={-1}
            />
          </div>
          <VerticalSeparator />
          <UserButton />
        </div>
      </Grid>
    </div>
  );
};

export default Header;
