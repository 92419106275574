import { z } from 'zod';

export const initialAuthValues = {
  username: '',
  password: '',
};

export const authFormSchema = z.object({
  username: z.string().min(1, 'Username is required'),
  password: z.string().min(1, 'Password is required'),
});

export type AuthFormSchema = z.infer<typeof authFormSchema>;
