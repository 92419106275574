import { DEFAULT_TABLE_CONFIG, InternalTableConfig, Sort, Table, TableProps } from '@components/common/Table';
import { usePaginatedQuery } from '@infrastructure/api/BaseNClient/usePaginatedQuery';
import React, { useMemo, useState } from 'react';

import { GenericPaginatedResponse } from '@infrastructure/api/types';

export type DataTableProps<T extends object> = Omit<TableProps<T>, 'items'> & {
  url: string;
  noCache?: boolean;
  items?: T[];
  params?: Record<string, any>;
  onResults?: (results: GenericPaginatedResponse<T>) => void;
};

export function DataTable<T extends object>({
  url,
  config,
  noCache,
  params = {},
  onResults,
  ...tableProps
}: DataTableProps<T>) {
  const initialConfig = useMemo(() => ({ ...DEFAULT_TABLE_CONFIG, ...config } as InternalTableConfig), [config]);
  const { page: initialPage, rowsPerPage: initialRowsPerPage, sortable, paginated, ...otherConfig } = initialConfig;

  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [sort, setSort] = useState<Sort>('asc' as Sort);
  const [sortBy, setSortBy] = useState<string | null>(null);

  const query = usePaginatedQuery<T>(
    url,
    {
      start_index: (page - 1) * rowsPerPage,
      end_index: (page - 1) * rowsPerPage + rowsPerPage,
      order: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : undefined,
      ...params,
    },
    onResults
      ? {
          staleTime: noCache ? 1 : undefined,
          cacheTime: noCache ? 1 : undefined,
          refetchOnMount: 'always',
          onSuccess: onResults,
        }
      : {}
  );

  const tableConfig = useMemo(
    () => ({
      ...otherConfig,
      page,
      rowCount: query.data?.total ?? query.data?.data.length ?? 0,
      sort,
      sortBy,
      rowsPerPage,
      loading: query.isLoading,
    }),
    [page, rowsPerPage, sort, sortBy, otherConfig, query.data, query.isLoading]
  );

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSortChange = (property: string | null, order: Sort) => {
    setSort(order);
    setSortBy(property);
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  };

  return (
    <Table<T>
      {...tableProps}
      items={query.data?.data ?? []}
      config={tableConfig}
      onRowsPerPageChange={handleRowsPerPageChange}
      onPageChange={handlePageChange}
      onSortChange={handleSortChange}
    />
  );
}
