import { Wizard } from '@components/common/form/Wizard';
import { TargetsStep, ReviewStep, PickScriptStep } from './steps';
import Icon from '@components/common/Icon';
import { RunScriptSchema } from './RunScriptWizard.schema';
import { useLocation } from 'react-router-dom';
import { VariablesStep } from './steps/VariablesStep';

export type RunScriptWizardProps = {
  onSubmit?: (values: RunScriptSchema, options: { setIsSubmitting: (state: boolean) => void }) => void;
};

export const RunScriptWizard = ({ onSubmit }: RunScriptWizardProps) => {
  const { state } = useLocation();

  return (
    <Wizard
      onSubmit={onSubmit}
      submitButtonText={
        <>
          <Icon name="CodeEditor" additionalClass="mr-8" />
          Run the job
        </>
      }
    >
      <PickScriptStep name="script" title="Script" initialValues={{ ...(state as any)?.script }} />

      <VariablesStep name="variables" title="Variables" />

      <TargetsStep
        name="targets"
        title="Targets"
        showGlobal={false}
        showRouting={false}
        initialValues={{ entities: (state as any)?.entities || [], global: false, routing: false }}
        queryParams={{ terminal_allowed: true }}
      />

      <ReviewStep name="review" title="Review" />
    </Wizard>
  );
};
