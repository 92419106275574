import React from 'react';
import { useLoadingContext } from 'react-router-loading';
import PageHeader from '@components/layout/PageHeader';
import { AlertsTable } from './components/AlertsTable';
import alertsSlice from '@infrastructure/redux/alertsPage/slice';
import { AlertsHeader } from './components/AlertsHeader';

export const AlertsPage = () => {
  const loadingContext = useLoadingContext();

  React.useEffect(() => {
    document.title = `Alerts - BaseN Platform`;
  }, []);

  return (
    <div className="page-container">
      <AlertsHeader />
      <div className="page-body">
        <AlertsTable onInitialLoadComplete={() => loadingContext.done()} actions={alertsSlice.actions} />
      </div>
    </div>
  );
};
