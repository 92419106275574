import React, { useCallback, useMemo } from 'react';
import IconButton from '@components/common/IconButton';
import CopyText from '@components/typography/CopyText';
import TextControl from '@components/common/form/Input/TextControl';
import { clamp } from 'lodash';

import styles from './PaginationActions.module.scss';

export interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (newPage: number) => void;
}

const PaginationActions: React.FC<TablePaginationActionsProps> = ({ count, page, rowsPerPage, onChangePage }) => {
  const isFirstPage = useMemo(() => page === 1, [page]);
  const isLastPage = useMemo(() => page >= Math.ceil(count / rowsPerPage), [page, count, rowsPerPage]);

  const totalPages = Math.ceil(count / rowsPerPage);

  // const handleFirstPageClick = useCallback(
  //   (event: React.MouseEvent<HTMLButtonElement>) => {
  //     onChangePage(event, 0);
  //   },
  //   [onChangePage]
  // );

  const handleBackClick = useCallback(() => {
    onChangePage(page - 1);
  }, [page, onChangePage]);

  const handleNextClick = useCallback(() => {
    onChangePage(page + 1);
  }, [page, onChangePage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangePage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newPage = +e.currentTarget.value;
      onChangePage(clamp(newPage, 1, totalPages));
    },
    [onChangePage, totalPages]
  );

  // const handleLastPageClick = useCallback(
  //   (event: React.MouseEvent<HTMLButtonElement>) => {
  //     onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  //   },
  //   [onChangePage, count, rowsPerPage]
  // );

  return (
    <div className="flex flex-row items-center">
      {/* <IconButton */}
      {/*  icon="ArrowLeftDouble" */}
      {/*  onClick={handleFirstPageClick} */}
      {/*  disabled={isFirstPage} */}
      {/*  aria-label="First Page" */}
      {/* /> */}
      <IconButton
        icon="ArrowLeft"
        onClick={handleBackClick}
        disabled={isFirstPage}
        aria-label="Previous Page"
        size="xs"
      />
      <TextControl
        value={page?.toString()}
        size="m"
        type="number"
        onChange={handleChangePage}
        noInfo
        noArrows
        additionalClass={styles.pagerInput}
        inputProps={{ min: 1, max: totalPages }}
      />
      <CopyText variant="copy-5" additionalClass="flex text-dark items-center mx-8 text-center">
        / {totalPages}
      </CopyText>
      <IconButton icon="ArrowRight" onClick={handleNextClick} disabled={isLastPage} aria-label="Next Page" size="xs" />
      {/* <IconButton icon="ArrowRightDouble" onClick={handleLastPageClick} disabled={isLastPage} aria-label="Last Page" /> */}
    </div>
  );
};

export default PaginationActions;
