import React, { useState, useCallback, useMemo, useRef } from 'react';
import {
  DashboardTemplate,
  updateDashboard,
  DashboardWithDetails,
  DashboardUpdateSuccessResponse,
  selectCurrentDashboardState,
  setDashboard,
  DashboardsQueryKey,
} from '@redux/dashboards';
import useTextSnippets from '@services/useTextSnippets';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import Error500Page from '@components/pages/Error500Page';
import GridLayout from '@components/layout/GridLayout';
import { Layout, Layouts } from 'react-grid-layout';
import { useApiClient } from '@infrastructure/api/useApiClient';
import { setNotification } from '@redux/notifications';
import { DefaultMotion, useBreakpoint, useScrollAware } from '@services';
import { DashboardContext, DashboardContextType } from '@redux/dashboards/context';
import { Widget } from '@components/layout/Widget';
import { DashboardWidget } from '@redux/widgetPage';
import useForm, { FormProvider } from '@components/common/form/useForm';
import getSchema, { DashboardSchema } from '@redux/dashboards/dashboardSchema';
import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import { DashboardDialogManager } from '@components/pages/Dashboards/CommonComponents/DashboardDialogManager';
import { DashboardPageHeader, PlaceholderWidget } from './components';
import withCommands, { WithCommands } from '@services/withCommands';
import { v4 } from 'uuid';
import { DateRange } from '@blueprintjs/datetime';
import moment from 'moment';
import { TimeControl } from '@components/common/form/TimeControl';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import { useQueryClient } from 'react-query';

import styles from './DashboardDetail.module.scss';

type DashboardDetailProps = {
  dashboard: DashboardWithDetails;
  // TODO
  template?: DashboardTemplate;
} & WithCommands;

const DashboardDetail: React.FC<DashboardDetailProps> = ({
  dashboard,
  widgetRemoveCommand,
  widgetDuplicateCommand,
}) => {
  const dispatch = useAppDispatch();
  const { breakpoints } = useBreakpoint(true);
  const dbTexts = useTextSnippets('dashboards');
  const [activeBreakpoint, setActiveBreakpoint] = useState<string>(breakpoints.isTablet ? 'tablet' : 'xxs');
  const dashboardState = useAppSelector(selectCurrentDashboardState);
  const dashboardRef = useRef(null);
  const scrollRef = useScrollAware(styles.isScrolling);

  const [isCreateMode, setIsCreateMode] = useState(false);

  const hasChartWidget = useMemo(
    () => !!dashboard.widgets.find((widget: any) => ['timeseries', 'pie', 'chart'].includes(widget.config?.type)),
    [dashboard]
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const now = moment();
  const [dateRange, setDateRange] = useState<DateRange>([
    now.clone().subtract(1, 'day').toDate(),
    now.clone().toDate(),
  ]);
  // styling for default range vs. user selected differs
  const [isDateRangeUserSelected, setIsDateRangeUserSelected] = useState(false);

  const updateDashboardMutation = useApiClient<DashboardUpdateSuccessResponse, DashboardWithDetails>('updateDashboard');

  const handleSaveDashboard = useCallback(
    (skipNotification?: boolean) =>
      updateDashboardMutation.mutate(
        dashboard,
        skipNotification
          ? undefined
          : {
              onSuccess: () => {
                dispatch(setNotification({ type: 'success', title: dbTexts.dashboardUpdated }));
                dispatch(setDashboard(dashboard));

                // we cannot know on what page is the dashboard that we just renamed, so we invalidate them all
                queryClient.invalidateQueries(DashboardsQueryKey);
              },
            }
      ),
    [updateDashboardMutation, dashboard, dispatch, dbTexts.dashboardUpdated, queryClient]
  );

  const handleLayoutChange = useCallback(
    (layout: Layout[], layouts: Layouts) => {
      const newDashboard = { ...dashboard, meta: layouts };
      dispatch(updateDashboard(newDashboard));
    },
    [dashboard, dispatch]
  );

  const handleBreakpointChange = useCallback((bp: string) => {
    setActiveBreakpoint(bp);
  }, []);

  const handleWidgetEdit = useCallback(
    (widgetId: string) => {
      navigate(`${ROUTES.widget.replace(':dashboardId', dashboard.id).replace(':widgetId', widgetId)}`);
    },
    [dashboard, navigate]
  );

  const handleWidgetRemove = useCallback(
    (widgetId: string) => {
      void widgetRemoveCommand(dashboard.id, widgetId);
    },
    [dashboard, widgetRemoveCommand]
  );

  const handleWidgetDuplicate = useCallback(
    (widgetId: string) => {
      void widgetDuplicateCommand(dashboard.id, widgetId, v4());
    },
    [dashboard, widgetDuplicateCommand]
  );

  const handleAddWidget = () => {
    setIsCreateMode(true);
  };

  const handleDateRangeChange = (newDateRange: DateRange) => {
    setDateRange(newDateRange);
    setIsDateRangeUserSelected(true);
  };

  const handleDateRangeReset = () => {
    const newNow = moment();
    setDateRange([newNow.clone().subtract(1, 'day').toDate(), newNow.clone().toDate()]);
    setIsDateRangeUserSelected(false);
  };

  const itemRenderer = useCallback(
    (item: Layout) => {
      const widget = dashboard.widgets.find(dbWidget => dbWidget.id === item.i) as DashboardWidget;
      if (widget) {
        const { id, config } = widget;
        return <Widget {...config} id={id} isLocked={dashboardState.isLocked} />;
      }
      return null;
    },
    [dashboard, dashboardState.isLocked]
  );

  const dashboardContext = useMemo<DashboardContextType>(
    () => ({
      dashboard: dashboard,
      onEdit: handleWidgetEdit,
      onRemove: handleWidgetRemove,
      onDuplicate: handleWidgetDuplicate,
      dateRange,
      dashboardRef,
    }),
    [dashboard, handleWidgetEdit, handleWidgetRemove, handleWidgetDuplicate, dateRange]
  );

  const dashboardForm = useForm<DashboardSchema>({
    validationSchema: getSchema(dbTexts),
    initialValues: {
      name: dashboard?.name,
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: () => {
      handleSaveDashboard(true);
    },
  });

  const gridProps = useMemo(
    () => ({
      innerRef: dashboardRef,
    }),
    []
  );

  if (!dashboard) {
    // TODO maybe template handling here too
    return <Error500Page text={dbTexts.dashboardNotFound} />;
  }

  return (
    <FormProvider form={dashboardForm} asPageContainer>
      <DashboardPageHeader
        activeBreakpoint={activeBreakpoint}
        addWidget={handleAddWidget}
        saveDashboard={handleSaveDashboard}
        loading={updateDashboardMutation.isLoading}
        dashboard={dashboard}
        middle={
          hasChartWidget ? (
            <TimeControl
              active={isDateRangeUserSelected}
              dateRange={dateRange}
              onChange={handleDateRangeChange}
              onReset={handleDateRangeReset}
            />
          ) : null
        }
      />
      <div className={styles.dashboardContainer} ref={scrollRef}>
        <DefaultMotion key={dashboard.meta[activeBreakpoint].length === 0 ? 'no-widgets' : 'widgets'} full>
          <DashboardContext.Provider value={dashboardContext}>
            {isCreateMode && <PlaceholderWidget dashboard={dashboard} onClose={() => setIsCreateMode(false)} />}

            {!dashboard.meta[activeBreakpoint].length && !isCreateMode ? (
              <NoElementsContainer
                icon="DashboardWidget"
                iconClass={styles.dashboardWidgetIcon}
                title={dbTexts.startAddingWidgets}
                description={dbTexts.widgetsDescription}
                buttonProps={{
                  label: dbTexts.newWidget,
                  loading: false,
                  onClick: handleAddWidget,
                }}
              />
            ) : (
              <GridLayout
                id="dashboard-layout"
                onLayoutChange={handleLayoutChange}
                onBreakpointChange={handleBreakpointChange}
                layouts={dashboard.meta}
                items={dashboard.meta[activeBreakpoint]}
                itemRenderer={itemRenderer}
                locked={dashboardState.isLocked}
                noResize={!breakpoints.isTablet}
                gridProps={gridProps}
                additionalClass={styles.grid}
              />
            )}
          </DashboardContext.Provider>
        </DefaultMotion>
      </div>
      <DashboardDialogManager />
    </FormProvider>
  );
};

export default withCommands(DashboardDetail);
