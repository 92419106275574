import React, { FC } from 'react';
import { useField } from 'formik';
import { RowHeightPicker, RowHeightPickerProps } from './RowHeightPicker';
import { RowHeight } from '@components/common/Table';

export type RowHeightPickerFieldProps = Omit<RowHeightPickerProps, 'value' | 'defaultValue' | 'onChange'> & {
  name: string;
  defaultValue?: string;
};

export const RowHeightPickerField: FC<RowHeightPickerFieldProps> = ({ defaultValue, size, ...props }) => {
  const [field, , helpers] = useField(props);

  const handleChange = (value: RowHeight) => {
    helpers.setValue(value);
  };

  return <RowHeightPicker size={size} {...props} {...field} onChange={handleChange} />;
};
