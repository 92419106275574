import React from 'react';
import { useLoadingContext } from 'react-router-loading';
import PageHeader from '@components/layout/PageHeader';
import { MetricsTable } from './components/MetricsTable';
import metricsSlice from '@infrastructure/redux/metricsPage/slice';
import { MetricsHeader } from './components/MetricsHeader';

export const MetricsPage = () => {
  const loadingContext = useLoadingContext();

  React.useEffect(() => {
    document.title = `Metrics - BaseN Platform`;
  }, []);

  return (
    <div className="page-container">
      <MetricsHeader />
      <div className="page-body">
        <MetricsTable onInitialLoadComplete={() => loadingContext.done()} actions={metricsSlice.actions} />
      </div>
    </div>
  );
};
