import { getURLQueryParams } from '@utils/url';
import React, { useMemo } from 'react';
import Error404Page from '../Error404Page';
import IconButton from '@components/common/IconButton';
import CopyText from '@components/typography/CopyText';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from '@infrastructure/routes';
import { useLoadingContext } from 'react-router-loading';
import {
  SessionDetailsEntry,
  SessionEntry,
  useSessionByIdQuery,
} from '@infrastructure/api/BaseNClient/useSessionByIdQuery';
import Card from '@components/common/Card/Card';
import { SessionLogPicker } from './components/SessionLogPicker';
import { SessionLogDetails } from './components/SessionLogDetails';
import { updateQueryString } from '@services/useHistory';
import { CodeEditor } from '@components/common/CodeEditor/CodeEditor';
import LoadingSpinner from '@components/layout/LoadingSpinner';
import { formatEpochDate } from '@utils/date';
import { useEntityQuery } from '@infrastructure/api/BaseNClient/useEntityQuery';
import Headline from '@components/typography/Headline';
import { EntityIcon } from '../EntityPage/components/EntityIcon';
import { EntityStatusDot } from '../EntityPage/components/EntityStatusDot';

import styles from './SessionLogPage.module.scss';
import Breadcrumbs from '@components/common/Breadcrumbs';
import { BreadcrumbProps } from '@components/common/Breadcrumbs/Breadcrumb';

type SessionLogPageParams = {
  entityId: string;
  sessionId?: string;
  user?: string;
};

export type SessionLogPageProps = {};

const getRawSessionLog = (data: SessionEntry[]): string => {
  return data.map(entry => entry.content).join('');
};

export const SessionLogPage = () => {
  const [, forceUpdate] = React.useState(0);

  const loadingContext = useLoadingContext();
  const { entityId } = useParams();
  const queryString = getURLQueryParams();
  const navigate = useNavigate();

  // data for this one is partially taken from query string, partially from logbook note and partially from session data
  // that's why it's a ref and not a state and why such a weird structure overall
  const activeItemRef = React.useRef<SessionDetailsEntry | null>(null);

  const entity = useEntityQuery({ entityId });

  const query = useSessionByIdQuery(
    {
      sessionId: queryString?.sessionId!,
      entityId: entityId!,
      user: queryString?.user!,
      rawdata: true,
    },
    {
      enabled: !!(entity.data && queryString?.sessionId && entityId && queryString.user),
    }
  );

  React.useEffect(() => {
    if (entity.data) {
      document.title = `Session log for ${entity.data.name}, on ${formatEpochDate(
        activeItemRef.current?.timestamp
      )}, by ${queryString.user} - BaseN Platform`;
    }
  }, [entityId, entity.data, queryString.user]);

  React.useEffect(() => {
    if (query.data) {
      loadingContext.done();

      activeItemRef.current = {
        ...activeItemRef.current,
        timestamp: query.data?.data[0]?.timestamp,
        user: queryString?.user ?? '',
        entityId: entityId!,
        sessionId: queryString?.sessionId ?? '',
        log: getRawSessionLog(query.data?.data ?? []),
      };

      forceUpdate(Date.now());
    }
  }, [query.data, loadingContext, queryString.user, queryString?.sessionId, entityId]);

  const breadcrumbsProps = useMemo(
    () => ({
      items: queryString?.fromPage === 'global'
        ? ([
          { label: 'Tools', href: ROUTES.tools },
          { label: 'Logbook', href: ROUTES.logbook },
          { label: 'Session logs', current: true }
        ].filter(Boolean) as BreadcrumbProps[])
        : ([
          { label: 'Inventory', href: ROUTES.inventory },
          entity.data ? { label: entity.data.name, href: ROUTES.entityById.replace(':entityId', entityId!).concat('?activeTabTitle=Logbook') } : false,
          { label: 'Session logs', current: true }
        ].filter(Boolean) as BreadcrumbProps[])
    }),
    [entity.data]
  );

  const handleChange = React.useCallback(option => {
    if (!option?.value) {
      return;
    }

    const { timestamp, endtimestamp, user, entityId, sessionId } = option.value;

    activeItemRef.current = {
      ...activeItemRef.current,
      timestamp,
      endtimestamp,
      user,
      entityId,
      sessionId,
      log: '',
    };

    updateQueryString({
      sessionId,
      user,
    } as SessionLogPageParams);
  }, []);

  const handleNavigate = React.useCallback(() => {
    if (queryString?.fromPage === 'global') {
      navigate(ROUTES.logbook);
    } else {
      navigate(ROUTES.entityById.replace(':entityId', entityId!).concat('?activeTabTitle=Logbook'))
    }
  }, []);

  if (!entityId || query.isError) {
    return <Error404Page />;
  }

  return (
    <div className="page-container">
      <div className="page-header">
        <div className={styles.headerSection}>
          <Breadcrumbs {...breadcrumbsProps} additionalClasses={{ root: styles.breadcrumbs }} />
        </div>
        {entity.data && (
          <div className="w-full flex justify-center">
            <Headline variant="headline-6" additionalClass="hbox">
              <EntityIcon entity={entity.data} />
              {entity.data?.name}
              <EntityStatusDot entity={entity.data} />
            </Headline>
          </div>
        )}
      </div>

      <div className="page-body">
        <div className="items-stretch h-full gap-16 hbox">
          <Card header="Session Logs" additionalClass={styles.sidebar}>
            <div className="vbox">
              <SessionLogPicker
                entityId={entityId}
                placeholder="Select a log..."
                clearable={false}
                value={activeItemRef.current}
                onChange={handleChange}
                rowHeight={24}
              />

              {activeItemRef.current && <SessionLogDetails entry={activeItemRef.current} />}
            </div>
          </Card>

          <Card header="Session record" additionalClass={styles.sessionLog} noPadding>
            {query.isLoading ? (
              <LoadingSpinner centered additionalClass="p-20" />
            ) : (
              <CodeEditor
                value={getRawSessionLog(query.data?.data ?? [])}
                readOnly
                additionalClass={styles.codeEditor}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};
