import React, { FC } from 'react';
import c from 'classnames';
import { ceil, sumBy } from 'lodash';
import { clampStripes, colorToRange, Stripe, valueToColor } from '@utils/issue';
import Tooltip from '@components/common/Tooltip';

import styles from './AlertBar.module.scss';
import alertStyles from './Alert.module.scss';

const toPercentage = (value: number, totalValue: number) => Math.min(ceil((100 / totalValue) * value, 2), 100);

export type AlertBarProps = {
  stripes: Stripe[];
  /** whether the component should produce bars of the same width */
  cappedWidth?: boolean;
  showTooltipOnHover?: boolean;
  clampBy?: 'color' | 'value';
  variant?: 'default' | 'silent' | 'parentSilent' | 'maintenance';
  additionalClass?: string;
};

export const AlertBar: FC<AlertBarProps> = ({
  stripes,
  cappedWidth = false,
  clampBy = 'color',
  showTooltipOnHover = true,
  additionalClass,
  variant = 'default',
}) => {
  const summaryDuration = sumBy(stripes, 'duration');
  const clampedStripes = clampStripes(stripes, clampBy);

  const hasOverlay = variant !== 'default';

  return (
    <div className={c(styles.alertBar, styles[variant], hasOverlay && styles.hasOverlay, additionalClass)}>
      {clampedStripes.map(({ duration, value }, idx) => {
        const color = valueToColor(value);
        const props = {
          className: c(
            styles.stripe,
            alertStyles[color],
            alertStyles[`${color}-bg`],
            !cappedWidth && `w-${duration * 4}`
          ),
          style: cappedWidth ? { width: `${toPercentage(duration, summaryDuration)}%` } : undefined,
        };

        return showTooltipOnHover ? (
          <Tooltip
            key={idx.toString()}
            position="top"
            content={`${duration}min(s), ${clampBy === 'value' ? value ?? '-' : colorToRange(color)}`}
            renderTarget={({ className, isOpen, ...targetProps }) => (
              <div {...targetProps} {...props} className={c(props.className, className)} />
            )}
          />
        ) : (
          <div key={idx.toString()} {...props} />
        );
      })}
    </div>
  );
};
