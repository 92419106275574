/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import c from 'classnames';
import { useAppDispatch } from '@infrastructure/redux/store';
import {
  UpdateIssueParams,
  UpdateIssueSuccessResponse,
  IssueWithDetails,
  getIssueById,
  updateIssue,
} from '@redux/issues';
import CopyText from '@components/typography/CopyText';
import useTextSnippets from '@services/useTextSnippets';
import { formatEpochDate } from '@utils/date';
import { DefaultMotion } from '@services';
import IconButton from '@components/common/IconButton';
import Button from '@components/common/Button';
import useForm, { FormProvider } from '@components/common/form/useForm';
import getSchema, { CommentSchema } from './Comment.schema';
import { useApiClient } from '@infrastructure/api/useApiClient';
import { setNotification } from '@redux/notifications';
import { TextField } from '@components/common/form/Input/TextField';
import { useMutation } from 'react-query';
import Gravatar from '@components/common/Gravatar';
import { useAccount } from '@infrastructure/redux/auth';
import { Linkify } from '@components/common/Link';

import styles from './IssueDetailsDrawer.module.scss';

export type IssueCommentsProps = { issue: IssueWithDetails; isDetailPage?: boolean; additionalClass?: string };

const IssueComments: React.FC<IssueCommentsProps> = ({ issue, isDetailPage, additionalClass }) => {
  const dispatch = useAppDispatch();
  const account = useAccount();
  const [showAddCommentForm, setShowAddCommentForm] = useState<boolean>(false);
  const { noComments, addComment } = useTextSnippets('issues');
  const commentsI18n = useTextSnippets('comment');
  const issueComments = (issue?.events || []).filter(event => event.queue === 'COMMENT');
  const hasComments = issueComments.length > 0;
  const updateIssueMutation = useApiClient<UpdateIssueSuccessResponse, UpdateIssueParams>('updateIssue');

  const issueQuery = useMutation<IssueWithDetails>('issueById', () => getIssueById(issue!.id), {
    onSuccess: data => {
      dispatch(updateIssue(data));
    },
  });

  const commentForm = useForm<CommentSchema>({
    validationSchema: getSchema(commentsI18n),
    initialValues: { comment: '' },
    enableReinitialize: true,
    onSubmit: (values: CommentSchema) => {
      updateIssueMutation.mutate(
        {
          id: issue!.id,
          comment: values.comment,
          // title: values.title,
        },
        {
          onSuccess: () => {
            setShowAddCommentForm(false);
            commentForm.resetForm();
            issueQuery.mutate();
            dispatch(setNotification({ type: 'success', title: commentsI18n.commentSaved }));
          },
          onError: () => {
            dispatch(setNotification({ type: 'error', title: commentsI18n.commentSaveFail }));
          },
        }
      );
    },
  });

  const cancelCommentAdd = useCallback(() => {
    setShowAddCommentForm(false);
    commentForm.resetForm();
  }, [commentForm]);

  const handleShowAddCommentForm = useCallback(() => {
    setShowAddCommentForm(true);
  }, []);

  return (
    <div className={c(styles.issueComments, additionalClass)}>
      <DefaultMotion
        key={showAddCommentForm ? 'addComment' : 'viewComments'}
        className={c(styles.commentsContainer, !hasComments && styles.empty, isDetailPage && styles.isDetailPage)}
      >
        <div className="w-full mb-16">
          {(isDetailPage || !showAddCommentForm) &&
            hasComments &&
            issueComments.map((issueComment, idx) => (
              <div key={idx} className="flex flex-row items-center w-full mb-16">
                {account?.username === issueComment.user ? (
                  <Gravatar email={account?.email} username={account?.name ?? account?.username} rating="g" size={64} />
                ) : (
                  <Gravatar email={issueComment.user} rating="g" size={64} />
                )}
                <div className="flex flex-col">
                  <div className="flex flex-row items-center mb-4">
                    {issueComment.user && (
                      <CopyText variant="copy-5" additionalClass="mr-8">
                        {issueComment.user}
                      </CopyText>
                    )}
                    <CopyText variant="copy-8" additionalClass="text-blue-gray-base">
                      {formatEpochDate(issueComment.time, 'DD/MM/YY, hh:mm:ss')}
                    </CopyText>
                  </div>
                  <pre className="whitespace-pre-wrap copy-6">
                    <Linkify target="_blank" rel="noopener noreferrer">
                      {issueComment.description}
                    </Linkify>
                  </pre>
                </div>
              </div>
            ))}

          {!hasComments && (
            <CopyText variant="copy-4" additionalClass="text-gray-4">
              {noComments}
            </CopyText>
          )}

          {!isDetailPage && !showAddCommentForm && (
            <Button variant="outline" additionalClass="my-20 mr-auto" size="s" onClick={handleShowAddCommentForm}>
              {addComment}
            </Button>
          )}
        </div>

        {(isDetailPage || showAddCommentForm) && (
          <FormProvider form={commentForm} additionalClass={styles.commentForm}>
            {!isDetailPage && (
              <IconButton icon="Close" additionalClass={styles.closeCommentIcon} onClick={cancelCommentAdd} size="xs" />
            )}
            <div className={styles.commentEditorContainer}>
              <TextField
                value={commentForm.values.comment}
                name="comment"
                multiline
                noInfo
                placeholder={commentsI18n.commentPlaceholder}
              />
              <Button
                variant="fillBlueDark"
                size="s"
                type="submit"
                loading={updateIssueMutation.isLoading}
                additionalClass={c('mb-10 mt-16 mr-auto')}
              >
                {commentsI18n.publishComment}
              </Button>
            </div>
          </FormProvider>
        )}
      </DefaultMotion>
    </div>
  );
};

export default IssueComments;
