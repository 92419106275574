import React, { createContext, useContext } from 'react';

export type WizardContextType<T = any> = {
  valuesRef: React.RefObject<T>;
};

export const WizardContext = createContext<WizardContextType>({
  valuesRef: React.createRef(),
});

export function useWizardContext<T = any>(): WizardContextType<T> {
  return useContext(WizardContext);
}
