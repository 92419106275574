import { createSlice } from '@reduxjs/toolkit';
import { EntityDetails, InventoryPageState } from './types';
import { createDataTableReducers, createInitialDataTableState } from '@utils/redux';

import { INVENTORY_TABLE_ID } from './selectors';

const initialState: InventoryPageState = createInitialDataTableState(INVENTORY_TABLE_ID);

const inventoryPageSlice = createSlice({
  name: 'inventoryPage',
  initialState,
  reducers: createDataTableReducers<EntityDetails>(initialState),
});

export const { updateItems, updatePagination, updateRowHeight, updateSorting, updateSearch, updateFilters, reset } =
  inventoryPageSlice.actions;

export default inventoryPageSlice;
