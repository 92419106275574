import { DetailsPanelProps } from '@components/layout/TablePageLayout/DetailsPanel';
import { InterfacesWidget } from '@components/widgets/InterfacesWidget/InterfacesWidget';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import React, { FC } from 'react';
import { EntityInfoGrid } from './EntityInfoGrid';

import styles from './ActiveItemDetails.module.scss';

export type ActiveItemDetailsProps = DetailsPanelProps & {
  item?: EntityDetails;
};

export const ActiveItemDetails: FC<ActiveItemDetailsProps> = ({ item }) => {
  return (
    <>
      <EntityInfoGrid entity={item} size="l" />

      <InterfacesWidget
        additionalClass={styles.detailsPanelWidget}
        config={{
          source: {
            entityId: item?.id,
          },
        }}
      />
    </>
  );
};
