import React, { ChangeEventHandler, useMemo, useRef } from 'react';
import PageHeader, { PageHeaderProps } from '@components/layout/PageHeader';
import { useAppDispatch, useAppSelector } from '@infrastructure/redux/store';
import { Dashboard, selectCurrentDashboardState, TEMP_WIDGET_ID, toggleLock, updateDashboard } from '@redux/dashboards';
import useTextSnippets from '@services/useTextSnippets';
import IconButton from '@components/common/IconButton';
import Popover from '@components/common/Popover';
import { useFormikContext } from 'formik';
import { DashboardSchema } from '@redux/dashboards/dashboardSchema';
import DashboardsMenu from '../DashboardsMenu/DashboardsMenu';
import withCommands, { WithCommands } from '@services/withCommands';

import styles from './DashboardPageHeader.module.scss';

type DashboardPageHeaderProps = PageHeaderProps & {
  loading?: boolean;
  activeBreakpoint: string;
  dashboard: Dashboard;
  addWidget: () => void;
  saveDashboard: (skipNotification?: boolean) => void;
} & WithCommands;

const DashboardPageHeader: React.FC<DashboardPageHeaderProps> = ({
  loading,
  dashboard,
  activeBreakpoint,
  addWidget,
  saveDashboard,
  openFavoritesMenuCommand,
  ...props
}) => {
  const dashboardForm = useFormikContext<DashboardSchema>();
  const dispatch = useAppDispatch();
  const dbTexts = useTextSnippets('dashboards');
  const commonTexts = useTextSnippets('common');
  const dashboardMenuRef = useRef<HTMLElement>(null);
  const dashboardState = useAppSelector(selectCurrentDashboardState);
  const isFavorite = dashboard.categories?.find(({ category_type }) => category_type === 'favorite');

  const handleToggleFavoriteDashboard = (e: React.MouseEvent<HTMLElement>) => {
    openFavoritesMenuCommand({
      dashboardId: dashboard.id,
      targetEl: e.target as HTMLElement,
    });
  };

  const handleLockToggle = () => {
    dispatch(toggleLock());
  };

  // const onToggleFullScreenDashboard = () => {
  // TODO
  // };

  const onDashboardNameChange: ChangeEventHandler<HTMLInputElement> = e => {
    dispatch(updateDashboard({ ...dashboard, name: e.currentTarget.value }));
  };

  const controlsDisabled = useMemo(
    () => !!dashboard?.meta[activeBreakpoint]?.find(layout => layout.i === TEMP_WIDGET_ID),
    [dashboard, activeBreakpoint]
  );

  return (
    <PageHeader
      {...props}
      level={2}
      breadcrumbs
      breadcrumbsProps={{
        omitRootLabel: true,
        inputError: dashboardForm.errors.name,
        renderLastAsInput: true,
        breadcrumbInputValue: dashboard.name,
        onLastBreadcrumbChange: onDashboardNameChange,
        onLastBreadcrumbBlur: dashboardForm.submitForm,
      }}
      pageControls={[
        {
          id: 'newWidget',
          onClick: addWidget,
          ariaLabel: 'Add Widget',
          icon: 'ControlPointOutlined',
          label: dbTexts.newWidget,
          disabled: controlsDisabled,
        },
        {
          id: 'save',
          variant: 'fillBlueDark',
          onClick: () => saveDashboard(false),
          loading,
          ariaLabel: 'Save Dashboard',
          label: commonTexts.save,
          icon: 'Save',
          hideIconOnDesktop: true,
          disabled: controlsDisabled || !dashboardState.dirty || Object.keys(dashboardForm.errors).length > 0,
        },
      ]}
      right={
        <div className={styles.rightIconContainer}>
          <IconButton
            icon={isFavorite ? 'StarFill' : 'StarOutline'}
            additionalClass={styles.desktopIcon}
            onClick={handleToggleFavoriteDashboard}
          />
          <IconButton
            icon={`${dashboardState.isLocked ? 'Lock' : 'LockOpen'}`}
            onClick={handleLockToggle}
            additionalClass="no-mobile"
          />

          {/* <IconButton icon="Fullscreen" additionalClass={styles.desktopIcon} onClick={onToggleFullScreenDashboard} /> */}
          <Popover
            position="bottom"
            content={<DashboardsMenu item={dashboard} />}
            additionalClass={styles.dashboardPopover}
            noArrows
            popoverRef={dashboardMenuRef}
            preserveTarget
          >
            <IconButton icon="MoreVertical" />
          </Popover>
        </div>
      }
    />
  );
};

export default withCommands(DashboardPageHeader);
