import { AppState } from '@infrastructure/redux/store';
import { getURLQueryParams } from '@utils/url';
import { createSelector } from 'reselect';
import { LocationState } from './types';

export const selectSliceRoot = (state: AppState): LocationState => state.location;

export const selectLocation = createSelector(selectSliceRoot, _ => _.location);
export const selectPrevLocation = createSelector(selectSliceRoot, _ => _.prevLocation);
export const selectPrevDocumentTitle = createSelector(selectSliceRoot, _ => _.prevDocumentTitle);
export const selectQueryStringParams = createSelector(selectLocation, _ => getURLQueryParams(_));
