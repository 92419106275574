import { useMutation, UseMutationResult } from 'react-query';
import { apiClient } from '@infrastructure/api';
import ENDPOINTS from '@infrastructure/api/endpoints';
import { GenericNotOkApiResponse, GenericOkApiResponse } from '../types';

export type FavoriteFolderCreateRequest = {
  name: string;
};

export type FavoriteFolderCreateResponse = GenericOkApiResponse & {
  id: number;
};

export const useFavoriteFolderCreateMutation = <
  S extends GenericOkApiResponse = FavoriteFolderCreateResponse,
  F extends GenericNotOkApiResponse = GenericNotOkApiResponse,
  V extends FavoriteFolderCreateRequest = FavoriteFolderCreateRequest
>(
  mutationOptions = {}
): UseMutationResult<S, F, V> => {
  return useMutation<S, F, V>(
    (body: V): Promise<S> =>
      apiClient.post(ENDPOINTS.categories, { ...body, category_type: 'favorite', target_type: 'dashboard' }),
    mutationOptions
  );
};
