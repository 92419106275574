/* eslint-disable no-param-reassign */
import invariant from '@utils/invariant';
import { DashboardWidget } from '@redux/widgetPage/types';
import { cloneDeep, reject } from 'lodash';
import { Layout, Layouts } from 'react-grid-layout';
import { TEMP_WIDGET_ID, TEMP_WIDGET_LAYOUT, WIDGET_META_CONFIGS } from './configs';
import { DashboardWithDetails, FullLayout } from './types';

export type ResizeHandle = 's' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne';
export type CompactionType = 'vertical' | 'horizontal' | null | undefined;

export function getWidgetById(dashboard: DashboardWithDetails, widgetId: string, returnClone = true) {
  const widget = dashboard.widgets.find(({ id }) => id === widgetId);

  invariant(widget, `Widget ${widgetId} cannot be found in Dashboard ${dashboard.id}`);

  return returnClone ? cloneDeep(widget) : widget;
}

function addLayoutToBreakpoint(layouts: Layout[], newLayout: FullLayout | Layout, toTop = true): Layout[] {
  return !toTop
    ? // add to bottom
      [...layouts, { ...newLayout, i: (newLayout as Layout).i ?? TEMP_WIDGET_ID, x: 0, y: Infinity }]
    : // otherwise add to top and shift all other items down by new items height
      [
        ...layouts.map(existingLayout => ({
          ...existingLayout,
          y: existingLayout.y + (newLayout.h ?? WIDGET_META_CONFIGS.defaultWidget.minH),
        })),
        { ...newLayout, i: (newLayout as Layout).i ?? TEMP_WIDGET_ID, x: 0, y: 0 },
      ];
}

export function addLayoutToLayouts(
  layouts: Layouts,
  newOrLayout: FullLayout | Layout | null = null,
  toTop: boolean = true
) {
  const layout = newOrLayout ?? cloneDeep(TEMP_WIDGET_LAYOUT);

  return Object.keys(layouts).reduce((newLayouts, bp) => {
    // for temporary widgets, on non-xxs (smallest) breakpoint, add some extra size, make it look better
    if ((layout as Layout).i === TEMP_WIDGET_ID && bp !== 'xxs') {
      layout.w = 6;
    }

    return { ...newLayouts, [bp]: addLayoutToBreakpoint(layouts[bp], layout, toTop) };
  }, {});
}

export function replaceWidgetIdInLayouts(bps: Layouts, widgetId: string, widgetIdToReplace = TEMP_WIDGET_ID) {
  return Object.keys(bps).reduce((newMeta, bp) => {
    const layouts: Layout[] = bps[bp];

    return {
      ...newMeta,
      [bp]: layouts.map((layout: Layout) => {
        if (layout.i === widgetIdToReplace) {
          return { ...layout, i: widgetId };
        } else {
          return layout;
        }
      }),
    };
  }, {}) as Layouts;
}

export function removeLayoutFromBreakpoint(layouts: Layout[], layout: Layout | string) {
  const id = typeof layout === 'string' ? layout : layout.i;
  return reject(layouts, { i: id });
}

function removeLayoutFromLayouts(layouts: Layouts, id: string) {
  return Object.keys(layouts).reduce(
    (newLayouts, bp) => ({ ...newLayouts, [bp]: removeLayoutFromBreakpoint(layouts[bp], id) }),
    {}
  );
}

export function addTempWidgetToDashboard(dashboard: DashboardWithDetails, toTop = true) {
  // temp widget is added as meta only, widget itself is missing
  return addWidgetToDashboard(dashboard, undefined, toTop);
}

export function addWidgetToDashboard(dashboard: DashboardWithDetails, widget?: DashboardWidget, toTop = true) {
  const newDashboard = cloneDeep(dashboard);
  newDashboard.meta = addLayoutToLayouts(
    newDashboard.meta,
    { ...TEMP_WIDGET_LAYOUT, i: widget?.id ?? TEMP_WIDGET_ID },
    toTop
  );

  if (widget) {
    const newWidget = cloneDeep(widget);
    newDashboard.widgets.unshift(newWidget);
  }

  return newDashboard;
}

/**
 * Find a widget in the dashboard by id (default is TEMP_WIDGET_ID) and replace it with a new DashboardWidget
 * instance. In case of TEMP_WIDGET_ID, entry might be only present in `meta` prop (result of `addWidgetToDashboard`)
 * or in `meta` and in `widgets` array (result of `duplicateWidgetWithId`)
 * @param dashboard
 * @param newWidget DashboardWidget to replace given widget with, if found
 * @param widgetIdToReplace
 * @returns
 */
export function replaceDashboardWidget(
  dashboard: DashboardWithDetails,
  newWidget: DashboardWidget,
  widgetIdToReplace = TEMP_WIDGET_ID
) {
  const newDashboard = cloneDeep(dashboard);

  const idx = dashboard.widgets.findIndex(({ id }) => id === widgetIdToReplace);

  if (idx < 0) {
    // only dashboard with temporary widget might not contain widget instance in widgets prop
    invariant(
      widgetIdToReplace === TEMP_WIDGET_ID,
      `Widget ${newWidget.id} cannot be found in Dashboard ${dashboard.id}`
    );

    newDashboard.widgets.unshift(cloneDeep(newWidget));
  } else {
    newDashboard.widgets[idx] = cloneDeep(newWidget);
  }

  newDashboard.meta = replaceWidgetIdInLayouts(newDashboard.meta, newWidget.id, widgetIdToReplace);

  return newDashboard;
}

export function removeWidgetFromDashboard(dashboard: DashboardWithDetails, widgetId: string) {
  const newDashboard = cloneDeep(dashboard);

  newDashboard.meta = removeLayoutFromLayouts(newDashboard.meta, widgetId);
  newDashboard.widgets = newDashboard.widgets.filter(({ id }) => id !== widgetId);

  return newDashboard;
}

export function duplicateWidgetWithId(
  dashboard: DashboardWithDetails,
  widgetId: string,
  newWidgetId: string = TEMP_WIDGET_ID
) {
  const widget = getWidgetFromDashboard(dashboard, widgetId);

  invariant(widget?.id, `Failed to duplicate - widget with such id doesn't exist!`);

  return addWidgetToDashboard(dashboard, { ...cloneDeep(widget), id: newWidgetId });
}

export function getWidgetFromDashboard(
  dashboard: DashboardWithDetails,
  widgetId: string = TEMP_WIDGET_ID
): DashboardWidget | null {
  return dashboard.widgets.find(({ id }) => id === widgetId) as DashboardWidget;
}

export function hasTempWidget(dashboard: DashboardWithDetails) {
  return !!getWidgetFromDashboard(dashboard);
}

export function replaceIdWithName(string: string, obj: { id: string; name: string }) {
  const idRegex = new RegExp(`${obj.id}|${obj.id.replace(/-/g, ' ')}`, 'g');

  if (idRegex.exec(string)) {
    return obj.name;
  }

  return string;
}

/**
 * Given two layoutitems, check if they collide.
 */
function collides(l1: Layout, l2: Layout): boolean {
  if (l1.i === l2.i) return false; // same element
  if (l1.x + l1.w <= l2.x) return false; // l1 is left of l2
  if (l1.x >= l2.x + l2.w) return false; // l1 is right of l2
  if (l1.y + l1.h <= l2.y) return false; // l1 is above l2
  if (l1.y >= l2.y + l2.h) return false; // l1 is below l2
  return true; // boxes overlap
}

export function validateDashboard(db: DashboardWithDetails) {
  Object.keys(db.meta).forEach(bp => {
    try {
      throwIfItemsCollide(db.meta[bp]);
    } catch (ex) {
      throw new Error(`Dashboard '${db.id}' invalid layout for '${bp}' breakpoint: ${(ex as Error).message}`);
    }
  });
}

export function throwIfItemsCollide(layouts: Layout[]) {
  for (let i = 0; i < layouts.length - 1; i++) {
    for (let ii = i + 1; ii < layouts.length; ii++) {
      invariant(!collides(layouts[i], layouts[ii]), `widget '${layouts[i].i}' collides with widget '${layouts[ii].i}'`);
    }
  }
}

export function fixInvalidBreakpoint(layouts: Layout[]): Layout[] {
  for (let i = 0; i < layouts.length - 1; i++) {
    for (let ii = i + 1; ii < layouts.length; ii++) {
      if (Number.isNaN(layouts[i].x) || Number.isNaN(layouts[i].y) || collides(layouts[i], layouts[ii])) {
        const newLayouts = addLayoutToBreakpoint(removeLayoutFromBreakpoint(layouts, layouts[i]), layouts[i]);

        return fixInvalidBreakpoint(newLayouts);
      }
    }
  }
  return layouts;
}

export function fixInvalidLayouts(layouts: Layouts): Layouts {
  return Object.keys(layouts).reduce(
    (newLayouts, bp) => ({ ...newLayouts, [bp]: fixInvalidBreakpoint(layouts[bp]) }),
    {}
  );
}
