import React from 'react';
import c from 'classnames';
import SwitchBase, { SwitchBaseProps } from '../SwitchBase/SwitchBase';
import { TabProps } from './Tab';

import styles from './style.module.scss';

type PassThroughProps = Pick<TabProps, 'disabled'>;
export type TabsProps = Omit<SwitchBaseProps<TabProps, PassThroughProps>, 'exclusive'> & {
  additionalClass?: string;
};

const Tabs: React.FC<TabsProps> = ({ additionalClass, ...props }) => (
  <SwitchBase<TabProps, PassThroughProps> exclusive {...props} additionalClass={c(styles.tabs, additionalClass)} />
);

export default Tabs;
