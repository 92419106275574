import NoElementsContainer from '@components/common/NoElementsContainer/NoElementsContainer';
import React, { FC, useState } from 'react';

export const NoMWs: FC<{ onCreateNew?: () => void }> = ({ onCreateNew }) => {
  const [isLoading, setIsloading] = useState(false);

  const handleCreateNew = async () => {
    setIsloading(true);
    onCreateNew?.();
    setIsloading(false);
  };

  return (
    <NoElementsContainer
      icon="AddMaintenanceWindow"
      title="Create a Maintenance Window"
      description="When a Maintenance Window is active all alerts of the selected entities will be disable. Create a maintenance window with multiple schedules. "
      buttonProps={{
        loading: isLoading,
        label: 'Create Maintenance Window',
        onClick: handleCreateNew,
      }}
    />
  );
};
