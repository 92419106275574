import React from 'react';
import { Table, TableColumn, TableColumns, TableConfig } from '@components/common/Table';
import { toIdMap } from '@utils/misc';
import { AlertBar } from '@components/connected/IssueManager/components/AlertBar/AlertBar';
import { timesAndValuesToStripes } from '@utils/issue';
import { AlertChannel } from '@infrastructure/api/BaseNClient/useAlertConfigsQuery';
import { getAlertChannelStats } from '@utils/alert';
import { AlertChannelsIndicator } from './AlertChannelsIndicator';
import Button from '@components/common/Button';
import IconButton from '@components/common/IconButton';
import { Position } from '@blueprintjs/core';

import styles from './ChannelTable.module.scss';

export const getTableColumns = (onOverrideClick?: (channel: AlertChannel) => void): TableColumns<AlertChannel> => {
  return toIdMap(
    [
      {
        id: 'status',
        label: 'Status',
        width: 250,
        sortable: true,
        valueRenderer: (channel: AlertChannel) => {
          return <AlertChannelsIndicator channels={[channel]} showBadge />;
        },
      },
      {
        id: 'info.channel',
        label: 'Name',
        valueRenderer: ({ info }: AlertChannel) => {
          return info.channel;
        },
      },
      {
        id: 'history',
        label: 'History',
        valueRenderer: (channel: AlertChannel) => {
          const { hasSilent } = getAlertChannelStats([channel]);

          return channel.times && channel.greenreds ? (
            <AlertBar
              stripes={timesAndValuesToStripes(channel.times, channel.greenreds)}
              variant={hasSilent ? 'silent' : 'default'}
            />
          ) : null;
        },
      },
      !!onOverrideClick && {
        id: 'actions',
        label: '',
        valueRenderer: (channel: AlertChannel) => {
          return (
            <IconButton
              icon="SingleOverride"
              title="Channel Override"
              tooltipProps={{ targetProps: { className: 'ml-auto' }, position: Position.TOP }}
              onClick={() => onOverrideClick?.(channel)}
            />
          );
        },
      },
    ].filter(Boolean) as TableColumn<AlertChannel>[]
  );
};

type ChannelTableProps = {
  channels: AlertChannel[];
  onOverride?: (channel: AlertChannel) => void;
};

export const ChannelTable: React.FC<ChannelTableProps> = ({ channels = [], onOverride }) => {
  const stats = getAlertChannelStats(channels);

  const [showNonAlerting, setShowNonAlerting] = React.useState(false);

  const handleFilter = React.useCallback(() => {
    setShowNonAlerting(state => !state);
  }, []);

  const filteredChannels = React.useMemo(
    () => channels.filter(channel => showNonAlerting || channel.color === 'red'),
    [channels, showNonAlerting]
  );

  const columns = React.useMemo(() => getTableColumns(onOverride), [onOverride]);

  const tableConfig: TableConfig = React.useMemo(
    () => ({
      sortable: false,
      paginated: false,
      selectable: false,
      hasToolbar: false,
      bordered: false,
    }),
    []
  );

  return (
    <>
      <Table<AlertChannel>
        items={filteredChannels}
        columns={columns}
        config={tableConfig}
        additionalClasses={{
          tableContainer: styles.tableContainer,
        }}
      />
      {stats.nonAlerting > 0 && (
        <Button onClick={handleFilter} variant="fillOutline" size="s" additionalClass="mt-20">
          {showNonAlerting ? 'Hide non-alerting channels' : 'Show non-alerting channels'}
        </Button>
      )}
    </>
  );
};
