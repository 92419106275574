import { useField } from 'formik';
import { MetricsTagInput, MetricsTagInputProps } from './MetricsTagInput';

export type MetricsTagInputFieldProps = Omit<MetricsTagInputProps, 'value' | 'onChange'> & {
  name: string;
  metricset?: string;
};

export function MetricsTagInputField({ name, ...props }: MetricsTagInputFieldProps) {
  const [field, , helpers] = useField<string[]>({ name });

  const handleChange = (items: string[]) => {
    helpers.setValue(items);
  };

  return <MetricsTagInput {...props} {...field} onChange={handleChange} />;
}
