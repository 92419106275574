import React from 'react';
import CopyText from '@components/typography/CopyText';
import useTextSnippets from '@services/useTextSnippets';
import fillTemplate from '@utils/fillTemplate';
import Button from '@components/common/Button';
import { StaticPage } from '../StaticPage/StaticPage';
import Icon from '@components/common/Icon';
import Headline from '@components/typography/Headline';

import styles from './ErrorPage.module.scss';

export type ErrorPageProps = {
  headline?: string | React.ReactNode;
  description?: string | React.ReactNode;
  onReset?: () => void;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({ headline, description, onReset }) => {
  const i18n = useTextSnippets('error');

  return (
    <StaticPage additionalClass={styles.errorPage}>
      <Icon name="NothingWorks" additionalClass={styles.icon} />
      <Headline variant="headline-2">{headline ?? i18n.oops}</Headline>

      <CopyText variant="copy-2" additionalClass="mb-24">
        {description ?? fillTemplate(i18n.contactUsOnError, { contactUs: i18n.contactUs })}
      </CopyText>

      <Button variant="fillBlueDark" onClick={onReset} additionalClass="mb-64">
        {i18n.reloadPage}
      </Button>

      <CopyText variant="copy-4" additionalClass="text-blue-gray-base">
        {i18n.takeABreak}
      </CopyText>
    </StaticPage>
  );
};
