import React, { ReactElement, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormikProps } from '@components/common/form';

export type ValidationHelperProps<Schema> = {
  children?: ((props: FormikProps<Schema>) => React.ReactNode) | React.ReactNode;
  setCanValidate: (canValidate: boolean) => void;
};

export default function ValidationHelper<Schema>({ children, setCanValidate }: ValidationHelperProps<Schema>) {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { isSubmitting } = useFormikContext<Schema>();

  useEffect(() => {
    if (!submitted && isSubmitting) {
      setSubmitted(true);
      setCanValidate(true);
    }
  }, [setCanValidate, isSubmitting, submitted]);

  return children as ReactElement<FormikProps<Schema>> | null;
}
