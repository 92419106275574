import { Form, SubmitButton, TextField } from '@components/common/form';
import styles from './AuthForm.module.scss';
import CopyText from '@components/typography/CopyText';
import Headline from '@components/typography/Headline';
import Icon from '@components/common/Icon';
import React from 'react';
import { useConnectionCheckMutation } from '@infrastructure/api/BaseNClient/useConnectionCheckMutation';
import { authFormSchema, initialAuthValues, AuthFormSchema } from './ReviewStep.schema';
import { useAppDispatch } from '@infrastructure/redux/store';
import { setNotification } from '@infrastructure/redux/notifications';

type AuthFormProps = {
  entityId: string;
  onSuccess?: (values: AuthFormSchema) => void;
};

export const AuthForm = ({ entityId, onSuccess }: AuthFormProps) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const dispatch = useAppDispatch();

  const checkMutation = useConnectionCheckMutation();

  const handleSubmit = React.useCallback(
    (values, { setSubmitting }) => {
      checkMutation.mutate(
        { ...values, entityId },
        {
          onSuccess: res => {
            setSubmitting(false);
            if (res.result === 'ok') {
              setIsChecked(true);
              onSuccess?.(values);
            } else {
              dispatch(
                setNotification({
                  type: 'error',
                  title: 'Failed to verify credentials',
                  description: res.message,
                })
              );
            }
          },
          onError: error => {
            setSubmitting(false);
            dispatch(
              setNotification({
                type: 'error',
                title: 'Failed to verify credentials',
                description: `There was an error while verifying credentials: ${error.message}`,
              })
            );
          },
        }
      );
    },
    [checkMutation, entityId, onSuccess, dispatch]
  );

  return !isChecked ? (
    <Form
      validationSchema={authFormSchema}
      initialValues={initialAuthValues}
      enableReinitialize
      additionalClass={styles.authForm}
      onSubmit={handleSubmit}
    >
      <Headline additionalClass="mb-16 text-black text-center" variant="headline-6">
        Credentials
      </Headline>

      <CopyText variant="copy-2" additionalClass="text-center mb-32">
        Please add SSH credentials to use for connecting to target devices. Credentials will be verified on the first
        target device.
      </CopyText>

      <div className={styles.fields}>
        <TextField size="l" label="Username" name="username" autoComplete="username" />

        <TextField size="l" label="Password" type="password" name="password" autoComplete="current-password" animate />

        <SubmitButton size="l" variant="fillBlueDark" fullWidth>
          <Icon name="FirewallSecurity" additionalClass="mr-4" size="s" />
          Verify credentials
        </SubmitButton>
      </div>
    </Form>
  ) : (
    <div className={styles.authForm}>
      <Headline additionalClass="mb-16 text-black text-center" variant="headline-6">
        Valid Credentials
      </Headline>
      <CopyText variant="copy-2" additionalClass="text-center mb-32">
        Your credentials are valid! You can run the job now.
      </CopyText>

      <Icon name="WorkstationUnlocked" additionalClass={styles.checkedIcon} />
    </div>
  );
};
