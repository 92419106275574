import React from 'react';
import c from 'classnames';

import styles from './ToggleButton.module.scss';

export type ToggleButtonProps = {
  active?: boolean;
  disabled?: boolean;
  variant?: 'normal' | 'borderless' | 'icon';
  round?: boolean;
  size?: 's' | 'm' | 'l';
  additionalClass?: string;
  onChange?: (e: React.MouseEvent<HTMLElement>, state: boolean) => void;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  active,
  disabled,
  variant = 'normal',
  round = true,
  size = 'm',
  additionalClass,
  onChange,
  children,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    onChange?.(e, !active);
  };

  return (
    <button
      type="button"
      className={c(
        styles.toggleButton,
        {
          active,
          [styles.active]: active,
          [styles.disabled]: disabled,
          [styles.round]: round,
          [styles.small]: size === 's',
          [styles.medium]: size === 'm',
          [styles.large]: size === 'l',
        },
        styles[variant],
        additionalClass
      )}
      onClick={!disabled ? handleClick : undefined}
    >
      {children}
    </button>
  );
};

export default ToggleButton;
