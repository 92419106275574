import React from 'react';
import { getInfoWidget } from './getWidgets';
import { Widget } from '@components/layout/Widget';
import c from 'classnames';
import { EntityDetails } from '@infrastructure/redux/inventoryPage';
import { hasAtLeastOneProperty } from '@utils/entity';

import styles from './OverviewTab.module.scss';

export const OverviewTab: React.FC<{ entity: EntityDetails }> = ({ entity }) => {
  const shouldShowInfo = hasAtLeastOneProperty(entity);
  const { widget: infoWidget } = getInfoWidget(entity);

  return shouldShowInfo ? (
    <Widget
      key={infoWidget.id}
      {...infoWidget.config}
      isLocked
      additionalClass={c(styles.widget, styles[infoWidget.config.type])}
    />
  ) : null;
};
