import React, { ReactNode, useEffect, useRef } from 'react';
import c from 'classnames';
import Icon, { IconNames } from '@components/common/Icon';
import Tooltip from '@components/common/Tooltip';

import styles from './style.module.scss';

export type CheckboxProps = {
  id?: string;
  label?: string | ReactNode;
  labelFirst?: boolean;
  name?: string;
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  size?: 's' | 'm' | 'l';
  required?: boolean;
  helperText?: string;
  additionalClass?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, checked?: boolean, indeterminate?: boolean) => void;
};

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  label,
  labelFirst = false,
  checked = false,
  indeterminate = false,
  disabled = false,
  size = 'm',
  required = false,
  helperText,
  additionalClass,
  onChange,
  ...otherProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // indeterminate state can only be set from JavaScript
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !checked && indeterminate;
    }
  }, [checked, indeterminate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // by default indeterminate state gets lost as soon as checkbox is clicked - undesired in a controlled input
    if (indeterminate) {
      e.preventDefault();
      onChange?.(e, (inputRef.current!.checked = false), true);
    } else {
      onChange?.(e, inputRef.current?.checked ?? false, false);
    }
  };

  let className;
  if (checked) {
    className = 'checked';
  } else if (indeterminate) {
    className = 'indeterminate';
  } else {
    className = 'unchecked';
  }
  const iconName = `${className !== 'unchecked' ? 'BoxCheckbox' : 'CheckboxUnselected'}` as IconNames;

  const labelComponent = label && (
    <span className={styles.title}>
      {label}
      {required ? <span className="text-red-base"> *</span> : null}
      {helperText && (
        <Tooltip content={helperText}>
          <Icon name="Help" additionalClass="ml-6" size="s" />
        </Tooltip>
      )}
    </span>
  );

  return (
    <label
      htmlFor={id}
      className={c(
        styles.checkbox,
        styles[className],
        {
          [styles.disabled]: disabled,
          [styles.noLabel]: !label,
          [styles.small]: size === 's',
          [styles.medium]: size === 'm',
          [styles.large]: size === 'l',
        },
        additionalClass
      )}
    >
      {labelFirst && labelComponent}
      <span className={styles.container}>
        <input
          id={id}
          name={name ?? id}
          ref={inputRef}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          {...otherProps}
        />
        <span className={styles.icons}>
          <span className={styles.iconCheckBoxUncheckedContainer}>
            <Icon name={iconName} additionalClass={styles.iconCheckBoxUnchecked} />
          </span>
          <Icon name="InternalCheckmark" additionalClass={styles.iconCheckBoxCheckmark} size={size} />
          <Icon name="InternalIndeterminate" additionalClass={styles.iconCheckBoxIndeterminate} size={size} />
        </span>
      </span>
      {!labelFirst && labelComponent}
    </label>
  );
};

export default Checkbox;
