import React from 'react';
import c from 'classnames';
import styles from './style.module.scss';

type Props = {
  noSpacing?: boolean;
  dark?: boolean;
  gutter?: number;
  additionalClass?: string;
};

const HorizontalSeparator: React.FC<Props> = ({ gutter = 25, additionalClass, dark, noSpacing }) => (
  <div
    className={c(styles.horizontalSeparator, dark && styles.dark, additionalClass)}
    style={
      noSpacing
        ? {}
        : {
            paddingTop: `${gutter}px`,
            marginLeft: `-${gutter}px`,
            width: `calc(100% + ${gutter * 2}px)`,
          }
    }
  />
);

export default HorizontalSeparator;
